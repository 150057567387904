import axios from "axios";
import Config from "../../../config";

const v2_actionCriarCrmLead = (data) => {
    
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/crm/leads/criar`,
        data
    });
};

export default v2_actionCriarCrmLead;
