import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {  TableRow, TableCell, IconButton } from '@mui/material';
// utils
import {  fDateTime } from 'src/utils/formatTime';

// components
import Label from 'src/components/Label';

import { BiRightArrowAlt, BiSolidError } from 'react-icons/bi';
import { mascararTel } from 'src/utils/functions';



// ----------------------------------------------------------------------

AvaliacoesRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function AvaliacoesRow({ row, selected, onViewRow }) {
  const theme = useTheme();

  const { nomeCliente, telefone, dataAvaliacao, atendente, nota } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };





  return (
    <TableRow hover selected={selected}>

      <TableCell align="left">{nomeCliente}</TableCell>

      <TableCell align="left">{mascararTel(telefone)}</TableCell>

      <TableCell align="left">{fDateTime(dataAvaliacao)}</TableCell>

      <TableCell align="left">{atendente}</TableCell>


      <TableCell sx={{ textTransform: 'capitalize' }}>
        {nota}
      </TableCell>

    </TableRow>
  );
}
