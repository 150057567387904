import { Paper, Stack, Typography, Box, Container } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import { Droppable, Draggable } from 'react-beautiful-dnd';
import NegociosCard from './NegociosCard';
import DrawerNegocio from './DrawerNegocio';
import SkeletonKanbanCrm from 'src/components/skeleton/crm/SkeletonKanbanCrm';
import { setLeadData } from 'src/redux/slices/crm';

// actions
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';
import DrawerChatWhatsapp from 'src/sections/@chats/chat/DrawerChatWhatsapp';

/// silces
import { getChatInfoByChatId, getMensagensWhatsappDb, setChatInfo } from 'src/redux/slices/chat';


import { Card, CardContent } from 'src/@/components/ui/card';


// ----------------------------------------------------------------------

KanbanColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number
};

export default function KanbanColumn({ user, configCrm, loadingKanban, cliente, column, index, callbackRefreshLeads }) {
  const dispatch = useDispatch()
  const { leadsKanban, lead } = useSelector((state) => state.crm);
  const { nome, leadIds, id } = column;
  const [openDrawerNegocio, setOpenDrawerNegocio] = useState(false);
  const [openDrawerChatWhatsapp, setOpenDrawerChatWhatsapp] = useState(false);
  const [configCrm2, setConfigCrm2] = useState(configCrm);


  useEffect(() => {

  }, [leadsKanban, leadIds]);

  async function buscarConfigCrm(querys) {
    try {
      const result = await v2_actionConfigCrm(cliente._id, querys);
      if (result.data.data) {
        setConfigCrm2(result.data.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handlOpenLead(data) {
    dispatch(setLeadData(data));
  }

  return (
    <>
      <DrawerChatWhatsapp
        open={openDrawerChatWhatsapp}
        onClose={() => {
          setOpenDrawerChatWhatsapp(false)
        }}
        cliente={cliente}
        user={user}
      />


      <DrawerNegocio
        configCrm={configCrm2}
        open={openDrawerNegocio}
        lead={lead}
        onClose={() => setOpenDrawerNegocio(false)}
        callbackRefreshLeads={callbackRefreshLeads}
      />

        <Draggable isDragDisabled draggableId={id} index={index}>
          {(provided) => (
            <Card
              {...provided.draggableProps}
              ref={provided.innerRef}
              className="bg-slate-100 w-full"
            >
              <CardContent>
                <Stack spacing={3} {...provided.dragHandleProps}>
                  <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={1} sx={{ pt: 1 }}>
                    <Typography variant="body" >{nome} </Typography>
                    <Box>
                      <Typography variant="body" sx={{ color: 'text.secondary' }}>{leadIds.length}</Typography>
                    </Box>
                  </Stack>
     
                  <Droppable droppableId={id} type="task">
                    {(provided) => (
                      <div className='flex flex-col gap-2 w-full min-h-[70vh - 5vh] min-w-[250px]' ref={provided.innerRef} {...provided.droppableProps} >

                        {loadingKanban ? (
                          <SkeletonKanbanCrm />
                        ) : (
                          leadsKanban && leadIds.map((cardId, index) => <NegociosCard
                            onOpenDrawerWhatsapp={(chatInfo, clienteWhatsapp) => {
                              setOpenDrawerChatWhatsapp(true)
                              dispatch(getMensagensWhatsappDb(clienteWhatsapp.sessao, chatInfo.numero, 1, 10));
                              dispatch(setChatInfo(chatInfo));
                              dispatch(getChatInfoByChatId(chatInfo._id, chatInfo.funil_id, user.id, true));
                            }}
                            onOpen={() => {
                              let leadContent = leadsKanban[cardId];
                              handlOpenLead(leadContent)
                              let querys = `?pipeline_id=${leadContent.crm_pipeline_id}`
                              buscarConfigCrm(querys);
                              setOpenDrawerNegocio(true);
                            }}
                            key={index}
                            card={leadsKanban[cardId]} />
                          )
                        )
                        }
                      </div>
                    )}
                  </Droppable>
            
                </Stack>

              </CardContent>
            </Card>

          )}
        </Draggable>



    </>
  );
}
