import arrowIosDownwardFill from '@iconify/icons-eva/arrow-ios-downward-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import emailFill from '@iconify/icons-eva/email-fill';
import phoneFill from '@iconify/icons-eva/phone-fill';
import pinFill from '@iconify/icons-eva/pin-fill';
import { Icon } from '@iconify/react';
import { Box, Avatar, Button, Divider, Collapse, Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { BsExclamationCircleFill } from 'react-icons/bs';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import { getChatsAtendimento, getChatInfoByChatId, getLoadMessagesInChat, getCountsChats } from 'src/redux/slices/chat';
import { useDispatch } from 'src/redux/store';


import buscarCep from '../../../actions/chats/buscarCep';
import getActionEditarContato from '../../../actions/chats/getActionEditarContato';
import getActionVerificaNumero from '../../../actions/chats/whatsapp/getActionVerificaNumero';
import ModalNovoContato from './components/ModalNovoContato';
import { Autocomplete, Chip, IconButton, LinearProgress, TextField } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import v2_actionEditarTagsChats from 'src/actions/v2/chats/v2_actionEditarTagsChats';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Scrollbar from 'src/components/Scrollbar';


//actions




const CollapseButtonStyle = styled(Button)(({ theme }) => ({
  ...theme.typography.overline,
  height: 40,
  borderRadius: 0,
  padding: theme.spacing(1, 2),
  justifyContent: 'space-between',
  color: theme.palette.text.disabled
}));

const RowIconStyle = styled(Icon)(({ theme }) => ({
  width: 16,
  height: 16,
  marginTop: 4,
  color: theme.palette.text.secondary
}));

const RowTextStyle = styled(Typography)(({ theme }) => ({
  flexGrow: 1,
  wordWrap: 'break-word',
  ...theme.typography.body2
}));

// ----------------------------------------------------------------------

ChatRoomOneParticipant.propTypes = {
  isCollapse: PropTypes.bool,
  onCollapse: PropTypes.func
};

export default function ChatRoomOneParticipant({ chat, isCollapse, listaTags, onCollapse, ...other }) {
  const defaultImage = 'https://zapchat.nyc3.digitaloceanspaces.com/images/not-picture.png';

  const dispatch = useDispatch();

  const { listaRespostas } = useSelector((state) => state.chat);

  const [stateModalNovoContato, setStateModalNovoContato] = useState(false);
  const [isContatoExiste, setIsContatoExiste] = useState(false);
  const [isNumeroWhatsapp, setIsNumeroWhatsapp] = useState(false);
  const [stepCadastrarContato, setStepCadastrarContato] = useState(2);

  const [ddd, setDDD] = useState("");
  const [whatsapp, setWhatsapp] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [obs, setObs] = useState("");

  const [nome, setNome] = useState("");
  const [sobrenome, setSobrenome] = useState("");
  //
  const [cep, setCep] = useState("");
  const [rua, setRua] = useState("");
  const [numero, setNumero] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");
  const [estado, setEstado] = useState("");
  const [complemento, setComplemento] = useState("");
  const contato = { nome, sobrenome, email, cpf, rua, cep, numero, bairro, cidade, estado, complemento, ddd, whatsapp, obs };
  const { user } = useAuth();
  //tags
  const [loadingTags, setLoadingTags] = useState(false);

  const [tagsChamado, setTagsChamados] = useState(chat.tags_ids);
  const [origemNumero, setOrigemNumero] = useState('nacional');



  const handleOnBlurSearchCep = async (zipCode) => {
    try {
      const address = await buscarCep(zipCode)
      if (address.erro) {
        alert('erro ao buscar cep')

      } else {
        //	setCepErro(false);
        setRua(address.logradouro);
        setBairro(address.bairro);
        setCidade(address.localidade);
        setEstado(address.uf);
      }
    } catch (address) {
      alert('erro ao buscar cep')
    }
  }

  const handleOpenModalNovoContato = () => {
    if (chat.info) {
      let phone = chat.numeroCliente;
      let dddExtract = phone.substring(2, 4);
      let excluirDDDAndPhone = phone.substring(4)
      setWhatsapp(excluirDDDAndPhone)
      setNome(chat.info.nome)
      setSobrenome(chat.info.sobrenome)
      setEmail(chat.info.email)
      setDDD(dddExtract)
      setCep(chat.info.cep)
      setRua(chat.info.rua)
      setNumero(chat.info.numero)
      setBairro(chat.info.bairro)
      setCidade(chat.info.cidade)
      setEstado(chat.info.estado)
      setComplemento(chat.info.complemento)
      setCpf(chat.info.cpf)
      setObs(chat.info.obs)
    } else {
      let phone = chat.numeroCliente;
      let excluirDDDAndPhone = phone.substring(4);

      setDDD((chat.numeroCliente).substring(2, 4));
      setWhatsapp(excluirDDDAndPhone);
    }

    setStateModalNovoContato(true);
  };

  async function onEditarTags(tags) {
    const reduceArrayTags = tags.map((acc) => acc._id)
    try {
      let data = {
          tags:reduceArrayTags,
          particpantExclude:user.id
      }
      setLoadingTags(true);
      await v2_actionEditarTagsChats(chat._id, data);
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId));
      dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
      dispatch(getLoadMessagesInChat(chat.numeroCliente));
      dispatch(getChatInfoByChatId(chat._id, null, user.id, true));

      setTimeout(() => {
        setLoadingTags(false)
      }, 900);
    } catch (error) {
      setLoadingTags(false)
      console.log(error)
    }
  }

  useEffect(() => {
    setTagsChamados(chat.tags_ids)
  }, [chat])
  


  const handleEditarContato = async () => {
    let data = {
      action: 'edit',
      usuarioId: user.id,
      clienteId: chat.cliente_id,
      nome,
      ddd,
      whatsapp: whatsapp,
      sobrenome,
      email,
      cpf,
      rua,
      bairro,
      cidade,
      numero,
      estado,
      cep,
      obs,
      complemento
    }
    getActionEditarContato(data)
      .then(res => {
        if (res.data.codigo === 1) {
          setStateModalNovoContato(false);
          setRua("");
          setCep("");
          setCidade("");
          setEstado("");
          setDDD("");
          setNome("");
          setEmail("");
          setSobrenome("");
          setBairro("");
          setNumero("");
          setWhatsapp("");
          setNumero("");
          setWhatsapp("");
          setCpf("");
          setObs("");

          dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
          dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
          dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));
          dispatch(getLoadMessagesInChat(chat.numeroCliente));
        }
      }).catch(e => console.log(e));
  }

  const verificaStepCadastrarContato = () => {

    let numberFormat = Number(ddd);

    if (whatsapp) {

      if (numberFormat >= 30) {
        if (whatsapp.length > 8) {
          // setDialogVerificaDDD(true);
          // setDataDialogVerificaDDD("RETIRE o digito 9 no início do número")
        } else {
          getActionVerificaNumero("55" + ddd + whatsapp).then(res => {
            if (res.data.response.numberExists === false) {
              setIsNumeroWhatsapp(true);
            } else {
              getActionChatInfo(chat.cliente_id, `55${ddd}${whatsapp}`).then(res => {
                if (res.data) {
                  setIsContatoExiste(true);
                } else {
                  setStepCadastrarContato(2)
                  // liberar passo 2
                }
              });
            }
          });
        }
      } else if (numberFormat < 30) {
        if (whatsapp.length < 9) {
          // setDialogVerificaDDD(true);
          // setDataDialogVerificaDDD("INSIRA o digito 9 no início do número")
        } else {
          getActionVerificaNumero("55" + ddd + whatsapp).then(res => {
            if (res.data.response.numberExists === false) {
              setIsNumeroWhatsapp(true);
            } else {
              getActionChatInfo(chat.cliente_id, `55${ddd}${whatsapp}`).then(res => {
                if (res.data) {
                  setIsContatoExiste(true);
                } else {
                  setStepCadastrarContato(2);
                }
              });
            }
          });
        }
      }
    }

  }



  if (chat === undefined) {
    return null;
  }

  return (
    <Scrollbar sx={{ height: '90vh' }}>


      {chat && (
        <>
          <Box
            sx={{
              pt: 4,
              pb: 1,
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column'
            }}
          >
            <Box sx={{ mt: 2, textAlign: 'center' }}>
              <Stack alignItems={'center'} spacing={2} direction="row">
                <Typography variant="subtitle1">{chat.info && chat.info.nome}</Typography>
                <IconButton onClick={handleOpenModalNovoContato} size="small" sx={{ ml: 1, bgcolor: theme => theme.palette.grey[300] }}>
                  <Iconify sx={{ width: 12, height: 12 }} icon="eva:edit-fill" />
                </IconButton>
              </Stack>

            </Box>
          </Box>
     
          <Divider sx={{mb:1, mt:3}} />

          <Box sx={{mt:1}}>
          <CollapseButtonStyle
            fullWidth
            color="inherit"
            onClick={onCollapse}
            endIcon={<Icon icon={isCollapse ? arrowIosDownwardFill : arrowIosForwardFill} width={16} height={16} />}
          >
            Informações
          </CollapseButtonStyle>
          {chat.info && (
            <Collapse in={isCollapse}>
              <Stack spacing={2} sx={{ p: 2.5, pt: 1 }}>
                <Stack direction="row" spacing={1}>
                  <RowIconStyle icon={pinFill} />
                  <RowTextStyle>{`${chat.info && chat.info.rua}, ${chat.info && chat.info.numero}, ${chat.info && chat.info.bairro}, ${chat.info && chat.info.cidade} - ${chat.info && chat.info.estado}`}</RowTextStyle>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <RowIconStyle icon={phoneFill} />
                  <RowTextStyle>{chat.numeroCliente}</RowTextStyle>
                  {/* <RowTextStyle>{formataNumeroCelular(chat.info && chat.numeroCliente)}</RowTextStyle> */}
                </Stack>
                <Stack direction="row" spacing={1}>
                  <RowIconStyle icon={emailFill} />
                  <RowTextStyle>{chat.info && chat.info.email}</RowTextStyle>
                </Stack>
                <Stack direction="row" alignItems={'center'} spacing={1}>
                  <Typography sx={{ fontWeight: 'bold', fontSize: 12 }}>CPF:</Typography>
                  <RowTextStyle>{chat.info && chat.info.cpf}</RowTextStyle>
                </Stack>
                <Stack direction="row" spacing={1}>
                  <BsExclamationCircleFill />
                  <RowTextStyle>{chat.info && chat.info.obs}</RowTextStyle>
                </Stack>
              </Stack>
            </Collapse>
          )}
          </Box>
        
        </>
      )}
           <Divider sx={{mb:1, mt:2}} />

{
            loadingTags ? <LinearProgress /> : (
              listaTags && tagsChamado && (
                <Autocomplete
                multiple
                sx={{ p: 2, mb:5, background:'#fff' }}
                limitTags={1}
                id="size-small-outlined-multi"
                options={listaTags}
                value={tagsChamado}
                size='small'
                onChange={(event, newInputValue) => {
                  onEditarTags(newInputValue)
                }}
                renderTags={(value, getTagProps) =>
                  value.map((option, index) => (
                    <Chip
                      variant="warning"
                      label={option.nome}
                      color="default"
                      size="small"
                      {...getTagProps({ index })}
                    />
                  ))
                }
                getOptionLabel={(option) => option.nome}
                renderInput={(params) => (
                  <TextField   {...params} sx={{ background:'#fff', height: 20 }} fullWidth placeholder="Tags" />
                )}
              />
              )
       
            )
          }

    </Scrollbar>
  );
}
