import axios from "axios";
import Config from "../../config";

const actionListaClientesCanais = (clienteId, platform, data) => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/${clienteId}/clientes-canais`
    });
};

export default actionListaClientesCanais;
