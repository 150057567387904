import { useState } from 'react';
// material
import { 
 // Button, 
 // Dialog, 
 // DialogTitle, 
 // DialogActions, 
 // DialogContent, 
 // DialogContentText, 
  LinearProgress, 
  Box 
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { verifyRules } from 'src/utils/functions';
// ----------------------------------------------------------------------

import { Button } from "src/@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/@/components/ui/dialog"
import { Input } from "src/@/components/ui/input"
import { Label } from "src/@/components/ui/label"
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';

export default function ModalFinalizarChat({
  loadingFinalizarChat,
  stateModalFinalizarChatProps,
  closeModalFinalizarChat,
  finalizarChat,
  user
}) {

  return (
    <div>
      <Dialog open={stateModalFinalizarChatProps} onOpenChange={closeModalFinalizarChat}>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>Finalizar chat</DialogTitle>
        </DialogHeader>
        <div className="flex flex-row py-12 gap-2">
        {loadingFinalizarChat ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : (
            <>
              {user && user.rules.length > 0 && verifyRules(user.rules, "finalizar_sem_mensagem") && (
                <ButtonLoading className="w-full bg-slate-500" onClick={() => finalizarChat(false)} loading={loadingFinalizarChat} >
                  Finalizar sem Mensagem
                </ButtonLoading>
              )}

              <ButtonLoading className="w-full bg-slate-500" onClick={() => finalizarChat(true)} loading={loadingFinalizarChat} >
                Finalizar
              </ButtonLoading>
            </>
          )}
        </div>

      </DialogContent>
    </Dialog>

      {/* <Dialog open={stateModalFinalizarChatProps}>
        <DialogTitle> {loadingFinalizarChat ? "Aguarde enquanto o chat é finalizado ..." : "Tem certeza que deseja finalizar o atendimento?"}  </DialogTitle>
        <DialogContent>
        </DialogContent>
        <DialogActions>
          {loadingFinalizarChat ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : (
            <>
              <Button variant="contained" color="error" onClick={closeModalFinalizarChat}>Cancelar</Button>
              {user && user.rules.length > 0 && verifyRules(user.rules, "finalizar_sem_mensagem") && (
                <LoadingButton onClick={() => finalizarChat("sem_mensagem")} loading={loadingFinalizarChat} variant="outlined" >
                  Finalizar sem Mensagem
                </LoadingButton>
              )}

              <LoadingButton onClick={() => finalizarChat("com_mensagem")} loading={loadingFinalizarChat} variant="contained" >
                Finalizar
              </LoadingButton>
            </>
          )}


        </DialogActions>
      </Dialog> */}
    </div>
  );
}
