import axios from "axios";

import Config from "../../config";

const getActionBuscarNomeOuSetor = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/chat/transferir/buscar-nome-ou-setor`,
        data
    });
};

export default getActionBuscarNomeOuSetor;
