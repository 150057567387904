import axios from "axios";

import Config from "../../../config";

const actionTotalTickets = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/dashboard/relatorios/tickets-total`,
        data
    });
};

export default actionTotalTickets;
