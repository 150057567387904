import axios from "axios";
import Config from "src/config";

const actionListaRespostasRapida = clienteId => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/cliente/respostas/lista/${clienteId}`
    });
};

export default actionListaRespostasRapida;
