import { createSlice } from '@reduxjs/toolkit';

// utils
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import getActionTransferirChat from 'src/actions/chats/getActionTransferirChat';
import getListaFunilByChatId from 'src/actions/chats/getListaFunilByChatId';

// actions whatsapp

import actionGetChatMeta from 'src/actions/chats/meta/actionGetChatMeta';
import v2_actionBuscarChatById from 'src/actions/v2/services/chats/v2_actionBuscarChatById';
import v2_actionListaByStatus from 'src/actions/v2/services/chats/v2_actionListaByStatus';

import getActionChatsPendentesMarketplace from '../../actions/chats/getActionChatsPendentesMarketplace';
import getActionListaChatsByStatus from "../../actions/chats/getActionListaChatsByStatus"
import getActionAllMessagesWhatsapp from '../../actions/chats/whatsapp/getActionAllMessagesWhatsapp';
import actionGetMessages from '../../actions/chats/whatsapp/getMessagesNew';
import axios from '../../utils/axios';
import getActionPesquisarChat from 'src/actions/chats/getActionPesquisarChat';
import v2_actionListaTagsChats from 'src/actions/v2/chats/v2_actionListaTagsChats';
import v2_actionCountChats from 'src/actions/v2/chats/v2_actionCountChats';
import v1_actionBuscarMensagensWhatsapp from 'src/actions/v1/whatsapp/v1_actionBuscarMensagensWhatsapp';
import Worker from "worker-loader!../../actions/idb/workers/app.worker.worker";
import v2_actionListaContatosCliente from 'src/actions/v2/contatos/v2_actionListaContatosCliente';
import v2_actionPesquisarContatos from 'src/actions/v2/contatos/v2_actionPesquisarContatos';
const wbWorker = new Worker();

const initialState = {
  contatos:[]
};

const slice = createSlice({
  name: 'contatos',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingChat = false;
      state.error = action.payload;
    },


    // GET PARTICIPANTS
    getBuscarContatosSuccess(state, action) {
      state.contatos = action.payload;
    },

  
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
 
} = slice.actions;


// BSUCAR CONTATOS  ----------------------------------------------------------------------
export function getBuscarContatos(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
   
      const result = await v2_actionListaContatosCliente(clienteId)
      dispatch(slice.actions.getBuscarContatosSuccess(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getPesquisarContatos(clienteId, pesquisa) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
        console.log('pesquisa', pesquisa)
      const result = await v2_actionPesquisarContatos(clienteId, pesquisa)
      dispatch(slice.actions.getBuscarContatosSuccess(result.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}





