
// // material
// import { CssBaseline } from '@mui/material';
// import { createTheme, ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// import PropTypes from 'prop-types';
// import { useMemo } from 'react';

// // hooks
// import useSettings from '../hooks/useSettings';
// //
// import breakpoints from './breakpoints';
// import GlobalStyles from './globalStyles';
// // import componentsOverride from './overrides';
// import palette from './palette';
// import shadows, { customShadows } from './shadows';
// import shape from './shape';
// import typography from './typography';

// // ----------------------------------------------------------------------

// ThemeConfig.propTypes = {
//   children: PropTypes.node
// };

// export default function ThemeConfig({ children }) {
//   const { themeMode, themeDirection } = useSettings();
//   const isLight = themeMode === 'light';

//   const themeOptions = useMemo(
//     () => ({
//       palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' },
//       shape,
//       typography,
//       breakpoints,
//       direction: themeDirection,
//       shadows: isLight ? shadows.light : shadows.dark,
//       customShadows: isLight ? customShadows.light : customShadows.dark
//     }),
//     [isLight, themeDirection]
//   );

//   const theme = createTheme(themeOptions);
//   // theme.components = componentsOverride(theme);

//   return (
//     <StyledEngineProvider injectFirst>
//       <ThemeProvider theme={theme}>
//         <CssBaseline />
//         <GlobalStyles />
//         {children}
//       </ThemeProvider>
//     </StyledEngineProvider>
//   );
// }


import PropTypes from 'prop-types';
import { useMemo } from 'react';
// @mui
import { CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider as MUIThemeProvider, StyledEngineProvider } from '@mui/material/styles';
// hooks
import useSettings from '../hooks/useSettings';
import GlobalStyles from './globalStyles';

import palette from './palette';
import typography from './typography';
import breakpoints from './breakpoints';
import componentsOverride from './overrides';
import shadows, { customShadows } from './shadows';

// ----------------------------------------------------------------------

ThemeProvider.propTypes = {
  children: PropTypes.node,
};

export default function ThemeProvider({ children }) {
  const { themeMode, themeDirection } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions = useMemo(
    () => ({
      palette: isLight ? palette.light : palette.dark,
      typography,
      breakpoints,
      shape: { borderRadius: 8 },
      direction: themeDirection,
      shadows: isLight ? shadows.light : shadows.dark,
      customShadows: isLight ? customShadows.light : customShadows.dark,
    }),
    [isLight, themeDirection]
  );

  const theme = createTheme(themeOptions);
  theme.components = componentsOverride(theme);

  return (
    <StyledEngineProvider injectFirst>
      <MUIThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
        <GlobalStyles />
        {children}
      </MUIThemeProvider>
    </StyledEngineProvider>
  );
}
