// material
import {
    Alert,
    Autocomplete,
    Button,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    MenuItem, Select, Stack, Typography, Drawer, Container, IconButton
} from '@mui/material';
import './react-phone-number-input.css'
import { LoadingButton } from '@mui/lab';
import { useState, forwardRef } from 'react';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import getActionCriarContato from 'src/actions/chats/getActionCriarContato';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import { fDate, isDateValid } from 'src/utils/formatTime';
import { maskCPF, maskDate } from 'src/utils/functions';
// ----------------------------------------------------------------------

export default function NovoChatForm({
    user,
    cliente,
    open,
    listaClientesCanais,
    loading,
    onClose,
    callbackAddContact,
    showSignupButton,
    loadingNovoContato
}) {


    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');
    const [contato, setContato] = useState({
        nome: '',
        email: '',
        whatsapp: '',
        dataNascimento: '',
        cpf: ''
    });


    const handleCallbackAddContact = () => {
        let data = {
            contato
        }
        callbackAddContact(data)
       clearFields();

    }


    function clearFields() {
        setContato({
            nome: '',
            email: '',
            whatsapp: '',
            dataNascimento: '',
            cpf: ''
        })
    }



    return (
        <div>

            <ModalAlerta
                open={openModalAlerta}
                mensagem={mensagemModalAlerta}
                onClose={() => {
                    setOpenModalAlerta(false);
                    setMensagemModalAlerta('');
                }}
            />


            <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={1}>

                <Stack spacing={1}>


                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
          
   
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                            isRequired={true}
                                label="Nome"
                                input={<InputStyle onChange={e => {
                                    setContato({ ...contato, nome: e.target.value })
                                }}
                                    value={contato.nome}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>
                    </Stack>

                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
          
   
          <Box sx={{ width: '100%' }}>
                            <RowInput
                            isRequired={true}
                                label="Telefone"
                                input={<PhoneInput
                                    defaultCountry='BR'
                                    style={{ padding: 10, borderRadius: 4, border: '1px solid #666' }}
                                    placeholder="Insira seu numero"
                                    value={contato.whatsapp}
                                    onChange={value => setContato({ ...contato, whatsapp: value })}
                                />}
                            />
                        </Box>

                    </Stack>


        

                </Stack>

            </Stack>

            {showSignupButton && ([
                <Stack direction="row" sx={{ mt: 5, mb: 2 }}>
                    <LoadingButton
                        loading={loadingNovoContato}
                        onClick={handleCallbackAddContact}
                        sx={{ height: 40, fontSize: 12, borderRadius: 0.5 }}
                        variant="contained"
                        startIcon={<Iconify icon="mdi:plus" />}
                    >
                        Criar Conversa
                    </LoadingButton>
                </Stack>
            ])}





        </div>
    );
}
