// material
import {
    Divider,
    Box,
    MenuItem, Select, Stack, Typography, Drawer, Container, IconButton
} from '@mui/material';
import './react-phone-number-input.css'
import { LoadingButton } from '@mui/lab';
import { useState, forwardRef, useEffect } from 'react';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import getActionCriarContato from 'src/actions/chats/getActionCriarContato';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import { fDate, isDateValid } from 'src/utils/formatTime';
import { formataNumeroCelular, maskCPF, maskDate, soNumeros } from 'src/utils/functions';
import actionEditarPerfil from 'src/actions/usuarios/actionEditarPerfil';
import { useSnackbar } from 'notistack';
import v2_actionListSectorsByClient from 'src/actions/v2/cliente/v2_actionListSectorsByClient';
// ----------------------------------------------------------------------

export default function ModalEditarAgente({
    user,
    cliente,
    open,
    loading,
    onClose,
    callbackEdit,
    showSignupButton,
    dataUsuario
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');
    const [loadingEditPerfil, setLoadingEditPerfil] = useState(false);
    const [listaSetores, setListaSetores] = useState([]);

    const [dataAgente, setDataAgente] = useState({
        nome:dataUsuario?.firstName,
        sobrenome: dataUsuario?.lastName,
        email: dataUsuario?.email,
        usuario: dataUsuario?.username,
        nomeFantasia: dataUsuario?.nomeFantasia,
        telefone: dataUsuario?.telefone,
        usuarioId: dataUsuario?._id,
        setor_id: dataUsuario?.setor_id
    });



    async function getListSectors() {
        try {
            const response = await v2_actionListSectorsByClient(user?.clienteId);
            setListaSetores(response.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    // const handleCallbackAddContact = () => {
    //     let data = {
    //         contato
    //     }
    //     callbackEdit(data)
    //     clearFields();

    // }


    function clearFields() {
        setDataAgente({
            nome: '',
            sobrenome: '',
            email: '',
            usuario: '',
            nomeFantasia: '',
            telefone: '',
            usuarioId: '',
            setor_id: ''
        })
    }

    useEffect(() => {

        getListSectors();


        if(dataUsuario){
            setDataAgente({
                nome:dataUsuario?.firstName,
                sobrenome: dataUsuario?.lastName,
                email: dataUsuario?.email,
                usuario: dataUsuario?.username,
                nomeFantasia: dataUsuario?.nomeFantasia,
                telefone: dataUsuario?.telefone,
                usuarioId: dataUsuario?._id,
                setor_id: dataUsuario?.setor_id
            })
        }
 
        return () => {
            setDataAgente({
                nome: '',
                sobrenome: '',
                email: '',
                usuario: '',
                nomeFantasia: '',
                telefone: '',
                usuarioId: '',
                setor_id: ''
            })
        }

    }, [dataUsuario]);


    
    const handleEditarPerfil = () => {
        setLoadingEditPerfil(true);

        let data = {
            usuarioId: dataAgente?.usuarioId,
            firstName: dataAgente?.nome,
            lastName: dataAgente?.sobrenome,
            telefone: `55${soNumeros(dataAgente?.telefone)}`,
            email: dataAgente?.email,
            username: maskUsername(dataAgente?.usuario),
            nomeFantasia: dataAgente?.nomeFantasia,
            setor_id: dataAgente?.setor_id
        }

        actionEditarPerfil(data).then(res => {
            if (res.data.error === true) {
                setLoadingEditPerfil(false)
                setTimeout(() => {
                    enqueueSnackbar(res.data.mensagem, { variant: 'error' });
                }, 1000);

            } else {
                setLoadingEditPerfil(false)
                enqueueSnackbar(res.data.mensagem, { variant: 'success' });
                callbackEdit()

            }
        }).catch((err) => {
            console.log(err)
            setLoadingEditPerfil(false)
            setTimeout(() => {
                enqueueSnackbar('Erro ao alterar perfil', { variant: 'error' });
            }, 1000);
        })
    }


    function maskUsername(str) {
        return str.split(' ').join('')
    }



    return (
        <div>

            <Drawer
                open={open}
                onClose={onClose}
                anchor="right"
                PaperProps={{ sx: { width: { xs: 1, sm: 450 } } }}
            >
                <Box sx={{ p: 3 }}>
                    <ModalAlerta
                        open={openModalAlerta}
                        mensagem={mensagemModalAlerta}
                        onClose={() => {
                            setOpenModalAlerta(false);
                            setMensagemModalAlerta('');
                        }}
                    />

                    <Stack justifyContent={'space-between'} direction="row" alignItems={'center'}>
                        <Typography variant="h6">Editar Agente</Typography>
                        <IconButton onClick={onClose} >
                            <Iconify icon={'eva:close-outline'} sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    </Stack>

                    <Divider sx={{mt:2, mb:2, borderStyle:'dashed'}} />


                    <Stack spacing={{ xs: 1, md: 1 }}>
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                            <RowInput
                                label="Nome"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, nome: e.target.value })}
                                        value={dataAgente?.nome}
                                        fullWidth
                                    />
                                }
                            />
                            <RowInput
                                label="Sobrenome"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, sobrenome: e.target.value })}
                                        value={dataAgente?.sobrenome}
                                        fullWidth
                                    />
                                }
                            />
                        </Stack>
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                            <RowInput
                                label="Telefone"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, telefone: e.target.value })}
                                        value={dataAgente?.telefone ? formataNumeroCelular(dataAgente?.telefone) : ""}
                                        fullWidth
                                    />
                                }
                            />
                            <RowInput
                                label="Usuário"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, usuario: e.target.value })}
                                        value={dataAgente?.usuario}
                                        fullWidth
                                    />
                                }
                            />
                        </Stack>



                        <Stack direction={{ xs: 'column', md: 'column' }} spacing={2}>
                            <RowInput
                                label="Nome Fantasia"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, nomeFantasia: e.target.value })}
                                        value={dataAgente?.nomeFantasia}
                                        fullWidth
                                    />
                                }
                            />
                            <RowInput
                                label="Email"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, email: e.target.value })}
                                        value={dataAgente?.email}
                                        fullWidth
                                    />
                                }
                            />
                               <RowInput
                                label="Setor"
                                input={
                                    <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={dataAgente?.setor_id}
                                    size='small'
                                    name="modelOpenai"
                                    onChange={(e) => setDataAgente({ ...dataAgente, setor_id: e.target.value })}
                                >
                                    {
                                        listaSetores.map((setor) => {
                                            return (
                                                <MenuItem key={setor._id} value={setor._id}>{setor.nome_setor}</MenuItem>
                                            )
                                        })  
                                    }
            
                                </Select>
                                }
                            />
                        </Stack>




                    </Stack>

                  
                        <Stack direction="row" sx={{ mt: 5, mb: 2 }}>
                            <LoadingButton
                                loading={loadingEditPerfil}
                                onClick={handleEditarPerfil}
                                sx={{ height: 30, fontSize: 12, borderRadius: 0.5 }}
                                variant="contained"
                                startIcon={<Iconify icon="material-symbols:save" />}
                            >
                                Salvar
                            </LoadingButton>
                        </Stack>
                 
                </Box>

            </Drawer>
        </div>
    );
}
