import PropTypes from 'prop-types';
import { useNavigate, useParams } from 'react-router-dom';
// material
import { List } from '@mui/material';
// routes
import { PATH_CHAT } from 'src/routes/paths';
// redux
import { useDispatch } from 'src/redux/store';
import {
  getChatsAtendimento,
  getChatsPendente,
  getMessagesWhatsapp,
  getChatsGeral,
  getChatInfoByChatId,
  getCountsChats,
  getChatsFinalizados,
  getMensagensWhatsappDb,
  setChatInfo,
  removeChatFromList
} from 'src/redux/slices/chat';

// actions only 
import getActionChatByChatId from 'src/actions/chats/getActionChatByChatId';
import getActionAceitarChat from 'src/actions/chats/getActionAceitarChat';
import useAuth from 'src/hooks/useAuth';
import getActionFinalizarChat from 'src/actions/chats/getActionFinalizarChat';
import { useState } from 'react';
import ChatAtendimentoItem from '../ChatsListItem/ChatAtendimentoItem';
import { Button } from 'src/@/components/ui/button';

ChatConversationListGeral.propTypes = {
  isOpenSidebar: PropTypes.bool,
};

export default function ChatConversationListGeral({ chats, chat, isOpenSidebar, cliente, ...other }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user } = useAuth();
  const { conversationKey } = useParams();
  const [loadingFinalizarChat, setLoadingFinalizarChat] = useState(false)
  const [loadingAceitarChat, setLoadingAceitarChat] = useState(false)
  const [keyFinalizarChat, setKeyFinalizarChat] = useState('');
  const [visibleItems, setVisibleItems] = useState(10);

  const loadMoreItems = () => {
    setVisibleItems((prev) => prev + 10);
  };


  const handleSelectConversation = (chat) => {
    if (cliente.config.chat_source_db) {
      dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
    } else {
      dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
    }
    if (chat) {
      dispatch(setChatInfo(chat));
    } else {
      dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));
    }

  };

  const handleFinalizarChat = async (chat) => {
    setKeyFinalizarChat(chat._id)
    setLoadingFinalizarChat(true)
    let data = {
      chatId: chat._id,
      usuarioId: user.id
    };
    try {
      await getActionFinalizarChat(data);
      dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 50, true));
      dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));
      // dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
      setLoadingFinalizarChat(false);
      setKeyFinalizarChat('');
      if (chat._id === conversationKey) return navigate(`${PATH_CHAT.chat.root}`);

    } catch (error) {
      setLoadingFinalizarChat(false)
      setKeyFinalizarChat('')
      console.log("Erro ao finalizar chat");
    }


  };



  const handleAceitar = async (chatId) => {
    setLoadingAceitarChat(true)
    const res_chat = await getActionChatByChatId({ chatId: chatId });
    if (res_chat.data.statusRoom !== 'Atendendo') {
      try {
        dispatch(removeChatFromList({ chatId: chatId, status: 'Geral' }))
        await getActionAceitarChat({ chatId: chatId, usuarioId: user.id });
        setLoadingAceitarChat(false);
        dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
        dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, false));
        dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));
        handleSelectConversation(chat)
      } catch (error) {
        setLoadingAceitarChat(false)
      }
    }
  };

  return (
    <List {...other}>
      {chats.slice(0, visibleItems).map((chat) => (
        <ChatAtendimentoItem
          key={chat._id}
          chat={chat}
          keyFinalizarChat={keyFinalizarChat}
          loadingFinalizarChat={loadingFinalizarChat}
          isOpenSidebar={isOpenSidebar}
          onFinalizarChat={() => handleFinalizarChat(chat)}
          onAceitarChat={() => handleAceitar(chat._id)}
          onSelectConversation={() => handleSelectConversation(chat)}
        />
      ))}
          {visibleItems < chats.length && (
      <Button className="w-full" onClick={loadMoreItems} fullWidth>
        Carregar Mais
      </Button>
    )}
    </List>
  );
}
