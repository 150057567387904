import axios from "axios";

import Config from "../../config";

const getActionClienteByClienteId = clienteId => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/cliente/${clienteId}`
    });
};

export default getActionClienteByClienteId;
