import { createSlice } from '@reduxjs/toolkit';

import getRoom from '../../actions/chats/staff/getRoom';
import search from '../../actions/chats/staff/getRoom';
import getRoomsGroup from '../../actions/chats/staff/getRoomsGroup';
import getRoomsWithoutIsGroup from '../../actions/chats/staff/getRoomsWithoutIsGroup';

// ----------------------------------------------------------------------


const initialState = {
  isLoading: false,
  isOnlineUsers: true,
  error: false,
  io: null,
  room: null,
  messages: [],
  rooms: [],
  staff: [],
  mensagensStaff: [],
  staffs: [],
  staffsGroup: [],
  id: null,
  onlineUsers: [],
  refreshMeetings: null,
  searchResults: [],
  roomsWithNewMessages: [],
  panel:'panel',
  groupTitle: '',
  nav:null,
  search: '',
  newGroupUsers:[]
};

const slice = createSlice({
  name: 'staff',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    

    getListaStaffsWithoutIsGroupSuccess(state, action) {
      state.isLoading = false;
      state.staffs = action.payload;
    },

    getListaStaffsSuccess(state, action) {
      state.isLoading = false;
      state.staffs = action.payload;
    },
    getListaStaffsGroupSuccess(state, action) {
      state.isLoading = false;
      state.staffsGroup = action.payload;
    },

    getStaffSuccess(state, action) {
      state.isLoading = false;
      state.staff = action.payload;
      state.room = action.payload;
      state.mensagensStaff = action.payload.messages;
    },

    getOnlineUsers(state, action) {
      state.onlineUsers = action.payload.data;
    },




    getMensagensStaffSuccess(state, action) {
      state.isLoading = false;
      state.mensagensStaff = action.payload;
    },
    

    getSearchStaffsSuccess(state, action) {
      state.isLoading = false;
      state.searchResults = action.payload;
    },

    setMessage(state, action) {
      const novaMensgaem = action.payload;
      state.mensagensStaff.push(novaMensgaem);
    },

    setMessages(state, action) {
      state.messages = action.payload
    },

    setLimparChatStaff(state) {
      state.room = null;
    },

    setSearch(state, action) {
      state.search = action.payload;
    },

    setIsOnlineUsers(state, action) {
      
      state.isOnlineUsers = action.payload;
    },

    setPanel(state, action) {
      state.panel = action.payload;
    },
    setNav(state, action) {
      state.nav = action.payload;
    },
    setGroupTitle(state, action) {
      state.groupTitle = action.payload;
    },
    setNewGroupUsers(state, action) {
      state.newGroupUsers = action.payload;
    },
    setSearchResults(state, action) {
      state.searchResults = action.payload;
    },



    setOnlineUsers(state, action) {
      state.onlineUsers = action.payload
      // if(state.onlineUsers.length === action.payload.length) {
    
      // } else  {
      //   state.onlineUsers = action.payload
      // }
      
    },

  

     setAddRoomUnread(state, action) {
      const novaMensagem = action.payload;
      state.roomsWithNewMessages.push(novaMensagem);
    },

    setRemoveRoomUnread(state, action) {

      state.roomsWithNewMessages = state.roomsWithNewMessages.filter(r => r !== action.payload); 
    },

    getInitIoSuccess(state, action) {
      state.isLoading = false;
      state.io = action.payload.io;
      state.id = action.payload.id;
      //state.id = { ...state, io: action.io, id: action.io.id };
    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { 
  startLoading, 
  setMessage, 
  setMessages, 
  setAddRoomUnread, 
  setRemoveRoomUnread, 
  getOnlineUsers, 
  setOnlineUsers, 
  setLimparChatStaff, 
  setIsOnlineUsers, 
  setPanel, 
  setSearchResults, 
  setGroupTitle,
  setNewGroupUsers,
  setSearch
} = slice.actions;



// CHATS FINALIZADOS ----------------------------------------------------------------------
export function getListaStaffs(isGroup) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getRoomsGroup(isGroup);
      dispatch(slice.actions.getListaStaffsSuccess(response.data.rooms));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getListaStaffsWithoutIsGroup() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getRoomsWithoutIsGroup();
      dispatch(slice.actions.getListaStaffsWithoutIsGroupSuccess(response.data.rooms));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// getListaStaffs group ----------------------------------------------------------------------
export function getListaStaffsGroup(isGroup) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getRoomsGroup(isGroup);
      dispatch(slice.actions.getListaStaffsGroupSuccess(response.data.rooms));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




// GET STAFF ----------------------------------------------------------------------
export function getStaff(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getRoom(id);
      dispatch(slice.actions.getStaffSuccess(response.data.room));
      //  dispatch(slice.actions.getMensagensStaffSuccess(response.data.room.messages));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET MENSAGENS STAFF ----------------------------------------------------------------------
export function getMensagensStaff(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getRoom(id);
      dispatch(slice.actions.getMensagensStaffSuccess(response.data.room.messages));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// GET MENSAGENS STAFF ----------------------------------------------------------------------
export function getSearchStaffs(text, limit) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await search(text, limit);
      dispatch(slice.actions.getSearchStaffsSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




// GET MENSAGENS STAFF ----------------------------------------------------------------------
export function getIoInit(io) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.getInitIoSuccess(io));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }

  };
}



