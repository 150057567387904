import axios from "axios";
import Config from "../../config";

const actionListaTicketsPorUsuario = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chamados/lista-por-usuario${querys}`

    });
};

export default actionListaTicketsPorUsuario;
