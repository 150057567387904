import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import v2_actionCriarAgente from 'src/actions/v2/ai/v2_actionCriarAgente';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';

import { Button } from "src/@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "src/@/components/ui/dialog"
import { Input } from "src/@/components/ui/input"
import { Label } from "src/@/components/ui/label"
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';

export default function ModalCriarAgente({ open, onClose, cliente, onCallback }) {

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCriarAgente = async () => {
        setLoading(true);
        try {
            let data = {
                scope: {
                    name: name
                }
            }
            const response = await v2_actionCriarAgente(cliente._id, data);
            onCallback(response.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }


    return (


        <Dialog 
        open={open}
        onOpenChange={onClose}
        >
        
            <DialogContent className="sm:max-w-[425px]">
                <DialogHeader>
                    <DialogTitle>Criar agente</DialogTitle>

                </DialogHeader>
                <div className="grid gap-4 py-4">
                    <RowInput
                        sx={{ width: '100%' }}
                        label="nome do agente"
                        input={<InputStyle
                            size="small"
                            value={name}
                            fullWidth
                            onChange={(e) => setName(e.target.value)
                            }
                        />}
                    />
                </div>
                <DialogFooter>
                    <ButtonLoading  loading={loading}onClick={handleCriarAgente} >Criar</ButtonLoading>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    );
}








