import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Navigate, useLocation, useNavigate } from 'react-router-dom';

// hooks
import useAuth from '../hooks/useAuth';
// pages
import Login from '../pages/authentication/Login';
import ChamadosDetalhes from 'src/sections/@dashboard/tickets/ChamadosDetalhes';
import { useDispatch, useSelector } from 'react-redux';
import { changeOpenDetalhesChamado, getListaColunas, getListaTickets, getRefreshComments } from 'src/redux/slices/chamados';
import { getListaNotificacoes } from 'src/redux/slices/notificacoes';
import ModalNovoLead from 'src/sections/@dashboard/cadastro/ModalNovoNegocio';
import ModalNovaEmpresa from 'src/sections/@dashboard/cadastro/ModalNovaEmpresa';
import { resetModalAlerta, setContato, setConversa, setEmpresa, setNegocio } from 'src/redux/slices/global';
import ModalNovoChat from 'src/sections/@chats/chat/Modals/ModalNovoChat';
import ModalNovoContato from 'src/sections/@chats/chat/Modals/ModalNovoContato';
import ModalNovoNegocio from 'src/sections/@dashboard/cadastro/ModalNovoNegocio';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import v2_actionDadosUsuario from 'src/actions/v2/usuarios/v2_actionDadosUsuario';
import { setUserStandby } from 'src/redux/slices/user';
import Login2 from 'src/pages/authentication/Login2';
import v2_actionCheckIsBlock from 'src/actions/v2/cliente/v2_actionCheckIsBlock';

// ----------------------------------------------------------------------

AuthGuard.propTypes = {
  children: PropTypes.node
};

export default function AuthGuard({ children }) {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth();
  const { pathname } = useLocation();
  const { user, cliente } = useAuth()
  const [requestedLocation, setRequestedLocation] = useState(null);
  const { chamado, comentarios_chamado, openChamado } = useSelector((state) => state.chamados);
  const { empresa, contato, negocio, conversa, modalAlerta } = useSelector((state) => state.global);
  const [listaClientesCanais, setListaClientesCanais] = useState([]);
  const navigate = useNavigate();
  
  async function buscarListaClientesCanais() {
    try {
      const response_clientes_canais = await actionListaClientesCanais(cliente._id)
      console.log(response_clientes_canais.data.data)
      setListaClientesCanais(response_clientes_canais.data.data);
    } catch (err) {
      console.log(err)
    }
  }

  async function getUserData() {
    try {
      const response = await v2_actionDadosUsuario(user.id);
      if(response.status === 200) {
        let userData = response.data.data
        dispatch(setUserStandby(userData.userStandby))
      }
    } catch (err) {
      console.log(err)
    }
  }

  async function checkIsBlock(id) {
   
    try {
        const response = await v2_actionCheckIsBlock(id);
        console.log('check block', response.data.data)
        if(response.status === 200) {
            if(response.data.data) {
                navigate('/auth/suspend');
            } else {
              navigate('/chats/chat');
            }
        }
     } catch(err) {
        console.log(err)
     }
    
}

  useEffect(() => {
    if (user) {
      dispatch(getListaNotificacoes(user.id))
      buscarListaClientesCanais();
      getUserData()
    }

    if(cliente) {
      checkIsBlock(cliente._id)
    }
  }, []);



  if (!isAuthenticated) {
    if (pathname !== requestedLocation) {
      setRequestedLocation(pathname);
    }
    return <Login />;
  }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }

  return (
    <>
      {chamado && (
        <ChamadosDetalhes
          chamado={chamado}
          comentarios={comentarios_chamado}
          isOpen={openChamado}
          onClose={() => {

            dispatch(changeOpenDetalhesChamado(false))
            dispatch(getListaTickets({ setorId: user.setorId, usuarioId: user.id, filial: user.filial }));
            dispatch(getListaColunas({ setorId: user.setorId, usuarioId: user.id, filial: user.filial }));
          }}
          onDeleteTask={() => { }}
        />
      )}

      <ModalNovoNegocio
        open={negocio.openModal}
        cliente={cliente}
        user={user}
        onClose={() => dispatch(setNegocio({ openModal: false, loading: false }))}
      />

      <ModalNovoChat
        open={conversa.openModal}
        onClose={() => dispatch(setConversa({ openModal: false, loading: false }))}
        listaClientesCanais={listaClientesCanais}
        cliente={cliente}
        user={user}
      />

      <ModalNovaEmpresa
        open={empresa.openModal}
        cliente={cliente}
        onClose={() => dispatch(setEmpresa({ openModal: false, loading: false }))}
      />

      <ModalNovoContato
      open={contato.openModal}
      cliente={cliente}
      callbackAddContact={() => {}}
      user={user}
      onClose={() => dispatch(setContato({ openModal: false, loading: false }))}
    />

    <ModalAlerta
      open={modalAlerta.open}
      mensagem={modalAlerta.mensagem}
      titulo={modalAlerta.titulo}
      onClose={() => {
        dispatch(resetModalAlerta())
      }}
      />

      {children}
    </>

  );
}

