import { List } from '@mui/material';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import getActionMensagemLida from 'src/actions/chats/getActionMensagemLida';

import getActionAceitarChat from 'src/actions/chats/getActionAceitarChat';
import getActionChatByChatId from 'src/actions/chats/getActionChatByChatId';
import {
  getChatInfoByChatId,
  getChatsAtendimento,
  getChatsFinalizados,
  getMensagensWhatsapp,
  getMensagensWhatsappDb,
  removeChatFromList,
  setChatInfo
} from 'src/redux/slices/chat';
import { useDispatch } from 'src/redux/store';
import { PATH_CHAT } from 'src/routes/paths';
import useAuth from 'src/hooks/useAuth';
import ChatAtendimentoItem from '../ChatsListItem/ChatAtendimentoItem';
import { useState } from 'react';
import { Button } from 'src/@/components/ui/button';




// ----------------------------------------------------------------------

ChatConversationListFinalizado.propTypes = {
  chats: PropTypes.array,
  onSelectContact: PropTypes.func
};

export default function ChatConversationListFinalizado({ chats, isOpenSidebar, cliente }) {
  const navigate = useNavigate();
  const { user } = useAuth()
  const dispatch = useDispatch();

  const [visibleItems, setVisibleItems] = useState(10);

  const loadMoreItems = () => {
    setVisibleItems((prev) => prev + 10);
  };

  const handlePegarChat = async (chatId) => {
    try {
      const response = await getActionChatByChatId({ chatId: chatId });
      if (response.data.statusRoom !== 'Atendendo') {
        dispatch(removeChatFromList({ chatId: chatId, status: 'Finalizado' }));
        await getActionAceitarChat({ chatId: chatId, usuarioId: user.id });
      }
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
      dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, false));

    } catch (error) {
      console.log(error)
    }
  }


  const handleSelectConversation = (chat) => {
    if (chat.direciona_usuarios) {
      if (chat.direciona_usuarios[0] === user.id) {
        getActionMensagemLida({ chatId: chat._id }).then(() => {
          dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
          if (cliente.config.chat_source_db) {
            dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
          } else {
            dispatch(getMensagensWhatsapp(chat.sessao, chat.numeroCliente));
          }

          if (chat) {
            dispatch(setChatInfo(chat));
          } else {
            dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));
          }
        }).catch(err => console.log(err))
        navigate(`${PATH_CHAT.chat.root}`);

      }
    } else if (chat.direciona_setores) {
      if (chat.direciona_setores[0] === user.setorId) {
        getActionMensagemLida({ chatId: chat._id }).then(() => {
          dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
          if (cliente.config.chat_source_db) {
            dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
          } else {
            dispatch(getMensagensWhatsapp(chat.sessao, chat.numeroCliente));
          }
          if (chat) {
            dispatch(setChatInfo(chat));
          } else {
            dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));
          }
        }).catch(err => console.log(err))
        navigate(`${PATH_CHAT.chat.root}`);

      }

    }
  };


  return (
    <List>
    {chats.slice(0, visibleItems).map((result, index) => (
      <ChatAtendimentoItem
        key={result._id}
        onSelectConversation={() => handleSelectConversation(result)}
        onAceitarChat={() => handlePegarChat(result._id)}
        isOpenSidebar={isOpenSidebar}
        chat={result}
      />
    ))}
    {visibleItems < chats.length && (
      <Button className="w-full" onClick={loadMoreItems} fullWidth>
        Carregar Mais
      </Button>
    )}
  </List>
  );
}
