import axios from "axios";

import Config from "../../config";

const actionBuscarCampanhaById = campanhaId => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/campanhas/${campanhaId}/id`      
    });
};

export default actionBuscarCampanhaById;
