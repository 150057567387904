// material
import { Input, Divider, IconButton, InputAdornment, Stack } from '@mui/material';
import { styled } from "@mui/material/styles";
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

//
import EmojiPicker from '../../EmojiPicker';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 46,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  px: theme.spacing(2),
  border: '1px solid #666',
  borderRadius: 5
}));

// ----------------------------------------------------------------------

DrawerChatgptInput.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
  onSend: PropTypes.func
};

export default function DrawerChatgptInput({ disabled, loadingGpt, changeText, text, conversationId, onSend, onOpenMenu, ...other }) {
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');


  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      onSend()
    }
  };


  return (
    <RootStyle {...other}>
      
      {/* <IconButton color="primary" disabled={!text} onClick={onOpenMenu}>
        <Iconify color="primary" icon="mdi:stars" width={18} height={15} />
        </IconButton> */}
      <Input
        
        fullWidth
        value={!loadingGpt ? text : "Gerando resposta..."}
        disabled={loadingGpt}
        disableUnderline
        onKeyUp={handleKeyUp}
        onChange={changeText}
        placeholder="Escreva uma mensagem..."
        sx={{ height: '100%', color: '#fff', fontWeight: '300', fontSize: 14, ml:2 }}
      />
      <Divider orientation="vertical" flexItem />
      <Stack sx={{mx:1}} direction="row" spaicng={1}>
        <IconButton color="primary" disabled={!text} onClick={onSend}>
          <Iconify width={20} icon="mdi:send" />
        </IconButton>

      </Stack>



    </RootStyle>
  );
}
