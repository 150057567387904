import { useState } from "react"
import { Button } from "src/@/components/ui/button"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "src/@/components/ui/dialog"
import { Input } from "src/@/components/ui/input"
import { Label } from "src/@/components/ui/label"
import { Textarea } from "src/@/components/ui/textarea"
import { RowInput } from "src/components/forms/RowInput"

export default function ModalSetores({
  open,
  onClose,
  onSubmit,
  action,
  nome,
  changeNome,
  descricao,
  changeDescricao
}) {

  return (
    <Dialog open={open} onOpenChange={onClose}>

      <DialogContent className="sm:max-w-[425px]">
        <DialogHeader>
          <DialogTitle>
            {action === "Criar" ? "Novo Setor" : "Editar Setor"}
            </DialogTitle>

        </DialogHeader>
        <div className="grid gap-4 py-4">
          <RowInput
            label="Nome"
            input={<Input
              onChange={changeNome}
              value={nome}
            />}
          />
          <RowInput
            label="Descrição"
            input={<Textarea
              multiline
              rows={4}
              onChange={changeDescricao}
              value={descricao}
            />}
          />
        </div>
        <DialogFooter>
          <Button onClick={onSubmit}>{action === "Criar" ? "Criar" : "Salvar"}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}
