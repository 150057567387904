import {useRef} from 'react'
import { Stack, Paper, Tooltip, OutlinedInput, IconButton } from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from 'src/components/Iconify';
// ----------------------------------------------------------------------

export default function KanbanTaskCommentInput({texto, onCriar, loading, changeTexto, completado, onFile, onKeyPress}) {
  const fileInputRef = useRef(null);

  const handleAttach = () => {
    fileInputRef.current?.click()
  };


  return (
    <Stack direction="row"  sx={{ py: 3,  background:'#fff' }}>
      {/* <MyAvatar /> */}

      <Paper variant="outlined" sx={{ p: 0.5, flexGrow: 1 }}>
        <OutlinedInput
          fullWidth
          disabled={completado ? true : false}
          value={texto}
          onChange={changeTexto}
          multiline
          onKeyPress={onKeyPress}
          rows={1}
          placeholder={completado ? "Não é possível comentar, o chamado está finalizado" : "Escreva algo..."}
          sx={{ '& fieldset': { display: 'none' } }}
        />

        {!completado && (
                  <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Stack direction="row" spacing={0.5}>
                    {/* <Tooltip title="Add Foto">
                      <IconButton onClick={handleAttach} size="small">
                      <input
                      hidden
                      className="file-input"
                      type="file"
                      ref={fileInputRef}
                      accept="image/*"
                      multiple={true}
                      onChange={e => console.log(e.target.files)}
                  />
                        <Iconify icon={'ic:round-add-photo-alternate'} width={20} height={20} />
                      </IconButton>
                    </Tooltip> */}
                    <Tooltip title="Add Arquivo">
                    <IconButton onClick={handleAttach} size="small">
                    <input
                      hidden
                      className="file-input"
                      type="file"
                      ref={fileInputRef}
                      multiple={true}
                      onChange={onFile}
                  />
                      <Iconify icon={'eva:attach-2-fill'} width={20} height={20} />
                    </IconButton>
                    </Tooltip>
                  </Stack>
        
                  <LoadingButton 
                  disabled={completado ? true : false} 
                  onClick={onCriar} 
                  loading={loading} 
                  variant="contained"
                  >
                    Comentar
                    </LoadingButton>
                </Stack>

        )}


      </Paper>
    </Stack>
  );
}
