import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/@/components/ui/table"

import {
  Stack
} from '@mui/material';

import Iconify from "src/components/Iconify";
import { Button } from "src/@/components/ui/button";
import { Badge } from "src/@/components/ui/badge";

export default function TableRespostasRapidas({ data, onEdit, onDelete }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Nome</TableHead>
          <TableHead>Descrição</TableHead>
          <TableHead>Data Criação</TableHead>
          <TableHead>Status</TableHead>
          <TableHead className="w-[100px]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.titulo}</TableCell>
            <TableCell align="left">{row.tipo}</TableCell>
            <TableCell align="left">  {row?.resposta?.substr(0, 50)}{row?.resposta?.length > 50 && '...'}</TableCell>


            <TableCell align="left">
            <Badge 
            className={`${row.ativo !== "1" ? "bg-red-500 text-white" : "bg-green-500 text-white"} border-0 rounded-sm`}
              >
                {row.ativo !== "1" ? "Desativado" : "Ativado"}
              </Badge>
            </TableCell>
            <TableCell>
              <Stack direction="row" spacing={1}>
                <Button onClick={() => onEdit(row)} variant="outline" size="icon" >
                  <Iconify icon={"ph:pencil-line"} />
                </Button>
                <Button onClick={() => onDelete(row)} variant="outline" size="icon" >
                <Iconify className="text-red-600" icon={"material-symbols:delete-sharp"} />
                </Button>
        
   
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
