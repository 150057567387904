// material
import {
    Stack, Typography, Drawer, Container, IconButton
} from '@mui/material';
import 'react-phone-number-input/style.css'
import { useState, forwardRef } from 'react';
import Iconify from 'src/components/Iconify';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import NovoContatoForm from 'src/sections/@dashboard/cadastro/NovoContatoForm';
import v2_actionCriarContato from 'src/actions/v2/contatos/v2_actionCriarContato';
import { validarNumeroWhatsapp } from 'src/utils/functions';
import { useSnackbar } from 'notistack';
import { getBuscarContatos } from 'src/redux/slices/contatos';
import { useDispatch } from 'react-redux';
import HeaderDrawer from 'src/components/modal/HeaderDrawer';
// ----------------------------------------------------------------------

export default function ModalNovoContato({
    user,
    cliente,
    open,
    listaClientesCanais,
    loading,
    callbackAddContact,
    onClose
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [loadingNovoContato, setLoadingNovoContato] = useState(false);
    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');
    const dispatch = useDispatch();

    async function handleCriarContato(data) {

        const { contato, endereco, camposPersonalizados } = data;

        setLoadingNovoContato(true);

        try {
            let data = {
                scope: {
                    nome: contato.nome,
                    telefone: validarNumeroWhatsapp(contato.telefone),
                    info: {
                        email: contato.email,
                        cpf: contato.cpf,
                        dataNascimento: contato.dataNascimento,
                        endereco
                    },
                    custom_fields: camposPersonalizados
                },
                cliente_id: cliente._id,
                usuario_id: user.id,
                checkExistContact: true
            }
            const response = await v2_actionCriarContato(data);
            if (response.status === 200) {
                if(response.data.error) {
                    if(response.data.codError === 'CONTACT_EXIST') {
                        setMensagemModalAlerta('Já existe um contato com esse número de telefone');
                        setOpenModalAlerta(true);
                        setLoadingNovoContato(false);
                        return;
                    }
                }
                setLoadingNovoContato(false);
                onClose();
                enqueueSnackbar('Contato criado com sucesso', { variant: 'success' });
                dispatch(getBuscarContatos((cliente._id)))
                callbackAddContact(response.data.data)
            }
        } catch (error) {
            console.log(error)
            setLoadingNovoContato(false);
            enqueueSnackbar('Erro ao criar contato', { variant: 'error' });
        }
    }



    function closeModalAlerta() {
        onClose();
    }

    return (
        <div>

            <Drawer
                open={open}
                onClose={closeModalAlerta}
                anchor="right"
                PaperProps={{ sx: { width: { xs: 1, sm: 500 } } }}
            >

                <ModalAlerta
                    open={openModalAlerta}
                    mensagem={mensagemModalAlerta}
                    onClose={() => {
                        setOpenModalAlerta(false);
                        setMensagemModalAlerta('');
                    }}
                />

                <HeaderDrawer
                    title={'Cadastrar Contato'}
                    close={closeModalAlerta}
                />

                <Container>
                    <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={1}>
                        <Stack spacing={3}>
                            <NovoContatoForm
                                cliente={cliente}
                                showSaveButton={false}
                                hideSelectEmpresa={true}
                                showSignupButton={true}
                                loadingNovoContato={loadingNovoContato}
                                callbackAddContact={data => {
                                    handleCriarContato(data)
                                }}
                            />
                        </Stack>
                    </Stack>

                </Container>


            </Drawer>
        </div>
    );
}
