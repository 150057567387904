// material
import {
    Alert,
    Autocomplete,
    Button,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    MenuItem, Select, Stack, Typography, Drawer, Container, IconButton
} from '@mui/material';
import './react-phone-number-input.css'
import { LoadingButton } from '@mui/lab';
import { useState, forwardRef, useEffect } from 'react';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import getActionCriarContato from 'src/actions/chats/getActionCriarContato';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import { fDate, isDateValid } from 'src/utils/formatTime';
import { formataNumeroCelular, maskCPF, maskDate, soNumeros } from 'src/utils/functions';
import actionEditarPerfil from 'src/actions/usuarios/actionEditarPerfil';
import { useSnackbar } from 'notistack';
import actionEditarSenha from 'src/actions/usuarios/actionEditarSenhaUsuario';
// ----------------------------------------------------------------------

export default function ModalEditarSenhaAgente({
    open,
    onClose,
    onCallback,
    dataUsuario
}) {

    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');
    const [loadingEditPerfil, setLoadingEditPerfil] = useState(false);
    const [dataAgente, setDataAgente] = useState({
        senha: null,
        confirmarSenha: null,
        email: null,
        usuarioId:null
    })




    useEffect(() => {
        console.log('dataUsuario', dataUsuario)

        if(dataUsuario) {
            setDataAgente({
                ...dataAgente,
                usuarioId: dataUsuario?._id,
                email: dataUsuario?.email
            })
        }


        return () => {
            setDataAgente({
                senha: null,
                confirmarSenha: null,
                usuarioId: null,
            })
        }

    }, [dataUsuario]);



    const handleEditarSenha = () => {
        actionEditarSenha({
            usuarioId: dataAgente.usuarioId,
            password: dataAgente.senha,
            repeatPassword: dataAgente.confirmarSenha,
        }).then(res => {
            onCallback();
            setDataAgente({
                senha: null,
                confirmarSenha: null,
                usuarioId: null,
            })
        }).catch(error => console.log('error', error));
    }


    return (
        <div>

            <Drawer
                open={open}
                onClose={onClose}
                anchor="right"
                PaperProps={{ sx: { width: { xs: 1, sm: 450 } } }}
            >
                <Box sx={{ p: 3 }}>
                    <ModalAlerta
                        open={openModalAlerta}
                        mensagem={mensagemModalAlerta}
                        onClose={() => {
                            setOpenModalAlerta(false);
                            setMensagemModalAlerta('');
                        }}
                    />

                    <Stack justifyContent={'space-between'} direction="row" alignItems={'center'}>
                        <Typography variant="h6">Editar Senha</Typography>
                        <IconButton onClick={onClose} >
                            <Iconify icon={'eva:close-outline'} sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    </Stack>

                    <Divider sx={{ mt: 2, mb: 2, borderStyle: 'dashed' }} />

                    <Stack sx={{mt:3}} spacing={2} direction={"column"}>
                    
                    <Stack>
                        <Typography variant="caption">Editando <span style={{fontWeight:'bold'}}>{dataAgente?.email}</span></Typography>
                    </Stack>


                    <Stack spacing={{ xs: 1, md: 1 }}>
                        <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
                            <RowInput
                                label="Senha"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, senha: e.target.value })}
                                        value={dataAgente?.senha}
                                        fullWidth
                                    />
                                }
                            />
                            <RowInput
                                label="Confirmar Senha"
                                input={
                                    <InputStyle
                                        onChange={e => setDataAgente({ ...dataAgente, confirmarSenha: e.target.value })}
                                        value={dataAgente?.confirmarSenha}
                                        fullWidth
                                    />
                                }
                            />
                        </Stack>
                    </Stack>
                    
                    </Stack>

                


                    <Stack direction="row" sx={{ mt: 5, mb: 2 }}>
                        <LoadingButton
                            loading={loadingEditPerfil}
                            onClick={handleEditarSenha}
                            sx={{ height: 30, fontSize: 12, borderRadius: 0.5 }}
                            variant="contained"
                            startIcon={<Iconify icon="material-symbols:save" />}
                        >
                            Salvar
                        </LoadingButton>
                    </Stack>

                </Box>

            </Drawer>
        </div>
    );
}
