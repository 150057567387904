// material
import {
    Alert,
    Button,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    MenuItem, Select, Stack, Typography, Drawer, Container, IconButton
} from '@mui/material';
import 'react-phone-number-input/style.css'
import { LoadingButton } from '@mui/lab';
import { useState, forwardRef } from 'react';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import getActionCriarContato from 'src/actions/chats/getActionCriarContato';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import NovoContatoForm from 'src/sections/@dashboard/cadastro/NovoContatoForm';
import v2_actionCriarContato from 'src/actions/v2/contatos/v2_actionCriarContato';
import { validarNumeroWhatsapp } from 'src/utils/functions';
import { useSnackbar } from 'notistack';
import VerContatoForm from './VerContatoForm';
import HeaderDrawer from 'src/components/modal/HeaderDrawer';
// ----------------------------------------------------------------------

export default function ModalVerContato({
    user,
    cliente,
    open,
    contatoData,
    listaClientesCanais,
    loading,
    callBackRefreshList,
    onClose
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [loadingNovoContato, setLoadingNovoContato] = useState(false);
    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');


    async function handleCriarContato(data) {
        const { contato, endereco, camposPersonalizados } = data;
        setLoadingNovoContato(true);
        try {
            let data = {
                scope: {
                    nome: contato.nome,
                    telefone: validarNumeroWhatsapp(contato.whatsapp),
                    info: {
                        email: contato.email,
                        cpf: contato.cpf,
                        dataNascimento: contato.dataNascimento,
                        endereco
                    },
                },
                custom_fiels: camposPersonalizados,
                cliente_id: cliente._id,
                usuario_id: user.id
            }
            const response = await v2_actionCriarContato(data);
            if (response.status === 200) {
                setLoadingNovoContato(false);
                onClose();
                enqueueSnackbar('Contato criado com sucesso', { variant: 'success' });
            }
        } catch (error) {
            console.log(error)
            setLoadingNovoContato(false);
            enqueueSnackbar('Erro ao criar contato', { variant: 'error' });
        }
    }



    function closeModalAlerta() {
        onClose();
    }

    return (
        <div>

            <Drawer
                open={open}
                onClose={closeModalAlerta}
                anchor="right"
                PaperProps={{ sx: { width: { xs: 500, sm: 500 } } }}
            >

                <ModalAlerta
                    open={openModalAlerta}
                    mensagem={mensagemModalAlerta}
                    onClose={() => {
                        setOpenModalAlerta(false);
                        setMensagemModalAlerta('');
                    }}
                />

                <HeaderDrawer
                    title="Ver contato"
                    close={closeModalAlerta}
                />

                <Container>
                    <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={1}>
                        <Stack spacing={3}>
                            {contatoData && (
                                <VerContatoForm
                                    callBackRefreshList={callBackRefreshList}
                                    contatoData={contatoData}
                                    cliente={cliente}
                                    showSaveButton={true}
                                    hideSelectEmpresa={true}
                                    showSignupButton={false}
                                    loadingNovoContato={loadingNovoContato}
                                    callbackAddContact={data => handleCriarContato(data)}
                                />
                            )}
                        </Stack>
                    </Stack>
                </Container>
            </Drawer>
        </div>
    );
}
