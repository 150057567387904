import axios from "axios";
import Config from "src/config";

const v1_actionEditarFlow = (flowId, data) => {
    
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/flows/${flowId}/editar`,
        data
    });
};

export default v1_actionEditarFlow;
