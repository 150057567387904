import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/@/components/ui/table"

import {
  Stack
} from '@mui/material';

import Iconify from "src/components/Iconify";
import { fDate } from "src/utils/formatTime";
import { Button } from "src/@/components/ui/button";
import { Badge } from "src/@/components/ui/badge";

export default function TableRespostasRapidas({ data, onDelete }) {
  return (
    <Table className>
      <TableHeader>
        <TableRow>
          <TableHead>Nome</TableHead>

          <TableHead className="w-[100px]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.name}</TableCell>
            <TableCell>
              <Stack direction="row" spacing={1}>
               
                <Button onClick={() => onDelete(row)} variant="outline" size="icon" >
                <Iconify className="text-red-600" icon={"material-symbols:delete-sharp"} />
                </Button>
        
   
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
