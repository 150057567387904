
import { Box, Button, Divider, Collapse, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';
import LabelInput from 'src/components/LabelInput';
import { fDateTime } from 'src/utils/formatTime';
import ChatInfoNotasInput from './ChatInfoNotasInput';
import actionCriarNotas from 'src/actions/chats/notas/actionCriarNotas';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import {useEffect} from 'react';

const RowInputInfo = ({ label, input }) => {
    return (
        <Stack spacing={0.5} direction="column">
            <LabelInput>{label}</LabelInput>
            {input}
        </Stack>
    )
}

// ----------------------------------------------------------------------

ChatInfoNotas.propTypes = {
    isCollapse: PropTypes.bool,
    onCollapse: PropTypes.func
};

export default function ChatInfoNotas({ chat, user }) {
    const [text , setText] = useState('')
    const {enqueueSnackbar} = useSnackbar();
    const [notas , setNotas] = useState(chat && chat.notas ? chat.notas : [])

    useEffect(() => {
      return () => {
        setNotas([])
      }
    }, [])
    
    return (
        <Stack>
            <Scrollbar sx={{    height: {
                    xs: `calc(25vh - 150px)`,
                    sm: `calc(35vh - 150px)`,
                    md: `calc(60vh - 125px)`,
                    lg: `calc(75vh - 125px)`,
                    xl: `calc(82vh - 125px)`
                }, }}>
                {chat.notas?.map((nota, index) => (
                    <Box key={index} sx={{ p: 2 }}>
                        <Typography sx={{ fontSize: 10, fontWeight: '800' }}> {fDateTime(nota.date)} </Typography>
                        <Typography variant='body' sx={{ fontSize: 12 }}> {nota.body} </Typography>

                    </Box>
                ))}
 
            </Scrollbar>
            {/* <ChatInfoNotasInput text={text} changeText={e => setText(e.target.value)} onSend={novaNota} /> */}
        </Stack>

    );
}
