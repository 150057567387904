// material
import { alpha, styled } from '@mui/material/styles';
import { MenuItem, Typography, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';

import { Button } from "src/@/components/ui/button"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/@/components/ui/popover"
import Iconify from './Iconify';

import { useState } from 'react';

// ----------------------------------------------------------------------

const ArrowStyle = styled('span')(({ theme }) => ({

    [theme.breakpoints.up('sm')]: {
        top: -7,
        zIndex: 1,
        width: 12,
        right: 20,
        height: 12,
        content: "''",
        position: 'absolute',
        borderRadius: '0 0 4px 0',
        transform: 'rotate(-135deg)',
        background: theme.palette.background.paper,
        borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
        borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`
    }
}));

// ----------------------------------------------------------------------

MenuDatas2.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object
};

export default function MenuDatas2({
    children,
    sx,
    inicial,
    final,
    changeInicial,
    changeFinal,
    onSelect,
    onClose,
    onPesquisar,
    content,
    ...other
}) {



    const [open, setOpen] = useState(null);


    function handleSelectPeriod(period) {
        onSelect(period);
        setOpen(null);
    }

    return (
        <Popover open={open} onOpenChange={() => setOpen(true)}>
          <PopoverTrigger asChild>
            <Button 
            variant="outline"
            className="mr-5 h-9" 
            >
            <Iconify className="mr-1" icon="solar:calendar-date-bold-duotone" />
                {content}
                </Button>
          </PopoverTrigger>
          <PopoverContent className="w-80">
          
          <MenuItem key={'mes_atual'} onClick={() => handleSelectPeriod('mes_atual')} sx={{ typography: 'body2', py: 1, px: 2.5 }} >
                Esse mês
            </MenuItem>
            <MenuItem key={'mes_anterior'} onClick={() => handleSelectPeriod('mes_anterior')} sx={{ typography: 'body2', py: 1, px: 2.5 }} >
                Mês anterior
            </MenuItem>
            <MenuItem key={'ultimos_90_dias'} onClick={() => handleSelectPeriod('ultimos_90_dias')} sx={{ typography: 'body2', py: 1, px: 2.5 }} >
                Ultimos 3 meses
            </MenuItem>

            <Stack spacing={2} sx={{ mt: 2 }}>
                <Stack spacing={2} direction={"column"}>
                    <DatePicker
                        label="Data Inicial"
                        inputFormat="dd/MM/yyyy"
                        value={inicial}
                        minDate={new Date('2022-01-01')}
                        onChange={(newValue) => {
                            changeInicial(newValue);
                        }}
                        renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
                    />
                    <DatePicker
                        label="Data final"
                        inputFormat="dd/MM/yyyy"
                        value={final}
                        minDate={new Date('2022-01-01')}
                        onChange={(newValue) => {
                            changeFinal(newValue);
                        }}
                        renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
                    />

                </Stack>
                <Button onClick={() => {
                    onPesquisar();
                    setOpen(null);
                }}
                >                    Pesquisar
                    </Button>
            </Stack>
          </PopoverContent>
        </Popover>
      )
    // return (
    //     <Popover
    //         anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
    //         transformOrigin={{ vertical: 'top', horizontal: 'right' }}
    //         PaperProps={{
    //             sx: {
    //                 mt: 1.5,
    //                 ml: 0.5,
    //                 overflow: 'inherit',
    //                 boxShadow: (theme) => theme.customShadows.z20,
    //                 border: (theme) => `solid 1px ${theme.palette.grey[500_8]}`,
    //                 width: 200,
    //                 p: 1,
    //                 ...sx
    //             }
    //         }}
    //         {...other}
    //     >
    //         <ArrowStyle className="arrow" />

    //         <MenuItem key={'mes_atual'} onClick={() => onSelect('mes_atual')} sx={{ typography: 'body2', py: 1, px: 2.5 }} >
    //             Esse mês
    //         </MenuItem>
    //         <MenuItem key={'mes_anterior'} onClick={() => onSelect('mes_anterior')} sx={{ typography: 'body2', py: 1, px: 2.5 }} >
    //             Mês anterior
    //         </MenuItem>
    //         <MenuItem key={'ultimos_90_dias'} onClick={() => onSelect('ultimos_90_dias')} sx={{ typography: 'body2', py: 1, px: 2.5 }} >
    //             Ultimos 3 meses
    //         </MenuItem>

    //         <Stack spacing={2} sx={{ mt: 2 }}>
    //             <Stack spacing={2} direction={"column"}>
    //                 <DatePicker
    //                     label="Data Inicial"
    //                     inputFormat="dd/MM/yyyy"
    //                     value={inicial}
    //                     minDate={new Date('2022-01-01')}
    //                     onChange={(newValue) => {
    //                         changeInicial(newValue);
    //                     }}
    //                     renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
    //                 />
    //                 <DatePicker
    //                     label="Data final"
    //                     inputFormat="dd/MM/yyyy"
    //                     value={final}
    //                     minDate={new Date('2022-01-01')}
    //                     onChange={(newValue) => {
    //                         changeFinal(newValue);
    //                     }}
    //                     renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
    //                 />

    //             </Stack>
    //             <Button onClick={onPesquisar} variant="contained">Pesquisar</Button>
    //         </Stack>

    //     </Popover>
    // );
}



