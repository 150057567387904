import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useCallback } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    Avatar,
    Tooltip,
    Divider,
    MenuItem,
    TextField,
    Typography,
    OutlinedInput,
    Grid,
    Container,
    Card,
    Tab,
    Tabs,
    Autocomplete,
    RadioGroup,
    Radio,
    FormControl,
    FormControlLabel,
    IconButton,
    Menu
} from '@mui/material';
import {Button} from 'src/@/components/ui/button';  
import { useDispatch } from 'react-redux';


import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import DrawerNegocioHead from './components/DrawerNegocioHead';
import DrawerNegocioStagesPipeline from './components/DrawerNegocioStagesPipeline';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { RowInput } from 'src/components/forms/RowInput';
import { TableBorder } from 'src/components/table';
import DrawerNegocioNotas from './components/DrawerNegocioNotas';
import InputStyle from 'src/components/InputStyle';
import DrawerNegocioTimeline from './components/DrawerNegocioTimeline';
import { useSnackbar } from 'notistack';
import v2_actionEditarLead from 'src/actions/v2/crm/v2_actionEditarLead';
import LabelIcon from 'src/components/LabelIcon';
import DrawerContatos from './components/DrawerContatos';
import DrawerChatWhatsapp from 'src/sections/@chats/chat/DrawerChatWhatsapp';
import { getChatInfoByChatId, getMensagensWhatsappDb, setChatInfo } from 'src/redux/slices/chat';
import v2_actionDetalhesLead from 'src/actions/v2/crm/v2_actionDetalhesLead';
import v2_actionDeletarLead from 'src/actions/v2/crm/v2_actionDeletarLead';
import ModalConfimacao from 'src/components/modal/ModalConfimacao';
import Scrollbar from 'src/components/Scrollbar';
import { fDate, fDateTime, fTime, fToNow } from 'src/utils/formatTime';


const MenuMarcarNegociacao = ({ anchorEl, onClose, open, onSelect }) => {
    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            sx={{}}

            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <MenuItem sx={{ minWidth: 120 }} onClick={() => onSelect(1)}>
                <Stack alignItems={"center"} spacing={1} direction={"row"}>
                    <Iconify color="green" icon="mdi:cash-plus" />
                    <Typography color="green" variant="subtitle2">Venda</Typography>
                </Stack>
            </MenuItem>
            <MenuItem onClick={() => onSelect(2)}>
                <Stack alignItems={"center"} spacing={1} direction={"row"}>
                    <Iconify color="red" icon="mingcute:sad-fill" />
                    <Typography color="red" variant="subtitle2">Perda</Typography>
                </Stack>
            </MenuItem>
        </Menu>
    )
}


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ px: 0.5, py: 1 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

DrawerNegocio.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    chamado: PropTypes.object,
    comentarios: PropTypes.array
};

const RowItemHeadDate = ({ title, value }) => {
    return (
        <Stack direction={"column"} spacing={0.2}>
            <Typography variant="extraSmall" >{title}</Typography>
            <Typography fontWeight={'600'} sx={{fontSize:11}}>{value}</Typography>
        </Stack>
    )
}

export default function DrawerNegocio({
    configCrm,
    open,
    onClose,
    lead,
    callbackRefreshLeads
}) {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch()
    const [leadData, setLeadData] = useState(null)
    const { user, cliente } = useAuth();
    const [tabDetalhesChamado, setTabDetalhesChamado] = useState(0)
    const [conversaoLead, setConversaoLead] = useState(leadData?.convertido)
    const handleChangeTabsDetalhes = (event, newValue) => {
        setTabDetalhesChamado(newValue);
    };
    const [anchorMarcarNegociacao, setAnchorMarcarNegociacao] = useState(null);
    const openAnchorMarcarNegociacao = Boolean(anchorMarcarNegociacao);
    const [isEditNomeLead, setIsEditNomeLead] = useState(false);
    const [openDrawerChatWhatsapp, setOpenDrawerChatWhatsapp] = useState(false);
    const [openModalConfirmacaoExclusao, setOpenModalConfirmacaoExclusao] = useState(null);
    const [loadingExclusaoLead, setLoadingExclusaoLead] = useState(null);
    // const [chat, setChat] = useState(null);
    const [nomeLead, setNomeLead] = useState(null);



    function setar(lead) {
        setLeadData(lead)
        setNomeLead(lead?.nome)

    }

    useEffect(() => {
        setar(lead)
    }, [lead, open]);

    async function buscarDetalhesLead(id) {
        try {
            const result = await v2_actionDetalhesLead(id);
            if (result.data.data) {
                setLeadData(result.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }




    async function editarLead(action, value) {

        if (action === "editar_conversao") {
            let data = {
                action: action,
                scope: {
                    convertido: value ? Number(value) : null,
                }
            }
            setConversaoLead(value)
            await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Negócio alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } if (action === "editar_contato") {

            let data = {
                action: action,
                scope: {
                    contato_id: value,
                }
            }
            await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Contato alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } if (action === "editar_nome_lead") {

            let data = {
                action: action,
                scope: {
                    nome: value,
                }
            }
            await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Lead alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        }

        setTimeout(() => {
            buscarDetalhesLead(lead._id);
            callbackRefreshLeads()
        }, 1000);

   

    }

    const labels = {
        1: 'Marcado como venda',
        2: 'Marcado como perda',
    };


    async function excluirLead(id) {
        setLoadingExclusaoLead(true)
        try {
            await v2_actionDeletarLead(id);
            onClose();
            callbackRefreshLeads();
            setLoadingExclusaoLead(false)
        } catch (error) {
            setLoadingExclusaoLead(false)
            console.log(error);
        }
    }


    return (

        <Drawer sx={{}} open={open} onClose={onClose} anchor="right" PaperProps={{ sx: { overflow: 'hidden', width: { xs: '95%', sm: '95%', md: '95%', lg: '90%', xl: '80%' } } }}>

            <DrawerChatWhatsapp
                open={openDrawerChatWhatsapp}
                onClose={() => {
                    setOpenDrawerChatWhatsapp(false)
                }}
                cliente={cliente}
                user={user}
            />

            <ModalConfimacao
                open={openModalConfirmacaoExclusao}
                onClose={() => setOpenModalConfirmacaoExclusao(false)}
                onConfirm={() => excluirLead(lead?._id)}
                loadingFinalizar={loadingExclusaoLead}
                mensagem={'Deseja mesmo excluir esse lead?'}
            />

            <Container maxWidth>
                <Stack spacing={2} sx={{ my: 2 }} justifyContent={'space-between'} alignItems={'center'} direction={"row"}>
                    <Stack spacing={1} alignItems={'center'} direction={"row"}>
                        <Button
                        variant='outline'
                        size="icon"
                        onClick={onClose}
                        >
                        <Iconify width={22} icon="material-symbols:arrow-back-ios-new" />

                        </Button>
            
                        <Stack spacing={1} direction="row">


                            {isEditNomeLead ? (
                                <Stack alignItems={"center"} direction="row" spacing={1}>
                                    <InputStyle sx={{ width: 400 }} value={nomeLead} onChange={e => setNomeLead(e.target.value)} size="medium" />
                                    <IconButton onClick={() => {
                                        editarLead('editar_nome_lead', nomeLead)
                                    }}>
                                        <Iconify width={22} icon="el:ok-sign" />
                                    </IconButton>
                                </Stack>

                            ) : (
                                <Stack alignItems={"center"} direction="row" spacing={1}>
                                    <Typography variant="subtitle1">{leadData?.nome}</Typography>
                                    <IconButton onClick={() => setIsEditNomeLead(true)}>
                                        <Iconify width={18} icon="mdi:pencil" />
                                    </IconButton>
                                </Stack>

                            )}


                        </Stack>



                    </Stack>
                    <Stack spacing={4} alignItems={"center"} direction={"row"}>
                        <RowItemHeadDate
                            title="Criado em"
                            value={lead?.data_criacao && fDateTime(lead?.data_criacao)}
                        />
                        <RowItemHeadDate
                            title="Ultima Atualização"
                            value={lead?.data_modificacao && fToNow(lead?.data_modificacao)}
                        />

                        {leadData?.convertido ? (
                            <Stack alignItems="center" direction={"row"} spacing={1}>
                                <Button
                                    onClick={async () => {
                                        await editarLead('editar_conversao', null)
                                        callbackRefreshLeads()
                                        setAnchorMarcarNegociacao(null)
                                    }}
                                    variant="outline"
        
                                >
                                    <Iconify width={10} height={10} icon="akar-icons:arrow-back-thick-fill" />
                                </Button>
                                <LabelIcon sx={{ height: 20 }} color={leadData?.convertido === 1 ? "success" : "error"} icon="mdi:fire"><Typography variant='caption' sx={{ p: 1 }}>{labels[leadData?.convertido]}</Typography></LabelIcon>
                            </Stack>
                        ) : (
                            <>
                                <Button
                                    variant="outline"
                                    onClick={event => {
                                        setAnchorMarcarNegociacao(event.currentTarget);
                                    }}
                                    aria-controls={openAnchorMarcarNegociacao ? 'basic-menu' : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={openAnchorMarcarNegociacao ? 'true' : undefined}
                                >
                                   
                                    Marcar
                                    <Iconify width={10} height={10} icon="bxs:down-arrow" />
                                </Button>
                                <MenuMarcarNegociacao
                                    onSelect={async (value) => {
                                        await editarLead('editar_conversao', value)
                                        callbackRefreshLeads()
                                        setAnchorMarcarNegociacao(null)
                                    }}
                                    open={openAnchorMarcarNegociacao}
                                    anchorEl={anchorMarcarNegociacao}
                                    onClose={() => setAnchorMarcarNegociacao(null)}
                                />

                            </>

                        )}

                        <Button
                        variant='outline'
                        size="icon"
                        onClick={() => setOpenModalConfirmacaoExclusao(true)}>
                        <Iconify icon="ic:baseline-delete" />
                        </Button>

                    </Stack>
                </Stack>
            </Container>

            <Box sx={{ height: '90vh', p: 1 }}>

                <Stack spacing={2} direction={"column"}>

                    <Stack>
                        <Box>
                            <DrawerNegocioHead
                                lead={leadData}
                                configCrm={configCrm}
                                user={user}
                                cliente={cliente}
                                callbackRefreshLeads={callbackRefreshLeads}
                                callBackRefreshLead={() => buscarDetalhesLead(lead._id)}
                                onOpenChatWhatsapp={value => {
                                    try {
                                        // setChat(value)
                                        setOpenDrawerChatWhatsapp(true)
                                        dispatch(getMensagensWhatsappDb(value.sessao, value.numero, 1, 10));
                                        dispatch(setChatInfo(value));
                                        dispatch(getChatInfoByChatId(value._id, value.funil_id, user.id, true));
                                    } catch (error) {
                                        console.log(error)
                                    }
                                }}
                            
                            />
                        </Box>
                    </Stack>

                    <Container maxWidth>


                        <Box sx={{
                            // height: '100%' 
                        }}>

                            <Box sx={{

                                px: 0,
                                borderRadius: 1
                            }}>
                                <Tabs value={tabDetalhesChamado} scrollButtons onChange={handleChangeTabsDetalhes} aria-label="basic tabs example">
                                    <Tab label="Contatos" {...a11yProps(0)} />
                                    <Tab label="Atividades" {...a11yProps(1)} />
                                    <Tab label="Comentários" {...a11yProps(2)} />
                                    {/* <Tab label="Arquivos" {...a11yProps(1)} /> */}


                                </Tabs>
                            </Box>

                            <TabPanel value={tabDetalhesChamado} index={0}>
                                <DrawerContatos
                                user={user}
                                    cliente={cliente}
                                    lead={leadData}
                                    onEditarContatoId={value =>  editarLead('editar_contato', value)}
                                    callbackCreateContact={() => {
                                        setTimeout(() => {
                                            buscarDetalhesLead(lead._id);
                                            callbackRefreshLeads()
                                        }, 1000);
                                
                                    }}

                                />
                            </TabPanel>
                            <TabPanel value={tabDetalhesChamado} index={1}>
                                <Scrollbar sx={{
                                    height: {
                                        xs: `calc(30vh - 100px)`,
                                        sm: `calc(40vh - 100px)`,
                                        md: `calc(45vh - 100px)`,
                                        lg: `calc(55vh - 100px)`
                                    },
                                    mb: 3
                                }}>
                                    <DrawerNegocioTimeline data={leadData && leadData.historico} />
                                </Scrollbar>

                            </TabPanel>
                            <TabPanel value={tabDetalhesChamado} index={2}>
                                <DrawerNegocioNotas refreshLead={() => buscarDetalhesLead(lead._id)} user={user} dataLead={leadData} />
                            </TabPanel>
                        </Box>

                    </Container>

                </Stack>

            </Box>

        </Drawer >


    );
}
