// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// components
import Label from 'src/components/Label';
import SvgIconStyle from 'src/components/SvgIconStyle';

import { BsChatText } from "react-icons/bs";
import { HiOutlineUserGroup } from "react-icons/hi";
import Iconify from 'src/components/Iconify';
import * as COLORS from '../../../src/theme/palette'

// ----------------------------------------------------------------------

const getIcon = (name) => <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  booking: getIcon('ic_booking'),
  invoice: getIcon('ic_invoice'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
};

const navConfig = [

  // {
  //   subheader: 'app',
  //   items: [
  //     {
  //       title: 'mail',
  //       path: '/',
  //       icon: ICONS.mail,
  //       info: (
  //         <Label variant="outlined" color="error">
  //           +32
  //         </Label>
  //       ),
  //     },
  //     { title: 'conversas', path:'/', icon: ICONS.chat },
  //     { title: 'chat interno', path:'/', icon: ICONS.calendar },
  //     { title: 'kanban', path:'/', icon: ICONS.kanban },
  //   ],
  // },
  {
 
    items: [

      { title: 'Conversas', path:'/chats/chat', nameIcon:<Iconify color={'#005249'} icon={'gridicons:chat'}  width={28} height={28} />, icon: ICONS.chat },
      { title: 'chat interno', path:'/chats/staff', nameIcon:<Iconify color={'#005249'} icon={'fluent:people-team-28-filled'}  width={28} height={28} />, icon: ICONS.calendar },
      { title: 'Agendamentos', path:'/chats/agendamentos', nameIcon:<Iconify color={'#005249'} icon={'mdi:clock'}  width={28} height={28} />, icon: ICONS.calendar },
  
    ],
   
   
    },

  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'Marketing',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       nameIcon:<Iconify color={'white'} icon={'ph:megaphone-light'}  width={24} height={24} />,
  //       children: [
  //         { title: 'Agendamentos', path:'/chats/agendamentos', icon: ICONS.kanban },
  //         { title: 'Campanhas', path:'/dashboard/campanhas',  icon: ICONS.kanban },
        
  //       ],
  //     },

  //   ],
  // },
  // {
  //   subheader: 'management',
  //   items: [
  //     // USER
  //     {
  //       title: 'Gerência',
  //       path: PATH_DASHBOARD.user.root,
  //       icon: ICONS.user,
  //       nameIcon:<Iconify color={'white'} icon={'ph:megaphone-light'}  width={24} height={24} />,
  //       children: [
  //         { title: 'Relatórios', path:'/dashboard/app', icon: ICONS.kanban },
  //         { title: 'Supervisão', path:'/dashboard/supervisionar',  icon: ICONS.kanban },
        
  //       ],
  //     },

  //   ],
  // },

];

export default navConfig;
