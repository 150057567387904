import { useState } from 'react';
import PropTypes from 'prop-types';
// mui
import { List } from '@mui/material';
// hooks
import useAuth from 'src/hooks/useAuth';
//redux
import { useDispatch } from 'src/redux/store';
import { getChatsAtendimento, getChatsGeral, getChatsPendente, getCountsChats, removeChatFromList } from 'src/redux/slices/chat';
//actions
import getActionAceitarChat from 'src/actions/chats/getActionAceitarChat';
import getActionChatByChatId from 'src/actions/chats/getActionChatByChatId';
//itens
import ChatAtendimentoItem from '../ChatsListItem/ChatAtendimentoItem';

ChatConversationListPendente.propTypes = {
  isOpenSidebar: PropTypes.bool
};

export default function ChatConversationListPendente({ chats, isOpenSidebar, ...other }) {
  const dispatch = useDispatch();
  const {user} = useAuth()
  const [loading, setLoading] = useState(false);


  const handleAceitar = async (chatId) => {
    setLoading(true);
    try {
      const res_chat = await getActionChatByChatId({chatId:chatId});
      if(res_chat.data.statusRoom !== 'Atendendo') {
         dispatch(removeChatFromList({chatId:chatId, status:'Pendente'}))
         await getActionAceitarChat({chatId:chatId, usuarioId:user.id});
         dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
         dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
         dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, true));
         dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));
         setLoading(false);
      }
    } catch(error) {
      console.log(error)
      setLoading(false);
    }

};

  return (
    <List disablePadding {...other}>
      {chats.map((chat) => (
        <ChatAtendimentoItem
          key={chat._id}
          chat={chat}
          loading={loading}
          isOpenSidebar={isOpenSidebar}
          onSelectConversation={() => {}}
          onAceitarChat={() => handleAceitar(chat._id)}
        />
      ))}
    </List>
  );
}
