import {
  Container
} from '@mui/material';
import { useEffect, useState } from 'react';
// redux
import Page from 'src/components/Page';
// components
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';

import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import Iconify from 'src/components/Iconify';
import TableTags from './TableTags';
import { CardContent, Card } from 'src/@/components/ui/card';
import ModalTags from './ModalTags';

import { Button } from 'src/@/components/ui/button';

///
import v2_actionListaTagsChats from 'src/actions/v2/chats/v2_actionListaTagsChats';
import v2_actionCriarTag from 'src/actions/v2/chats/v2_actionCriarTag';
import v2_actionEditarTag from 'src/actions/v2/chats/v2_actionEditarTag';
import ModalConfimacao from 'src/components/modal/ModalConfimacao';
import v2_actionDeletarTag from 'src/actions/v2/chats/v2_actionDeletarTag';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'nome', label: 'Nome', alignRight: false },
  { id: 'data', label: 'Data Criação', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

export default function TagsComponent() {
  const { user } = useAuth();
  const [openDialogNovoSetor, setOpenDialogNovoSetor] = useState(false);
  const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('');
  ///// value fields
  const [tagId, setTagId] = useState('');
  const [tituloEdit, setTituloEdit] = useState('');
  const [listaTags, setListaTags] = useState([]);

  const [nome, setNome] = useState('');

  async function buscarListaTags() {
    try {
      let querys = `?cliente_id=${user.clienteId}`
      const response = await v2_actionListaTagsChats(querys);
      setListaTags(response.data.data)
    } catch (error) {

    }
  }

  useEffect(() => {
    buscarListaTags()
  }, []);

  function applyFilter(tableData, filterName) {
    if (filterName) {
      tableData = tableData.filter(
        (item) =>
          item.nome.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      );
    }
    return tableData;
  }

  async function handleCriarTag() {
    try {
      setLoading(true)
      const data = {
        nome: nome,
        usuario_id: user.id,
        cliente_id: user.clienteId
      }
      await v2_actionCriarTag(data);
      await buscarListaTags();
      setLoading(false)
      setOpenDialogNovoSetor(false);
      setNome('');
      setTagId('');
    } catch (error) {
      setLoading(false)
    }
  }

  async function handleDeletarTag() {
    try {
      await v2_actionDeletarTag(tagId);
      await buscarListaTags();
      setNome('');
      setTagId('');
      setOpenModalConfirmacao(false);

    } catch (error) {

    }

  }




  const handleEditarTag = async () => {
    try {
      await v2_actionEditarTag(tagId, { nome: nome });
      await buscarListaTags();
      setAction('');
      setNome('');
      setTagId('');
      setOpenDialogNovoSetor(false);
    } catch (error) {
      console.log('error', error)
    }

  };


  const dataFiltered = applyFilter(listaTags, pesquisa);

  return (
    <Page title={`${nomeProjeto} - Agentes`}>

      <Container fullWidth maxWidth={"lg"}  >
        <HeaderPageComponentConfig
          titulo="Etiquetas"
          descricao={'Crie etiquetas para organizar suas conversas.'}
          actions={<Button
            onClick={() => {
              setOpenDialogNovoSetor(true);
              setAction('Criar')
            }}
          >
            Criar Etiqueta
          </Button>}
        />

        <ModalConfimacao
          open={openModalConfirmacao}
          onClose={() => {
            setOpenModalConfirmacao(false);
          }}
          onConfirm={handleDeletarTag}
          loadingFinalizar={loading}
          mensagem={'Deseja mesmo excluir essa etiqueta?'}
        />

        <ModalTags
          open={openDialogNovoSetor}
          action={action}
          nome={nome}
          changeNome={(e) => {
            setNome(e.target.value)
          } }
          onSubmit={action === "Criar" ? handleCriarTag : handleEditarTag }
          onClose={() => {
            setOpenDialogNovoSetor(false);
            setNome('');
            setTagId('');
          }}
        />

        <Card className="mt-8">
          <CardContent>
            <TableTags
              data={dataFiltered}
              onDelete={row => {
                setTagId(row._id);
                setOpenModalConfirmacao(true);
              }}
              onEdit={row => {
                setOpenDialogNovoSetor(true)
                setAction('Editar')
                setTagId(row._id);
                setNome(row.nome);
              }}
            />
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
}
