import PropTypes from 'prop-types';
// @mui
import {IconButton, Stack, Tooltip, Checkbox } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { formataNumeroCelular } from 'src/utils/functions';
import { fDateTime } from 'src/utils/formatTime';
import { Button } from 'src/@/components/ui/button';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import { Label } from "src/@/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/@/components/ui/popover"

import { TableCell, TableRow } from 'src/@/components/ui/table';



export default function VendasRowTable({
  row,
  index,
  onVerPedido
}) {

  return (
    <TableRow key={index} hover>

      <TableCell >
        {row?.id_pedido}
      </TableCell>

      <TableCell >
        {row?.cliente?.nome}
      </TableCell>

      <TableCell >
        {row?.valor_total?.total}
      </TableCell>

  

      <TableCell >
        {row?.produtos.length}
      </TableCell>


      <TableCell >
        {fDateTime(row?.data_criacao)}
      </TableCell>

      <TableCell sx={{ p: 0.5 }} >
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Tooltip title="Ver contato">
            <Button variant="outline" size="icon" onClick={onVerPedido}>
              <Iconify color="black" icon="hugeicons:view" width={18} height={18} />
            </Button>
          </Tooltip>

        </Stack>

      </TableCell>

    </TableRow>
  );
}
