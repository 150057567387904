import { Container, Stack } from "@mui/material"
import { Button } from "src/@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "src/@/components/ui/card"
import { Input } from "src/@/components/ui/input"
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "src/@/components/ui/tabs"
import { HeaderPageComponentConfig } from "src/components/_dashboard/configuracoes";


const CharacterQuota = () => {
  return (
    <div className="bg-slate-100 p-4 rounded-md w-full">
      <div className="text-sm font-medium text-gray-500">Tokens de texto</div>
      <div className="flex justify-between items-center mt-2">
        <div className="text-gray-800">
        <div>Total</div>
          <div>Gasto</div>
          <div>Restantes</div>
        </div>
        <div className="text-right">
          <div className="text-lg font-semibold text-gray-800">10000</div>
          <div className="text-lg font-semibold text-gray-800">29,563</div>
          <div className="text-lg font-semibold text-gray-800">2200</div>

        </div>
      </div>
      <div className="flex items-center mt-4">
        <div className="flex-grow bg-gray-300 h-2 rounded-full">
          <div className="bg-violet-900 h-2 rounded-full" style={{ width: '1%' }}></div>
        </div>
        <div className="text-gray-500 ml-2">1%</div>
      </div>

    </div>
  );
};

const CharacterQuotaAudio = () => {
  return (
    <div className="bg-slate-100 p-4 rounded-md w-full">
      <div className="text-sm font-medium text-gray-500">Tokens de audio</div>
      <div className="flex justify-between items-center mt-2">
        <div className="text-gray-800">
          <div>Total</div>
          <div>Gasto</div>
          <div>Restantes</div>
        </div>
        <div className="text-right">
          <div className="text-lg font-semibold text-gray-800">3000</div>
          <div className="text-lg font-semibold text-gray-800">800</div>
          <div className="text-lg font-semibold text-gray-800">2200</div>
        </div>
      </div>
      <div className="flex items-center mt-4">
        <div className="flex-grow bg-gray-300 h-2 rounded-full">
          <div className="bg-violet-900 h-2 rounded-full" style={{ width: '30%' }}></div>
        </div>
        <div className="text-gray-500 ml-2">30%</div>
      </div>

    </div>
  );
};


export function ConsumoComponent() {
  return (
    <Container>
      <HeaderPageComponentConfig
        titulo="Consumo"
      />
      <div className="mt-5">
      <Stack direction={"row"} spacing={1}>
                <CharacterQuota />
                {/* <CharacterQuotaAudio /> */}
              </Stack>

      </div>
    </Container>
  )
}
