import { Typography, Stack, Box, IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Iconify from 'src/components/Iconify';
import { useState } from 'react';
import PipileneStageItem from './PipelineStageItem';
import v2_actionEditarPipeline from 'src/actions/v2/crm/v2_actionEditarPipeline';
import PopoverCriarEstagio from './PopoverCriarEstagio';
import v2_actionDeletarPipelineStage from 'src/actions/v2/crm/v2_actionDeletarPipelineStage';
// material
import { Button } from 'src/@/components/ui/button';
import { Card, CardContent } from 'src/@/components/ui/card';
// ----------------------------------------------------------------------


export default function PipileneItem({
  index,
  pipelines,
  item,
  onCallback,
  user,
  cliente,
  onEdit,
  onDelete,
  ...other
}) {
  const [funis, setFunis] = useState([pipelines]);
  const [pipelineId, setPipelineId] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [isEdit, setIsEdit] = useState(null);

  function reorder(list, startIndex, endIndex) {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
    return result;
  }

  const [estagios, setEstagios] = useState(item?.estagios);


  async function handleEditarPipilene(orders) {
    try {
      let data = {
        action: "edit_order_pipeline_stage",
        scope: {
          orderIds: orders
        }
      }
      await v2_actionEditarPipeline(item.pipeline_id, data, cliente._id);
      onCallback();

    } catch (error) {

    }

  }


  async function handleDeletePipelineStage(id) {
    try {
      await v2_actionDeletarPipelineStage(id);
      onCallback();

    } catch (error) {

    }

  }


  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedEstagios = Array.from(estagios);
    const [movedItem] = reorderedEstagios.splice(result.source.index, 1);
    reorderedEstagios.splice(result.destination.index, 0, movedItem);

    setEstagios(reorderedEstagios);
    handleEditarPipilene(reorderedEstagios);

    // Aqui você pode enviar os _ids e a nova ordenação para o backend ou realizar outras ações
    const updatedOrder = reorderedEstagios.map(item => item._id);
    console.log('Nova ordenação dos _ids:', updatedOrder);
  };


  return (
    <Card className="bg-slate-50">
      <CardContent className="p-6">
        <Stack spacing={2} direction="column">
          <Stack justifyContent={"space-between"} direction={"row"} alignItems={"center"} spacing={1}>

            <Typography variant='subtitle2'>{item.pipeline_nome} </Typography>

            <Stack alignItems={"center"} direction={"row"} spacing={1}>
              <Button size="icon" className="h-6 w-6" variant="outline" aria-label="add" onClick={() => onEdit(item)}>
                <Iconify icon="material-symbols:edit-outline" />
              </Button>
              <Button className=" h-6 w-6" size="icon" variant="outline" aria-label="add" onClick={() => onDelete(item)}>
                <Iconify color="red" icon="material-symbols:delete" />
              </Button>
            </Stack>
          </Stack>
          <Stack>

            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="funis">
                {(provided) => (
                  <ul style={{ listStyleType: 'none' }} {...provided.droppableProps} ref={provided.innerRef}>
                    <Stack spacing={1} direction="column">
                      
                      {item?.estagios?.map((funil, index) => (
                        <Draggable key={funil._id} draggableId={funil._id} index={index}>
                          {(provided) => (
                            <li
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                            >
                              <PipileneStageItem
                                user={user}
                                cliente={cliente}
                                onEdit={item => {
                                  setPipelineId(item._id)
                                  setIsEdit(true)
                                  setAnchorEl(true)

                                }}
                                onDelete={item => {
                                  handleDeletePipelineStage(item._id)
                                }}
                                onCallback={onCallback}
                                funil={funil} />
                            </li>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </Stack>
                  </ul>
                )}
              </Droppable>
            </DragDropContext>

          </Stack>
          <Button
            size="icon"
            className="w-full"
            aria-describedby={id}
            onClick={e => {
              setPipelineId(item._id)
              setIsEdit(false)
              setAnchorEl(e.currentTarget)

            }}>
            <Iconify icon="akar-icons:plus" />
          </Button>
          <PopoverCriarEstagio
            id={id}
            cliente={cliente}
            user={user}
            pipelineId={pipelineId}
            isEdit={isEdit}
            anchorEl={anchorEl}
            open={open}
            onClose={() => setAnchorEl(null)}
            onCallback={onCallback}
          />
        </Stack>


      </CardContent>
    </Card>
  );
}
