import axios from "axios";
import Config from "src/config";

const actionDeletarRespostaRapida = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/cliente/respostas/deletar`,
        data
    });
};

export default actionDeletarRespostaRapida;
