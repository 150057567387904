
import {
  Box,
  Grid,
  Stack,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { formataNumeroCelular } from 'src/utils/functions';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';

export default function PerfilGeral({
  nome,
  sobrenome,
  usuario,
  nomeFantasia,
  email,
  telefone,
  ddd,
  changeDDD,
  changeTelefone,
  changeNome,
  changeSobrenome,
  changeUsuario,
  changeNomeFantasia,
  changeEmail,
  submit

}) {


  return (
    <Grid container spacing={3}>
      <Grid item xs={12} md={8}>
        <Box>
          <Stack spacing={{ xs: 2, md: 3 }}>
            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
              <RowInput
                label="Nome"
                input={<InputStyle size="small" onChange={changeNome} value={nome} fullWidth />}
              />
              <RowInput
                label="Sobrenome"
                input={<InputStyle size="small" onChange={changeSobrenome} value={sobrenome} fullWidth />}
              />
            </Stack>

            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <RowInput
                label="DDD"
                input={<InputStyle size="small" sx={{ maxWidth: 100 }} inputProps={{ maxLength: 2 }} onChange={changeDDD} value={ddd} fullWidth />}
              />
                <RowInput
                label="Telefone"
                input={<InputStyle size="small" sx={{ maxWidth: 200 }} inputProps={{ maxLength: ddd > 30 ? 8 : 9 }} onChange={changeTelefone} value={telefone} fullWidth />}
              />
            </Stack>

            <Stack direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <RowInput
                label="Nome Fantasia"
                input={<InputStyle size="small" onChange={changeNomeFantasia} value={nomeFantasia} fullWidth />}
              />
                       <RowInput
                label="Email"
                input={<InputStyle size="small" onChange={changeEmail} value={email} fullWidth />}
              />
              
            </Stack>

            <Stack sx={{ alignItems: 'center' }} direction={{ xs: 'column', md: 'row' }} spacing={2}>
            <RowInput
                label="Nome de usuário"
                input={<InputStyle size="small" onChange={changeUsuario} value={usuario} />}
              />
              <Typography sx={{ fontSize: 12 }}>Ex: <strong>jose.dasilva</strong></Typography>
            </Stack>




          </Stack>

          <Box sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}>
            <LoadingButton onClick={submit} type="submit" variant="contained">
              Salvar
            </LoadingButton>
          </Box>
        </Box>
      </Grid>
    </Grid>

  );
}
