import axios from "axios";

import Config from "src/config";

const v2_actionDeletarTag = (tagId) => {
    return axios({
        method: "delete",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/${tagId}/deletar-tag`,
    });
};
export default v2_actionDeletarTag;
