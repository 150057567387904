import { Box, Avatar, Button, ListItemAvatar, ListItemButton, Typography, IconButton, Popover } from '@mui/material';
import { styled } from '@mui/material/styles';
import { Stack } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';

// material
import { useState } from 'react'
//
import { BsChatDots, BsPersonFill, BsFillChatDotsFill } from "react-icons/bs";
import { FaRegHandRock } from "react-icons/fa";
import { GiSandsOfTime } from "react-icons/gi";

//utils 

import getActionBuscarNomeOuSetor from 'src/actions/chats/getActionBuscarNomeOuSetor';
import { formataNumeroCelular } from 'src/utils/functions';




// ----------------------------------------------------------------------

const AVATAR_SIZE = 48;

const RootStyle = styled(ListItemButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  transition: theme.transitions.create('all'),
  borderBottom: '1px solid #f0f0f0'
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': { borderRadius: '5%' },
  '& .MuiAvatar-root': { width: '100%', height: '100%' }
});


export default function ChatSearchItem({
  chat,
  onSelectConversation,
  onAceitarChat,
  ...other
}) {
  const [click, setCLick] = useState(null);
  const [quemEsta, setQuemEsta] = useState('');
  const handleClick = (event) => {

    if (chat.direciona_usuarios.length > 0) {
      getActionBuscarNomeOuSetor({
        usuarioId: chat.direciona_usuarios.length > 0 ? chat.direciona_usuarios[0] : [],
        setorId: "",

      }).then(res => setQuemEsta(res.data.nomeFantasia)).catch(err => console.log(err))
    } else if (chat.direciona_setores.length > 0) {
      getActionBuscarNomeOuSetor({
        usuarioId: "",
        setorId: chat.direciona_setores.length > 0 ? chat.direciona_setores[0] : [],

      }).then(res => setQuemEsta(res.data.nome_setor)).catch(err => console.log(err))
    }


    setCLick(event.currentTarget);
  };

  const handleClose = () => {
    setCLick(null);
  };




  return (
    <RootStyle
      disableGutters
      onClick={onSelectConversation}
      // sx={{
      //   ...(isSelected && { bgcolor: 'action.selected' })
      // }}
      {...other}
    >
      <ListItemAvatar>
        <Box>

          <AvatarWrapperStyle className="avatarWrapper" key={chat._id}>
            <Avatar variant="rounded" alt={chat.nomeCliente} src={chat.fotoPerfil} />
          </AvatarWrapperStyle>


        </Box>
      </ListItemAvatar>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column'
        }}
      >
        <Typography variant="caption">{chat.nomeCliente}</Typography>
        <Typography variant="caption">{formataNumeroCelular(chat.numeroCliente)}</Typography>

        <Stack sx={{mt:1}}>
          <Stack alignItems={'center'}  direction={'row'} spacing={1}>
            <BsFillChatDotsFill size={10} />
            <Typography sx={{ mt: 1 }} variant="caption">{chat.statusRoom}</Typography>
          </Stack>

          <Stack direction={'row'} spacing={1}>

            <BsPersonFill size={12} />
            <Typography sx={{ mt: 1, fontSize: 10 }} variant="caption">{chat.usuario_atendendo}</Typography>
          </Stack>
        </Stack>





      </Box>


      <Box
        sx={{
          ml: 2,
          display: 'flex',
          alignItems: 'flex-end',
          flexDirection: 'column'
        }}
      >
        {/* <Box
                    sx={{
                        fontSize: 12,
                        lineHeight: '22px',
                        whiteSpace: 'nowrap',
                        color: 'text.disabled'
                    }}
                >
              {chat.dataUltimaMensagem && formatDistanceToNowStrict(new Date(chat.dataUltimaMensagem), {addSuffix: false, locale: ptBR })}
                </Box> */}

        {chat.statusRoom === 'Finalizado' && <IconButton onClick={onAceitarChat}> <FaRegHandRock size={20} /> </IconButton>}
        {chat.statusRoom === 'Geral' && <IconButton onClick={onAceitarChat}> <FaRegHandRock size={20} /> </IconButton>}
        {chat.statusRoom === 'Pendente' && <GiSandsOfTime size={20} />}

      </Box>


    </RootStyle>
  );
}
