import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
// utils
import { fDateTime } from 'src/utils/formatTime';
// components
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import { TableCell, TableRow } from 'src/@/components/ui/table';
import { Button } from 'src/@/components/ui/button';

export default function SupervisaoRow({ row, selected, onViewRow, loading, chat }) {
    const theme = useTheme();
    const { numeroCliente, nomeCliente, nome_cliente, statusRoom, chatbot, cliente_id, lastUpdate, nome_setor, nome_usuario, novaMensagem, } = row;
    const [setOpenMenuActions] = useState(null);

    const handleCloseMenu = () => {
        setOpenMenuActions(null);
    };

    return (
        <TableRow>
            <TableCell>
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={
                        (statusRoom === "Finalizado" && 'success') ||
                        (statusRoom === "Pendente" && 'error') ||
                        (statusRoom === "Atendendo" && 'warning') ||
                        (statusRoom === "Geral" && 'info') ||
                        'default'
                    }
                    sx={{ textTransform: 'capitalize' }}
                >
                    {statusRoom}
                </Label>
            </TableCell>

            <TableCell>{numeroCliente}</TableCell>
            <TableCell>{nomeCliente ? nomeCliente : nome_cliente}</TableCell>
            <TableCell>{nome_usuario}</TableCell>
            <TableCell>{nome_setor}</TableCell>
            <TableCell>{fDateTime(lastUpdate)}</TableCell>
            <TableCell>
                <Label
                    variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                    color={
                        (chatbot === true && 'default') ||
                        (chatbot === false && 'default') ||
                        'default'
                    }
                    sx={{ textTransform: 'capitalize' }}
                >
                    {chatbot ? 'Ativado' : 'Desativado'}
                </Label>
            </TableCell>
            <TableCell>
                <Button
                    onClick={() => {
                        onViewRow();
                    }}
                    size="icon"
                    variant="outline"
                >
                    <Iconify icon={"carbon:view-filled"} />
                </Button>

            </TableCell>
        </TableRow>
    );
}
