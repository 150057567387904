import { useState, useEffect, useCallback } from 'react'
import {
    Stack,
    Container,
    Drawer,
    Typography,
    Divider,
    IconButton,
    Button,
    TextField,
    FormControl,
    RadioGroup,
    FormControlLabel,
    FormLabel,
    Radio
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyleMultline from 'src/components/InputStyleMultline';
import { UploadCampanha } from 'src/components/upload';

import * as React from 'react';
import { Box } from '@mui/material'
import SelectBasic from 'src/components/zui/Select';

import LoadingButton from '@mui/lab/LoadingButton';
import v2_actionCriarAutomacao from 'src/actions/v2/cliente/v2_actionCriarAutomacao';
import InputStyle from 'src/components/InputStyle';
import v2_actionUploadS3 from 'src/actions/v2/global/actionUploadS3';

const OptionsItemAutomacao = ({ title, selected, tipo, onClick }) => {
    return (
        <Button onClick={onClick} sx={{ p: 2, background: selected === tipo ? '#F2F6FA' : '#fff', border: '1px solid', borderColor: selected === tipo ? '#ccc' : '#e5e2f7', borderRadius: 0.5 }}>
            <Stack direction="row" spacing={1}>

                {/* <Iconify icon="material-symbols-light:play-arrow" width={24} height={24} /> */}
                <Typography color="text.primary" variant='caption'>{title}</Typography>

            </Stack>
        </Button>
    )

}

export default function DrawerAutomacao({
    cliente,
    user,
    open,
    onClose,
    onCallback
}) {


    const [automacoes, setAutomacoes] = useState([])
    const [tipoAutomacao, setTipoAutomacao] = useState(null);
    const [nomeAutomacao, setNomeAutomacao] = useState(null);
    const [tipoMensageEnvio, setTipoMensageEnvio] = useState(null);


    //upload
    const [files, setFiles] = useState([]);
    const filesUrl = []
    // eslint-disable-next-line no-unused-vars
    const [preview, setPreview] = useState(true);
    const [mensagemAniversario, setMensagemAniversario] = useState('');
    const [isFile, setIsFile] = useState(false);
    const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
    const [loading, setLoading] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditFile, setIsEditFile] = useState(false);
    const [filesEdit, setFilesEdit] = useState([]);


    function reset() {
        setFiles([]);
        setFilesEdit([]);
        setTipoAutomacao(null);
        setNomeAutomacao(null);
        setTipoMensageEnvio(null);
        setMensagemAniversario('');
    }


    async function handleCriarAutomacao() {
        try {
            let data = {
                scope: {
                    tipo: tipoAutomacao,
                    nome: nomeAutomacao,
                    rules: {
                        files: filesUrl,
                        tipo_mensagem: tipoMensageEnvio,
                        mensagem: mensagemAniversario
                    }
           
                }
            }

            if(tipoMensageEnvio === "arquivo") {
                await uploadAndGetUrl();
            }
    
            const result = await v2_actionCriarAutomacao(cliente._id, data);
            if (result.status === 200) {
               onCallback()
               reset()
               onClose()
            }
        } catch (error) {
            console.log(error);
        }
    }

    async function uploadAndGetUrl() {
        if (files.length > 0) {
            for (let file of files.map(item => item)) {
                var formData = new FormData();
                var imagefile = file;
                formData.append("file", imagefile);
                try {
                    let querys = `?path=plataforma/automacoes`
                    const response = await v2_actionUploadS3(formData, querys)
                    if (!response.data.error) {
                        filesUrl.push({ key: response.data.data.key, type: file.type })
                    }

                } catch (error) {
                    console.log('erro ao upload arquivo', error)
                }

            }
        }
    }


    const handleDropMultiFile = useCallback(
        (acceptedFiles) => {
            setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
        },
        [setFiles]
    );


    const handleRemove = (file) => {
        const filteredItems = files.filter((_file) => _file !== file);
        setFiles(filteredItems);
    };
    const handleRemoveEdit = (file) => {
        const filteredItems = filesEdit.filter((_file) => _file !== file);
        setIsEditFile(false);
        setFilesEdit(filteredItems);
    };


    return (
        <Drawer hideBackdrop={false} open={open} onClose={onClose} anchor="right" >
            <Stack sx={{ height: '100vh' }} justifyContent={"space-between"} direction={"column"}>
                <Box sx={{ p: 2 }} >
                    <Container>
                        <Box sx={{ mt: 0.5, mb: 0.5 }}>
                            <Stack direction="row" justifyContent={"space-between"}>
                                <Typography variant='h6'>Criar Automação</Typography>
                                <IconButton onClick={() => onClose()}>
                                    <Iconify icon="mdi:close" width={24} height={24} />
                                </IconButton>
                            </Stack>
                        </Box>

                    </Container>

                    <Divider sx={{ borderStyle: 'dashed' }} />

                    {/* 
                <SelectBasic /> */}



                    <Container >
                        <Box sx={{ minWidth: 600, maxWidth: 600, mt: 4 }}>
                            <Stack spacing={3} direction={'column'} justifyContent={'space-between'} alignContent={'space-between'}>

                            <Stack sx={{ width: '100%' }} direction={"row"} spacing={1}>
                                                    <RowInput
                                                        sx={{ width: '100%' }}
                                                        label="Nome da Automação"
                                                        input={
                                                            <InputStyle
                                                                value={nomeAutomacao}
                                                                size='small'
                                                                onChange={(event) => setNomeAutomacao(event.target.value)}
                                                            />
                                                        }
                                                    />
                                                </Stack>

                                <Stack direction={"row"} spacing={1}>
                                    <OptionsItemAutomacao
                                        onClick={() => setTipoAutomacao('pipiline_send_birthday_message')}
                                        title="Mensagem Aniversário"
                                        tipo="pipiline_send_birthday_message"
                                        selected={tipoAutomacao}
                                    />
                                    <OptionsItemAutomacao
                                        title="Mensagem após inatividade"
                                        tipo="pipeline_send_message_inativity"
                                        onClick={() => setTipoAutomacao('pipeline_send_message_inativity')}
                                        selected={tipoAutomacao}
                                    />

                                </Stack>

                                <Stack>

                                </Stack>

                                {tipoAutomacao === "pipiline_send_birthday_message" && (
                                    <Box sx={{ mt: 3 }}>
                                        <Stack direction="column" spacing={2}>
                                            <Stack>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                        value={tipoMensageEnvio}
                                                        onChange={e => setTipoMensageEnvio(e.target.value)}
                                                    >
                                                        <FormControlLabel value="texto" control={<Radio />} label="Texto" />
                                                        <FormControlLabel value="arquivo" control={<Radio />} label="Arquivo" />
                                                    </RadioGroup>
                                                </FormControl>
                                            </Stack>

                                            {tipoMensageEnvio === "texto" && (
                                                <Stack sx={{ width: '100%' }} direction={"row"} spacing={1}>
                                                    <RowInput
                                                        sx={{ width: '100%' }}
                                                        label="Mensagem de aniversário"
                                                        input={
                                                            <InputStyleMultline
                                                                value={mensagemAniversario}
                                                                multiline
                                                                size='small'
                                                                rows={4}
                                                                onChange={(event) => setMensagemAniversario(event.target.value)}
                                                            />
                                                        }
                                                    />
                                                </Stack>
                                            )}

                                            {tipoMensageEnvio === 'arquivo' && (
                                                <UploadCampanha
                                                    loadingEnviarArquivos={loadingEnviarArquivos}
                                                    showPreview={preview}
                                                    files={files}
                                                    isEdit={isEdit}
                                                    isEditFile={isEditFile}
                                                    filesEdit={filesEdit}
                                                    onRemoveEdit={handleRemoveEdit}
                                                    onDrop={handleDropMultiFile}
                                                    onRemove={handleRemove}

                                                />
                                            )}

                                        </Stack>
                                    </Box>
                                )}



                            </Stack>
                        </Box>
                    </Container>
                </Box>

                <Stack>
                    <LoadingButton 
                    loading={loading} 
                    onClick={handleCriarAutomacao}
                    variant="contained" 
                    sx={{
                        borderRadius: 0, width: 1, height: 70
                    }} >
                        Salvar
                    </LoadingButton>
                </Stack>
            </Stack>

        </Drawer>

    );
}


