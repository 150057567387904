import PropTypes from 'prop-types';
import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import { styled } from '@mui/material/styles';
import { Avatar, Box, ImageList, ImageListItem, Typography, Stack } from '@mui/material';
// components
import Image from 'src/components/Image';
import { useState } from 'react';
import LightboxModal from 'src/components/LightboxModal';
import ptBR from 'date-fns/locale/pt-BR';
import AudioComponent from 'src/components/_chats/chat/components/ChatPage/AudioComponent';
import AudioComponentMeta from 'src/components/_chats/chat/meta_components/ChatComponent/AudioComponent';
import Iconify from 'src/components/Iconify';
import MarkdownWrapper from 'src/components/_chats/chat/meta_components/ChatComponent/MarkdownWrapper';
import moment from 'moment';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  marginBottom: theme.spacing(3)
}));

const MessageTextContainer = styled('div')(({ theme }) => ({
  overflowWrap: 'break-word',
  whiteSpace: 'break-spaces'
}));

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 320,
  padding: theme.spacing(1.5),
  marginTop: theme.spacing(0.5),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: 'white',
  boxShadow: theme.customShadows.z1,
}));

const InfoStyle = styled(Typography)(({ theme }) => ({
  display: 'flex',
  marginTop: theme.spacing(0.75),
  fontSize: 9,
  color: theme.palette.text.secondary,
}));

const VideoContainer = styled('div')(({ theme }) => ({
  objectFit: 'cover',
  cursor: 'point',
  maxHeight: 300,
  'video': {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    cursor: 'pointer',
    maxHeight: 300,

  }

}));

// ----------------------------------------------------------------------

ChatMessageItemMeta.propTypes = {
  message: PropTypes.object.isRequired,
  conversation: PropTypes.object.isRequired,
  onOpenLightbox: PropTypes.func,
};

export default function ChatMessageItemMeta({ message, conversation, onOpenLightbox }) {
  const sender = conversation.find((participant) => participant.id === message.message.sender.id);

  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);


  const isMe = message.message.isMe
  const isRead = message.message.isRead
  const isImage = message.contentType === 'image';
  const isDeleted = message.message.message.is_deleted;
  const istext = !message.message.isFile;
  const typeFile = message.message.isFile && message.message.message.attachments.length > 0 && message.message.message.attachments[0].type;
  const files = message.message.isFile && message.message.message.attachments.length > 0 && message.message.message.attachments[0];

  let imagens = message.message.isFile ? message.message.message.attachments.filter(attachment => attachment.type === "image") : [];
  // Extrai e retorna apenas as URLs das imagens
  const imagesLightbox = imagens.map(imagem => imagem.payload.url);



  return (
    <RootStyle>

      <LightboxModal
        images={imagesLightbox}
        mainSrc={imagesLightbox[selectedImage]}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />

      <Box
        sx={{
          display: 'flex',
          ...(isMe && {
            ml: 'auto',
          }),
        }}
      >
        <div>
          <ContentStyle
            sx={{
              ...(isMe && { color: 'grey.800', bgcolor: '#EFFFDE' }),
              ...(isImage && { p: 0 }),
            }}
          >

            {istext ? (
              <MessageTextContainer>
                {isDeleted && <Typography sx={{color:'#f03'}} variant="caption">Mensagem excluida</Typography>}
         
                  {!isDeleted && (
                      
                        <Typography variant="caption" >
                           <MarkdownWrapper>
                          {message?.message?.message?.text}
                          </MarkdownWrapper>
                          </Typography>
                  ) }
        
              </MessageTextContainer>
            ) : (
              <>

                {typeFile === 'video' && (
                  <VideoContainer>
                    <video
                      controls
                      src={files.payload.url}
                    />
                  </VideoContainer>
                )}

                {typeFile === 'audio' && (
                  <AudioComponentMeta
                    url={files.payload.url}
                    isMe={isMe}
                  // profileImage={!message?.sender?.profilePicThumbObj?.eurl ? defaultImage : message?.sender?.profilePicThumbObj?.eurl}
                  // audioRef={audioRef}
                  //  downloadAudio={onClickDownload}
                  />
                )}

                {typeFile === 'file' && (
                  <a href={files.payload.url} target="_blank" rel="noreferrer">
                    {files.payload.url}
                  </a>
                )}
              </>

            )}

            {!istext && imagesLightbox.length > 0 && (
                  imagesLightbox.length > 1 ? (
                    <ImageList
                      sx={{ width: 300, height: 200 }} cols={3} rowHeight={164}>
                      {imagesLightbox.map((item) => (
                        <ImageListItem key={item}>
                          <img
                            onClick={() => {
                              setOpenLightbox(true)
                              setSelectedImage(imagesLightbox.findIndex((index) => index === item))
                            }}
                            srcSet={`${item}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                            src={`${item}`}
                            loading="lazy"
                          />
                        </ImageListItem>
                      ))}
                    </ImageList>
                  ) : (
      
                    <Image
                      alt="attachment"
                      src={imagesLightbox[0]}
                      onClick={() => setOpenLightbox(true)}
                      sx={{ borderRadius: 1, cursor: 'pointer', '&:hover': { opacity: 0.8 } }}
                    />
                  )

            )}
            
        


          </ContentStyle>
          <Stack sx={{ mt: 0.5 }} spacing={1} direction="row" alignItems="center">
            <InfoStyle
              variant="caption"
              sx={{
                ...(isMe && { justifyContent: 'flex-end' }),
              }}
            >
              {moment(new Date(message.date)).format('DD/MM/YYY HH:mm')}
            </InfoStyle>
            {isRead && <Iconify color="blue" icon="ri:check-double-fill" />}
          </Stack>

        </div>
      </Box>
    </RootStyle>
  );
}
