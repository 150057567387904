import axios from "axios";
import Config from "../../../config";

const v2_actionPesquisarContatos = (clienteId, pesquisa) => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/cadastro/contatos/${clienteId}/pesquisar?pesquisa=${pesquisa}`
    });
};

export default v2_actionPesquisarContatos;
