import PropTypes from 'prop-types';
import React, { useState, useRef, useEffect } from 'react';
// @mui
import {
  Box,
  Stack,
  // Drawer,
  Tooltip,
  Typography,
  Grid,
  Container,
  Button,
  useMediaQuery,
  useTheme,
  Drawer,
  AppBar,
  Toolbar,
  IconButton,
  Dialog,
  Slide
} from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import { useSelector } from 'react-redux';
import useAuth from 'src/hooks/useAuth';
import ChatWindow from './ChatWindow';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 0.5, py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});


DrawerChatWhatsapp.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  chat: PropTypes.object,
  comentarios: PropTypes.array
};

export default function DrawerChatWhatsapp({
  chat,
  comentarios,
  open,
  onClose,
}) {
  const messagesEnd = useRef(null);
  const { chatInfo } = useSelector((state) => state.chat);
  const { config } = useSelector((state) => state.global);
  const theme = useTheme();
  const { user } = useAuth();
  const [tagsChamado, setTagsChamados] = useState(chat?.tags_ids);
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [sidebarLeft, setSidebarLeft] = useState(false);


  useEffect(() => {
    scrollToBottom();
  }, [chat, open, tagsChamado]);


  const onFecharDetalhesChamado = async () => {
    onClose();
  }

  const scrollToBottom = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight;
    }
  }


  return (
    <Dialog
    sx={{ zIndex:2  }}
      fullScreen
      open={open}
      onClose={onClose}
      TransitionComponent={Transition}
    >
      <AppBar
      // className='h-11 relative'
      >
        <Toolbar className='h-4'>
          <IconButton
            edge="start"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <Iconify icon={'eva:arrow-ios-back-fill'} width={20} height={20} />
          </IconButton>
          <p>
            Voltar
          </p>

        </Toolbar>
      </AppBar>

      <div className='mt-16 overflow-hidden'>
        {chatInfo && <ChatWindow
          sidebarLeft={sidebarLeft}
          changeOpenSidebarLeft={() => {
            isMobile && setSidebarLeft(!sidebarLeft)
          }}
          config={config}
          chatInfo={chatInfo}
        />}
      </div>

    </Dialog>

  );
}
