import axios from "axios";
import { number } from "prop-types";

import Config from "src/config";

const v2_actionVerificarNumero = (clienteId, number, querys) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/${clienteId}/${number}/check-number-phone${querys}`
    });
};

export default v2_actionVerificarNumero;
