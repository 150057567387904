import axios from "axios";

const mimeToExtension = {
  "application/epub+zip": ".epub",
  "application/gzip": ".gz",
  "application/java-archive": ".jar",
  "application/javascript": ".js",
  "application/json": ".json",
  "application/msword": ".doc",
  "application/octet-stream": ".bin",
  "application/ogg": ".ogg",
  "application/pdf": ".pdf",
  "application/rtf": ".rtf",
  "application/vnd.ms-excel": ".xls",
  "application/vnd.ms-fontobject": ".eot",
  "application/vnd.ms-powerpoint": ".ppt",
  "application/vnd.openxmlformats-officedocument.presentationml.presentation": ".pptx",
  "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": ".xlsx",
  "application/vnd.openxmlformats-officedocument.wordprocessingml.document": ".docx",
  "application/vnd.rar": ".rar",
  "application/vnd.visio": ".vsd",
  "application/x-7z-compressed": ".7z",
  "application/x-bzip": ".bz",
  "application/x-bzip2": ".bz2",
  "application/x-csh": ".csh",
  "application/x-sh": ".sh",
  "application/x-tar": ".tar",
  "application/xhtml+xml": ".xhtml",
  "application/xml": ".xml",
  "application/zip": ".zip",
  "audio/midi": ".mid",
  "audio/mpeg": ".mp3",
  "audio/ogg": ".ogg",
  "audio/wav": ".wav",
  "font/otf": ".otf",
  "font/ttf": ".ttf",
  "font/woff": ".woff",
  "font/woff2": ".woff2",
  "image/bmp": ".bmp",
  "image/gif": ".gif",
  "image/jpeg": ".jpg",
  "image/png": ".png",
  "image/svg+xml": ".svg",
  "image/tiff": ".tiff",
  "image/webp": ".webp",
  "text/calendar": ".ics",
  "text/css": ".css",
  "text/csv": ".csv",
  "text/html": ".html",
  "text/plain": ".txt",
  "text/xml": ".xml",
  "video/mp4": ".mp4",
  "video/mpeg": ".mpeg",
  "video/ogg": ".ogg",
  "video/webm": ".webm",
  "video/x-msvideo": ".avi"
};

export function downloadFromBase64(base64Data, fileName, mimeType) {
  // Converta a base64 em um ArrayBuffer
  const binaryData = atob(base64Data);
  const arrayBuffer = new ArrayBuffer(binaryData.length);
  const uint8Array = new Uint8Array(arrayBuffer);
  
  for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
  }
  
  // Crie um Blob a partir do ArrayBuffer
  const blob = new Blob([uint8Array], { type: mimeType });

  // Crie um URL temporário para o Blob
  const url = window.URL.createObjectURL(blob);

  // Crie um link para download e simule um clique nele
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;

  // Verifique se a extensão correspondente ao tipo MIME está disponível
  const extension = mimeToExtension[mimeType];
  if (extension) {
      fileName += extension;
  }

  a.download = fileName; // Use o nome do arquivo fornecido
  document.body.appendChild(a);
  a.click();

  // Limpe o URL temporário após o download
  window.URL.revokeObjectURL(url);
}
