import { Box, TableSortLabel, Typography } from '@mui/material';
import PropTypes from 'prop-types';
// material

import { TableHead, TableRow, TableCell, TableHeader } from 'src/@/components/ui/table';

// ----------------------------------------------------------------------

ContatosHeadTable.propTypes = {
  order: PropTypes.oneOf(['asc', 'desc']),
  orderBy: PropTypes.string,
  rowCount: PropTypes.number,
  headLabel: PropTypes.array,
  numSelected: PropTypes.number,
  onRequestSort: PropTypes.func,
  onSelectAllClick: PropTypes.func
};

export default function ContatosHeadTable({
  order,
  orderBy,
  headLabel,
  onRequestSort
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHeader >
      <TableRow >
        {headLabel.map((headCell) => (
          <TableCell className="font-semibold" >
         {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  );
}
