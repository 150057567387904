import axios from "axios";
import Config from "../../config";

const actionCheckConnectionWhatsapp = (querys) => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/check-connection${querys ? querys : ""}`
    });
};

export default actionCheckConnectionWhatsapp;
