import { createSlice } from '@reduxjs/toolkit';
import actionListaAgendamentos from 'src/actions/agendamentos/actionListaCampanhas';
import actionListaCampanhas from 'src/actions/campanhas/actionListaCampanhas';
import actionListaNotificacoes from 'src/actions/notificacoes/actionListaNotificacoes';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  notificacoes:[],
  notificacoesNaoLidas:[],
  notificacoesLidas:[]

};

const slice = createSlice({
  name: 'notificacoes',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getListaNotificacoesSuccess(state, action) {
        state.notificacoes = action.payload;
        state.notificacoesLidas = action.payload.lidas;
        state.notificacoesNaoLidas = action.payload.naoLidas;
      }

  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getListaNotificacoes(id) {
    return async (dispatch) => {
      dispatch(slice.actions.startLoading());
      try {
        const response = await actionListaNotificacoes(id);
        dispatch(slice.actions.getListaNotificacoesSuccess(response.data.data));
      } catch (error) {
        console.log('Erro ao buscar notificações', error)
        dispatch(slice.actions.hasError(error));
      }
    };
  }




