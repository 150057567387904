// material
import { IconButton, Popover } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { MenuItem, Typography, Stack, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { DatePicker } from '@mui/x-date-pickers';
import Iconify from 'src/components/Iconify';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { setContato, setConversa, setEmpresa, setNegocio } from 'src/redux/slices/global';

// ----------------------------------------------------------------------

import { Button } from 'src/@/components/ui/button';
import { DropdownMenu1 } from 'src/@/components/use/dropdownMenu/DropdownMenu1';

const ArrowStyle = styled('span')(({ theme }) => ({
    [theme.breakpoints.up('sm')]: {
        top: -7,
        zIndex: 1,
        width: 12,
        right: 20,
        height: 12,
        content: "''",
        position: 'absolute',
        borderRadius: '0 0 4px 0',
        transform: 'rotate(-135deg)',
        background: theme.palette.background.paper,
        borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
        borderBottom: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`
    }
}));

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
    fontSize: 14,
    py: 1,
    px: 2.5
}));

// ----------------------------------------------------------------------

MenuOptionsNovo.propTypes = {
    children: PropTypes.node.isRequired,
    sx: PropTypes.object
};



export default function MenuOptionsNovo({
    children,
    sx,
    inicial,
    final,
    changeInicial,
    changeFinal,
    onSelect,
    onPesquisar,
    ...other
}) {
    const dispatch = useDispatch();
    const [anchorElMenuOptionsNovo, setAnchorElMenuOptionsNovo] = useState(null);
    const openMenuOptionsNovo = Boolean(anchorElMenuOptionsNovo);

    async function onSelect(value) {

        if (value === 'negocio') {
            dispatch(setNegocio({ loading: false, openModal: true }))
        } else if (value === 'empresa') {
            dispatch(setEmpresa({ loading: false, openModal: true }))
        } else if (value === 'conversa') {
            dispatch(setConversa({ loading: false, openModal: true }))
        } else if (value === 'contato') {
            dispatch(setContato({ loading: false, openModal: true }))
        }
        setAnchorElMenuOptionsNovo(null)
    }

    return (
        <>
            <Popover
                anchorEl={anchorElMenuOptionsNovo}
                open={openMenuOptionsNovo}
                onClose={() => setAnchorElMenuOptionsNovo(null)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                PaperProps={{
                    sx: {
                        mt: 1.5,
                        ml: 0.5,
                        overflow: 'inherit',
                        boxShadow: (theme) => theme.customShadows.z20,
                        border: (theme) => `solid 1px ${theme.palette.grey[300]}`,
                        width: 200,
                        p: 1,
                        ...sx
                    }
                }}
                {...other}
            >
                <ArrowStyle className="arrow" />

                <MenuItemStyle key={'negocio'} onClick={() => onSelect('conversa')} >
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 0.5 }}>
                        <Iconify width={16} height={16} icon={'ri:whatsapp-fill'} />
                        <Typography sx={{ fontSize: 13, fontWeight: '600' }}>Conversa</Typography>
                    </Stack>
                </MenuItemStyle>
                <MenuItemStyle key={'negocio'} onClick={() => onSelect('negocio')} >
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 0.5 }}>
                        <Iconify width={16} height={16} icon={'streamline:subscription-cashflow'} />
                        <Typography sx={{ fontSize: 13, fontWeight: '600' }}>Negócio</Typography>
                    </Stack>
                </MenuItemStyle>
                <MenuItemStyle key={'mes_anterior'} onClick={() => onSelect('contato')} >
                    <Stack direction="row" spacing={1} alignItems="center" sx={{ mb: 0.5 }}>
                        <Iconify width={18} height={18} icon={'material-symbols:person-outline'} />
                        <Typography sx={{ fontSize: 13, fontWeight: '600' }}>Contato</Typography>
                    </Stack>
                </MenuItemStyle>

            </Popover>

            <Button
                
                className=" h-8 bg-violet-500 flex items-center"
                onClick={event => setAnchorElMenuOptionsNovo(event.currentTarget)}
            >
                Criar
                <Iconify className="text-white ml-1 mt-0.5" icon="mdi:add-bold" />
            </Button>

        </>

    );
}



