
import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    // Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Container,
    IconButton
} from '@mui/material';
import Iconify from 'src/components/Iconify';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getListaUsuariosByClienteId } from 'src/redux/slices/user';

// components
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/material';
import HeaderPagePadrao from 'src/components/header/HeaderPagePadrao';
import ContatosHeadTable from 'src/components/_dashboard/contatos/ContatosHeadTable';
import ContatosRowTable from 'src/components/_dashboard/contatos/ContatosRowTable';
import { setContato } from 'src/redux/slices/global';
import ModalVerContato from 'src/sections/@dashboard/cadastro/ModalVerContato';
import getActionCriarChatAndNovaMensagem from 'src/actions/chats/getActionCriarChatAndNovaMensagem';
import { validarNumeroWhatsapp } from 'src/utils/functions';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import { PATH_CHAT } from 'src/routes/paths';
import { getBuscarContatos, getPesquisarContatos } from 'src/redux/slices/contatos';
import { getChatInfoByChatId, getMensagensWhatsapp, getMensagensWhatsappDb, setChatInfo } from 'src/redux/slices/chat';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import ModalUploadContatos from 'src/sections/@dashboard/cadastro/ModalUploadContatos';


//zui
import { Button } from 'src/@/components/ui/button';
import v2_actionExportarContatos from 'src/actions/v2/contatos/v2_actionExportarContatos';
import v2_actionVerificarNumero from 'src/actions/v2/cliente/v2_actionVerificarNumero';
// ----------------------------------------------------------------------

import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "src/@/components/ui/dialog";

import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select"

import { Table } from 'src/@/components/ui/table';
import { Input } from 'src/@/components/ui/input';
import { CardContent, Card } from 'src/@/components/ui/card';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';

const TABLE_HEAD = [
    { id: 'nome', label: 'Nome', alignRight: false },
    { id: 'telefone', label: 'Telefone', alignRight: false },
    { id: 'email', label: 'Email', alignRight: false }, ,
    { id: 'cpf', label: 'cpf', alignRight: false },
    { id: 'dataNascimento', label: 'Data Nascimento', alignRight: false },
    { id: 'cidade', label: 'Cidade', alignRight: false },
    { id: 'data_criacao', label: 'Data Criação', alignRight: false },
    { id: 'custom_fileds', label: 'Campos Personalizados', alignRight: false },
    { id: '', },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applyFilter(tableData, filterName) {


    return tableData;
}

const ModalConfirmChannel = ({
    open,
    contato,
    user,
    cliente,
    onOpenChange,
    canais,
    onSubmit,
    enqueueSnackbar,
    onClose,
    navigate
}) => {

    const [selected, setSelected] = useState(null);
    const [loadingNovoChat, setLoadingNovoChat] = useState(false);


    async function onCriarChat(row) {
        setLoadingNovoChat(true);
       
   
        try {
            let querys = `?is_contact=1&is_chat=1&is_whatsapp=1`;
            const checkNumber = await v2_actionVerificarNumero(selected?.cliente_id, validarNumeroWhatsapp(row.telefone), querys);
    
            if (checkNumber.status === 200) {
                if (checkNumber.data.data.isChatExist) {
                    enqueueSnackbar('Chat já está cadastrado com outro agente', { variant: 'warning' });
                    setLoadingNovoChat(false);
                    return;
                } else if (!checkNumber.data.data.isWhatsapp) {
                    enqueueSnackbar('Erro ao criar chat. Número não existe no whatsapp', { variant: 'error' });
                    setLoadingNovoChat(false);
                    return;
                }
            }
            const response_chat_info = await getActionChatInfo(selected.cliente_id, validarNumeroWhatsapp(row.telefone));

            if (response_chat_info.data) {
                if (response_chat_info.data.direciona_usuarios.includes(user.id)) {
                    await buscarMessagesAndInfo(response_chat_info.data.data);
                    setLoadingNovoChat(false);
                    navigate(`${PATH_CHAT.chat.root}`);

                } else {
                    setLoadingNovoChat(false);
                    enqueueSnackbar('Chat já está cadastrado com outro agente', { variant: 'warning' });
                }
                setLoadingNovoChat(false);
            } else {
          
                try {
                    let data1 = {
                        nomeCliente: row.nome || '',
                        numeroCliente: validarNumeroWhatsapp(row.telefone),
                        statusRoom: "Atendendo",
                        usuarioId: user.id,
                        clienteId: selected.cliente_id
                    }
                    await getActionCriarChatAndNovaMensagem(data1)
                    await getActionChatInfo(selected.cliente_id, validarNumeroWhatsapp(row.telefone));
                    setLoadingNovoChat(false);
                    navigate(`${PATH_CHAT.chat.root}`);
                } catch (error) {
                    console.log(error)
                    setLoadingNovoChat(false)
                }

            }
        } catch (error) {
            console.log(error)
            setLoadingNovoChat(false)
        }

    }

            return (
                <Dialog open={open} onOpenChange={onClose}>

                    <DialogContent className="sm:max-w-[425px]">
                        <DialogHeader>
                            <DialogTitle>Criar chat</DialogTitle>
                            <DialogDescription>
                                Selecione o canal que deseja utilizar para criar o chat
                            </DialogDescription>
                        </DialogHeader>
                        <div className="grid gap-4 py-4 mt-3 w-full">
                            <Select onValueChange={value => {
                                console.log('value', value)
                                setSelected(value)
                            }}
                                value={selected}
                            >
                                <SelectTrigger className="w-[180px]">
                                    <SelectValue placeholder="Selecione o canal" />
                                </SelectTrigger>
                                <SelectContent>
                                    <SelectGroup>
                                        {canais && canais.map((item, index) => (
                                            <SelectItem key={index._id} value={item}>
                                                {item.nome}
                                            </SelectItem>
                                        ))}
                                    </SelectGroup>
                                </SelectContent>
                            </Select>
                        </div>
                        <DialogFooter>
                            <ButtonLoading onClick={() => onCriarChat(contato)} loading={loadingNovoChat}>Criar chat</ButtonLoading>
                        </DialogFooter>
                    </DialogContent>
                </Dialog>
            )
        }



export default function Contatos() {
            const navigate = useNavigate()
            const dispatch = useDispatch();
            const { user, cliente } = useAuth();
            const { usuariosList } = useSelector((state) => state.user);
            const { contatos } = useSelector((state) => state.contatos);
            const { enqueueSnackbar } = useSnackbar();
            const [page, setPage] = useState(0);
            const [order, setOrder] = useState('asc');
            const [selected, setSelected] = useState([]);
            const [orderBy, setOrderBy] = useState('nome');
            const [filterName, setFilterName] = useState('');
            const [rowsPerPage, setRowsPerPage] = useState(10);
            const [openModalVerContato, setStateModalVerContato] = useState(false);
            const [pesquisa, setPesquisa] = useState('');
            const [contatoData, setContatoData] = useState(null);
            const [loadingNovoChat, setLoadingNovoChat] = useState(false);
            const tableData = contatos
            const [listaClientesCanais, setListaClientesCanais] = useState([]);

            //upload contatos
            const [filesUploadContacts, setFilesUploadContacts] = useState([]);
            const [openPopover, setOpenPopover] = useState(false);
            const [canais, setCanais] = useState([]);
            const [selectedCanal, setSelectedCanal] = useState(null);

            const [openModalCriarChat, setOpenModalCriarChat] = useState(false);
            const [contatoChat, setContatoChat] = useState(null);


            function buscarMessagesAndInfo(chat) {
                if (cliente.config.chat_source_db) {
                    dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
                } else {
                    dispatch(getMensagensWhatsapp(chat.sessao, chat.numeroCliente));
                }
                if (chat) {
                    dispatch(setChatInfo(chat));
                } else {
                    dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));
                }
            }

            async function handleCriarChat(row) {
                setLoadingNovoChat(true);
                setContatoChat(row)
                // verificar se é multisession
                if (cliente?.multiSession === 1) {
                    setOpenModalCriarChat(true);
                    setLoadingNovoChat(false)
                    return;
                }

                let querys = `?is_contact=1&is_chat=1&is_whatsapp=1`;
                const checkNumber = await v2_actionVerificarNumero(cliente._id, validarNumeroWhatsapp(row?.telefone), querys);

                if (checkNumber.status === 200) {
                    if (checkNumber.data.data.isChatExist) {
                        enqueueSnackbar('Chat já está cadastrado com outro agente', { variant: 'warning' });
                        setLoadingNovoChat(false)
                        return;
                    } else if (!checkNumber.data.data.isWhatsapp) {
                        enqueueSnackbar('Erro ao criar chat. Número não existe no whatsapp', { variant: 'error' });
                        setLoadingNovoChat(false)
                        return;
                    }
                }

                console.log('cliente id', cliente._id)
        
                try {
                    const response_chat_info = await getActionChatInfo(cliente._id, validarNumeroWhatsapp(row.telefone));
        
                    if (response_chat_info.data) {
                        if (response_chat_info.data.direciona_usuarios.includes(user.id)) {
                            buscarMessagesAndInfo(response_chat_info.data.data);
                            navigate(`${PATH_CHAT.chat.root}`);
                            setLoadingNovoChat(false)
                        } else {
                            enqueueSnackbar('Chat já está cadastrado com outro agente', { variant: 'warning' });
                            setLoadingNovoChat(false)
                        }
                        setLoadingNovoChat(false);
                    } else {
                  
                        try {
                            let data1 = {
                                nomeCliente: row.nome || '',
                                numeroCliente: validarNumeroWhatsapp(row.telefone),
                                statusRoom: "Atendendo",
                                usuarioId: user.id,
                                clienteId: cliente._id
                            }
                            await getActionCriarChatAndNovaMensagem(data1)
                            await getActionChatInfo(cliente._id, validarNumeroWhatsapp(row.telefone));
                            setLoadingNovoChat(false);
                            navigate(`${PATH_CHAT.chat.root}`);
                        } catch (error) {
                            console.log(error)
                            setLoadingNovoChat(false)
                        }
        
                    }
                } catch (error) {
                    enqueueSnackbar('Erro ao criar chat', { variant: 'error' });

                    console.log(error)
                    setLoadingNovoChat(false)
                }
            }


            async function buscarListaClientesCanais() {
                try {
                    const response_clientes_canais = await actionListaClientesCanais(cliente._id)
                    console.log(response_clientes_canais.data.data)
                    setListaClientesCanais(response_clientes_canais.data.data);
                } catch (err) {
                    console.log(err)
                }
            }

            async function buscarCanais() {
                const response_clientes_canais = await actionListaClientesCanais(user.clienteId);
                setCanais(response_clientes_canais.data.data);
                setSelectedCanal(response_clientes_canais.data?.data[0]);
            }


            useEffect(() => {
                buscarCanais();
                buscarListaClientesCanais();
                dispatch(getBuscarContatos(cliente._id))
                dispatch(getListaUsuariosByClienteId(user.clienteId));
            }, []);


            const handleChangePage = (event, newPage) => {
                console.log('newPage', newPage)
                setPage(newPage);
            };

            const handleRequestSort = (event, property) => {
                const isAsc = orderBy === property && order === 'asc';
                setOrder(isAsc ? 'desc' : 'asc');
                setOrderBy(property);
            };

            const handleSelectAllClick = (event) => {
                if (event.target.checked) {
                    const newSelecteds = contatos.map((n) => n.nome);
                    setSelected(newSelecteds);
                    return;
                }
                setSelected([]);
            };

            const handleChangeRowsPerPage = (event) => {
                setRowsPerPage(parseInt(event.target.value, 10));
                setPage(0);
            };


            async function handlePesquisarContatos() {
                try {
                    dispatch(getPesquisarContatos(cliente._id, pesquisa))
                } catch (error) {
                    console.log(error);
                }
            }

            const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - usuariosList.length) : 0;
            const filteredUsers = applyFilter(tableData, pesquisa);
            const isUserNotFound = filteredUsers?.length === 0;
            const [openModalUpload, setOpenModalUpload] = useState(false);


            const handleExportarContatos = async () => {
                try {
                    const response = await v2_actionExportarContatos(cliente._id);
                    const url = window.URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', `lais-contatos-leads.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                } catch (error) {
                    console.log(error)
                }
            }

            return (
                <Container maxWidth="lg">
                    {
                        contatoData && (
                            <ModalVerContato
                                open={openModalVerContato}
                                onClose={() => setStateModalVerContato(false)}
                                contatoData={contatoData}
                                cliente={cliente}
                                user={user}
                                listaClientesCanais={[]}
                                loading={false}
                                callBackRefreshList={() => {
                                    setStateModalVerContato(false);
                                    dispatch(getBuscarContatos(cliente._id))
                                }}
                            />
                        )
                    }
                    <ModalUploadContatos
                        cliente={cliente}
                        open={openModalUpload}
                        onClose={() => setOpenModalUpload(false)}
                    />
                    <ModalConfirmChannel
                        open={openModalCriarChat}
                        onClose={() => {
                            setOpenModalCriarChat(false);
                            setContatoChat(null);
                        }}
                        
                        contato={contatoChat}
                        cliente={cliente}
                        user={user}
                        onOpenChange={(value) => setOp(value)}
                        canais={canais}
                        enqueueSnackbar={enqueueSnackbar}
                        navigate={navigate}
                    />

                    <div className='my-7'>
                        <HeaderPageComponentConfig
                            titulo="Contatos"
                            descricao={'Gerencie seus contatos com facilidade. Crie, importe e exporte contatos para sua lista.'}
                            actions={<Button onClick={() => {
                                dispatch(setContato({ loading: false, openModal: true }))
                            }}
                            >Novo Contato</Button>}
                        />
                    </div>


                    <Card>
                        <Box sx={{ display: 'flex', borderRadius: 1, alignItems: 'center', height: 70, bgcolor: theme => theme.palette.grey[200] }}>
                            <Container maxWidth>
                                <Stack justifyContent={"space-between"} direction="row">
                                    <Stack spacing={1} direction="row">
                                        <Box>
                                            <Input
                                                className="bg-white"
                                                placeholder="Pesquisar"
                                                value={pesquisa}
                                                onChange={(e) => setPesquisa(e.target.value)}
                                            />
                                        </Box>
                                        <Button className="h-8" size="icon" variant="outline" onClick={handlePesquisarContatos}>
                                            <Iconify icon="fluent:search-12-filled" />
                                        </Button>
                                    </Stack>
                                    <Stack spacing={1} direction={"row"}>
                                        <Button onClick={() => setOpenModalUpload(true)} variant="outline"><Iconify className="mr-2" icon="ic:outline-cloud-upload" /> Importar</Button>
                                        <Button onClick={handleExportarContatos} variant="outline"> <Iconify className="mr-2" icon="file-icons:microsoft-excel" />Exportar</Button>
                                    </Stack>
                                </Stack>
                            </Container>
                        </Box>

                        <Container sx={{ mt: 1 }} maxWidth>
                            <Scrollbar>
                                <TableContainer sx={{ minWidth: 300 }}>
                                    <Table>
                                        <ContatosHeadTable
                                            order={order}
                                            orderBy={orderBy}
                                            headLabel={TABLE_HEAD}
                                            rowCount={usuariosList.length}
                                            numSelected={selected.length}
                                            onRequestSort={handleRequestSort}
                                            onSelectAllClick={handleSelectAllClick}

                                        />

                                        {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                            return (
                                                <ContatosRowTable
                                                    key={row._id}
                                                    index={index}
                                                    row={row}
                                                    loadingNovoChat={loadingNovoChat}
                                                    onCriarChat={() => {
                                                        handleCriarChat(row);
                                                    }}
                                                    onVerContato={() => {
                                                        setContatoData(row);
                                                        setStateModalVerContato(true);
                                                    }}
                                                    onOpenChange={(value) => {
                                                        setOpenModalCriarChat(true);
                                                    }}
                                                    openPopover={openPopover}
                                                />
                                            );
                                        })}


                                        {isUserNotFound && (
                                            <TableBody>
                                                <TableRow>
                                                    <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                        <SearchNotFound searchQuery={filterName} />
                                                    </TableCell>
                                                </TableRow>
                                            </TableBody>
                                        )}
                                    </Table>
                                </TableContainer>
                            </Scrollbar>


                        </Container>


                        <TablePagination

                            component="div"
                            labelRowsPerPage="resultados por página"
                            count={filteredUsers?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            rowsPerPageOptions={[10]}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </Card>
                </Container>

            );
        }
