import axios from "axios";

import Config from "../../../config";


const actionSendFileMeta = (platform, data) => {
    const session = "dev-instagram-zindi-1"

   return axios.post(`${Config.urlMeta}/api/v1/meta/${session}/send-file?platform=${platform}`,
    data,
   {headers: { 
    'Authorization': 'Bearer $2b$10$mb9yVuk7Mz2Kpy0AuGaWZ.rdFMjT4rDtmhmCFUKp6Ak1d3POFsuTW'
  }});
};

export default actionSendFileMeta;
