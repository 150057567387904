import axios from "axios";

import Config from "src/config";

const v1_actionEnviarGpt = (data, querys) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/chatgpt/enviar${querys ? querys : ""}`,
        data
    });
};
export default v1_actionEnviarGpt;
