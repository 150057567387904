// material
import {
    Alert,
    Autocomplete,
    Button,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    MenuItem, Select, Stack, Typography, Drawer, Container, IconButton,
    Accordion,
    AccordionSummary
} from '@mui/material';
import './react-phone-number-input.css'
import { LoadingButton } from '@mui/lab';
import { useState, forwardRef } from 'react';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import getActionCriarContato from 'src/actions/chats/getActionCriarContato';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import { fDate, isDateValid } from 'src/utils/formatTime';
import { maskCPF, maskDate } from 'src/utils/functions';
import NovoChatForm from './NovoChatForm';
// ----------------------------------------------------------------------

export default function NovaEmpresaForm({
    user,
    cliente,
    open,
    listaClientesCanais,
    loading,
    onClose,
    callbackAddEmpresa,
    callbackAddContact,
    showSignupButton,
    loadingNovoContato,
    showAddContact,
    showAddEmpresa
}) {


    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');
    const [empresa, setEmpresa] = useState({
        nome: '',
        cnpj: '',
        telefone: '',
        email: '',
        endereco: {
            cep: "",
            rua: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            complemento: ""
        }
    });

    const [contatosEmpresa, setContatosEmpresa] = useState([]);



    const handleCallbackAddEmpresa = () => {
        let data = {
            empresa
        }
        callbackAddEmpresa(data)
        clearFields();

    }


    function clearFields() {
        setEmpresa({
            nome: '',
            cnpj: '',
            telefone: '',
            email: '',
            endereco: {
                cep: "",
                rua: "",
                numero: "",
                bairro: "",
                cidade: "",
                estado: "",
                complemento: ""
            }
        })
    }

    const handleOnBlurSearchCep = async (zipCode) => {
        try {
            const address = await buscarCep(zipCode)
            if (!address.erro) {
                setEmpresa({
                    ...empresa,
                    endereco: {
                        ...empresa.endereco,
                        cep: address.cep,
                        rua: address.logradouro,
                        bairro: address.bairro,
                        cidade: address.localidade,
                        estado: address.uf,
                    }
                })


            }
        } catch (error) {
            console.log('erro ao buscar cep', error)
        }
    }



    return (
        <div>

            <ModalAlerta
                open={openModalAlerta}
                mensagem={mensagemModalAlerta}
                onClose={() => {
                    setOpenModalAlerta(false);
                    setMensagemModalAlerta('');
                }}
            />


            <Stack direction="column" spacing={1}>
                <Stack
                    sx={{ width: { xs: '100%', md: '100%' } }}
                    spacing={1}
                    direction={"row"}
                >
                    <Box sx={{ width: '100%' }}>
                        <RowInput
                            isRequired={true}
                            label="Nome da empresa"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({ ...empresa, nome: e.target.value })
                                }}
                                value={empresa.nome}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                </Stack>



                <Stack
                    sx={{ width: { xs: '100%', md: '100%' } }}
                    spacing={1}
                    direction={"row"}
                >
                    <Box sx={{ width: '50%' }}>
                        <RowInput
                            isRequired={true}
                            label="Telefone"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({ ...empresa, telefone: e.target.value })
                                }}
                                value={empresa.telefone}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                    <Box sx={{ width: '50%' }}>
                        <RowInput
                            label="CNPJ"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({ ...empresa, cnpj: e.target.value })
                                }}
                                value={empresa.cnpj}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                </Stack>

                <Stack
                    sx={{ width: { xs: '100%', md: '100%' } }}
                    spacing={1}
                    direction={"row"}
                >
                    <Box sx={{ width: '100%' }}>
                        <RowInput
                            label="Email"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({ ...empresa, email: e.target.value })
                                }}
                                value={empresa.email}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                </Stack>


                {showAddContact && (
                    <Accordion
                        sx={{
                            border: '1px solid #ccc',
                            backgroundColor: '#f9f9f9',
                        }}
                        style={{ boxShadow: "none" }}
                    >
                        <AccordionSummary
                            sx={{
                                minHeight: 40,
                                border: 'none',
                            }}
                            style={{ borderRadius: 10 }}
                            expandIcon={<Iconify icon="mdi:plus" />}
                            expandIconPosition={<Iconify icon="mdi:close" />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                        >
                            <Typography sx={{ fontSize: 12 }}>Adicionar contato</Typography>
                        </AccordionSummary>
                        <Box sx={{ px: 2 }}>


                            <NovoChatForm
                                cliente={cliente}
                                hideSelectEmpresa={true}
                                showSaveButton={true}
                                callbackAddContact={data => addContact(data)}
                            />
                            <Stack direction="row">

                            </Stack>
                        </Box>
                    </Accordion>
                )}


                {contatosEmpresa.length > 0 && (
                    contatosEmpresa.map((contato, index) => (
                        <Box key={contato.contato.whatsapp}>
                            <Stack direction="row" justifyContent="space-between">
                                <Stack direction={'column'}>
                                    <Typography>{contato.contato.nome}</Typography>
                                    <Typography>{contato.contato.whatsapp}</Typography>
                                </Stack>


                                <IconButton onClick={() => console.log('index', index)}>
                                    <Iconify icon="mdi:delete" />
                                </IconButton>
                            </Stack>

                        </Box>
                    ))
                )}


                <Divider sx={{ my: 3, borderStyle: 'dashed' }} />


                <Stack
                    sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                    spacing={1}
                    direction={"row"}
                >
                    <Box sx={{ width: '50%' }}>
                        <RowInput
                            label="CEP"

                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({
                                        ...empresa, endereco: {
                                            ...empresa.endereco,
                                            cep: e.target.value
                                        }
                                    })
                                }}
                                onBlur={e => handleOnBlurSearchCep(e.target.value)}
                                value={empresa?.endereco?.cep}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                </Stack>

                <Stack
                    sx={{ width: { xs: '100%', md: '100%' } }}
                    spacing={1}
                    direction={"row"}
                >
                    <Box sx={{ width: '100%' }}>
                        <RowInput
                            label="Rua"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({
                                        ...empresa, endereco: {
                                            ...empresa.endereco,
                                            rua: e.target.value
                                        }
                                    })
                                }}
                                name="nomeEmpresa"
                                value={empresa?.endereco?.rua}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                </Stack>


                <Stack
                    sx={{ width: { xs: '100%', md: '100%' } }}
                    spacing={1}
                    direction={"row"}
                >
                    <Box sx={{ width: '25%' }}>
                        <RowInput
                            label="Número"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({
                                        ...empresa, endereco: {
                                            ...empresa.endereco,
                                            numero: e.target.value
                                        }
                                    })
                                }}
                                value={empresa?.endereco?.numero}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                    <Box sx={{ width: '75%' }}>
                        <RowInput
                            label="Bairro"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({
                                        ...empresa, endereco: {
                                            ...empresa.endereco,
                                            bairro: e.target.value
                                        }
                                    })
                                }}
                                value={empresa?.endereco?.bairro}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>

                </Stack>


                <Stack
                    sx={{ width: { xs: '100%', md: '100%' } }}
                    spacing={1}
                    direction={"row"}
                >
                    <Box sx={{ width: '40%' }}>
                        <RowInput
                            label="Complemento"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({
                                        ...empresa, endereco: {
                                            ...empresa.endereco,
                                            complemento: e.target.value
                                        }
                                    })
                                }}
                                value={empresa?.endereco?.complemento}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                    <Box sx={{ width: '40%' }}>
                        <RowInput
                            label="Cidade"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({
                                        ...empresa, endereco: {
                                            ...empresa.endereco,
                                            cidade: e.target.value
                                        }
                                    })
                                }}
                                name="nomeEmpresa"
                                value={empresa?.endereco?.cidade}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                    <Box sx={{ width: '20%' }}>
                        <RowInput
                            label="Estado"
                            input={<InputStyle
                                onChange={e => {
                                    setEmpresa({
                                        ...empresa, endereco: {
                                            ...empresa.endereco,
                                            estado: e.target.value
                                        }
                                    })
                                }}
                                value={empresa?.endereco?.estado}
                                variant="outlined"
                                size="small"
                                fullWidth
                            />}
                        />
                    </Box>
                </Stack>


            </Stack>



            {showAddEmpresa && ([
                <Stack direction="row" sx={{ mt: 5, mb: 2 }}>
                    <LoadingButton
                        loading={loadingNovoContato}
                        onClick={handleCallbackAddEmpresa}
                        sx={{ height: 30, fontSize: 12, borderRadius: 0.5 }}
                        variant="contained"
                        size="small"
                        startIcon={<Iconify icon="mdi:plus" />}
                    >
                        Add Empresa
                    </LoadingButton>
                </Stack>
            ])}





        </div>
    );
}
