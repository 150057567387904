import React, { useState, useCallback, useMemo } from 'react';
import { Stack } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from "src/@/components/ui/button";
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "src/@/components/ui/popover";
import { TagItem } from 'src/sections/@chats/chat/TagItem';
import { useNavigate } from 'react-router';
import { Checkbox } from 'src/@/components/ui/checkbox';
import { mergeTags } from 'src/utils/chats.utils';
import { ScrollArea } from 'src/@/components/ui/scroll-area';
import v2_actionEditarChat from 'src/actions/v2/chats/v2_actionEditarChat';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import useAuth from 'src/hooks/useAuth';
import { getChatInfoByChatId, getChatsAtendimento, getChatsFinalizados, getChatsGeral, getChatsPendente } from 'src/redux/slices/chat';
import { useSnackbar } from 'notistack';
import { Input } from 'src/@/components/ui/input';
import debounce from 'lodash.debounce';

const TagsComponent = React.memo(({
    children,
    sx,
    inicial,
    final,
    changeInicial,
    changeFinal,
    onPesquisar,
    tags,
    chatId,
    chat,
    ...other
}) => {
    const navigate = useNavigate();
    const { listaTags } = useSelector((state) => state.chat);
    const [loadingTags, setLoadingTags] = useState(false);
    const { user } = useAuth();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [newTagsChats, setNewTagsChats] = useState(() => mergeTags(listaTags, tags));
    const [searchTerm, setSearchTerm] = useState('');

    const handleCheckboxChange = useCallback((id, value) => {
        setNewTagsChats(prevTags =>
            prevTags.map(tag =>
                tag?._id === id ? { ...tag, checked: value } : tag
            )
        );
    }, []);

    const handleSearchChange = useCallback(debounce((event) => {
        setSearchTerm(event.target.value);
    }, 300), []);

    const filteredTags = useMemo(() => newTagsChats.filter(tag =>
        tag.nome.toLowerCase().includes(searchTerm.toLowerCase())
    ), [newTagsChats, searchTerm]);

    const onEditarTags = useCallback(async (ids) => {
        try {
            let data = {
                scope: { tags: ids }
            };
            await v2_actionEditarChat(chatId, data);
            setLoadingTags(true);
            console.log(chat?.statusRoom);
            if (chat?.statusRoom === "Atendendo") dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
            if (chat?.statusRoom === "Geral") dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 50, false));
            if (chat?.statusRoom === "Pendente") dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 10, false));
            if (chat?.statusRoom === "Finalizado") dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 10, false));
            dispatch(getChatInfoByChatId(chatId, null, user.id, false));
            enqueueSnackbar('Tag atualizada com sucesso', { variant: 'success', autoHideDuration: 1500, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            setTimeout(() => {
                setLoadingTags(false);
            }, 900);

        } catch (error) {
            setLoadingTags(false);
            console.log(error);
        }
    }, [chatId, chat, dispatch, enqueueSnackbar, user]);

    return (
        <div className='mt-2'>
            <Stack direction="row" spacing={2}>
                <div className='flex flex-row'>
                    {tags?.length > 0 && (
                        <div className="flex flex-row gap-0.5">
                            {tags.slice(0, 3).map((tag, index) => (
                                <TagItem
                                    key={index}
                                    nomeTag={tag.nome}
                                />
                            ))}
                        </div>
                    )}
                    <Popover className="z-50">
                        <PopoverTrigger asChild>
                            <Button className="h-6 px-2 ml-0.5" variant="outline">
                                <Iconify icon="mdi:tag-plus" />
                                {tags.length > 3 && <>+{tags?.length - 3}</>}
                            </Button>
                        </PopoverTrigger>
                        <PopoverContent className="w-72 ml-5 ">
                            <div className="grid gap-4">
                                <div className="space-y-0.5 flex-row flex justify-between items-center ">
                                    <div className="font-bold text-sm leading-none">Etiquetas</div>
                                    <Button
                                        onClick={() => navigate('/dashboard/configuracoes/tags')}
                                        className="text-xs h-6"
                                        variant="secondary"
                                    >
                                        Criar Etiqueta
                                    </Button>
                                </div>
                                <div className="grid gap-2 bg-slate-50 p-2 rounded-sm">
                                    <Input
                                        type="text"
                                        placeholder="Pesquisar etiquetas"
                                        value={searchTerm}
                                        onChange={handleSearchChange}
                                        className="w-full mb-2 rounded"
                                    />
                                    <ScrollArea className="h-44">
                                        {filteredTags?.map((i) => (
                                            <div className="flex items-center gap-2 gap-y-2 mt-2" key={i._id}>
                                                <Checkbox
                                                    id={`tag-${i._id}`}
                                                    checked={i.checked}
                                                    onCheckedChange={value => handleCheckboxChange(i._id, value)}
                                                />
                                                <label
                                                    htmlFor={`tag-${i._id}`}
                                                    className="text-xs capitalize font-medium"
                                                >
                                                    {i.nome}
                                                </label>
                                            </div>
                                        ))}
                                    </ScrollArea>
                                </div>
                            </div>
                            <ButtonLoading
                                loading={loadingTags}
                                variant="secondary"
                                className="w-full mt-2 bg-slate-300"
                                onClick={() => {
                                    onEditarTags(newTagsChats.map((tag) => {
                                        let newObj = {
                                            _id: tag._id,
                                            checked: tag.checked
                                        };
                                        return newObj;
                                    }).filter((tag) => tag.checked === true)
                                        .map((tag) => tag._id));
                                }}
                            >
                                Salvar
                            </ButtonLoading>
                        </PopoverContent>
                    </Popover>
                </div>
            </Stack>
        </div>
    );
});

export default TagsComponent;