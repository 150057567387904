import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { List, Stack, Button, IconButton, ListItemText, ListItem, Typography } from '@mui/material';
// utils
import { fData } from '../../utils/formatNumber';
//
import Image from '../Image';
import Iconify from '../Iconify';
import { varFade } from '../animate';

// ----------------------------------------------------------------------

const getFileData = (file) => {
  if (typeof file === 'string') {
    return {
      key: file,
    };
  }
  return {
    key: file.name,
    name: file.name,
    size: file.size,
    preview: file.preview,
  };
};


export default function MultiPreviewNamesList({ showPreview = true, files, onRemove, onRemoveAll }) {
  const hasFile = files?.length > 0;

  console.log('files', files.length)

  return (
    <>
      <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>

        {files.map((file, index) => {
                      const { key, name, size, preview } = getFileData(file);

          return (
            
            <ListItem
            key={index}
            component={m.div}
          >

            <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

            <ListItemText
              primary={isString(file) ? file : name}
              secondary={isString(file) ? '' : fData(size || 0)}
              primaryTypographyProps={{ variant: 'subtitle2' }}
              secondaryTypographyProps={{ variant: 'caption' }}
            />

            <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
              <Iconify icon={'eva:close-fill'} />
            </IconButton>
          </ListItem>
          
        )})}
    
      </List>

    </>
  );
}
