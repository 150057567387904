import axios from "axios";
import Config from "../../../config";

const v2_actionListaColunasCrm = (querys) => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/crm/leads/lista-colunas${querys ? querys : ''}`
    });
};

export default v2_actionListaColunasCrm;
