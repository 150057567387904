import axios from "axios";

import Config from "../../config";

const actionListaCampanhas = clienteId => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/campanhas/${clienteId}/lista`,   
    });
};

export default actionListaCampanhas;
