import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {  TableRow, TableCell, IconButton } from '@mui/material';
// utils
import {  fDateTime } from 'src/utils/formatTime';

// components
import Label from 'src/components/Label';

import { BiRightArrowAlt, BiSolidError } from 'react-icons/bi';



// ----------------------------------------------------------------------

ChamadosRowTable.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function ChamadosRowTable({ row, selected, onSelectRow, onViewRow, onEditRow, onDeleteRow }) {
  const theme = useTheme();

  const { assunto, titulo, prioridade, status, data_criacao, nome_autor, completado, chamado, ultima_modificacao, data_para_conclusao } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };





  return (
    <TableRow sx={{backgroundColor:new Date() > new Date(data_para_conclusao) ? "#FFEAED" : "#fff" }} hover selected={selected}>

      <TableCell align="left">#{chamado}</TableCell>

      <TableCell align="left">{assunto}</TableCell>

      <TableCell align="left">{status}</TableCell>

      <TableCell align="left">{fDateTime(data_criacao)}</TableCell>
      <TableCell align="left">{fDateTime(data_para_conclusao)}</TableCell>
      <TableCell align="left">{fDateTime(ultima_modificacao)}</TableCell>
  

      <TableCell align="left">{nome_autor}</TableCell>


      <TableCell sx={{ textTransform: 'capitalize' }}>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (completado === true && 'success') ||
            (completado === false && 'error') ||
            'error'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {completado ? 'Sim' : 'Não'}
        </Label>
      </TableCell>

      <TableCell align="right">
        <IconButton
          onClick={() => {
            onViewRow();
            handleCloseMenu();
          }}>
          <BiRightArrowAlt />
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
