import { useEffect, useState, useRef } from 'react';
// material
import { Stack, Table, Box, TableRow, TableHead, TableBody, TableCell, TableContainer, IconButton, Grid, TextField, Button, Typography, Paper, Container, Card } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import Scrollbar from 'src/components/Scrollbar';

import { useDispatch, useSelector } from 'src/redux/store';
// configs
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';
import ForcaVendaResumoWidget from 'src/components/_dashboard/relatorios/forcaVendas/ForcaVendaResumoWidget';
import actionMetricasForcaVendas from 'src/actions/dashboard/relatorios/actionMetricasForcaVendas';
import actionMetricasForcaVendasUsuarios from 'src/actions/dashboard/relatorios/actionMetricasForcaVendasUsuarios';
import useTable, { emptyRows, getComparator } from 'src/hooks/useTable';
import { TableBorder, TableEmptyRows, TableHeadCustom, TableNoData } from 'src/components/table';
import Breadcrumbs from 'src/components/Breadcrumbs';
import actionListaClientesFv from 'src/actions/dashboard/relatorios/actionListaClientesFv';
import MenuDatas from 'src/components/MenuDatas';
import { validaLabelMenuDatas } from 'src/utils/functions';
import ClientesForcaVendasRow from 'src/sections/@dashboard/relatorios/ClientesForcaVendasRow';

export default function RelatorioForcaVendasClientes() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const { somaChatsByStatus } = useSelector((state) => state.dashboard);
  const { user } = useAuth();
  const [inicial, setInicial] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const [atendimentosFinalizados, setAtendimentosFinalizados] = useState(0);
  const [atendimentosTransferidos, setAtendimentosTransferidos] = useState(0);
  const [ticketsFinalizados, setTicketsFinalizados] = useState(0);
  const [tempoMedioEspera, setTempoMedioEspera] = useState(0);
  const [tempoMedioTotal, setTempoMedioTotal] = useState(0);
  const [listaMetricasAtendimetosUsuarios, setListaMetricasAtendimetosUsuarios] = useState([]);
  const multiSession = localStorage.getItem("multiSession");
  const clienteIdActive = localStorage.getItem("clienteIdActive");
  //força vendas
  const [metricasForcaVendas, setMetricasForcaVendas] = useState(null);
  const [filterName, setFilterName] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [tableData, setTableData] = useState([]);

  const [periodo, setPeriodo] = useState('mes_atual')
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuDatas = Boolean(anchorEl);


  const dataRelatorio = {
    atendimentosFinalizados,
    atendimentosTransferidos,
    ticketsFinalizados,
    tempoMedioTotal,
    tempoMedioEspera,
    somaChatsByStatus
  }

  const datesRelatorio = {
    inicial,
    final
  }

  const TABLE_HEAD = [
    { id: 'nome', label: 'Nome Cliente' },
    { id: 'telefone', label: 'Telefone' },
    { id: 'data_visita', label: 'Data visita' },
    { id: 'atendente', label: 'Atendente' },
    { id: 'convertido', label: 'Convertido' },
    { id: '' },
  ];


  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
  } = useTable({ defaultOrderBy: 'date_created', defaultRowsPerPage: 25 });

  useEffect(() => {
    buscarListaForcaVendas(periodo)
  }, []);

  const buscarListaForcaVendas = async (value) => {
    try {
      let inicio2 = moment(inicial).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${value}&cliente_id=${user.clienteId}&final=${inicio2}&final=${final2}`
      const res = await actionListaClientesFv(querys);

      console.log('result buscarListaForcaVendas', res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  const onFiltrar = async (value, action) => {
    setPeriodo(value)
    buscarListaForcaVendas(value)
  }

  const onPesquisar = () => {
    buscarListaForcaVendas('null');
    setPeriodo('null')
  }




  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterName,
    filterStartDate,
    filterEndDate,
  });

  const denseHeight = dense ? 56 : 76;
  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  return (
    <Box sx={{mt:5}} title={nomeProjeto}>
      <Container maxWidth>
        <Breadcrumbs
          backButton={() => navigate('/dashboard/relatorios')}
          links={[{ name: 'Relatorios', href: '/dashboard/relatorios' }, { name: 'Relatorio Clientes Força Vendas' }]}
        />
      </Container>
      <Container sx={{ mt: 2 }} maxWidth>
        <Box sx={{ pt: 1, pb: 1 }}>
          <Stack direction="row" spacing={2} alignItems="center" >

            <Paper
              component="form"
              sx={{ p: '10px 10px', display: 'flex', alignItems: 'center' }}
            >
              <Button

                startIcon={<Iconify icon="solar:calendar-date-bold-duotone" />}
                endIcon={<Iconify icon="bxs:down-arrow" sx={{ width: 10, height: 10 }} />}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                {validaLabelMenuDatas(periodo, inicial, final)}
              </Button>

            </Paper>

            <MenuDatas
              anchorEl={anchorEl}
              open={openMenuDatas}
              onClose={() => setAnchorEl(false)}
              inicial={inicial}
              final={final}
              changeInicial={newValue => setInicial(newValue)}
              changeFinal={newValue => { setFinal(newValue) }}
              onSelect={value => {
                setPeriodo(value);
                setAnchorEl(null);
                onFiltrar(value)
              }}
              onPesquisar={() => onPesquisar('null')}
            />

          </Stack>
        </Box>

        <Card sx={{ background: '#fff', p: 3, mt: 2, boxShadow: '0px 0px 40px 0px #18225833', minHeight: 'calc(98vh - 150px)', }}>
          <TableBorder>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                  />
                  <TableBody>
                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <ClientesForcaVendasRow
                        key={row.numero}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onViewRow={() => handleOpenDetails(row)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}

                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </TableBorder>

          <Stack>
          </Stack>
        </Card>
      </Container>
    </Box>
  );
}


function applySortFilter({
  tableData,
  comparator,
  filterName,
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterName) {
    tableData = tableData.filter(
      (item) =>
        item.cpf.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.full_name.toLowerCase().indexOf(filterName.toLowerCase()) !== -1 ||
        item.number.toString().indexOf(filterName) !== -1
    );
  }


  return tableData;
}

