import { TableHead, TableRow, TableCell, TableHeader } from 'src/@/components/ui/table';

// ----------------------------------------------------------------------

export default function VendasHeadTable({
  order,
  orderBy,
  headLabel,
  onRequestSort
}) {
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHeader >
      <TableRow >
        {headLabel.map((headCell) => (
          <TableCell className="font-semibold" >
         {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHeader>
  );
}
