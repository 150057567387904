// utils

import jwtDecode from "jwt-decode";
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';

// actions
import getActionLoginUsuario from '../actions/auth/getActionLoginUsuario';
import setAuthToken from '../actions/chats/staff/setAuthToken';
import getActionClienteByClienteId from '../actions/clientes/getActionClienteByClienteId';
import axios from '../utils/axios';
import { setSession } from '../utils/jwt';
import actionListaClientesCanais from "src/actions/clientes/actionListaClientesCanais";

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  cliente: null, 
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, cliente } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      cliente
    };
  },
  LOGIN: (state, action) => {
    const { user, cliente } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      cliente
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    cliente: null
  }),
  REGISTER: (state, action) => {
    const { user } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      cliente
    };
  }
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve()
});

AuthProvider.propTypes = {
  children: PropTypes.node
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const initialize = async () => {
      try {
        const accessToken = window.localStorage.getItem('token');
        const clienteData = window.localStorage.getItem('cliente_data');

        if (accessToken) {
          setSession(accessToken);
          setAuthToken(accessToken)

          const user = jwtDecode(accessToken);

          if (user.exp < new Date() / 1000) {
            dispatch({
              type: 'INITIALIZE',
              payload: {
                isAuthenticated: false,
                user: null,
                cliente: null
              }
            });
          }

          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              cliente:JSON.parse(clienteData)
            }
          });

          
        } else {
          console.log('nao autenticado')
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              cliente: null
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            cliente: null
          }
        });
      }
    };

    initialize();
  }, []);

  const login = async (email, password, checkManterLogado) => {
 
     try {
      const response = await getActionLoginUsuario({ email: email, password: password });

      const { token } = response.data;
      localStorage.setItem('token', response.data.token);
      localStorage.setItem('user', JSON.stringify(jwtDecode(response.data.token)));
      setAuthToken(response.data.token)
      const user = jwtDecode(response.data.token);
  
      const responseCliente = await getActionClienteByClienteId(user.clienteId);
      const response_clientes_canais = await actionListaClientesCanais(user.clienteId);

      let listaNumerosSubs = response_clientes_canais.data.numeros_subs;
      const numeros_subs_reduce = listaNumerosSubs.map((item) => item.nome)

      const newClienteObj = {
        ...responseCliente.data.cliente, 
        clientes_canais:responseCliente.data.cliente.multiSession === 1 ?  response_clientes_canais.data.data : [], 
        numeros_subs: responseCliente.data.cliente.multiSession === 1 ?  numeros_subs_reduce : [],
        numeros_subs_data: responseCliente.data.cliente.multiSession === 1 ?  listaNumerosSubs : [] 
      }

      localStorage.setItem('cliente_data', JSON.stringify(newClienteObj));
      localStorage.setItem('zindi-data', `{"session":"${responseCliente.data.cliente.sessao}","token":"${responseCliente.data.cliente.token}"}`);
      localStorage.setItem('numberId', responseCliente.data.cliente.numero);
      localStorage.setItem('usuarioId', responseCliente.data.cliente.usuario);
      localStorage.setItem('pod', responseCliente.data.cliente.pod);
      localStorage.setItem('clienteIdActive', responseCliente.data.cliente._id);
      localStorage.setItem('multiSession', responseCliente.data.cliente.multiSession);
      localStorage.setItem('marketplaceId', responseCliente.data.cliente.marketplace_id);
      localStorage.setItem('soundNotification', "true");

      if (checkManterLogado) {
        localStorage.setItem('credenciais', JSON.stringify({ email, password, checkManterLogado }));
      } else {
        localStorage.removeItem('credenciais');
      }
      setSession(token);

   
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          cliente: newClienteObj
        },
      });
      window.location.href = "/sync";
      return response;
     } catch (error) {
      console.log('erro ao login context', error)
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: false,
          user: null
        }
      });
       
     }
  };


  const logout = async () => {
    setSession(null);
    localStorage.removeItem("user");
    localStorage.removeItem("zindi-data");
    localStorage.removeItem("cliente_data");
    localStorage.removeItem("usuarioId");
    localStorage.removeItem("token");
    localStorage.removeItem("pod");
    localStorage.removeItem("clienteIdActive");
    localStorage.removeItem("multiSession");
    localStorage.removeItem("numberId");
    localStorage.removeItem("marketplaceId");
    localStorage.removeItem("multiSession");
    dispatch({ type: 'LOGOUT' });
  };

  const resetPassword = () => { };
  const updateProfile = () => { };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        resetPassword,
        updateProfile
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
