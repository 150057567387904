import { Box, Container, Link, Stack, TableContainer, TablePagination } from "@mui/material"
import {
    ChevronLeft,
    ChevronRight,
    Copy,
    CreditCard,
    File,
    Home,
    LineChart,
    ListFilter,
    MoreVertical,
    Package,
    Package2,
    PanelLeft,
    Search,
    Settings,
    ShoppingCart,
    Truck,
    Users2,
} from "lucide-react"
import { useState } from "react"

import { Badge } from "src/@/components/ui/badge"
import {
    Breadcrumb,
    BreadcrumbItem,
    BreadcrumbLink,
    BreadcrumbList,
    BreadcrumbPage,
    BreadcrumbSeparator,
} from "src/@/components/ui/breadcrumb"
import { Button } from "src/@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card"

import { Input } from "src/@/components/ui/input"
import {
    Pagination,
    PaginationContent,
    PaginationItem,
} from "src/@/components/ui/pagination"
import { Progress } from "src/@/components/ui/progress"
import { Separator } from "src/@/components/ui/separator"
import { Sheet, SheetContent, SheetTrigger } from "src/@/components/ui/sheet"
import {
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from "src/@/components/ui/table"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "src/@/components/ui/tabs"
import EmptyContentGhost from "src/components/EmptyContentGhost"
import Iconify from "src/components/Iconify"
import Scrollbar from "src/components/Scrollbar"
import SearchNotFound from "src/components/SearchNotFound"
import { setContato } from "src/redux/slices/global"

import VendasHeadTable from "src/sections/@dashboard/vendas/VendasHeadTable"
import VendasRowTable from "src/sections/@dashboard/vendas/VendasRowTable";
import { fDateTime } from "src/utils/formatTime"


const pedidos = [
    {

        "id_pedido": "NEI2WIO2WOU2KOU5",
        "data": "2024-07-03 17:18:48",
        "prazo_producao": "0",
        "status": {
            "id": "1",
            "nome": "Pedido pendente",
            "data": "2024-07-03 17:18:48",
            "class": "text-info",
            "info": "API",
            "info_reject": ""
        },
        "data_criacao": "2024-07-03 17:18:48",
        "observacoes": "",
        "observacoes_internas": "",
        "mensagem_presente": "",
        "vendedor": "",
        "nota_fiscal": [],
        "adicional": [],
        "ip": "",
        "user_agent": "",
        "loja": {
            "id": "23505",
            "identificacao": "bhbalas",
            "cidade": "Contagem",
            "uf": "MG",
            "multiloja": "0",
            "multicd": "0",
            "nome": "BH Balas",
            "cep_origem": "",
            "url": "https://www.pedidosbhbalas.com.br",
            "url_interna_matriz": "",
            "logo": "https://cdn.sistemawbuy.com.br/arquivos/bf7f0e0b3af5b13655fe6d09511cd080/config/logo-bh-balas-nova-391.png",
            "isFilial": ""
        },
        "owner": {
            "id": "23505",
            "identificacao": "bhbalas",
            "cidade": "Contagem",
            "uf": "MG",
            "multiloja": "0",
            "multicd": "0",
            "nome": "BH Balas",
            "cep_origem": "",
            "url": "https://www.pedidosbhbalas.com.br",
            "url_interna_matriz": "",
            "logo": "https://cdn.sistemawbuy.com.br/arquivos/bf7f0e0b3af5b13655fe6d09511cd080/config/logo-bh-balas-nova-391.png",
            "isFilial": ""
        },
        "sem_senha": "0",
        "is_mobile": "0",
        "is_delivery": "0",
        "impresso": "0",
        "primeira_compra": "0",
        "assinaturas_id": "",
        "total_itens": "8",
        "pagamento": {
            "id": "20",
            "servico": "A Combinar",
            "tipo": "",
            "tipo_interno": "pay_later",
            "parcelas": "1",
            "paymentLink": "",
            "linha_digitavel": "",
            "confirmed": "0000-00-00 00:00:00",
            "pagamento_ate": "",
            "info": {
                "code": "",
                "tid": "",
                "notificationCode": "",
                "nsu": "",
                "authorizationCode": "",
                "card": []
            }
        },
        "antifraude": [],
        "frete": {
            "valor": "0",
            "prazo": "0",
            "estimativa": "2024-07-03",
            "nome": "",
            "rastreio": "",
            "rastreio_url": "https://rastreio.xpagencia.com.br/?objetos=[codigo]",
            "tipo_envio": "0",
            "tipo_envio_nome": "",
            "retirada": {
                "endereco": ""
            },
            "is_schedule": "0",
            "schedule_data": [],
            "hidden_info": ""
        },
        "cupom": {
            "id": "0",
            "cupom": "",
            "tipo": "0",
            "valor": "0"
        },
        "cliente": {
            "id": "5625450",
            "nome": "Joao Victor Rezende Teixeira",
            "fantasia": "",
            "responsavel": "",
            "responsavel_cpf": "",
            "doc1": "128.574.256-78",
            "doc2": "",
            "telefone1": "",
            "telefone2": "",
            "nascimento": "0000-00-00",
            "cep": "31235-190",
            "endereco": "Rua Cassiano Eustáquio Oliveira",
            "numero": "81",
            "bairro": "Residencial Lagoa dos Mandarins",
            "complemento": "casa",
            "cidade": "Divinópolis",
            "uf": "MG",
            "email": "jaoteixeira12@gmail.com",
            "tipo_endereco": "rua",
            "responsavel_endereco": ""
        },
        "produtos": [
            {
                "pid": "5117560",
                "sku": "",
                "qtd": "5",
                "produto_id": "0",
                "sku_id": "0",
                "erp_id": "",
                "cl1": "0",
                "cod_estoque": "",
                "produto": "GRANULADO HARALD MELKEN GRANULE BRANCO 400G",
                "valor": "24.93",
                "valor_de": "0",
                "is_atacado": "0",
                "is_promo": "0",
                "tabela": "2539",
                "cor": "",
                "variacao": "",
                "acabamento": "",
                "campo_adicional": "",
                "variacaoValor": "",
                "peso": "0",
                "frete_gratis": "0",
                "gtin": "",
                "ncm": "",
                "unidade": "",
                "brinde": "0",
                "cod": "",
                "url_download": "",
                "cla_cm3": "0",
                "comprimento": "0",
                "largura": "0",
                "altura": "0",
                "preco_custo": "0",
                "compra_aneis": "",
                "compra_lentes": "",
                "compra_combo": [],
                "compra_delivery": [],
                "campo_anotacao": [],
                "frete_prazo": "0",
                "frete_valor": "0",
                "loja": {
                    "id": "23505",
                    "identificacao": "bhbalas",
                    "cidade": "Contagem",
                    "uf": "MG",
                    "multiloja": "0",
                    "multicd": "0",
                    "nome": "BH Balas",
                    "cep_origem": "",
                    "url": "https://www.pedidosbhbalas.com.br",
                    "url_interna_matriz": "",
                    "logo": "https://cdn.sistemawbuy.com.br/arquivos/bf7f0e0b3af5b13655fe6d09511cd080/config/logo-bh-balas-nova-391.png",
                    "isFilial": ""
                },
                "google_category": "",
                "is_digital": "0",
                "is_orderbump": "0",
                "prazo_entrega": "0",
                "has_giftpack": "0",
                "giftpack_valor": "0",
                "itens_adicionais": [],
                "multicd_id": "0",
                "is_signature": "0",
                "signature_info": [],
                "auxiliar": []
            },
            {
                "pid": "5117560",
                "sku": "",
                "qtd": "3",
                "produto_id": "0",
                "sku_id": "0",
                "erp_id": "",
                "cl1": "0",
                "cod_estoque": "",
                "produto": "BALA ICEKISS TENTACAO MELAO 500G",
                "valor": "6.44",
                "valor_de": "0",
                "is_atacado": "0",
                "is_promo": "0",
                "tabela": "2539",
                "cor": "",
                "variacao": "",
                "acabamento": "",
                "campo_adicional": "",
                "variacaoValor": "",
                "peso": "0",
                "frete_gratis": "0",
                "gtin": "",
                "ncm": "",
                "unidade": "",
                "brinde": "0",
                "cod": "",
                "url_download": "",
                "cla_cm3": "0",
                "comprimento": "0",
                "largura": "0",
                "altura": "0",
                "preco_custo": "0",
                "compra_aneis": "",
                "compra_lentes": "",
                "compra_combo": [],
                "compra_delivery": [],
                "campo_anotacao": [],
                "frete_prazo": "0",
                "frete_valor": "0",
                "loja": {
                    "id": "23505",
                    "identificacao": "bhbalas",
                    "cidade": "Contagem",
                    "uf": "MG",
                    "multiloja": "0",
                    "multicd": "0",
                    "nome": "BH Balas",
                    "cep_origem": "",
                    "url": "https://www.pedidosbhbalas.com.br",
                    "url_interna_matriz": "",
                    "logo": "https://cdn.sistemawbuy.com.br/arquivos/bf7f0e0b3af5b13655fe6d09511cd080/config/logo-bh-balas-nova-391.png",
                    "isFilial": ""
                },
                "prazo_entrega": "0",
                "has_giftpack": "0",
                "giftpack_valor": "0",
                "itens_adicionais": [],
                "multicd_id": "0",
                "is_signature": "0",
                "signature_info": [],
                "auxiliar": []
            }
        ],
        "valor_total": {
            "subtotal": "143.92",
            "desconto": "0",
            "giftpack": "0",
            "acrescimo": "0",
            "frete": "0",
            "total": "143.92"
        },
        "historico": [
            {
                "pid": "5117560",
                "data": "2024-07-03 17:18:48",
                "observacoes": "",
                "status": "1",
                "info": "API",
                "info_reject": "",
                "status_nome": "Pedido pendente"
            }
        ],
        "historico_desconto": [],
        "faturado": false,
        "isPay": false,
        "channel": {
            "id": "2",
            "name": "API",
            "detail": {
                "cnpj": ""
            }
        }
    }
]


const TABLE_HEAD = [
    { id: 'pedido', label: 'Pedido', alignRight: false },
    { id: 'cliente', label: 'Cliente', alignRight: false },
    { id: 'valor_total', label: 'Valor total', alignRight: false },
    { id: 'qtd_produtos', label: 'Qtd Produtos', alignRight: false },
    { id: 'data_criacao', label: 'Data Pedido', alignRight: false },
    { id: '', },
];



function applyFilter(tableData, filterName) {
    return tableData;
}


export function VendasPage() {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [pesquisa, setPesquisa] = useState('');
    const filteredUsers = applyFilter(pedidos, pesquisa);
    const isUserNotFound = filteredUsers?.length === 0;

    const [pedido, setPedido] = useState(null);

    return (
        <div className="flex min-h-screen w-full flex-col bg-muted/80">

            <div className="flex flex-col sm:gap-4 sm:py-4 sm:pl-14">

                <main className="grid flex-1 items-start gap-4 p-4 sm:px-6 sm:py-0 md:gap-8 lg:grid-cols-3 xl:grid-cols-3">
                    <div className="grid auto-rows-max items-start gap-4 md:gap-8 lg:col-span-2">
                        <Card>
                            <CardContent>
                                <div className="py-6 flex flex-row gap-1">
                                    <Box>
                                        <Input
                                            className="bg-white"
                                            placeholder="Pesquisar"
                                            value={pesquisa}
                                            onChange={(e) => setPesquisa(e.target.value)}
                                        />
                                    </Box>
                                    <Button className="h-8" size="icon" variant="outline" onClick={() => { }}>
                                        <Iconify icon="fluent:search-12-filled" />
                                    </Button>
                                </div>
                                <Scrollbar>
                                    <TableContainer sx={{ minWidth: 300 }}>
                                        <Table>
                                            <VendasHeadTable
                                                order={order}
                                                orderBy={orderBy}
                                                headLabel={TABLE_HEAD}
                                                rowCount={pedidos.length}
                                                numSelected={selected.length}


                                            />

                                            {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => {
                                                return (
                                                    <VendasRowTable
                                                        key={row._id}
                                                        index={index}
                                                        row={row}
                                                        onVerPedido={() => setPedido(row)}
                                                        onOpenChange={(value) => { }}
                                                    />
                                                );
                                            })}


                                            {isUserNotFound && (
                                                <TableBody>
                                                    <TableRow>
                                                        <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                            <SearchNotFound searchQuery={filterName} />
                                                        </TableCell>
                                                    </TableRow>
                                                </TableBody>
                                            )}
                                        </Table>
                                    </TableContainer>
                                </Scrollbar>




                                <TablePagination

                                    component="div"
                                    labelRowsPerPage="resultados por página"
                                    count={filteredUsers?.length}
                                    rowsPerPage={rowsPerPage}
                                    page={page}
                                    rowsPerPageOptions={[10]}

                                />

                            </CardContent>
                        </Card>
                    </div>

                    {pedido ? (
                        <div>
                            <Card
                                className="overflow-hidden" x-chunk="dashboard-05-chunk-4"
                            >
                                <CardHeader className="flex flex-row items-start justify-between bg-muted/50">
                                    <div className="grid gap-0.5">
                                        <CardTitle className="group flex items-center gap-2 text-lg">
                                            Pedido {pedido?.id_pedido}
                                            {/* <Button
                                                size="icon"
                                                variant="outline"
                                                className="h-6 w-6 opacity-0 transition-opacity group-hover:opacity-100"
                                            >
                                                <Copy className="h-3 w-3" />
                                                <span className="sr-only">Copy Order ID</span>
                                            </Button> */}
                                        </CardTitle>
                                        <CardDescription>{fDateTime(pedido?.data_criacao)}</CardDescription>
                                    </div>

                                    <Button onClick={() => setPedido(null)} variant='ghost'>
                                        <Iconify icon="mdi:close" />
                                    </Button>

                                </CardHeader>
                                <CardContent className="p-6 text-sm">
                                    <div className="grid gap-3">
                                        <div className="font-semibold">Produtos</div>
                                        <ul className="grid gap-3">
                                            {pedido?.produtos.map((produto, index) => (
                                                <li key={index} className="flex items-center justify-between">
                                                    <span className="text-muted-foreground">
                                                        <span>{produto.qtd} x {produto.produto} </span>
                                                    </span>
                                                    <span>R$ {produto.valor}</span>
                                                </li>
                                            ))}

                                        </ul>
                                        <Separator className="my-2" />
                                        <ul className="grid gap-3">
                                            <li className="flex items-center justify-between">
                                                <span className="text-muted-foreground">Subtotal</span>
                                                <span>$299.00</span>
                                            </li>
                                            <li className="flex items-center justify-between">
                                                <span className="text-muted-foreground">Frete</span>
                                                <span>$0.00</span>
                                            </li>

                                            <li className="flex items-center justify-between font-semibold">
                                                <span className="text-muted-foreground">Total</span>
                                                <span>R$329.00</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <Separator className="my-4" />

                                    <div className="grid gap-3">
                                        <div className="font-semibold">Informações do cliente</div>
                                        <dl className="grid gap-3">

                                            <div className="flex items-center justify-between">
                                                <dt className="text-muted-foreground">Cliente</dt>
                                                <dd>{pedido?.cliente?.nome}</dd>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <dt className="text-muted-foreground">Email</dt>
                                                <dd>
                                                    <dd>{pedido?.cliente?.email}</dd>
                                                </dd>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <dt className="text-muted-foreground">Telefone</dt>
                                                <dd>
                                                    <a href="tel:">{pedido?.cliente?.telefone1 || pedido?.cliente?.telefone2}</a>
                                                </dd>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <dt className="text-muted-foreground">CPF</dt>
                                                <dd>
                                                    <a href="tel:">{pedido?.cliente?.doc1}</a>
                                                </dd>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <dt className="text-muted-foreground">CNPJ</dt>
                                                <dd>
                                                    <a href="tel:">{pedido?.cliente?.doc2}</a>
                                                </dd>
                                            </div>
                                            <div className="flex items-center justify-between">
                                                <dt className="text-muted-foreground">Endereço</dt>
                                                <dd>
                                                    <a href="tel:">{pedido?.cliente?.endereco} {pedido?.cliente?.numero} {pedido?.cliente?.bairro}</a>
                                                </dd>
                                            </div>
                                        </dl>
                                    </div>
                 
                                </CardContent>
                                <CardFooter className="flex flex-row items-center border-t bg-muted/50 px-6 py-3">
                                    <div className="text-xs text-muted-foreground">
                                        Pedido feito via IA
                                    </div>

                                </CardFooter>
                            </Card>
                        </div>
                    ) : (
                        <EmptyContentGhost
                            img={'/static/illustrations/illustration_empty_content.svg'}
                            title="Selecione um pedido"
                        />
                    )}

                </main>
            </div>
        </div>
    )
}
