
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/Iconify';
import { useSnackbar } from 'notistack';

//mui
import { Box, Stack, Container, Stepper, Step, StepLabel } from '@mui/material';
//zui 
import { Button } from 'src/@/components/ui/button';
import { Card, CardContent } from 'src/@/components/ui/card';
import { Label } from 'src/@/components/ui/label';
import { Input } from 'src/@/components/ui/input';
import { RowInput } from 'src/components/forms/RowInput';
import { RadioGroup, RadioGroupItem } from 'src/@/components/ui/radio-group';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select"
import { Slider } from 'src/@/components/ui/slider';
import { Textarea } from 'src/@/components/ui/textarea';
import { Switch } from 'src/@/components/ui/switch';
// actions
import v2_actionAiConfig from 'src/actions/v2/ai/v2_actionAiConfig';
import v2_actionAgenteAi from 'src/actions/v2/ai/v2_actionAgenteAi';

//components
import AiAgentTraining from './tabs/AiAgentTraining';
import AiAgentProfileTab from './tabs/AiAgentProfileTab';
import AiAgentConfigTab from './tabs/AiAgentConfigTab';
import { ScrollArea, ScrollBar } from 'src/@/components/ui/scroll-area';
import AiSelectPromptType from './tabs/AiSelectPromptType';
import AiAgentPromptTab from './tabs/AiAgentPromptTab';
import v2_actionEditarAgenteAi from 'src/actions/v2/ai/v2_actionEditarAgenteAi';
import useAuth from 'src/hooks/useAuth';
import Scrollbar from 'src/components/Scrollbar';

const steps = ['Escopo', 'Perfil', 'Prompt', 'Configurações', 'Treinamento'];


export default function ConfigAgentesIaComponent() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [config, setConfig] = useState(null);
    const [aiAgent, setAiAgent] = useState(null);
    const { cliente } = useAuth();

    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('id');


    async function buscarConfigAi() {
        try {
            const response = await v2_actionAiConfig(cliente._id);
            setConfig(response.data.data)
        } catch (error) {
        }
    }

    async function buscarAgenteAi(id) {
        try {
            const response = await v2_actionAgenteAi(id);
            setAiAgent(response.data.data)
        } catch (error) {

        }
    }

    async function handleEditarAgenteAi() {
        try {
            let data = {
                action: "update",
                scope: aiAgent
            }
            await v2_actionEditarAgenteAi(aiAgent._id, data);
            navigate('/dashboard/configuracoes/agentes-ia')
        } catch (error) {
            enqueueSnackbar('Erro ao editar agente', { variant: 'error' })
        }
    }


    useEffect(() => {
        buscarAgenteAi(code)
        buscarConfigAi();

        return () => {
            setAiAgent(null)
            setConfig(null)
        }
    }, [code])


    const handleNext = async () => {
        // finalizar
        if (activeStep === steps.length - 1) {
            await handleEditarAgenteAi();
            enqueueSnackbar('Agente editado com sucesso', { variant: 'success' });
            buscarAgenteAi(code)
            return;
        }

        // verificar se  campos company name, name,  persona estão preenchidos
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };


    return (
        <div className='w-full h-full flex flex-col justify-between'>
            <Container maxWidth="xl">
                <Stack>
                    <Box sx={{ width: '100%' }}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack spacing={1} alignItems={"center"} direction={"row"}>
                                <Button
                                    size='icon'
                                    variant='outline'
                                    onClick={
                                        () => {
                                            navigate('/dashboard/configuracoes/agentes-ia')
                                        }}
                                >
                                    <Iconify icon="majesticons:arrow-left" color="black" />

                                </Button>
                                <p>voltar aos agentes</p>
                            </Stack>

                        </Stack>
                    </Box>
                    <div className='h-[80vh] flex items-center justify-center'>
                        <Card className="w-full">
                            <div className="p-3 bg-slate-300">
                                <Stepper activeStep={activeStep}>
                                    {steps.map((label, index) => {
                                        const stepProps = {};
                                        const labelProps = {};
                                        return (
                                            <Step key={label} {...stepProps}>
                                                <StepLabel {...labelProps}>{label}</StepLabel>
                                            </Step>
                                        );
                                    })}
                                </Stepper>
                            </div>

                            <div className="h-[70vh] w-full flex justify-center items-center bg-slate-100">
                                {activeStep === 0 && (
                                    <AiSelectPromptType
                                        changePromptType={(value) => {
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    generalConfig: {
                                                        ...aiAgent.config.generalConfig,
                                                        type: value,
                                                    },
                                                },
                                            })

                                        }

                                        }
                                        aiAgent={aiAgent}
                                    />
                                )}
                                {activeStep === 1 && (
                                    <AiAgentProfileTab
                                        aiAgent={aiAgent}
                                        config={config}
                                        changeName={(value) =>
                                            setAiAgent({
                                                ...aiAgent,
                                                name: value
                                            })
                                        }
                                        changeCompanyName={(value) =>
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    promptGuide: {
                                                        ...aiAgent.config.promptGuide,
                                                        companyName: value,
                                                    },
                                                },
                                            })
                                        }
                                        changePersona={(value) =>
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    promptGuide: {
                                                        ...aiAgent.config.promptGuide,
                                                        persona: value,
                                                    },
                                                },
                                            })
                                        }
                                        changeTemperature={(value) => {
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    generalConfig: {
                                                        ...aiAgent.config.generalConfig,
                                                        temperature: value[0],
                                                    },
                                                },
                                            })
                                            console.log(value)
                                        }

                                        }
                                        changeAgentFunction={(value) => {
                                            console.log(value);
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    generalConfig: {
                                                        ...aiAgent.config.generalConfig,
                                                        agent_function_id: value,
                                                    },
                                                },
                                            });
                                        }}
                                        changeTypeCommunication={(value) =>
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    promptGuide: {
                                                        ...aiAgent.config.promptGuide,
                                                        type_speaking_id: value,
                                                    },
                                                },
                                            })
                                        }
                                        changeBusinessActivity={(value) =>
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    promptGuide: {
                                                        ...aiAgent.config.promptGuide,
                                                        business_activities_id: value,
                                                    },
                                                },
                                            })
                                        }
                                        changeAgentTypeSpeaking={(value) =>
                                            setAiAgent({
                                                ...aiAgent,
                                                config: {
                                                    ...aiAgent.config,
                                                    promptGuide: {
                                                        ...aiAgent.config.promptGuide,
                                                        type_speaking_id: value,
                                                    },
                                                },
                                            })
                                        }
                                    />
                                )}
                                {activeStep === 2 && (
                                    <AiAgentPromptTab
                                        prompt={aiAgent?.config?.promptGuide?.prompt}
                                        aiAgent={aiAgent}
                                        changePrompt={(value) => {
                                            if (aiAgent?.config?.generalConfig.type === "promptFree") {
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        promptFree: {
                                                            ...aiAgent.config.promptFree,
                                                            prompt: value,
                                                        },
                                                    },
                                                });
                                            } else {
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        promptGuide: {
                                                            ...aiAgent.config.promptGuide,
                                                            prompt: value,
                                                        },
                                                    },
                                                });
                                            }

                                        }}
                                    />
                                )}
                                {activeStep === 3 && (
                                    <Scrollbar>
                                        <AiAgentConfigTab
                                            changeAgentType={(value) =>
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        generalConfig: {
                                                            ...aiAgent.config.generalConfig,
                                                            agent_type: value,
                                                        },
                                                    },
                                                })
                                            }
                                            aiAgent={aiAgent}
                                            awnserAudio={aiAgent?.config?.generalConfig?.awnserAudio}
                                            changeAwnserAudio={(value) =>
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        generalConfig: {
                                                            ...aiAgent.config.generalConfig,
                                                            awnserAudio: value,
                                                        },
                                                    },
                                                })
                                            }
                                            listTypeVoices={config?.voices}

                                            changeListenMsgs={(value) =>
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        generalConfig: {
                                                            ...aiAgent.config.generalConfig,
                                                            timeListenMsgs: value ? Number(value) : value,
                                                        },
                                                    },
                                                })
                                            }
                                            changeDelayBetweenMsgs={(value) =>
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        generalConfig: {
                                                            ...aiAgent.config.generalConfig,
                                                            delayBetweenMsgs: value ? Number(value) : value,
                                                        },
                                                    },
                                                })
                                            }

                                            onChangeMsgs={(event) => {
                                                const { name, value } = event.target;
                                                console.log(aiAgent.config.generalConfig.messages.msgBreakAudios)


                                                // estrutura: config.generalConfig.messages.msgBreakFiles
                                                switch (name) {
                                                    case "msgs.msgBreakFiles":
                                                        setAiAgent({
                                                            ...aiAgent,
                                                            config: {
                                                                ...aiAgent.config,
                                                                generalConfig: {
                                                                    ...aiAgent.config.generalConfig,
                                                                    messages: {
                                                                        ...aiAgent.config.generalConfig.messages,
                                                                        msgBreakFiles: value,
                                                                    },
                                                                },
                                                            },
                                                        });
                                                        break;

                                                    case "msgs.msgImages":
                                                        setAiAgent({
                                                            ...aiAgent,
                                                            config: {
                                                                ...aiAgent.config,
                                                                generalConfig: {
                                                                    ...aiAgent.config.generalConfig,
                                                                    messages: {
                                                                        ...aiAgent.config.generalConfig.messages,
                                                                        msgImages: value,
                                                                    }
                                                                },
                                                            },
                                                        });
                                                        break;

                                                    case "msgs.msgBreakAudios":
                                                        console.log('msgBreakAudios', aiAgent.config.generalConfig)
                                                        setAiAgent({
                                                            ...aiAgent,
                                                            config: {
                                                                ...aiAgent.config,
                                                                generalConfig: {
                                                                    ...aiAgent.config.generalConfig,
                                                                    messages: {
                                                                        ...aiAgent.config.generalConfig.messages,
                                                                        msgBreakAudios: value,
                                                                    }
                                                                },
                                                            },
                                                        });
                                                        break;
                                                    default:
                                                        break;
                                                }

                                            }}


                                            changeAllowsInChat={(value) =>
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        generalConfig: {
                                                            ...aiAgent.config.generalConfig,
                                                            allowsInChat: value,
                                                        },
                                                    },
                                                })
                                            }

                                            changeVoice={(value) => {
                                                setAiAgent({
                                                    ...aiAgent,
                                                    config: {
                                                        ...aiAgent.config,
                                                        generalConfig: {
                                                            ...aiAgent.config.generalConfig,
                                                            voice_id: value,
                                                        },
                                                    },
                                                });
                                            }}
                                            voice={aiAgent?.config?.generalConfig?.voice_id}
                                        />
                                    </Scrollbar>

                                )}
                                {activeStep === 4 && <AiAgentTraining />}
                            </div>
                        </Card>
                    </div>
                    <div className="flex flex-row justify-between">

                        <Button
                            variant="outline"
                            disabled={activeStep === 0}
                            onClick={handleBack}

                        >
                            Voltar
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button
                            onClick={handleNext}
                        >
                            {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                        </Button>

                    </div>


                </Stack>
            </Container>
            <Container maxWidth>

            </Container>

        </div>
    );
}
