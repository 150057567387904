import axios from "axios";
import Config from "src/config";

const v2_actionCheckIsBlock = (id) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/${id}/check-is-block`
    });
};

export default v2_actionCheckIsBlock;
