import { Typography, Stack, Box, IconButton } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Iconify from 'src/components/Iconify';
import { useState } from 'react';
import PopoverEditarEstagio from './PopoverEditarEstagio';
import { CardHeader, Card, CardContent } from 'src/@/components/ui/card';
// material

export default function PipileneStageItem({
  funil,
  isModal,
  isEdit,
  onEdit,
  onDelete,
  user,
  cliente,
  onCallback,
  ...other
}) {

  const [pipelineId, setPipelineId] = useState(null);
  const [pipelineStage, setPipelineStage] = useState(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  return (
    <Card>
      <CardContent className="p-2">
      <PopoverEditarEstagio
        id={id}
        cliente={cliente}
        user={user}
        pipelineId={pipelineId}
        isEdit={isEdit}
        anchorEl={anchorEl}
        pipelineStage={pipelineStage}
        open={open}
        onClose={() => setAnchorEl(null)}
        onCallback={() => {
          onCallback();
          setAnchorEl(null);
        }}
      />
      <Stack direction={"row"} justifyContent={"space-between"}>
        <Typography variant='caption'>{funil.nome}</Typography>
        <Stack alignItems={"center"} direction={"row"} spacing={0.5}>
          <IconButton
            aria-describedby={id}
            size="small"
            aria-label="add"
            onClick={(event) => {
              setAnchorEl(event.currentTarget);
              setPipelineId(funil._id);
              setPipelineStage(funil);
            }}
          >
            <Iconify icon="material-symbols:edit-outline" width={10} height={10} />
          </IconButton>
          <IconButton size="small" aria-label="add" onClick={() => onDelete(funil)}>
            <Iconify icon="material-symbols:delete" width={10} height={10} />
          </IconButton>
        </Stack>
      </Stack>
      </CardContent>
    </Card>
  );
}
