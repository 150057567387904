import axios from "axios";

import Config from "../../../../config";

const v2_actionListaByStatus = (clienteId, usuarioId, status, setorId, limit) => {
    return axios({
        method: "get",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/${clienteId}/${usuarioId}/lista-by-status?status=${status}&limit=${limit ? limit : 100}&setor_id=${setorId}`
    });
};

export default v2_actionListaByStatus;
