import { Popover, Typography, Box, FormControl, TextField, Divider, MenuItem, Stack, IconButton, Button } from '@mui/material';
import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import v2_actionCriarEstagioFunil from 'src/actions/v2/cliente/v2_actionCriarEstagioFunil';

export default function PopoverCriarEstagio({
  user,
  cliente,
  open,
  pipelineId,
  id,
  anchorEl,
  onClose,
  onCallback
}) {

  const [nome, setNome] = useState('');
  const [loading, setLoading] = useState(false);



  async function handleCriarEstagioFunil() {
    try {
      let data = {
        scope: {
          nome:nome,
          pipeline_id: pipelineId,
          criado_por: user.id,
          cliente_id: cliente._id
        }
      }
      const result = await v2_actionCriarEstagioFunil(cliente._id, data);
      if (result.status === 200) {
        onCallback()
        setNome('');
        onClose()
      }
    } catch (error) {
      console.log(error);
    }
  }
  return (
    <Popover
      sx={{ mt: 1}}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ width: 300, height: 150, p: 3 }}>
        <Stack direction={{ xs: 'column', md: 'column' }} spacing={2} sx={{ width: 1 }}>

          <Stack direction="row" spacing={1}>
     
            <TextField
              size="small"
              //type="number"
              value={nome}
              label="Nome estágio"
              onChange={(event) => setNome(event.target.value)}
            />

          </Stack>

          <LoadingButton loading={loading} onClick={handleCriarEstagioFunil} variant='contained'>
            Salvar
          </LoadingButton>



        </Stack>
      </Box>

    </Popover>
  );
}


