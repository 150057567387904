import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { HEADER, NAVBAR } from 'src/config';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';

import DashboardNavbar from './DashboardNavbar';
import useAuth from 'src/hooks/useAuth';


const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: HEADER.MOBILE_HEIGHT,

  elevation:1,
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT-8,
  //  paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    height: `calc(100vh - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
    ...(collapseClick && {
      marginLeft: NAVBAR.DASHBOARD_COLLAPSE_WIDTH,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function ChatLayout() {
  const [open, setOpen] = useState(false);
  const {user} = useAuth();
  const { collapseClick, isCollapse } = useCollapseDrawer();


  return (
    <Box
    sx={{
      display: { lg: 'flex' },
      margin:0,
      minHeight: { lg: 1, md:1, sm:1 },
      background:'white',
      overflow:'hidden'
    }}
  >
      <DashboardNavbar />
      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
