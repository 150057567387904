import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';

export default function PrivacyPolicySection() {

  return (
    <Box sx={{p:3}}>
     <p>
              <strong>Na Lais App</strong>, a privacidade e segurança são prioridades e nos comprometemos com a transparência do tratamento de dados pessoais dos nossos usuários/clientes. Por isso, essa presente Política de Privacidade estabelece como é feita a coleta, uso e transferência de informações de clientes ou outras pessoas que acessam ou usam nosso aplicativo.
            </p>
            <p>
              Ao utilizar nossos serviços, você entende que coletamos e usamos suas informações pessoais nas formas descritas nesta Política, sob as normas da Constituição Federal de 1988 (art. 5º, LXXIX; e o art. 22º, XXX – incluídos pela EC 115/2022), das normas de Proteção de Dados (LGPD, Lei Federal 13.709/2018), das disposições consumeristas da Lei Federal 8078/1990 e as demais normas do ordenamento jurídico brasileiro aplicáveis.
            </p>
            <p>
              Dessa forma, a <strong>Lais App</strong> no papel de Controladora de Dados, obriga-se ao disposto na presente Política de Privacidade.
            </p>
            <ol>
              <li>
                <strong>Quais dados coletamos sobre você e para qual finalidade?</strong>
                <ul>
                  <li>
                    <strong>1.1. Dados pessoais fornecidos pelo titular</strong>
                    <ul>
                      <li>Nome da rede WiFi: para conexão dos dispositivos a serem controlados e monitorados pelo aplicativo</li>
                      <li>Senha da rede WiFi: para a conexão dos dispositivos a serem controlados e monitorados pelo aplicativo</li>
                    </ul>
                  </li>
                  <li>
                    <strong>1.2. Dados pessoais coletados automaticamente</strong>
                    <ul>
                      <li>Informações de diagnóstico do smartphone como versão do android, endereço IP local, versão de software do aplicativo são utilizados para manter a integridade da conexão entre os dispositivos monitorados e controlados com o aplicativo.</li>
                      <li>Informações de diagnóstico dos dispositivos monitorados e controlados pelo aplicativo como endereço IP local, versão de firmware, versão de hardware, temperatura, são utilizados para permitir o diagnóstico e controle remoto dos dispositivos através do aplicativo.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Como coletamos os seus dados?</strong>
                <ul>
                  <li>
                    <strong>Nesse sentido, a coleta dos seus dados pessoais ocorre da seguinte forma:</strong>
                    <ul>
                      <li>Os dados dos dispositivos são coletados periodicamente no intervalo de 10 segundos.</li>
                      <li>Imagens, audios e videos podem ser coletados, apartir da iniciativa do usuários (via captura da camera ou upload na galeria) para que sejam enviadas na conversação no App.</li>
                    </ul>
                  </li>
                  <li>
                    <strong>2.1. Consentimento</strong>
                    <ul>
                      <li>É a partir do seu consentimento que tratamos os seus dados pessoais. O consentimento é a manifestação livre, informada e inequívoca pela qual você autoriza a Lais App a tratar seus dados.</li>
                      <li>Assim, em consonância com a Lei Geral de Proteção de Dados, seus dados só serão coletados, tratados e armazenados mediante prévio e expresso consentimento.</li>
                      <li>O seu consentimento será obtido de forma específica para cada finalidade acima descrita, evidenciando o compromisso de transparência e boa-fé da Lais App para com seus usuários/clientes, seguindo as regulações legislativas pertinentes.</li>
                      <li>Ao utilizar os serviços da Lais App e fornecer seus dados pessoais, você está ciente e consentindo com as disposições desta Política de Privacidade, além de conhecer seus direitos e como exercê-los.</li>
                      <li>A qualquer tempo e sem nenhum custo, você poderá revogar seu consentimento.</li>
                      <li>É importante destacar que a revogação do consentimento para o tratamento dos dados pode implicar a impossibilidade da performance adequada de alguma funcionalidade do aplicativo que dependa da operação. Tais consequências serão informadas previamente.</li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <strong>Quais são os seus direitos?</strong>
                <p>A Lais App assegura a seus usuários/clientes seus direitos de titular previstos no artigo 18 da Lei Geral de Proteção de Dados. Dessa forma, você pode, de maneira gratuita e a qualquer tempo:</p>
                <ul>
                  <li>Confirmar a existência de tratamento de dados, de maneira simplificada ou em formato claro e completo.</li>
                  <li>Acessar seus dados, podendo solicitá-los em uma cópia legível sob forma impressa ou por meio eletrônico, seguro e idôneo.</li>
                  <li>Corrigir seus dados, ao solicitar a edição, correção ou atualização destes.</li>
                  <li>Limitar seus dados quando desnecessários, excessivos ou tratados em desconformidade com a legislação através da anonimização, bloqueio ou eliminação.</li>
                  <li>Solicitar a portabilidade de seus dados, através de um relatório de dados cadastrais que a Lais App trata a seu respeito.</li>
                  <li>Eliminar seus dados tratados a partir de seu consentimento, exceto nos casos previstos em lei.</li>
                  <li>Revogar seu consentimento, desautorizando o tratamento de seus dados.</li>
                  <li>Informar-se sobre a possibilidade de não fornecer seu consentimento e sobre as consequências da negativa.</li>
                </ul>
              </li>
              <li>
                <strong>Como você pode exercer seus direitos de titular?</strong>
                <p>Para exercer seus direitos de titular, você pode desconectar os dispositivos utilizados da sua rede WiFi, e desinstalar o aplicativo do seu smartphone. Nenhum dado coletado é gravado de forma permanente, de forma que se o usuário desejar interromper o nosso acesso a esses dados, basta desconectar os dispositivos da rede WiFi usando o botão “Resetar rede WiFi” no próprio aplicativo. Os dispositivos irão permanecer funcionando, porém não será mais possível monitorá-los ou controlá-los, tendo em vista que para isso é necessário utilizar o aplicativo, o que requer a conexão com a internet para o seu funcionamento.</p>
              </li>
              <li>
                <strong>Como e por quanto tempo seus dados serão armazenados?</strong>
                <p>Seus dados pessoais coletados pela Lais App serão utilizados e armazenados durante o tempo necessário para a prestação do serviço ou para que as finalidades elencadas na presente Política de Privacidade sejam atingidas, considerando os direitos dos titulares dos dados e dos controladores. Esses dados não serão gravados de forma permanente, podendo ser armazenados em arquivos de logs por períodos de tempo inferiores a um dia, com o objetivo de realizar diagnósticos e avaliações técnicas periódicas.</p>
                <p>De modo geral, seus dados serão acessíveis enquanto a relação contratual entre você e a Lais App perdurar, porém não serão armazenados por um período de tempo superior a um dia. Findado o período de armazenamento dos dados pessoais, estes serão excluídos de nossas bases de dados ou anonimizados, ressalvadas as hipóteses legalmente previstas no artigo 16 lei geral de proteção de dados.</p>
                <p>Isto é, informações pessoais sobre você que sejam imprescindíveis para o cumprimento de determinações legais, judiciais e administrativas e/ou para o exercício do direito de defesa em processos judiciais e administrativos serão mantidas, a despeito da exclusão dos demais dados.</p>
              </li>
              <li>
                <strong>O que fazemos para manter seus dados seguros?</strong>
                <p>Para mantermos suas informações pessoais seguras, usamos ferramentas físicas, eletrônicas e gerenciais orientadas para a proteção da sua privacidade.</p>
                <p>Aplicamos essas ferramentas levando em consideração a natureza dos dados pessoais coletados, o contexto e a finalidade do tratamento e os riscos que eventuais violações gerariam para os direitos e liberdades do titular dos dados coletados e tratados.</p>
                <p>Entre as medidas que adotamos, destacamos as seguintes:</p>
                <ul>
                  <li>Apenas pessoas autorizadas têm acesso a seus dados pessoais</li>
                  <li>O acesso a seus dados pessoais é feito somente após o compromisso de confidencialidade</li>
                  <li>Seus dados pessoais são armazenados em ambiente seguro e idôneo.</li>
                  <li>Seus dados são trafegados sem contexto, de modo que ao interceptar qualquer mensagem, é impossível definir sua origem através das próprias informações trafegadas</li>
                  <li>O acesso ao dados é feito através de um endpoint privado, e é protegido por usuário e senha conhecidos apenas pelos desenvolvedores</li>
                  <li>As credenciais de acesso salvas nos periféricos controlados e monitorados pelo aplicativo são criptografadas por chave única e podem ser visualizados ou modificados apenas pelo próprio firmware dos dispositivos</li>
                </ul>
              </li>
              <li>
                <strong>Com quem seus dados podem ser compartilhados?</strong>
                <p>Tendo em vista a preservação de sua privacidade, a Lais App não compartilhará seus dados pessoais com nenhum terceiro não autorizado.</p>
                <p>Além disso, também existem outras hipóteses em que seus dados poderão ser compartilhados, que são:</p>
                <ol>
                  <li>Determinação legal, requerimento, requisição ou ordem judicial, com autoridades judiciais, administrativas ou governamentais competentes.</li>
                  <li>Caso de movimentações societárias, como fusão, aquisição e incorporação, de forma automática</li>
                  <li>Proteção dos direitos da Lais App em qualquer tipo de conflito, inclusive os de teor judicial.</li>
                </ol>
              </li>
              <li>
                <strong>Transferência internacional de dados</strong>
                <p>Alguns dos terceiros com quem compartilhamos seus dados podem ser localizados ou ou possuir instalações localizadas em países estrangeiros. Nessas condições, de toda forma, seus dados pessoais estarão sujeitos à Lei Geral de Proteção de Dados e às demais legislações brasileiras de proteção de dados</p>
                <p>Nesse sentido, a Lais App se compromete a sempre adotar eficientes padrões de segurança cibernética e de proteção de dados, nos melhores esforços de garantir e cumprir as exigências legislativas.</p>
                <p>Ao concordar com essa Política de Privacidade, você concorda com esse compartilhamento, que se dará conforme as finalidades descritas no presente instrumento.</p>
              </li>
              <li>
                <strong>Cookies ou dados de navegação</strong>
                <p>A Lais App não faz uso de Cookies, que são arquivos de texto enviados pela plataforma ao seu computador e que nele se armazenam, que contém informações relacionadas à navegação do aplicativo.</p>
              </li>
              <li>
                <strong>Alteração desta Política de Privacidade</strong>
                <p>Reservamos o direito de modificar essa Política de Privacidade a qualquer tempo, principalmente em função da adequação a eventuais alterações feitas em nosso aplicativo ou em âmbito legislativo. Recomendamos que você a revise com frequência.</p>
                <p>Eventuais alterações entrarão em vigor a partir de sua publicação em nosso aplicativo e sempre lhe notificaremos acerca das mudanças ocorridas.</p>
                <p>Ao utilizar nossos serviços e fornecer seus dados pessoais após tais modificações, você às consente.</p>
              </li>
              <li>
                <strong>Responsabilidade</strong>
                <p>A Lais App prevê a responsabilidade dos agentes que atuam nos processos de tratamento de dados, em conformidade com os artigos 42 ao 45 da Lei Geral de Proteção de Dados.</p>
                <p>Nos comprometemos em manter esta Política de Privacidade atualizada, observando suas disposições e zelando por seu cumprimento.</p>
                <p>Além disso, também assumimos o compromisso de buscar condições técnicas e organizativas seguramente aptas a proteger todo o processo de tratamento de dados.</p>
                <p>Caso a Autoridade Nacional de Proteção de Dados exija a adoção de providências em relação ao tratamento de dados realizado pela Lais App, comprometemo-nos a segui-las.</p>
              </li>
              <li>
                <strong>Isenção de responsabilidade</strong>
                <p>Conforme mencionado no Tópico 6, embora adotemos elevados padrões de segurança a fim de evitar incidentes, não há nenhum aplicativo inteiramente livre de riscos. Nesse sentido, a Lais App não se responsabiliza por:</p>
                <ol>
                  <li>Quaisquer consequências decorrentes da negligência, imprudência ou imperícia dos usuários em relação a seus dados individuais. Garantimos e nos responsabilizamos apenas pela segurança dos processos de tratamento de dados e do cumprimento das finalidades descritas no presente instrumento.</li>
                  <li>Ações maliciosas de terceiros, como ataques de hackers, exceto se comprovada conduta culposa ou deliberada da Lais App.</li>
                  <li>Inveracidade das informações inseridas pelo usuário/cliente nos registros necessários para a utilização dos serviços da Lais App; quaisquer consequências decorrentes de informações falsas ou inseridas de má-fé são de inteiramente responsabilidade do usuário/cliente.</li>
                </ol>
              </li>
              <li>
                <strong>Encarregado de Proteção de Dados</strong>
                <p>A Lais App disponibiliza os seguintes meios para que você possa entrar em contato conosco para exercer seus direitos de titular:</p>
                <ul>
                  <li>Email: ojoaoteixeira@outlook.com</li>
                  <li>Telefone:+55 37 8844-4321</li>
                </ul>
                <p>Caso tenha dúvidas sobre esta Política de Privacidade ou sobre os dados pessoais que tratamos, você pode entrar em contato com o nosso Encarregado de Proteção de Dados Pessoais, através dos seguintes canais:</p>
                <p>Internets Solutions</p>
              </li>
            </ol>
    </Box>
)}
