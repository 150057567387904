import axios from "axios";
import Config from "../../config";

const actionVerChamado = chamadoId => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chamados/${chamadoId}/detalhes`
    });
};

export default actionVerChamado;
