import axios from "axios";
import Config from "../../../config";

const v2_actionImportarContatos = (clienteId,data) => {
    
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/cadastro/contatos/${clienteId}/importar`,
        data
    });
};

export default v2_actionImportarContatos;
