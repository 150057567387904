import Config from  '../../../config'
import api from "../../../services/api";
import axios from 'axios';
import { getSession} from "../../../services/auth";
import config from "../../../utils/sessionHeader";

const getActionVerificaNumero = phone => {

   return axios.get(`${Config.urlWhatsapp}/api/${getSession()}/check-number-status/${phone}`, config);
};

export default getActionVerificaNumero;

