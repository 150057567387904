import { useEffect, useState, useRef } from 'react';
// material
import { Stack, Table, Box, TableRow, TableHead, TableBody, TableCell, TableContainer, IconButton, Grid, TextField, Button, Typography, Card, Container, Paper } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import Scrollbar from 'src/components/Scrollbar';

import { useDispatch, useSelector } from 'src/redux/store';
// configs
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';
import ForcaVendaResumoWidget from 'src/components/_dashboard/relatorios/forcaVendas/ForcaVendaResumoWidget';
import actionMetricasForcaVendas from 'src/actions/dashboard/relatorios/actionMetricasForcaVendas';
import actionMetricasForcaVendasUsuarios from 'src/actions/dashboard/relatorios/actionMetricasForcaVendasUsuarios';
import EmptyContent from 'src/components/EmptyContent';
import Breadcrumbs from 'src/components/Breadcrumbs';
import MenuDatas from 'src/components/MenuDatas';
import { validaLabelMenuDatas } from 'src/utils/functions';
import moment from 'moment';
import v2_actionMetricasForcaVendasUsuarios from 'src/actions/v2/dashboard/relatorios/v2_actionMetricasForcaVendasUsuarios';
import v2_actionMetricasForcaVendas from 'src/actions/v2/dashboard/relatorios/v2_actionMetricasForcaVendas';

export default function RelatorioForcaVendas() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const { somaChatsByStatus } = useSelector((state) => state.dashboard);
  const { user } = useAuth();
  const [isPesquisado, setIsPesquisado] = useState(false);
  const [inicial, setInicial] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const [dataFunil, setDataFunil] = useState([]);
  const [metricas1, setMetricas1] = useState(0);
  const [metricas2, setMetricas2] = useState(0);
  const [metricas3, setMetricas3] = useState(0);
  const [atendimentosFinalizados, setAtendimentosFinalizados] = useState(0);
  const [atendimentosTransferidos, setAtendimentosTransferidos] = useState(0);
  const [ticketsFinalizados, setTicketsFinalizados] = useState(0);
  const [tempoMedioEspera, setTempoMedioEspera] = useState(0);
  const [tempoMedioAceiteFinalizado, setTempoMedioAceiteFinalizado] = useState(0);
  const [tempoMedioTotal, setTempoMedioTotal] = useState(0);
  const [listaMetricasAtendimetosUsuarios, setListaMetricasAtendimetosUsuarios] = useState([]);
  const multiSession = localStorage.getItem("multiSession");
  const clienteIdActive = localStorage.getItem("clienteIdActive");

  //força vendas
  const [metricasForcaVendas, setMetricasForcaVendas] = useState(null);

  const [periodo, setPeriodo] = useState('mes_atual')
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuDatas = Boolean(anchorEl);


  const dataRelatorio = {
    atendimentosFinalizados,
    atendimentosTransferidos,
    ticketsFinalizados,
    tempoMedioTotal,
    tempoMedioEspera,
    somaChatsByStatus
  }


  useEffect(() => {
    getMetricasForcaVendas(periodo);
    buscarMetricasForcaVendasUsuarios(periodo)
  }, [inicial, final]);




  const getMetricasForcaVendas = async (value) => {
    try {
      let inicio2 = moment(inicial).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${value}&cliente_id=${user.clienteId}&inicio=${inicio2}&final=${final2}`
      const res = await v2_actionMetricasForcaVendas(querys);
      setMetricasForcaVendas(res.data.data);
    } catch (err) {
      console.log('err', err)
    }
  }

  const buscarMetricasForcaVendasUsuarios = async (value) => {
    try {
      let inicio2 = moment(inicial).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${value}&cliente_id=${user.clienteId}&inicio=${inicio2}&final=${final2}`
      const res = await v2_actionMetricasForcaVendasUsuarios(querys);
      setListaMetricasAtendimetosUsuarios(res.data.data)
    } catch (error) {

    }
  }

  const onFiltrar = async (value, action) => {
    setPeriodo(value)
    getMetricasForcaVendas(value);
    buscarMetricasForcaVendasUsuarios(value)
  }



  const handlePesquisar = () => {
    getMetricasForcaVendas('null');
    buscarMetricasForcaVendasUsuarios('null')
    setIsPesquisado(true);
    setPeriodo('null')
  }



  return (
    <Box sx={{mt:5}} title={nomeProjeto}>
  <Container maxWidth={false} >
      <Breadcrumbs
        backButton={() => navigate('/dashboard/relatorios')}
        links={[{ name: 'Relatorios', href: '/dashboard/relatorios' }, { name: 'Relatorio Força Vendas' }]}
      />
</Container>
      <Container sx={{ mt: 2 }} maxWidth>
        <Box sx={{ pt: 1, pb: 1 }}>
          <Stack direction="row" spacing={2} alignItems="center" >

            <Paper
              component="form"
              sx={{ p: '10px 10px', display: 'flex', alignItems: 'center' }}
            >
              <Button

                startIcon={<Iconify icon="solar:calendar-date-bold-duotone" />}
                endIcon={<Iconify icon="bxs:down-arrow" sx={{ width: 10, height: 10 }} />}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                {validaLabelMenuDatas(periodo, inicial, final)}
              </Button>

            </Paper>

            <MenuDatas
              anchorEl={anchorEl}
              open={openMenuDatas}
              onClose={() => setAnchorEl(false)}
              inicial={inicial}
              final={final}
              changeInicial={newValue => setInicial(newValue)}
              changeFinal={newValue => { setFinal(newValue) }}
              onSelect={value => {
                setPeriodo(value);
                setAnchorEl(null);
                onFiltrar(value)
              }}
              onPesquisar={() => handlePesquisar('null')}
            />

          </Stack>
        </Box>
      </Container>


      <Container sx={{ mt: 2 }} maxWidth>
        <Box sx={{ height: 'calc(98vh - 150px)', }}>

          <Stack direction={'column'} spacing={2}>


            <Card sx={{ p: 3 }}>
              {metricasForcaVendas && (
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Leads" total={metricasForcaVendas.totalLeads} icon={'mdi:leads-outline'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Convertidos" total={metricasForcaVendas.totalLeadsConvertidos} icon={'icon-park-solid:success'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Conversão" total={metricasForcaVendas.taxaConversao} icon={'ic:baseline-percent'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="TMA" total={metricasForcaVendas.tempoTma} icon={'mdi:clock-outline'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Avaliação" total={metricasForcaVendas.avaliacoes.media} icon={'material-symbols:star-outline'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Total Avaliações" total={metricasForcaVendas.avaliacoes.total} icon={'mingcute:counter-fill'} />
                  </Grid>

                </Grid>
              )}
            </Card>
            {listaMetricasAtendimetosUsuarios.length > 0 && (
              <Card sx={{ p: 3 }}>

                <Box sx={{ height: 400, alignItems: 'center' }}>
                  <Typography variant='h4'>Métricas por usuário</Typography>
                  <Scrollbar >
                    <TableContainer sx={{ minWidth: 400, mt: 3 }}>
                      <Table >
                        <TableHead>
                          <TableRow>

                            <TableCell align="left">Usuário</TableCell>
                            <TableCell align="left">Leads</TableCell>
                            <TableCell align="left">Convertidos</TableCell>
                            <TableCell align="left">Conversão</TableCell>
                            <TableCell align="left">TMA - Leads</TableCell>
                            <TableCell align="left">Avaliação</TableCell>
                            <TableCell align="left">Total Avaliações</TableCell>


                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {listaMetricasAtendimetosUsuarios.map((row) => {
                            let tma = row.tma / row.count_tma;
                            let tma_calc = tma / 60;

                            function verificahoras(number) {
                              if (number >= 60) {
                                return (number / 60).toFixed(1)
                              } else {
                                return number.toFixed(1);
                              }
                            }


                            function resultTimes(values) {
                              if (!values) return 0;
                              let format = `${verificahoras(values)} ${values >= 60 ? "h" : "min"}`
                              return format;
                            }

                            function calculaAvaliacao() {
                              let media = row.mediaAvaliacoes / row.totalAvaliacoes;
                              return media;
                            }

                            return (
                              <TableRow key={row._id}>
                                <TableCell component="th" scope="row">
                                  {row.nomeUsuario ? row.nomeUsuario : ''}
                                </TableCell>

                                <TableCell width={150} component="th" scope="row">
                                  {row.total ? row.total : '-'}
                                </TableCell>
                                <TableCell width={150} component="th" scope="row">
                                  {row.totalConvertido ? row.totalConvertido : '-'}
                                </TableCell>

                                <TableCell width={150} component="th" scope="row">
                                  {row.totalConvertido && row.total ? `${(row.totalConvertido / row.total * 100).toFixed(1)}%` : '-'}

                                </TableCell>
                                <TableCell width={150} component="th" scope="row">
                                  {tma ? resultTimes(tma) : '-'}

                                </TableCell>
                                <TableCell width={150} component="th" scope="row">

                                  {calculaAvaliacao()}
                                </TableCell>
                                <TableCell width={150} component="th" scope="row">
                                  {row.totalAvaliacoes ? row.totalAvaliacoes : 0}

                                </TableCell>
                              </TableRow>
                            )
                          })}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Scrollbar>
                </Box>

              </Card>
            )}
          </Stack>

        </Box>
      </Container>
    </Box>
  );
}
