import axios from "axios";
import Config from "src/config";

const v1_actionDownloadMediaWhatsapp = (querys) => {
    return axios({
        method: "get",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/canais/download-media${querys}`
    });
};

export default v1_actionDownloadMediaWhatsapp;
