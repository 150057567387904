import axios from "axios";
import Config from "src/config";

const v1_actionListaAutomacoes = (clienteId) => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/cliente/automacoes/${clienteId}/lista`
    });
};

export default v1_actionListaAutomacoes;
