import { useEffect, useState, useCallback } from 'react';
// @mui
import { Container, Stack, Button, Typography, IconButton, Card, Box, Grid } from '@mui/material';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getListaColunas, getListaTickets, closeModalDetalhesChamado } from 'src/redux/slices/chamados';
// components
import Page from 'src/components/Page';
import { SkeletonKanbanColumn } from 'src/components/skeleton';
//auth
import useAuth from 'src/hooks/useAuth';
// sections
import { KanbanColumn } from 'src/sections/@dashboard/tickets';
import { NovoChamado } from 'src/sections/@dashboard/tickets/ChamadosNovoChamado'
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
//actions
import actionListaTagsUsuarios from 'src/actions/chamados/tags/actionListaTagsUsuario';
import actionCriarChamado from 'src/actions/chamados/actionCriarChamado';
//icons
import { BsDisplay } from "react-icons/bs";
import v2_actionUploadS3 from 'src/actions/v2/global/actionUploadS3';
import Iconify from 'src/components/Iconify';
import v2_actionBuscarDadosCliente from 'src/actions/v2/cliente/v2_actionBuscarDadosCliente';
import actionListaInputsPrioridade from 'src/actions/chamados/actionListaInputsPrioridade';
import Scrollbar from 'src/components/Scrollbar';
import { NAVBAR } from 'src/config';

// ----------------------------------------------------------------------

export default function ChamadosPage() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { user } = useAuth();
  const { colunas } = useSelector((state) => state.chamados);
  const { chamado, comentarios_chamado, openChamado } = useSelector((state) => state.chamados);
  const [isOpen, setIsOpen] = useState(false);
  const [assunto, setAssunto] = useState('');
  const [descricao, setDescricao] = useState('');
  const [prioridade, setPrioridade] = useState('');
  const [arquivos, setArquivos] = useState([]);
  const [direcionadoPara, setDirecionadoPara] = useState('');
  const [direcionadoSetores, setDirecionadoSetores] = useState('');
  const [tipoLocal, setTipoLocal] = useState('chamado_interno');
  const [setorOuUsuario, setSetorOuUsuario] = useState('');
  const [setorEscolhido, setSetorEscolhido] = useState('');
  const [usuarioEscolhido, setUsuarioEscolhido] = useState('');
  const [filialEscolhido, setFilialEscolhido] = useState('');
  const [estimativa, setEstimativa] = useState('');
  const [files, setFiles] = useState([]);
  const filesUrl = []
  const [preview, setPreview] = useState(true);
  const [isFile, setIsFile] = useState(false);
  const [listaTags, setistaTags] = useState([]);
  const [config, setConfig] = useState(null);
  const [listaInputsPrioridade, setListaInputsPrioridade] = useState([]);
  const [loading, setLoading] = useState(false);
  //modal assunto
  const [modalAssunto, setModalAssunto] = useState(false);
  const [emCopia, setEmCopia] = useState([]);
  const [dataConclusao, setDataConclusao] = useState('');

  const [searchParams] = useSearchParams();
  const parametro = searchParams.get('foo');

  console.log('colunas', colunas)

  function isEmpty(obj) {
    return Object.keys(obj).length === 0;
  }


  async function buscarListaTags() {
    try {
      let querys = `?clienteId=${user.clienteId}`
      const response = await actionListaTagsUsuarios(querys);
      setistaTags(response.data.data)
    } catch (error) {

    }
  }

  async function buscarConfiguracoes() {
    try {
      const response = await v2_actionBuscarDadosCliente(user.clienteId);
      const response_inputs = await actionListaInputsPrioridade(user.clienteId);
      setConfig(response.data.data)
      setListaInputsPrioridade(response_inputs.data.data)
    } catch (error) {
      console.log('error', error)
    }
  }



  useEffect(() => {
    buscarListaTags()
    dispatch(getListaColunas({ setorId: user.setorId, usuarioId: user.id, filial: "" }));
    dispatch(getListaTickets({ setorId: user.setorId, usuarioId: user.id, filial: "" }));
  }, [dispatch]);

  const ordemColunas = [
    "pendente",
    "aberto",
    "recusado",
  ];

  const handleCloseDetails = () => {
    console.log('clicou para fechar o detalhes')
    try {
      closeModalDetalhesChamado(false)
      //   dispatch(getListaColunas({ setorId: user.user.setor_id, usuarioId: user.id, filial: user.user.filial }));
      //   dispatch(getListaTickets({ setorId: user.user.setor_id, usuarioId: user.id, filial: user.user.filial }));
    } catch (error) {
      console.log('error', error)
    }


  };


  const onCriarChamado = async () => {
    setLoading(true)
    let data = {
      assunto: assunto,
      descricao: descricao,
      criadoPor: user.id,
    //  prioridade: estimativa,
      copiadoPara: emCopia.length > 0 ? emCopia.map(item => item._id) : [],
      arquivos: filesUrl,
      direcionadoPara: setorOuUsuario === 'usuario' ? usuarioEscolhido._id : null,
      direcionadoFilial: filialEscolhido ? filialEscolhido.codigo_filial : null,
      direcionadoSetores: setorEscolhido ? setorEscolhido._id : null,
      dataConclusao
    }

    try {
      if (files.length > 0) await uploadFilesAndGetUrl(files);
      await actionCriarChamado(user.clienteId, data);
      limparCampos()
      setIsOpen(false);
      dispatch(getListaTickets({ setorId: user.setor_id, usuarioId: user.id, filial: user.filial }));
      dispatch(getListaColunas({ setorId: user.setor_id, usuarioId: user.id, filial: user.filial }));
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }

  }

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
    [setFiles]
  );

  const handleOpenNovoChamado = async () => {
    await buscarConfiguracoes();
    setIsOpen(true);
  }


  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  async function uploadFilesAndGetUrl(files) {
    if (files.length > 0) {
      for (let file of files.map(item => item)) {
        var formData = new FormData();
        var imagefile = file;
        formData.append("file", imagefile);
        try {
          let querys = `?path=chamados/clientes/${user.clienteId}/anexos`
          const response = await v2_actionUploadS3(formData, querys)
          if (!response.data.error) {
            console.log('!response.data.error')
            filesUrl.push({ key: response.data.data.key, type: file.type, name: file.name, size: file.size })
          }
        } catch (error) {
          console.log('erro ao upload arquivo', error)
        }
      }
    }
  }


  function limparCampos() {
    setAssunto('');
    setDescricao('');
    setPrioridade('');
    setArquivos([]);
    setDirecionadoPara('');
    setDirecionadoSetores('');
    setSetorOuUsuario('');
    setSetorEscolhido('');
    setUsuarioEscolhido('');
    setFilialEscolhido('');
    setEmCopia([])
    setEstimativa('')
  }



  const selectAssunto = (item) => {
    console.log('selectAssunto', item)
    setAssunto(item.assunto);
    setEstimativa(item.prioridade);
    setTipoLocal(item.loja_ou_filial);
    setSetorOuUsuario(item.setor_ou_usuario)
    if (item.loja_ou_filial === 'loja') {
      setFilialEscolhido(item.filial);
    }

    if (item.loja_ou_filial === 'backoffice') {
      if (item.setor_ou_usuario == 'setor') {
        console.log('setou setor', item.setor)
        setSetorEscolhido(item.setor);
      } else if (item.setor_ou_usuario == 'usuario') {
        console.log('direciona_usuarios', item.direciona_usuarios)
        setUsuarioEscolhido(item.usuario);
      }
    }

    setIsFile(item.isFile);
    setDescricao(item.descricao);
    setModalAssunto(false)
  }



  return (
    <Page title="Chamados" >
           <Stack
        sx={{
          mt:5,
          display: 'flex',
          height: `calc(100vh - ${NAVBAR.DASHBOARD_ITEM_HORIZONTAL_HEIGHT + 150}px)`,
        }}
      >
      <Container maxWidth={false} >

        <Stack direction={'row'} justifyContent={'space-between'} spacing={8} sx={{ mb: 5 }}>
          <Stack direction={'row'} spacing={1}>
            <Button size="small" variant={location.pathname === "/dashboard/chamados" ? 'contained' : 'outlined'} startIcon={<Iconify icon={"bi:kanban"} width={12} height={12} />} onClick={() => navigate(`/dashboard/chamados`)}>
              Kanban
            </Button>
            <Button size="small" variant={location.pathname === "/dashboard/meus-chamados" ? 'contained' : 'outlined'} startIcon={<Iconify icon={"icons8:list"} width={12} height={12} />} onClick={() => navigate(`/dashboard/meus-chamados`)}>
              Lista
            </Button>
          </Stack>

          <Stack direction={'row'} spacing={1}>
            <Button size="small" variant='contained' startIcon={<Iconify icon={"gg:add"} width={12} height={12} />} onClick={handleOpenNovoChamado}>
              Criar Chamado
            </Button>
          </Stack>
        </Stack>

      </Container>

      <NovoChamado
        loading={loading}
        user={user}
        config={config && config.config}
        listaInputsPrioridade={listaInputsPrioridade}
        tipoLocal={tipoLocal}
        setorOuUsuario={setorOuUsuario}
        usuarioEscolhido={usuarioEscolhido}
        setorEscolhido={setorEscolhido}
        filialEscolhido={filialEscolhido}
        estimativa={estimativa}
        selectAssunto={list => selectAssunto(list)}
        changeEmCopia={newValue => setEmCopia(newValue)}
        changeEstimativa={e => {
          setEstimativa(e.target.value)
          console.log('e.target.value', e.target.value)
        }}
        changeTipoLocal={e => {
          setTipoLocal(e.target.value);
          if (e.target.value === 'loja') {
            setUsuarioEscolhido('');
            setSetorEscolhido('');
            setSetorOuUsuario('');
          } else if (e.target.value === 'backoffice') {
            setFilialEscolhido('');
          }
        }}
        changeSetorOuUsuario={e => {
          setSetorOuUsuario(e.target.value);
          if (e.target.value === 'setor') {
            setUsuarioEscolhido('');
          } else if (e.target.value === 'setor') {
            setSetorEscolhido('');
          }
        }}
        changeUsuarioEscolhido={newValue => {
          setUsuarioEscolhido(newValue);
        }}
        changeFilialEscolhido={newValue => {
          setFilialEscolhido(newValue);

        }}
        changeSetorEscolhido={newValue => {
          setSetorEscolhido(newValue)
        }}
        changeIsFile={e => setIsFile(e.target.checked)}
        modalAssunto={modalAssunto}
        closeModalAssunto={() => setModalAssunto(false)}
        openModalAssunto={() => setModalAssunto(true)}
        //
        emCopia={emCopia}
        isFile={isFile}
        preview={preview}
        files={files}
        assunto={assunto}
        descricao={descricao}
        prioridade={prioridade}
        arquivos={arquivos}
        direcionadoPara={direcionadoPara}
        direcionadoSetores={direcionadoSetores}
        changePrioridade={e => setPrioridade(e.target.value)}
        changeAssunto={e => setAssunto(e.target.value)}
        changeDescricao={value => setDescricao(value)}
        changeDirecionadoPara={e => setDirecionadoPara(e.target.value)}
        changeDirecionadoSetores={e => {
          setDirecionadoSetores(e.target.value)
        }}
        isOpen={isOpen}
        onClose={() => {
          limparCampos();
          setIsOpen(false);
        }

        }
        handleDropMultiFile={handleDropMultiFile}
        handleRemove={handleRemove}
        onCriarChamado={onCriarChamado}
        dataConclusao={dataConclusao}
        changeDataConclusao={e => {
          console.log('changeDataConclusao', e)
          setDataConclusao(e)
        } }
      />

      <Box sx={{
        borderRadius: 1,
        //backgroundColor: 'white',
        // display: 'flex',
        // height: `calc(100vh - ${NAVBAR.DASHBOARD_ITEM_HORIZONTAL_HEIGHT + 50}px)`,
      }}>

        <Container maxWidth>

        <Scrollbar>

          {isEmpty(colunas) ? (
            <Stack sx={{ mt: 10 }} direction="column" alignItems={'center'}>
              <BsDisplay color="primary" size={100} />
              <Typography color={'primary'} sx={{ mt: 3 }} variant='h4'>Sem chamados por enquanto</Typography>
              <Typography sx={{ mt: 1 }} variant='body'>Mantenha a plataforma aberta para receber novos chamados</Typography>
            </Stack>
          ) : (

            <Stack
              direction="row"
              alignItems="flex-start"
              spacing={3}
              // sx={{
              //   width: '100%',
              //   height: 'calc(100% - 32px)',
              //   overflowY: 'hidden'
              // }}
            >
              {!ordemColunas.length ? (
                <SkeletonKanbanColumn />
              ) : (
                ordemColunas.map((columnId, index) => (
                  <Grid container>
                    <Grid item xs={12} md={12} lg={12}>
                    <KanbanColumn listaTags={listaTags} index={index} key={columnId} column={colunas[columnId]} />
                    </Grid>
              

                  </Grid>
                   
            
                ))
              )}
            </Stack>


          )}

        </Scrollbar>
        </Container>
      </Box>


      </Stack>
    </Page>
  );
}
