import axios from "axios";
import Config from "../../config";

const actionListaColunas = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chamados/lista-colunas`,
        data
    });
};

export default actionListaColunas;
