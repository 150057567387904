import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'

import {
    Box,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    IconButton,
    OutlinedInput,
    Stack,
    Typography
} from '@mui/material'
import { IconEraser, IconTrash, IconX } from '@tabler/icons-react'

import { BackdropLoader } from 'src/components/ui-component/loading/BackdropLoader'
import { StyledButton } from 'src/components/ui-component/button/StyledButton'

import { Button } from 'src/@/components/ui/button'

import scraperApi from 'src/api/scraper'

// import useNotifier from '@/utils/useNotifier'

import {
    HIDE_CANVAS_DIALOG,
    SHOW_CANVAS_DIALOG,
    enqueueSnackbar as enqueueSnackbarAction,
    closeSnackbar as closeSnackbarAction
} from 'src/store/actions'
import Scrollbar from 'src/components/Scrollbar'
import { ButtonLoading } from 'src/@/components/use/ButtonLoading'
import { Input } from 'src/@/components/ui/input'
import Iconify from 'src/components/Iconify'
import { RowInput } from 'src/components/forms/RowInput'

const ManageScrapedLinksDialog = ({ show, dialogProps, onCancel, onSave }) => {
    const dispatch = useDispatch()

    //useNotifier()
    const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args))
    const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args))

    const [loading, setLoading] = useState(false)
    const [selectedLinks, setSelectedLinks] = useState([])
    const [url, setUrl] = useState('')

    useEffect(() => {
        if (dialogProps.url) setUrl(dialogProps.url)
        if (dialogProps.selectedLinks) setSelectedLinks(dialogProps.selectedLinks)

        return () => {
            setLoading(false)
            setSelectedLinks([])
            setUrl('')
        }
    }, [dialogProps])

    useEffect(() => {
        if (show) dispatch({ type: SHOW_CANVAS_DIALOG })
        else dispatch({ type: HIDE_CANVAS_DIALOG })
        return () => dispatch({ type: HIDE_CANVAS_DIALOG })
    }, [show, dispatch])

    const handleFetchLinks = async () => {
        setLoading(true)
        try {
            //trocar api
            const fetchLinksResp = await scraperApi.fetchLinks(url, dialogProps.relativeLinksMethod, dialogProps.limit)

            if (fetchLinksResp.data) {
                setSelectedLinks(fetchLinksResp.data.links)
                enqueueSnackbar({
                    message: 'Links extraidos com sucesso',
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'success',
                        action: (key) => (
                            <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
                                <IconX />
                            </Button>
                        )
                    }
                })
            }
        } catch (error) {
            enqueueSnackbar({
                message: 'Erro ao buscar links',
                options: {
                    key: new Date().getTime() + Math.random(),
                    variant: 'error',
                    persist: true,
                    action: (key) => (
                        <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
                            <IconX />
                        </Button>
                    )
                }
            })
            setLoading(false)
        }
        setLoading(false)
    }

    const handleChangeLink = (index, event) => {
        const { value } = event.target
        const links = [...selectedLinks]
        links[index] = value
        setSelectedLinks(links)
    }

    const handleRemoveLink = (index) => {
        const links = [...selectedLinks]
        links.splice(index, 1)
        setSelectedLinks(links)
    }

    const handleRemoveAllLinks = () => {
        setSelectedLinks([])
    }

    const handleSaveLinks = () => {
        onSave(url, selectedLinks)
    }

    const component = show ? (
        <Dialog
            onClose={onCancel}
            open={show}
            fullWidth
            maxWidth='sm'
            aria-labelledby='manage-scraped-links-dialog-title'
            aria-describedby='manage-scraped-links-dialog-description'
        >
            <DialogTitle sx={{ fontSize: '1rem' }} id='manage-scraped-links-dialog-title'>
                {dialogProps.title || `Manipular links de ${url}`}
            </DialogTitle>
            <DialogContent>

                <div className='flex items-center w-full justify-center gap-2 mt-5'>
                    <Input
                        id='url'
                        size='small'
                        type='text'
                        value={url}
                        name='url'
                        label="URL"
                        className="w-full"
                        onChange={(e) => {
                            setUrl(e.target.value)
                        }}
                    />
                    <Button
                        disabled={!url}
                        onClick={handleFetchLinks}
                    >
                        Extrair links
                    </Button>
                </div>

                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', mb: 1.5, mt: 3 }}>
                    <Typography sx={{ fontWeight: 500 }}>Links extraídos</Typography>
                    {selectedLinks.length > 0 ? (
                        <Button
                            className="h-6 bg-red-800"
                            color='error'
                            title='Limpar Todos'
                            onClick={handleRemoveAllLinks}
                        >
                            <Iconify icon="material-symbols:delete-sharp" />
                            Limpar Todos
                        </Button>
                    ) : null}
                </Box>
                <>
                    {loading && <BackdropLoader open={loading} />}
                    {selectedLinks.length > 0 ? (
                        <Scrollbar
                            style={{
                                height: '100%',
                                maxHeight: '320px',
                                overflowX: 'hidden',
                                display: 'flex',
                                flexDirection: 'column',
                                gap: 4
                            }}
                        >
                            {selectedLinks.map((link, index) => (
                                <div key={index} className='w-full flex gap-2'>

                                    <Input
                                        sx={{ width: '100%' }}
                                        key={index}
                                        type='text'
                                        onChange={(e) => handleChangeLink(index, e)}
                                        size='small'
                                        disabled
                                        value={link}
                                        name={`link_${index}`}
                                    />


                                    <Button
                                        onClick={() => handleRemoveLink(index)}
                                        variant="outline"
                                        size="icon">
                                        <Iconify icon="material-symbols:delete-sharp" />

                                    </Button>


                                </div>
                            ))}
                        </Scrollbar>
                    ) : (
                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            <p className=" text-sm">
                                Os links extrídos aparecerão aqui
                            </p>
                        </div>
                    )}
                </>
            </DialogContent>
            <DialogActions>
                <Button onClick={onCancel}>Cancelar</Button>
                <ButtonLoading variant='contained' onClick={handleSaveLinks}>
                    Salvar
                </ButtonLoading>
            </DialogActions>
        </Dialog>
    ) : null

    return component
}

ManageScrapedLinksDialog.propTypes = {
    show: PropTypes.bool,
    dialogProps: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func
}

export default ManageScrapedLinksDialog
