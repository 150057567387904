
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { Icon } from '@iconify/react';
import { useDropzone } from 'react-dropzone';
import fileFill from '@iconify/icons-eva/file-fill';
import closeFill from '@iconify/icons-eva/close-fill';
import { motion, AnimatePresence } from 'framer-motion';
// material
import { alpha, styled } from '@mui/material/styles';
import {
  Box,
  List,
  Stack,
  Paper,
  Button,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton
} from '@mui/material';
// components
import { fData } from 'src/utils/formatNumber';
import { BsFillCloudDownloadFill } from 'react-icons/bs';


const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

ChamadosArquivos.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object
};

export default function ChamadosArquivos({
  error,
  loadingEnviarArquivos,
  onEnviarArquivos,
  isEdit,
  isEditFile,
  showPreview = false,
  files,
  filesEdit,
  completado,
  onRemoveEdit,
  onRemove,
  onRemoveAll,
  sx,
  isMe,
  ...other }) {
  const hasFile = files.length > 0;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
    // maxFiles:2,
    multiple: true,
    maxSize: 5000000
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path, size } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>

            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - Arquivo é muito grande epra ser enviado. O maximo permitido é <strong>5mb</strong>
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx }}>
      {!completado && (
        <DropZoneStyle
          {...getRootProps()}
          sx={{
            ...(isDragActive && { opacity: 0.72 }),
            ...((isDragReject || error) && {
              color: 'error.main',
              borderColor: 'error.light',
              bgcolor: 'error.lighter'
            })
          }}
        >
          <input {...getInputProps()} />
          {/* <BsPlusLg size={15} /> */}

          {/* <UploadIllustration sx={{ width: 70 }} /> */}


          <Box sx={{ p: 1, ml: { md: 2 } }}>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              Arraste arquivos ou selecione&nbsp;
              <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
                buscando
              </Typography>
              &nbsp;em sua máquina
            </Typography>
            <Typography style={{ fontSize: 10 }}>Limite máximo de 5mb para arquivos</Typography>
          </Box>



        </DropZoneStyle>
      )}
      {fileRejections.length > 0 && <ShowRejectionItems />}

      <Stack>

      </Stack>

      {/* <Button variant="contained" sx={{width:'100%', marginTop:2, marginBottom:2}}>Baixar todos arquivos</Button> */}

      {isEditFile ? (

        <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
          <AnimatePresence>
            {filesEdit.map((file) => {

              const { key, type, size, name } = file;

              function verificaTipoImagem(type) {
                if (type === "image/png") {
                  return `https://lais-app.s3.us-east-2.amazonaws.com/${key}`;
                } else if (type === "image/jpg") {
                  return `https://lais-app.s3.us-east-2.amazonaws.com/${key}`;
                } else if (type === "application/pdf") {
                  return 'https://lais-app.s3.us-east-2.amazonaws.com/files/pdf.png'
                } else if (type === "video/mp4") {
                  return 'https://lais-app.s3.us-east-2.amazonaws.com/files/camera-de-video.png'
                } else {
                  return 'https://lais-app.s3.us-east-2.amazonaws.com/files/pasta.png'
                }
              }


              // if (showPreview) {
              //   return (
              //     <>
              //       <ListItem
              //         key={key}

              //         sx={{
              //           p: 0,
              //           m: 0.5,
              //           width: 70,
              //           height: 70,
              //           borderRadius: 1.5,
              //           overflow: 'hidden',
              //           position: 'relative',
              //           display: 'inline-flex'
              //         }}
              //       >

              //         <Paper
              //           variant="outlined"
              //           component="img"
              //           src={isString(file) ? file : verificaTipoImagem(type)}
              //           sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
              //         />

              //         <Box sx={{ top: 6, right: 6, position: 'absolute' }}>

              //           <IconButton
              //             size="small"
              //             onClick={() => onRemoveEdit(file)}
              //             sx={{
              //               p: '2px',
              //               color: 'common.white',
              //               bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
              //               '&:hover': {
              //                 bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
              //               }
              //             }}
              //           >
              //             <Icon icon={closeFill} />
              //           </IconButton>

              //         </Box>

              //       </ListItem>

              //     </>
              //   );
              // }

              return (
                <ListItem
                  key={key}

                  sx={{
                    my: 1,
                    width: '100%',
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper'
                  }}
                >
                  <ListItemIcon sx={{ background: '#fff' }}>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>

                  <ListItemText
                    primary={<>{name.substr(0, 30)} {name.length > 30 && '...'}</>}
                    secondary={fData(size)}
                    primaryTypographyProps={{ variant: 'caption' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />

                  <ListItemSecondaryAction>
               
                      <IconButton size="small" onClick={() => onRemoveEdit(file)}>
                        <Icon icon={closeFill} />
                      </IconButton>
              
                    <IconButton
                      target="_blank"
                      component="a"
                      href={`https://lais-app.s3.us-east-2.amazonaws.com/${key}`}
                      size="small"
                      variant={'text'}
                      color={'primary'}
                    >
                      <BsFillCloudDownloadFill />
                    </IconButton>

                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </AnimatePresence>
        </List>

      ) : (

        <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
          <AnimatePresence>
            {files.map((file) => {
              const { name, size, preview, type } = file;
              const key = isString(file) ? file : name;
              function verificaTipoImagem(type) {
                if (type === "image/png") {
                  return preview;
                } else if (type === "image/jpg") {
                  return preview;
                } else if (type === "application/pdf") {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/pdf.png'
                } else if (type === "video/mp4") {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/camera-de-video.png'
                } else {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/pasta.png'
                }
              }


              if (showPreview) {
                return (
                  <>
                    <ListItem
                      key={key}
                      sx={{
                        p: 0,
                        m: 0.5,
                        width: 70,
                        height: 70,
                        borderRadius: 1.5,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-flex'
                      }}
                    >

                      <Paper
                        variant="outlined"
                        component="img"
                        src={isString(file) ? file : verificaTipoImagem(type)}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                      />

                      <Box sx={{ top: 6, right: 6, position: 'absolute' }}>
                        <IconButton
                          size="small"
                          onClick={() => onRemove(file)}
                          sx={{
                            p: '2px',
                            color: 'common.white',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                            '&:hover': {
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                            }
                          }}
                        >
                          <Icon icon={closeFill} />
                        </IconButton>
                      </Box>

                    </ListItem>

                    <ListItemText
                      primary={isString(file) ? file : name}
                      secondary={isString(file) ? '' : fData(size)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                      secondaryTypographyProps={{ variant: 'caption' }}
                    />


                  </>
                );
              }

              return (
                <ListItem
                  key={key}


                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper'
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>
                  <ListItemText
                    primary={isString(file) ? file : name}
                    secondary={isString(file) ? '' : fData(size)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>

                    <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                      <Icon icon={closeFill} />
                    </IconButton>
                  </ListItemSecondaryAction>

                </ListItem>
              );
            })}
          </AnimatePresence>
        </List>

      )}

    </Box>
  );
}
