import { Button, Badge, Typography, ListItemButton,Stack } from '@mui/material';
import Iconify from '../Iconify';


export default function ButtonMenuNavbar({ nome, to, icon, badge, path, slug }) {
  return (
    <Badge badgeContent={badge} color="error">
      <ListItemButton
        onClick={to}
        selected={path === slug ? true : false}
        sx={{
          "&.Mui-selected": {
            //backgroundColor: theme => theme.palette.primary.main,
            backgroundColor: 'rgba(168, 134, 255, 0.15)',
            background:'none'
          },
          py: 0.8,
          px: 1.5,
          borderRadius: 0.5,
        }}
      // sx={{background:'primary.main', py:0, px:1, borderRadius:0.3}}
      //startIcon={}
      >
        <Stack alignItems={"center"} direction="row" spacing={0.5}>
          {/* <Iconify width={18} color="#333" sx={{ mr: -0.7 }} icon={icon} /> */}
          <div className='flex items-center gap-2 text-sm text-slate-800'>
          {nome}
          </div>
          {/* <Typography
            sx={{ fontSize: 13, fontWeight: '400' }}
            color={path === slug ? 'rgb(141, 161, 255)' : '#333'}

          >
           
          </Typography> */}
        </Stack>


      </ListItemButton >
    </Badge>

  )
}
