// material
import {
    Alert,
    Autocomplete,
    Button,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    MenuItem, Select, Stack, Typography, Drawer, Container, IconButton
} from '@mui/material';
import './react-phone-number-input.css'
import { LoadingButton } from '@mui/lab';
import { useState, forwardRef, useEffect } from 'react';

import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { RowInput } from 'src/components/forms/RowInput';
import { maskCPF, maskDate } from 'src/utils/functions';
import v2_actionEditarContato from 'src/actions/v2/contatos/v2_actionEditarContato';
import { useSnackbar } from 'notistack';
import { Input } from 'src/@/components/ui/input';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/@/components/ui/accordion';
import { set } from 'lodash';

// ----------------------------------------------------------------------

export default function VerContatoForm({
    user,
    cliente,
    open,
    contatoData,
    showSaveButton,
    onClose,
    callbackAddContact,
    callBackRefreshList
}) {
    const [contato, setContato] = useState({
        nome: contatoData?.nome,
        email: contatoData?.info?.email,
        whatsapp: contatoData?.telefone,
        dataNascimento: contatoData?.info?.dataNascimento,
        cpf: contatoData?.info?.cpf
    });
    const [endereco, setEndereco] = useState({
        cep: contatoData?.info?.endereco?.cep,
        rua: contatoData?.info?.endereco?.rua,
        numero: contatoData?.info?.endereco?.numero,
        bairro: contatoData?.info?.endereco?.bairro,
        cidade: contatoData?.info?.endereco?.cidade,
        estado: contatoData?.info?.endereco?.estado,
        complemento: contatoData?.info?.endereco?.complemento
    });

    const { enqueueSnackbar } = useSnackbar();
    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');
    const [loading, setLoading] = useState(false);
    const handleCallbackAddContact = () => {
        let data = {
            contato,
            endereco,
            camposPersonalizados
        }
        callbackAddContact(data)
        clearFields();

    }

    useEffect(() => {
        setContato({
            nome: contatoData?.nome,
            email: contatoData?.info?.email,
            whatsapp: contatoData?.telefone,
            dataNascimento: contatoData?.info?.dataNascimento,
            cpf: contatoData?.info?.cpf
        });
        setEndereco({
            cep: contatoData?.info?.endereco?.cep,
            rua: contatoData?.info?.endereco?.rua,
            numero: contatoData?.info?.endereco?.numero,
            bairro: contatoData?.info?.endereco?.bairro,
            cidade: contatoData?.info?.endereco?.cidade,
            estado: contatoData?.info?.endereco?.estado,
            complemento: contatoData?.info?.endereco?.complemento
        });

        setCamposPersonalizados(contatoData?.custom_fields || [])

    }, [contatoData, open])

    const [camposPersonalizados, setCamposPersonalizados] = useState([]);
    const [campoPersonalizado, setCampoPersonalizado] = useState({
        nome: '',
        valor: ''
    })

    async function addNovoCampoPersonalizado() {
        if (campoPersonalizado.nome === '' || campoPersonalizado.valor === '') {
            enqueueSnackbar('Preencha os campos', { variant: 'error' });
            return;
        }
        let novoCampo = {
            nome: campoPersonalizado.nome,
            valor: campoPersonalizado.valor
        }
        setCamposPersonalizados([...camposPersonalizados, novoCampo]);
        setCampoPersonalizado({
            nome: '',
            valor: ''
        })
    }

    async function removeCampoPersonalizado(index) {
        let newCampos = camposPersonalizados.filter((item, i) => i !== index)
        setCamposPersonalizados(newCampos)
    }


    async function editarContato() {
        setLoading(true);
        console.log(contato)
        try {
            let data =
            {
                scope: {
                    nome: contato.nome,
                    telefone: contato.whatsapp,
                    info: {
                        email: contato.email,
                        cpf: contato.cpf,
                        dataNascimento: contato.dataNascimento,
                        endereco: {
                            cep: endereco.cep,
                            rua: endereco.rua,
                            bairro: endereco.bairro,
                            cidade: endereco.cidade,
                            estado: endereco.estado,
                            numero: endereco.numero,
                            complemento: endereco.complemento
                        }
                    },
                    custom_fields: camposPersonalizados
                }
            }

            await v2_actionEditarContato(contatoData._id, data)
            setLoading(false);
            callBackRefreshList()
            // setIsChange(false);
            enqueueSnackbar('Contato alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }




    function clearFields() {
        setContato({
            nome: '',
            email: '',
            whatsapp: '',
            dataNascimento: '',
            cpf: ''
        })

        setEndereco({
            cep: "",
            rua: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            complemento: ""
        })

    }


    function limparCampos() {
        setRua("");
        setCep("");
        setCidade("");
        setEstado("");
        setDDD("");
        setNome("");
        setEmail("");
        setSobrenome("");
        setBairro("");
        setNumero("");
        setWhatsapp("");
        setCpf("");
        setObs("");
    }


    const handleOnBlurSearchCep = async (zipCode) => {
        try {
            const address = await buscarCep(zipCode)
            if (!address.erro) {
                setEndereco({
                    cep: address.cep,
                    rua: address.logradouro,
                    bairro: address.bairro,
                    cidade: address.localidade,
                    estado: address.uf,
                })

            }
        } catch (address) {

        }
    }

    return (
        <div>

            <ModalAlerta
                open={openModalAlerta}
                mensagem={mensagemModalAlerta}
                onClose={() => {
                    setOpenModalAlerta(false);
                    setMensagemModalAlerta('');
                }}
            />


            <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={1}>

                <Stack spacing={1}>

                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                                isRequired={true}
                                label="Nome"
                                input={<Input onChange={e => {
                                    setContato({ ...contato, nome: e.target.value })
                                }}
                                    value={contato.nome}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>
                    </Stack>

                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '60%' }}>
                            <RowInput
                                isRequired={true}
                                label="Telefone"
                                input={<PhoneInput
                                    defaultCountry='BR'
                                    className='bg-slate-100'
                                    disabled={true}
                                    style={{ padding: 3, borderRadius: 4, border: '1px solid #ccc', background:'#f9f9f9' }}
                                    placeholder="Insira seu numero"
                                    value={contato.whatsapp}
                                    onChange={value => setContato({ ...contato, whatsapp: value })}
                                />}
                            />
                        </Box>

                        <Box sx={{ width: '40%' }}>
                            <RowInput
                                label="Data Nascimento"
                                input={<Input onChange={e => {
                                    setContato({ ...contato, dataNascimento: e.target.value })
                                }}
                                    value={contato.dataNascimento && maskDate(contato.dataNascimento)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>

                    </Stack>


                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >

                        <Box sx={{ width: '60%' }}>
                            <RowInput
                                label="Email"
                                type="email"
                                input={<Input onChange={e => {
                                    setContato({ ...contato, email: e.target.value })
                                }}
                                    value={contato.email}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>

                        <Box sx={{ width: '40%' }}>
                            <RowInput
                                label="CPF"
                                input={<Input onChange={e => {
                                    setContato({ ...contato, cpf: e.target.value })
                                }}
                                    value={contato.cpf && maskCPF(contato.cpf)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>

                    </Stack>


                    <Divider sx={{ pt: 1, pb: 1, borderStyle: 'dashed' }} />



                </Stack>

            </Stack>

            <Accordion type="multiple" collapsible={true} className="w-full">
                <AccordionItem value="item-1">
                    <AccordionTrigger>Endereço</AccordionTrigger>
                    <AccordionContent>
                        <Stack direction={"column"} spacing={1}>

                            <Stack
                                sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                                spacing={1}
                                direction={"row"}
                            >
                                <Box sx={{ width: '50%' }}>
                                    <RowInput
                                        label="CEP"

                                        input={<Input onChange={e => {
                                            setEndereco({ ...endereco, cep: e.target.value })
                                        }}
                                            onBlur={e => handleOnBlurSearchCep(e.target.value)}
                                            value={endereco.cep}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />}
                                    />
                                </Box>
                            </Stack>

                            <Stack
                                sx={{ width: { xs: '100%', md: '100%' } }}
                                spacing={1}
                                direction={"row"}
                            >
                                <Box sx={{ width: '100%' }}>
                                    <RowInput
                                        label="Rua"
                                        input={<Input onChange={e => {
                                            setEndereco({ ...endereco, rua: e.target.value })

                                        }} value={endereco.rua} variant="outlined" size="small" fullWidth />}
                                    />
                                </Box>
                            </Stack>


                            <Stack
                                sx={{ width: { xs: '100%', md: '100%' } }}
                                spacing={1}
                                direction={"row"}
                            >
                                <Box sx={{ width: '25%' }}>
                                    <RowInput
                                        label="Número"
                                        input={<Input onChange={e => {
                                            setEndereco({ ...endereco, numero: e.target.value })
                                        }} value={endereco.numero} variant="outlined" size="small" fullWidth />}
                                    />
                                </Box>
                                <Box sx={{ width: '75%' }}>
                                    <RowInput
                                        label="Bairro"
                                        input={<Input onChange={e => {
                                            setEndereco({ ...endereco, bairro: e.target.value })
                                        }}
                                            value={endereco.bairro}
                                        />}
                                    />
                                </Box>

                            </Stack>


                            <Stack
                                sx={{ width: { xs: '100%', md: '100%' } }}
                                spacing={1}
                                direction={"row"}
                            >
                                <Box sx={{ width: '40%' }}>
                                    <RowInput
                                        label="Complemento"
                                        input={<Input onChange={e => {
                                            setEndereco({ ...endereco, complemento: e.target.value })
                                        }} value={endereco.complemento} variant="outlined" size="small" fullWidth />}
                                    />
                                </Box>
                                <Box sx={{ width: '40%' }}>
                                    <RowInput
                                        label="Cidade"
                                        input={<Input onChange={e => {
                                            setEndereco({ ...endereco, cidade: e.target.value })
                                        }} value={endereco.cidade} variant="outlined" size="small" fullWidth />}
                                    />
                                </Box>
                                <Box sx={{ width: '20%' }}>
                                    <RowInput
                                        label="Estado"
                                        input={<Input onChange={e => {
                                            setEndereco({ ...endereco, estado: e.target.value })
                                        }} value={endereco.estado} variant="outlined" size="small" fullWidth />}
                                    />
                                </Box>
                            </Stack>
                        </Stack>
                    </AccordionContent>
                </AccordionItem>
                <AccordionItem value="item-2">
                    <AccordionTrigger>Campos personalizados</AccordionTrigger>
                    <AccordionContent>

                        {
                            camposPersonalizados.map((item, index) => (
                                <Stack className="mt-3" direction={"row"}>

                                    <RowInput
                                        sx={{ width: '100%' }}
                                        label={item.nome}
                                        input={<Input onChange={e => {
                                            // encontrar o objeto no array e alterar o valor
                                            camposPersonalizados[index].valor = e.target.value;
                                            setCamposPersonalizados([...camposPersonalizados])
                                        }}
                                        className="h-7 text-xs"
                                            value={item.valor}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />}
                                    />

                                    <Stack>
                                        <Button onClick={() => removeCampoPersonalizado(index)} variant="ghost" size="icon" className="mt-5 ml-1">
                                            <Iconify icon="material-symbols-light:delete-outline" />
                                        </Button>

                                    </Stack>

                                </Stack>
                            ))
                        }
                        <Divider sx={{ pt: 1, pb: 1, borderStyle: 'dashed' }} />
                        <Stack className='mt-5' direction="column">
                            {/* <div className="text-xs text-gray-300 font-medium">criar novo campo</div> */}

                            <Stack className="mt-2 w-full" direction={"row"}>
                                <Stack className="w-full" spacing={1} direction={"row"} alignItems={"center"}>
                                    <RowInput
                                        label="nome"
                                        sx={{ width: '30%' }}
                                        input={<Input
                                            onChange={e => setCampoPersonalizado(
                                                { ...campoPersonalizado, nome: e.target.value }
                                            )}
                                            value={campoPersonalizado.nome}
                                            className="rounded-sm w-full h-6 bg-slate-50"
                                        />}
                                    />
                                    <RowInput
                                        label="valor"
                                        sx={{ width: '70%' }}

                                        input={<Input
                                            onChange={e => setCampoPersonalizado(
                                                { ...campoPersonalizado, valor: e.target.value }
                                            )}
                                            value={campoPersonalizado.valor}
                                            className="rounded-sm w-full h-6 bg-slate-50"
                                        />}
                                    />
                                </Stack>
                                <Button onClick={addNovoCampoPersonalizado} size="icon" variant="secondary" className="mt-6 ml-1 h-6 bg-slate-100">
                                    <Iconify icon="ic:baseline-add" />
                                </Button>

                            </Stack>
                        </Stack>

                    </AccordionContent>
                </AccordionItem>
            </Accordion>

            {showSaveButton && ([
                <Stack direction="row" sx={{ mt: 2, mb: 2 }}>
                    <Button
                        onClick={() => editarContato()}
                        sx={{ height: 25, fontSize: 10, borderRadius: 0.5 }}
                        variant="contained"
                        startIcon={<Iconify icon="mdi:plus" />}
                    >
                        Salvar
                    </Button>
                </Stack>
            ])}

        </div>
    );
}
