import { Stack, Container } from "@mui/material";
//zui 
import { Button } from 'src/@/components/ui/button';
import { Card, CardContent } from 'src/@/components/ui/card';
import { Label } from 'src/@/components/ui/label';
import { Input } from 'src/@/components/ui/input';
import { RowInput } from 'src/components/forms/RowInput';
import { RadioGroup, RadioGroupItem } from 'src/@/components/ui/radio-group';
import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select"
import { Slider } from 'src/@/components/ui/slider';
import { Textarea } from 'src/@/components/ui/textarea';
import { Switch } from 'src/@/components/ui/switch';
import ContainerOptions from "../components/ContainerOptions";


export default function AiAgentProfileTab({
    aiAgent,
    config,
    changePersona,
    changeCompanyName,
    changeCreativity,
    changeTemperature,
    changeBusinessActivity,
    changeTypeCommunication,
    changeAgentType,
    changeAgentFunction,
    changeName
}) {

    return (

        <Container maxWidth="lg">

            <Stack sx={{ my: 5 }} direction={"column"} spacing={3}>
                <ContainerOptions>
                    <Stack direction={"row"} spacing={1}>
                        <RowInput
                            sx={{ width: '100%' }}
                            label="Nome do agente"
                            input={
                                <Input
                                    name="name"
                                    value={aiAgent?.name}
                                    onChange={(e) => {
                                        changeName(e.target.value)
                                        //  setAiAgent({ ...aiAgent, config: { ...aiAgent.config, promptGuide: { ...aiAgent.config.promptGuide, persona: e.target.value } } })
                                    }}
                                />
                            }
                        />
                        {aiAgent?.config?.generalConfig?.type === "stepByStep" && (
                            <RowInput
                                sx={{ width: '100%' }}
                                label="Persona"
                                input={
                                    <Input
                                        name="name"
                                        value={aiAgent?.config?.promptGuide?.persona}
                                        onChange={(e) => {
                                            changePersona(e.target.value)
                                            //  setAiAgent({ ...aiAgent, config: { ...aiAgent.config, promptGuide: { ...aiAgent.config.promptGuide, persona: e.target.value } } })
                                        }}
                                    />
                                }
                            />
                        )}

                        {aiAgent?.config?.generalConfig?.type === "stepByStep" && (

                            <RowInput
                                sx={{ width: '100%' }}

                                label="Nome da empresa"
                                input={
                                    <Input
                                        value={aiAgent?.config?.promptGuide?.companyName}
                                        onChange={(e) => {
                                            changeCompanyName(e.target.value)
                                            // setAiAgent({ ...aiAgent, config: { ...aiAgent.config, promptGuide: { ...aiAgent.config.promptGuide, companyName: e.target.value } } })
                                        }}
                                    />
                                }
                            />
                        )}


                    </Stack>
                </ContainerOptions>

                <ContainerOptions>
                    <Stack direction={"row"} spacing={1}>

                        <div className='w-full'>
                            <ContainerOptions>
                                <RowInput
                                    label="Criatividade do agente"
                                    input={
                                        <div className="pt-2">
                                            <Slider
                                                onValueChange={(value) => {
                                                    changeTemperature(value);
                                                    // setAiAgent({ ...aiAgent, config: { ...aiAgent.config, promptGuide: { ...aiAgent.config.promptGuide, creativity: value } } })
                                                }}
                                                defaultValue={[aiAgent?.config?.generalConfig?.temperature]}
                                                max={10}
                                                step={1}
                                            />
                                            <div className="flex justify-between mt-2">
                                                <p className="text-[10px]">pouco criativo</p>
                                                <p className="text-[10px]">muito criativo</p>
                                            </div>
                                        </div>
                                    }
                                />
                            </ContainerOptions>
                        </div>

                    </Stack>
                </ContainerOptions>



                <Stack direction="row" spacing={2}>


                    {aiAgent?.config?.generalConfig?.type === "stepByStep" && (
                        <>
                            <div className='w-full'>
                                <ContainerOptions>
                                    <RowInput
                                        label={"Função"}
                                        input={
                                            <RadioGroup
                                                value={aiAgent?.config?.generalConfig?.agent_function_id}
                                                onValueChange={(value) => changeAgentFunction(value)}
                                                className="grid grid-cols-2 gap-4"
                                            >
                                                {config?.agentFunctions.map((option) => (
                                                    <div className='cursor-pointer w-full' key={option._id}>
                                                        <RadioGroupItem
                                                            value={option._id}
                                                            id={option._id}
                                                            className="peer sr-only "
                                                        />
                                                        <Label
                                                            htmlFor={option._id}
                                                            className={`flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-2 hover:bg-accent hover:text-accent-foreground ${aiAgent?.config?.generalConfig?.agent_function_id === option._id ? 'bg-violet-500 text-white' : ''}`}
                                                        >
                                                            {option.name}
                                                        </Label>
                                                    </div>
                                                ))}
                                            </RadioGroup>
                                        }
                                    />
                                </ContainerOptions>
                            </div>
                            <div className="w-full">
                                <ContainerOptions>
                                    <Stack spacing={2} direction={"row"}>
                                        <RowInput

                                            label={"Atividade"}
                                            input={<Select
                                                value={aiAgent?.config?.promptGuide?.business_activities_id}
                                                onValueChange={(value) => changeBusinessActivity(value)}
                                            >
                                                <SelectTrigger className="w-full">
                                                    <SelectValue placeholder="Selecione uma atividade" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {config?.businessActivities.map((i) => (
                                                        <SelectGroup key={i._id}>
                                                            <SelectItem value={i._id}>{i.name}</SelectItem>
                                                        </SelectGroup>
                                                    ))}
                                                </SelectContent>
                                            </Select>}
                                        />

                                        <RowInput
                                            label="Comunicação"
                                            input={<Select value={aiAgent?.config?.promptGuide?.type_speaking_id}
                                                onValueChange={(value) => {
                                                    changeTypeCommunication(value)
                                                    // setAiAgent({ ...aiAgent, config: { ...aiAgent.config, promptGuide: { ...aiAgent.config.promptGuide, type_speaking_id: value } } })
                                                }}
                                            >
                                                <SelectTrigger className="w-full">
                                                    <SelectValue placeholder="" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    {config?.typeComunications.map((i) => (
                                                        <SelectGroup key={i._id}>
                                                            <SelectItem value={i._id}>{i.name}</SelectItem>
                                                        </SelectGroup>
                                                    ))}
                                                </SelectContent>
                                            </Select>}
                                        />
                                    </Stack>
                                </ContainerOptions>
                            </div>

                        </>
                    )}

                </Stack>
            </Stack>
        </Container>

    )

}