import { Typography, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
// material

//
// import Typography from 'src/theme/overrides/Typography';

// ----------------------------------------------------------------------



const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  background:'#f0eef3'
}));

// ----------------------------------------------------------------------



export default function Defcon({ ...other }) {
  const [counter, setCounter] = useState(120);

  const {defcon} = useSelector((state) => state.global);

  useEffect(() => {
    const timer =
      counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
    return () => clearInterval(timer);
  }, [counter]);

  const Defcon1 = () => {
    return (
      <Stack alignItems="center" diectiction="collumn">
        <img width="300" src="https://lais-app.s3.us-east-2.amazonaws.com/static/maintenance-page_01.gif" />
        <h1 className="scroll-m-20 text-2xl font-extrabold tracking-tight lg:text-2xl">
        Estamos fazendo uma rápida atualização no sistema
    </h1>
    <p className="text-xl text-muted-foreground mt-3">
    Só vai levar alguns instantes, por favor aguarde!
    </p>

      </Stack>
    )
  }


  const Defcon2 = () => {
    return (
      <Stack alignItems="center" diectiction="collumn">
        <img width="100" src="https://zindi.s3.us-east-2.amazonaws.com/files/whatsapp-pulse.gif" />
        <Typography sx={{ mt: 4 }} variant='h4'>Desconexão dectada</Typography>
        <Typography sx={{ mt: 1 }} variant='body'></Typography>
      </Stack>
    )
  }


  return (
    <RootStyle {...other}>

      {defcon === "desconnectedMobile" && <Defcon2 />}
      {defcon === "1" && <Defcon1 />}

    </RootStyle>
  );
}
