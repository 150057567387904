
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  TextField,
  Stack,
  Box,
  Typography,
  Avatar
} from '@mui/material';
import { useState } from 'react';
import { Input } from 'src/@/components/ui/input';
import getActionPesquisarChat from 'src/actions/chats/getActionPesquisarChat';
import { RowInput } from 'src/components/forms/RowInput';
import HeaderModal from 'src/components/modal/HeaderModal';
import palette from 'src/theme/palette';
import { formataNumeroCelular } from 'src/utils/functions';

import { Button } from 'src/@/components/ui/button';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';

// ----------------------------------------------------------------------

export default function ModalEncaminharMensagem({
  user,
  open,
  onClose,
  onSubmit,
  pesquisa,
  changePesquisa,
  dataPesquisa,
  onSelect,
  loading
}) {

  const [search, setSearch] = useState("");
  const [listaContatos, setListaContatos] = useState([]);
  const [selected, setSelected] = useState(null);

  async function handlePesquisarChat(event) {
    const { value } = event.target;

    try {

      if (value.length > 3) {
        let data = {
          clienteId: user.clienteId,
          search: value
        }
        console.log('user pesuqi', user)
        const res = await getActionPesquisarChat(data)
        setListaContatos(res.data.chats)
      }

    } catch (error) {
      console.log(error)
    }
  }




  return (
    <div>

      <Dialog
        open={open}
        fullWidth={true}
        maxWidth={'sm'}
      >
        <HeaderModal title="Encaminhar Mensagem" />
        <Divider />
        <DialogContent>
          <Stack spacing={2}>
            <Stack direction={'collumn'}>
              <Input value={pesquisa} onChange={handlePesquisarChat} placeholder="Pesquisar chat" />


            </Stack>
            <Stack>
              <Box sx={{ height: 300, overflow: 'auto' }}>
                {listaContatos && listaContatos.map((data, index) => {
                  return (
                    <Box
                      key={data._id}
                      onClick={() => setSelected(data)}
                      sx={{
                        padding: 1,
                        cursor: 'pointer',
                         backgroundColor: data._id === (selected && selected._id) ? palette.light.background.lighter : '#fff',
                        borderRadius: 1
                      }} >
                      <Stack spacing={3} direction={'row'}>
                        <Stack>
                          <Avatar
                            sx={{ borderRadius: 1 }}
                            src={data.fotoPerfil}
                          />
                        </Stack>
                        <Stack direction={'column'}>
                          <small className="text-sm font-medium leading-none">{formataNumeroCelular(data.numeroCliente)}</small>

                          <p className="leading-7">
                          {data.nomeCliente}
                          </p>
         
                        </Stack>
                      </Stack>
                    </Box>
                  )
                })}
              </Box>
            </Stack>
          </Stack>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          {selected && (
            <ButtonLoading loading={loading} onClick={() => onSubmit(selected.numeroCliente)} autoFocus>Encaminhar</ButtonLoading>
          )}

        </DialogActions>
      </Dialog>
    </div>
  );
}
