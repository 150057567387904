// @mui
import { Card, Skeleton, Stack } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonListaChats() {


const Item = () => {
    return (
        <>
        <Skeleton variant="rectangular" />
        <Stack direction={'row'} spacing={2} sx={{ p: 1 }}>
            <Stack>
            <Skeleton variant="rectangular" sx={{ width: 50, height: 50, borderRadius:1, bgcolor: 'grey.300' }} />
            </Stack>
            <Stack>
            <Skeleton variant="text" sx={{ width: 150, bgcolor: 'grey.300' }} />
            <Skeleton variant="text" sx={{ width: 120, bgcolor: 'grey.300' }} />
            </Stack>
        </Stack>
      </>
    )
}


  return (

    <Stack sx={{p:1}} spacing={1} direction={'column'}>
        <Item />
        <Item />
        <Item />
        <Item />
    </Stack>
  
  );
}
