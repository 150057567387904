
import { Box, Button, Divider, Typography, Stack, IconButton, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';

import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import buscarCep from 'src/actions/chats/buscarCep';
import PhoneInput from 'react-phone-number-input'
import { formataNumeroCelular, maskCPF, maskDate } from 'src/utils/functions';
import v2_actionPesquisarContatos from 'src/actions/v2/contatos/v2_actionPesquisarContatos';
import InputStyleAutocomplete from 'src/components/InputStyleAutocomplete';
import v2_actionEditarContato from 'src/actions/v2/contatos/v2_actionEditarContato';
import { getChatInfoByChatId } from 'src/redux/slices/chat';
import { useDispatch } from 'react-redux';


ChatInfoContatoForm.propTypes = {
    isCollapse: PropTypes.bool,
    onCollapse: PropTypes.func,
    lead: PropTypes.object,
};

export default function ChatInfoContatoForm({
    cliente,
    lead,
    onOpenModalNovoContato,
    onEditarContatoId
}) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const [listaContatos, setListaContatos] = useState([]);
    const [pesquisa, setPesquisa] = useState('');
    const [contactId, setContactId] = useState(lead?.contato);
    const [isChange, setIsChange] = useState(null);
    const [loadingEditarContato, setLoadingEditarContato] = useState(null);

    const [contato, setContato] = useState({
        nome: contactId?.nome,
        email: contactId?.info?.email,
        whatsapp: contactId?.telefone,
        dataNascimento: contactId?.info?.dataNascimento,
        cpf: contactId?.info?.cpf
    });
    const [endereco, setEndereco] = useState({
        cep: contactId?.info?.endereco?.cep,
        rua: contactId?.info?.endereco?.rua,
        numero: contactId?.info?.endereco?.numero,
        bairro: contactId?.info?.endereco?.bairro,
        cidade: contactId?.info?.endereco?.cidade,
        estado: contactId?.info?.endereco?.estado,
        complemento: contactId?.info?.endereco?.complemento
    });

    useEffect(() => {
        setContactId(lead?.contato);
    }, [lead])



    async function pesquisarContatos(pesquisa) {
        try {
            const result = await v2_actionPesquisarContatos(cliente._id, pesquisa)
            setListaContatos(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function editarContato(data) {

        setLoadingEditarContato(true);
        try {
            await v2_actionEditarContato(contactId._id, data)
            setLoadingEditarContato(false);
            setIsChange(false);
            enqueueSnackbar('Contato alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
            dispatch(getChatInfoByChatId(lead._id))
        } catch (error) {
            setLoadingEditarContato(false);
            console.log(error);
        }
    }


    return (
        <Stack>
            {/* <Scrollbar
                sx={{ height: '35vh' }}
            > */}
                <Box sx={{ width: '100%' }}>
                    <Stack alignItems={"center"} direction="row" spacing={2}>
                        <Autocomplete
                            filterOptions={(x) => x}
                            autoComplete
                            includeInputInList
                            placeholder='Escolha um contato'
                            filterSelectedOptions
                            id="combo-box-demo"
                            options={listaContatos}
                            value={contactId}
                            inputValue={pesquisa}
                            noOptionsText={
                                <Stack direction="column" spacing={1}>
                                    <Typography variant="extraSmall" textAlign={"center"} sx={{ color: 'text.secondary' }}>
                                        Pesquise por nome ou telefone...
                                    </Typography>
                                    <Button onClick={onOpenModalNovoContato} startIcon={<Iconify icon={'gg:add'} sx={{ width: 20, height: 20 }} />} size="small">
                                        Criar Contato
                                    </Button>
                                </Stack>
                            }
                            onChange={(event, newValue, reason) => {
                                console.log('reset', reason)
                                if (reason === "clear") {
                                    setContactId(null);
                                    onEditarContatoId(null);
                         
                                    return;
                                } else {
                                    setContactId({contato_id:newValue});
                                    onEditarContatoId(newValue?._id);
                                    dispatch(getChatInfoByChatId(lead._id));
                                }
                            
                            }}
                            onInputChange={async (event, newInputValue) => {
                                setPesquisa(newInputValue);
                                if (newInputValue.length > 3) {
                                    pesquisarContatos(newInputValue);
                                } else {
                                    setListaContatos([]);
                                }
                            }}
                            isOptionEqualToValue={(option, value) => option._id === value?._id}
                            getOptionLabel={(option) =>
                                typeof option === 'string' ? option : ((option.nome ? `${option.nome} -` : "") + '' + formataNumeroCelular(option.telefone))

                            }
                            renderInput={(params) => <InputStyleAutocomplete sx={{ width: 250 }} {...params} />}
                        />
                        {!contactId && (
                            <IconButton onClick={() => {
                                onOpenModalNovoContato();
                            }}>
                                <Iconify icon={'zondicons:add-solid'} sx={{ width: 20, height: 20 }} />
                            </IconButton>
                        )}
                        {/* {!contactId && (
                            <Button variant="contained" startIcon={<Iconify icon={'gg:add'} sx={{ width: 20, height: 20 }} />} size="small">
                                Criar Contato
                            </Button>
                        )} */}
                    </Stack>
                </Box>

                <Divider sx={{ my: 2, borderStyle: 'dashed' }} />

                {/* <Box sx={{ mt: 3, pb: 5 }}>

                    {contactId && (
                        <Box sx={{ px: 0.5 }}>
                            <Stack direction="column" spacing={2}>
                                <Stack
                                    sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                                    spacing={1}
                                    direction={"column"}
                                >

                                    <RowInput
                                        isRequired={true}
                                        label="Nome"
                                        input={<InputStyle onChange={e => {
                                            setContato({ ...contato, nome: e.target.value })
                                            setIsChange(true);
                                        }}
                                            value={contato.nome}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />}
                                    />
                                    <RowInput
                                        isRequired={true}
                                        label="Telefone"
                                        input={<PhoneInput
                                            defaultCountry='BR'
                                            style={{ padding: 10, borderRadius: 4, border: '1px solid #666' }}
                                            placeholder="Insira seu numero"
                                            value={contato.whatsapp}
                                            onChange={value => {
                                                setIsChange(true);
                                                setContato({ ...contato, whatsapp: value })
                                            }}
                                        />}
                                    />
                                    <RowInput
                                        label="Data Nascimento"
                                        input={<InputStyle onChange={e => {
                                            setContato({ ...contato, dataNascimento: e.target.value });
                                            setIsChange(true);
                                        }}
                                            value={contato.dataNascimento && maskDate(contato.dataNascimento)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />}
                                    />
                                    <RowInput
                                        label="Email"
                                        type="email"
                                        input={<InputStyle onChange={e => {
                                            setContato({ ...contato, email: e.target.value });
                                            setIsChange(true);
                                        }}
                                            value={contato.email}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />}
                                    />
                                    <RowInput
                                        label="CPF"
                                        input={<InputStyle onChange={e => {
                                            setContato({ ...contato, cpf: e.target.value });
                                            setIsChange(true);
                                        }}
                                            value={contato.cpf && maskCPF(contato.cpf)}
                                            variant="outlined"
                                            size="small"
                                            fullWidth
                                        />}
                                    />
                                </Stack>

                                <Stack
                                    sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                                    spacing={1}
                                    direction={"row"}
                                >
                                    <Box sx={{ width: '30%' }}>
                                        <RowInput
                                            label="CEP"

                                            input={<InputStyle onChange={e => {
                                                setEndereco({ ...endereco, cep: e.target.value })
                                                setIsChange(true);
                                            }}
                                                onBlur={e => handleOnBlurSearchCep(e.target.value)}
                                                value={endereco.cep}
                                                variant="outlined"
                                                size="small"
                                                fullWidth
                                            />}
                                        />
                                    </Box>
                                </Stack>

                                <Stack
                                    sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                                    spacing={1}
                                    direction={"row"}
                                >
                                    <Box sx={{ width: '70%' }}>
                                        <RowInput
                                            label="Rua"
                                            input={<InputStyle onChange={e => {
                                                setEndereco({ ...endereco, rua: e.target.value })
                                                setIsChange(true);
                                            }} value={endereco.rua} variant="outlined" size="small" fullWidth />}
                                        />
                                    </Box>
                                    <Box sx={{ width: '25%' }}>
                                        <RowInput
                                            label="Número"
                                            input={<InputStyle onChange={e => {
                                                setEndereco({ ...endereco, numero: e.target.value });
                                                setIsChange(true);
                                            }} value={endereco.numero} variant="outlined" size="small" fullWidth />}
                                        />
                                    </Box>
                                </Stack>


                                <Stack>


                                    <Box sx={{ width: '75%' }}>
                                        <RowInput
                                            label="Bairro"
                                            input={<InputStyle onChange={e => {
                                                setEndereco({ ...endereco, bairro: e.target.value });
                                                setIsChange(true);
                                            }} value={endereco.bairro} variant="outlined" size="small" fullWidth />}
                                        />
                                    </Box>
                                </Stack>


                                <Stack
                                    sx={{ width: { xs: '100%', md: '100%' } }}
                                    spacing={1}
                                    direction={"row"}
                                >
                                    <Box sx={{ width: '40%' }}>
                                        <RowInput
                                            label="Complemento"
                                            input={<InputStyle onChange={e => {
                                                setEndereco({ ...endereco, complemento: e.target.value })
                                                setIsChange(true);
                                            }} value={endereco.complemento} variant="outlined" size="small" fullWidth />}
                                        />
                                    </Box>
                                    <Box sx={{ width: '40%' }}>
                                        <RowInput
                                            label="Cidade"
                                            input={<InputStyle onChange={e => {
                                                setEndereco({ ...endereco, cidade: e.target.value })
                                                setIsChange(true);
                                            }} value={endereco.cidade} variant="outlined" size="small" fullWidth />}
                                        />
                                    </Box>
                                    <Box sx={{ width: '20%' }}>
                                        <RowInput
                                            label="Estado"
                                            input={<InputStyle onChange={e => {
                                                setEndereco({ ...endereco, estado: e.target.value });
                                                setIsChange(true);
                                            }} value={endereco.estado} variant="outlined" size="small" fullWidth />}
                                        />
                                    </Box>
                                </Stack>



                                {isChange && (
                                    <Stack direction="row">
                                        <LoadingButton onClick={handleEditarContato} loading={loadingEditarContato} size="small" variant='contained'>Salvar alterações</LoadingButton>
                                    </Stack>
                                )}


                            </Stack>
                        </Box>
                    )} 


                </Box>*/}


            {/* </Scrollbar> */}

        </Stack>

    );
}
