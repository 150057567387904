import axios from "axios";
import Config from "src/config";

const v1_actionEnviarArquivoWhatsapp = (querys, data) => {

    return axios({
        method: "post",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/canais/enviar-arquivo${querys}`,
        data,
        maxContentLength: Infinity,
        maxBodyLength: Infinity

    });
};

export default v1_actionEnviarArquivoWhatsapp;
