import {
  Stack,
  Container,
  Box,
  LinearProgress
} from '@mui/material';
import { useState, useEffect } from 'react';

// components
import useAuth from 'src/hooks/useAuth';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import { Button } from 'src/@/components/ui/button';
import TableRespostasRapidas from './TableRespostasRapidas';
import { CardContent, Card } from 'src/@/components/ui/card';
import { ModalClonarVoz } from './ModalClonarVoz';
import v2_actionListaVozes from 'src/actions/v2/ai/v2_actionListaVozes';
import v2_actionDeletarVozAi from 'src/actions/v2/ai/v2_actionDeletarVozAi';
import { useSnackbar } from 'notistack';

export default function AiVozesComponent() {
  const { user } = useAuth();
  const [openDialogNovoSetor, setOpenDialogNovoSetor] = useState(false);
  const [listaVozes, setListaVozes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modalAlerta, setModalAlerta] = useState(false);
  const [modalCriarVoz, setModalCriarVoz] = useState(false);
  const {enqueueSnackbar} = useSnackbar()


  const buscarVozesAi = async () => {
    setLoading(true)
    try {
      const res = await v2_actionListaVozes(user.clienteId)
      setListaVozes(res.data.data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('Erro ao buscar Lista de Responstas rápidas', error)
    }
  }

  
  const handleDelete = async (id) => {
    try {
      await v2_actionDeletarVozAi(id);
      enqueueSnackbar('Resposta rápida deletada com sucesso', { variant: 'success' })
      buscarVozesAi();

    } catch (error) {
      console.log('Erro ao deletar resposta rápida', error)
      enqueueSnackbar('Erro ao deletar resposta rápida', { variant: 'error' })
    }

    // await buscarRespostasRapidas()
  };

  useEffect(() => {
    buscarVozesAi()
  }, []);


  return (
    <div>
      <ModalAlerta
        open={modalAlerta}
        onClose={() => setModalAlerta(false)}
        mensagem={'Não é possivel editar resposta rapida do tipo arquivo ou audio, exclua e crie uma nova resposta rápida'}
      />

      <Container maxWidth="md">

        <div className=''>
          <HeaderPageComponentConfig
            titulo="Vozes"
            descricao={'Clone vozes para serem utilizadas nos agentes de IA'}
            actions={<Button
              onClick={() => {
                setModalCriarVoz(true)
              }}
            >
              Clonar Voz
            </Button>}
          />
        </div>

        <ModalClonarVoz
          open={modalCriarVoz}
          onClose={() => setModalCriarVoz(false)}
          user={user}
          onCallback={() => {
            buscarVozesAi()
            setModalCriarVoz(false)
          }}
        />


        <Stack sx={{ mt: 5 }}>
          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : (
            <Stack spacing={2}>
              {/* <Stack direction={'collumn'}>
        <TextField size="small" value={pesquisaRespostaRapida} onChange={e => setPesquisaRespostaRapidas(e.target.value)} placeholder="Pesquisar" />
      </Stack> */}

              <Container maxWidth="xs">
                <Card>
                  <CardContent>
                    <TableRespostasRapidas
                      data={listaVozes}
                      onDelete={row => handleDelete(row._id)}
                    />
                  </CardContent>
                </Card>
              </Container>


            </Stack>
          )}
        </Stack>
      </Container>


    </div>
  );
}
