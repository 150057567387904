import { createSlice } from '@reduxjs/toolkit';

// utils
import actionUpdateConfigFunil from 'src/actions/chats/funil/actionUpdateConfigFunil';
import v2_actionListaChatsFunil from 'src/actions/v2/chats/funil/v2_actionListaChatsFunil';
import v2_actionDetalhesLead from 'src/actions/v2/crm/v2_actionDetalhesLead';
import v2_actionListaColunasCrm from 'src/actions/v2/crm/v2_actionListaColunasCrm';
import v2_actionListaLeads from 'src/actions/v2/crm/v2_actionListaLeads';

// ----------------------------------------------------------------------

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

function objFromArrayId(array, key = '_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

const initialState = {
  isLoading: false,
  error: false,
  colunas:[],
  lead:null,
  leadsKanban:null,
  ordemColunas:[]
};

const slice = createSlice({
  name: 'crm',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    persistCard(state, action) {
      const columns = action.payload;

      state.colunas = columns;
    },
    persistColumn(state, action) {
      state.ordemColunas = action.payload;
    },
    getListaLeadsFunilSuccess(state, action) {
      state.isLoading = false;
      if (Object.keys(objFromArrayId(action.payload)).length === 0) {
        state.leadsKanban = null;
    } else {
      state.leadsKanban = objFromArrayId(action.payload);
    }

      // state.leadsKanban = objFromArrayId(action.payload);
    },
    getDetalhesLeadSuccess(state, action) {
      state.isLoading = false;
      state.lead = action.payload;
    },
    setLeadData(state, action) {
      state.lead = action.payload;
    },
    getListaColunasCrmSuccess(state, action) {
      let listaColunas = action.payload;
      const reduce = listaColunas.map(item => item.id)
    
      state.isLoading = false;
      state.colunas = objFromArray(action.payload);
      state.ordemColunas = reduce;
    }
  }
});

// Reducer
export default slice.reducer;

export const { setLeadData } = slice.actions;

// ----------------------------------------------------------------------

export function getListaLeads(querys) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v2_actionListaLeads(querys);
      dispatch(slice.actions.getListaLeadsFunilSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getListaColunasCrm(querys) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v2_actionListaColunasCrm(querys);
      dispatch(slice.actions.getListaColunasCrmSuccess(response.data.data)); 
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getDetalhesLead(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v2_actionDetalhesLead(id);
      dispatch(slice.actions.getDetalhesLeadSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function persistColumn(newColumnOrder, user) {
  return async (dispatch) => {
    dispatch(slice.actions.persistColumn(newColumnOrder));
    try {
      
      
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
   
  };
}

// ----------------------------------------------------------------------

export function persistCard(columns) {
  return (dispatch) => {
    console.log('columns persistCard 1', columns)
    dispatch(slice.actions.persistCard(columns));
  };
}

