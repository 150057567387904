import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "src/@/components/ui/table"

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import { useRef, useState } from "react";
import Iconify from "src/components/Iconify";
import Label from "src/components/Label";
import { Badge } from "src/@/components/ui/badge";
import { Button } from "src/@/components/ui/button";

export default function TableUsuarios({ data, onEditSenha, onEditUsuario, onDelete }) {

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead >Nome</TableHead>
          <TableHead>Setor</TableHead>
          <TableHead>Ramal</TableHead>
          <TableHead>Email</TableHead>
          <TableHead>Status</TableHead>

          <TableHead className="w-[100px]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => (
          <TableRow
            hover
            key={index}
          >

            <TableCell>{row.nomeFantasia}</TableCell>
            <TableCell>{row.nome_setor}</TableCell>
            <TableCell>{row.ramal}</TableCell>
            <TableCell>{row.email}</TableCell>
            <TableCell>
            <Badge 
            className={`${row.status === false ? "bg-red-500 text-white" : "bg-green-500 text-white"} border-0 rounded-sm`}
            >  {row.status === false ? "Desativado" : "Ativo"}</Badge>
            </TableCell>


            <TableCell>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Button
                variant="outline"
                size="icon">
                <Iconify onClick={() => {
                  const { firstName, lastName, nome_setor, nomeFantasia, _id, status, username, email, ramal, telefone, setor_id } = row;
                  let dataUsuario = {
                    firstName, lastName, nomeFantasia, _id, username, email, telefone, setor_id
                  }
                  onEditSenha(dataUsuario)
                }
                } icon="bi:shield-lock" color="primary" />
                </Button>
                <Button
                variant="outline"
                size="icon">
                <Iconify onClick={() => {
                  const { firstName, lastName, nome_setor, nomeFantasia, _id, status, username, email, ramal, telefone, setor_id } = row;
                  let dataUsuario = {
                    firstName, lastName, nomeFantasia, _id, username, email, telefone, setor_id
                  }
                  onEditUsuario(dataUsuario)
                }
                }  icon="bi:pencil" color="primary" />
                </Button>
              </Stack>

            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
