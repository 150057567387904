
import {
    Box,
    Menu
} from '@mui/material';


export default function MenuGatilhos({
    anchorEl,
    openMenu,
    changeAnchorEl
}) {



    return (
        <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openMenu}
        onClose={changeAnchorEl}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <Box>
          <Box
            sx={{
              m: 1,
              borderRadius: 1,

              p: 1,
              display: 'grid',
              gap: 1,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(1, 1fr)',
                md: 'repeat(3, 1fr)',
                lg: 'repeat(3, 1fr)',
              },
            }}>
       
          </Box>
        </Box>

      </Menu>

    );
}
