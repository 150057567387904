import { Stack, Container } from "@mui/material";
//zui 

import { Card, CardContent } from 'src/@/components/ui/card';
import { RowInput } from 'src/components/forms/RowInput';
import { Textarea } from 'src/@/components/ui/textarea';

export default function AiAgentPromptTab({
    prompt, 
    changePrompt,
    aiAgent
}) {
    return (

        <Container maxWidth="md">
        <Card className="my-7">
            <CardContent>
                <Stack sx={{ my: 1 }} direction={"column"} spacing={1}>
                    <RowInput

                        label="Prompt"
                        input={
                            <Textarea


                                value={aiAgent?.config?.generalConfig?.type === "promptFree" ? aiAgent?.config?.promptFree?.prompt : aiAgent?.config?.promptGuide?.prompt}
                                rows={10}
                                onChange={(e) => changePrompt(e.target.value)}
                            />
                        }
                    />
                    <p className="leading-2 text-xs [&:not(:first-child)]:mt-5">Descreva a função do seu agente</p>
                </Stack>
            </CardContent>
        </Card>
    </Container>

    )

}