import axios from "axios";
import Config from "../../config";

const actionListaInputsPrioridade = clienteId => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chamados/${clienteId}/inputs-prioridade `
    });
};

export default actionListaInputsPrioridade;
