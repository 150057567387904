import { alpha, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
// material

// ----------------------------------------------------------------------

const RootStyle = styled('span')(({ theme, styleProps }) => {
  const isLight = theme.palette.mode === 'light';
  const { color, variant } = styleProps;

  const styleFilled = (color) => ({
    color: theme.palette[color].main,
    backgroundColor: theme.palette[color].main
  });

  const styleOutlined = (color) => ({
    color: theme.palette[color].main,
    backgroundColor: 'transparent',
    border: `1px solid ${theme.palette[color].main}`
  });

  const styleGhost = (color) => ({
    color: theme.palette[color].main,
    backgroundColor: alpha(theme.palette[color].main, 0.16)
  });

  return {
    height: 15,
    minWidth: 22,
    lineHeight: 0,
    borderRadius: 5,
    cursor: 'default',
    alignItems: 'center',
    whiteSpace: 'nowrap',
    display: 'inline-flex',
   // justifyContent: 'center',
    padding: theme.spacing(1.1, 0),
    color: '#666',
    fontSize: theme.typography.pxToRem(12),
   // backgroundColor: theme.palette.grey[300],
    fontWeight: '400',
    textAlign:'left',

    // ...(color !== 'default'
    //   ? {
    //       ...(variant === 'filled' && { ...styleFilled(color) }),
    //       ...(variant === 'outlined' && { ...styleOutlined(color) }),
    //       ...(variant === 'ghost' && { ...styleGhost(color) })
    //     }
    //   : {
    //       ...(variant === 'outlined' && {
    //         //backgroundColor: 'transparent',
    //         color: theme.palette.grey[300],
    //         border: `1px solid ${theme.palette.grey[800]}`
    //       }),
    //       ...(variant === 'ghost' && {
    //         color: isLight ? theme.palette.grey[800] : theme.palette.common.white,
    //        // backgroundColor: theme.palette.grey[500_16]
    //       })
    //     })
  };
});

// ----------------------------------------------------------------------

export default function LabelInput({ color = 'default', variant = 'ghost', children, ...other }) {
  return (
    <RootStyle styleProps={{ color, variant }} {...other}>
      {children}
    </RootStyle>
  );
}

LabelInput.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf(['default', 'primary', 'secondary', 'info', 'Connected', 'Disconnected', 'success', 'warning', 'error']),
  variant: PropTypes.oneOf(['filled', 'outlined', 'ghost'])
};
