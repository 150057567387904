import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
// @mui
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
// redux
import { useDispatch } from 'src/redux/store';
import ChamadosCard from './ChamadosCard';
import useResponsive from 'src/hooks/useResponsive';
import { Droppable, Draggable } from 'react-beautiful-dnd';
// ----------------------------------------------------------------------

KanbanColumn.propTypes = {
  column: PropTypes.object,
  index: PropTypes.number,
};

export default function KanbanColumn({ listaTags, column, index }) {
  const dispatch = useDispatch();
  const { tickets } = useSelector((state) => state.chamados);
  let stage1 = column ? column.ticketsId : [];
  const ticketsId = stage1;
  const id = column ? column.id : null;
  const isDesktop = useResponsive('up', 'lg');



  return (

      <Box
        sx={{

          px: 2,
          py: 2,
          borderRadius:2,
          background: theme => theme.palette.grey[200],
        }}
      >
        <Stack spacing={1} >
          <Stack>
            <Typography sx={{ textTransform: 'Capitalize', color: '#333' }} variant="h6">{id} {id ? '' : ''}</Typography>

          </Stack>



          {ticketsId.length > 0 && (
            ticketsId.map((cardId, index) => (
              <ChamadosCard
                listaTags={listaTags}
                key={cardId}
                onDeleteTask={() => { }}
                card={tickets[cardId]}
                index={index}
              />
            ))
          )}



        </Stack>
      </Box>

  );
}
