import {
  Container
} from '@mui/material';
import { useEffect, useState } from 'react';
import { CardContent, Card } from 'src/@/components/ui/card';
import { Button } from 'src/@/components/ui/button';
// redux
import Page from 'src/components/Page';
// components
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
///
import ModalConfimacao from 'src/components/modal/ModalConfimacao';
import ModalSetores from './ModalSetores';
import TableSetores from './TableSetores';

//actions
import v2_actionDeletarSetor from 'src/actions/v2/cliente/setores/v2_actionDeletarSetor';
import v2_actionEditarSetor from 'src/actions/v2/cliente/setores/v2_actionEditarSetor';
import { useSnackbar } from 'notistack';
import v1_actionListaSetores from 'src/actions/v1/clientes/v1_actionListaSetores';
import v2_actionCriarSetor from 'src/actions/v2/cliente/setores/v2_actionCriarSetor';

// ----------------------------------------------------------------------
const TABLE_HEAD = [
  { id: 'nome', label: 'Nome', alignRight: false },
  { id: 'data', label: 'Data Criação', alignRight: false },
  { id: '' },
];
// ----------------------------------------------------------------------

export default function SetoresComponent() {
  const { user } = useAuth();
  const [openDialogNovoSetor, setOpenDialogNovoSetor] = useState(false);
  const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
  const [pesquisa, setPesquisa] = useState('');
  const [loading, setLoading] = useState(false);
  const [action, setAction] = useState('');
  ///// value fields
  const [setorId, setSetorId] = useState('');
  const [nome, setNome] = useState('');
  const [descricao, setDescricao] = useState('');
  const [listaSetores, setListaSetores] = useState([]);
  const { enqueueSnackbar } = useSnackbar();


  async function buscarListaSetores() {
    try {
      const response = await v1_actionListaSetores(user.clienteId);
      if (response.status === 200) {
        setListaSetores(response.data.data);
      }
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    buscarListaSetores();
  }, []);

  function applyFilter(tableData, filterName) {
    if (filterName) {
      tableData = tableData.filter(
        (item) =>
          item.nome_setor.toLowerCase().indexOf(filterName.toLowerCase()) !== -1
      );
    }
    return tableData;
  }


  async function handleCriarSetor() {
    try {
      setLoading(true)
      const data = {
        scope: {
          name: nome,
          description: descricao
        }
      }
      await v2_actionCriarSetor(user.clienteId, data);
      await buscarListaSetores();
      setLoading(false)
      setOpenDialogNovoSetor(false);
      setNome('');
      setDescricao('');
      setSetorId('');
    } catch (error) {
      setLoading(false)
    }
  }

  const handleEditarSetor = async () => {
    let data = {
      scope: {
        name: nome,
        description: descricao
      }
    }

    await v2_actionEditarSetor(setorId, data);
    await buscarListaSetores();
    setAction('');
    setNome('');
    setDescricao('');
    setSetorId('');
    setOpenDialogNovoSetor(false);
  };

  const handleDeletarSetor = async () => {

    let data = {
      checkUsers: true
    }
    const response = await v2_actionDeletarSetor(setorId, data);
    if (response.status === 200) {
      if (response.data.error) {
        enqueueSnackbar(response.data.message, { variant: 'error' });
      } else {
        setSetorId('');
        setOpenModalConfirmacao(false);
        enqueueSnackbar("Setor deletado com sucesso", { variant: 'success' });
        await buscarListaSetores();
      }
    }
  };

  const dataFiltered = applyFilter(listaSetores, pesquisa);

  return (


      <Container fullWidth maxWidth={"lg"}  >
        <HeaderPageComponentConfig
          titulo="Setores"
          descricao={'Aqui você pode criar, editar e excluir setores.'}
          actions={<Button
            onClick={() => {
              setOpenDialogNovoSetor(true);
              setAction('Criar')
            }}
          >
            Criar Setor
          </Button>}
        />

        <ModalConfimacao
          open={openModalConfirmacao}
          onClose={() => {
            setOpenModalConfirmacao(false);
          }}
          onConfirm={handleDeletarSetor}
          loadingFinalizar={loading}
          mensagem={'Deseja mesmo excluir esse setor?'}
        />

        <ModalSetores
          open={openDialogNovoSetor}
          action={action}
          descricao={descricao}
          changeDescricao={(e) => {
            setDescricao(e.target.value)
          }}
          nome={nome}
          changeNome={(e) => {
            setNome(e.target.value)
          }}
          onSubmit={action === "Criar" ? handleCriarSetor : handleEditarSetor}
          onClose={() => {
            setOpenDialogNovoSetor(false);
            setNome('');
            setDescricao('');
            setSetorId('');
          }}
        />

        <Card className="mt-8">
          <CardContent>
            <TableSetores
              data={dataFiltered}
              onDelete={row => {
                setSetorId(row._id);
                setOpenModalConfirmacao(true);
              }}
              onEdit={row => {
                console.log('row', row)
                setOpenDialogNovoSetor(true)
                setAction('Editar')
                setSetorId(row._id);
                setNome(row.nome_setor);
                setDescricao(row.description);  
              }}
            />
          </CardContent>
        </Card>
      </Container>

  );
}
