import { filter } from 'lodash';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import {
    Table,
    Stack,
    Button,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogContent,
    Divider,
    Grid,
    TextField,
    DialogActions,
    DialogContentText,
    Container,
    ListItem,
    Chip,
    Paper,
    Autocomplete,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio
} from '@mui/material';

// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getListaUsuariosByClienteId } from 'src/redux/slices/user';

// hooks
import useSettings from 'src/hooks/useSettings';
// components
import { UserListHead } from 'src/components/_dashboard/user/list';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import AgentesMoreMenu from 'src/components/_chats/agentes/AgentesMoreMenu';
import actionEditarSenha from 'src/actions/usuarios/actionEditarSenhaUsuario';
import actionEditarPerfil from 'src/actions/usuarios/actionEditarPerfil';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/material';
import { TableBorder } from 'src/components/table';
import { getTransformEventsWebhook, soNumeros } from 'src/utils/functions';
import Iconify from 'src/components/Iconify';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import actionListaWebhooks from 'src/actions/clientes/actionListaWebhooks';
import { fDate, fDateTime } from 'src/utils/formatTime';
import WebhooksMoreMenu from 'src/components/_dashboard/configuracoes/WebhooksMoreMenu';
import actionCriarWebhook from 'src/actions/clientes/actionCriarWebhook';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'nome', label: 'Nome', alignRight: false },
    { id: 'setor', label: 'Data Criação', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'Email', label: 'Método', alignRight: false },
    { id: 'Acoes', label: 'Ações', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.nomeFantasia.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function MainWebhooks() {
    const theme = useTheme();

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [openWebhook, setOpenWebook] = useState(false);
    const [nome, setNome] = useState('');
    const [url, setUrl] = useState('');
    const [eventos, setEventos] = useState([]);
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [usuarioId, setUsuarioId] = useState('');
    const { logout, user } = useAuth();

    // lista webhooks

    const [webhooks, setWebhooks] = useState([])

    async function buscarWebhooks() {
        try {
            const response = await actionListaWebhooks(user.clienteId);
            setWebhooks(response.data.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const events = [
        "whatsapp.payload_message",
        "whatsapp.pesquisa_concluida",
        "whatsapp.after_resposta_pesquisa"
    ]



    useEffect(() => {
        buscarWebhooks()
    }, []);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = webhooks.map((n) => n.nome);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };


    const handleOpenDialogEditarSenha = (_id) => {
        setUsuarioId(_id)

    }


    const resetarCampos = () => {
        setUsuarioId('');
        setSenha('');
        setConfirmarSenha('');
    }


    async function onCriarWebhook() {
        try {

            let data = {
                nome,
                url,
                method:'POST',
                events:eventos,
                clienteId:user.clienteId
            }
            await actionCriarWebhook(data);
            setOpenWebook(false);
            setNome('');
            setUrl('');
            setEventos([]);
            buscarWebhooks()
        } catch (error) {
       console.log('error', error)
        }
    }




    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - webhooks.length) : 0;

    const filteredUsers = applySortFilter(webhooks, getComparator(order, orderBy), filterName);

    const isUserNotFound = filteredUsers.length === 0;

    return (
        <Box>

          


            <Container sx={{ mt: 4 }} maxWidth="lg">
            <HeaderPageComponentConfig
                backAction={() => navigate('/dashboard/configuracoes/integracoes')}
                titulo="Webhooks"
                descricao={'O recurso Usuários contém todos os agentes que sua empresa está envolvida. É possível editar contatos e senhas de acesso.'}
                actions={<Button onClick={() => setOpenWebook(true)} variant='contained'>Novo Webhook</Button>}
            />


                <Dialog open={openWebhook} maxWidth={'sm'} fullWidth={true} onClose={() => setOpenWebook(false)}>
                    <DialogTitle>Webhook</DialogTitle>
                    <DialogContent>
                        <Divider sx={{ mt: 2, mb: 2 }} />

                        <Stack direction={'column'} spacing={2}>
                            <TextField size="small" autoFocus onChange={e => setNome(e.target.value)} type="text" value={nome} margin="dense" variant="outlined" label="Nome" />


                            <Stack spacing={2} direction="row">
                                <TextField size="small" autoFocus onChange={e => setUrl(e.target.value)} type="text" value={url} margin="dense" variant="outlined" label="URL" />
                                <FormControl row>
                            
                                    <RadioGroup
                                    size="small"
                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                        name="controlled-radio-buttons-group"
                                        value={'POST'}

                                    >
                                        <FormControlLabel  value="POST" control={<Radio />} label="POST" />

                                    </RadioGroup>
                                </FormControl>
                            </Stack>

                            <Autocomplete
                                multiple
                                size="small"
                                value={eventos}
                                onChange={(event, newValue) =>  setEventos(newValue) }
                                options={events.map((option) => getTransformEventsWebhook(option))}
                                renderInput={(params) => (
                                    <TextField {...params} placeholder="Eventos" />
                                )}
                            />
                        </Stack>

                    </DialogContent>
                    <Divider style={{ marginTop: 10 }} />
                    <DialogActions>
                        <Button onClick={() => setOpenWebook(false)} color="inherit">
                            Cancelar
                        </Button>
                        <Button onClick={onCriarWebhook} variant="contained">
                            Salvar
                        </Button>
                    </DialogActions>
                </Dialog>



                <TableBorder>
                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 300 }}>
                            <Table size="small" aria-label="a dense table">
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={webhooks.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}

                                />
                                <TableBody dense>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { nome, data_criacao, events, _id, status, method } = row;

                                        const isItemSelected = selected.indexOf(nome) !== -1;


                                        return (
                                            <TableRow
                                                hover
                                                // key={id}
                                                tabIndex={-1}
                                                sx={{ height: 50 }}

                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >



                                                <TableCell component="th" scope="row" padding="none">
                                                    {nome}
                                                </TableCell>
                                                <TableCell align="left">
                                                    {fDateTime(data_criacao)}
                                                </TableCell>


                                                <TableCell align="left">
                                                    <Label
                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                        color={(status === false && 'error') || 'success'}
                                                    >
                                                        {status === false ? "Desativado" : "Ativo"}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="left">  <Typography variant="caption" noWrap>  {method}</Typography></TableCell>

                                                <TableCell align="left">
                                                    <WebhooksMoreMenu
                                                        onEdit={() => {
                                                            handleOpenDialogEditarSenha(_id)
                                                        }}

                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                {/* <SearchNotFound searchQuery={filterName} /> */}
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination

                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={webhooks.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBorder>
            </Container>

        </Box>
    );
}
