
import { Tab, Box, Tabs, Stack, Divider, Dialog, Container } from '@mui/material';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import CanaisConfig from './CanaisConfig';
import v2_actionListUsersByClient from 'src/actions/v2/usuarios/v2_actionListUsersByClient';
import v2_actionListSectorsByClient from 'src/actions/v2/cliente/v2_actionListSectorsByClient';
import useAuth from 'src/hooks/useAuth';
import v2_actionDetalhesCanal from 'src/actions/v2/cliente/canais/v2_actionDetalhesCanal';
import v2_actionEditarCanal from 'src/actions/v2/cliente/canais/v2_actionEditarCanal';
import { useSnackbar } from 'notistack';
import { LoadingButton } from '@mui/lab';
import DialogWhatsappConexao from './DialogWhatsappConexao';
import { Button } from 'src/@/components/ui/button';


export default function ConectarWhatsapp() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const { user } = useAuth();
    const [channel, setChannel] = useState(null);


    const location = useLocation();
    // Extrai o parâmetro de consulta "channel" da string de busca (search) da URL
    const params = new URLSearchParams(location.search);
    const channelId = params.get('channelId');

    async function getChannelDetails() {
        try {
            const response = await v2_actionDetalhesCanal(channelId);
            setChannel(response.data.data);
        } catch (error) {
            console.error(error);
        }
    }


    useEffect(() => {
        getChannelDetails()
    }, []);


    return (
        <Container maxWidth="lg">
                <Stack spacing={1.5}>
                    <Box sx={{ width: '100%' }}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack spacing={1} alignItems={"center"} direction={"row"}>

                                <Button
                                    size='icon'
                                    variant='outline'
                                    onClick={
                                        () => {
                                            navigate('/dashboard/configuracoes/canais')
                                        }}
                                >
                                    <Iconify icon="majesticons:arrow-left" color="black" />
                                </Button>

                                <Typography variant="body2" color="text.primary" sx={{ fontWeight: 600, textTransform: 'lowercase' }}>
                                    voltar para os canais
                                </Typography>


                            </Stack>
                        </Stack>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', height: '60vh', mt:10 }}>
                        <DialogWhatsappConexao channel={channel} />

                    </Box>
                </Stack>

        </Container>
    );
}
