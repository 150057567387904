import axios from "axios";

import Config from "../../config";

const actionEditarCampanha = (campanhaId, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/campanhas/${campanhaId}/editar`,
        data
    });
};

export default actionEditarCampanha;
