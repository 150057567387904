import PropTypes from 'prop-types';
import { useEffect, useRef } from 'react';

//
import Scrollbar from '../../../Scrollbar';
import '../Messages.css';
///// ANTIGO

// substituir  >>>>>>    const selectedMessage = useSelector(state => state.io.selectedMessage); 
import useResponsive from 'src/hooks/useResponsive';
//////// revisado ///////////////////
import { useDropzone } from 'react-dropzone';
// icons

// services
import { getSession, getToken } from "../../../../services/auth";
// component
import ChatComponent from "./ChatComponent";

// style

//redux
import { useDispatch } from '../../../../redux/store';
import { setSelectedMessage } from '../../../../redux/slices/chat';
import ChatMessageItemMeta from 'src/sections/@chats/chatInstagram/ChatMessageItem';

ChatMessageListMeta.propTypes = {
  conversa: PropTypes.object.isRequired
};

export default function ChatMessageListMeta({ conversa, canal }) {
  const scrollRef = useRef();
  const chatRef = useRef(null);
  const messagesEnd = useRef(null);
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'md');

  //slices
  const scrollMessagesToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };


  useEffect(() => {
    scrollMessagesToBottom();
  }, [conversa.length]);


  const { getInputProps } = useDropzone({
    accept: ['image/*'],
    acceptStyle: { backgroundColor: "#0aafd9" },
    rejectStyle: { backgroundColor: "#0aafd9" },
    // onDrop: acceptedFiles => {
    //   setFiles(
    //     acceptedFiles.map(file =>
    //       Object.assign(file, {
    //         preview: URL.createObjectURL(file)
    //       })
    //     )
    //   );
    // }
  });

  return (
    <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, flexGrow: 1 }}>
      <input  {...getInputProps()} />
  
      <ul ref={chatRef}>
      {conversa.map((message) => (
        <ChatMessageItemMeta
          key={message._id}
          message={message}
          conversation={conversa}
        // onOpenLightbox={handleOpenLightbox}
        />
      ))}
        <div ref={messagesEnd} />
      </ul>
    </Scrollbar>

  );
}
