import { styled } from '@mui/material/styles';
import { Stack, Box, Avatar, ListItemText, ListItemAvatar, ListItemButton, IconButton, Menu, MenuItem, Typography, Badge, Grid, Tooltip } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
// material
import { useState } from "react";
import { RiWhatsappFill, RiInstagramLine } from "react-icons/ri";

//utils
import { formataNumeroCelular } from "src/utils/functions";
import Label from 'src/components/Label';
import Iconify from 'src/components/Iconify';
import { TagItem } from '../TagItem';
const AVATAR_SIZE = 48;
const defaultImage = 'https://zindi.s3.us-east-2.amazonaws.com/static_zindi/not-picture.png'


const RootStyle = styled('div')(({ theme }) => ({
  transition: theme.transitions.create('all'),
  cursor: 'default',
  borderBottom: '1px solid #e0e0e0',
  paddingTop: 4,
  paddingBottom: 4,
  paddingRight: 4,
}));

const AvatarWrapperStyle = styled('div')({
  position: 'relative',
  width: AVATAR_SIZE,
  height: AVATAR_SIZE,
  '& .MuiAvatar-img': {},
  '& .MuiAvatar-root': { width: '100%', height: '100%' }
});


// ----------------------------------------------------------------------


export default function ChatPesquisaItem({
  chat,
  user,
  onSelectConversation,
  actionMarcarNaoLido,
  isOpenSidebar,
  key,
  disabled,
  onAceitarChat,
  ...other
}) {

  const isSuperVisor = user?.level === "root";

  function validaCanal() {
    if (chat.canal === "instagram") {
      return <RiInstagramLine size={15} color="#ff1803" />
    } else if (chat.canal === "whatsapp") {
      return <RiWhatsappFill size={15} color="#00a884" />
    } else {
      return <RiWhatsappFill size={15} color="#00a884" />
    }
  }

  return (
    <RootStyle  {...other} >

      {isOpenSidebar ? (

        <Stack direction={"column"} key={key} >

          <Stack spacing={2} direction={'row'} sx={{ mb: 1 }}>
            <Stack direction={'column'} spacing={1} sx={{ width: '75%' }}>
              <ListItemButton
                onClick={onSelectConversation}
                disablePadding
                disabled={disabled}
              >
                <Stack spacing={2} direction={'row'}>
                  <Box sx={{ width: '25%' }} >
                    <ListItemAvatar>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        badgeContent={
                          <Box style={{ background: '#fff', width: 23, height: 23, alignItems: 'center', justifyContent: 'center', flex: 1, padding: 5, borderRadius: 10 }}>
                            {validaCanal()}
                          </Box>
                        }>
                        <AvatarWrapperStyle className="avatarWrapper" key={chat._id}>
                          <Avatar variant="rounded" alt={chat.nomeCliente} src={chat.fotoPerfil} />
                        </AvatarWrapperStyle>
                      </Badge>
                    </ListItemAvatar>
                  </Box>
                  <Box sx={{ width: '80%' }}>
                    <Stack>
                      <Typography sx={{ fontWeight: chat.novaMensagem === 'sim' ? '600' : '500', fontSize: 12, color: '#333' }}>{chat.nomeCliente ? chat.nomeCliente : ""}</Typography>
                      <Typography sx={{ fontWeight: chat.novaMensagem === 'sim' ? '600' : '500', fontSize: 12 }}>{chat?.numeroCliente?.startsWith("55") ? formataNumeroCelular(chat.numeroCliente) : `+${chat.numeroCliente}`}</Typography>
                      <Stack direction={'row'} spacing={0.5} alignItems={'center'} sx={{ mt: 1 }}>


                        <Stack spacing={1} direction={"column"}>
                          {chat.usuario_atendendo && (
                            <Stack direction="row">
                              <Iconify icon={'fontisto:person'} width={10} height={10} color={'#00a884'} />
                              <Typography sx={{ fontSize: 9 }}>{chat.usuario_atendendo}</Typography>
                            </Stack>
                          )}



                        </Stack>
                      </Stack>

                    </Stack>
                  </Box>
                </Stack>
              </ListItemButton>

            </Stack>

            <Box sx={{
              width: '25%',

            }}>
              <Stack direction={'column'} spacing={1}>
                <Label color="success" >
                  <Stack direction={'row'} spacing={1}>
                    <Iconify icon={'fluent:channel-share-28-filled'} width={10} height={10} color={'#00a884'} />
                    <Typography sx={{ textTransform: 'uppercase', fontSize: 8 }}> {chat.nome_canal}</Typography>
                  </Stack>
                </Label>

                <Stack spacing={0.5} direction="column">
                  <Stack alignItems={"center"} spacing={2} direction="row">
                    <Stack direction={'row'} spacing={0.5} alignItems={'center'} justifyContent={'end'}>

                      {chat.statusRoom === 'Avaliando' && (
                        <Tooltip title="Chat em avaliação">
                          <Box sx={{ mt: 1 }}>
                            <Iconify color="primary.main" icon={'ph:star-duotone'} width={18} height={18} />
                          </Box>
                        </Tooltip>
                      )}

                      {chat.statusRoom === 'Finalizado' && (
                        <Tooltip title="Pegar Chat">
                          <IconButton onClick={onAceitarChat} size={'small'}>
                            <Iconify icon={'fa-regular:hand-rock'} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {chat.statusRoom === 'Geral' && (
                        <Tooltip title="Pegar Chat">
                          <IconButton onClick={onAceitarChat} size={'small'}>
                            <Iconify icon={'fa-regular:hand-rock'} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {chat.statusRoom === 'Avaliando' && (
                        <Tooltip title="Pegar Chat">
                          <IconButton onClick={onAceitarChat} size={'small'}>
                            <Iconify icon={'fa-regular:hand-rock'} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {chat.statusRoom === 'Atendendo' && isSuperVisor && (
                        <Tooltip title="Pegar Chat">
                          <IconButton onClick={onAceitarChat} size={'small'}>
                            <Iconify icon={'fa-regular:hand-rock'} />
                          </IconButton>
                        </Tooltip>
                      )}
                      {chat.statusRoom === 'Pendente' && (
                        <Stack direction="row" spacing={1}>
                          {user.level === 'root' && (
                            <Tooltip title="Pegar Chat">
                              <IconButton onClick={onAceitarChat} size={'small'}>
                                <Iconify icon={'fa-regular:hand-rock'} />
                              </IconButton>
                            </Tooltip>
                          )}

                          {user.level !== 'root' && chat?.direciona_usuarios?.includes(user.id) && (
                            <Tooltip title="Pegar Chat">
                              <IconButton onClick={onAceitarChat} size={'small'}>
                                <Iconify icon={'fa-regular:hand-rock'} />
                              </IconButton>
                            </Tooltip>
                          )}





                          <Iconify icon={'fa-regular:hourglass-half'} size={15} />
                        </Stack>
                      )}

                    </Stack>
                    {chat.dataUltimaMensagem && <Typography style={{ fontSize: 8, textAlign: 'right' }}>{formatDistanceToNowStrict(new Date(chat.dataUltimaMensagem), { addSuffix: false, locale: ptBR })}</Typography>}

                  </Stack>
                  <TagItem nomeTag={chat?.statusRoom} />
                </Stack>

              </Stack>

            </Box>

          </Stack>

          <Stack direction="row" spacing={2}>
            {chat.tags_ids?.length > 0 && (
              <Box
                sx={{
                  display: 'grid',
                  gap: 0.5,

                  pb: 1,
                  gridTemplateColumns: {
                    xs: 'repeat(2, 1fr)',
                    sm: 'repeat(2, 1fr)',
                    md: 'repeat(4, 1fr)',
                    lg: 'repeat(4, 1fr)',
                  },
                }}>
                {chat.tags_ids.map((tag) => (
                  <TagItem
                    //cor={tag.cor_hex}
                    nomeTag={tag.nome}
                  />

                ))}
              </Box>
            )}

          </Stack>

        </Stack>

      ) : (
        <ListItemAvatar>
          <AvatarWrapperStyle className="avatarWrapper" key={chat._id}>
            <Avatar alt={chat.nomeCliente} src={chat.fotoPerfil ? chat.fotoPerfil : defaultImage} />
          </AvatarWrapperStyle>
        </ListItemAvatar>
      )}
    </RootStyle>
  );
}
