import {
  Cloud,
  CreditCard,
  Github,
  Keyboard,
  LifeBuoy,
  LogOut,
  Mail,
  MessageSquare,
  Plus,
  PlusCircle,
  Settings,
  User,
  UserPlus,
  Users,
  MessageCircleDashed,
  MessageSquareDot
} from "lucide-react"
import { useNavigate } from "react-router";

import { Button } from "src/@/components/ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import Iconify from "src/components/Iconify";
import useAuth from "src/hooks/useAuth";
import { validaMenu, verifyRules } from "src/utils/functions";




export function DropdownMenuDemo2() {

  const navigate = useNavigate();
  const { user } = useAuth();

  const isAdmin = user?.level === "root";
  const menuItems = [
    {
      title: "Conversas",
      icon: <Iconify icon="iconoir:chat-bubble" className="mr-2 h-4 w-4" />,
      rule: "chats",
      action: () => navigate('/chats'),
      isAdmin: false
    },

    {
      title: "Agenda de contatos",  
      icon: <Iconify icon="ri:contacts-book-3-line" className="mr-2 h-4 w-4" />,
      rule: null,
      action: () => navigate('/dashboard/contatos'),
    },

    {
      title: "Funil",
      icon: <Iconify icon="ph:funnel" className="mr-2 h-4 w-4" />,
      rule: "funil_vendas",
      action: () => navigate('/dashboard/negocios'),
      isAdmin: false
    },

    {
      title: "Chat Interno",
      icon: <Iconify icon="mingcute:group-line" className="mr-2 h-4 w-4" />,
      rule: "chat_interno",
      action: () => navigate('/chats/staff'),
      isAdmin: false
    },

    {
      title: "Configurações",
      icon: <Iconify icon="ion:settings-outline" className="mr-2 h-4 w-4" />,
      rule: "configuracoes",
      action: () => navigate('/dashboard/configuracoes/canais'),
      isAdmin: true
    },


    {
      title: "Chamados",
      icon: <Iconify icon="fluent:clipboard-task-16-regular" className="mr-2 h-4 w-4" />,
      rule: "chamados",
      action: () => navigate('/dashboard/chamados')
    },
    {
      title: "Vendas",
      icon: <Iconify icon="solar:ticket-sale-broken" className="mr-2 h-4 w-4" />,
      rule: "vendas",
      action: () => navigate('/dashboard/vendas')
    },

  ];



  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="text-gray-900" variant='ghost'>
          <Iconify color="black" icon="tdesign:menu-application" className="mr-2 h-4 w-4" />
          Menu
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56">

        <DropdownMenuGroup>
          {menuItems
            .filter(item => {
              return (item.rule === null || verifyRules(user.rules, item.rule))
              //   && (!item.isAdmin || user.isAdmin)
            }

            )
            .map((item) => (
              <DropdownMenuItem key={item.title} onClick={item.action}>
                {item.icon}
                <span>{item.title}</span>
              </DropdownMenuItem>
            ))
          }

          <DropdownMenuSub>
            {validaMenu(user ? user.rules : [''], ['agendamentos', 'campanhas']) && (
              <DropdownMenuSubTrigger>
                <Iconify icon="nimbus:marketing" className="mr-2 h-4 w-4" />
                <span>Marketing</span>
              </DropdownMenuSubTrigger>
            )}
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {verifyRules(user.rules, 'agendamentos') && (
                  <DropdownMenuItem onClick={() => navigate('/chats/agendamentos')}>
                    <span>Agendamentos</span>
                  </DropdownMenuItem>
                )}
                {verifyRules(user.rules, 'campanhas') && (
                  <DropdownMenuItem onClick={() => navigate('/dashboard/campanhas')}>
                    <span>Campanhas</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>



          <DropdownMenuSub>
            <DropdownMenuSubTrigger>
              <Iconify icon="iconoir:tools" className="mr-2 h-4 w-4" />
              <span>Ferramentas</span>
            </DropdownMenuSubTrigger>
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                <DropdownMenuItem onClick={() => window.open('https://internets.com.br/whatsapp_link/', '_blank')}>
                  <span>Gerador de link para Whatsapp</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => window.open('https://internets.com.br/whatsapp_botao/', '_blank')}>
                  <span>Gerador de botão para Site</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => window.open('https://internets.com.br/codigo-qr-do-whatsapp/', '_blank')}>
                  <span>Gerador de QrCode</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => window.open('http://internets.com.br/utm/', '_blank')}>
                  <span>Gerador de UTM</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => window.open('http://internets.com.br/verificarnumero/', '_blank')}>
                  <span>Verificador de número válido</span>
                </DropdownMenuItem>

              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub>

        </DropdownMenuGroup>
        <DropdownMenuSeparator />

        {
          isAdmin && (
            <>
              <DropdownMenuLabel>Admin</DropdownMenuLabel>

              <DropdownMenuGroup>
                <DropdownMenuItem onClick={() => navigate('/dashboard/home')}>
                  <Iconify icon="clarity:dashboard-line" className="mr-2 h-4 w-4" />
                  <span>Métricas</span>
                </DropdownMenuItem>
                <DropdownMenuItem onClick={() => navigate('/dashboard/supervisao/lista')}>
                  <Users className="mr-2 h-4 w-4" />
                  <span>Supervisionar</span>
                </DropdownMenuItem>

                <DropdownMenuSub>

                  {validaMenu(user ? user.rules : [''], ['relatorios', 'relatorio', 'relatorio_funil']) && (
                    <DropdownMenuSubTrigger>
                      <Iconify icon="mdi:report-box-outline" className="mr-2 h-4 w-4" />
                      <span>Relatórios</span>
                    </DropdownMenuSubTrigger>
                  )}



                  <DropdownMenuPortal>
                    {verifyRules(user.rules, 'relatorios') && (
                      <DropdownMenuSubContent>
                        <DropdownMenuItem onClick={() => navigate('/dashboard/relatorios/chats-avaliacoes')}>
                          <span>Lista de avaliações</span>
                        </DropdownMenuItem>
                      </DropdownMenuSubContent>
                    )}
                  </DropdownMenuPortal>
                </DropdownMenuSub>

              </DropdownMenuGroup>


              {/* <DropdownMenuSub>

            {validaMenu(user ? user.rules : [''], ['agendamentos', 'campanhas']) && (
              <DropdownMenuSubTrigger>
                <Iconify icon="mingcute:ai-line" className="mr-2 h-4 w-4" />
                <span>IA</span>
              </DropdownMenuSubTrigger>
            )}
            <DropdownMenuPortal>
              <DropdownMenuSubContent>
                {verifyRules(user.rules, 'campanhas') && (
                  <DropdownMenuItem onClick={() => navigate('/dashboard/ai/analyze')}>
                    <span>Análise sentimento</span>
                  </DropdownMenuItem>
                )}
              </DropdownMenuSubContent>
            </DropdownMenuPortal>
          </DropdownMenuSub> */}
            </>
          )
        }


      </DropdownMenuContent>
    </DropdownMenu>
  )
}
