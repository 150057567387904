import { useState, useEffect } from 'react'
import {
    Stack,
    Button,
    Container,
    Drawer,
    Divider,
    Typography,
    CircularProgress,
    Box,
    IconButton,
    FormGroup,
    FormControl,
    Checkbox,
    FormControlLabel,
    FormLabel,
    LinearProgress,
    Tooltip
} from '@mui/material';
import Iconify from 'src/components/Iconify';
import DrawerChatgptInput from './DrawerChatgptInput';
import MenuGatilhos from './MenuGatilhos';
import Scrollbar from 'src/components/Scrollbar';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------


export default function DrawerChatGpt({
    open,
    onClose,
    choice,
    textInputDrawer,
    isShowContextOptions,
    conversacao,
    changeText,
    onStartConversationGpt,
    onEnviarGpt,
    loadingGpt,
    onClear
}) {

    const [checked, setChecked] = useState(true);
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenu = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();

    const [state, setState] = useState({
        copied: false
    });

    const handleChange = (event) => {
        setState({ value: event.target.value, copied: false });
    };

    const onCopy = () => {
        setState({ ...state, copied: true });
        if (state.value) {
            enqueueSnackbar('Copied', { variant: 'success' });
        }
    };

    const handleChangeContext = (event) => {
        setChecked(event.target.checked);
    };



    const ItemGerandoResposta = () => {
        return (
            <Stack direction={'column'} sx={{ mt: 3 }} justifyContent={'center'} spacing={3}>
                <Stack spacing={1} alignItems={'center'} direction={'row'}>
                    <Box sx={{ width: 20 }}><LinearProgress /></Box>
                    <Typography color="#e0e0e0" variant='caption'>Criando sugestão de resposta..</Typography>
                    <Stack direction={'row'} spacing={2}>
                        <IconButton color='error' onClick={onClose}>
                            <Iconify width={14} icon="line-md:cancel" />
                        </IconButton>
                    </Stack>
                </Stack>
                <Divider />
            </Stack>
        )
    }

    const ItemRespostaGerada = ({
        resposta,
        choice,
        onClick
    }) => {
        return (

            <Box sx={{ mt: 1 }} >
                <Box sx={{
                    p: 1.5,
                    mt: 0.25,
                    mb: 1,
                    background: resposta.fromGpt ? '#3f3f4b' : '',
                    borderRadius: 1,
                }}>
                    <Typography sx={{ fontSize: 12, color: '#ccc' }}>{resposta?.body}</Typography>
                </Box>
                {resposta?.body && (
                    <CopyToClipboard text={resposta?.body} onCopy={onCopy}>
                        <Tooltip title="Copiar">
                            <IconButton>
                                <Iconify color="#fff" icon="eva:copy-fill" />
                            </IconButton>
                        </Tooltip>
                    </CopyToClipboard>
                )}

                {/* <Stack spacing={2} direction="row">
                    <Button onClick={choice} color="primary" variant="outlined" >
                        <Iconify width={25} icon="tabler:hand-grab" />
                    </Button>
                </Stack> */}
            </Box>
        )
    }

    return (
        <Drawer hideBackdrop={true} open={open} onClose={onClose} anchor="right" >

            <MenuGatilhos
                open={openMenu}
                anchorEl={null}
                changeAnchorEl={() => setAnchorEl(null)}
            />

            <Box 
            className="bg-slate-800"
            >
                <Container >
                    <Box sx={{ minWidth: 600, maxWidth: 600, mt: 4, height: '100vh' }}>

                        <Stack sx={{ height: '95vh' }} direction={'column'} justifyContent={'space-between'} alignContent={'space-between'}>
                            <Stack direction={'column'}>

                                <Stack direction={'row'} spacing={2} justifyContent={'space-between'} alignItems={'center'}>
                                    <Stack>
                                        <Typography sx={{ color: '#fff' }} textAlign={'left'} variant='h5'>Copilot</Typography>
                                        <Typography sx={{ color: '#fff', fontSize: 12 }} textAlign={'left'} >Gere sugestões de respostas com inteligência artificial</Typography>
                                    </Stack>
                                    <Stack spacing direction="row">
                                        <IconButton onClick={onClear} sx={{ float: 'right' }}>
                                            <Iconify icon="icon-park-twotone:clear" />
                                        </IconButton>
                                        <IconButton onClick={onClose} sx={{ float: 'right' }}>
                                            <Iconify icon="ant-design:close-outlined" />
                                        </IconButton>
                                    </Stack>
                                </Stack>
                                <Divider sx={{ mt: 2, mb: 3 }} />
                                <Box >
                                    <Scrollbar sx={{ height: '70vh' }} >
                                        {isShowContextOptions && (
                                            <Stack>
                                                <FormControl component="fieldset">
                                                    <FormGroup aria-label="position" row>
                                                        <FormControlLabel
                                                            value="top"
                                                            sx={{ color: 'white' }}
                                                            control={<Checkbox
                                                                checked={checked}
                                                                onChange={handleChangeContext}
                                                            />}
                                                            label="Inserir contexto conversa"
                                                            labelPlacement="left"
                                                        />
                                                    </FormGroup>
                                                </FormControl>
                                            </Stack>
                                        )}
                                        {loadingGpt && <ItemGerandoResposta />}
                                        {!loadingGpt && conversacao && conversacao.length > 0 && (
                                            conversacao.map((resposta) => (
                                                <ItemRespostaGerada choice={() => choice(resposta)} resposta={resposta} />
                                            )
                                            )
                                        )}
                                        {isShowContextOptions && conversacao && conversacao.length === 0 && checked && (
                                            <Stack sx={{ mt: 4 }} direction={'row'}>
                                                <Button onClick={onEnviarGpt} color="primary" variant="contained" >
                                                    <Iconify width={30} icon="mdi:refresh" />
                                                    <Typography variant='caption'>criar resposta</Typography>
                                                </Button>
                                            </Stack>
                                        )}
                                    </Scrollbar>
                                </Box>
                            </Stack>

                            <DrawerChatgptInput
                                text={textInputDrawer}
                                changeText={changeText}
                                loadingGpt={loadingGpt}
                                onSend={onStartConversationGpt}
                            />

                        </Stack>
                    </Box>
                </Container>
            </Box>
        </Drawer>

    );
}
