export const TOKEN_KEY = "zindi-data";
export const USER_KEY = "user";

export const isAuthenticated = () => localStorage.getItem(TOKEN_KEY) !== null;

export const defaultKey = () => localStorage.getItem(TOKEN_KEY);
export const userItem = () => localStorage.getItem(USER_KEY);

export const login = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const login2 = token => {
    localStorage.setItem(TOKEN_KEY, token);
};

export const logout = () => {
    localStorage.removeItem(TOKEN_KEY);
};

export const getSession = () => {
    if (defaultKey() !== null) {
        const {session} = JSON.parse(defaultKey());
        return session;
    }
};

export const getUsuario = () => {
    if (localStorage.getItem("usuarioId") !== null) {
        const { usuario } = localStorage.getItem("usuarioId");
        return usuario;
    }
};

export const getToken = () => {
    if (defaultKey() !== null) {
        const {token} = JSON.parse(defaultKey());
        return token;
    }
};

export const getUser = () => {
    if (userItem() !== null) {
        const {user} = JSON.parse(userItem());
        return user;
    }
};

export const getDefaultImage = () => {
    return "https://www.promoview.com.br/uploads/images/unnamed%2819%29.png";
};

export const getClienteData = async () => {
    // get Data from Storage
    try {
      const data = await localStorage.getItem('cliente_data');
      if (data !== null) {
        return JSON.parse(data);
      }
    } catch (error) {
      console.log(error);
    }
  };
