import { combineReducers } from 'redux';
import storage from 'redux-persist/lib/storage';

// slices
import calendarReducer from './slices/calendar';
import chatReducer from './slices/chat';
import chatKanbanReducer from './slices/chat-kanban';
import dashboardReducer from './slices/dashboard';
import globalReducer from './slices/global';
import kanbanReducer from './slices/kanban';
import mailReducer from './slices/mail'
import marketingReducer from './slices/marketing'
import staffReducer from './slices/staff';
import userReducer from './slices/user';
import chamadosReducer from './slices/chamados';
import notificacoesReducer from './slices/notificacoes';
import crmReducer from './slices/crm';
import contatoReducer from './slices/contatos';
import customizationReducer from './slices/customization';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: []
};

const rootReducer = combineReducers({
  chat: chatReducer,
  staff: staffReducer,
  user: userReducer,
  dashboard: dashboardReducer,
  calendar: calendarReducer,
  kanban: kanbanReducer,
  global:globalReducer,
  chatKanban:chatKanbanReducer,
  marketing: marketingReducer,
  mail:mailReducer,
  chamados:chamadosReducer,
  notificacoes:notificacoesReducer,
  crm:crmReducer,
  contatos:contatoReducer,
  customization: customizationReducer
});

export { rootPersistConfig, rootReducer };
