import { useEffect, useState, useRef } from 'react';
// material
import { Stack, Table, Box, TablePagination, TableRow, TableHead, TableBody, TableCell, TableContainer, IconButton, Grid, TextField, Button, Typography, Paper, Container, MenuItem, FormControl } from '@mui/material';
import moment from 'moment';
import Scrollbar from 'src/components/Scrollbar';

import { useDispatch, useSelector } from 'src/redux/store';
// configs
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';
import useTable, { emptyRows, getComparator } from 'src/hooks/useTable';
import { TableBorder, TableEmptyRows, TableHeadCustom, TableNoData } from 'src/components/table';
import Breadcrumbs from 'src/components/Breadcrumbs';
import MenuDatas from 'src/components/MenuDatas';
import { validaLabelMenuDatas } from 'src/utils/functions';
import v2_actionListaAvaliacoes from 'src/actions/v2/dashboard/relatorios/v2_actionListaAvaliacoes';
import AvaliacoesRow from 'src/sections/@dashboard/relatorios/AvaliacoesRow';
import InputStyle from 'src/components/InputStyle';
import getActionListaUsuariosByClienteId from 'src/actions/usuarios/getActionListaUsuariosByClienteId';
import { RowInput } from 'src/components/forms/RowInput';
import { SelectItem } from 'src/@/components/ui/select';
import { SelectZui } from 'src/components/zui';
import MenuDatas2 from 'src/components/MenuDatas2';

export default function RelatorioListaAvaliacoes() {
  const navigate = useNavigate();

  const [listaUsuarios, setListaUsuarios] = useState([]);
  const { user } = useAuth();
  const [inicial, setInicial] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const [filterName, setFilterName] = useState('');
  const [filterStartDate, setFilterStartDate] = useState(null);
  const [filterEndDate, setFilterEndDate] = useState(null);
  const [tableData, setTableData] = useState([]);
  const [periodo, setPeriodo] = useState('mes_atual')
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuDatas = Boolean(anchorEl);

  const [filterUsuario, setFilterUsuario] = useState('');



  const TABLE_HEAD = [
    { id: 'nome', label: 'Nome Cliente' },
    { id: 'telefone', label: 'Telefone' },
    { id: 'data_visita', label: 'Data Avaliação' },
    { id: 'atendente', label: 'Atendente' },
    { id: 'nota', label: 'Nota' },
    { id: '' },
  ];


  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    //
    selected,
    onChangePage,
    onChangeRowsPerPage,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
  } = useTable({ defaultOrderBy: 'date_created', defaultRowsPerPage: 10 });
  const buscarUsuarios = async () => {
    try {
      const res = await getActionListaUsuariosByClienteId(user.clienteId);
      setListaUsuarios(res.data.usuarios)
    } catch (error) {
      console.log(error)
    }
  }
  const buscarListaAvaliacoes = async (value) => {
    try {
      let inicio2 = moment(inicial).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${value}&cliente_id=${user.clienteId}&inicio=${inicio2}&final=${final2}`
      const res = await v2_actionListaAvaliacoes(querys);
      setTableData(res.data.data)

    } catch (error) {
      console.log(error)
    }
  }


  useEffect(() => {
    buscarUsuarios()
    buscarListaAvaliacoes(periodo)
  }, []);




  const onFiltrar = async (value, action) => {
    setPeriodo(value)
    buscarListaAvaliacoes(value)
  }

  const onPesquisar = () => {
    buscarListaAvaliacoes('null');
    setPeriodo('null')
  }




  const dataFiltered = applySortFilter({
    tableData,
    comparator: getComparator(order, orderBy),
    filterUsuario
  });

  const denseHeight = dense ? 56 : 76;
  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterEndDate) ||
    (!dataFiltered.length && !!filterStartDate);

  return (
    <Container maxWidth="lg">
      <Box sx={{ mt: 5 }} title={nomeProjeto}>


        <div className='flex flex-row items-center gap-3'>

            <RowInput
              label="período"
              input={<MenuDatas2
              onClose={() => {
                setAnchorEl(null);
              } }
                anchorEl={anchorEl}
                open={openMenuDatas}
                inicial={inicial}
                final={final}
                changeInicial={newValue => setInicial(newValue)}
                changeFinal={newValue => { setFinal(newValue) }}
                content={validaLabelMenuDatas(periodo, inicial, final)}
                onSelect={value => {
                  setPeriodo(value);
                  setAnchorEl(null);
                  onFiltrar(value)
                }}
                onPesquisar={() => onPesquisar('null')}
              />}

            />

            <RowInput
              label="usuario"
              input={
                <SelectZui
                  value={filterUsuario}
                  list={listaUsuarios}
                  onChange={e => {
                    let value = e;
                    setFilterUsuario(value);

                  }}
                  emptyOption={{ value: null, label: 'Todos' }}
                  itemSelect={(item) => (
                    <SelectItem key={item._id} value={item.nomeFantasia || "undefined"}>
                      {item.firstName}
                    </SelectItem>
                  )}
                />
              }
            />

 
        </div>


        <div className='mt-5'>
          <TableBorder>

            <Scrollbar>
              <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
                <Table size={dense ? 'small' : 'medium'}>
                  <TableHeadCustom
                    order={order}
                    orderBy={orderBy}
                    headLabel={TABLE_HEAD}
                    rowCount={tableData.length}
                    numSelected={selected.length}
                    onSort={onSort}
                    onSelectAllRows={(checked) =>
                      onSelectAllRows(
                        checked,
                        tableData.map((row) => row.id)
                      )
                    }
                  />
                  <TableBody>
                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                      <AvaliacoesRow
                        key={row.numero}
                        row={row}
                        selected={selected.includes(row.id)}
                        onSelectRow={() => onSelectRow(row.id)}
                        onViewRow={() => handleOpenDetails(row)}
                        onEditRow={() => handleEditRow(row.id)}
                        onDeleteRow={() => handleDeleteRow(row.id)}
                      />
                    ))}

                    <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)} />

                    <TableNoData isNotFound={isNotFound} />
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
          </TableBorder>

        </div>

        <Stack>
        </Stack>
        <Box sx={{ position: 'relative' }}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            labelRowsPerPage="Linhas por página"
            count={dataFiltered.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={onChangePage}
            onRowsPerPageChange={onChangeRowsPerPage}
          />
        </Box>



      </Box>
    </Container>
  );
}


function applySortFilter({
  tableData,
  comparator,
  filterUsuario,
}) {
  const stabilizedThis = tableData.map((el, index) => [el, index]);

  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });

  tableData = stabilizedThis.map((el) => el[0]);

  if (filterUsuario) {
    tableData = tableData.filter(
      (item) =>
        item.atendente.toLowerCase().indexOf(filterUsuario.toLowerCase()) !== -1
    );
  }


  return tableData;
}

