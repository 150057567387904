import axios from "axios";
import Config from "src/config";

const v1_actionEnviarVozWhatsapp = (querys, data) => {
    return axios({
        method: "post",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/canais/enviar-voz${querys}`,
        data,
        maxContentLength: Infinity,
        maxBodyLength: Infinity

    });
};

export default v1_actionEnviarVozWhatsapp;
