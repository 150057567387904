import axios from "axios";

import Config from "../../../config";

const actionTotalAtendimentos = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/dashboard/relatorios/atendimentos-total${querys}`
    });
};

export default actionTotalAtendimentos;
