import axios from "axios";
import IO from "socket.io-client";

import Config from "../config";

export const socket = null;
export const api = axios.create({
    baseURL: ''
});

export default api;
