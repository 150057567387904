import { Stack, Container, Alert, Autocomplete, Typography, TextField } from "@mui/material";
//zui 
import { Button } from 'src/@/components/ui/button';
import { Card, CardContent } from 'src/@/components/ui/card';
import { Label } from 'src/@/components/ui/label';
import { Input } from 'src/@/components/ui/input';
import { RowInput } from 'src/components/forms/RowInput';
import { RadioGroup, RadioGroupItem } from 'src/@/components/ui/radio-group';

import { Switch } from 'src/@/components/ui/switch';
import SelectVoice from "./components/SelectVoice";
import ContainerOptions from "../components/ContainerOptions";
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/@/components/ui/tooltip";
import InputStyleMultline from "src/components/InputStyleMultline";
import { Textarea } from "src/@/components/ui/textarea";
import { ZuiAlert } from "src/@/components/use/alert";
import { Badge } from "src/@/components/ui/badge";
import {
    Accordion,
    AccordionContent,
    AccordionItem,
    AccordionTrigger,
} from "src/@/components/ui/accordion"


export default function AiAgentConfigTab({
    aiAgent,
    listTypeVoices,
    voice,
    changeVoice,
    awnserAudio,
    changeAwnserAudio,
    changeAgentType,
    changeListenMsgs,
    changeAllowsInChat,
    changeDelayBetweenMsgs,
    onChangeMsgs
}) {

    const optionsTipoAgente = [
        { value: 'basic', label: 'Básico', desc: 'Pode ser um modelo maior e mais robusto, capaz de lidar com tarefas complexas e gerar textos mais longos e coesos.' },
        { value: 'advanced', label: 'Avançado', desc: 'Destinado a tarefas mais simples ou onde o desempenho em tempo real é crucial. Modelos "mini" são geralmente mais rápidos e exigem menos recursos computacionais. ' }
    ];

    const optionsTypeVoices = [
        {
            value: 'never',
            label: 'Nunca',
            desc: 'O agente sempre responderá com texto as mensagens'

        },
        {
            value: 'responseAudio',
            label: 'Responder aúdio',
            desc: 'O agente responderá com áudio somente se a mensagem do usuário for um áudio'
        },
        {
            value: 'always',
            label: 'Sempre',
            desc: 'O agente sempre responderá com áudio'
        },
        {
            value: 'random',
            label: 'Randômico',
            desc: 'O agente responderá com áudio de forma aleatória'
        }
    ];

    const optionsAllowsInChat = [
        {
            "value": "ptt",
            "label": "Mensagem de voz"
        },
        {
            "value": "audio",
            "label": "Mensagem de audio"
        },
        {
            "value": "image",
            "label": "Imagens"
        },
        {
            "value": "video",
            "label": "Videos"
        },
        {
            "value": "document",
            "label": "Documentos"
        }
    ]

    const newOptionsAllowsInChat = optionsAllowsInChat.filter(item => aiAgent?.config?.generalConfig?.allowsInChat.includes(item.value));

    return (

        <Container maxWidth="md">

            <Stack sx={{ my: 5 }} direction={"column"} spacing={3}>
                <ContainerOptions>
                    <RowInput
                        label={"Tipo de Agente"}
                        input={
                            <RadioGroup
                                value={aiAgent?.config?.generalConfig?.agent_type}
                                onValueChange={(value) => changeAgentType(value)}
                                className="grid grid-cols-2 gap-4 w-full"
                            >
                                {optionsTipoAgente.map((option) => (
                                    <TooltipProvider>
                                        <Tooltip>
                                            <TooltipTrigger asChild>
                                                <div className='cursor-pointer w-full' key={option.desc}>
                                                    <RadioGroupItem
                                                        value={option.value}
                                                        id={option.value}
                                                        className="peer sr-only "
                                                    />
                                                    <Label
                                                        htmlFor={option.value}
                                                        className={`flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-2 hover:bg-violet-500 hover:text-white ${aiAgent?.config?.generalConfig?.agent_type === option.value ? 'bg-violet-500 text-white' : ''}`}
                                                    >
                                                        {option.label}
                                                    </Label>
                                                </div>
                                            </TooltipTrigger>
                                            <TooltipContent className="bg-slate-900 text-white">
                                                <p>{option.desc}</p>
                                            </TooltipContent>
                                        </Tooltip>
                                    </TooltipProvider>
                                ))}
                            </RadioGroup>
                        }
                    />
                </ContainerOptions>

                <ContainerOptions>
                    <Stack direction={"row"} spacing={2}>
                        <RowInput
                            sx={{ width: '100%' }}
                            label={"Audio como resposta"}
                            input={
                                <RadioGroup
                                    defaultValue={awnserAudio}
                                    value={awnserAudio}
                                    onValueChange={changeAwnserAudio}
                                    className="grid grid-cols-4 gap-4"
                                >
                                    {optionsTypeVoices.map((option) => (
                                        <div>
                                            <RadioGroupItem
                                                value={option.value}
                                                id={option.value}
                                                className="peer sr-only"
                                            />
                                            <Label
                                                htmlFor={option.value}
                                                className={`flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-2 hover:bg-accent hover:text-accent-foreground ${awnserAudio === option.value ? 'bg-violet-500 text-white' : ''}`}
                                            >
                                                {option.label}
                                            </Label>
                                        </div>
                                    ))}
                                </RadioGroup>
                            }
                        />

                        <RowInput
                            label={"Voz"}
                            input={<SelectVoice
                                listTypeVoices={listTypeVoices}
                                voice={voice}
                                changeVoice={(value) => {
                                    changeVoice(value)

                                }}
                            />}
                        />

                    </Stack>
                </ContainerOptions>




                <ContainerOptions>
                    <Stack spacing={{ xs: 2, md: 3 }}>

                        <Accordion type="single" collapsible className="w-full">
                            <AccordionItem value="item-1">
                                <AccordionTrigger>Configurações avançadas</AccordionTrigger>
                                <AccordionContent>
                                   
                        <Stack direction={"row"}>
                            <RowInput
                                label={"Tempo de resposta do agente"}
                                info="Tempo em segundos que o agente aguarda para processar a mensagem e responder"
                                input={
                                    <div className="flex flex-row items-center">

                                        <Input
                                            value={aiAgent?.config?.generalConfig?.timeListenMsgs}
                                            name="timeListenMsgs"
                                            onChange={e => changeListenMsgs(e.target.value)}
                                            fullWidth
                                            className="w-1/3"
                                            type="number"
                                        />
                                        <Label className="ml-2">segundos</Label>
                                    </div>
                                }
                            />


                            <RowInput
                                label={"Tempo de digitação do agente"}
                                info="Tempo de delay que o agente demora para escrever as mensagens. Tempo padrão: 50"
                                input={
                                    <div className="flex flex-row items-center">

                                        <Input
                                            value={aiAgent?.config?.generalConfig?.delayBetweenMsgs}
                                            onChange={e => changeDelayBetweenMsgs(e.target.value)}
                                            fullWidth
                                            className="w-1/3"
                                            type="number"
                                        />
                                        <Label className="ml-2">milisegundos</Label>
                                    </div>
                                }
                            />

                        </Stack>
                                </AccordionContent>
                            </AccordionItem>
                        </Accordion>


                        <Stack spacing={2}>

                            <RowInput
                                sx={{ width: '100%' }}
                                label="tipo de mensagens permitidas"
                                info="Selecione os tipos de mensagens que o agente pode receber"
                                input={<Autocomplete
                                    multiple
                                    value={newOptionsAllowsInChat}
                                    options={optionsAllowsInChat}
                                    onChange={(event, newValue) => {
                                        let newValues = newValue.map(item => item.value);
                                        changeAllowsInChat(newValues)
                                    }}
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Badge key={option.value} {...getTagProps({ index })}>
                                                {option.label}
                                            </Badge>

                                        ))
                                    }
                                    getOptionLabel={(option) => option.label}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                        />
                                    )}
                                />}
                            />


                        </Stack>
                        <Stack direction="column" spacing={2}>
                            <RowInput
                                sx={{ width: '100%' }}
                                label="Mensagem recusa envio de audios"
                                input={<Textarea
                                    multiline
                                    rows={3}
                                    name="msgs.msgBreakAudios"
                                    onChange={onChangeMsgs}
                                    value={aiAgent?.config?.generalConfig?.messages?.msgBreakAudios}
                                />}
                            />
                            <RowInput
                                sx={{ width: '100%' }}
                                label="Mensagem recusa envio de imagens"
                                input={<Textarea
                                    multiline
                                    onChange={onChangeMsgs}
                                    rows={3}
                                    name="msgs.msgImages"
                                    value={aiAgent?.config?.generalConfig?.messages?.msgImages}
                                    fullWidth
                                />}
                            />
                            <RowInput
                                sx={{ width: '100%' }}
                                label="Mensagem recusa envio de arquivos"
                                input={<Textarea
                                    multiline
                                    rows={3}
                                    size="small"
                                    name="msgs.msgBreakFiles"
                                    onChange={onChangeMsgs}
                                    value={aiAgent?.config?.generalConfig?.messages?.msgBreakFiles}
                                    fullWidth
                                />}
                            />
                        </Stack>
                    </Stack>
                </ContainerOptions>
            </Stack>
        </Container>

    )

}