import { useState } from 'react';
import { LoadingButton } from '@mui/lab';
import {
    Box,
    DialogTitle,
    Drawer,
    IconButton,
    Stack,
    Typography
} from '@mui/material';
import CanaisConfig from './CanaisConfig';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import v2_actionCriarCanal from 'src/actions/v2/cliente/canais/v2_actionCriarCanal';



export default function DialogNewChannel({ 
    open, 
    onClose,
    onSubmit,
    canal
 }) {

    const {cliente} = useAuth();

    const [nome, setNome] = useState('')
    const [chanelType, setChanelType] = useState('whatsapp')
    const [webhookType, setWebhookType] = useState('')

    const newObjChannel = {
        platform: chanelType,
        webhook_type: webhookType,
        ...canal
    }

    function handleChange(event, newValue) {
        const { name, value } = event.target

        switch (name) {
            case 'channelName':
                setNome(value)
                break;
        }
    }

    async function handleCreateChannel() {
        let data = {
            scope: {
                nome: nome,
                platform: chanelType,
                webhook_type: webhookType
            }
        }
        const response = await v2_actionCriarCanal(cliente._id, data)
        onSubmit(response.data.data._id);
    }



    return (
        <Drawer
            open={open}
            onClose={onClose}
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
           anchor='right'
        >
            <DialogTitle sx={{
                background: theme => theme.palette.primary.main, py: 1, color: '#fff'
            }}>
                <Stack direction={"row"} alignItems={"center"} justifyContent={'space-between'}>
                <Typography variant="h6" align="center">
                    Criar novo canal
                    </Typography>
                    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                        <IconButton
                        onClick={onClose}
                        >
                            <Iconify icon={"mdi:close"} color="white" width={24} height={24} />
                        </IconButton>
                    </Box>
          
                </Stack>
     
            </DialogTitle>
            <Box sx={{ p: 2, minWidth:{md:800, lg:1000, sm:800, xs:600} }}>
                <CanaisConfig
                onSubmit={handleCreateChannel}
                onChange={handleChange}
                onChangeChannel={value => {
                    if (value.target === "webhookType") {
                        setWebhookType(value.value)}
                }}
                canal={newObjChannel}
                />
            </Box>
        
        </Drawer>
    );
}


