import sumBy from 'lodash/sumBy';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useS } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Tab,
  Tabs,

  Stack,
  Switch,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  TextField,
  InputBase,
  Paper,
  Typography,
  Divider
} from '@mui/material';
// routes// hooks
import useTabs from 'src/hooks/useTabs';
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';
import useTable, { emptyRows } from 'src/hooks/useTable';

// components
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from 'src/components/table';
// sections

import { useDispatch, useSelector } from 'src/redux/store';
//components
import { notifyMe, soNumeros, validaLabelMenuDatas } from 'src/utils/functions';
import v2_actionListaChatsSupervisao from 'src/actions/v2/dashboard/supervisao/v2_actionListaChatsSupervisao';
import moment from 'moment';
import SupervisaoRow from 'src/sections/@dashboard/supervisao/SupervisaoRow';
import { getListaSetoresTransferencia, getListaUsuariosTransferencia } from 'src/redux/slices/user';
import { getMensagensWhatsapp, getMensagensWhatsappDb, setChatInfo, setMessage } from 'src/redux/slices/chat';
import LoadingScreen from 'src/components/LoadingScreen';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import DrawerChatWhatsapp from 'src/sections/@chats/chat/DrawerChatWhatsapp';
import { RowInput } from 'src/components/forms/RowInput';
import v2_actionListaTagsChats from 'src/actions/v2/chats/v2_actionListaTagsChats';

//zui
import { SelectItem } from 'src/@/components/ui/select';
import { SelectZui } from 'src/components/zui';
// ----------------------------------------------------------------------

import { Button } from 'src/@/components/ui/button';
import MenuDatas2 from 'src/components/MenuDatas2';
import { Input } from 'src/@/components/ui/input';
import { TableHead, Table, TableBody, TableHeader } from 'src/@/components/ui/table';
import { Card, CardContent } from 'src/@/components/ui/card';
import { SupervisaoExport } from 'src/sections/@dashboard/supervisao/SupervisaoExport';
import v2_actionGerarExcelRelatorioChats from 'src/actions/v2/dashboard/relatorios/excel/v2_actionGerarExcelRelatorioChats';
import AiAnalyzeRow from 'src/sections/@dashboard/aiAnalyse/AiAnalyzeRow';
import SkeletonSupervisionList from 'src/@/components/use/skeleton/SkeletonDashboardPage';
import v2_actionBuscarChatById from 'src/actions/v2/services/chats/v2_actionBuscarChatById';


const TABLE_HEAD = [
  { id: 'numberAnalyze', label: 'Número Avaliação' },
  { id: 'numeroCliente', label: 'Numero' },
  { id: 'nome_contato', label: 'Nome Contato' },
  // { id: 'nome_usuario', label: 'Atendente' },
  // { id: 'nome_setor', label: 'Setor' },
  { id: 'lastUpdate', label: 'Data' },

  // { id: 'chatbot', label: 'Chatbot' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function AiAnaliseSentimento() {
  const { themeStretch } = useSettings();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'date_created', defaultRowsPerPage: 25 });

  const { listaUsuariosTransferencia, listaSetoresTransferencia } = useSelector((state) => state.user);
  const { listaFunil, chatInfo, mensagens } = useSelector((state) => state.chat);
  const { user, cliente } = useAuth();
  const [filterName, setFilterName] = useState('');
  const [filterPesquisa, setFilterPesquisa] = useState('');
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(null);
  const [inicio, setInicio] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const [periodo, setPeriodo] = useState('mes_atual')
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuDatas = Boolean(anchorEl);
  const [valorEscolhidoUsuario, setValorEscolhidoUsuario] = useState("");
  const [valorEscolhidoSetor, setValorEscolhidoSetor] = useState("");

  const [chat, setChat] = useState(null);
  const [openChatdetalhes, setOpenChatDetalhes] = useState(false);
  const [loadingListaChats, setLoadingListaChats] = useState(false);
  const [isPesquisado, setIsPesquisado] = useState(false);
  const [listaChatsPesquisa, setListaChatsPesquisa] = useState([]);
  const [listaChatsFiltro, setListaChatsFiltro] = useState([]);
  const tableData = isPesquisado ? listaChatsPesquisa : listaChatsFiltro;
  const [loadingVerDetalhes, setLoadingVerDetalhes] = useState(false);

  const [clienteIdPadrao, setClienteIdPadrao] = useState(cliente._id);
  const [listaClientesCanais, setListaClientesCanais] = useState([]);
  const isMultisession = cliente.multiSession === 1;
  const [listaTags, setListaTags] = useState([]);
  const [valorEscolhidoTag, setValorEscolhidoTag] = useState("");



  async function buscarListaClientesCanais() {
    try {
      const response_clientes_canais = await actionListaClientesCanais(cliente._id)
      setListaClientesCanais(response_clientes_canais.data.data);
    } catch (err) {
      console.log(err)
    }
  }

  function obterNumeroPorClienteId(cliente_id, arr) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].cliente_id === cliente_id) {
        return arr[i].nome;
      }
    }
    return null; // Ou outra ação apropriada, como lançar um erro.
  }

  async function buscarListaTags() {
    try {
      let querys = `?cliente_id=${user.clienteId}`
      const response = await v2_actionListaTagsChats(querys);
      setListaTags(response.data.data)
    } catch (error) {

    }
  }


  async function buscarListaChats(querys, action, isPesquisado) {
    setLoadingListaChats(true);
    if (action === 'pesquisar' && !filterPesquisa) {
      alert("Campo de pesquisa está vazio!");
      return;
    }

    try {
      const response = await v2_actionListaChatsSupervisao(querys);
      if (isPesquisado) {
        setListaChatsPesquisa(response.data.data);
        setLoadingListaChats(false);
      } else {
        setListaChatsFiltro(response.data.data);
        setLoadingListaChats(false);
      }


    } catch (error) {
      setLoadingListaChats(false);
      console.log(error)
    }

  }

  const onLoad = async () => {
    const inicio2 = moment(inicio).format("YYYY-MM-DD");
    const final2 = moment(final).format("YYYY-MM-DD");
    const querys = `?periodo=${periodo}&cliente_id=${isMultisession ? clienteIdPadrao : user.clienteId}&inicio=${inicio2}&final=${final2}&action=filtrar`;
    await buscarListaChats(querys, "filtrar", false)
    Promise.all([
      dispatch(getListaUsuariosTransferencia(user.clienteId)),
      dispatch(getListaSetoresTransferencia(user.clienteId)),
      buscarListaClientesCanais(),
      buscarListaTags()
    ])
  }


  useEffect(() => {
    onLoad()
  }, [location, periodo, clienteIdPadrao]);


  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    // getListaPedidosVendaFacil()
    setPage(0);
  };

  const handleChangeClienteIdPadrao = (value) => {
    console.log('value', value)
    setClienteIdPadrao(value)
    if (filterPesquisa.length > 0) {
      onPesquisarCanal(value)
    } else {
      const inicio2 = moment(inicio).format("YYYY-MM-DD");
      const final2 = moment(final).format("YYYY-MM-DD");
      const querys = `?periodo=${periodo}&cliente_id=${value}&inicio=${inicio2}&final=${final2}&action=filtrar`;
      buscarListaChats(querys, "filtrar", false)
    }

  }

  const handlePesquisa = (e) => {
    setFilterPesquisa(e.target.value);
  };



  const onPesquisar = async () => {
    setIsPesquisado(true);
    const inicio2 = moment(inicio).format("YYYY-MM-DD");
    const final2 = moment(final).format("YYYY-MM-DD");
    const querys = `?periodo=null&cliente_id=${isMultisession ? clienteIdPadrao : user.clienteId}&inicio=${inicio2}&final=${final2}&action=pesquisar&search=${filterPesquisa}`;
    await buscarListaChats(querys, "pesquisar", true)
  }

  const onPesquisarCanal = async (clienteId) => {
    setIsPesquisado(true);
    const inicio2 = moment(inicio).format("YYYY-MM-DD");
    const final2 = moment(final).format("YYYY-MM-DD");
    const querys = `?periodo=null&cliente_id=${clienteId}&inicio=${inicio2}&final=${final2}&action=pesquisar&search=${filterPesquisa}`;
    await buscarListaChats(querys, "pesquisar", true)
  }


  const onFiltrar = async (value, action) => {
    setIsPesquisado(false);
    setPeriodo(value)
    const inicio2 = moment(inicio).format("YYYY-MM-DD");
    const final2 = moment(final).format("YYYY-MM-DD");
    const querys = `?periodo=${value}&cliente_id=${isMultisession ? clienteIdPadrao : user.clienteId}&inicio=${inicio2}&final=${final2}&action=${action}`;
    await buscarListaChats(querys, "filtrar", false);
    setFilterPesquisa('')
  }


  async function handleOpenDetails(card) {
    setLoadingVerDetalhes(true);
    setOpenChatDetalhes(true);
    const res = await v2_actionBuscarChatById(card._id, null, user.id)
    setChat(res.data);
    dispatch(setChatInfo(res.data));
    setLoadingVerDetalhes(false);
    if (cliente.config.chat_source_db) {
      dispatch(getMensagensWhatsappDb(res.data.sessao, res.data.numeroCliente));
    } else {
      dispatch(getMensagensWhatsapp(res.data.sessao, res.data.numeroCliente));
    }
  }

  const dataFiltered = applySortFilter({
    tableData,
    filterStatus,
    valorEscolhidoUsuario,
    valorEscolhidoTag,
    valorEscolhidoSetor
  });



  const handleGerarExcelChats = async () => {
    try {
      const inicio2 = moment(inicio).format("YYYY-MM-DD");
      const final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${periodo}&usuario_id=${user.id}&cliente_id=${clienteIdPadrao}&inicio=${inicio2}&final=${final2}`
      const response = await v2_actionGerarExcelRelatorioChats(querys);
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `lais-chats.xlsx`);
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.log(error)
    }
  }




  const denseHeight = dense ? 56 : 76;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus)

  return (

    <>
      {chat && (
        <DrawerChatWhatsapp
          chat={chat}
          open={openChatdetalhes}
          onClose={() => {
            setChat(null)
            setOpenChatDetalhes(false)
          }}
          listaFunil={listaFunil}
        />
      )}

      <Box sx={{ mt: 2 }}>
        <Container maxWidth={'xl'} >
          <Stack spacing={1} direction="row" justifyContent={"space-between"} alignItems="center" mb={1}>
            <div>

              <Stack sx={{ p: 1 }} spacing={1.5} direction="row" alignItems="end" >
                <RowInput
                  label="Filtrar por data"
                  input={
                    <MenuDatas2
                      anchorEl={anchorEl}
                      open={openMenuDatas}
                      onClose={() => setAnchorEl(false)}
                      inicial={inicio}
                      final={final}
                      content={validaLabelMenuDatas(periodo, inicio, final)}
                      changeInicial={newValue => setInicio(newValue)}
                      changeFinal={newValue => { setFinal(newValue) }}
                      onSelect={value => {
                        setPeriodo(value);
                        setAnchorEl(null);
                        onFiltrar(value, 'filtrar')
                      }}
                      onPesquisar={() => onFiltrar('null')}
                    />
                  }
                />

                {isMultisession && (
                  <RowInput
                    sx={{ width: '100%' }}
                    label="Canal"
                    input={<SelectZui
                      value={clienteIdPadrao}
                      list={listaClientesCanais}
                      onChange={e => {
                        let value = e;
                        handleChangeClienteIdPadrao(value);

                      }}
                      itemSelect={(item) => (
                        <SelectItem key={item.cliente_id} value={item.cliente_id}>
                          {item.nome || "undenfined"}
                        </SelectItem>
                      )}
                    />}

                  />
                )}

                {/* <RowInput
                    sx={{ width: '100%' }}
                    label="Filtrar por usuário"
                    input={<SelectZui
                      value={valorEscolhidoUsuario}
                      list={listaUsuariosTransferencia}
                      onChange={e => {
                        let value = e;
                        setValorEscolhidoUsuario(value);

                      }}
                      emptyOption={{ value: null, label: 'Todos' }}
                      itemSelect={(item) => (
                        <SelectItem key={item._id} value={item.nomeFantasia || "undefined"}>
                          {item.firstName}
                        </SelectItem>
                      )}
                    />}

                  />


                  <RowInput
                    sx={{ width: '100%' }}
                    label="Filtrar por setor"
                    input={<SelectZui
                      value={valorEscolhidoSetor}
                      list={listaSetoresTransferencia}
                      onChange={e => {
                        let value = e;
                        setValorEscolhidoSetor(value);

                      }}
                      emptyOption={{ value: null, label: 'Todos' }}
                      itemSelect={(item) => (
                        <SelectItem key={item._id} value={item.nome_setor}>
                          {item.nome_setor}
                        </SelectItem>
                      )}
                    />}

                  /> */}

                <Button
                  className="h-9"
                  variant="outline"
                  onClick={onLoad}
                >
                  <Iconify icon="mdi:reload" />
                </Button>
                {/* <SupervisaoExport
                  onExportListChats={() => handleGerarExcelChats()}
                /> */}


              </Stack>

            </div>

            <div className='flex flex-row gap-1 items-center pt-5'>

              <RowInput
                label=""
                input={
                  <Stack direction={'row'} spacing={0.3}>
                    <Input
                      placeholder="Pesquisar"
                      value={filterPesquisa}
                      className="w-[150px]"
                      onChange={e => {
                        if (e.target.value.length === 0) {
                          setIsPesquisado(false);
                        }
                        setFilterPesquisa(e.target.value)
                      }}
                    />
                    {isPesquisado && (

                      <IconButton onClick={() => {
                        setIsPesquisado(false);
                        setFilterPesquisa('');
                      }} type="button" sx={{ p: '5px' }} aria-label="search">
                        <Iconify width={15} icon="ooui:clear" />
                      </IconButton>
                    )}
                    <Button
                      className="h-8"
                      onClick={onPesquisar}
                      size="icon"
                      disabled={filterPesquisa.length === 0}
                    >
                      <Iconify color="white" icon="eva:search-outline" />
                    </Button>

                  </Stack>
                }
              />

            </div>



          </Stack>

        </Container>
      </Box>


      {loadingListaChats ? (
        <Container maxWidth={'xl'}>
          <Card sx={{ display: 'flex', flexDirection: 'column', height: '90vh' }}>
            <SkeletonSupervisionList />
          </Card>
        </Container>
      )
        :
        (
          <Container maxWidth={'xl'}>
            <Card>



              <div className='p-3'>

                <Scrollbar sx={{ maxHeight: '60vh' }}>
                  <Table>
                    <TableHeader className="bg-slate-100 p-2">
                      {
                        TABLE_HEAD.map((item, index) => (
                          <TableHead key={index}>
                            {item.label}
                          </TableHead>
                        ))
                      }
                    </TableHeader>
                    <TableBody>
                      {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                        <AiAnalyzeRow
                          key={row.id}
                          row={row}
                          chat={chat}
                          loading={loadingVerDetalhes}
                          selected={selected.includes(row.id)}
                          onSelectRow={() => onSelectRow(row.id)}
                          onViewRow={() => handleOpenDetails(row)}

                        />
                      ))}

                      <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)} />

                      <TableNoData isNotFound={isNotFound} />
                    </TableBody>
                  </Table>
                </Scrollbar>
                <Box sx={{ position: 'relative' }}>
                  <TablePagination
                    rowsPerPageOptions={[25, 50, 100]}
                    component="div"
                    labelRowsPerPage="Linhas por página"
                    labelDisplayedRows={({ from, to, count }) => `${from} - ${to} de ${count !== -1 ? count : `mais de ${to}`}`}
                    count={dataFiltered.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={onChangePage}
                    onRowsPerPageChange={onChangeRowsPerPage}
                  />
                </Box>
              </div>

            </Card>
          </Container>
        )}



    </>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  filterStatus,
  valorEscolhidoUsuario,
  valorEscolhidoTag,
  valorEscolhidoSetor
}) {



  if (filterStatus !== null) {
    tableData = tableData.filter((item) => item.statusRoom === filterStatus)
  }


  if (valorEscolhidoUsuario) {
    tableData = tableData.filter((item) => item.nome_usuario === valorEscolhidoUsuario)
  }

  if (valorEscolhidoSetor) {
    tableData = tableData.filter((item) => item.nome_setor === valorEscolhidoSetor)
  }

  if (valorEscolhidoTag) {
    tableData = tableData.filter((item) => item.tags?.includes(valorEscolhidoTag))
  }

  console.log('tableData', tableData)

  return tableData;
}

