import axios from "axios";

import Config from "../../config";

const getActionPesquisarChat = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/chat/pesquisar`,
        data
    });
};

export default getActionPesquisarChat;
