import { Container, IconButton, Stack, Typography, Box } from "@mui/material";
import Iconify from "src/components/Iconify";
import { styled } from '@mui/material/styles';
import { HEADER, NAVBAR } from 'src/config';

const MainStyle = styled(Box, {
    shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ theme }) => ({
    // paddingTop: HEADER.MOBILE_HEIGHT + 24,
    marginTop:15,
    paddingBottom: 35,
    //background:theme.palette.grey[200],
    borderBottom: `1px solid ${theme.palette.grey[200]}`,
    [theme.breakpoints.up('lg')]: {
        paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT - 50,
        transition: theme.transitions.create('margin-left', {
            duration: theme.transitions.duration.shorter,
        }),

    },
}));

export default function HeaderPagePadrao({ titulo, descricao, actions, back, backAction }) {

    return (
        <MainStyle>
  
                <Stack justifyContent={'space-between'} spacing={2} direction={'row'} >
                    <Stack>
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            {backAction && <IconButton onClick={backAction}><Iconify icon={'typcn:arrow-back'} height={25} width={25} /></IconButton>}
                            <Stack direction={'column'}>
                                <h3 className="scroll-m-20 text-2xl font-semibold tracking-tight">
                                    {titulo}
                                </h3>
                                <p className="leading-7">
                                    {descricao}
                                </p>
                            </Stack>
                        </Stack>
                    </Stack>
                    <Stack alignContent={'flex-end'} justifyContent={'end'} alignItems={'flex-end'}>
                        {actions}
                    </Stack>

                </Stack>
    
        </MainStyle>
    );
}
