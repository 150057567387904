import axios from "axios";
import Config from "../../config";

const actionListaIntegracoesByCliente = clienteId => {   
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/cliente/integracoes/lista/${clienteId}`
    });
};

export default actionListaIntegracoesByCliente;
