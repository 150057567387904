import { useEffect, useState, useRef } from 'react';
// material
import { Stack, Table, Box, TableRow, TableHead, TableBody, TableCell, TableContainer, IconButton, Grid, TextField, Button, Typography, Card, Container, Paper, FormControl, InputBase } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

import Scrollbar from 'src/components/Scrollbar';


import { useDispatch, useSelector } from 'src/redux/store';
// configs
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';
import ForcaVendaResumoWidget from 'src/components/_dashboard/relatorios/forcaVendas/ForcaVendaResumoWidget';
import actionMetricasForcaVendas from 'src/actions/dashboard/relatorios/actionMetricasForcaVendas';
import actionMetricasForcaVendasUsuarios from 'src/actions/dashboard/relatorios/actionMetricasForcaVendasUsuarios';
import EmptyContent from 'src/components/EmptyContent';
import Breadcrumbs from 'src/components/Breadcrumbs';
import MenuDatas from 'src/components/MenuDatas';
import { resultTimes, validaLabelMenuDatas } from 'src/utils/functions';
import moment from 'moment';
import v2_actionMetricasForcaVendasUsuarios from 'src/actions/v2/dashboard/relatorios/v2_actionMetricasForcaVendasUsuarios';
import v2_actionMetricasForcaVendas from 'src/actions/v2/dashboard/relatorios/v2_actionMetricasForcaVendas';
import v2_actionRelatorioChamados from 'src/actions/v2/dashboard/relatorios/v2_actionRelatorioChamados';
import v2_actionRelatorioChamadosUsuarios from 'src/actions/v2/dashboard/relatorios/v2_actionRelatorioChamadosUsuarios';

export default function RelatorioChamados() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const { somaChatsByStatus } = useSelector((state) => state.dashboard);
  const { user } = useAuth();
  const [isPesquisado, setIsPesquisado] = useState(false);
  const [inicial, setInicial] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const multiSession = localStorage.getItem("multiSession");
  const clienteIdActive = localStorage.getItem("clienteIdActive");

  //força vendas
  const [metricasChamados, setMetricasChamados] = useState(null);
  const [listaMetricasUsuarios, setListaMetricasUsuarios] = useState([]);
  const [periodo, setPeriodo] = useState('mes_atual')
  const [filterPesquisa, setFilterPesquisa] = useState('mes_atual')
  const [anchorEl, setAnchorEl] = useState(null);
  const openMenuDatas = Boolean(anchorEl);


  useEffect(() => {
    handleBuscarDadosChamados(periodo);

  }, [inicial, final]);


  const handleBuscarDadosChamados = async (value) => {
    try {
      let inicio2 = moment(inicial).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${value}&cliente_id=${user.clienteId}&inicio=${inicio2}&final=${final2}`
      const res = await v2_actionRelatorioChamados(querys);
      const res2 = await v2_actionRelatorioChamadosUsuarios(querys);
      setMetricasChamados(res.data.data.total);
      setListaMetricasUsuarios(res2.data.data)
    } catch (err) {
      console.log('err', err)
    }
  }


  const handlePesquisar = () => {
    handleBuscarDadosChamados('null');
    setIsPesquisado(true);
  }

  const onFiltrar = async (value, action) => {
    setIsPesquisado(false);
    setPeriodo(value)
    handleBuscarDadosChamados(value);
    setFilterPesquisa('')
  }

  const onPesquisar = async () => {
    // setIsPesquisado(true);
    // const inicio2 = moment(inicio).format("YYYY-MM-DD");
    // const final2 = moment(final).format("YYYY-MM-DD");
    // const querys = `?periodo=null&cliente_id=${multiSession === "1" ? clienteIdActive : user.clienteId}&inicio=${inicio2}&final=${final2}&action=pesquisar&search=${filterPesquisa}`;
    // await buscarListaChats(querys, "pesquisar", true)
  }

  return (
    <>
      <Box sx={{mt:5}} title={nomeProjeto}>
      <Container maxWidth={false} >
        <Breadcrumbs
          backButton={() => navigate('/dashboard/relatorios')}
          links={[{ name: 'Relatorios', href: '/dashboard/relatorios' }, { name: 'Relatorio Chamados' }]}
        />
       </Container>

        <Container maxWidth={false} >
          <MenuDatas
            anchorEl={anchorEl}
            open={openMenuDatas}
            onClose={() => setAnchorEl(false)}
            inicial={inicial}
            final={final}
            changeInicial={newValue => setInicial(newValue)}
            changeFinal={newValue => { setFinal(newValue) }}
            onSelect={value => {
              setPeriodo(value);
              setAnchorEl(null);
              onFiltrar(value, 'filtrar')
            }}
            onPesquisar={() => onPesquisar()}
          />

          <Stack spacing={2} direction="row" alignItems="center" mb={1}>

            <Paper
              component="form"
              sx={{ p: '1px 4px', display: 'flex', alignItems: 'center' }}
            >
              <Button
                //variant="outlined"
                sx={{ p: '7px', minWidth: 170, textTransform: 'none' }}
                startIcon={<Iconify icon="solar:calendar-date-bold-duotone" />}
                endIcon={<Iconify icon="bxs:down-arrow" sx={{ width: 10, height: 10 }} />}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                {validaLabelMenuDatas(periodo, inicial, final)}
              </Button>
              {/* 
            <FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
              <TextField
                select
                label="Filtrar por usuário"
                fullWidth
                size="small"
                value={valorEscolhidoUsuario}
                onChange={e => {
                  setValorEscolhidoUsuario(e.target.value);
                }}
              >
                <MenuItem value=""><em>Sem filtro</em> </MenuItem>
                {listaUsuariosTransferencia.map((option) => (
                  <MenuItem key={option._id} value={option.nomeFantasia}>
                    {option.firstName}
                  </MenuItem>
                ))}
              </TextField>
            </FormControl> */}
            </Paper>

          </Stack>
        </Container>

        <Container sx={{mt:3}} maxWidth>
          <Box sx={{ backgroundColor: '#fff', height: 'calc(9vh - 150px)', }}>

            <Stack direction={'column'} spacing={2}>
            <Card sx={{ p: 3 }}>
              {metricasChamados && (
                <Grid container spacing={3} >
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Abertos" total={metricasChamados.totalAberto} icon={'ion:ticket'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Pendentes" total={metricasChamados.totalPendente} icon={'tabler:file-time'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Concluidos" total={metricasChamados.totalConcluido} icon={'fa-solid:check'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Tempo Conclusão" total={0} icon={'mdi:clock-outline'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Concluídos apos Prazo" total={metricasChamados.atrasados} icon={'material-symbols:assignment-late-sharp'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Cancelados" total={metricasChamados.totalCancelado} icon={'zondicons:close-solid'} />
                  </Grid>
                  <Grid item xs={12} sm={4} md>
                    <ForcaVendaResumoWidget title="Recusados" total={metricasChamados.totalRecusado} icon={'healthicons:refused'} />
                  </Grid>


                </Grid>
              )}
            </Card>

               {listaMetricasUsuarios.length > 0 && (
            
            <Card sx={{ backgroundColor: '#fff', p: 3 }}>

         
                      <Box sx={{ height: 400, alignItems: 'center' }}>
                        <Typography variant='h4'>Métricas por usuário</Typography>
                        <Scrollbar >
                          <TableContainer sx={{ minWidth: 400, mt: 3 }}>
                            <Table >
                              <TableHead>
                                <TableRow>

                                  <TableCell align="left">Usuário</TableCell>
                                  <TableCell align="left">Abertos</TableCell>
                                  <TableCell align="left">Pendentes</TableCell>
                                  <TableCell align="left">Concluídos</TableCell>
                                  <TableCell align="left">Tempo Conclusão</TableCell>
                                  <TableCell align="left">Concluídos apos Prazo</TableCell>
                                  <TableCell align="left">Recusados</TableCell>
                                  <TableCell align="left">Cancelados</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {listaMetricasUsuarios.map((row) => {
                                  let tma = row.tma / row.count_tma;
           

                                  return (
                                    <TableRow key={row._id}>
                                      <TableCell component="th" scope="row">
                                        {row.nomeUsuario}
                                      </TableCell>

                                      <TableCell width={150} component="th" scope="row">
                                        {row.totalAberto ? row.totalAberto : '-'}
                                      </TableCell>
                                      <TableCell width={150} component="th" scope="row">
                                        {row.totalPendente ? row.totalPendente : '-'}
                                      </TableCell>

                                      <TableCell width={150} component="th" scope="row">
                                        {row.totalConcluido ? row.totalConcluido : '-'}

                                      </TableCell>
                                      <TableCell width={150} component="th" scope="row">
                                        {tma ? resultTimes(tma) : '-'}

                                      </TableCell>
                                      <TableCell width={150} component="th" scope="row">
                                        {row.totalAtrasados ? row.totalAtrasados : '-'}
                                      </TableCell>

                                      <TableCell width={150} component="th" scope="row">
                                        {row.totalRecusados ? row.totalRecusados : '-'}
                                      </TableCell>

                                      <TableCell width={150} component="th" scope="row">
                                        {row.totalCancelado ? row.totalCancelado : '-'}
                                      </TableCell>

                                    </TableRow>
                                  )
                                })}
                              </TableBody>
                            </Table>
                          </TableContainer>
                        </Scrollbar>
                      </Box>
           

            </Card>
                     )}
            </Stack>

          </Box>
        </Container>
      </Box >
    </>
  );
}
