
// material
import {
    useMediaQuery, Divider, IconButton,
    // Tabs, 
    // Tab, 
    Typography, Box, Stack, Paper
} from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

//import ChatRoomOneParticipant from './ChatRoomOneParticipant';
import v2_actionListaTagsChats from 'src/actions/v2/chats/v2_actionListaTagsChats';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import ChatInfoContato from './ChatInfoContato';
import ChatInfoNotas from './ChatInfoNotas';
import ChatInfoNegocios from './ChatInfoNegocios';
import v2_actionListaLeadsPorChat from 'src/actions/v2/crm/v2_actionListaLeadsPorChat';
import ChatInfoContatoAntigo from './ChatInfoContatoAntigo';

import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "src/@/components/ui/tabs"
import { Card, CardContent } from 'src/@/components/ui/card';
import { getChatInfoByChatId } from 'src/redux/slices/chat';
import { useDispatch } from 'react-redux';
import VerContatoForm from 'src/sections/@dashboard/cadastro/VerContatoForm';
import Scrollbar from 'src/components/Scrollbar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    //    width: '100%',
    //    display: 'flex',
    minWidth: 370,
    padding: 10
}));



ChatInfoSidebar.propTypes = {
    chat: PropTypes.object.isRequired
};

export default function ChatInfoSidebar({ chat, ...other }) {
    const { user, cliente } = useAuth();
    const [showInfo, setShowInfo] = useState(true);
    const [listaTags, setListaTags] = useState([]);
    const [listaLeads, setListaLeads] = useState([]);
    const [value, setValue] = useState(0);
    const [openSidebar, setOpenSidebar] = useState(false);

    const dispatch = useDispatch();

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue === 1) {
            buscarLeads()
        }
    };


    async function buscarListaTags() {
        try {
            let querys = `?cliente_id=${user.clienteId}`
            const response = await v2_actionListaTagsChats(querys);
            setListaTags(response.data.data)
        } catch (error) {

        }
    }

    async function buscarLeads() {
        try {
            let querys = `?usuario_id=${user.id}&chat_id=${chat._id}`
            const response = await v2_actionListaLeadsPorChat(querys);
            setListaLeads(response.data.data)
        } catch (error) {
        }
    }

    useEffect(() => {
        buscarLeads();
        buscarListaTags();

    }, [chat, value]);



    return (
        <RootStyle

            {...other}
        >
            <Tabs
                onValueChange={value => {
                    setValue(value)
                }}
                defaultValue={0}
                value={value}
            >
                <TabsList className="grid bg-slate-200 w-full grid-cols-4">
                    <TabsTrigger value={0}>Chat</TabsTrigger>
                    {chat?.contato_id && <TabsTrigger value={1}>Contato</TabsTrigger>}
                    <TabsTrigger value={2}>Negócios</TabsTrigger>
                    <TabsTrigger value={3}>Notas</TabsTrigger>

                </TabsList>
                {/* {SCROLLABLE_TAB.map((panel, index) => (
              <TabsContent key={panel.value} value={String(index + 1)}>
                <Scrollbar style={{ height: 'calc(100vh - 200px)' }}>
                  {panel.component}
                </Scrollbar>
              </TabsContent>
            ))} */}

                <Box sx={{ p: 1.5 }}>
                    <TabsContent value={0}>
                        <ChatInfoContato
                            cliente={cliente}
                            chat={chat}
                            listaTags={listaTags}
                            isCollapse={showInfo}
                            onCollapse={() => setShowInfo((prev) => !prev)}
                        />
                    </TabsContent>

                    <TabsContent value={1}>

                        {chat?.contato && (
                            <VerContatoForm
                                callBackRefreshList={() => {
                                    dispatch(getChatInfoByChatId(chat._id));
                                }}
                                contatoData={chat?.contato}
                                cliente={cliente}
                                showSaveButton={true}
                                hideSelectEmpresa={true}
                                showSignupButton={false}
                                loadingNovoContato={false}
                               // callbackAddContact={data => handleCriarContato(data)}
                            />
                        )}
                    </TabsContent>


                    <TabsContent value={2}>
                        <ChatInfoNegocios
                            chat={chat}
                            user={user}
                            cliente={cliente}
                            callbackRefresh={() => buscarLeads()}
                            listaLeads={listaLeads}
                        />
                    </TabsContent>
                    <TabsContent value={3}>
                    <ChatInfoNotas
                        chat={chat}
                        user={user}
                    />
                </TabsContent>
                </Box>
            </Tabs>
        </RootStyle>
    );
}
