
import { Box, Stack, Typography, Divider } from '@mui/material';
import Iconify from 'src/components/Iconify';
import LabelIcon from 'src/components/LabelIcon';

import { Card, CardContent } from 'src/@/components/ui/card';

import { Draggable } from 'react-beautiful-dnd';
import { fDateTime, fDateTimeSuffix, fToNow } from 'src/utils/formatTime';

const labels = {
    1: 'Sem interesse',
    2: 'Frio',
    3: 'Morno',
    4: 'Esquentando',
    5: 'Quente',
};


export default function NegocioCard({ card, onOpen }) {
    console.log('card', card)
    return (

        <Card
            onClick={onOpen}
            className="cursor-pointer p-3"
        >


            <Stack spacing={1} direction="column">


                <Stack justifyContent={'space-between'} spacing={1} direction="row">

                    <Stack direction="column" spacing={1}>
                        <Stack direction={"row"} alignItems={"center"}>
                            <Stack direction={"row"} spacing={1}>
                                <LabelIcon icon="ph:funnel-duotone">{card?.nomeFunil}</LabelIcon>
                                <Iconify width={16} icon="mdi:arrow-right-thin" />
                                <LabelIcon icon="mdi:funnel-cog">{card?.nomeEstagio}</LabelIcon>
                            </Stack>


                        </Stack>

                        <LabelIcon color="warning" icon="mdi:fire">{labels[card?.qualificacao]}</LabelIcon>

                    </Stack>

                    {/* <Stack spacing={.5} direction="row">
                                    <Typography sx={{ fontSize: 10 }}>{card && fToNow(card?.data_modificacao)}</Typography>
                                    <Iconify icon="ri:time-fill" />
                                </Stack> */}


                </Stack>

                <Divider sx={{ my: 0.5, borderStyle: 'dashed' }} />


                {/* <Avatar src={chat.info && chat.info.avatar ? chat.info.avatar : defaultImage} alt={"Foto perfil"} sx={{borderRadius:1}} /> */}
                <Stack onClick={onOpen} direction={'column'}>

                    <Typography variant='subtitle2' textAlign={"left"}>{card?.nome}</Typography>

                  
                    {card?.contato && (
                        <>
                          <Divider sx={{ my: 2, borderStyle: 'dashed' }} />
                          <Stack direction={"row"} spacing={1}>
                            <Stack alignItems={"center"} spacing={0.5} direction="row">
                                <Iconify width={12} icon="mdi:account-circle" />
                                <Typography textAlign={'left'} sx={{ fontSize: 12 }}>{card?.contato?.nome}</Typography>
                            </Stack>

                            <Stack alignItems={"center"} spacing={0.5} direction="row">
                                <Iconify width={12} icon="ic:baseline-whatsapp" />
                                <Typography textAlign={'left'} sx={{ fontSize: 12 }}>{card?.contato?.telefone}</Typography>
                            </Stack>
                        </Stack>
                        </>
             
                    )}

                </Stack>

            </Stack>

        </Card>

    )
}


















