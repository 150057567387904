import axios from "axios";

import Config from "../../../config";

const getRoomsWithoutIsGroup = () => {
    return axios({
        method: "post",
        url: (Config.urlWs || '') + "/api/rooms/list-rooms-without-rule-group",
        data: { limit: 30 }
    });
};

export default getRoomsWithoutIsGroup;
