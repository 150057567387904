import axios from "axios";
import Config from "../../../config";

const v2_actionExportarContatos = (clienteId) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/cadastro/contatos/${clienteId}/exportar`,
        responseType: 'blob'
    });
};

export default v2_actionExportarContatos;
