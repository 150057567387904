import axios from "axios";
import Config from "src/config";

const v2_actionListaVozes = (clienteId, querys) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/${clienteId}/ai/vozes${querys ? querys : ''}`
        });
};

export default v2_actionListaVozes;
