// material
import { Input, Divider, IconButton, InputAdornment, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useRef, useState } from 'react';

import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  minHeight: 46,
  display: 'flex',
  position: 'relative',
  alignItems: 'center',
  px: theme.spacing(2),
  border: '1px solid #666',
  borderRadius: 5
}));

// ----------------------------------------------------------------------

ChatInfoNotasInput.propTypes = {
  disabled: PropTypes.bool,
  conversationId: PropTypes.string,
  onSend: PropTypes.func
};

export default function ChatInfoNotasInput({ disabled, changeText, text, conversationId, onSend, onOpenMenu, ...other }) {
  const fileInputRef = useRef(null);
  const [message, setMessage] = useState('');


  const handleKeyUp = (event) => {
    if (event.key === 'Enter') {
      onSend()
    }
  };


  return (
    <RootStyle {...other}>
      

      <Input
        disabled={disabled}
        fullWidth
        value={text}
        disableUnderline
        onKeyUp={handleKeyUp}
        onChange={changeText}
        multiline
        maxRows={3}
        placeholder="Escreva algo..."
        sx={{ height: '100%', fontWeight: '500', fontSize: 14,p:1 }}
      />

      <Divider orientation="vertical" flexItem />

      <Stack sx={{mx:1}} direction="row" spaicng={1}>



        <IconButton color="primary" disabled={!text} onClick={onSend}>
          <Iconify width={20} icon="mdi:send" />
        </IconButton>

      </Stack>



    </RootStyle>
  );
}
