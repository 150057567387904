import { Box, Typography, Link } from '@mui/material';
import { isString } from 'lodash';
import PropTypes from 'prop-types';

// material
//
import { MBreadcrumbs } from './@material-extend';
import { Container, IconButton, Stack } from '@mui/material';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

Breadcrumbs.propTypes = {
  links: PropTypes.array,
  action: PropTypes.node,
  heading: PropTypes.string.isRequired,
  moreLink: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  sx: PropTypes.object
};

export default function Breadcrumbs({ backButton, links, action, heading, moreLink = '' || [], sx, ...other }) {
  return (

      <Box sx={{ mb: 1, ...sx }}>
        <Stack spacing={3} direction="row" alignItems={'center'}>
          {backButton && (
            <IconButton sx={{ background: theme => theme.palette.grey[500], borderRadius: 3 }} onClick={backButton}>
              <Iconify icon="typcn:arrow-back" color="#fff" width={18} height={18} />
            </IconButton>
          )}

          <MBreadcrumbs links={links} {...other} />
        </Stack>
      </Box>

  );
}
