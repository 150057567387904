import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';

import { Button } from 'src/@/components/ui/button';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';

export default function ModalConfimacao({ open, mensagem, onClose, onConfirm, loadingFinalizar }) {


  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <p className="text-md text-muted-foreground">
            {mensagem}
          </p>

        </DialogContent>
        <DialogActions>
          <Button variant="ghost" onClick={onClose}>Cancelar</Button>
          <ButtonLoading loading={loadingFinalizar} onClick={onConfirm} autoFocus>Confirmar</ButtonLoading>
        </DialogActions>
      </Dialog>
    </div>
  );
}
