import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "src/@/components/ui/table"

import {
  Stack
} from '@mui/material';

import Iconify from "src/components/Iconify";
import { fDate } from "src/utils/formatTime";
import { Button } from "src/@/components/ui/button";

export default function TableSetores({ data, onEdit, onDelete }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>Nome</TableHead>
          <TableHead>Data Criação</TableHead>
          <TableHead className="w-[100px]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell>{row.nome_setor}</TableCell>
            <TableCell>{fDate(row.data_criacao)}</TableCell>
            <TableCell>
              <Stack direction="row" spacing={1}>
                <Button onClick={() => onEdit(row)} variant="outline" size="icon" >
                  <Iconify icon={"ph:pencil-line"} />
                </Button>
                <Button onClick={() => onDelete(row)} variant="outline" size="icon" >
                  <Iconify color="red" icon={"material-symbols-light:delete-outline"} />
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
