
import { Box, Stack, Typography, Divider, Badge, Popover, IconButton } from '@mui/material';
import Iconify from 'src/components/Iconify';
import LabelIcon from 'src/components/LabelIcon';
import { useState } from 'react';

import { Draggable } from 'react-beautiful-dnd';
import { fDateTime, fDateTimeSuffix, fToNow } from 'src/utils/formatTime';
import { formataNumeroCelular } from 'src/utils/functions';

const labels = {
    1: 'Sem interesse',
    2: 'Frio',
    3: 'Morno',
    4: 'Esquentando',
    5: 'Quente',
};


export default function NegociosCard({ onOpenDrawerWhatsapp, card, index, onOpen }) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [anchorElConversao, setAnchorElConversao] = useState(null);
    const open = Boolean(anchorEl);
    const openElConversao = Boolean(anchorElConversao);

    const handlePopoverOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handlePopoverClose = () => {
        setAnchorEl(null);
    };


    function validaColorStatusConversao() {
        switch (card?.conversao) {
            case 1:
                return 'green'
            case 2:
                return 'red'
            default:
                return 'red'
        }
    }



    return (
        <Draggable draggableId={card?._id} index={index}>
            {(provided) => (
                <div {...provided.draggableProps} {...provided.dragHandleProps} ref={provided.innerRef}>
                    <Box

                        sx={{
                            textAlign: 'center',
                            border: '1px solid #ddcdff',
                            borderRadius: 1,
                            p: 1,
                            background: '#fff',
          
                        }}>

                        <Stack spacing={1} direction="column">
                            <Box onClick={onOpen}>
                                <Stack justifyContent={'space-between'} spacing={1} direction="row">

                                    <Stack direction="row" spacing={1}>
                                        <LabelIcon color="warning" icon="mdi:fire">{labels[card?.qualificacao]}</LabelIcon>
                                        <LabelIcon icon="ph:funnel-duotone">{card?.nomeEstagio}</LabelIcon>
                                    </Stack>
                                    <Stack spacing={.5} direction="row">
                                    <p className='text-[10px]'>{card && fToNow(card?.data_modificacao)}</p>
                                    </Stack>
                                </Stack>
                                <Divider sx={{ my: 1, borderStyle: 'dashed' }} />
                                <div className='text-left'>
                                        <p className='text-xs'>{card?.nome}</p>
                                    </div>

                            </Box>
                            <Divider sx={{ my: 0, borderStyle: 'dashed' }} />

                            <Box sx={{ cursor: 'default' }}>

                                <Stack justifyContent={'space-between'} spacing={2} direction="row">

                                    <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                        {card?.whatsapp && (
                                            <>
                                                <Box
                                                    sx={{
                                                        color: 'action.active',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        '& > *': {
                                                            marginBottom: 0,
                                                        },
                                                        '& .MuiBadge-root': {
                                                            marginRight: 0,
                                                        }
                                                    }}
                                                >
                                                    {/* <Badge
                                   color="primary"
                                   badgeContent={card?.whatsapp?.novaMensagem === "sim" ? true : false}
                               > */}
                                                    <IconButton onClick={() => {
                                                        onOpenDrawerWhatsapp(card?.whatsapp, card?.clienteWhatsapp)
                                                    }}>
                                                        <Iconify width={18} height={18} icon="ri:whatsapp-fill" />
                                                    </IconButton>
                                                    {/* </Badge> */}
                                                </Box>



                                                <Stack sx={{ pt: 1 }} direction="column" spacing={0.5}>
                                                    <div className='text-left'>
                                                        <p className='text-[10px]'>{card?.whatsapp?.lastUpdate && fToNow(card?.whatsapp?.lastUpdate)}</p>
                                                    </div>

                                                </Stack>
                                            </>
                                        )}
                                    </Stack>



                                    <Stack alignItems={"center"} direction={"row"} spacing={1}>

                                        {card?.contato && card.contato && (
                                            <Stack>
                                                <Box
                                                    sx={{
                                                        pt: .5,
                                                        color: 'action.active',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        '& > *': {
                                                            marginBottom: 0,
                                                        },
                                                        '& .MuiBadge-root': {
                                                            marginRight: 0,
                                                        },
                                                        '& .MuiBadge-badge': {
                                                            width: 1.5,
                                                            height: 6
                                                        },
                                                    }}
                                                >

                                                    <Iconify
                                                        aria-owns={open ? 'mouse-over-popover' : undefined}
                                                        aria-haspopup="true"
                                                        onMouseEnter={handlePopoverOpen}
                                                        onMouseLeave={handlePopoverClose}
                                                        width={22} height={22} icon="material-symbols-light:person" />

                                                </Box>

                                                <Popover
                                                    id="mouse-over-popover"
                                                    sx={{
                                                        pointerEvents: 'none',
                                                    }}
                                                    open={open}
                                                    anchorEl={anchorEl}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={handlePopoverClose}
                                                    disableRestoreFocus
                                                >
                                                    <Box sx={{ width: 200, height: 100, p: 2 }}>
                                                        <Stack direction="column" spacing={0.5}>
                                                            {card?.contato && (
                                                                <Stack direction={"column"} spacing={1}>
                                                                    <Stack alignItems={"center"} spacing={0.5} direction="row">
                                                                        <Iconify width={12} icon="mdi:account-circle" />
                                                                        <Typography textAlign={'left'} sx={{ fontSize: 12 }}>{card?.contato?.nome}</Typography>
                                                                    </Stack>

                                                                    <Stack alignItems={"center"} spacing={0.5} direction="row">
                                                                        <Iconify width={12} icon="ic:phone" />
                                                                        <Typography textAlign={'left'} sx={{ fontSize: 12 }}>{card?.contato?.telefone && formataNumeroCelular(card?.contato?.telefone)}</Typography>
                                                                    </Stack>

                                                                    <Stack alignItems={"center"} spacing={0.5} direction="row">
                                                                        <Iconify width={12} icon="ic:email" />
                                                                        <Typography textAlign={'left'} sx={{ fontSize: 12 }}>{card?.contato?.info?.email}</Typography>
                                                                    </Stack>
                                                                </Stack>
                                                            )}
                                                        </Stack>
                                                    </Box>

                                                </Popover>
                                            </Stack>

                                        )}

                                        {card?.convertido && (


                                            <Stack alignItems={"center"} direction={"row"} spacing={1}>
                                                <Box
                                                    sx={{
                                                        pt: .5,
                                                        color: 'action.active',
                                                        display: 'flex',
                                                        flexDirection: 'column',
                                                        '& > *': {
                                                            marginBottom: 0,
                                                        },
                                                        '& .MuiBadge-root': {
                                                            marginRight: 0,
                                                        },
                                                        '& .MuiBadge-badge': {
                                                            width: 1.5,
                                                            height: 6
                                                        },
                                                    }}
                                                >

                                                    <Iconify
                                                        aria-owns={openElConversao ? 'mouse-over-popover' : undefined}
                                                        aria-haspopup="true"
                                                        color={card?.convertido === 1 ? 'green' : 'red'}
                                                        onMouseEnter={event => setAnchorElConversao(event.currentTarget)}
                                                        onMouseLeave={event => setAnchorElConversao(event.null)}
                                                        width={18} height={18} icon="streamline:subscription-cashflow-solid" />

                                                </Box>

                                                <Popover
                                                    id="mouse-over-popover"
                                                    sx={{
                                                        pointerEvents: 'none',
                                                    }}
                                                    open={openElConversao}
                                                    anchorEl={anchorElConversao}
                                                    anchorOrigin={{
                                                        vertical: 'bottom',
                                                        horizontal: 'left',
                                                    }}
                                                    transformOrigin={{
                                                        vertical: 'top',
                                                        horizontal: 'left',
                                                    }}
                                                    onClose={event => setAnchorElConversao(null)}
                                                    disableRestoreFocus
                                                >
                                                    <Box sx={{ width: 200, height: 50, p: 2 }}>

                                                        <Typography textAlign={'left'} sx={{ fontSize: 12 }}>{card?.convertido === 1 ? "Marcado como Venda" : "Marcado como Perda"}</Typography>

                                                    </Box>

                                                </Popover>
                                            </Stack>
                                        )}


                                    </Stack>

                                </Stack>

                            </Box>

                        </Stack>

                    </Box>

                </div>
            )}
        </Draggable>
    )
}


















