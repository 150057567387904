import axios from "axios";

import Config from "../../config";

const actionDescontinuarAgendamento = campanhaId => {
    return axios({
        method: "delete",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/agendamentos/${campanhaId}/deletar`
    });
};

export default actionDescontinuarAgendamento;
