import PropTypes from 'prop-types';
import { useState, useRef, useEffect, useCallback } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
  Box,
  Stack,
  Drawer,
  Button,
  Avatar,
  Tooltip,
  Divider,
  MenuItem,
  TextField,
  Typography,
  OutlinedInput,
  Grid,
  Container,
  Card,
  Tab,
  Tabs,
  Autocomplete,
  RadioGroup,
  Radio,
  FormControl,
  FormControlLabel,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// hooks
import useResponsive from 'src/hooks/useResponsive';
// components
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { IconButtonAnimate } from 'src/components/animate';
//
import KanbanTaskCommentList from './KanbanTaskCommentList';
import ChamadosArquivos from './ChamadosArquivos'
import KanbanTaskCommentInput from './KanbanTaskCommentInput';

//icons
import { BsFillXOctagonFill } from "react-icons/bs";

import { getVerChamado, getListaTickets, getRefreshComments } from 'src/redux/slices/chamados';
import { useDispatch } from 'react-redux';

//actions
import actionCriarComentario from 'src/actions/chamados/comentarios/actionCriarComentario';
import actionEditarChamado from 'src/actions/chamados/actionEditarChamado';


import ModalConfimacao from 'src/components/modal/ModalConfimacao';
import TimelinePadrao from 'src/components/chamados/TimelinePadrao';
import Editor from './ChamadosNovoChamado/editor';
import useAuth from 'src/hooks/useAuth';
import Markdown from 'src/components/Markdown';
import getActionListaUsuariosByClienteId from 'src/actions/usuarios/getActionListaUsuariosByClienteId';
import v2_actionUploadS3 from 'src/actions/v2/global/actionUploadS3';
import actionListaInputsPrioridade from 'src/actions/chamados/actionListaInputsPrioridade';
import { DateTimePicker } from '@mui/x-date-pickers';
import { NAVBAR } from 'src/config';
import InputStyle from 'src/components/InputStyle';


// ----------------------------------------------------------------------

const PRIORITIZES = ['imediato', 'normal',];

const LabelStyle = styled(Typography)(({ theme }) => ({
  ...theme.typography.body2,
  width: 140,
  fontSize: 13,
  flexShrink: 0,
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ px: 0.5, py: 1 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


ChamadosDetalhes.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  chamado: PropTypes.object,
  comentarios: PropTypes.array
};

export default function ChamadosDetalhes({
  chamado,
  comentarios,
  isOpen,
  onClose,
}) {
  const isDesktop = useResponsive('up', 'sm');
  const dispatch = useDispatch()

  const { user } = useAuth();

  const [openConfirmaFinalizacao, setOpenConfirmaFinalizacao] = useState(false);
  const [openConfirmaExlusaoArquivos, setOpenConfirmaExlusaoArquivos] = useState(false);


  // files
  const [files, setFiles] = useState([]);
  const filesUrl = []
  const [filesEdit, setFilesEdit] = useState([]);
  const [preview, setPreview] = useState(true);
  const [isFile, setIsFile] = useState(false);
  const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditFile, setIsEditFile] = useState(true);

  const [tabDetalhesChamado, setTabDetalhesChamado] = useState(0)

  const [attachments, setAttachments] = useState([]);
  const [prioridade2, setPrioridade] = useState(chamado && chamado.prioridade);
  const [completado, setCompletado] = useState(chamado && chamado.completado)
  const [titulo, setTitulo] = useState(chamado && chamado.titulo);
  const [chamadoId, setChamadoId] = useState('');
  const [descricaoOld, setDescricaoOld] = useState('');
  const [descricao, setDescricao] = useState('');
  const [isMe, setIsMe] = useState(false);
  const [forMe, setForMe] = useState(null);
  const [isSimple, setIsSimple] = useState(true);
  const [isChangeEditor, setIsChangeEditor] = useState(null);

  const [dataChamado, setDataChamado] = useState('');

  const [listaUsuariosBackoffice, setListaUsuariosBackoffice] = useState([]);
  const [listaInputsPrioridade, setListaInputsPrioridade] = useState([]);
  const [emCopia, setEmCopia] = useState([]);
  const [actionCompleted, setActionCompleted] = useState(null);

  //comentarios
  const [texto, setTexto] = useState('');
  const [isMediaComentario, setIsMediaComentario] = useState(false);
  const [loadingComentario, setLoadingComentario] = useState(false);
  const [loadingFinalizar, setLoadingFinalizar] = useState(false);
  const [fileSelect, setFileSelect] = useState(null);
  const messagesEnd = useRef(null);
  const [dataConclusao, setDataConclusao] = useState('');
  const emCopiaReduce = emCopia.length > 0 && emCopia.map(item => item._id);
  const validaCopia = emCopia.length > 0 && emCopiaReduce.includes(user.id);

  console.log('emCopiaReduce', emCopiaReduce)
  console.log('validaCopia', validaCopia)

  const buscarUsuarios = async () => {
    try {
      const res = await getActionListaUsuariosByClienteId(user.clienteId);
      setListaUsuariosBackoffice(res.data.usuarios)
    } catch (error) {
      console.log(error)
    }
  }

  const buscarInputsPrioridade = async () => {
    try {
      const res = await actionListaInputsPrioridade(user.clienteId);
      setListaInputsPrioridade(res.data.data)
    } catch (error) {
      console.log(error)
    }
  }

  function validaEdicao() {
    if (!isMe) return true
    if (isMe && !completado) {
      return false
    } else {
      return true
    }
  }

  useEffect(() => {
    buscarUsuarios();
    buscarInputsPrioridade()
    if (chamado) {
      setChamadoId(chamado._id)
      setDescricaoOld(chamado.descricao)
      setDescricao(chamado.descricao);
      setCompletado(chamado.completado);
      setPrioridade(chamado.prioridade)
      setTitulo(chamado.titulo);
      setDataConclusao(new Date(chamado.data_para_conclusao));
      setDescricao(chamado.descricao);
      setEmCopia(chamado.copiado_para);
      setDataChamado(chamado)
      setFilesEdit(chamado.arquivos)

      if (user.id === chamado.criado_por) {
        setForMe(false);
      } else {
        setForMe(true);
      }
    }
  
    scrollToBottom();

   return () => {
    setForMe(null)
   }

  }, [chamado, isOpen, isMe]);

  const handleCloseDetails = () => {
    dispatch(getListaColunas({ setorId: user.user.setor_id, usuarioId: user.id, filial: user.user.filial }));
    dispatch(getListaTickets({ setorId: user.user.setor_id, usuarioId: user.id, filial: user.user.filial }));
    dispatch(changeOpenDetalhesChamado(false))
  };


  const onFecharDetalhesChamado = async () => {
    if (descricao !== descricaoOld) {
      await onEditarDescricao();
      setIsChangeEditor(null);
    }
    onClose();

    // setDescricao('');
    // setIsMe(false);
    // setTitulo('');
  }

  const scrollToBottom = () => {
    if (messagesEnd.current) {
      messagesEnd.current.scrollTop = messagesEnd.current.scrollHeight;
    }
  }

  const limparCamposComentarios = () => {
    setLoadingComentario(false);
    setTexto('');
    const filesUrl = []

  }


  const handleKeyUp = (event) => {

    if (!event.shiftKey && event.key === 'Enter') {
      onCriarComentario()
    }
  };

  const onCriarComentario = async () => {
    setLoadingComentario(true)
    try {
      const data = {
        autorId: user.id,
        isMedia: filesUrl.length > 0 ? true : false,
        texto: texto,
        files: filesUrl,
        status: true,
        descontinuado: false
      }
      await actionCriarComentario(chamado._id, data);
      dispatch(getRefreshComments(chamado._id));
      limparCamposComentarios()
    } catch (e) {
      console.log('Erro ao enviar comentário', e)
      setLoadingComentario(false)
    }
  }

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
      let filesSend = acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) }))
      onEditarArquivosChamados(chamadoId, filesSend);
    },
    [setFiles, chamadoId]
  );

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };




  const handleRemoveEdit = async (file) => {
    setLoadingFinalizar(true);
    const filteredItems = filesEdit.filter((_file) => _file !== file);
    let data = {
      action: 'editar_arquivos',
      arquivos: filteredItems
    }
    await actionEditarChamado(chamado._id, data);
    setFilesEdit(filteredItems);
    setLoadingFinalizar(false);
    setOpenConfirmaExlusaoArquivos(false);
    setFileSelect(null);
  };

  const handleChangeIsFile = (event) => {
    setIsFile(event.target.checked);
  };

  async function uploadAndGetUrl(file) {
    var formData = new FormData();
    var imagefile = file;
    formData.append("file", imagefile);
    try {
      let querys = `?path=chamados/clientes/${user.clienteId}/comentarios`
      const response = await v2_actionUploadS3(formData, querys)
      if (!response.data.error) {
        console.log('!response.data.error')
        filesUrl.push({ key: response.data.data.key, type: file.type, name: file.name, size: file.size })
      }
    } catch (error) {
      console.log('erro ao upload arquivo', error)
    }
  }


  async function uploadFilesAndGetUrl(files) {
    if (files.length > 0) {
      for (let file of files.map(item => item)) {
        var formData = new FormData();
        var imagefile = file;
        formData.append("file", imagefile);
        try {
          let querys = `?path=chamados/clientes/${user.clienteId}/anexos`
          const response = await v2_actionUploadS3(formData, querys)
          if (!response.data.error) {
            console.log('!response.data.error')
            filesUrl.push({ key: response.data.data.key, type: file.type, name: file.name, size: file.size })
          }
        } catch (error) {
          console.log('erro ao upload arquivo', error)
        }
      }
    }
  }


  const onEditarArquivosChamados = async (chamadoId, files) => {
    await uploadFilesAndGetUrl(files);
    let data = {
      action: 'add_arquivos',
      arquivos: filesUrl
    }
    const res = await actionEditarChamado(chamadoId, data);
    if (res) {
      let arquivos = res.data.data.arquivos;
      setFilesEdit(arquivos)
    }
  }


  const handleToggleCompleted = async (action) => {
    setActionCompleted(action);
    setOpenConfirmaFinalizacao(true);

  };

  const onFinalizarChamado = async () => {
    setLoadingFinalizar(true)
    try {
      let data = {
        action: actionCompleted,
        completado: true
      }
      await actionEditarChamado(chamado._id, data);
      dispatch(getVerChamado(chamado._id));
      setOpenConfirmaFinalizacao(false)
      dispatch(getListaTickets({ setorId: user.user.setor_id, usuarioId: user.id, filial: user.user.filial }));
      setLoadingFinalizar(false);
      setActionCompleted(null);
    } catch (error) {
      console.log('error', error);
      setLoadingFinalizar(false);
    }
  };


  const onEditarPrioridade = async (event) => {
    console.log('onEditarPrioridade', event)
    setDataConclusao(event);
    try {
      let data = {
        action: 'editar_estimativa',
        data_para_conclusao: event
      }
      await actionEditarChamado(chamado._id, data);
      dispatch(getVerChamado(chamado._id));
      dispatch(getListaTickets({  setorId: user.setorId, usuarioId: user.id, filial: null }));
    } catch (error) {
      console.log('error', error)
    }
  };

  const onEditarTitulo = async () => {
    try {
      let data = {
        action: 'editar_titulo',
        titulo: titulo
      }
      await actionEditarChamado(chamado._id, data);
      dispatch(getVerChamado(chamado._id));
      dispatch(getListaTickets({ setorId: user.setorId, usuarioId: user.id, filial: null }));
    } catch (error) {
      console.log('error', error)
    }
  };

  const onEditarDescricao = async () => {
    try {
      let data = {
        action: 'editar_descricao',
        descricao: descricao
      }
      if (descricao !== descricaoOld) {
        await actionEditarChamado(chamado._id, data);
        dispatch(getVerChamado(chamado._id));
        dispatch(getListaTickets({  setorId: user.setorId, usuarioId: user.id, filial: null }));
      }

    } catch (error) {
      console.log('error', error)
    }
  };

  const onSetarArquivo = async (e) => {
    if (e.target.files.length > 0) {

      let files1 = e.target.files;
      setIsMediaComentario(true)
      await uploadAndGetUrl(e.target.files[0]);
      await onCriarComentario()

    }
  };

  const handleChangeTabsDetalhes = (event, newValue) => {
    setTabDetalhesChamado(newValue);
  };


  const onEditarCopiadoPara = async (newValue) => {
    let copiadoParaRedeuce = newValue.map(item => item._id);
    setEmCopia(newValue);
    let data = {
      action: 'editar_copiado_para',
      copiadoPara: copiadoParaRedeuce
    }
    try {
      await actionEditarChamado(chamado._id, data);
    } catch (err) {
      console.log('err', err)
    }
  }



  return (
    
      <Drawer sx={{}} open={isOpen} onClose={onFecharDetalhesChamado} anchor="right" PaperProps={{ sx: { bgcolor: 'background.neutral',  overflow:'hidden', width: { xs: 1, sm: '100%' } } }}>
        <Container maxWidth="none" SX={{height: `calc(100vh)`}}>
          <Stack p={1.5} spacing={2} direction="row" alignItems="center">

            <Stack direction="row" spacing={2} justifyContent="flex-start" alignItems={'center'} flexGrow={1}>
              <Tooltip title="Fechar">
                <IconButtonAnimate onClick={onFecharDetalhesChamado} size="small">
                  <Iconify icon={'eva:arrow-ios-back-fill'} width={30} height={30} />
                </IconButtonAnimate>
              </Tooltip>
              <Typography variant="h6">Chamado #{chamado && chamado.chamado}</Typography>


               </Stack>
          </Stack>
        </Container>


        <Container sx={{ mb: 5 }} maxWidth="none">

          <ModalConfimacao
            open={openConfirmaFinalizacao}
            onClose={() => setOpenConfirmaFinalizacao(false)}
            onConfirm={onFinalizarChamado}
            loadingFinalizar={loadingFinalizar}
            mensagem={'Deseja mesmo finalizar esse chamado'}
          />

          <ModalConfimacao
            open={openConfirmaExlusaoArquivos}
            onClose={() => setOpenConfirmaExlusaoArquivos(false)}
            onConfirm={() => handleRemoveEdit(fileSelect)}
            loadingFinalizar={loadingFinalizar}
            mensagem={'Deseja mesmo excluir esse arquivo?'}
          />



          <Grid container spacing={2}>
            <Grid item md={7} xs={12}>
              <Card sx={{ height: '90vh', minHeight: '90vh' }}>
                <Scrollbar >
                  <Stack p={2.5} spacing={2} direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems="center">
                    <InputStyle
                      fullWidth
                      disabled={isMe ? false : true}
                      size="small"
                      placeholder="Insira um nome para o Ticket"
                      value={titulo}
                      onChange={e => setTitulo(e.target.value)}
                      onBlur={onEditarTitulo}
              
                    />

                    <Stack spacing={2} direction={'row'}>
                      {!completado && forMe && !validaCopia && (
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{ px: 3, background: "#f03", color: "#fff" }}
                          color={completado ? 'primary' : 'inherit'}
                          startIcon={!completado && <BsFillXOctagonFill color="#fff" sx={{ p: 2 }} size={12} />}
                          onClick={() => !completado && handleToggleCompleted('recusar_chamado')}
                        >
                          Recusar
                        </Button>
                      )}

                      {!completado && !forMe && !validaCopia && (
                        <Button
                          size="small"
                          variant="outlined"
                          sx={{ px: 3, background: "#f03", color: "#fff" }}
                          color={completado ? 'primary' : 'inherit'}
                          startIcon={!completado && <BsFillXOctagonFill color="#fff" sx={{ p: 2 }} size={12} />}
                          onClick={() => !completado && handleToggleCompleted('cancelar_chamado')}
                        >
                          Cancelar
                        </Button>
                      )}



                      {!completado && !validaCopia && (
                        <LoadingButton
                          size="small"
                          variant="contained"
                          sx={{ px: 2 }}
                          containded
                          loading={loadingFinalizar}
                          color={completado ? 'primary' : 'primary'}
                          startIcon={!completado && <Iconify icon={'eva:checkmark-fill'} width={16} height={16} />}
                          onClick={() => !completado && handleToggleCompleted('editar_completado')}
                        >
                          {completado ? 'Finalizado' : 'Finalizar'}
                        </LoadingButton>
                      )}
                    </Stack>




                  </Stack>
                  <Divider />
                  <Grid sx={{ p: 3 }} container spacing={2}>


                    <Stack>
                      <Grid md={12}>

                        <Stack spacing={2} sx={{ px: 1, py: 1 }}>
                          <Stack direction="row" spacing={1}>
                          <Stack  spacing={1} alignItems={{ xs: 'left', sm: 'left', md: 'center' }}  direction={{ xs: 'column', sm: 'column', md: 'row' }}>
                            <LabelStyle sx={{ textAlign: { xs: 'right', sm: 'right', md: 'right' } }}>Responsável</LabelStyle>
                            <Stack direction="row" flexWrap="wrap" alignItems="center">
                              <Typography sx={{ mr: 1, fontWeight: 'bold' }}>{chamado && chamado.nome_responsavel ? chamado.nome_responsavel : "Aguardando aceite..."}</Typography>
                            </Stack>
                          </Stack>

                          <Stack spacing={1} alignItems={{ xs: 'left', sm: 'left', md: 'center' }} direction={{ xs: 'column', sm: 'column', md: 'row' }}>
                            <LabelStyle sx={{ textAlign: { xs: 'right', sm: 'right', md: 'right' } }}>Quem Criou</LabelStyle>
                            <Stack direction="row" flexWrap="wrap" alignItems="center">
                              <Typography sx={{ mr: 1, fontWeight: 'bold' }}>{chamado && chamado.nome_autor}</Typography>
                            </Stack>
                          </Stack>
                          </Stack>
                          <Divider />

                          <Stack alignItems={{ xs: 'left', sm: 'left', md: 'center' }} spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row' }}>
                            <LabelStyle sx={{ textAlign: { xs: 'left', sm: 'left', md: 'center' } }}>Em Copia</LabelStyle>
                            <Stack direction={{ sm: 'column', md: 'row' }} sx={{ width: '100%' }} flexWrap="wrap" alignItems="center">
                              <Autocomplete
                                sx={{ mt: 2, width: '100%' }}
                                multiple
                                disabled={completado ? true : false}
                                id="size-small-outlined-multi"
                                options={listaUsuariosBackoffice}
                                value={emCopia}
                                fullWidth
                                onOpen={() => buscarUsuarios()}
                                onChange={(event, newValue) => {
                                  onEditarCopiadoPara(newValue)
                                }}
                                getOptionLabel={(option) => option.nomeFantasia + " (" + option.nome_setor + ")"}
                                renderInput={(params) => (
                                  <TextField {...params} size="small" fullWidth placeholder="Selecionar usuarios" />
                                )}
                              />
                            </Stack>
                          </Stack>

                          <Stack sx={{ alignItems: 'center' }} spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems={{ xs: 'left', sm: 'left', md: 'center' }}>
                            <LabelStyle sx={{ textAlign: { xs: 'left', sm: 'left', md: 'center' } }}>Estima de Resolução</LabelStyle>
                            <DateTimePicker
                                                    label="Data para Conclusão"
                                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                                    value={dataConclusao}
                                                    okText="Salvar"
                                                    closeOnSelect={false}
                                                    disabled={completado ? true : false}
                                                    onAccept={value => onEditarPrioridade(value)}
                                                   // minDateTime={new Date(dataConclusao)}
                                                    // onAccept={(newValue) => {console.log(newValue)}}
                                                    // onChange={(newValue) => {
                                                    //   //console.log('onEditarPrioridade', newValue)
                                                    //   onEditarPrioridade(newValue)
                                                    // }}
                                                    renderInput={(params) => <TextField   {...params} size="small" margin="normal" />}
                                                />
                            {/* <FormControl>
                              <RadioGroup
                                row
                                disabled={completado ? true : false}
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={prioridade2}
                                onChange={onEditarPrioridade}
                              >
                                {listaInputsPrioridade.map((item) => {
                                  return (
                                    <FormControlLabel key={item._id} value={item._id} control={<Radio disabled={completado ? true : false} />} label={`${item.label} (${item.descricao})`} />
                                  )
                                })}

                              </RadioGroup>
                            </FormControl> */}
                            {/* <TextField
                              fullWidth
                              select
                              size="small"
                              onOpen={() => buscarInputsPrioridade()}
                              value={prioridade2}
                              onChange={onEditarPrioridade}
                              sx={{
                                '& svg': { display: 'none' },
                                '& fieldset': { display: 'none' },
                                '& .MuiSelect-select': { p: 0, display: 'flex', alignItems: 'center' },
                              }}
                            >
                              {PRIORITIZES.map((option) => (
                                <MenuItem key={option._id} value={option._id} sx={{ mx: 1, my: 0.5, borderRadius: 1 }}>
                                <Box
                                    sx={{
                                      mr: 1,
                                      width: 14,
                                      height: 14,
                                      borderRadius: 0.5,
                                      bgcolor: 'error.main',
                                      ...(option === 'imediato' && { bgcolor: 'error.main' }),
                                      ...(option === 'normal' && { bgcolor: 'info.main' }),

                                    }}
                                  />
                                  <Typography variant="body2" sx={{ textTransform: 'capitalize' }}>
                                    {option.label}
                                  </Typography>
                                </MenuItem>
                              ))}
                            </TextField> */}
                          </Stack>

                          <Grid item md={12}>
                            <Stack sx={{ alignItems: 'center' }} spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems={{ xs: 'left', sm: 'left', md: 'center' }}>
                              <LabelStyle sx={{ textAlign: { xs: 'left', sm: 'left', md: 'center' } }}>Descrição</LabelStyle>
                              {completado ? (
                                <Box
                                  sx={{ borderWidth: 1, borderStyle: 'dashed', borderColor: '#ccc', borderRadius: 1, p: 3 }}
                                ><Markdown children={descricao} /> </Box>
                              ) : (
                                chamado && (
                                  <Editor
                                    value={descricao}
                                    onBlur={onEditarDescricao}
                                    readOnly={validaEdicao()}
                                    onChange={e => {
                                      setDescricao(e);
                                      setIsChangeEditor(true);
                                    }}
                                  //  disabled={isMe ? false : true}
                                  />
                                )

                              )}

                            </Stack>
                          </Grid>



                          <Stack spacing={3} direction="column" >
                            <Stack sx={{ alignItems: 'center' }} spacing={3} direction={{ xs: 'column', sm: 'column', md: 'row' }} alignItems={{ xs: 'left', sm: 'left', md: 'center' }}>
                              <LabelStyle sx={{ textAlign: { xs: 'left', sm: 'left', md: 'center' } }}>Arquivos</LabelStyle>
                              <ChamadosArquivos
                                isMe={isMe}
                                loadingEnviarArquivos={loadingEnviarArquivos}
                                showPreview={preview}
                                completado={completado}
                                files={files}
                                isEdit={isEdit}
                                isEditFile={isEditFile}
                                filesEdit={filesEdit}
                                onRemoveEdit={(file) => {
                                  setFileSelect(file);
                                  setOpenConfirmaExlusaoArquivos(true);
                                }}
                                onDrop={handleDropMultiFile}
                                onRemove={handleRemove}
                                attachments={attachments}
                              />
                            </Stack>
                          </Stack>
                        </Stack>
                      </Grid>
                    </Stack>
                  </Grid>
                </Scrollbar>
              </Card>
            </Grid>

            <Grid item md={5} xs={12}>

              <Stack sx={{ flex: 1, height: '90vh', minHeight: '90vh' }}>
                <Card >

                  <Box sx={{ m: 3 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <Tabs value={tabDetalhesChamado} onChange={handleChangeTabsDetalhes} aria-label="basic tabs example">
                        <Tab label="Comentários" {...a11yProps(0)} />
                        <Tab label="Atividades" {...a11yProps(1)} />
                      </Tabs>
                    </Box>
                    <TabPanel value={tabDetalhesChamado} index={0}>

                      <Scrollbar scrollableNodeProps={{ ref: messagesEnd }} sx={{ flexGrow: 1, height: '90vh', pb: 35, margin: 0 }}>
                        {chamado && (
                          <KanbanTaskCommentList
                            chamado={chamado}
                            comments={comentarios}
                          />
                        )}

                        <div ref={messagesEnd} />
                      </Scrollbar>
                      <Stack sx={{ background: '#fff', position: isDesktop ? 'absolute' : 'inherit', width: '90%', bottom: 0 }}>

                        <KanbanTaskCommentInput
                          completado={completado}
                          onFile={onSetarArquivo}
                          texto={texto}
                          onKeyPress={handleKeyUp}
                          loading={loadingComentario}
                          changeTexto={e => setTexto(e.target.value)}
                          onCriar={onCriarComentario}
                        />

                      </Stack>



                    </TabPanel>
                    <TabPanel value={tabDetalhesChamado} index={1}>
                      <Scrollbar scrollableNodeProps={{ ref: messagesEnd }} sx={{ flexGrow: 1, height: '90vh' }}>
                        <TimelinePadrao data={chamado && chamado.historico} />
                      </Scrollbar>
                    </TabPanel>
                  </Box>
                </Card>
              </Stack>
            </Grid>
          </Grid >
        </Container >
      </Drawer >
    

  );
}
