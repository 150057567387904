import axios from "axios";

import Config from "../../config";

const actionListaAgendamentos = (clienteId, usuarioId) => {
    console.log('chega2', clienteId, usuarioId)
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/agendamentos/${clienteId}/${usuarioId}/lista`,   
    });
};

export default actionListaAgendamentos;
