import {
    Box,
    Tabs,
    Tab,
    TabPanel,
    Stack
} from '@mui/material';
import * as React from 'react';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import TrainingConfig from './tabs/TrainingConfig';
// ----------------------------------------------------------------------



export default function ChannelDialogflow({
    canal,
    onChange,
    listSectors,
    listUsers
}) {

    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleChangeIndex = (index) => {
        setValue(index);
    };


    return (
        <>
            <Box sx={{ width: '25%' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    indicatorColor="secondary"
                    textColor="inherit"
                    variant="fullWidth"
                    aria-label="full width tabs example"
                >
                    <Tab label="Configurações"  />
                    <Tab label="Direcionamentos"  />
                </Tabs>

                </Box>

                {value === 0 && (
                     <Stack spacing={2} direction={"column"} sx={{ mt: 2 }}>
                     <RowInput
                         label={"private key"}
                         input={<InputStyle
                             size="small"
                             value={canal?.config?.dialogflow?.privateKey}
                             fullWidth
                             name="dialogflowPrivateKey"
                             onChange={onChange}
                         />}
                     />
 
                     <Stack direction={"row"} spacing={2} alignItems={"center"}>
                         <RowInput
                             label={"client email"}
                             input={<InputStyle
                                 autoFocus
                                 value={canal?.config?.dialogflow?.clientEmail}
                                 fullWidth
                                 name="dialogflowClientEmail"
                                 onChange={onChange}
                             />}
                         />
                         <RowInput
                             label={"project id"}
                             input={<InputStyle
                                 autoFocus
                                 type="text"
                                 value={canal?.config?.dialogflow?.projectId}
                                 fullWidth
                                 name="dialogflowProjectId"
                                 onChange={onChange}
                             />}
                         />
 
                     </Stack>
                 </Stack>
                )}
                {value === 1 && <TrainingConfig
            listSectors={listSectors}
            listUsers={listUsers}
            />}

              
        
        </>
    );
}

