import { List } from '@mui/material';
import PropTypes from 'prop-types';

// routes
import getActionMensagemLida from 'src/actions/chats/getActionMensagemLida';
import getActionAceitarChat from 'src/actions/chats/getActionAceitarChat';
import {
  getChatInfoByChatId,
  getChatsAtendimento,
  getChatsFinalizados,
  getChatsGeral,
  getMensagensWhatsappDb,
  getMessagesWhatsapp,
  setChatInfo,
  setSelectedMessage
} from 'src/redux/slices/chat';
import { useDispatch } from 'src/redux/store';

import useAuth from 'src/hooks/useAuth';
import { useSnackbar } from 'notistack';
import ChatAtendimentoItem from 'src/sections/@chats/chat/ChatsListItem/ChatAtendimentoItem';


ChatListPesquisas.propTypes = {
  query: PropTypes.string,
  results: PropTypes.array,
  onSelectContact: PropTypes.func
};

export default function ChatListPesquisas({ 
  results, 
  isOpenSidebar,
  callback
 }) {
  const { enqueueSnackbar } = useSnackbar();
  const { user, cliente } = useAuth();
  const dispatch = useDispatch();
  const isSuperVisor = user.level === "root";

  function buscarMessagesAndInfo(chat) {
    if (cliente.config.chat_source_db) {
      dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
    } else {
      dispatch(getMessagesWhatsapp(chat.sessao, chat.numeroCliente));
    }
    if (chat) {
      dispatch(setChatInfo(chat));
    } else {
      dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));
    }
  }

  const openWhatsappChat = async (chat) => {
    await getActionMensagemLida({ chatId: chat._id });
    dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
    dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));

    dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
    dispatch(setSelectedMessage({}));
  };

  function updateListAndReadMessage(chat) {
    getActionMensagemLida({ chatId: chat._id })
    dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
    dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, true));
  }

  const handlePegarChat = async (chat) => {
    try {
      if (chat?.direciona_usuarios.length === 0) {
        if (chat?.direciona_usuarios.includes(user.id)) {
          if (chat.statusRoom !== "Atendendo") {
            getActionAceitarChat({ chatId: chat?._id, usuarioId: user.id })
            dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));
            dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
            enqueueSnackbar('Chat aceito com sucesso', { variant: 'success' });
            callback()
            buscarMessagesAndInfo(chat)
          } else {
            if (isSuperVisor) {
              getActionAceitarChat({ chatId: chat?._id, usuarioId: user.id })
              dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));
              dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
              enqueueSnackbar('Chat aceito com sucesso', { variant: 'success' });
              callback()
              buscarMessagesAndInfo(chat)
            }
          }
        } else {

          if (isSuperVisor) {
            getActionAceitarChat({ chatId: chat?._id, usuarioId: user.id })
            dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));
            dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
            enqueueSnackbar('Chat aceito com sucesso', { variant: 'success' });
            callback()
            if (cliente.config.chat_source_db) {
              dispatch(getMensagensWhatsappDb(chat.sessao, chat.numeroCliente));
            } else {
              dispatch(getMessagesWhatsapp(chat.sessao, chat.numeroCliente));
            }
            if (chat) {
              dispatch(setChatInfo(chat));
            } else {
              dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, true));
            }
          } else {
            if (chat.statusRoom !== "Atendendo") {
              getActionAceitarChat({ chatId: chat?._id, usuarioId: user.id })
              dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));
              dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
              enqueueSnackbar('Chat aceito com sucesso', { variant: 'success' });
              callback()
              buscarMessagesAndInfo(chat)
            } else {
              enqueueSnackbar('Chat já está em atendimento com outro atendente', { variant: 'error' });
            }
          }

        }


      } else {

        getActionAceitarChat({ chatId: chat?._id, usuarioId: user.id })
        dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));
        dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
        enqueueSnackbar('Chat aceito com sucesso', { variant: 'success' });
        buscarMessagesAndInfo(chat)
        callback()

      }
    } catch (error) {
      console.log(error)
    }

  }

  const handleSelectConversation = async (chat) => {
    if (chat.direciona_usuarios.length === 0) {
      await getActionMensagemLida({ chatId: chat._id })
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
      dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, true));
    } else {
      if (isSuperVisor) {
        openWhatsappChat(chat);
        updateListAndReadMessage(chat)
      } else {
        if (chat.direciona_usuarios.includes(user.id)) {
            openWhatsappChat(chat);
          updateListAndReadMessage(chat)
        } else {
          enqueueSnackbar('Chat já está em atendimento com outro atendente', { variant: 'error' });
        }
      }

    }
  };

  return (
    <List>
      {results?.map(result => (
        <ChatAtendimentoItem
          user={user}
          searchItem={true}
          onSelectConversation={() => handleSelectConversation(result)}
          onAceitarChat={() => handlePegarChat(result)}
          isOpenSidebar={isOpenSidebar}
          chat={result}
          isSearch={true}
        />

      ))}
    </List>
  );
}
