import axios from "axios";

import Config from "../../../../config";

const v2_actionRelatorioChamados = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/dashboard/relatorios/chamados${querys}`
        });
};

export default v2_actionRelatorioChamados;
