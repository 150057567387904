import axios from "axios";

import Config from "src/config";

const actionBuscarConfigFunil = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/funil/config${querys}`
    });
};

export default actionBuscarConfigFunil;
