import {
  Box,
  Typography,
  Stack,
  Button,
  Divider,
  Container
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

//paginas
import DialogWhatsappConexao from './DialogWhatsappConexao';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import Iconify from 'src/components/Iconify';
import { formataNumeroCelular, verifyRules } from 'src/utils/functions';
import Label from 'src/components/Label';
import { useDispatch } from 'react-redux';
import v2_actionEditarCanal from 'src/actions/v2/cliente/canais/v2_actionEditarCanal';
import DialogNewChannel from './DialogNewChannel';
import { useNavigate } from 'react-router';
import v2_actionDeletarCanal from 'src/actions/v2/cliente/canais/v2_actionDeletarCanal';
import getActionClienteByClienteId from 'src/actions/clientes/getActionClienteByClienteId';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import { useEffect } from 'react';
import { CardContent, Card } from 'src/@/components/ui/card';

// ----------------------------------------------------------------------

CanaisComponent.propTypes = {
  canais: PropTypes.array,
};

export default function CanaisComponent({ user, canais, listaCanais, onConfig, onConnect, onButtonLoginFacebook, fbLogin, onCallback }) {
  const [openDialgoWhatsapp, setOpenDialgoWhatsapp] = useState(false)
  const [session, setSession] = useState('')
  const [canal, setCanal] = useState(null)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [openNewChannel, setOpenNewChannel] = useState(false)

  async function handleDesvincularCanal(canalId) {
    try {
      let data = {
        action: "desvincular_meta_instagram"
      }
      const response = await v2_actionEditarCanal(canalId, data)
      if (response.status === 200) {
        onCallback()
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    onCallback()
  }, [])

  async function renoveAStorageMultisession() {
    const responseCliente = await getActionClienteByClienteId(user.clienteId);
    const response_clientes_canais = await actionListaClientesCanais(user.clienteId);

    let listaNumerosSubs = response_clientes_canais.data.numeros_subs;
    const numeros_subs_reduce = listaNumerosSubs.map((item) => item.nome)

    const newClienteObj = {
      ...responseCliente.data.cliente,
      clientes_canais: responseCliente.data.cliente.multiSession === 1 ? response_clientes_canais.data.data : [],
      numeros_subs: responseCliente.data.cliente.multiSession === 1 ? numeros_subs_reduce : [],
      numeros_subs_data: responseCliente.data.cliente.multiSession === 1 ? listaNumerosSubs : []
    }

    localStorage.setItem('multiSession', responseCliente.data.cliente.multiSession);
    localStorage.setItem('marketplaceId', responseCliente.data.cliente.marketplace_id);
    localStorage.setItem('cliente_data', JSON.stringify(newClienteObj));
  }

  async function handleDeleteCanal(canalId) {
    try {
      const response = await v2_actionDeletarCanal(canalId)
      if (response.status === 200) {
        onCallback();
        renoveAStorageMultisession()
      }
    } catch (error) {
      console.log(error)
    }
  }

  return (
    <Container maxWidth="lg">

      <HeaderPageComponentConfig
        titulo="Canais"
        descricao={'Conecte seus canais de atendimento e gerencie tudo em um só lugar.'}
      //  actions={<Button variant='contained'>Teste</Button>}
      />


        <DialogNewChannel
          open={openNewChannel}
          onClose={() => setOpenNewChannel(false)}
          onSubmit={(value) => {
            setOpenNewChannel(false)
            navigate({
              pathname: '/dashboard/configuracoes/channelDetails',
              search: '?channelId=' + value + '&new=1'
            });
            onCallback()
            renoveAStorageMultisession()
          }}
        />

        <Box
          sx={{
            display: 'grid',
            gap: 1,
            flexDirection: 'row',
            mt:2,
            gridTemplateColumns: {
              xs: 'repeat(1, 1fr)',
              sm: 'repeat(3, 1fr)',
              md: 'repeat(4, 1fr)',
              lg: 'repeat(4, 1fr)',
            },
          }}
        >


          {listaCanais && listaCanais.map((item, index) => (

            <CanalCard
              user={user}
              onDelete={() => handleDeleteCanal(item._id)}
              botao={() => {
                if (item.platform === "instagram") {
                  onConnect()
                } else {
                  navigate({
                    pathname: '/dashboard/configuracoes/conectar-whatsapp',
                    search: '?channelId=' + item._id
                  });
                }
              }}
              fbLogin={<Button
                sx={{ backgroundColor: '#1877f2', borderRadius: 0.5 }}
                startIcon={<Iconify icon="mdi:facebook" />}
                size="small"
                variant="contained"
                onClick={() => onButtonLoginFacebook(item)}
              >
                Entrar com facebook
              </Button>}
              onConfig={() => {
                navigate({
                  pathname: '/dashboard/configuracoes/channelDetails',
                  search: '?channelId=' + item._id
                })
              }}
              canal={item}
              onDesvincular={() => handleDesvincularCanal(item._id)}
            />

          )
          )}
          <CardNewChannel onClick={() => setOpenNewChannel(true)} />

        </Box>
    </Container>
  );
}

const CardNewChannel = ({ onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        // backgroundColor: theme => theme.palette.grey[100],
        border: '2px dashed #e5e2f7',
        p: 2,
        ml: 1,
        borderRadius: 1,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        minWidth: 200,
        '&:hover': {
          backgroundColor: '#f5f5f5',
          cursor: 'pointer'

        }
      }}
    >

      <Stack direction="column" alignItems="center" justifyContent="center" spacing={2} >
        <Stack direction={"column"} spacing={1} justifyContent={"center"} alignItems={"center"}>
          <Iconify icon={"lets-icons:add-duotone"} width={30} height={30} />
          <Typography variant="subtitle2" color="text.primary">
            Adicionar novo canal
          </Typography>
        </Stack>
      </Stack>

    </Box>
  );

}

// ----------------------------------------------------------------------

CanalCard.propTypes = {
  item: PropTypes.object,
};

function CanalCard({ user, canal, botao, onConfig, fbLogin, onDelete, onDesvincular }) {

  function formatIcons(key) {
    switch (key) {
      case "whatsapp":
        return <Iconify icon={"dashicons:whatsapp"} width={16} height={16} />
      case "instagram":
        return <Iconify icon={"ri:instagram-fill"} width={16} height={16} />
      case "messenger":
        return <Iconify icon={"mingcute:messenger-fill"} width={16} height={16} />
      default:
        break;
    }

  }

  return (
    <Card className="bg-slate-100">
      <CardContent>
      <Stack className='mt-5' spacing={2}>
        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2} >
          <Stack direction={"row"} spacing={1} alignItems={"center"}>
            {formatIcons(canal.platform)}
            <Typography variant="subtitle2" color="text.primary">
              {canal.nome}
            </Typography>
          </Stack>

          {canal.platform === "whatsapp" && (
            <Stack textAlign={"center"}>
              <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                {formataNumeroCelular(canal.numero_whatsapp)}
              </Typography>
            </Stack>
          )}


        </Stack>
        <Divider sx={{ borderStyle: 'dashed' }}></Divider>

        <Stack sx={{ mt: 1, mb: 2 }}>
          <Stack direction={"column"} alignItems={"center"} spacing={1.5}>
            {canal.platform === "instagram" && (
              canal.status_connection_whatsapp === "conectado" ? (
                <Stack spacing={2}>
                  <Label variant="outlined" color={canal.status_connection_whatsapp === "conectado" ? "success" : "error"}>{canal.status_connection_whatsapp === "conectado" ? "Conectado" : "Desabilitado"}</Label>
                  {fbLogin}
                  {/* <Button onClick={onDesvincular} sx={{height:30, fontSize:12}} variant='contained'>
                    Desvincular
                  </Button> */}
                </Stack>
              ) : <>{fbLogin}</>
            )}
            {
              canal.platform === "whatsapp" && (
                canal.status_connection_whatsapp === "conectado" ? (
                  <Label variant="outlined" color={canal.status_connection_whatsapp === "conectado" ? "success" : "error"}>{canal.status_connection_whatsapp === "conectado" ? "Conectado" : "Desabilitado"}</Label>
                ) : (
                  <Button
                    variant="contained"
                    size="small"
                    sx={{ height: 24, borderRadius: 0.5, fontSize: 12, fontWeight: 600, textTransform: 'none' }}
                    onClick={botao}
                  >
                    Conectar
                  </Button>
                ))}

            {verifyRules(user.rules, "editar_canal") && (
              <Stack direction="row" spacing={1} >

                <Button
                  variant="default"
                  size="small"
                  sx={{ height: 20, borderRadius: 0.5, fontSize: 12, fontWeight: 600, textTransform: 'none' }}
                  onClick={onConfig}
                  startIcon={<Iconify icon="icon-park-outline:config" width={15} height={15} />}
                >
                  Configurar
                </Button>
                {user.clienteId !== canal.cliente_id && (
                  <Button
                    variant="default"
                    size="small"
                    sx={{ height: 20, borderRadius: 0.5, fontSize: 12, fontWeight: 600, textTransform: 'none' }}
                    onClick={onDelete}
                    startIcon={<Iconify color="red" icon="mi:delete" width={15} height={15} />}
                  >
                    Excluir
                  </Button>
                )}

              </Stack>
            )}

          </Stack>

        </Stack>
      </Stack>

      </CardContent>
    </Card>

  );
}
// ----------------------------------------------------------------------


