import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import {Stack, Typography} from '@mui/material'

export default function ModalAlerta({open, mensagem, onClose, titulo}) {


  return (
    <div>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>

          <Stack direction="column" spacing={2}>

          { titulo &&  <Typography variant="h5">{titulo}</Typography>}
          
           <Typography variant="body">{mensagem}</Typography>
          </Stack>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" onClick={onClose}>Ok</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
