import axios from "axios";

import Config from "src/config";

const v1_actionBuscarMensagensWhatsapp = (querys, data) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/canais/buscar-mensagens${querys}`,
        data
    });
};

export default v1_actionBuscarMensagensWhatsapp;
