
import { Box, Button, Divider, Collapse, Typography, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';
import LabelInput from 'src/components/LabelInput';
import { fDateTime } from 'src/utils/formatTime';
import actionCriarNotas from 'src/actions/chats/notas/actionCriarNotas';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { useEffect } from 'react';
import DrawerNegocioNotasInput from './DrawerNegocioNotasInput';
import v2_actionAddNotasLead from 'src/actions/v2/crm/v2_actionAddNotasLead';


DrawerNegocioNotas.propTypes = {
    isCollapse: PropTypes.bool,
    onCollapse: PropTypes.func
};

export default function DrawerNegocioNotas({ dataLead, user, refreshLead }) {
    const [text, setText] = useState('')
    const { enqueueSnackbar } = useSnackbar();
    const [notas, setNotas] = useState(dataLead && dataLead ? dataLead?.notas : [])

    useEffect(() => {
        return () => {
            setNotas([])
        }
    }, [dataLead])


    async function novaNota() {
        try {
            let data = {
                nota: {
                    body: text,
                    usuario_id: user.id,
                    date: new Date()
                }
            }
            await v2_actionAddNotasLead(dataLead._id, data)
            setText('');
            let dataPreview = {
                body: text,
                usuario_id: user.id,
                date: new Date()
            }
            setNotas([...notas, dataPreview])
            enqueueSnackbar('Nota adicionada com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            refreshLead()
        } catch (error) {
            console.log(error)
        }
    }

    return (
        <Stack>

         
            <DrawerNegocioNotasInput text={text} changeText={e => setText(e.target.value)} onSend={novaNota} />

            <Scrollbar sx={{
                
                height: {
                    xs: `calc(50vh - 100px)`,
                    sm: `calc(50vh - 100px)`,
                    md: `calc(50vh - 100px)`,
                    lg: `calc(60vh - 100px)`,
                    xl: `calc(60vh - 100px)`
                },
           
            }}>
                {notas && notas.map((nota, index) => (
                    <Box key={index} sx={{ p: 2 }}>
                        <Typography sx={{ fontSize: 10, fontWeight: '800' }}> {fDateTime(nota.date)} </Typography>
                        <Typography variant='body' sx={{ fontSize: 12 }}> {nota.body} </Typography>

                    </Box>
                ))}
            </Scrollbar>
        </Stack>

    );
}
