import axios from "axios";

import Config from "src/config";

const v2_actionFinalizarMultiplosChats = (data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/finalizar-multiplos`,
        data
    });
};
export default v2_actionFinalizarMultiplosChats;
