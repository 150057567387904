import closeFill from '@iconify/icons-eva/close-fill';
import fileFill from '@iconify/icons-eva/file-fill';
import { Icon } from '@iconify/react';
import {
  Box,
  List,
  Paper,
  ListItem,
  Typography,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction
} from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import { motion, AnimatePresence } from 'framer-motion';
import { isString } from 'lodash';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
// material

// utils
import { UploadIllustration } from '../../assets';
import { fData } from '../../utils/formatNumber';
//
import { MIconButton } from '../@material-extend';
import { varFade } from '../animate';


// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  textAlign: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(1, 1),
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.neutral,
  border: `1px dashed ${theme.palette.grey[500_32]}`,
  '&:hover': { opacity: 0.72, cursor: 'pointer' },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' }
}));

// ----------------------------------------------------------------------

UploadCampanha.propTypes = {
  error: PropTypes.bool,
  showPreview: PropTypes.bool,
  files: PropTypes.array,
  onRemove: PropTypes.func,
  onRemoveAll: PropTypes.func,
  sx: PropTypes.object
};

export default function UploadCampanha({
  error,
  isEditFile,
  showPreview = true,
  files,
  filesEdit,
  onRemoveEdit,
  onRemove,
  sx,
  ...other }) {
  const hasFile = files.length > 0;

  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    ...other,
    maxFiles:2,
    maxSize:5000000 
  });

  const ShowRejectionItems = () => (
    <Paper
      variant="outlined"
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08)
      }}
    >
      {fileRejections.map(({ file, errors }) => {
        const { path } = file;
        return (
          <Box key={path} sx={{ my: 1 }}>
            <Typography variant="subtitle2" noWrap>
   
            </Typography>
            {errors.map((e) => (
              <Typography key={e.code} variant="caption" component="p">
                - Arquivo é muito grande epra ser enviado. O maximo permitido é <strong>5mb</strong>
              </Typography>
            ))}
          </Box>
        );
      })}
    </Paper>
  );

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter'
          })
        }}
      >
        <input {...getInputProps()} />

        <UploadIllustration sx={{ width: 70 }} />

        <Box sx={{ p: 0, ml: { md: 2 } }}>
          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Arraste arquivos ou selecione&nbsp;
            <Typography variant="body2" component="span" sx={{ color: 'primary.main', textDecoration: 'underline' }}>
              buscando
            </Typography>
            &nbsp;em sua máquina
          </Typography>
          <Typography style={{fontSize:10}}>Limite máximo de 5mb para arquivos</Typography>
        </Box>
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems />}

      {isEditFile ? (

        <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
          <AnimatePresence>
            {filesEdit.map((file) => {

              const { key, type } = file;

              function verificaTipoImagem(type) {
                if (type === "image/png") {
                  return `https://zindi-s1.s3.us-east-2.amazonaws.com/${key}`;
                } else if (type === "image/jpg") {
                  return `https://zindi-s1.s3.us-east-2.amazonaws.com/${key}`;
                } else if (type === "application/pdf") {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/pdf.png'
                } else if (type === "video/mp4") {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/camera-de-video.png'
                } else {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/pasta.png'
                }
              }


              if (showPreview) {
                return (
                  <>
                    <ListItem
                      key={key}
                      component={motion.div}
                      {...varFade().inRight}
                      sx={{
                        p: 0,
                        m: 0.5,
                        width: 100,
                        height: 100,
                        borderRadius: 1.5,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-flex'
                      }}
                    >

                      <Paper
                        variant="outlined"
                        component="img"
                        src={isString(file) ? file : verificaTipoImagem(type)}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                      />

                      <Box sx={{ top: 6, right: 6, position: 'absolute' }}>

                        <MIconButton
                          size="small"
                          onClick={() => onRemoveEdit(file)}
                          sx={{
                            p: '2px',
                            color: 'common.white',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                            '&:hover': {
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                            }
                          }}
                        >
                          <Icon icon={closeFill} />
                        </MIconButton>

                      </Box>

                    </ListItem>

                  </>
                );
              }

              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  {...varFade().inRight}
                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper'
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>
                  {/* <ListItemText
                primary={isString(file) ? file : name}
                secondary={isString(file) ? '' : fData(size)}
                primaryTypographyProps={{ variant: 'subtitle2' }}
                secondaryTypographyProps={{ variant: 'caption' }}
              /> */}
                  <ListItemSecondaryAction>
                    <MIconButton edge="end" size="small" onClick={() => onRemove(file)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </AnimatePresence>
        </List>

      ) : (

        <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
          <AnimatePresence>
            {files.map((file) => {
              console.log('file', file)
              const { name, size, preview, type } = file;
              const key = isString(file) ? file : name;

              console.log('size', size)

              function verificaTipoImagem(type) {
                if (type === "image/png") {
                  return preview;
                } else if (type === "image/jpg") {
                  return preview;
                } else if (type === "application/pdf") {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/pdf.png'
                } else if (type === "video/mp4") {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/camera-de-video.png'
                } else {
                  return 'https://zindi.s3.us-east-2.amazonaws.com/files/pasta.png'
                }
              }


              if (showPreview) {
                return (
                  <>
                    <ListItem
                      key={key}
                      component={motion.div}
                      sx={{
                        p: 0,
                        m: 0.5,
                        width: 100,
                        height: 100,
                        borderRadius: 1.5,
                        overflow: 'hidden',
                        position: 'relative',
                        display: 'inline-flex'
                      }}
                    >

                      <Paper
                        variant="outlined"
                        component="img"
                        src={isString(file) ? file : verificaTipoImagem(type)}
                        sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                      />

                      <Box sx={{ top: 6, right: 6, position: 'absolute' }}>

                        <MIconButton
                          size="small"
                          onClick={() => onRemove(file)}
                          sx={{
                            p: '2px',
                            color: 'common.white',
                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                            '&:hover': {
                              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48)
                            }
                          }}
                        >
                          <Icon icon={closeFill} />
                        </MIconButton>

                      </Box>

                    </ListItem>
                    <ListItemText
                      primary={isString(file) ? file : name}
                      secondary={isString(file) ? '' : fData(size)}
                      primaryTypographyProps={{ variant: 'subtitle2' }}
                      secondaryTypographyProps={{ variant: 'caption' }}
                    />
                  </>
                );
              }

              return (
                <ListItem
                  key={key}
                  component={motion.div}
                  {...varFade().inRight}
                  sx={{
                    my: 1,
                    py: 0.75,
                    px: 2,
                    borderRadius: 1,
                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                    bgcolor: 'background.paper'
                  }}
                >
                  <ListItemIcon>
                    <Icon icon={fileFill} width={28} height={28} />
                  </ListItemIcon>
                  <ListItemText
                    primary={isString(file) ? file : name}
                    secondary={isString(file) ? '' : fData(size)}
                    primaryTypographyProps={{ variant: 'subtitle2' }}
                    secondaryTypographyProps={{ variant: 'caption' }}
                  />
                  <ListItemSecondaryAction>
                    <MIconButton edge="end" size="small" onClick={() => onRemove(file)}>
                      <Icon icon={closeFill} />
                    </MIconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              );
            })}
          </AnimatePresence>
        </List>

      )}

    </Box>
  );
}
