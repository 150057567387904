import axios from "axios";
import Config from "../../../../../config";

const v2_actionGerarExcelRelatorioChats = (querys) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/dashboard/relatorios/excel/chats${querys}`,
        responseType: 'blob'
    });
};

export default v2_actionGerarExcelRelatorioChats;
