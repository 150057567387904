import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    Container,
    Box,
    Grid,
    Divider,
    LinearProgress,
    DialogActions,
    Autocomplete,
    Chip
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import v1_actionCriarFluxoDireto from 'src/actions/v1/clientes/v1_actionCriarFluxoDireto';
import v2_actionCriarTreinamentoAgente from 'src/actions/v2/cliente/flows/v2_actionCriarTreinamentoAgente';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import InputStyleMultline from 'src/components/InputStyleMultline';
// @mui

ModalDirecionamento.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default function ModalDirecionamento({ 
    open, 
    onClose, 
    clienteId, 
    onCallback,
    listSectors,
    listUsers
 }) {

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [instruction, setInstruction] = useState('');

    const [recebe, setRecebe] = useState('');
    const [usuarios, setUsuarios] = useState([]);
    const [setores, setSetores] = useState([]);


    async function handleSave() {
        try {
            let data = {
                clienteId:clienteId, 
                recebeField:recebe,
                direcionaUsuariosField:usuarios.map(user => user._id),
                direcionaSetoresField:setores.map(setor => setor._id)
              }
            await v1_actionCriarFluxoDireto(data)
            onCallback();
            onClose();
            setRecebe('');
            setUsuarios([]);
            setSetores([]);

        } catch(err) {
            console.log(err)
        }
    }
    



    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
            fullWidth

        >
    
            <DialogTitle sx={{
                background: theme => theme.palette.primary.main, py: 1, color: '#fff'
            }}>
                Adicionar Fluxo direto
            </DialogTitle>
            <Container>
                <DialogContent sx={{ mt: 3 }}>

                    <Stack direction="column" spacing={2}>


                    <Stack direction="column" spacing={2}>
                        <RowInput
                            label="Input"
                            input={<InputStyle value={recebe} onChange={e => setRecebe(e.target.value)} />}
                        />
                    
                    </Stack>
                           
                <Stack direction={"row"} spacing={2}>
                <RowInput
                    label={"direciona usuários"}
                    sx={{ width: '100%' }}
                    input={<Autocomplete
                        multiple
                        variant="outlined"
                        fullWidth
                        options={!listUsers ? [{nomeFantasia:"Loading...", _id:0}] : listUsers }

                        size='small'
                        name="directTargetUser"
                        getOptionLabel={(option) => `${option.nomeFantasia} - ${option.nome_setor}`}
                        value={usuarios}
                        //filterSelectedOptions
                        onChange={(e, newValue) => setUsuarios(newValue)}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (<Chip variant="outlined" label={option.nomeFantasia} {...getTagProps({ index })} />))
                        }
                        renderInput={(params) => (
                            <InputStyleMultline {...params} variant="outlined" />
                        )}
                    />}
                />

                <RowInput
                    label={"direciona setores"}
                    sx={{ width: '100%' }}
                    input={<Autocomplete
                        multiple
                        variant="outlined"
                        fullWidth
                        options={!listSectors ? [{nome_setor:"Loading...", _id:0}] : listSectors }
                        size='small'
                        getOptionLabel={(option) => option.nome_setor}
                        defaultValue={setores || []}
                        //filterSelectedOptions
                        onChange={(e, newValue) => setSetores(newValue)}
                        name="directTargetSector"
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (<Chip variant="outlined" label={option.nome_setor} {...getTagProps({ index })} />))
                        }
                        renderInput={(params) => (
                            <InputStyleMultline {...params} variant="outlined" />
                        )}
                    />}
                />
                </Stack>
                </Stack>
                </DialogContent>
                <Divider sx={{borderStyle:'dashed'}} />
                <DialogActions>
                    <Button onClick={onClose}>Cancelar</Button>
                    <LoadingButton loading={false} onClick={handleSave} variant="contained" color="primary">Salvar</LoadingButton>
                </DialogActions>
            </Container>
        </Dialog>
    );
}


