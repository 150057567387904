import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { List, Stack, IconButton, ListItemText, ListItem, Paper, Box } from '@mui/material';
// utils
import { fData } from 'src/utils/formatNumber';
import { BsFillCloudDownloadFill } from 'react-icons/bs';

// ----------------------------------------------------------------------

const getFileData = (file) => {
    if (typeof file === 'string') {
        return {
            key: file,
        };
    }
    return {
        key: file.key,
        name: file.name,
        size: file.size,
        preview: file.preview,
    };
};

// ----------------------------------------------------------------------

FilePreviewComentarios.propTypes = {
    files: PropTypes.array,
    showPreview: PropTypes.bool
};

export default function FilePreviewComentarios({ showPreview = false, files }) {
    const hasFile = files.length > 0;
    console.log('verificaTipoImagem', files)
    return (
        <>
            <List disablePadding sx={{ ...(hasFile && { my: 3 }) }}>
                <AnimatePresence>
                    {files.map((file) => {
                        const { key, name, size, preview } = getFileData(file);

                        function verificaTipoImagem(type) {
                          
                            if (type === "image/png") {
                                return `https://lais-app.s3.us-east-2.amazonaws.com/${key}`;
                            } else if (type === "image/jpg") {
                                return `https://lais-app.s3.us-east-2.amazonaws.com/${key}`;
                            } else if (type === "image/jpeg") {
                                return `https://lais-app.s3.us-east-2.amazonaws.com/${key}`;
                            } else if (type === "application/pdf") {
                                return 'https://lais-app.s3.us-east-2.amazonaws.com/files/pdf.png'
                            } else if (type === "video/mp4") {
                                return 'https://lais-app.s3.us-east-2.amazonaws.com/files/camera-de-video.png'
                            } else {
                                return 'https://lais-app.s3.us-east-2.amazonaws.com/files/pasta.png'
                            }
                        }


                        //       if (showPreview) {
                        //         return (
                        //           <>
                        //             <ListItem
                        //               key={key}

                        //               sx={{
                        //                 p: 0,
                        //                 m: 0.5,
                        //                 width: 70,
                        //                 height: 70,
                        //                 borderRadius: 1.5,
                        //                 overflow: 'hidden',
                        //                 position: 'relative',
                        //                 display: 'inline-flex'
                        //               }}
                        //             >

                        //               <Paper
                        //                 variant="outlined"
                        //                 component="img"
                        //                 src={isString(file) ? file : verificaTipoImagem(file.type)}
                        //                 sx={{ width: '100%', height: '100%', objectFit: 'cover', position: 'absolute' }}
                        //               />

                        //               <Box sx={{ top: 6, right: 6, position: 'absolute' }}>

                        //               <IconButton
                        //   target="_blank"
                        //   component="a"
                        //   href={`https://katuxa.s3.us-east-1.amazonaws.com/${key}`}
                        //   size="small"
                        //   variant={'text'}
                        //   color={'primary'}
                        // >
                        //   <BsFillCloudDownloadFill />
                        // </IconButton>

                        //               </Box>

                        //             </ListItem>

                        //           </>
                        //         );
                        //       }

                        return (
                            <ListItem
                                key={key}
                                component={m.div}
                    
                                sx={{
                                    my: 1,
                                    px: 2,
                                    py: 0.75,
                                    borderRadius: 0.75,
                                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                                }}
                            >
                                <Stack direction={'row'} spacing={3}>
                                    <Paper
                                        variant="outlined"
                                        component="img"
                                        src={isString(file) ? file : verificaTipoImagem(file.type)}
                                        sx={{ width: 30, height: 30, objectFit: 'cover' }}
                                    />

                                    <ListItemText
                                        primary={isString(file) ? file : name}
                                        secondary={isString(file) ? '' : fData(size || 0)}
                                        primaryTypographyProps={{ variant: 'subtitle2' }}
                                        secondaryTypographyProps={{ variant: 'caption' }}
                                    />

                                    <IconButton
                                        target="_blank"
                                        component="a"
                                        href={`https://lais-app.s3.us-east-2.amazonaws.com/${key}`}
                                        size="small"
                                        variant={'text'}
                                        color={'primary'}
                                    >
                                        <BsFillCloudDownloadFill />
                                    </IconButton>
                                </Stack>

                            </ListItem>
                        );
                    })}
                </AnimatePresence>
            </List>

        </>
    );
}
