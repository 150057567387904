
import { useTheme } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import {
    Container,
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    TextField,
    DialogActions,
    Drawer,
    Radio,
    FormControlLabel,
    Select,
    Chip,
    MenuItem,
    FormControl,
    InputLabel,
    Switch,
    Autocomplete,
    Alert,
    Tabs,
    Tab,
    FormLabel,
    RadioGroup,
    CircularProgress,
    IconButton,
    Paper,
    Divider
} from '@mui/material';
import { useState, useEffect, useCallback, useMemo } from 'react';


// utils
import actionBuscarCampanhaById from 'src/actions/campanhas/actionBuscarCampanhaById';
import actionCriarCampanha from 'src/actions/campanhas/actionCriarCampanha';
import actionDescontinuarcampanha from 'src/actions/campanhas/actionDescontinuarcampanha';
import actionEditarCampanha from 'src/actions/campanhas/actionEditarCampanha';
import actionListaCampanhas from 'src/actions/campanhas/actionListaCampanhas';
import CampanhasMoreMenu from 'src/components/_chats/campanhas/CampanhasMoreMenu';
import { UploadCampanha } from 'src/components/upload';
import { nomeProjeto } from 'src/config';
import useTabs from 'src/hooks/useTabs';
import { getListaOptionsFunil, getListaOptionsTags } from 'src/redux/slices/global';
import { fDate, fDateTimeSuffix } from 'src/utils/formatTime';

// redux
import { UserListHead } from '../../components/_dashboard/user/list';
import Label from '../../components/Label';
import Page from '../../components/Page';
import Scrollbar from '../../components/Scrollbar';
import SearchNotFound from '../../components/SearchNotFound';
import { useDispatch, useSelector } from '../../redux/store';

import MicRecorder from "mic-recorder-to-mp3";
import { BsMicFill } from 'react-icons/bs';
import RecordingTimer from 'src/components/_chats/chat/components/RecordingTimer';
import { FaCheck } from 'react-icons/fa';
import Iconify from 'src/components/Iconify';
import v2_actionUploadS3 from 'src/actions/v2/global/actionUploadS3';
import Breadcrumbs from 'src/components/Breadcrumbs';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import useAuth from 'src/hooks/useAuth';
import { TableBorder } from 'src/components/table';
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';

import { Button } from 'src/@/components/ui/button';


const STATUS_OPTIONS = [
    { value: null, label: "Todos" },
    { value: 'agendado', label: "Agendados" },
    { value: 'em andamento', label: "Em Andamento" },
    { value: 'finalizado', label: "Finalizado" },
    { value: 'erro', label: "Falhados" }
];

const TABLE_HEAD = [
    { id: 'nome', label: 'Nome', alignRight: false },
    { id: 'status_envio', label: 'Status Envio', alignRight: false },
    { id: 'criado_por', label: 'Criado Por', alignRight: false },
    { id: 'tipo', label: 'tipo', alignRight: false },
    { id: 'data_envio', label: 'Data Envio', alignRight: false },
    { id: 'status', label: 'Ativo', alignRight: false },
    { id: '', label: '', alignRight: false },
];

// ----------------------------------------------------------------------

export default function Campanhas() {
    const theme = useTheme();
    const dispatch = useDispatch();

    const [tableData, setTableData] = useState([]);
    const { optionsFunil, optionsTags } = useSelector((state) => state.global);
    const { usuariosList } = useSelector((state) => state.user);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    // eslint-disable-next-line no-unused-vars
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { user, cliente } = useAuth();

    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(null);

    const [campanhaId, setCampanhaId] = useState('');

    const [drawerNovaCampanha, setDrawerNovaCampanha] = useState(false);


    const [funilIds, setFunilIds] = useState([]);
    const [tagsIds, setTagsIds] = useState([]);
    const [body, setBody] = useState('');
    const [tipoCampanha, setTipoCampanha] = useState('');
    const [dataCampanha, setDataCampanha] = useState(new Date())
    const [dataInicio, setDataInicio] = useState(new Date())
    const [dataFinal, setDataFinal] = useState(new Date())
    const [isFiltroData, setIsFiltroData] = useState(true);
    const [horaCampanha, setHoraCampanha] = useState('')
    const [minutoCampanha, setMinutoCampanha] = useState('');
    const [nomeCampanha, setNomeCampanha] = useState('');
    const [isEdit, setIsEdit] = useState(false);
    const [isEditFile, setIsEditFile] = useState(false);
    const [filesEdit, setFilesEdit] = useState([]);
    const [isChatAtendimento, setIsChatAtendimento] = useState(false);

    //campanhas
    const [modalConfirmarExclusao, setModalConfirmarExclusao] = useState(false);
    const [isAlert, setIsAlert] = useState(false);

    //upload
    const [files, setFiles] = useState([]);
    const filesUrl = []
    // eslint-disable-next-line no-unused-vars
    const [preview, setPreview] = useState(true);
    const [isFile, setIsFile] = useState(false);
    const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
    const [tipoArquivo, setTipoArquivo] = useState('');

    const [recording, setRecording] = useState(false);
    const [audioGravado, setAudioGravado] = useState(null);
    const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);
    const [loadingAudio, setLoadingAudio] = useState(false);

    const [clienteIdPadrao, setClienteIdPadrao] = useState(user.cliente_id_padrao);
    const [listaClientesCanais, setListaClientesCanais] = useState([]);
    const isMultisession = cliente.multiSession === 1;

    const [funilId, setFunilId] = useState('');
    const [configCrm, setConfigCrm] = useState(null);


    async function buscarListaClientesCanais() {
        try {
            const response_clientes_canais = await actionListaClientesCanais(cliente._id)
            setListaClientesCanais(response_clientes_canais.data.data);
        } catch (err) {
            console.log(err)
        }
    }


    async function buscarConfigCrm(querys) {
        try {
            const result = await v2_actionConfigCrm(cliente._id, querys);
            if (result.data.data) {
                setConfigCrm(result.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        if (user.cliente_id_padrao) {
            setClienteIdPadrao(user.cliente_id_padrao);
        }
        if (cliente.multiSession === 1) {
            buscarListaClientesCanais()
        }
        callListaCampanhas(isMultisession ? clienteIdPadrao : cliente._id);
        buscarConfigCrm(null)

        dispatch(getListaOptionsTags(cliente._id));


    }, []);


    const handleChangeClienteIdPadrao = (e) => {
        setClienteIdPadrao(e.target.value)
        callListaCampanhas(isMultisession ? e.target.value : cliente._id);
    }


    // calls
    const callListaCampanhas = async clienteId => {
        try {
            const res = await actionListaCampanhas(clienteId);
            setTableData(res.data.data)
        } catch (error) {
            console.log('error', error)
        }
    }

    const dataFiltered = applySortFilter({
        tableData,
        filterName,
        filterStatus
    });


    const handleChangeTipoCampanha = (event) => {
        console.log('handleChangeTipoCampanha', event.target.value)
        setTipoCampanha(event.target.value);
    };


    const handleOpenDialogExcluirCampanha = (id) => {
        setCampanhaId(id)
        setModalConfirmarExclusao(true);

    }

    const handleChangeHoraCampanha = (event) => {
        setHoraCampanha(event.target.value);
    };
    const handleChangeMinutoCampanha = (event) => {
        setMinutoCampanha(event.target.value);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usuariosList.map((n) => n.nome);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleOpenDialogEditarCampanha = async (row) => {
        setCampanhaId(row._id)
        setFunilIds(row.funil_ids_content);
        setIsEditFile(true);
        setFilesEdit(row.files)
        setIsFiltroData(row.isFiltroData)
        setDataInicio(row.data_inicio)
        setDataFinal(row.data_final)
        setIsEdit(true);
        setDrawerNovaCampanha(true);
        try {
            const res = await actionBuscarCampanhaById(row._id);
            if (res.data.data) {
                let hora = new Date(res.data.data.data_envio).getHours()
                let minuto = new Date(res.data.data.data_envio).getMinutes()

                setIsFile(res.data.data.file);
                setHoraCampanha(hora.toString());
                setMinutoCampanha(minuto.toString())
                setBody(res.data.data.texto)
                setNomeCampanha(res.data.data.nome);
            }


        } catch (error) {
            console.log('error', error)
        }


    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onCloseDrawerNovaCampanha = () => {
        setDrawerNovaCampanha(false);
        limparCampos()
    }


    const handleDropMultiFile = useCallback(
        (acceptedFiles) => {
            setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
        },
        [setFiles]
    );

    const handleRemove = (file) => {
        const filteredItems = files.filter((_file) => _file !== file);
        setFiles(filteredItems);
    };
    const handleRemoveEdit = (file) => {
        const filteredItems = filesEdit.filter((_file) => _file !== file);
        setIsEditFile(false);
        setFilesEdit(filteredItems);
    };

    const handleChangeIsFile = (event) => {
        setIsFile(event.target.checked);
    };
    const handleChangeIsChatAtendimento = (event) => {
        setIsChatAtendimento(event.target.checked);
    };
    const handleChangeIsFiltroData = (event) => {
        setIsFiltroData(event.target.checked);
    };

    //     filesUrl.push({ nome: filename, base64 });

    async function uploadAndGetUrl() {
        if (files.length > 0) {
            for (let file of files.map(item => item)) {
                var formData = new FormData();
                var imagefile = file;
                formData.append("file", imagefile);
                try {
                    let querys = `?path=plataforma/camapanhas`
                    const response = await v2_actionUploadS3(formData, querys)
                    if (!response.data.error) {
                        filesUrl.push({ key: response.data.data.key, type: file.type })
                    }

                } catch (error) {
                    console.log('erro ao upload arquivo', error)
                }

            }
        }
    }

    async function uploadAudioAndGetUrl(file) {
        if (file) {
            var formData = new FormData();
            var audiofile = file;
            formData.append("file", audiofile);
            try {
                let querys = `?path=plataforma/agendamentos`
                const response = await v2_actionUploadS3(formData, querys)
                if (!response.data.error) {
                    return { key: response.data.data.key, type: file.type, tipo: "audio" };
                }
            } catch (error) {
                console.log('erro ao upload arquivo', error)
            }


        }
    }

    const handleUploadAudio = async () => {
        const response_upload = await uploadAudioAndGetUrl(audioGravado);
        filesUrl.push(response_upload)

    };

    const limparCampos = () => {
        setFunilIds([])
        setTagsIds([]);
        setAudioGravado(null);
        setDataCampanha(new Date());
        setHoraCampanha('');
        setMinutoCampanha('');
        setNomeCampanha('');
        setTipoCampanha('');
        setBody('');
        setFiles([]);
        setIsEdit(false);
        setIsAlert(false);
        setIsFile(false);
        setIsChatAtendimento(false);
        setIsEditFile(false);
        setIsFiltroData(true)
        setDataInicio(new Date())
        setDataFinal(new Date())

    }

    const onEditarOnlyStatus = async (event, id) => {
        console.log(event, id)
        try {
            let data = { action: 'edit_only_status', status: event.target.checked }
            await actionEditarCampanha(id, data);
            callListaCampanhas(isMultisession ? clienteIdPadrao : cliente._id);

        } catch (error) {
            alert('Erro ao alterar o status, tente novamente')
        }
    };

    const onDeletarCampanha = async (id) => {
        try {
            await actionDescontinuarcampanha(id);
            setModalConfirmarExclusao(false)
            callListaCampanhas(isMultisession ? clienteIdPadrao : cliente._id);
        } catch (error) {
            console.log('erro ao deletar campanha');
        }
    }

    function validaFunilTags() {
        if (tipoCampanha === 'funil' && funilIds.length === 0) {
            return true
        } else if (tipoCampanha === 'tag' && tagsIds.length === 0) {
            return true
        } else {
            return false
        }
    }

    const handleCancelAudio = async () => {
        try {
            await recorder.stop().getMp3();
            setRecording(false);
            setAudioGravado(null);
        } catch (err) {
            console.log(err);
        }
    };

    async function pararGravacao() {
        try {
            const [buffer, blob] = await recorder.stop().getMp3()
            const file = new File(buffer, "audio.mp3", {
                type: blob.type,
                lastModified: Date.now()
            });
            setAudioGravado(file)
            setRecording(false)
        } catch (error) {
            filesUrl = [];
        }

    }

    const handleStartRecording = async () => {
        setLoadingAudio(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await recorder.start();
            setRecording(true);
            setLoadingAudio(false);
        } catch (err) {
            console.log(err);
            setLoadingAudio(false);
        }
    };



    const onCriarCampanha = async () => {
        if (horaCampanha === "" ||
            minutoCampanha === "" ||
            validaFunilTags() ||
            nomeCampanha === "" ||
            (isFile && tipoArquivo === 'arquivo' && files.length === 0) ||
            (isFile && tipoArquivo === 'audio' && !audioGravado) ||
            (!isFile && body === "")
        ) {
            console.log('(isFile && files.length === 0)', (isFile && tipoArquivo === 'arquivo' && files.length === 0))
            setIsAlert(true)
        } else {

            setLoadingEnviarArquivos(true);
            try {
                const data = {
                    nome: nomeCampanha,
                    dataCampanha: dataCampanha,
                    horaCampanha: horaCampanha,
                    minutoCampanha: minutoCampanha,
                    funilIds: funilIds,
                    tagsIds: tagsIds,
                    isFiltroData,
                    dataInicio,
                    dataFinal,
                    status_envio: "agendado",
                    criado_por: user.id,
                    cliente_id: isMultisession ? clienteIdPadrao : cliente._id,
                    tipo: tipoCampanha,
                    texto: body,
                    isFile: isFile,
                    files: filesUrl,
                    isChatAtendimento
                }
                if (isFile) {
                    if (tipoArquivo === "audio") {
                        await handleUploadAudio();
                    } else {
                        await uploadAndGetUrl();
                    }

                }

                await actionCriarCampanha(data);
                setLoadingEnviarArquivos(false);
                setDrawerNovaCampanha(false)
                callListaCampanhas(isMultisession ? clienteIdPadrao : cliente._id);
                limparCampos();
            } catch (error) {
                setLoadingEnviarArquivos(false);
                console.log('error', error);
                // limparCampos();
            }
        }
    }

    const onEditarCampanha = async () => {
        if (horaCampanha === "" || minutoCampanha === "" || funilIds.length === 0 || nomeCampanha === "" || (isFile && tipoArquivo === 'arquivo' && files.length === 0) || (!isFile && body === "")) {
            setIsAlert(true)
        } else {
            setLoadingEnviarArquivos(true);

            if (isFile) {
                try {
                    let data = {
                        action: 'edit_info_with_file',
                        nome: nomeCampanha,
                        dataCampanha: dataCampanha,
                        horaCampanha: horaCampanha,
                        minutoCampanha: minutoCampanha,
                        isFiltroData,
                        dataInicio,
                        dataFinal,
                        funilIds: funilIds,
                        texto: body,
                        isFile: isFile,
                        files: filesUrl,
                        isChatAtendimento
                    }
                    await uploadAndGetUrl();
                    await actionEditarCampanha(campanhaId, data);
                    setDrawerNovaCampanha(false)
                    callListaCampanhas(isMultisession ? clienteIdPadrao : cliente._id);
                    limparCampos();
                    setLoadingEnviarArquivos(false);
                } catch (error) {
                    setLoadingEnviarArquivos(false);
                    console.log('error', error);
                    // limparCampos();
                }

            } else {

                try {
                    let data = {
                        action: 'edit_info_without_file',
                        nome: nomeCampanha,
                        dataCampanha: dataCampanha,
                        horaCampanha: horaCampanha,
                        minutoCampanha: minutoCampanha,
                        isFiltroData,
                        dataInicio,
                        dataFinal,
                        funilIds: funilIds,
                        texto: body,
                        isChatAtendimento
                    }
                    await actionEditarCampanha(campanhaId, data);
                    setDrawerNovaCampanha(false)
                    callListaCampanhas(isMultisession ? clienteIdPadrao : cliente._id);
                    limparCampos();
                    setLoadingEnviarArquivos(false);
                } catch (error) {
                    setLoadingEnviarArquivos(false);
                    console.log('error', error);
                    // limparCampos();
                }

            }


        }
    }

    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - tableData.length) : 0;
    const isUserNotFound = (!dataFiltered.length && !!filterStatus);

    return (
        <Container>


            <div className='my-5'>
                <HeaderPageComponentConfig
                    titulo="Campanhas"
                    descricao={'Crie e programer campanhas para seus clientes'}
                    actions={<Button
                        onClick={() => setDrawerNovaCampanha(true)}
                    >
                        Criar Campanha
                    </Button>}
                />
            </div>
            <div>


                <Stack spacing={2} direction="row" alignItems="center" mb={1}>

                    {isMultisession && (
                        <Paper
                            component="form"
                            sx={{ p: '10px 20px', display: 'flex', alignItems: 'center' }}
                        >
                            <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                <Typography variant="caption">Canal:</Typography>
                                <Select
                                    size="small"
                                    placeholder='Escolha um funil'
                                    defaultValue={clienteIdPadrao}
                                    sx={{ minWidth: 150, height: 35 }}
                                    value={clienteIdPadrao}
                                    onChange={handleChangeClienteIdPadrao}
                                >
                                    {listaClientesCanais.map((option) => (
                                        <MenuItem key={option.cliente_id} value={option.cliente_id}>
                                            {option.nome}
                                        </MenuItem>

                                    ))}
                                </Select>
                            </Stack>
                        </Paper>
                    )}

                </Stack>

                <TableBorder sx={{
                    height: 'calc(100vh - 30px)',
                    mt: 2,
                    p: 3
                }}>
                    <Dialog
                        open={modalConfirmarExclusao}
                        onClose={() => { setModalConfirmarExclusao(false); setCampanhaId('') }}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            {"Deseja mesmo excluir?"}
                        </DialogTitle>

                        <DialogActions>
                            <Button onClick={() => {
                                setModalConfirmarExclusao(false);
                                setCampanhaId('')
                            }}>
                                Cancelear
                            </Button>
                            <Button variant='contained' onClick={() => onDeletarCampanha(campanhaId)} autoFocus>Confirmar</Button>
                        </DialogActions>
                    </Dialog>

                    <Drawer open={drawerNovaCampanha} onClose={onCloseDrawerNovaCampanha} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 600 } } }}>
                        <Container>
                            <Typography variant='h6' sx={{ p: 2 }}>{isEdit ? "Editar" : "Criar"} Campanha</Typography>
                            <Divider />
                            <Stack direction={'column'} spacing={3} sx={{ mt: 2 }}>
                                {isMultisession && (
                                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                                        <Typography variant="caption">Canal:</Typography>
                                        <Select
                                            size="small"
                                            placeholder='Escolha um funil'
                                            defaultValue={clienteIdPadrao}
                                            sx={{ minWidth: 150, height: 35 }}
                                            value={clienteIdPadrao}
                                            onChange={handleChangeClienteIdPadrao}
                                        >
                                            {listaClientesCanais.map((option) => (
                                                <MenuItem key={option.cliente_id} value={option.cliente_id}>
                                                    {option.nome}
                                                </MenuItem>

                                            ))}
                                        </Select>
                                    </Stack>
                                )}

                                <Stack>
                                    <RowInput
                                        label="Nome da Campanha"
                                        input={
                                            <TextField sx={{ height: 35 }} size="small" value={nomeCampanha} onChange={e => setNomeCampanha(e.target.value)} placeholder='Nome da campanha' />
                                        } />
                                </Stack>

                                <Stack sx={{ mt: 2, mb: 3 }} spacing={2} direction={'column'}>

                                    <Stack direction={'row'}>
                                        <FormControlLabel
                                            value="start"
                                            control={<Radio
                                                checked={tipoCampanha === 'funil'}
                                                onChange={handleChangeTipoCampanha}
                                                value="funil"
                                                name="radio-buttons"
                                                inputProps={{ 'aria-label': 'A' }}
                                            />}
                                            label="Funil de Vendas"
                                            labelPlacement="end"
                                        />

                                        <FormControlLabel
                                            value="start"
                                            control={
                                                <Radio
                                                    checked={tipoCampanha === 'tag'}
                                                    onChange={handleChangeTipoCampanha}
                                                    value="tag"
                                                    name="radio-buttons"
                                                    inputProps={{ 'aria-label': 'B' }}
                                                />}
                                            label="Tags"
                                            labelPlacement="end"
                                        />
                                    </Stack>
                                </Stack>

                                {tipoCampanha === 'funil' && (

                                    <Stack spacing={2} sx={{ mt: 3, mb: 3 }}>
                                        <RowInput
                                            label="Funil"
                                            input={
                                                <FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                                                    <InputStyle
                                                        select
                                                        placeholder="Funil"
                                                        fullWidth
                                                        size="small"
                                                        value={funilId}
                                                        onChange={async (e) => {
                                                            let querys = `?pipeline_id=${e.target.value}`
                                                            await buscarConfigCrm(querys);
                                                            setFunilId(e.target.value);

                                                        }}
                                                    >
                                                        {configCrm?.pipelines.map((option) => (
                                                            <MenuItem key={option._id} value={option._id}>
                                                                {option.nome}
                                                            </MenuItem>
                                                        ))}
                                                    </InputStyle>
                                                </FormControl>
                                            } />

                                        {funilId && (
                                            <RowInput
                                                label="Estágio Funil"
                                                input={
                                                    <Autocomplete
                                                        multiple
                                                        variant="outlined"
                                                        fullWidth
                                                        options={configCrm?.stages}
                                                        getOptionLabel={(option) => option.nome}
                                                        defaultValue={funilIds}
                                                        filterSelectedOptions
                                                        onChange={(event, newValue) => {
                                                            setFunilIds(newValue);
                                                        }}
                                                        renderTags={(value, getTagProps) =>
                                                            value.map((option, index) => (<Chip key={index} variant="outlined" label={option.nome} {...getTagProps({ index })} />))
                                                        }
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="outlined" placeholder="Opções de funil" />
                                                        )}
                                                    />
                                                } />
                                        )}
                                    </Stack>
                                )}

                                {tipoCampanha === 'tag' && (

                                    <Stack sx={{ mt: 3, mb: 3 }}>
                                        <Autocomplete
                                            multiple
                                            variant="outlined"
                                            fullWidth
                                            options={optionsTags}
                                            getOptionLabel={(option) => option.nome}
                                            defaultValue={tagsIds}
                                            filterSelectedOptions
                                            onChange={(event, newValue) => {
                                                setTagsIds(newValue);
                                            }}
                                            renderTags={(value, getTagProps) =>
                                                value.map((option, index) => (<Chip key={index} variant="outlined" label={option.nome} {...getTagProps({ index })} />))
                                            }
                                            renderInput={(params) => (
                                                <TextField {...params} variant="outlined" label="Selecionar Tags" placeholder="Opções de funil" />
                                            )}
                                        />
                                    </Stack>
                                )}




                                <Stack spacing={2}>
                                    <Stack sx={{ mt: 3 }} alignItems={'center'} spacing={2} direction={'row'}>
                                        <DatePicker
                                            label="Data envio"
                                            inputFormat="dd/MM/yyyy"
                                            value={new Date(dataCampanha)}
                                            minDate={new Date()}
                                            onChange={(newValue) => {
                                                setDataCampanha(newValue);
                                            }}
                                            renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
                                        />
                                        <FormControl sx={{ width: 100 }}>
                                            <InputLabel id="demo-simple-select-label">Hora</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={horaCampanha}
                                                label="Hora"
                                                size='small'
                                                onChange={handleChangeHoraCampanha}
                                            >
                                                <MenuItem value={'8'}>08</MenuItem>
                                                <MenuItem value={'9'}>09</MenuItem>
                                                <MenuItem value={'10'}>10</MenuItem>
                                                <MenuItem value={'11'}>11</MenuItem>
                                                <MenuItem value={'12'}>12</MenuItem>
                                                <MenuItem value={'13'}>13</MenuItem>
                                                <MenuItem value={'14'}>14</MenuItem>
                                                <MenuItem value={'15'}>15</MenuItem>
                                                <MenuItem value={'16'}>16</MenuItem>
                                                <MenuItem value={'17'}>17</MenuItem>
                                                <MenuItem value={'18'}>18</MenuItem>

                                            </Select>
                                        </FormControl>

                                        <FormControl sx={{ width: 100 }}>
                                            <InputLabel id="demo-simple-select-label">Minutos</InputLabel>
                                            <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={minutoCampanha}
                                                label="Minutos"
                                                size='small'
                                                onChange={handleChangeMinutoCampanha}
                                            >
                                                <MenuItem value={'0'}>0</MenuItem>
                                                <MenuItem value={'10'}>10</MenuItem>
                                                <MenuItem value={'20'}>20</MenuItem>
                                                <MenuItem value={'30'}>30</MenuItem>
                                                <MenuItem value={'40'}>40</MenuItem>
                                                <MenuItem value={'50'}>50</MenuItem>

                                            </Select>
                                        </FormControl>
                                    </Stack>
                                    {!isEdit && (
                                        horaCampanha && minutoCampanha && <Typography variant='body'>Sua campanha comecará em {fDate(dataCampanha)} as {horaCampanha}:{minutoCampanha}</Typography>
                                    )}
                                </Stack>

                                <FormControlLabel sx={{ mt: 3 }} control={<Switch
                                    checked={isFiltroData}
                                    disabled
                                    onChange={handleChangeIsFiltroData}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />} label="Filtrar por data" />

                                {isFiltroData && (

                                    <Stack spacing={2} alignItems={'center'} direction="row">
                                        <DatePicker
                                            label="Data Inicial"
                                            inputFormat="dd/MM/yyyy"
                                            value={new Date(dataInicio)}
                                            minDate={new Date('2017-01-01')}
                                            onChange={(newValue) => {
                                                setDataInicio(newValue);
                                            }}
                                            renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
                                        />
                                        <DatePicker
                                            label="Data Final"
                                            inputFormat="dd/MM/yyyy"
                                            value={new Date(dataFinal)}
                                            minDate={new Date('2017-01-01')}
                                            onChange={(newValue) => {
                                                setDataFinal(newValue);
                                            }}
                                            renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
                                        />
                                    </Stack>

                                )}



                                <Stack spacing={2} sx={{ mt: 5 }}>
                                    {!isFile && (
                                        <>
                                            <TextField multiline inputProps={{ maxLength: 500 }} value={body} onChange={e => setBody(e.target.value)} rows={4} placeholder='Digite aqui o texto. Para utilizar o comando nome, digite: {{nome}}' />
                                            <Typography sx={{ fontSize: 10 }}>{body.length}/500</Typography>
                                        </>
                                    )}
                                    <FormControlLabel control={<Switch
                                        checked={isFile}
                                        onChange={handleChangeIsFile}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="Inserir Arquivos" />

                                    {isFile && (
                                        <>

                                            <FormControl sx={{ mt: 5, mb: 5 }}>
                                                <FormLabel id="demo-controlled-radio-buttons-group">Tipo</FormLabel>
                                                <RadioGroup
                                                    row
                                                    aria-labelledby="demo-controlled-radio-buttons-group"
                                                    name="controlled-radio-buttons-group"
                                                    value={tipoArquivo}
                                                    onChange={e => {
                                                        setTipoArquivo(e.target.value)
                                                    }}
                                                >
                                                    <FormControlLabel value="arquivo" control={<Radio />} label="Arquivo" />
                                                    <FormControlLabel value="audio" control={<Radio />} label="Audio" />
                                                </RadioGroup>
                                            </FormControl>

                                            {tipoArquivo === 'arquivo' && (
                                                <UploadCampanha
                                                    loadingEnviarArquivos={loadingEnviarArquivos}
                                                    showPreview={preview}
                                                    files={files}
                                                    isEdit={isEdit}
                                                    isEditFile={isEditFile}
                                                    filesEdit={filesEdit}
                                                    onRemoveEdit={handleRemoveEdit}
                                                    onDrop={handleDropMultiFile}
                                                    onRemove={handleRemove}

                                                />
                                            )}

                                            {tipoArquivo === "audio" && (
                                                <>
                                                    {recording ? (
                                                        <Stack direction="column" spacing={2}>
                                                            <Stack spacing={2} direction="row" alignItems={'center'}>
                                                                <Typography variant="subtitle2">
                                                                    Gravação em andamento
                                                                </Typography>
                                                                <CircularProgress size={15} />
                                                            </Stack>
                                                            <Stack direction="row">
                                                                <IconButton
                                                                    aria-label="cancelRecording"
                                                                    component="span"
                                                                    fontSize="large"
                                                                    disabled={loadingAudio}
                                                                    onClick={handleCancelAudio}
                                                                >
                                                                    <Iconify icon="mdi:cancel" />
                                                                </IconButton>
                                                                {loadingAudio ? (
                                                                    <div>
                                                                        <CircularProgress />
                                                                    </div>
                                                                ) : (
                                                                    <RecordingTimer />
                                                                )}

                                                                <IconButton
                                                                    aria-label="sendRecordedAudio"
                                                                    component="span"
                                                                    onClick={pararGravacao}
                                                                    disabled={loadingAudio}
                                                                >
                                                                    <FaCheck />
                                                                </IconButton>
                                                            </Stack>

                                                        </Stack>
                                                    ) : (
                                                        <Stack direction={'row'}>

                                                            {!audioGravado && (
                                                                <Button
                                                                    startIcon={<BsMicFill />}
                                                                    variant='contained'
                                                                    size="small"
                                                                    component="span"
                                                                    disabled={loadingAudio}
                                                                    onClick={handleStartRecording}
                                                                >
                                                                    Gravar audio
                                                                </Button>
                                                            )}

                                                            {audioGravado && (
                                                                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                                                    <audio controls>
                                                                        <source src={URL.createObjectURL(audioGravado)} type="audio/mp3" />
                                                                        Seu navegador não suporta a reprodução de áudio.
                                                                    </audio>
                                                                    <IconButton onClick={() => setAudioGravado(null)} variant='outlined' color="error">
                                                                        <Iconify icon="material-symbols:delete" />
                                                                    </IconButton>
                                                                </Stack>

                                                            )}
                                                        </Stack>

                                                    )}
                                                </>
                                            )}

                                        </>

                                    )}

                                    <FormControlLabel control={<Switch
                                        checked={isChatAtendimento}
                                        onChange={handleChangeIsChatAtendimento}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />} label="Colocar chats em atendimento" />

                                </Stack>

                                {isAlert && (
                                    <Stack sx={{ mt: 5 }}>
                                        <Alert severity="warning">Preencha todos os campos antes de continuar</Alert>
                                    </Stack>
                                )}


                                <Stack sx={{ mt: 6 }} spacing={3} direction="row" justifyContent="flex-end">
                                    <Button onClick={onCloseDrawerNovaCampanha} sx={{ mr: 1.5 }}>
                                        Cancelar
                                    </Button>
                                    <LoadingButton fullWidth onClick={isEdit ? onEditarCampanha : onCriarCampanha} loading={loadingEnviarArquivos} variant="contained" >
                                        {isEdit ? "Editar" : "Criar"} Campanha
                                    </LoadingButton>
                                </Stack>
                            </Stack>
                        </Container>
                    </Drawer>




                    {/* <UserListToolbar numSelected={selected.length} filterName={filterName} onFilterName={handleFilterByName} /> */}
                    <Tabs
                        allowScrollButtonsMobile
                        variant="scrollable"
                        scrollButtons="auto"
                        value={filterStatus}
                        onChange={onChangeFilterStatus}
                        sx={{ px: 2, mb: 3, bgcolor: 'background.neutral' }}
                    >
                        {STATUS_OPTIONS.map((tab) => (
                            <Tab disableRipple key={tab.value} label={tab.label} value={tab.value} />
                        ))}
                    </Tabs>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 400 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={tableData.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />

                                <TableBody>
                                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { _id, nome, data_envio, nome_usuario, status, status_envio, tipo } = row;

                                        const isItemSelected = selected.indexOf(status_envio) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={_id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >

                                                <TableCell align="left">
                                                    {nome}
                                                </TableCell>

                                                <TableCell align="left">
                                                    <Label
                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                        color={(status_envio === 'em andamento' && 'warning') || (status_envio === 'agendado' && 'info') || (status_envio === 'finalizado' && 'success') || (status_envio === 'erro' && 'error') || 'default'}
                                                    >
                                                        {status_envio}
                                                    </Label>
                                                </TableCell>

                                                <TableCell align="left">
                                                    {nome_usuario}
                                                </TableCell>

                                                <TableCell align="left">
                                                    {tipo}
                                                </TableCell>

                                                <TableCell align="left">{fDateTimeSuffix(new Date(data_envio))}</TableCell>

                                                <TableCell align="left">
                                                    <Switch
                                                        checked={status}
                                                        onChange={(event) => onEditarOnlyStatus(event, _id)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />


                                                </TableCell>




                                                <TableCell align="left">
                                                    <CampanhasMoreMenu
                                                        onEdit={() => handleOpenDialogEditarCampanha(row)}
                                                        onDelete={() => handleOpenDialogExcluirCampanha(_id)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={tableData.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </TableBorder>
            </div>
        </Container>
    );
}


function applySortFilter({ tableData, filterStatus }) {

    if (filterStatus !== null) {
        tableData = tableData.filter((item) => item.status_envio === filterStatus);
    }

    return tableData;
}

