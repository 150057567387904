
import { Tab, Box, Tabs, Stack, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import LaisConfig from './tabs/MessageConfig';
import v1_actionEditarFlow from 'src/actions/v1/clientes/v1_actionEditarFlow';
import { useSnackbar } from 'notistack';
import TrainingConfig from './tabs/TrainingConfig';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import Laisv2ConfigTab from './tabs/Laisv2ConfigTab';

export default function ChannelLaisAiV2({ 
    isEdit,
    canal, 
    onChange,
    config,
    listSectors,
    listUsers,
    cliente
 }) {
    const [currentTab, setCurrentTab] = useState('config');


    const ACCOUNT_TABS = [
        {
            value: 'config',
            name: 'Configurações',
            icon: <Iconify icon="streamline:ai-settings-spark-solid" width={20} height={20} />,
            component: <Laisv2ConfigTab
            onChange={onChange}
            canal={canal}
            config={config}
            cliente={cliente}
            />
        },
        // {
        //     value: 'treinamento',
        //     name: 'Treinamento',
        //     icon: <Iconify icon="eos-icons:machine-learning-outlined" width={20} height={20} />,
        //     component: <TrainingConfig />
        // },
        {
            value: 'direcionamentos',
            name: 'Direcionamentos',
            icon: <Iconify icon="eos-icons:machine-learning-outlined" width={20} height={20} />,
            component:<TrainingConfig
            listSectors={listSectors}
            listUsers={listUsers}
            />
            
        },


    ];

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };



    return (
            <Box>
                <Stack spacing={2}>
                    <Stack sx={{ mt: 1 }} spacing={2}>
                        <Tabs
                            value={currentTab}
                            scrollButtons="auto"
                            variant="scrollable"
                            allowScrollButtonsMobile
                            onChange={handleChangeTab}
                        >
                            {ACCOUNT_TABS.map((tab) => (
                                <Tab disableRipple key={tab.value} label={tab.name} icon={tab.icon} value={tab.value} />
                            ))}
                        </Tabs>
                        <Stack sx={{ mt: 2 }}>
                            {ACCOUNT_TABS.map((tab) => {
                                const isMatched = tab.value === currentTab;
                                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                            })}
                        </Stack>
                    </Stack>
                </Stack>
            </Box>
    );
}
