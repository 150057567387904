import axios from "axios";
import Config from "../../../config";

const v2_actionEditarContato = (contatoId, data) => {
    
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/cadastro/contatos/${contatoId}/editar`,
        data
    });
};

export default v2_actionEditarContato;
