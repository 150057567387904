import { Stack,  } from '@mui/material';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
// @mui
// redux
// routes
// hooks
import Page from 'src/components/Page';

//paginas
import Campanhas from 'src/pages/chats/Campanhas';
import MensagensAgendadas from 'src/pages/chats/MensagensAgendadas';

import MarketingSidebar from 'src/sections/@dashboard/marketing/MarketingSidebar/MarketingSidebar';


export default function Marketing() {
  const { label } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);
  return (
    <Page title="Marketing">
      <Box sx={{
        // height: { md: '85vh' },
        display: { md: 'flex' }
      }}
      >
        <Stack sx={{ width: '100%' }} direction={'row'} spacing={4}>
          <MarketingSidebar
            isOpenSidebar={openSidebar}
            onCloseSidebar={() => setOpenSidebar(false)}
          />

          <Box style={{ width: '100%' }}>
            {label === "campanhas" && <Campanhas/> }
            {label === "agendamentos" && <MensagensAgendadas />}
  
          </Box>






        </Stack>


      </Box>

    </Page>
  );
}
