import { CircularProgress, LinearProgress, styled, Box, Typography } from '@mui/material';
import Logo from './Logo';


const RootStyle = styled('div')(({ theme }) => ({
  height: '100%',
  display: 'flex',
  alignItems: 'center',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  //background: 'radial-gradient(circle, rgb(255, 245, 255) 0%, rgba(229,245,255,1) 50%, rgba(204,233,255,1) 100%)'

  backgroundColor: theme.palette.grey[100]
}));

// ----------------------------------------------------------------------

export default function LoadingScreen({ ...other }) {
  return (
    <RootStyle {...other}>
      <Logo />
      <Box sx={{ width: '30%', justifyContent: 'center', mt: 2 }}>
        <LinearProgress />
      </Box>
      <Typography variant='caption' sx={{ mt: 3 }}>Carregando...</Typography>
    </RootStyle>
  );
}
