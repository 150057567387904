// material
import { 
     Button,
     Dialog,
     DialogTitle,
     DialogActions,
     DialogContent,
     MenuItem,
     FormControl,
     Grid,
     Divider,
     TextField
} from '@mui/material';


// ----------------------------------------------------------------------

export default function ModalFunilChat({
  closeModalFunilChat, 
  valorEscolhidoFunilProps,
  handleFunil,
  listaFunilProps,
  stateModalFunilProps,
  submitFunilChat
}) {



  return (
    <div>

      <Dialog 
      open={stateModalFunilProps}
      fullWidth={true}
      maxWidth={'sm'}
      >
        <DialogTitle>Funil de Vendas</DialogTitle>
        <DialogContent>
    
              <Grid container spacing={2}>
                     <Grid item md={6}>
                          <FormControl  fullWidth sx={{ m: 1, minWidth: 120 }}>
                          <TextField
                            select
                            fullWidth
                            //label="Select"
                            value={valorEscolhidoFunilProps}
                            onChange={event => handleFunil(event)}
                          
                          >
                        {listaFunilProps.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            {option.nome_funil}
                          </MenuItem>
                        ))}
                        </TextField>
                          </FormControl>
                        </Grid>
              
              
            </Grid>
       
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={closeModalFunilChat}>Cancelar</Button>
          <Button variant="contained" onClick={submitFunilChat} autoFocus>Salvar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
