import PropTypes from 'prop-types';
import { useState } from 'react';
// @mui
import { useTheme } from '@mui/material/styles';
import {  TableRow, TableCell, IconButton } from '@mui/material';
// utils
import {  fDateTime } from 'src/utils/formatTime';

// components
import Label from 'src/components/Label';

import { BiRightArrowAlt, BiSolidError } from 'react-icons/bi';



// ----------------------------------------------------------------------

ClientesForcaVendasRow.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function ClientesForcaVendasRow({ row, selected, onViewRow }) {
  const theme = useTheme();

  const { nome_cliente, numero, atendente, data_visita, converteu, telefone_ddd } = row;

  const [openMenu, setOpenMenuActions] = useState(null);

  const handleOpenMenu = (event) => {
    setOpenMenuActions(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setOpenMenuActions(null);
  };





  return (
    <TableRow hover selected={selected}>

      <TableCell align="left">{nome_cliente}</TableCell>

      <TableCell align="left">{telefone_ddd}</TableCell>

      <TableCell align="left">{fDateTime(data_visita)}</TableCell>

      <TableCell align="left">{atendente}</TableCell>


      <TableCell sx={{ textTransform: 'capitalize' }}>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={
            (converteu === true && 'success') ||
            (converteu === false && 'error') ||
            'error'
          }
          sx={{ textTransform: 'capitalize' }}
        >
          {converteu ? 'Sim' : 'Não'}
        </Label>
      </TableCell>

    </TableRow>
  );
}
