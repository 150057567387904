import axios from "axios";
import Config from "src/config";

const v2_actionCriarTreinamentoAgente = (clienteId, flowId, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/${clienteId}/flows/${flowId}/treinamentos/criar`,
        data
    });
};

export default v2_actionCriarTreinamentoAgente;
