
import {
    Autocomplete,
    Box,
    Divider,
    Grid,
    Stack,
    Typography,
    TextField,
    Alert,
    Button
} from '@mui/material';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyleMultline from 'src/components/InputStyleMultline';
import { useEffect, useState } from 'react';
import v1_actionEditarFlow from 'src/actions/v1/clientes/v1_actionEditarFlow';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';

export default function ConfigFlow({
    changeNome,
    changeSobrenome,
    flow

}) {

    const { enqueueSnackbar } = useSnackbar();

    const [allowsInChat, setAllowsInChat] = useState([]);
    const [dataFlow, setDataFlow] = useState({
        name: '',
        config: {
            flowId: '',
            messages: {
                msgBreakFiles: "",
                msgBreakAudios: "",
                msgImages: "",
                messageCallback: ""
            },
            persona: '',
            timeClearSessions: '',
            prompt: '',
            persona: '',
            allowsInChat: allowsInChat
        }
    });


    useEffect(() => {
        setDataFlow({
            name: flow?.name,
            config: {
                flowId: flow?.config.flowId,
                messages: {
                    msgBreakFiles: flow?.config.messages.msgBreakFiles,
                    msgBreakAudios: flow?.config.messages.msgBreakAudios,
                    msgImages: flow?.config.messages.msgImages,
                    messageCallback: flow?.config.messages.messageCallback
                },
                persona: flow?.config.persona,
                timeClearSessions: flow?.config.timeClearSessions,
                prompt: flow?.config.prompt,
                persona: flow?.config.persona,
                allowsInChat: flow?.config.allowsInChat ? flow?.config.allowsInChat : []
            }
        });
        setAllowsInChat(flow?.config.allowsInChat ? flow?.config.allowsInChat : []);
    }, []);

    async function handleSaveFlow() {
        try {

            let data = {
                scope: dataFlow
            }
            await v1_actionEditarFlow(flow._id, data);
            enqueueSnackbar('Fluxo alterado com sucesso', { variant: 'success' });
        } catch (error) {
            enqueueSnackbar('Erro ao editar fluxo', { variant: 'error' });

        }
    }


    return (
        <Stack sx={{ mt: 3 }} spacing={4}>

            <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                <Stack spacing={{ xs: 2, md: 3 }}>
                    <Box sx={{ width: '100%' }}>
                        <Stack direction="row" spacing={2}>
                            <RowInput
                                sx={{ width: '30%' }}
                                label="nome do fluxo"
                                input={<InputStyle
                                    size="small"
                                    value={dataFlow?.name}
                                    fullWidth
                                    onChange={(e) =>
                                        setDataFlow({
                                            ...dataFlow,
                                            name: e.target.value
                                        })
                                    }
                                />}
                            />
                            <RowInput
                                sx={{ width: '20%' }}
                                label="id do fluxo"
                                input={<InputStyle
                                    size="small"
                                    disabled

                                    value={dataFlow?.config?.flowId}
                                    fullWidth
                                />}
                            />
                        </Stack>
                    </Box>
                </Stack>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', borderColor: '#ccc' }} />

            <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                <Stack spacing={{ xs: 2, md: 3 }}>
                    <Typography variant='h6'>tipos de mensagens</Typography>
                    <Stack spacing={2}>

                        <RowInput
                            sx={{ width: '100%' }}
                            label="tipo de mensagens permitidas"
                            input={<Autocomplete
                                multiple
                                id="tags-standard"
                                value={dataFlow?.config?.allowsInChat}
                                options={[
                                    "imagem",
                                    "audio",
                                    "arquivos"
                                ]}
                                onChange={(event, newValue) => setDataFlow({
                                    ...dataFlow,
                                    config: {
                                        ...dataFlow.config,
                                        allowsInChat: newValue
                                    }
                                })}

                                //     renderTags={(value, getTagProps) =>
                                //         value.map((option, index) => (
                                //             <Typography variant="body2" sx={{ fontWeight: 600 }} key={index} {...getTagProps({ index })}>
                                //                 {option}
                                //             </Typography>
                                //         ))
                                //    }
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        size='small'
                                        {...params}
                                    />
                                )}
                            />}
                        />

                        {dataFlow?.config?.allowsInChat?.includes('audio') && (
                            <Alert severity="info">
                                <Typography variant="body2">
                                    Com a opção de permissão de audio nas mensagens ativado, serão debitados os tokens necessários para transcrição do audio.
                                </Typography>
                            </Alert>
                        )}




                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <RowInput
                            sx={{ width: '100%' }}
                            label="Mensagem recusa envio de audios"
                            onChange={e => setDataFlow({
                                ...dataFlow,
                                config: {
                                    ...dataFlow.config,
                                    messages: {
                                        ...dataFlow.config.messages,
                                        msgBreakAudios: e.target.value
                                    }
                                }
                            })
                            }
                            input={<InputStyleMultline multiline rows={5} size="small" onChange={changeNome} value={dataFlow?.config?.messages?.msgBreakAudios} fullWidth />}
                        />
                        <RowInput
                            sx={{ width: '100%' }}
                            label="Mensagem recusa envio de imagens"
                            input={<InputStyleMultline
                                multiline
                                onChange={e => setDataFlow({
                                    ...dataFlow,
                                    config: {
                                        ...dataFlow.config,
                                        messages: {
                                            ...dataFlow.config.messages,
                                            msgImages: e.target.value
                                        }
                                    }
                                })
                                }
                                rows={5}
                                size="small"

                                value={dataFlow?.config?.messages?.msgImages}
                                fullWidth
                            />}
                        />
                        <RowInput
                            sx={{ width: '100%' }}
                            label="Mensagem recusa envio de arquivos"
                            input={<InputStyleMultline
                                multiline
                                rows={5}
                                size="small"
                                onChange={e => setDataFlow({
                                    ...dataFlow,
                                    config: {
                                        ...dataFlow.config,
                                        messages: {
                                            ...dataFlow.config.messages,
                                            msgBreakFiles: e.target.value
                                        }
                                    }
                                })
                                }
                                value={dataFlow?.config?.messages?.msgBreakFiles}
                                fullWidth
                            />}
                        />
                    </Stack>
                </Stack>
            </Box>

            <Divider sx={{ borderStyle: 'dashed', borderColor: '#ccc' }} />

            <Stack direction={"row"}>
                <Button
                    onClick={handleSaveFlow}
                    sx={{ width: '20%', }}
                    variant='contained'
                    size="large"
                    startIcon={<Iconify icon="majesticons:save-line" color="white" width={20} height={20} />}
                >
                    <Typography variant="body2" sx={{ fontWeight: 600, textTransform: 'lowercase' }}>
                        Salvar
                    </Typography>
                </Button>
            </Stack>

            {/* 
            <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                <Stack spacing={{ xs: 2, md: 3 }}>
                    <Typography variant='h6'>agente de vendas</Typography>
                    <Stack>

                        <Stack direction="row" spacing={2}>
                            <RowInput
                                sx={{ width: '10%' }}
                                label="tempo pedido no carrinho"
                                input={<InputStyle size="small" onChange={changeNome} value={nome} fullWidth />}
                            />
                        </Stack>

                    </Stack>
                </Stack>
            </Box> */}


        </Stack>

    );
}
