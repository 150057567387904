import {
    Button,
    Divider,
    Stack, 
    Typography, 
    Drawer, 
    IconButton, 
    Box,
    Accordion,
    AccordionSummary    
} from '@mui/material';
import 'react-phone-number-input/style.css'
import { useState, forwardRef, useEffect } from 'react';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import NovoContatoForm from './NovoContatoForm';
import { validarNumeroWhatsapp } from 'src/utils/functions';
import NovaEmpresaForm from './NovaEmpresaForm';

export default function ModalNovaEmpresa({
    open,
    onClose,
    cliente,
    chat
}) {


    function sair() {
        onClose();
    }

    function addContact(data) {
        let {contato, endereco} = data
        let newContact = {
         contato: {
            ...contato,
            whatsapp: validarNumeroWhatsapp(contato.whatsapp),
         },
         endereco
        }
        setContatosEmpresa([...contatosEmpresa, newContact])
    }

    return (
        <div>

            <Drawer
                open={open}
                onClose={() => sair()}
                anchor="right"
                PaperProps={{ sx: { height:'100vh', width: { xs: 1, sm: 400 } } }}
            >

  

                <Stack justifyContent={'space-between'} direction="column" spacing={4} sx={{ p: 3 }}>
                    <Stack direction="column">
                    <Stack sx={{ mb: 3 }} justifyContent={'space-between'} direction="row" alignItems={'center'}>
                        <Typography variant="h6">Criar Empresa</Typography>
                        <IconButton  onClick={() => sair()} >
                            <Iconify icon={'eva:close-outline'} sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    </Stack>

                    <Stack justifyContent={'space-between'} direction="column">




                   <NovaEmpresaForm
                     addContact={addContact}

                     />


                
                    </Stack>
                </Stack>
                <Stack direction="row">
                            <Button sx={{ height: 35, fontSize: 12, borderRadius: 0.5 }} variant="contained" >
                                Criar Empresa
                            </Button>
                        </Stack>
                </Stack>
            </Drawer>
        </div>
    );
}
