import { ListItemText, ListItemButton } from '@mui/material';
import PropTypes from 'prop-types';
import { NavLink as RouterLink } from 'react-router-dom';
// @mui

const linkTo = (label) => {
  return `/dashboard/marketing/${label.slug}`;

};

// ----------------------------------------------------------------------

MarketingSidebarItem.propTypes = {
  label: PropTypes.object.isRequired,
};

export default function MarketingSidebarItem({ label, ...other }) {

  return (
    <ListItemButton
      to={linkTo(label)}
      component={RouterLink}
      sx={{
        px: 3,
        height: 48,
        typography: 'caption',
         fontFamily:'Arial',
        textTransform: 'capitalize',
        // '&.active': {
        //   color: 'text.primary',
        //   fontWeight: 'fontWeightMedium',
        //   bgcolor: 'action.selected',
        // },
      }}
      {...other}
    >
      {/* <ListItemIcon>
        <Iconify icon={label.icon}  width={24} height={24} />
      </ListItemIcon> */}

      <ListItemText disableTypography primary={label.nome} />

    </ListItemButton>
  );
}
