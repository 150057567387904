// @mui
import PropTypes from 'prop-types';
import { Box } from '@mui/material';


export default function TableBorder({ children }) {
  return (
    <Box sx={{ borderColor: theme => theme.palette.grey[300], borderStyle: 'solid', borderWidth: 1, borderRadius: 1, p: 1 }}>
      {children}
    </Box>
  );
}
