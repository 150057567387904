
import styled, { css } from "styled-components";

import DownloadImage from "../../../../../../assets/ic_download_chat.svg";

export const ChatLayout = styled.div`
  width: 100%;
  ${({ side }) =>
    side !== "left" &&
    css`
      display: flex;
      flex-direction: row-reverse;
    `};
`;

export const MessageContainer = styled.div`
  .msg-title-top {
    margin-bottom: 10px;
    padding-right: 20px;
    width: fit-content;
    color: #28a745;
    font-weight: bold;
  }

  width:  ${({ isDesktop }) => (isDesktop ? 'fit-content' : '100%')};
  font-size: 12px;
  max-width:100%;
  height: auto;
  position: relative;
  box-shadow: 0px 2px 1px -1px rgba(145, 158, 171, 0.2),0px 1px 1px 0px rgba(145, 158, 171, 0.14),0px 1px 3px 0px rgba(145, 158, 171, 0.12);
  display: flex;
  padding: 5px 5px;
  margin:5px 0;
  flex-direction: column;
  flex-wrap: wrap;
  color: ${({ side }) => (side === "left" ? "#333" : "#333")};
  border-radius: 5px;

  ${({ isWarning }) =>
    !isWarning &&
    css`
      background: ${({ side }) => (side === "left" ? "#fff" : "#f6ffed")};
      box-shadow: 0px 2px 1px -1px rgba(145, 158, 171, 0.2),0px 1px 1px 0px rgba(145, 158, 171, 0.14),0px 1px 3px 0px rgba(145, 158, 171, 0.12)
    `}
  .caption {
    bottom: 0;
    padding: 10px;
  }

  ${({ isWarning }) =>
    isWarning &&
    css`
      width: 100%;
      
      text-align: center;

      .msg-title-top,
      span {
        width: 100%;
        text-align: center;
      }
    `}
`;

export const MessageContent = styled.div`
  display: flex;
  margin: 3px 0;
  position: relative;


  span {
    display: block;
    width: 100%;
  }

  .download {
    position: absolute;
    background-image: url("${DownloadImage}");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    padding: 2em;
    margin: auto;
    z-index: 1;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 80px;
    height: 80px;
  }
`;

export const ImageContainer = styled.div`

  cursor: pointer;
  display: flex;
  flex-direction: column;
  position: relative;

  img {
    width: 100%;
    height:100%;
    cursor: pointer;
    min-height:330px;
    max-height:330px;
    border-radius: 5px;
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    cursor: pointer;
    max-height:300px;
  }
`;

export const DocumentComponent = styled.div`
  width: 100%;
  ${({ isWarning }) =>
    isWarning &&
    css`
      max-width: 500px;
    `}
  background: #fffefe;
  color: #333;
  padding: 1em 10px;
  min-width: 250px;
  border-radius: 5px;
  cursor: pointer;
  border: 1px solid #f0f0f0;
  svg {
    color: #909090;
    border-radius: 50%;
    padding: 5px;
    width: 30px;
    height: 30px;
  }

  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StickerComponent = styled.img`
  width: 125px;
  height: 125px;
  object-fit: contain;
`;

export const MessageContentText = styled.div`
  width: 100%;
  margin: 3px 0;
  position: relative;
  overflow-wrap: break-word;
  white-space: pre-wrap;
  padding:5px;
  min-width:150px;
  font-size: 14px;

  a {
    color: ${({ side }) => (side === "left" ? "#333" : "#fff")};
  }
`;
