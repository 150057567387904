
import { Box, Icon, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { Button } from 'src/@/components/ui/button';
import { Label } from 'src/@/components/ui/label';


import {
    Select,
    SelectContent,
    SelectGroup,
    SelectItem,
    SelectLabel,
    SelectTrigger,
    SelectValue,
} from "src/@/components/ui/select"
import { Switch } from 'src/@/components/ui/switch';
import { ZuiTooltip } from 'src/@/components/use/tooltip';
import v2_actionAgenteAi from 'src/actions/v2/ai/v2_actionAgenteAi';
import v2_actionListaAgentesAi from 'src/actions/v2/ai/v2_actionListaAgentesAi';
import Iconify from 'src/components/Iconify';

export default function Laisv2ConfigTab({
    cliente,
    config,
    onChange
}) {
    const navigate = useNavigate();
    const [agents, setAgents] = useState([]);

    async function getAiAgents() {
        try {
            const response = await v2_actionListaAgentesAi(cliente._id);
            if (response.status === 200) {
                setAgents(response.data.data);
            }
        } catch (error) {
            console.log('error', error);
        }
    }

    useEffect(() => {
        getAiAgents();
    }, []);


    return (

        <div className='mt-5 flex flex-col gap-4'>


            <div className='mt-5 flex flex-row gap-4'>
                <Select
                    value={config?.laisai_v2?.ai_agent_id}
                    onValueChange={value => {
                        let e = {
                            target: {
                                name: 'laisai_v2.ai_agent_id',
                                value: value
                            }
                        }
                        onChange(e);
                    }}
                >
                    <SelectTrigger className="w-[250px]">
                        <SelectValue placeholder="Selecione um agente" />
                    </SelectTrigger>
                    <SelectContent>
                        <SelectGroup>
                            <SelectLabel>Agentes</SelectLabel>
                            {agents.map((agent) => (
                                <SelectItem key={agent._id} value={agent._id}>{agent.name}</SelectItem>
                            ))}
                        </SelectGroup>
                    </SelectContent>
                </Select>

                <Button
                variant="secondary"
                    className="bg-slate-200"
                    onClick={() => navigate('/dashboard/configuracoes/agentes-ia')}
                >
                    <Iconify name="material-symbols-light:groups-outline" className="mr-2" />
                    Ver agentes
                </Button>
            </div>

        </div>

    );
}
