import { useEffect, useState, useRef } from 'react';
// material
import {Box, Card, Container, Stack, Typography} from '@mui/material';

// components;
import Page from 'src/components/Page';
// configs
import { nomeProjeto } from 'src/config';
import { BsFillKanbanFill, BsFillFileBarGraphFill, BsKanban } from 'react-icons/bs';
import { MdFlashOn} from 'react-icons/md';

import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useNavigate } from 'react-router';

export default function Gerencia() {
    const navigate = useNavigate()

    const ItemRelatorio = ({titulo, icon, descricao, link}) => {
        return (
            <Card onClick={link} sx={{p: 2, m: 2, borderRadius:1, borderWidth:1, borderColor:'#ccc', cursor:'pointer'}}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    {icon}
                    <Typography variant="h6">{titulo}</Typography> 
                    <Typography variant="caption">{descricao}</Typography> 
                </Stack>

            </Card>
        )
    }
 
  return (
      <Container maxWidth>
         <HeaderBreadcrumbs
                    heading="Gerência"
                    links={[
                        { name: 'Lista de Relatorios' }
                    ]}
                    // action={
                    //     <Button variant="contained" onClick={() => setDrawerAgendamento(true)}> Criar Agendamento</Button>
                    // }
                />
            <Box>

          <ItemRelatorio icon={<BsFillFileBarGraphFill size={25} />} titulo="Supervisionar" descricao="Supervisionar" link={() => navigate('/dashboard/app')} />
          </Box>
      </Container>
  );
}
