import { styled } from '@mui/material/styles';
import { Stack, Box, Avatar, ListItemButton, Menu, MenuItem, Typography, Badge, Icon } from '@mui/material';
import { formatDistanceToNowStrict } from 'date-fns';
import ptBR from 'date-fns/locale/pt-BR';
// material
import { useState } from "react";
import { HiChevronDown } from "react-icons/hi";
import { RiWhatsappFill, RiInstagramLine } from "react-icons/ri";

//utils
import { formataNumeroCelular, verifyRules } from "src/utils/functions";
import BadgeStatus from 'src/components/BadgeStatus';
import { TagItem } from 'src/sections/@chats/chat/TagItem';
import Label from 'src/components/Label';
import { Button } from 'src/@/components/ui/button';
import TagsComponent from 'src/components/tags/TagsComponent';
import { Card } from 'src/@/components/ui/card';
import Iconify from 'src/components/Iconify';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import { ZuiTooltip } from 'src/@/components/use/tooltip';
import { useSnackbar } from 'notistack';
import {

  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuShortcut,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import v2_actionFinalizarChat from 'src/actions/v2/chats/v2_actionFinalizarChat';
import useAuth from 'src/hooks/useAuth';
import { getChatsAtendimento, getChatsFinalizados, getChatsGeral, removeChatFromList } from 'src/redux/slices/chat';
import { useDispatch } from 'react-redux';
import { toast } from 'src/@/components/ui/use-toast';


const TitleChat = ({ chat }) => {
  const formattedNumber = chat?.numeroCliente?.startsWith("55")
    ? formataNumeroCelular(chat.numeroCliente)
    : `+${chat.numeroCliente}`;

  const displayText = chat.nomeCliente
    ? chat.nomeCliente
    : formattedNumber;

  return <small className="text-sm font-semibold">{displayText}</small>;
};

const DropDownMenuOptions = ({
  chat,
  onFinishWithMsg,
  onFinishWithoutMsg,
  onMarkAsUnread,
  user
}) => {

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button className="w-5 h-5" size="icon" variant="outline"><Iconify width={10} icon="fe:arrow-down" /></Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>
            <span>Finalizar chat</span>
          </DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <DropdownMenuItem onClick={onFinishWithMsg}>
                <span>Finalizar com mensagem</span>
              </DropdownMenuItem>
              {verifyRules(user.rules, "finalizar_sem_mensagem") && (
                <DropdownMenuItem onClick={onFinishWithoutMsg}>
                  <span>Finalizar sem mensagem</span>
                </DropdownMenuItem>

              )}

            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
        {chat.novaMensagem !== 'sim' && (
          <DropdownMenuItem onClick={onMarkAsUnread}>
            <span>Marcar não lido</span>
          </DropdownMenuItem>
        )}

      </DropdownMenuContent>
    </DropdownMenu>

  )
}

export default function ChatAtendimentoItem({
  chat,
  onSelectConversation,
  actionMarcarNaoLido,
  isOpenSidebar,
  onFinishChat,
  key,
  disabled,
  onAceitarChat,
  searchItem
}) {



  const [anchorEl, setAnchorEl] = useState(null);
  const openMenu = Boolean(anchorEl);
  const statusChat = chat?.statusRoom;
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useAuth();
  const dispatch = useDispatch();

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleSelectChat() {
    if (statusChat === "Pendente") {
      if (user.level !== "root") {
        enqueueSnackbar("Esse chat está Pebdente, aceite para visualizar a conversa", { variant: "error" });
        return;
      }
    }
    onSelectConversation();
  }



  const options = [
    {
      label: "Marcar Não lido",
      method() {
        setAnchorEl(null);
        actionMarcarNaoLido();
      },
    },
  ];

  function validaCanal() {
    if (chat.canal === "instagram") {
      return <RiInstagramLine size={13} color="#ff1803" />
    } else if (chat.canal === "whatsapp") {
      return <RiWhatsappFill size={13} color="#00a884" />
    } else {
      return <RiWhatsappFill size={13} color="#00a884" />
    }
  }

  function formatLastMessage() {
    if (!chat?.lastMessageWpp?.message) return "";
    let lastMessage = chat?.lastMessageWpp?.message;

    if (chat?.lastMessageWpp?.fromMe) {
      lastMessage = lastMessage.replace(/\*_[^_]+_\*/g, '');
      lastMessage = "Você: " + lastMessage;
      if (lastMessage.length > 35) {
        lastMessage = lastMessage.substring(0, 35) + "...";
      }
      return lastMessage || "";
    }

    if (lastMessage.length > 35) {
      lastMessage = lastMessage.substring(0, 35) + "...";
    }

    return lastMessage || "";
  }




  const handleFinalizarChat = async (chatId, sendMessage, statusOrigin) => {
    let data = {
      userId: user.id,
      sendMessage: sendMessage
    };
    try {
      dispatch(removeChatFromList({ chatId: chatId, status: statusOrigin }))
      await v2_actionFinalizarChat(chatId, data);
      toast("Chat finalizado com sucesso", {
        // description: "Sunday, December 03, 2023 at 9:00 AM",
        action: {
          label: "Fechar",
          onClick: () => console.log("Fechar"),
        }
      });
      dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));

      // if (chat._id === conversationKey) return navigate(`${PATH_CHAT.chat.root}`);

    } catch (error) {
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 100, false));
      enqueueSnackbar("Erro ao finalizar chat", { variant: "error" });
      console.log("Erro ao finalizar chat");
    }

  };



  return (
    <Card className={`my-2 mr-1 border-b-1 p-2 shadow-sm ${chat.novaMensagem === 'sim' && chat?.statusRoom === "Atendendo" ? "bg-violet-50" : "bg-slate-50 "}`} key={key}>

      <Stack direction={"row"}>
        <Box sx={{ width: '100%' }}>
          <Stack direction="column" spacing={1}>
            <Stack key={key} spacing={0.5} direction={'row'}>
              <ListItemButton
                sx={{ px: 0.5, py: 0 }}
                onClick={handleSelectChat}
                disabled={disabled}
              >

                <Stack direction={'column'} sx={{ width: '75%' }}>

                  <Stack spacing={1} direction={'row'}>
                    <Box className='flex items-center justify-center'>
                      <Badge
                        overlap="circular"
                        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
                        badgeContent={
                          <Box style={{ background: '#fff', width: 20, height: 20, alignItems: 'center', justifyContent: 'center', flex: 1, padding: 5, borderRadius: 10 }}>
                            {validaCanal()}
                          </Box>
                        }>
                        <Avatar sx={{ bgcolor: 'primary.secondary', color: '#fff', width: 35, height: 35, mt: 1 }} variant="rounded" alt={chat.nomeCliente} src={chat.fotoPerfil} />
                      </Badge>

                    </Box>
                    <Box className="w-full">
                      <Stack>
                        {chat.canal !== "instagram" && (
                          <div className='flex-col flex h-10 justify-center'>
                            <TitleChat chat={chat} />
                            {chat?.lastMessageWpp && chat?.statusRoom !== "Pendente" && (
                              <div className='flex gap-1 flex-row items-center mt-1'>
                                {/* <Iconify icon="lets-icons:message-duotone" width={15} /> */}
                                <small className='text-xs'>{formatLastMessage()}</small>
                              </div>
                            )}

                          </div>

                        )}

                        {chat.canal === "instagram" && (
                          <Typography sx={{ fontWeight: chat.novaMensagem === 'sim' ? '500' : '300', fontSize: 13 }}>
                            @{chat?.instagramUser?.profile?.username}
                          </Typography>
                        )}
                        {/* <Typography sx={{ fontSize: 10 }}>{formataNumeroCelular(chat.lastMessage)}</Typography> */}
                      </Stack>
                    </Box>
                  </Stack>
                </Stack>

                <Box sx={{
                  width: '25%',
                  backgrounColor: "red"
                }}>
                  <Stack direction={'column'} spacing={1}>
                    <Label
                      color="success"
                    >
                      <Stack direction={'row'} spacing={1}>
                        <Typography sx={{ textTransform: 'uppercase', fontSize: 7 }}> {chat.nome_canal}</Typography>
                      </Stack>
                    </Label>

                    <Stack direction={'row'} spacing={1} alignItems={'center'} justifyContent={'end'}>
                      {chat.dataUltimaMensagem && <Typography style={{ fontSize: 9, textAlign: 'right' }}>{formatDistanceToNowStrict(new Date(chat.dataUltimaMensagem), { addSuffix: false, locale: ptBR })}</Typography>}
                      {chat.novaMensagem === 'sim' && <BadgeStatus status="unread" size="medium" />}
                    </Stack>

                  </Stack>
                </Box>
              </ListItemButton>
            </Stack>




            <div className="flex items-center justify-between ">
              <div className='flex flex-row items-center gap-2 content-center'>
                <TagsComponent
                  chat={chat}
                  chatId={chat?._id}
                  tags={chat?.tags_ids || []}
                />
                {!chat?.nomeCliente || chat?.nomeCliente !== "" && (
                  <ZuiTooltip content={chat?.numeroCliente?.startsWith("55") ? formataNumeroCelular(chat.numeroCliente) : `+${chat.numeroCliente}`}>
                    <div className='mt-2'>
                      <Iconify icon="gravity-ui:circle-info" />
                    </div>
                  </ZuiTooltip>
                )}

                {chat?.usuario_atendendo && (
                  <ZuiTooltip content={chat?.usuario_atendendo}>
                    <Button variant="outline" className="h-5 px-1 mt-2" >
                      <Iconify icon="fluent:person-support-28-filled" />
                    </Button>
                  </ZuiTooltip>
                )}
              </div>

              {statusChat === "Atendendo" && (
                <DropDownMenuOptions
                  chat={chat}
                  user={user}
                  onMarkAsUnread={actionMarcarNaoLido}
                  onFinishWithMsg={() => handleFinalizarChat(chat._id, true, "Atendendo")}
                  onFinishWithoutMsg={() => handleFinalizarChat(chat._id, false, "Atendendo")}
                />
              )}

              {statusChat === 'Finalizado' && (
                <ZuiTooltip content="Pegar Chat">
                  <Button
                    size="icon"
                    variant="outline"
                    className="w-6 h-6 ml-1"
                    onClick={onAceitarChat} >
                    <Iconify icon={'fa-solid:hand-rock'} />
                  </Button>
                </ZuiTooltip>
              )}
              {statusChat === 'Geral' && (
                <div className='flex flex-row items-center gap-0.55'>
                  <ZuiTooltip content="Finalizar Chat">
                    <Button
                      size="icon"
                      variant="outline"
                      className="w-6 h-6 ml-1"
                      onClick={() => handleFinalizarChat(chat._id, false, "Geral")}
                    >
                      <Iconify icon={'material-symbols:tab-close'} />
                    </Button>
                  </ZuiTooltip>
                  <ZuiTooltip content="Pegar Chat">
                    <Button
                      size="icon"
                      variant="outline"
                      className="w-6 h-6 ml-1"
                      onClick={onAceitarChat}
                    >
                      <Iconify icon={'fa-solid:hand-rock'} />
                    </Button>
                  </ZuiTooltip>
                </div>

              )}
              {statusChat === 'Avaliando' && (
                <div className='flex flex-row items-center gap-1'>
                  <ZuiTooltip content="Chat em avaliação">
                    <Box>
                      <Iconify color="primary.main" icon={'ph:star-duotone'} width={18} height={18} />
                    </Box>
                  </ZuiTooltip>
                  <ZuiTooltip content="Pegar Chat">
                    <Button
                      size="icon"
                      variant="outline"
                      className="w-6 h-6 ml-1"
                      onClick={onAceitarChat}
                    >
                      <Iconify icon={'fa-solid:hand-rock'} />
                    </Button>
                  </ZuiTooltip>
                </div>
              )}

              {statusChat === "Pendente" && (
                <div>
                  {searchItem ? (
                    <ZuiTooltip content="Pegar Chat">
                      <Button
                        size="icon"
                        variant="outline"
                        className="w-6 h-6 ml-1"
                        onClick={onAceitarChat}
                      >
                        <Iconify icon={'fa-solid:hand-rock'} />
                      </Button>
                    </ZuiTooltip>
                  ) : (
                    <ButtonLoading
                      loading={false}
                      size="icon"
                      variant="outline"
                      className="w-6 h-6 ml-1"
                      onClick={onAceitarChat}
                    >
                      <Iconify icon={'icon-park-solid:check-one'} color={'#00a884'} />
                    </ButtonLoading>
                  )}
                </div>
              )}

              {statusChat === "Atendento" && (
                <div >
                  <Button
                    size="icon"
                    className="w-5 h-5 ml-1"
                    variant="outline"
                    sx={{ size: 140 }}
                    id="basic-button"
                    aria-controls={openMenu ? 'basic-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={openMenu ? 'true' : undefined}
                    onClick={handleClick}>
                    <HiChevronDown color={'#333'} variant="outlined" />
                  </Button>
                  <Menu id="basic-menu" anchorEl={anchorEl} open={openMenu} onClose={handleClose} MenuListProps={{ 'aria-labelledby': 'basic-button' }}>
                    {options.map((option) => (
                      <MenuItem key={option.label} onClick={() => option.method()}>
                        <small className="text-sm font-medium leading-none">{option.label}</small>
                      </MenuItem>
                    ))}
                  </Menu>
                </div>
              )}

            </div>



          </Stack>
        </Box>

      </Stack>





    </Card>
  );
}
