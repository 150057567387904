import PropTypes from 'prop-types';
// @mui
import {IconButton, Stack, Tooltip, Checkbox } from '@mui/material';
import Iconify from 'src/components/Iconify';
import { formataNumeroCelular } from 'src/utils/functions';
import { fDateTime } from 'src/utils/formatTime';
import { Button } from 'src/@/components/ui/button';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';
import { Label } from "src/@/components/ui/label"
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "src/@/components/ui/popover"

import { TableCell, TableRow } from 'src/@/components/ui/table';

ContatosRowTable.propTypes = {
  row: PropTypes.object.isRequired,
  selected: PropTypes.bool,
  onSelectRow: PropTypes.func,
  onViewRow: PropTypes.func,
  onEditRow: PropTypes.func,
  onDeleteRow: PropTypes.func,
};

export default function ContatosRowTable({
  row,
  index,
  selected,
  onVerContato,
  onCriarChat,
  loading,
  loadingNovoChat
}) {
  const { nome, telefone, info, data_criacao, custom_fields } = row;



  return (
    <TableRow key={index} hover>

      {/* <TableCell padding="checkbox">
        <Checkbox checked={selected} onClick={onSelectRow} />
      </TableCell> */}

      <TableCell >
        {nome}
      </TableCell>

      <TableCell>
        {formataNumeroCelular(telefone)}
      </TableCell>

      <TableCell >
        {info?.email}
      </TableCell>

      <TableCell >
        {info?.cpf}
      </TableCell>



      <TableCell >
        {info?.dataNascimento}
      </TableCell>

      <TableCell >
        {info?.endereco?.cidade}
      </TableCell>
      <TableCell >
        {fDateTime(data_criacao)}
      </TableCell>
      <TableCell >
        {custom_fields?.length}
      </TableCell>


      <TableCell sx={{ p: 0.5 }} >
        <Stack direction="row" spacing={1} alignItems={"center"}>
          <Tooltip title="Ver contato">
            <Button variant="outline" size="icon" onClick={onVerContato}>
              <Iconify color="black" icon="hugeicons:view" width={18} height={18} />
            </Button>
          </Tooltip>
          <Tooltip title="Criar Chat">
            <Button disabled={loadingNovoChat} variant="outline" size="icon" onClick={onCriarChat}>
              <Iconify color="black" icon="ic:baseline-whatsapp" width={18} height={18} />
            </Button>
          </Tooltip>

        </Stack>

      </TableCell>

    </TableRow>
  );
}
