import axios from "axios";
import Config from "../../config";

const actionStartSessionWhatsapp = (querys, data) => {
    
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/start-session${querys ? querys : ""}`,
        data
    });
};

export default actionStartSessionWhatsapp;
