import { Button } from "src/@/components/ui/button";
import { Sheet, SheetTrigger, SheetContent, SheetHeader, SheetTitle } from "src/@/components/ui/sheet";
import Iconify from "src/components/Iconify";
import QrCode from "react-qr-code";
import { useSelector } from "react-redux";
import { mascararTel } from "src/utils/functions";

export function HelpComponent() {
    const { globalConfig } = useSelector(state => state.global);

    return (
        <Sheet>
            <SheetTrigger asChild>
                <Button
                    size="icon"
                    variant="ghost"
                >
                    <Iconify color="black" icon="material-symbols:help-outline" className="h-5 w-5" />
                </Button>
            </SheetTrigger>
            <SheetContent>
                <SheetHeader>
                    <SheetTitle>Central de ajuda</SheetTitle>
                </SheetHeader>

                {/* WhatsApp Support Section */}
                <div className="my-4 p-4 border rounded-lg shadow-sm">
                    <Button onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${globalConfig?.provider?.config?.phone_support}&text=Oi%2C+venho+do+site+lais+e+gostaria+de+tirar+algumas+duvidas&type=phone_number&app_absent=0`, '_blank')} className="my-4 w-full bg-green-500">
                        <Iconify icon="ic:baseline-whatsapp" className="h-5 w-5 mr-2" />
                        Conversar com suporte
                    </Button>
                    <p className="mt-4">Contato: <span className="font-bold">{mascararTel(globalConfig?.provider?.config?.phone_support)}</span></p>
                    <p className="text-xs mt-5">ou se preferir, converse com o suporte via WhatsApp do seu aparelho lendo o QRCode abaixo:</p>
                    <QrCode value={`https://wa.me/${globalConfig?.provider?.config?.phone_support}`} size={64} className="mx-auto my-4" />
                </div>

                {/* Quick Access Buttons */}
                <div className="grid grid-cols-1 gap-4">
                    {globalConfig?.provider?.config?.links?.showAppAndroid && (
                        <Button onClick={() => window.open(globalConfig?.provider?.config?.links?.playstore_url, '_blank')} variant="outline" className="flex flex-col items-center">
                            <Iconify icon="logos:android" className="h-8 w-8" />
                            App Android
                        </Button>
                    )}

                    {globalConfig?.provider?.config?.links?.showAppWindows && (
                        <Button onClick={() => window.open(globalConfig?.provider?.config?.links?.app_windows_url, '_blank')} variant="outline" className="flex flex-col items-center">
                            <Iconify icon="ic:outline-windows" className="h-8 w-8" />
                            App Windows
                        </Button>
                    )}

                    {globalConfig?.provider?.config?.links?.showDocsApi && (
                        <Button onClick={() => window.open(globalConfig?.provider?.config?.links?.api_docs_url, '_blank')} variant="outline" className="flex flex-col items-center">
                            <Iconify icon="mdi:file-document-outline" className="h-8 w-8" />
                            Documentação
                        </Button>
                    )}

                    {globalConfig?.provider?.config?.links?.showDocsApi && (
                        <Button onClick={() => window.open(globalConfig?.provider?.config?.links?.course_url, '_blank')} variant="outline" className="flex flex-col items-center">
                            <Iconify icon="mdi:book-open-outline" className="h-8 w-8" />
                            Curso
                        </Button>
                    )}

                </div>
            </SheetContent>
        </Sheet>
    );
}
