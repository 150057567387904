import { Button } from "src/@/components/ui/button"
import {
    DropdownMenu,
    DropdownMenuContent,
    DropdownMenuGroup,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuPortal,
    DropdownMenuSeparator,
    DropdownMenuShortcut,
    DropdownMenuSub,
    DropdownMenuSubContent,
    DropdownMenuSubTrigger,
    DropdownMenuTrigger,
    DropdownMenuCheckboxItem
} from "src/@/components/ui/dropdown-menu"
import Iconify from "src/components/Iconify"


export function SupervisaoExport({onExportListChats}) {
    
    return (
        <DropdownMenu>
            <DropdownMenuTrigger asChild>
                <Button
                    variant="outline"
                    size="sm"
                    className="h-9 mt-0.5 gap-1 text-sm"
                >
                    Exportar
                    <Iconify icon="iconamoon:arrow-down-2-bold" className="w-4 h-4" />
                </Button>
            </DropdownMenuTrigger>
            <DropdownMenuContent align="end">
            
                <DropdownMenuItem onClick={onExportListChats}>
               Exportar lista de chats (.xlsx)
                 </DropdownMenuItem>

            </DropdownMenuContent>
        </DropdownMenu>
    )
}





