import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    Container,
    Box,
    Grid,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    ListItemButton,
    TextField,
} from '@mui/material';
import { useState } from 'react';
import v1_actionCriarFlow from 'src/actions/v1/clientes/v1_actionCriarFlow';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';


export default function DialogCriarFlow({ open, onClose, cliente, onCallback }) {

    const [name, setName] = useState('');
    const [loading, setLoading] = useState(false);

    const handleCriarFlow = async () => {
        setLoading(true);
        try {
            let data = {
                scope: {
                    name: name
                }
            }
            const response = await v1_actionCriarFlow(cliente._id, data);
            onCallback(response.data.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log(error)
        }
    }


    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'sm'}

        >

            <DialogTitle sx={{
                background: theme => theme.palette.primary.main, py: 1, color: '#fff'
            }}>
                Criar novo fluxo
            </DialogTitle>

            <DialogContent sx={{ mt: 3 }}>
                <RowInput
                    sx={{ width: '100%' }}
                    label="nome do fluxo"
                    input={<InputStyle
                        size="small"
                        value={name}
                        fullWidth
                        onChange={(e) => setName(e.target.value)
                        }
                    />}
                />
            </DialogContent>
            <DialogActions>
                <Button color="error" onClick={onClose}>Cancelar</Button>
                <LoadingButton loading={loading} variant='contained' size="small" onClick={handleCriarFlow}>Criar</LoadingButton>
            </DialogActions>

        </Dialog>
    );
}






