import React, { useEffect } from 'react';
import Defcon1 from './components/Defcon';
import GoogleAnalytics from './components/GoogleAnalytics';
import LoadingScreen from './components/LoadingScreen';
import NotistackProvider from './components/NotistackProvider';
import ScrollToTop from './components/ScrollToTop';
import ThemeLocalization from './components/ThemeLocalization';
import ThemePrimaryColor from './components/ThemePrimaryColor';
import useAuth from './hooks/useAuth';
import { useSelector } from './redux/store';
import Router from './routes';
import ThemeConfig from './theme';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { Toaster } from "src/@/components/ui/toaster"
import { clarity } from 'react-microsoft-clarity';
import { getGlobalConfig } from './redux/slices/global';
import { verificarUrlProvider } from './utils/functions';
import config from './config';
import v2_actionCheckIsBlock from './actions/v2/cliente/v2_actionCheckIsBlock';

export default function App() {
  const { isInitialized,  cliente, logout } = useAuth();
  const user = JSON.parse(localStorage.getItem('user'));
  const { defcon } = useSelector((state) => state.global);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const suspend = false;
 
  const verifyBlock = async () => {
    if(cliente?.isBlock) {
      navigate('/auth/suspend');
    }
  }

  async function checkIsBlock(id) {
   
    try {
        const response = await v2_actionCheckIsBlock(id);
        console.log('check block', response.data.data)
        if(response.status === 200) {
            if(!response.data.data) {
                navigate('/chats/chat');
            }
        }
     } catch(err) {
        console.log(err)
     }
    
}

const verificaTimeToken = () => {
  const user = JSON.parse(localStorage.getItem('user')); // Supondo que o usuário esteja armazenado no localStorage

  if (!user || !user.exp) {
    logout()
    return;
  }

  const currentTime = Date.now() / 1000;

  if (user.exp < currentTime) {
    logout()
  }
}


  useEffect(() => {
    verificaTimeToken();
    const url = verificarUrlProvider()
    dispatch(getGlobalConfig(url));
    //verifyBlock();
    // if(cliente) {
    //   console.log('is block')
    //   checkIsBlock(cliente._id)
    // }

  }, [defcon]);

  if(!config.dev) {
  clarity.init("njqf38l8k6");
  } 


  // clarity.identify(user?.id, { userProperty: 'value' });

  return (
    <ThemeConfig>
      <ThemePrimaryColor>
        <ThemeLocalization>
          <NotistackProvider>
            <ScrollToTop />
            <GoogleAnalytics />
            <Toaster />
            {isInitialized ? (defcon ? <Defcon1 /> :  <Router />) : <LoadingScreen />}
          </NotistackProvider>
        </ThemeLocalization>
      </ThemePrimaryColor>
    </ThemeConfig>
  );
}
