import { useEffect, useState, useRef } from 'react';
// material
import { Stack, Table, Box, TableRow, TableHead, TableBody, TableCell, TableContainer, IconButton, Container, Grid, CardHeader, Card, TextField, Button, Divider, Typography, Tooltip } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';

//import '../../styleFunil.css'
// components;
import Page from 'src/components/Page';
import SomaPadrao from 'src/components/_dashboard/relatorios/SomaPadrao';
import Scrollbar from 'src/components/Scrollbar';
import { FunnelChart } from "react-funnel-pipeline";
import "react-funnel-pipeline/dist/index.css";
//icons
//actions
import actionTotalAtendimentos from 'src/actions/dashboard/relatorios/actionTotalAtendimentos';
import actionTotalTickets from 'src/actions/dashboard/tickets/actionTotalTickets';
import actionMetricasAtendimentosUsuarios from 'src/actions/dashboard/relatorios/actionMetricasAtendimentosUsuarios';
import getActionSomaFunilVendas from 'src/actions/dashboard/somaFunilVendas';
//functions
import { verificahoras } from 'src/utils/functions';
//redux
import { getSomaChatsByStatus, getSomaFunilVendas } from 'src/redux/slices/dashboard';
import { getListaUsuariosByClienteId } from 'src/redux/slices/user';
import { useDispatch, useSelector } from 'src/redux/store';
// configs
import { nomeProjeto } from 'src/config';
import useAuth from 'src/hooks/useAuth';
import Iconify from 'src/components/Iconify';
import { useNavigate } from 'react-router';
import Breadcrumbs from 'src/components/Breadcrumbs';

export default function RelatorioFunilVendas() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const componentRef = useRef(null);
  const { somaChatsByStatus } = useSelector((state) => state.dashboard);
  const { user } = useAuth();
  const [inicial, setInicial] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const [dataFunil, setDataFunil] = useState([]);
  const [atendimentosFinalizados, setAtendimentosFinalizados] = useState(0);
  const [atendimentosTransferidos, setAtendimentosTransferidos] = useState(0);
  const [ticketsFinalizados, setTicketsFinalizados] = useState(0);
  const [tempoMedioEspera, setTempoMedioEspera] = useState(0);
  const [tempoMedioAceiteFinalizado, setTempoMedioAceiteFinalizado] = useState(0);
  const [tempoMedioTotal, setTempoMedioTotal] = useState(0);
  const [listaMetricasAtendimetosUsuarios, setListaMetricasAtendimetosUsuarios] = useState([]);
  const multiSession = localStorage.getItem("multiSession");
  const clienteIdActive = localStorage.getItem("clienteIdActive");


  async function getOnlySomaFunilVendas() {
    setDataFunil([])
    try {
      const response = await getActionSomaFunilVendas({ clienteId: multiSession === "1" ? clienteIdActive : user.clienteId, inicio: inicial, final: final });
      setDataFunil(response.data)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getOnlySomaFunilVendas()
  }, [inicial, final]);




  const getTotalAtendimentos = async () => {
    try {
      let data = { clienteId: multiSession === "1" ? clienteIdActive : user.clienteId, inicio: inicial, final: final }
      const res = await actionTotalAtendimentos(data);
      setAtendimentosTransferidos(res.data.data.transferidos);
      setAtendimentosFinalizados(res.data.data.finalizados);
    } catch (err) {
      console.log('err', err)
    }
  }

  const buscarMetricasAtendimentosUsuarios = async () => {
    try {
      let data = { clienteId: multiSession === "1" ? clienteIdActive : user.clienteId, inicio: inicial, final: final }
      const res = await actionMetricasAtendimentosUsuarios(data);
      setListaMetricasAtendimetosUsuarios(res.data.data)
    } catch (error) {

    }
  }



  const getTotalTickets = async () => {
    try {
      let data = { clienteId: multiSession === "1" ? clienteIdActive : user.clienteId, inicio: inicial, final: final }
      const res = await actionTotalTickets(data);
      setTicketsFinalizados(res.data.data.finalizados);
      if (res.data.data.tempo_medio_espera) {

        let tempoTotal = res.data.data.tempo_medio_espera.tempo_total;
        let count = res.data.data.tempo_medio_espera.count;
        let tempoMedioEspera = tempoTotal / count;
        console.log('tempoMedioEspera', tempoMedioEspera)
        let calculo = tempoMedioEspera / 60
        console.log('tempoMedioEspera', calculo);
        setTempoMedioEspera(calculo);
      }
      if (res.data.data.tempo_medio_aceite_finalizado) {
        let tempoMedio = res.data.data.tempo_medio_aceite_finalizado.tempo_total / res.data.data.tempo_medio_aceite_finalizado.count
        let calculo = tempoMedio / 60
        console.log('calculo tempo_medio_aceite_finalizado', calculo)
        setTempoMedioAceiteFinalizado(calculo);
      }
      if (res.data.data.tempo_medio_total_ticket) {
        let tempoMedio = res.data.data.tempo_medio_total_ticket.tempo_total / res.data.data.tempo_medio_total_ticket.count
        let calculo = tempoMedio / 60;
        setTempoMedioTotal(calculo);
      }
    } catch (err) {
      console.log('err', err)
    }
  }


  const ItemMinutagem = ({ total, titulo }) => {
    return (
      <Card sx={{ display: 'flex', alignItems: 'center', p: 3 }}>
        <Stack direction={'column'}>
          <Typography variant="caption">{titulo}</Typography>
          <Stack direction={'row'} alignItems={'center'} spacing={1}>
            <Typography variant="h6">{verificahoras(total)}</Typography>
            <Typography variant="caption">{total >= 60 ? "h" : "min"}</Typography>
          </Stack>
        </Stack>

      </Card>
    );
  }

  useEffect(() => {
    buscarMetricasAtendimentosUsuarios();
    getOnlySomaFunilVendas();

    dispatch(getListaUsuariosByClienteId(user.clienteId));
    dispatch(getSomaChatsByStatus(user.clienteId, new Date(), new Date()));
    dispatch(getSomaFunilVendas(user.clienteId, new Date(), new Date()));
    getTotalAtendimentos();
    getTotalTickets();

  }, [dispatch]);

  const handlePesquisar = () => {
    dispatch(getSomaChatsByStatus(user.clienteId, inicial, final));
    dispatch(getSomaFunilVendas(user.clienteId, inicial, final));
    getTotalAtendimentos();
    getTotalTickets();
    buscarMetricasAtendimentosUsuarios();

  }



  return (
    <Page sx={{mt:5}} title={nomeProjeto}>
      <Container maxWidth>

        <Breadcrumbs
          backButton={() => navigate('/dashboard/relatorios')}
          links={[{ name: 'Relatorios', href: '/dashboard/relatorios' }, { name: 'Relatório Relatório Funil de Vendas' }]}
        />
        <Card sx={{ p: 3 }}>
          <Stack direction="row" spacing={2} alignItems="center" >
            <DatePicker
              label="Data Inicial"
              inputFormat="dd/MM/yyyy"
              value={inicial}
              minDate={new Date('2017-01-01')}
              onChange={(newValue) => {
                setInicial(newValue);
              }}
              renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
            />
            <DatePicker
              label="Data final"
              inputFormat="dd/MM/yyyy"
              value={final}
              minDate={new Date('2017-01-01')}
              onChange={(newValue) => {
                setFinal(newValue);
              }}
              renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
            />
            <Button onClick={handlePesquisar} variant="contained">Pesquisar</Button>
          </Stack>
        </Card>

        <Stack spacing={6} direction={'column'} sx={{ mt: 5 }}>
          <Stack spacing={2} sx={{ width: '100%', flexGrow: 1 }}>
            {dataFunil.length > 0 && (
              <Stack>
                <Grid container>
                  <Grid md={4} sm={12} xs={12}>
                    <Card sx={{ p: 3 }}>
                      <CardHeader
                        title="Funil de Vendas"
                      />
                      <Box sx={{ alignContent: 'center', mt: 2 }}>
                        <FunnelChart data={dataFunil} />
                      </Box>
                    </Card>
                  </Grid>
                </Grid>
              </Stack>
            )}
          </Stack>

        </Stack>
      </Container>


  

    </Page>
  );
}
