import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import {fDateTime} from '../../utils/formatTime'

export default function TimelinePadrao({ data }) {

    
function deParaTimeline (value) {
  if(value === "editar_estimativa") {
    return "Estimativa editada"
  }  else if(value === "editar_descricao") {
    return "Descrição Editada"
  } else  if(value === "aceitar_chamado") {
    return "Chamado aceito"
  } else  if(value === "editar_completado") {
    return "Chamado finalizado"
  }  else  if(value === "editar_arquivos") {
    return "Arquivo Editado"
  }   else  if(value === "add_arquivos") {
    return "Arquivo inserido"
  } else  if(value === "editar_copiado_para") {
    return "Em Copia editado"
  } else  if(value === "editar_status") {
    return "Status alterado"
  }  else  if(value === "editar_titulo") {
    return "Titulo alterado"
  }  else  if(value === "criacao") {
    return "Chamado criado"
  }  else  if(value === "cancelar_chamado") {
    return "Chamado cancelado"
  }  else  if(value === "recusar_chamado") {
    return "Chamado recusado"
  }
}

    return (
        <Timeline
            sx={{
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.2,
                },
            }}
        >

            {data.map((option) => (
                
                <TimelineItem >
                    <TimelineOppositeContent sx={{fontSize:12}} color="textSecondary">
                        {fDateTime(option.date)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{fontSize:12, fontStyle:'italic'}}>{deParaTimeline(option.action)}</TimelineContent>
                </TimelineItem>
            ))}

        </Timeline>
    );
}
