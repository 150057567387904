import roundAccountBox from '@iconify/icons-ic/round-account-box';
import roundVpnKey from '@iconify/icons-ic/round-vpn-key';
import { Icon } from '@iconify/react';
import { Tab, Box, Tabs, Stack, Dialog, DialogActions, DialogTitle, DialogContentText, DialogContent, Button, Divider, Container } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material

// redux
import actionEditarPerfil from 'src/actions/usuarios/actionEditarPerfil';
import actionEditarSenha from 'src/actions/usuarios/actionEditarSenhaUsuario';
import actionDetalhesUsuario from 'src/actions/usuarios/actionDetalhesUsuario';

import PerfilAlterarSenha from 'src/components/_dashboard/perfil/PerfilAlterarSenha';
import PerfilGeral from 'src/components/_dashboard/perfil/PerfilGeral';
import Page from 'src/components/Page';
import useAuth from 'src/hooks/useAuth';
import Preferencias from 'src/components/_dashboard/perfil/Preferencias';
import { soNumeros } from 'src/utils/functions';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import Iconify from 'src/components/Iconify';
import v2_actionEditarUsuario from 'src/actions/v2/usuarios/v2_actionEditarUsuario';

export default function PerfilComponent() {
  const [currentTab, setCurrentTab] = useState('general');
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const [openDialogLogout, setOpenDialogLogout] = useState(false);
  const [nome, setNome] = useState('');
  const [sobrenome, setSobrenome] = useState('');
  const [email, setEmail] = useState('');
  const [ddd, setDdd] = useState('');
  const [telefone, setTelefone] = useState('');
  const [usuario, setUsuario] = useState('');
  const [nomeFantasia, setNomeFantasia] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const soundNotification = localStorage.getItem('soundNotification');
  const { logout, user } = useAuth();

  const [mensagemIntervalo, setMensagemIntervalo] = useState(null);
  const [isModificado, setIsModificado] = useState(false);

  //loading
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    buscarDetalhesUsuario()

  }, []);

  const handleEditarSenha = () => {
    setIsLoading(true)
    if (password === repeatPassword) {
      actionEditarSenha({ usuarioId: user.id, password: password, repeatPassword: repeatPassword }).then(() => {
        enqueueSnackbar('Senha alterada com sucesso', { variant: 'success' });
      }).catch(() => {
        enqueueSnackbar('Erro ao alterar senha', { variant: 'error' });
      })
    } else {
      enqueueSnackbar('Senhas não conferem', { variant: 'error' });
    }
  }

  function maskUsername(str) {
    return str.split(' ').join('')
  }

  const handleLogout = async () => {
    try {
      await logout();
      navigate('/');

    } catch (error) {
      console.error(error);
      enqueueSnackbar('Unable to logout', { variant: 'error' });
    }
  };



  const handleEditarPerfil = () => {
    setIsLoading(true);

    let data = {
      usuarioId: user.id,
      firstName: nome,
      lastName: sobrenome,
      email,
      telefone: `55${ddd}${soNumeros(telefone)}`,
      username: maskUsername(usuario),
      nomeFantasia
    }

    actionEditarPerfil(data).then(res => {
      if (res.data.error === true) {
        setOpenDialogLogout(false)
        setTimeout(() => {
          enqueueSnackbar(res.data.mensagem, { variant: 'error' });
        }, 1000);

      } else {

        setIsLoading(true);
        setTimeout(() => {
          enqueueSnackbar(res.data.mensagem, { variant: 'success' });
          handleLogout()
        }, 1000);


      }
    }).catch(() => {
      setIsLoading(false);
      setTimeout(() => {
        enqueueSnackbar('Erro ao alterar perfil', { variant: 'error' });
      }, 1000);
    })
  }

  const changeSoundNotification = () => {
    if (soundNotification === 'true') {
      localStorage.setItem('soundNotification', "false");
      window.location.reload();
    } else if (soundNotification === 'false') {
      localStorage.setItem('soundNotification', "true");
      window.location.reload();
    }

  };

  async function handelEditarPreferencias() {
    setIsLoading(true);
    try {
      let data = {
        "scope": {
          "config.messageStandby": mensagemIntervalo
        }
      }
      await v2_actionEditarUsuario(user.id, data);
      enqueueSnackbar('Alterado com sucesso', { variant: 'success' });
    } catch(erro) {
      setIsLoading(false);
      setTimeout(() => {
        enqueueSnackbar('Erro ao alterar perfil', { variant: 'error' });
      }, 1000);
    }
  }

  const buscarDetalhesUsuario = async () => {
    try {
      const res = await actionDetalhesUsuario(user.id);
      if (res.data.data) {
        let dataUsuario = res.data.data;
        const codigoPais = res.data.data.telefone && res.data.data.telefone.substring(0, 2);
        const ddd = res.data.data.telefone && res.data.data.telefone.substring(2, 4);
        const numeroTelefone = res.data.data.telefone && res.data.data.telefone.substring(4);
        setTelefone(numeroTelefone);
        setDdd(ddd);

        setNome(dataUsuario.firstName);
        setSobrenome(dataUsuario.lastName);
        setEmail(dataUsuario.email);
        setUsuario(dataUsuario.username);
        setNomeFantasia(dataUsuario.nomeFantasia);
        setMensagemIntervalo(dataUsuario?.config?.messageStandby);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const ACCOUNT_TABS = [
    {
      value: 'general',
      name: 'Perfil',
      icon: <Icon icon={roundAccountBox} width={20} height={20} />,
      component: <PerfilGeral
        nome={nome}
        sobrenome={sobrenome}
        email={email}
        ddd={ddd}
        changeDDD={e => setDdd(e.target.value)}
        telefone={telefone}
        usuario={maskUsername(usuario)}
        nomeFantasia={nomeFantasia}
        changeTelefone={e => setTelefone(e.target.value)}
        changeNome={e => setNome(e.target.value)}
        changeSobrenome={e => setSobrenome(e.target.value)}
        changeEmail={e => setEmail(e.target.value)}
        changeUsuario={e => setUsuario(e.target.value)}
        changeNomeFantasia={e => setNomeFantasia(e.target.value)}
        submit={() => setOpenDialogLogout(true)}
      />
    },
    {
      value: 'change_password',
      name: 'Alterar Senha',
      icon: <Icon icon={roundVpnKey} width={20} height={20} />,
      component: <PerfilAlterarSenha
        password={password}
        repeatPassword={repeatPassword}
        changePassword={e => setPassword(e.target.value)}
        changeRepeatPassword={e => setRepeatPassword(e.target.value)}
        isLoading={isLoading}
        submit={handleEditarSenha}
      />
    },
    {
      value: 'preferencias',
      name: 'Preferências',
      icon: <Iconify width={18} height={18} icon="ri:user-settings-line" />,
      component: <Preferencias
        mensagemIntervalo={mensagemIntervalo}
        changeMensagemIntervalo={e => setMensagemIntervalo(e.target.value)}
        isModificado={isModificado}
        changeIsModificado={() => setIsModificado(true)}
        checkedSomNotificacao={soundNotification === 'true' ? true : false}
        changeSomNotificacao={changeSoundNotification}
        onSubmit={handelEditarPreferencias}
      />
    }
  ];



  const handleChangeTab = (event, newValue) => {
    setCurrentTab(newValue);
  };

  return (
    <Container maxWidth="lg">
      <Dialog
        open={openDialogLogout}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Atenção"}
        </DialogTitle>
        <Divider sx={{ mt: 2, mb: 2 }} />
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Após a alteração você será desconectado da plataforma. Faça o Login novamente!
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialogLogout(false)}>Cancelar</Button>
          <LoadingButton loading={isLoading} variant="contained" onClick={handleEditarPerfil} autoFocus>
            Ok
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <HeaderPageComponentConfig
        titulo="Perfil"
        descricao={'Aqui você encontra todas as informações sobre a sua conta. Altere seus dados pessoais, senha e preferências.'}

      />

      <Box sx={{ p: 3 }}>

        <Stack sx={{ mt: 1 }} spacing={2}>
          <Tabs
            value={currentTab}
            scrollButtons="auto"
            variant="scrollable"
            allowScrollButtonsMobile
            onChange={handleChangeTab}
          >
            {ACCOUNT_TABS.map((tab) => (
              <Tab disableRipple key={tab.value} label={tab.name} icon={tab.icon} value={tab.value} />
            ))}
          </Tabs>

          <Stack sx={{ mt: 3 }}>
            {ACCOUNT_TABS.map((tab) => {
              const isMatched = tab.value === currentTab;
              return isMatched && <Box key={tab.value}>{tab.component}</Box>;
            })}
          </Stack>

        </Stack>
      </Box>
    </Container>
  );
}
