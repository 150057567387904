import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import logoLais from 'src/assets/logos/logo-lais1.png';
import logoEva from 'src/assets/logos/logo-eva.png';

export default function Logo({ sx }) {

  function verificarUrl() {
    // Obtém a URL atual
    const urlAtual = window.location.href;
  
    // Exemplo de verificação simples: verificar se a URL contém uma palavra específica
    if (urlAtual.includes('eva')) {
      return logoEva
    } else {
      return logoLais;
    }
  
  }

  return (
    <Box className="flex items-center" sx={{ width: 90, height: 35, pr:1, ...sx }}>
      <img src={verificarUrl()}></img>
    </Box>
  );
}
