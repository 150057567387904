// material
import {
    Autocomplete,
    FormControl,
    Box,
    MenuItem,
    Stack,
    Typography,
    Accordion,
    AccordionSummary,
    Rating
} from '@mui/material';
import './react-phone-number-input.css'
import { styled } from '@mui/material/styles';
import { LoadingButton } from '@mui/lab';
import React, { useState, forwardRef } from 'react';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import getActionCriarContato from 'src/actions/chats/getActionCriarContato';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import { fDate, isDateValid } from 'src/utils/formatTime';
import { formataNumeroCelular, maskCPF, maskDate, validarNumeroWhatsapp } from 'src/utils/functions';
import RadionIcon from 'src/components/RadionIcon';
import NovoContatoForm from './NovoContatoForm';
import NovaEmpresaForm from './NovaEmpresaForm';
import Reacy, { useEffect } from 'react';
// ----------------------------------------------------------------------

//actions
import v2_actionPesquisarContatos from 'src/actions/v2/contatos/v2_actionPesquisarContatos';
import InputStyleAutocomplete from 'src/components/InputStyleAutocomplete';
import v2_actionPesquisarContatosEmpresa from 'src/actions/v2/contatos/v2_actionPesquisarContatosEmpresa';
import v2_actionCriarContatoEmpresa from 'src/actions/v2/contatos/v2_actionCriarContatoEmpresa';
import { enqueueSnackbar } from 'notistack';
import { setModalAlerta } from 'src/redux/slices/global';
import { useSnackbar } from 'notistack';
import v2_actionCriarContato from 'src/actions/v2/contatos/v2_actionCriarContato';

const StyledRating = styled(Rating)({
    width: 150,
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

const labels = {
    1: 'Sem interesse',
    2: 'Frio',
    3: 'Morno',
    4: 'Esquentando',
    5: 'Quente',
};

function getLabelText(value) {
    return `${value} ${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

export default function NovoNegocioForm({
    user,
    cliente,
    configCrm,
    loadingNovoContato,
    callbackAddLead,
    onSelectPipeline,
    dispatch
}) {



    const [openSelectStage, setOpenSelectStage] = useState(false);
    const [contactId, setContactId] = useState(null);
    const [empresaId, setEmpresaId] = useState(null);
    const [hover, setHover] = useState(-1);

    const [listaContatos, setListaContatos] = useState([]);
    const [pesquisa, setPesquisa] = useState('');
    const [pesquisaEmpresa, setPesquisaEmpresa] = useState('');

    const [contatosEmpresa, setContatosEmpresa] = useState([]);
    const [listaEmpresas, setListaEmpresas] = useState([]);

    const { enqueueSnackbar } = useSnackbar();
    const [loadingCriarContato, setLoadingCriarContato] = useState(false);
    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');
    const [expanded, setExpanded] = useState(false);


    
  const handleChangeAccordion = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };


    const [negocio, setNegocio] = useState({
        nome: null,
        pipelineId: null,
        pipelineStageId: null,
        origemLead: null,
        qualificacao: 1,
        empresaId:empresaId?._id,
        contatoId: null,
        contato: {
            nome: '',
            email: '',
            whatsapp: '',
            dataNascimento: '',
            cpf: '',
            endereco: {
                cep: '',
                logradouro: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: ''
            }
        },
        empresa: {
            nome: '',
            cnpj: '',
            endereco: {
                cep: '',
                logradouro: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: ''
            }
        }
    });


    const handleCallbackAddLead = () => {
        const { nome, pipelineId, pipelineStageId } = negocio;
        if (nome == "" || pipelineId == "" || !pipelineStageId) return dispatch(setModalAlerta({ open: true, titulo: "Atenção", mensagem: 'Preencha todos os campos obrigatórios' }));
        callbackAddLead(negocio)
        clearFields();

    }

    async function handleCriarContato(data) {
        const { contato, endereco } = data;
        setLoadingCriarContato(true);
        try {
            let data = {
                scope: {
                    nome: contato.nome,
                    telefone: validarNumeroWhatsapp(contato.telefone),
                    info: {
                        email: contato.email,
                        cpf: contato.cpf,
                        dataNascimento: contato.dataNascimento,
                        endereco
                    },
                },
                cliente_id: cliente._id,
                usuario_id: user.id
            }
            const response = await v2_actionCriarContato(data);
            if (response.status === 200) {
                setLoadingCriarContato(false);
                enqueueSnackbar('Contato criado com sucesso', { variant: 'success' });
                return response.data.data
            }
        } catch (error) {
            console.log(error)
            setLoadingCriarContato(false);
            enqueueSnackbar('Erro ao criar contato', { variant: 'error' });
        }
    }





    function clearFields() {
        setNegocio({
            nome: null,
            pipelineId: null,
            pipelineStageId: null,
            origemLead: null,
            qualificacao: null,
            contatoId: null,
            contato: {
                nome: '',
                email: '',
                whatsapp: '',
                dataNascimento: '',
                cpf: '',
                endereco: {
                    cep: '',
                    logradouro: '',
                    numero: '',
                    complemento: '',
                    bairro: '',
                    cidade: '',
                    estado: ''
                }
            },
            empresa: {
                nome: '',
                cnpj: '',
                endereco: {
                    cep: '',
                    logradouro: '',
                    numero: '',
                    complemento: '',
                    bairro: '',
                    cidade: '',
                    estado: ''
                }
            }
        })
    }

    async function pesquisarContatosEmpresa(pesquisa) {
        try {
            const result = await v2_actionPesquisarContatosEmpresa(cliente._id, pesquisa)
            setListaEmpresas(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    

    async function criarContatoEmpresa(data) {
        const { empresa } = data;
        console.log('data criar contatos', empresa)
        try {
            let data = {
                clienteId: cliente._id,
                usuarioId: user.id,
                scope: {
                    nome: empresa.nome,
                    info: {
                        email: empresa.email,
                        cnpj: empresa.cnpj,
                        site: "",
                        nomeFantasia: "",
                        razaoSocial: "",
                        inscricaoEstadual: "",
                        inscricaoMunicipal: "",
                        telefone: "",
                    },
                    segmentos: [],
                    atributos: [],
                    endereco: empresa.endereco,
                    contatos: [],
                }
            }
            const result = await v2_actionCriarContatoEmpresa(data)

            await pesquisarContatosEmpresa(empresa.nome);
            //setEmpresaId(result.data.data)
            enqueueSnackbar(result.data.mensagem, { variant: 'success' });
        } catch (error) {
            console.log(error);
        }
    }



    useEffect(() => {
    }, [configCrm, listaContatos])

    async function pesquisarContatos(pesquisa) {
        try {
            const result = await v2_actionPesquisarContatos(cliente._id, pesquisa)
            setListaContatos(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

  


    return (
        <div>


            <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={3}>
                <Stack spacing={3}>
                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                                isRequired={true}
                                label="Nome do lead"
                                input={<InputStyle onChange={e => {
                                    setNegocio({ ...negocio, nome: e.target.value })
                                }}
                                    value={negocio?.nome}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>
                    </Stack>

                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                                isRequired={true}
                                label="Funil"
                                input={<FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                                    <InputStyle
                                        select
                                        placeholder="Funil"
                                        fullWidth
                                        size="small"
                                        value={negocio?.pipelineId}
                                        onChange={async (e) => {
                                            setNegocio({ ...negocio, pipelineId: e.target.value })
                                            await onSelectPipeline(e.target.value);
                                            setOpenSelectStage(true)
                                        }}
                                    >
                                        {configCrm?.pipelines.map((option) => (
                                            <MenuItem key={option._id} value={option._id}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </InputStyle>
                                </FormControl>}
                            />
                        </Box>
                    </Stack>

                    {negocio?.pipelineId && (
                        <Stack
                            sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                            spacing={1}
                            direction={"row"}
                        >
                            <Box sx={{ width: '100%' }}>
                                <RowInput
                                    isRequired={true}
                                    label="Estagio do Funil"
                                    input={<FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                                        <InputStyle
                                            select
                                            fullWidth
                                            disabled={!negocio?.pipelineId}

                                            open={openSelectStage}
                                            onOpen={() => setOpenSelectStage(true)}
                                            size="small"
                                            value={negocio?.pipelineStageId}
                                            onChange={e => {
                                                setNegocio({ ...negocio, pipelineStageId: e.target.value });
                                                setOpenSelectStage(true)
                                            }}
                                        >
                                            {configCrm?.stages.map((option) => (
                                                <MenuItem key={option._id} value={option._id}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </InputStyle>
                                    </FormControl>}
                                />
                            </Box>
                        </Stack>
                    )}



                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                                label="Origem Contato"
                                input={<FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                                    <InputStyle
                                        select
                                        fullWidth
                                        size="small"
                                        value={negocio?.origemLead}
                                        onChange={e => {
                                            setNegocio({ ...negocio, origemLead: e.target.value })
                                        }}
                                    >
                                        {configCrm?.origens.map((option) => (
                                            <MenuItem key={option._id} value={option._id}>
                                                {option.name}
                                            </MenuItem>
                                        ))}
                                    </InputStyle>
                                </FormControl>}
                            />
                        </Box>
                    </Stack>
                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                                label="Qualificação"
                                input={
                                    <Stack spacing={1} direction="column">

                                        <StyledRating
                                            onChange={async (event) => {
                                                setNegocio({ ...negocio, qualificacao: event.target.value })

                                            }}
                                            icon={<Iconify width={22} height={22} icon="fluent:fire-24-filled" />}
                                            emptyIcon={<Iconify width={22} height={22} icon="fluent:fire-24-regular" />}
                                            name="size-small"
                                            getLabelText={getLabelText}
                                            onChangeActive={(event, newHover) => {
                                                setHover(newHover);
                                            }}
                                            size="large"
                                            defaultValue={negocio.qualificacao}
                                            value={negocio.qualificacao}
                                            sx={{ mt: 2 }}

                                        />
                                        <Typography sx={{ fontSize: 10, color: 'text.secondary' }}>
                                            {labels[hover !== -1 ? hover : negocio.qualificacao]}
                                        </Typography>
                                    </Stack>
                                }
                            />
                        </Box>
                    </Stack>



                    {/* <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                                label="Qualificação"
                                input={<Stack>
                                    <RadionIcon title={"Teste"} checked={false} />
                                </Stack>}
                            />
                        </Box>
                    </Stack> */}


                </Stack>

                <Stack
                    sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                    spacing={1}
                    direction={"column"}
                >
                    <Box sx={{ width: '100%' }}>
                        <RowInput
                            label="Contato"

                            input={<Autocomplete
                                filterOptions={(x) => x}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                id="combo-box-demo"
                                options={listaContatos}
                                value={contactId}
                                inputValue={pesquisa}
                                noOptionsText="Pesquise por nome ou telefone..."
                                onChange={(event, newValue) => {
                                    setNegocio({ ...negocio, contatoId: newValue?._id })
                                    setContactId(newValue)
                                }}
                                onInputChange={async (event, newInputValue) => {
                                    setPesquisa(newInputValue);
                                    if (newInputValue.length > 3) {
                                        pesquisarContatos(newInputValue);
                                    } else {
                                        setListaContatos([]);
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : (option.nome + ' - ' + formataNumeroCelular(option.telefone))

                                }
                                renderInput={(params) => <InputStyleAutocomplete {...params} />}
                            />}
                        />
                    </Box>

                    {!contactId && (
                        <Accordion
                        expanded={expanded === 'panel1'} onChange={handleChangeAccordion('panel1')}
                            sx={{
                                border: '1px solid',
                                borderColor: theme => theme.palette.primary.main,
                                backgroundColor: theme => theme.palette.grey[200],
                            }}
                            style={{ boxShadow: "none" }}
                        >
                            <AccordionSummary
                                sx={{
                                    minHeight: 40,
                                    border: 'none',
                                }}
                                style={{ borderRadius: 10 }}
                                expandIcon={<Iconify icon="mdi:plus" />}
                                expandIconPosition={<Iconify icon="mdi:close" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontSize: 12 }}>Adicionar contato</Typography>
                            </AccordionSummary>
                            <Box sx={{ px: 0.5 }}>


                                <NovoContatoForm
                                    cliente={cliente}
                                    hideSelectEmpresa={true}
                                    showSaveButton={true}
                                    callbackAddContact={ async(data) => {
                                        // const response_cadastro = await handleCriarContato(data);
                                        // setNegocio({ ...negocio, contatoId: response_cadastro?._id })
                                        // setContactId(response_cadastro)
                                    } }
                                />
                                <Stack direction="row">

                                </Stack>
                            </Box>
                        </Accordion>
                    )}



                </Stack>

                {/* <Stack
                    sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                    spacing={1}
                    direction={"column"}
                >
                    <Box sx={{ width: '100%' }}>
                        <RowInput
                            label="Empresa"

                            input={<Autocomplete
                                filterOptions={(x) => x}
                                autoComplete
                                includeInputInList
                                filterSelectedOptions
                                id="combo-box-demo"
                                options={listaEmpresas}
                                value={negocio?.empresaId}
                                inputValue={pesquisaEmpresa}
                                noOptionsText="Sem resultados"
                                onChange={(event, newValue) => {
                                    setNegocio({ ...negocio, empresaId: newValue?._id })
                                    setEmpresaId(newValue)
                                }}
                                onInputChange={async (event, newInputValue) => {
                                    setPesquisaEmpresa(newInputValue);
                                    if (newInputValue.length > 3) {
                                        pesquisarContatosEmpresa(newInputValue);
                                    } else {
                                        setListaEmpresas([]);
                                    }
                                }}
                                isOptionEqualToValue={(option, value) => option._id === value._id}
                                getOptionLabel={(option) =>
                                    typeof option === 'string' ? option : (option?.nome + ' - ' + option?.info?.cnpj)

                                }
                                renderInput={(params) => <InputStyleAutocomplete {...params} />}
                            />}
                        />
                    </Box>
                    {!negocio?.empresaId && (
                        <Accordion
                            sx={{
                                border: '1px solid',

                                borderColor: theme => theme.palette.primary.main,
                                backgroundColor: theme => theme.palette.grey[200],
                            }}
                            style={{ boxShadow: "none" }}
                        >
                            <AccordionSummary
                                sx={{
                                    minHeight: 40,
                                    border: 'none',
                                }}
                                style={{ borderRadius: 10 }}
                                expandIcon={<Iconify icon="mdi:plus" />}
                                expandIconPosition={<Iconify icon="mdi:close" />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography sx={{ fontSize: 12 }}>Adicionar Empresa</Typography>
                            </AccordionSummary>
                            <Box sx={{ px: 2, py: 3 }}>


                                <NovaEmpresaForm
                                    showAddEmpresa={true}
                                    cliente={cliente}
                                    hideSelectEmpresa={true}
                                    showSaveButton={true}
                                    callbackAddEmpresa={data => criarContatoEmpresa(data)}
                                />
                                <Stack direction="row">

                                </Stack>
                            </Box>
                        </Accordion>
                    )}
                </Stack> */}



            </Stack>


            <Stack direction="row" sx={{ mt: 5, mb: 2 }}>
                <LoadingButton
                    loading={loadingNovoContato}
                    onClick={handleCallbackAddLead}
                    sx={{ height: 40, fontSize: 12, borderRadius: 0.5 }}
                    variant="contained"
                    startIcon={<Iconify icon="mdi:plus" />}
                >
                    Criar Lead
                </LoadingButton>
            </Stack>






        </div>
    );
}
