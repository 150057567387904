import axios from "axios";
import Config from "src/config";

const v2_actionCriarSetor = (clienteId, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/${clienteId}/setores/criar`,
        data
    });
};

export default v2_actionCriarSetor;
