import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import { useTheme } from '@mui/material/styles';
import {
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Container,
    IconButton,
    Button
} from '@mui/material';

// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getListaUsuariosByClienteId } from 'src/redux/slices/user';

// components
import { UserListHead } from 'src/components/_dashboard/user/list';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/material';
import { TableBorder } from 'src/components/table';
import Iconify from 'src/components/Iconify';
import ModalTraining from '../ModalTraining';
import v2_actionListaTreinamentoAgentes from 'src/actions/v2/cliente/flows/v2_actionListaTreinamentoAgentes';


// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'title', label: 'Titulo', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.nomeFantasia.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TrainingConfig({flow}) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { usuariosList } = useSelector((state) => state.user);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [openModalTraining, setOpenModalTraining] = useState(false);
    const [listaTreinamentos, setListaTreinamentos] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const [messageTraining, setMessageTraining] = useState('')

    async function handleListsTreinamentos() {
        try {
            const response = await v2_actionListaTreinamentoAgentes(user.clienteId, flow?._id);
            setListaTreinamentos(response.data.data)
        } catch (error) {
            console.log(error);
        }
    
    }

    useEffect(() => {
        handleListsTreinamentos()
    }, []);


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaTreinamentos.length) : 0;
    const filteredUsers = applySortFilter(listaTreinamentos, getComparator(order, orderBy), filterName);

    return (
        <Box sx={{ mt: 2 }}>



            <ModalTraining
                open={openModalTraining}
                onClose={() => {
                    setMessageTraining('')
                    setIsEdit(false)
                    setOpenModalTraining(false)
                }}
                onCallback={() => handleListsTreinamentos()}
                clienteId={user.clienteId}
                flow={flow}
                messageTraining={messageTraining}
                flowId={flow?._id}
                isEdit={isEdit}
            />

            <Stack spacing={3} direction={"column"}>
                <Stack justifyContent={"space-between"} spacing={2} direction={"row"}>
                    <Stack alignItems={"center"} direction="row" spacing={1}>
                        <Iconify icon="gg:sync" width={15} height={15} />
                        <Typography variant='extraSmall'>ultima soncronização </Typography>
                        <Typography variant='extraSmall'>13/05/2024 21:10</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>

                <Button
                        variant='default'
                        onClick={() => setOpenModalTraining(true)}
                        startIcon={<Iconify icon="material-symbols:sync" />}
                    >
                        Sincronizar
                    </Button>
                    <Button
                        variant='contained'
                        onClick={() => setOpenModalTraining(true)}
                        startIcon={<Iconify icon="fluent:calendar-template-20-filled" />}
                    >
                        Adicionar Treinamento
                    </Button>
                    </Stack>
                </Stack>
                <TableBorder>

                    <Scrollbar sx={{height:'50vh'}}>
                        <TableContainer sx={{ minWidth: 300 }}>
                            <Table size="small" aria-label="a dense table">

                                <TableBody dense>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                              
                                        return (
                                            <TableRow
                                                hover
                                                key={row._id}
                                                tabIndex={-1}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 }  }}

                                                role="checkbox"
                                           
                                            >

                                                <TableCell  component="th" scope="row" padding="none">
                                                    <Stack sx={{py:2}} direction="column"  spacing={2}>
                                                        <Typography variant="subtitle2" noWrap>

                                                   {row.title}
                                                   </Typography>
                                                   <Typography variant="caption" noWrap>
                                                   {row.content.substr(0, 200)}{row.content.length > 200 && '...'}
                                 
                                                   </Typography>
                                                   </Stack>
                                                </TableCell>
                                             
                    

                                                <TableCell width={50} align="left">
                                                    <Stack>
                                                        <IconButton
                                                            onClick={() => {
                                                                setOpenModalTraining(true)
                                                                setMessageTraining(row)
                                                                setIsEdit(true)
                                                            }}
                                                        >
                                                            <Iconify icon={"dashicons:edit"} width={20} height={20} />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>


                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination

                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={usuariosList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBorder>
            </Stack>




        </Box>
    );
}
