import * as React from "react"

import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "src/@/components/ui/select"

const lista = [
    {
        title: "Maça",
        value: "apple",
    },
    {
        title: "Banana",
        value: "banana",
    },
    {
        title: "Blueberry",
        value: "blueberry",
    },
    {
        title: "Grapes",
        value: "grapes",
    },
    {
        title: "Pineapple",
        value: "pineapple",
    },
    
]

export default function SelectZui({
    placeholder = "",
    onChange,
    value,
    list,
    itemSelect,
    contentList,
    emptyOption,
    fullWidth
}) {
  console.log('lsita', list)
  return (
    <Select
    value={value}
    onValueChange={value => onChange(value)}
    >
      <SelectTrigger className={`w-${fullWidth ? 'full' : '[160px]'} bg-white`}>
        <SelectValue placeholder={placeholder} />
      </SelectTrigger>
      <SelectContent className="max-h-[300px]">
        {contentList ? (
            contentList
        ) : (
            <SelectGroup>
                {emptyOption && <SelectItem value={emptyOption.value}>{emptyOption.label}</SelectItem>}
                {list?.map((item) => itemSelect(item))}
            </SelectGroup>
        )}
      </SelectContent>
    </Select>
  )
}
