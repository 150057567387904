import PropTypes from 'prop-types';
import { useState, useRef, useEffect } from 'react';
// @mui
import { Stack, Avatar, Typography, Container } from '@mui/material';
// utils
import { fToNow } from '../../../utils/formatTime';
// components
import Image from '../../../components/Image';
import LightboxModal from '../../../components/LightboxModal';
import Scrollbar from 'src/components/Scrollbar';

import Config from '../../../config';

import { FilePreviewComentarios } from '../../../components/upload'

// ----------------------------------------------------------------------

KanbanTaskCommentList.propTypes = {
  comments: PropTypes.array,
};

export default function KanbanTaskCommentList({ comments }) {
  const [openLightbox, setOpenLightbox] = useState(false);
  const [selectedImage, setSelectedImage] = useState(0);
  const arrayMimeTypesImages = [
    "image/jpeg",
    "image/png",
    "image/webp",
  ];
  const arrayMimeTypesArquivos = [
    "text/plain",
    "application/pdf"
  ]

  const handleOpenLightbox = (url) => {
    //const selectedImage = imagesLightbox.findIndex((index) => index === url);
    const selectedImage = url;
    setOpenLightbox(true);
    setSelectedImage(selectedImage);
  };

  function validaArquivo(comment) {
    if (comment.isMedia && comment.files.length > 0) {
   

      if (arrayMimeTypesArquivos.includes(comment.files[0].type)) {
        return (
          <FilePreviewComentarios files={comment.files} />
        )
      } else if (arrayMimeTypesImages.includes(comment.files[0].type)) {

        return (
          <Image
          src={`https://lais-app.s3.us-east-2.amazonaws.com/${comment.files[0].key}`}
          onClick={() => handleOpenLightbox(`https://lais-app.s3.us-east-2.amazonaws.com/${comment.files[0].key}`)}
          sx={{ mt: 2, borderRadius: 1 }}
        />
        )
      } 

    }
  }


  return (
    <>

      <Stack spacing={1} sx={{ py: 2, px: 2.5 }}>

        {comments.length > 0 ? (
          comments.map((comment) => (
            <ul>
              <Stack sx={{ mt: 3 }} key={comment._id} direction="row" spacing={2}>
                <Avatar src={comment.avatar} sx={{ width: 42, height: 42 }} />
                <div>
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography variant="subtitle2"> {comment.nome_autor}</Typography>
                    <Typography variant="caption" sx={{ color: 'text.secondary' }}>
                      {fToNow(comment.data_criacao)}
                    </Typography>
                  </Stack>

                  {comment.isMedia ? (
                    validaArquivo(comment)
                  ) : (
                    <Typography variant="body2" sx={{ mt: 0.5 }}>
                      {comment.texto}
                    </Typography>
                  )}
                </div>
              </Stack>

            </ul>

          ))
        ) : (
          <Stack>
            <Typography>Sem comentários existentes</Typography>
          </Stack>


        )}
      </Stack>

      <LightboxModal
        images={[]}
        mainSrc={selectedImage}
        photoIndex={selectedImage}
        setPhotoIndex={setSelectedImage}
        isOpen={openLightbox}
        onCloseRequest={() => setOpenLightbox(false)}
      />
    </>
  );
}
