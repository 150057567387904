import * as React from 'react';
import { Stack, Box, IconButton } from '@mui/material'
import Iconify from '../Iconify';

export default function HeaderDrawer({
    title,
    subtitle,
    close
}) {

    return (
        <Box 
        className="bg-gray-100 py-3"
        sx={{

            px:2,
        }}>
            <Stack justifyContent={'space-between'} direction="row" alignItems={'center'}>
                <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
                    {title}
                </h4>
                <p className="leading-7 [&:not(:first-child)]:mt-6">
                    {subtitle}
                </p>
                <IconButton onClick={close} >
                    <Iconify icon={'eva:close-outline'} sx={{ width: 20, height: 20 }} />
                </IconButton>
            </Stack>
        </Box>
    );
}
