import React from 'react';
import { FormControlLabel, Radio, Typography, FormControl, FormLabel, RadioGroup    } from '@mui/material';
import {  styled } from '@mui/material/styles';

import Iconify from './Iconify';

const RadioCustom = styled(Radio)(({ theme }) => ({
    '& .MuiSvgIcon-root': {
        fontSize: 20,
      },
  }));
  

const RadionIcon = ({ title, checked, onChange }) => {

  return (
    <FormControl>
    <RadioGroup
    row
      aria-labelledby="demo-radio-buttons-group-label"
      defaultValue="female"
    >
      <FormControlLabel  value={1} control={<RadioCustom />} label={<Typography sx={{fontSize:11}}>Frio</Typography>} />
      <FormControlLabel value={2} control={<RadioCustom />} label={<Typography sx={{fontSize:11}}>Morno</Typography>} />
      <FormControlLabel   value={3} control={<RadioCustom />} label={<Typography sx={{fontSize:11}}>Esquentando</Typography>} />
      <FormControlLabel value={5} control={<RadioCustom />} label={<Typography sx={{fontSize:11}}>Quente</Typography>} />
      <FormControlLabel value={0} control={<RadioCustom />} label={<Typography sx={{fontSize:11}}>Sem Interesse</Typography>} />
    </RadioGroup>
  </FormControl>
  );
};

export default RadionIcon;
