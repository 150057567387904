import axios from "axios";
import Config from "../../../config";

const v2_actionDeletarPipelineStage = (id) => {
    return axios({
        method: "delete",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/${id}/pipelines/deletar-estagio`
    });
};

export default v2_actionDeletarPipelineStage;
