import axios from "axios";

import Config from "../../config";

const actionCriarAgendamento = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/agendamentos/criar`,
        data,
        maxContentLength: Infinity,
        maxBodyLength: Infinity,        
    });
};

export default actionCriarAgendamento;
