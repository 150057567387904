
import { Icon } from '@iconify/react';
import { Box, Divider, Collapse, Typography, Stack, Paper } from '@mui/material';

import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { getChatsAtendimento, getChatInfoByChatId, getLoadMessagesInChat, getCountsChats } from 'src/redux/slices/chat';
import { useDispatch } from 'src/redux/store';
import { Autocomplete, Chip, IconButton, LinearProgress, TextField } from '@mui/material';
import useAuth from 'src/hooks/useAuth';
import v2_actionEditarTagsChats from 'src/actions/v2/chats/v2_actionEditarTagsChats';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import Scrollbar from 'src/components/Scrollbar';
import InputStyle from 'src/components/InputStyle';
import { LoadingButton } from '@mui/lab';
import { useSnackbar } from 'notistack';
import { RowInput } from 'src/components/forms/RowInput';
import { formataNumeroCelular } from 'src/utils/functions';
import v2_actionEditarChat from 'src/actions/v2/chats/v2_actionEditarChat';
import ChatInfoContatoForm from './ChatInfoContatoForm';
import ModalNovoContato from './Modals/ModalNovoContato';
import ModalVerContato from 'src/sections/@dashboard/cadastro/ModalVerContato';
import Iconify from 'src/components/Iconify';

// zui
import { Button } from 'src/@/components/ui/button';
import { Input } from 'src/@/components/ui/input';
import TagsComponent from 'src/components/tags/TagsComponent';

ChatInfoContato.propTypes = {
    isCollapse: PropTypes.bool,
    onCollapse: PropTypes.func
};

const RootStyle = styled('div')(({ theme }) => ({
    padding: 1
}));

export default function ChatInfoContato({ cliente, chat, isCollapse, listaTags, onCollapse, ...other }) {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar()
    const { listaRespostas } = useSelector((state) => state.chat);
    const [stateModalNovoContato, setStateModalNovoContato] = useState(false);
    const [nome, setNome] = useState(null);
    const { user } = useAuth();
    //tags
    const [loadingTags, setLoadingTags] = useState(false);
    const [tagsChamado, setTagsChamados] = useState(chat.tags_ids);
    const [isChanges, setIsChanges] = useState(false);
    const [modalVerContato, setModalVerContato] = useState(false);

    useEffect(() => {
        if (chat) {
            setNome(chat.nomeCliente ? chat.nomeCliente : "");
        }
        setTagsChamados(chat.tags_ids)

    }, [chat])

    async function onEditarTags(tags) {
        const reduceArrayTags = tags.map((acc) => acc._id)
        try {
            let data = {
                tags: reduceArrayTags,
                particpantExclude: user.id
            }
            setLoadingTags(true);
            await v2_actionEditarTagsChats(chat._id, data);
            dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId));
            // dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
            // // dispatch(getLoadMessagesInChat(chat.numeroCliente));
            dispatch(getChatInfoByChatId(chat._id, null, user.id, false));
            enqueueSnackbar('Tag atualizada com sucesso', { variant: 'success', autoHideDuration: 1500, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            setTimeout(() => {
                setLoadingTags(false)
            }, 900);

        } catch (error) {
            setLoadingTags(false)
            console.log(error)
        }
    }

    useEffect(() => {
        return () => {
            setNome(null);
        }
    }, [chat])

    const handleEditarContato = async (scope, refreshLista) => {
        try {
            let data = {
                scope: scope
            }
            await v2_actionEditarChat(chat._id, data)
            setIsChanges(false);
            dispatch(getChatInfoByChatId(chat._id, chat.funil_id, user.id, false));
            enqueueSnackbar('Chat atualizado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
            if (refreshLista) {
                dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
            }
        } catch (error) {
            console.log('error', error)
            enqueueSnackbar('Erro ao alterar chat', { variant: 'error', autoHideDuration: 3000, anchorOrigin: { vertical: 'top', horizontal: 'right' } });
        }
    }



    if (chat === undefined) {
        return null;
    }

    return (
        <Scrollbar sx={{ height: '90vh' }}>
            <RootStyle

                {...other}
            >

                {chat && (
                    <>

                        <Stack spacing={2}>
                            <Stack spacing={2}>
                                <RowInput
                                    label="nome do chat"
                                    input={<Input name="Nome" onChange={e => {
                                        setNome(e.target.value)
                                        setIsChanges(true)
                                    }}
                                        value={nome}
                                        variant="outlined" size="small" fullWidth />}
                                />

                            </Stack>

                            {/* {chat?.canal === "whatsapp" && (
                            <Stack spacing={2}>
                                <Box sx={{ width: '75%' }}>
                                    <RowInput
                                        label="telefone"
                                        input={<Input
                                            name="Telefone"
                                            disabled
                                            onChange={e => {
                                                setDDD(e.target.value)
                                                setIsChanges(true)
                                            }} value={chat?.numeroCliente?.startsWith("55") ? formataNumeroCelular(chat.numeroCliente) : `+${chat.numeroCliente}`} variant="outlined" size="small" fullWidth />}
                                    />
                                </Box>
                            </Stack>
                        )} */}

                            {chat?.canal === "instagram" && (
                                <Stack spacing={2}>
                                    <Box sx={{ width: '75%' }}>
                                        <RowInput
                                            label="usuário instagram"
                                            input={<InputStyle
                                                name="Usuário Instagram"
                                                disabled
                                                value={`@${chat?.instagramUser?.profile?.username}`}
                                                variant="outlined" size="small"
                                                fullWidth
                                            />}
                                        />
                                    </Box>
                                </Stack>
                            )}




                        </Stack>
                        {isChanges && (
                            <Stack
                                sx={{ width: { xs: '100%', md: '100%' }, mt: 3 }}
                                spacing={1}
                                direction={"row"}
                            >
                                <Button
                                    onClick={() => {
                                        let scope = {
                                            nomeCliente: nome
                                        }
                                        handleEditarContato(scope, true)
                                    }}
                                >
                                    Salvar Alterações
                                </Button>
                            </Stack>
                        )}

                    </>
                )}


                <Divider sx={{ mb: 1, mt: 3, borderStyle: 'dashed', color: theme => theme.palette.grey[500] }} />

                {/* <Stack spacing={2}>
                <Box sx={{ width: '100%' }}>
                    <RowInput
                        label="Tags"
                        input={
                            loadingTags ? <LinearProgress /> : (
                                listaTags && tagsChamado && (
                                    <Autocomplete
                                        multiple
                                        noOptionsText={'Sem opções cadastradas...'}
                                        sx={{ mb: 5, background: '#fff' }}
                                        limitTags={5}
                                        id="size-small-outlined-multi"
                                        options={listaTags}
                                        value={tagsChamado}
                                        size='small'
                                        onChange={(event, newInputValue) => {
                                            onEditarTags(newInputValue)
                                        }}
                                        renderTags={(value, getTagProps) =>
                                            value.map((option, index) => (
                                                <Chip
                                                    variant="warning"
                                                    label={option.nome}
                                                    color="default"
                                                    size="small"
                                                    {...getTagProps({ index })}
                                                />
                                            ))
                                        }
                                        getOptionLabel={(option) => option.nome}
                                        renderInput={(params) => (
                                            <TextField   {...params} sx={{ background: '#fff' }} fullWidth placeholder="Tags" />
                                        )}
                                    />
                                )

                            )
                        }
                    />
                </Box>
            </Stack> */}

                <Stack>
                    <TagsComponent 
                    user={user}
                    chat={chat}
                    chatId={chat?._id}  
                    tags={chat?.tags_ids || []}
                     />
                </Stack>


                <ModalNovoContato
                    cliente={cliente}
                    user={user}
                    open={stateModalNovoContato}
                    listaRespostas={listaRespostas}
                    listaClientesCanais={[]}
                    callbackAddContact={data => {
                        let scope = {
                            contato_id: data._id
                        }
                        handleEditarContato(scope)
                    }}
                    onClose={() => {
                        setStateModalNovoContato(false);
                    }}

                />

                <Stack sx={{ mt: 2 }} spacing={2}>
                    <Box sx={{ width: '100%' }}>
                        <RowInput
                            label="Contato"
                            input={<ChatInfoContatoForm
                                cliente={cliente}
                                lead={chat}
                                onOpenModalNovoContato={() => setStateModalNovoContato(true)}
                                onEditarContatoId={value => {
                                    let scope = {
                                        contato_id: value
                                    }
                                    handleEditarContato(scope)
                                }}
                            />}
                        />
                    </Box>
                </Stack>

                {chat?.contato_id && (
                    <Stack direction="row">

                        <Button
                            variant="secondary"
                            onClick={() => setModalVerContato(true)}
                        >
                            <Iconify className="mr-2 h-5 mt-0.5 w-4" icon="carbon:view-filled" />  Ver contato
                        </Button>

                        <ModalVerContato
                            open={modalVerContato}
                            onClose={() => setModalVerContato(false)}
                            user={user}
                            contatoData={chat?.contato}
                            cliente={cliente}
                            listaClientesCanais={[]}
                            loading={false}
                            callBackRefreshList={() => {
                                setModalVerContato(false);
                                dispatch(getChatInfoByChatId(chat._id))
                            }}
                        />
                    </Stack>
                )}

            </RootStyle>
        </Scrollbar>
    );
}
