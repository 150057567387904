import { Popover, Typography, Box, FormControl, TextField, Divider, MenuItem, Stack, IconButton, Button } from '@mui/material';
import { useEffect, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import v2_actionCriarEstagioFunil from 'src/actions/v2/cliente/v2_actionCriarEstagioFunil';
import v2_actionEditarPipeline from 'src/actions/v2/crm/v2_actionEditarPipeline';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';

export default function PopoverEditarEstagio({
  open,
  id,
  cliente,
  pipelineStage,
  anchorEl,
  onClose,
  onCallback
}) {

  const [nome, setNome] = useState('');
  const [loading, setLoading] = useState(false);



  async function handleEditarPipilene(id, scope) {
    try {
      let data = {
        action: "editar_pipeline_stage",
        scope: scope
      }
      await v2_actionEditarPipeline(pipelineStage?._id, data, cliente._id);
      onCallback()

    } catch (error) {

    }

  }


  useEffect(() => {
    setNome(pipelineStage?.nome);
  }, [pipelineStage])

  return (
    <Popover
      sx={{ mt: 1 }}
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box sx={{ width: 300, height: 150, p: 3 }}>
        <Stack direction={{ xs: 'column', md: 'column' }} spacing={2} sx={{ width: 1 }}>

          <Stack direction="row" spacing={1}>

            <RowInput
              label={'Nome'}
              input={
                <InputStyle
                  size="small"
                  //type="number"
                  value={nome}
                  onChange={(event) => setNome(event.target.value)}
                />
              }
            />


          </Stack>

          <LoadingButton loading={loading} onClick={() => handleEditarPipilene(id, { nome: nome })} variant='contained'>
            Salvar
          </LoadingButton>



        </Stack>
      </Box>

    </Popover>
  );
}


