import {
    Box,
    Grid,
    Typography,
    Stack,
    Button,
    IconButton,
    Divider,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    ListItemAvatar,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    DialogContentText
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

//paginas
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import Iconify from 'src/components/Iconify';
import { formataNumeroCelular, verifyRules } from 'src/utils/functions';
import Label from 'src/components/Label';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import v2_actionMetaListaPaginas from 'src/actions/v2/meta/v2_actionMetaListaPaginas';
import v2_actionMetaInfoInstagram from 'src/actions/v2/meta/v2_actionMetaInfoInstagram';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PrivacyPolicySection from './PrivacyPolicy';
// ----------------------------------------------------------------------

CanaisMetaPageList.propTypes = {
    canais: PropTypes.array,
};

export default function CanaisMetaPageList() {
    const [openModalAlerta, setModalAlerta] = useState(false)
    const navigate = useNavigate()
    // Obtém o objeto de localização da URL
    const location = useLocation();
    // Extrai o parâmetro de consulta "channel" da string de busca (search) da URL
    const params = new URLSearchParams(location.search);
    const channel = params.get('channel');

    const [listPagesMeta, setListPagesMeta] = useState([])
    const [listInstagramPages, setListInstagramPages] = useState([])

    const [selectedIndex, setSelectedIndex] = useState(null);
    const [selectedIndexInstagram, setSelectedIndexInstagram] = useState(null);
    const [idInstagram, setIdInstagram] = useState(null);

    //privacy policy
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);


    const handleListItemClick = (event, index) => {
        setSelectedIndex(index);
        setListInstagramPages([])
        setSelectedIndexInstagram(1)
    };

    const handleListItemClickInstagram = (event, index) => {
        setSelectedIndexInstagram(index);
    };

    async function buscarListaPaginasMeta() {
        try {
            let querys = `?canal_id=${channel}`
            const response = await v2_actionMetaListaPaginas(querys)
            setListPagesMeta(response.data.data);
        } catch (error) {

        }
    }

    async function getInfoAccountInstagram(id) {
        try {
            let querys = `?canal_id=${channel}`
            const response = await v2_actionMetaInfoInstagram(id, querys)
            setListInstagramPages([])
            setListInstagramPages([...listInstagramPages, response.data.data]);
        } catch (error) {

        }
    }

    useEffect(() => {
        buscarListaPaginasMeta()

    }, [])


    return (
        <>
            <HeaderPageComponentConfig
                titulo="Configuração de contas"
                descricao={'Cinfiguração de contas de atendimento'}
            //  actions={<Button variant='contained'>Teste</Button>}
            />


            <ModalAlerta
                open={openModalAlerta}
                mensagem={"Canais configurados com sucesso!"}
                onClose={() => {
                    navigate('/chats/chat');
                    setModalAlerta(false)
                }}
            />

            <Dialog open={openPrivacyPolicy} onClose={() => setOpenPrivacyPolicy(false)}>
                <DialogTitle>Políticas de Privacidade PLataforma</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                      <PrivacyPolicySection />
                    </DialogContentText>

                </DialogContent>
                <DialogActions>
                    <Button variant='contained' onClick={() => setOpenPrivacyPolicy(false)} color="primary">
                        Ok
                    </Button>
                </DialogActions>
            </Dialog>



            <Box sx={{ flex: 1, p: 3 }}>

                <Box sx={{ width: '30%', mt: 3 }}>
                    <Stack direction={"column"} spacing={5}>
                        <Stack direction="column" spacing={1}>
                            <Stack>
                                <Typography variant="h6" color="text.primary">Páginas</Typography>
                                <Typography variant="caption" color="text.primary">Selecione uma página</Typography>
                            </Stack>

                            <List component="nav" aria-label="main mailbox folders">
                                {listPagesMeta && listPagesMeta.map((item, index) => (
                                    <ListItemButton
                                        sx={{ border: '1px solid #e0e0e0', borderRadius: 1 }}
                                        selected={selectedIndex === index}
                                        disabled={selectedIndex === index}
                                        onClick={(event) => {
                                            getInfoAccountInstagram(item.instagram_business_account.id)
                                            handleListItemClick(event, index)
                                        }}
                                    >
                                        <Stack direction={"row"} spacing={3} alignItems={"center"}>

                                            {selectedIndex === index ?
                                                <Iconify sx={{ color: 'primary.main' }} icon={"el:ok-circle"} width={22} height={22} />
                                                :
                                                <Iconify color="green" icon={"material-symbols:circle-outline"} width={22} height={22} />
                                            }
                                            <ListItemAvatar
                                                sx={{ width: 40, height: 40 }}

                                            >
                                                <Avatar
                                                    borderRadius={1}
                                                    alt={item.name}
                                                    src={item.profile}
                                                />

                                            </ListItemAvatar>

                                            <Stack>
                                                <ListItemText primary={item.name} />
                                                <ListItemText secondary={item.id} />
                                            </Stack>

                                        </Stack>
                                    </ListItemButton>
                                )
                                )}
                            </List>
                        </Stack>

                        {!selectedIndex && listInstagramPages.length > 0 && (
                            <Stack>
                                <Typography variant="h6" color="text.primary">Contas do instagram</Typography>
                                <List component="nav" aria-label="main mailbox folders">
                                    {listInstagramPages && listInstagramPages.map((item, index) => (
                                        <ListItemButton
                                            sx={{ border: '1px solid #e0e0e0', borderRadius: 1 }}
                                            selected={selectedIndexInstagram === index}
                                            onClick={(event) => {
                                                setIdInstagram(item.id);
                                                handleListItemClickInstagram(event, index)
                                            }}
                                        >
                                            <Stack direction={"row"} spacing={3} alignItems={"center"}>

                                                {selectedIndexInstagram === index ?
                                                    <Iconify sx={{ color: 'primary.main' }} icon={"el:ok-circle"} width={22} height={22} />
                                                    :
                                                    <Iconify color="green" icon={"material-symbols:circle-outline"} width={22} height={22} />
                                                }

                                                <ListItemAvatar
                                                    sx={{ width: 40, height: 40 }}

                                                >
                                                    <Avatar
                                                        borderRadius={1}
                                                        alt={item.name}
                                                        src={item.profile_picture_url}
                                                    />

                                                </ListItemAvatar>

                                                <Stack>
                                                    <ListItemText primary={item.name} />
                                                    <ListItemText secondary={`@${item.username}`} />
                                                </Stack>

                                            </Stack>
                                        </ListItemButton>
                                    )
                                    )}
                                </List>
                            </Stack>
                        )}

                        <Stack direction={"row"} alignItems={"center"}>
                            <Typography variant='caption'>Ao prosseguir, você concorda com nossa</Typography>
                            <Button sx={{ fontSize: 12 }} variant='text' onClick={() => setOpenPrivacyPolicy(true)}>políticas de privacidade</Button>
                        </Stack>

                        <Button
                            disabled={!idInstagram}
                            variant='contained'
                            onClick={() => setModalAlerta(true)}
                        >
                            Salvar
                        </Button>



                    </Stack>

                </Box>

            </Box>
        </>
    );
}

