import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Grid, Box } from '@mui/material';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';


export default function PerfilAlterarSenha({
  isSubmitting,
  password,
  repeatPassword,
  changePassword,
  changeRepeatPassword,
  submit
}) {
  return (
    <Grid container>
      <Grid item md={8} xs={12}>
        <Box>
          <Stack direction={"row"} spacing={1.5}>

            <RowInput
              label="Senha"
              input={<InputStyle
                value={password}
                type="password"
                onChange={changePassword}
              />}
            />

            <RowInput
              label="Confirmar Senha"
              input={<InputStyle
                value={repeatPassword}
                type="password"
                onChange={changeRepeatPassword}
              />}
            />


          
          </Stack>
          <LoadingButton sx={{mt:2}} size="small" onClick={submit} variant="contained" loading={isSubmitting}>
              Salvar
            </LoadingButton>
        </Box>
      </Grid>
    </Grid>

  );
}
