import axios from "axios";
import Config from "src/config";

const v1_actionListaSetores = (clienteId) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/${clienteId}/setores`
    });
};

export default v1_actionListaSetores;
