import { Stack, Typography, Box } from '@mui/material'
import LabelInput from '../LabelInput'
import { ZuiTooltip } from 'src/@/components/use/tooltip'
import Iconify from '../Iconify'

export const RowInput = ({ label, input, isRequired, info, sx }) => {
    return (
        <Stack sx={{ ...sx }} spacing={0.5} direction="column">
            <Stack alignItems={"center"} direction="row" spacing={0.5}>
                <small className='text-gray-500'> {label}</small>
                <Typography sx={{ fontSize: 7, color: "#f03", fontWeight: '400' }}>{isRequired ? "(Obrigatório)" : ""} </Typography>
                {info && (
                    <ZuiTooltip content={info}>
                        <Box>
                        <Iconify className="text-violet-600 w-4 h-4" icon="material-symbols:help" />
                        </Box>
                    </ZuiTooltip>
                )}
            </Stack>
            {input}
        </Stack>

    )
}
