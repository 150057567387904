import axios from "axios";

import Config from "src/config";

const v2_actionBuscarDadosCliente = clienteId => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/${clienteId}/detalhes`
    });
};

export default v2_actionBuscarDadosCliente;
