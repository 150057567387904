import axios from "axios";

import Config from "src/config";

const v2_actionEditarChat = (chatId, data) => {
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/${chatId}/editar`,
        data
    });
};
export default v2_actionEditarChat;
