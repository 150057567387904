import {
    Box,
    Tabs,
    Tab,
    TabPanel,
    Stack,
    MenuItem,
    Select,
    Autocomplete,
    Chip
} from '@mui/material';
import * as React from 'react';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import InputStyleMultline from 'src/components/InputStyleMultline';
// ----------------------------------------------------------------------



export default function ChannelDirect({
    canal,
    onChange,
    onChangeAutocomplete,
    listSectors,
    listUsers,
}) {



    const usuarios = canal?.config?.direcionamento_direto?.direciona_usuario.map(id => listUsers.find(usuario => usuario._id === id))
    const setores = canal?.config?.direcionamento_direto?.direciona_setor.map(id => listSectors.find(setor => setor._id === id))


    return (
        <Stack direction={"column"} sx={{ mt: 2 }} spacing={3}>
            <Stack direction={"column"} spacing={2}>
                <RowInput
                    label={"status"}
                    sx={{ width: '50%' }}

                    input={<Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={canal?.config?.direcionamento_direto?.statusRoom}
                        name="statusRoomDirectTarget"
                        size='small'
                        onChange={onChange}
                    >
                        <MenuItem value={"Atendendo"}>Atendendo</MenuItem>
                        <MenuItem value={"Pendente"}>Pendente</MenuItem>
                    </Select>}
                />
                
                <Stack direction={"row"} spacing={2}>
                <RowInput
                    label={"direciona usuários"}
                    sx={{ width: '100%' }}
                    input={<Autocomplete
                        multiple
                        variant="outlined"
                        fullWidth
                        options={!listUsers ? [{nomeFantasia:"Loading...", _id:0}] : listUsers }

                        size='small'
                        name="directTargetUser"
                        getOptionLabel={(option) => `${option?.nomeFantasia} - ${option.nome_setor}`}
                        value={usuarios || []}
                        //filterSelectedOptions
                        onChange={(e, newValue) => onChangeAutocomplete(e, newValue, 'directTargetUser')}
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (<Chip variant="outlined" label={option?.nomeFantasia} {...getTagProps({ index })} />))
                        }
                        renderInput={(params) => (
                            <InputStyleMultline {...params} variant="outlined" />
                        )}
                    />}
                />

                <RowInput
                    label={"direciona setores"}
                    sx={{ width: '100%' }}
                    input={<Autocomplete
                        multiple
                        variant="outlined"
                        fullWidth
                        options={!listSectors ? [{nome_setor:"Loading...", _id:0}] : listSectors }
                        size='small'
                        getOptionLabel={(option) => option?.nome_setor}
                        value={setores || []}
                        //filterSelectedOptions
                        onChange={(e, newValue) => onChangeAutocomplete(e, newValue, 'directTargetSector')}
                        name="directTargetSector"
                        renderTags={(value, getTagProps) =>
                            value.map((option, index) => (<Chip variant="outlined" label={option?.nome_setor} {...getTagProps({ index })} />))
                        }
                        renderInput={(params) => (
                            <InputStyleMultline {...params} variant="outlined" />
                        )}
                    />}
                />
                </Stack>
            </Stack>

            <Stack>
                <RowInput
                    label={"mensagem após direcionamento"}
                    sx={{ width: '100%' }}

                    input={<InputStyleMultline
                        autoFocus
                        type="text"
                        value={canal?.config?.direcionamento_direto?.mensagem_apos_cadastro}
                        name="directTargetMessage"
                        margin="dense"
                        variant="outlined"
                        multiline
                        rows={6}
                        onChange={onChange}
                    />}
                />
            </Stack>
        </Stack>
    );
}

