import {
    IconButton,
    Box,
    Autocomplete,
    FormControl,
    MenuItem,
    Stack,
    Typography,
    Accordion,
    AccordionSummary,
    Divider,
    Rating,
    Menu,
    Tooltip,
    Chip,
    Paper,
    Button
} from '@mui/material';
import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Iconify from 'src/components/Iconify';
import { RowInput } from 'src/components/forms/RowInput';
import DrawerNegocioStagesPipeline from './DrawerNegocioStagesPipeline';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import InputStyle from 'src/components/InputStyle';
import LabelInput from 'src/components/LabelInput';
import { useDispatch } from 'react-redux';
import NovoNegocioForm from '../../cadastro/NovoNegocioForm';
import Scrollbar from 'src/components/Scrollbar';
import NovaEmpresaForm from '../../cadastro/NovaEmpresaForm';

//actions
import v2_actionPesquisarContatos from 'src/actions/v2/contatos/v2_actionPesquisarContatos';
import v2_actionPesquisarContatosEmpresa from 'src/actions/v2/contatos/v2_actionPesquisarContatosEmpresa';
import v2_actionCriarContatoEmpresa from 'src/actions/v2/contatos/v2_actionCriarContatoEmpresa';


//components
import RadionIcon from 'src/components/RadionIcon';
import NovoContatoForm from '../../cadastro/NovoContatoForm';
import InputStyleAutocomplete from 'src/components/InputStyleAutocomplete';
import v2_actionEditarLead from 'src/actions/v2/crm/v2_actionEditarLead';
import { fDate, fTime, fTimestamp, fToNow } from 'src/utils/formatTime';
import { useSnackbar } from 'notistack';
import { formataNumeroCelular } from 'src/utils/functions';
import getActionPesquisarChat from 'src/actions/chats/getActionPesquisarChat';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';
import { LoadingButton } from '@mui/lab'

// ----------------------------------------------------------------------

const MenuCanaisConversa = ({ anchorEl, onClose, open, cliente, user, onSelectChat }) => {

    const [listaContatos, setListaContatos] = useState([]);
    const [pesquisa, setPesquisa] = useState('');
    const [contatoWhatsappId, setContatoWhatsappId] = useState(null);

    const [modalAlerta, setModalAlerta] = useState(null);
    const [modalAlertaMensagem, setModalAlertaMensagem] = useState(null);

    const pesquisarChat = async (value) => {
        try {
            setPesquisa(value);
            let data = {
                clienteId: cliente._id,
                search: value
            }
            const result = await getActionPesquisarChat(data);
            setListaContatos(result.data.chats);

        } catch (error) {
            console.error(error);
        }
    };

    async function validarInclusaoChatLead(value) {
        const { _id, direciona_usuarios, statusRoom } = value;
        let statusPermitidos = ['Finalizado', 'Avaliando', 'Geral'];

        if (statusPermitidos.includes(statusRoom)) {
            onSelectChat(value);
            setContatoWhatsappId(value)
        } else {
            if (direciona_usuarios.length > 0 && direciona_usuarios.includes(user.id)) {
                onSelectChat(value);
                setContatoWhatsappId(value)
            } else {
                setModalAlerta(true);
                setModalAlertaMensagem('Não é possível incluir esse chat, pois ele está em andamento com outro usuário. Por favor, escolha outro chat.');
                setContatoWhatsappId(null);
            }
        }

    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <ModalAlerta
                open={modalAlerta}
                mensagem={modalAlertaMensagem}
                onClose={() => {
                    setModalAlerta(false);
                    setModalAlertaMensagem(null);
                }}
            />
            <Paper sx={{ width: 300, height: 100, p: 2 }}>

                <RowInput

                    label="Whatsapp"
                    input={<Autocomplete
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        placeholder='Escolha um contato'
                        filterSelectedOptions
                        id="combo-box-demo"
                        options={listaContatos}
                        value={contatoWhatsappId}
                        inputValue={pesquisa}
                        noOptionsText={
                            <Stack direction="column" spacing={1}>
                                <Typography variant="extraSmall" textAlign={"center"} sx={{ color: 'text.secondary' }}>
                                    Digite 5 caracteres para começar a pesquisar
                                </Typography>
                                {/* <Button startIcon={<Iconify icon={'gg:add'} sx={{ width: 20, height: 20 }} />} size="small">
                                    Criar Contato
                                </Button> */}
                            </Stack>
                        }
                        onChange={(event, newValue, reason) => {
                            if (reason === "reset") {
                                setContatoWhatsappId(null);

                            }
                            setContatoWhatsappId(newValue);
                            validarInclusaoChatLead(newValue)

                        }}
                        onInputChange={async (event, newInputValue) => {
                            setPesquisa(newInputValue);
                            if (newInputValue.length > 3) {
                                pesquisarChat(newInputValue);
                            } else {
                                setListaContatos([]);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option._id === value._id}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : (formataNumeroCelular(option.numeroCliente))

                        }
                        renderInput={(params) => <InputStyleAutocomplete sx={{ width: 250 }} {...params} />}
                    />}

                />



                {/* <MenuItem onClick={onClose}>
                <Stack alignItems={"center"} spacing={1} direction={"row"}>
                    <Iconify icon="mdi:whatsapp" />
                    <Typography variant="caption">Whatsapp</Typography>
                </Stack>
            </MenuItem> */}

            </Paper>

        </Menu>
    )
}


const MenuAlterarFunil = ({ anchorEl, configCrmProps, onClose, open, cliente, user, onConfirm }) => {

    const [listaContatos, setListaContatos] = useState([]);
    const [pipelineId, setPipelineId] = useState(null);
    const [pipelineStageId, setPipelineStageId] = useState(null);

    const [modalAlerta, setModalAlerta] = useState(null);
    const [modalAlertaMensagem, setModalAlertaMensagem] = useState(null);
    const [configCrm, setConfigCrm] = useState(configCrmProps?.configCrmProps);

    async function buscarConfigCrm(querys) {
        try {
            const result = await v2_actionConfigCrm(cliente._id, querys);
            if (result.data.data) {
                setConfigCrm(result.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={onClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <ModalAlerta
                open={modalAlerta}
                mensagem={modalAlertaMensagem}
                onClose={() => {
                    setModalAlerta(false);
                    setModalAlertaMensagem(null);
                }}
            />
            <Paper sx={{ width: 400, height: 300, p: 2 }}>

                <Stack spacing={3} direction={"column"}>

                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '100%' }}>
                            <RowInput
                                isRequired={true}
                                label="Funil"
                                input={<FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                                    <InputStyle
                                        select
                                        placeholder="Funil"
                                        fullWidth
                                        size="small"
                                        value={pipelineId}
                                        onChange={async (e) => {
                                            setPipelineId(e.target.value)
                                            let querys = `?pipeline_id=${e.target.value}`
                                            buscarConfigCrm(querys);

                                        }}
                                    >
                                        {configCrmProps?.pipelines.map((option) => (
                                            <MenuItem key={option._id} value={option._id}>
                                                {option.nome}
                                            </MenuItem>
                                        ))}
                                    </InputStyle>
                                </FormControl>}
                            />
                        </Box>
                    </Stack>

                    {pipelineId && (
                        <Stack
                            sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                            spacing={1}
                            direction={"row"}
                        >
                            <Box sx={{ width: '100%' }}>
                                <RowInput
                                    isRequired={true}
                                    label="Estagio do Funil"
                                    input={<FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                                        <InputStyle
                                            select
                                            fullWidth
                                            disabled={!pipelineId}
                                            size="small"
                                            value={pipelineStageId}
                                            onChange={e => {
                                                setPipelineStageId(e.target.value);

                                            }}
                                        >
                                            {configCrm?.stages.map((option) => (
                                                <MenuItem key={option._id} value={option._id}>
                                                    {option.nome}
                                                </MenuItem>
                                            ))}
                                        </InputStyle>
                                    </FormControl>}
                                />
                            </Box>
                        </Stack>
                    )}

                    <Stack direction="row">
                        <LoadingButton onClick={() => onConfirm({ pipelineId, pipelineStageId })} loading={false} variant="contained" >
                            Finalizar
                        </LoadingButton>
                    </Stack>


                </Stack>
            </Paper>

        </Menu>
    )
}

const RootStyle = styled('div')(({ theme }) => ({
    // height: 120,
    // display: 'flex',
    // position: 'relative',
    backgroundColor: theme.palette.grey[200],
    alignItems: 'center',
    padding: 20,
    borderRadius: 10,
}));

const StyledRating = styled(Rating)({
    '& .MuiRating-iconFilled': {
        color: '#ff6d75',
    },
    '& .MuiRating-iconHover': {
        color: '#ff3d47',
    },
});

const labels = {
    1: 'Sem interesse',
    2: 'Frio',
    3: 'Morno',
    4: 'Esquentando',
    5: 'Quente',
};

function getLabelText(value) {
    return `${value} ${value !== 1 ? 's' : ''}, ${labels[value]}`;
}

const SelectCanaisConversaItem = ({
    open,
    onClose,
    onChange
}) => {
    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">
                {"Use Google's location service?"}
            </DialogTitle>
            <DialogContent>

            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Fechar
                </Button>
            </DialogActions>
        </Dialog>
    )
}

// ----------------------------------------------------------------------

DrawerNegocioHead.propTypes = {
    disabled: PropTypes.bool,
    conversationId: PropTypes.string,
    onSend: PropTypes.func
};

export default function DrawerNegocioHead({
    configCrm,
    lead,
    user,
    cliente,
    loading,
    disabled,
    changeText,
    text,
    conversationId,
    onSend,
    onOpenMenu,
    onOpenChatWhatsapp,
    callBackRefreshLead,
    callbackRefreshLeads,
    ...other
}) {

    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [anchorMenuAlterarFunil, setAnchorMenuAlterarFunil] = useState(null);
    const openAnchorAlterarFunil = Boolean(anchorMenuAlterarFunil);
    const [anchorMenuCanaisConversa, setAnchorMenuCanaisConversa] = useState(null);
    const openAnchorMenuCanaisConversa = Boolean(anchorMenuCanaisConversa);
    const [listaContatos, setListaContatos] = useState([]);
    const [listaEmpresas, setListaEmpresas] = useState([]);
    const [empresaId, setEmpresaId] = useState(null);
    const [hover, setHover] = useState(-1);



    const handleCloseMenuCanaisConvera = () => {
        setAnchorMenuCanaisConversa(null);
    };


    const [negocio, setNegocio] = useState({
        nome: null,
        pipelineId: lead?.pipelineId,
        pipelineStageId: lead?.pipelineStageId,
        origemLead: lead?.origem_lead_id,
        qualificacao: lead?.qualificacao,
        dataFechamento: lead?.data_fechamento,
        contatoId: null,
        valor: lead?.valor,
        contato: {
            nome: '',
            email: '',
            whatsapp: '',
            dataNascimento: '',
            cpf: '',
            endereco: {
                cep: '',
                logradouro: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: ''
            }
        },
        empresa: {
            nome: '',
            cnpj: '',
            endereco: {
                cep: '',
                logradouro: '',
                numero: '',
                complemento: '',
                bairro: '',
                cidade: '',
                estado: ''
            }
        }
    });



    function clearFields() {
        setNegocio({
            nome: null,
            pipelineId: null,
            pipelineStageId: null,
            origemLead: null,
            qualificacao: null,
            contatoId: null,
            valor: null,
            contato: {
                nome: '',
                email: '',
                whatsapp: '',
                dataNascimento: '',
                cpf: '',
                endereco: {
                    cep: '',
                    logradouro: '',
                    numero: '',
                    complemento: '',
                    bairro: '',
                    cidade: '',
                    estado: ''
                }
            },
            empresa: {
                nome: '',
                cnpj: '',
                endereco: {
                    cep: '',
                    logradouro: '',
                    numero: '',
                    complemento: '',
                    bairro: '',
                    cidade: '',
                    estado: ''
                }
            }
        })
    }


    useEffect(() => {
    }, [listaContatos])

    async function pesquisarContatos(pesquisa) {
        try {
            const result = await v2_actionPesquisarContatos(cliente._id, pesquisa)
            setListaContatos(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function pesquisarContatosEmpresa(pesquisa) {
        try {
            const result = await v2_actionPesquisarContatosEmpresa(cliente._id, pesquisa)
            setListaEmpresas(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function criarContatoEmpresa(data) {
        const { empresa } = data;
        console.log('data criar contatos', empresa)
        try {
            let data = {
                clienteId: cliente._id,
                usuarioId: user.id,
                scope: {
                    nome: empresa.nome,
                    info: {
                        email: empresa.email,
                        cnpj: empresa.cnpj,
                        site: "",
                        nomeFantasia: "",
                        razaoSocial: "",
                        inscricaoEstadual: "",
                        inscricaoMunicipal: "",
                        telefone: "",
                    },
                    segmentos: [],
                    atributos: [],
                    endereco: empresa.endereco,
                    contatos: [],
                }
            }
            const result = await v2_actionCriarContatoEmpresa(data)

            await pesquisarContatosEmpresa(empresa.nome);
            //setEmpresaId(result.data.data)
            enqueueSnackbar(result.data.mensagem, { variant: 'success' });
        } catch (error) {
            console.log(error);
        }
    }



    async function editarLead(action, value) {

        if (action === "editar_qualificacao") {
            let data = {
                action: action,
                scope: {
                    qualificacao: Number(value),
                }
            }
            const result = await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Qualificação alterada com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } else if (action === "editar_origem_lead") {
            let data = {
                action: action,
                scope: {
                    origem_lead_id: value
                }
            }
            const result = await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Origem  alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } else if (action === "editar_estagio_funil") {
            let data = {
                action: action,
                scope: {
                    estagio_pipeline_id: value
                }
            }
            const result = await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Estágio alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } else if (action === "editar_data_fechamento") {
            let data = {
                action: action,
                scope: {
                    data_previsao: value
                }
            }
            await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Data Alterada com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } else if (action === "editar_chat_whatsapp") {
            let data = {
                action: action,
                scope: {
                    whatsapp_chat_id: value
                }
            }
            await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Chat incluído com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
            callbackRefreshLeads();
        } else if (action === "editar_funil") {
            let data = {
                action: action,
                scope: value
            }
            await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Funil alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
            callbackRefreshLeads();
            callBackRefreshLead()
        } else if (action === "editar_valor") {
            let data = {
                action: action,
                scope: {
                    valor: value
                }
            }
            await v2_actionEditarLead(lead._id, data, user.id);
            enqueueSnackbar('Valor alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
            callbackRefreshLeads();
            callBackRefreshLead()
        }

    }

    const newStagesPipelines = configCrm?.stages.map((i, index) => {
        let newObj = {
            ...i,
            posicao: index
        }
        return newObj;
    })

    const ativeStepFilter = newStagesPipelines?.filter((x) => x._id === lead?.estagio_pipeline_id)
    const posicaoStage = ativeStepFilter && ativeStepFilter.length > 0 && ativeStepFilter[0].posicao;

    function substituirVirgulaPorPonto(valor) {
        if(!valor) return;
         return valor.toString().replace(',', '.');

        // return valor.replace(',', '.');
    }



    return (
        <Scrollbar>
            <RootStyle {...other}>
                <Stack direction="row" spacing={3}>

                    <Stack direction={"column"}>
                        <Stack alignItems={"center"} spacing={1} direction="row">
                            <LabelInput>
                                {lead?.nomeFunil}
                            </LabelInput>
                            <IconButton
                                aria-controls={openAnchorAlterarFunil ? 'basic-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openAnchorAlterarFunil ? 'true' : undefined}
                                onClick={event => setAnchorMenuAlterarFunil(event.currentTarget)}
                            >
                                <Iconify width={14} height={14} icon="mdi:edit" />
                            </IconButton>
                            <MenuAlterarFunil
                                onConfirm={value => {
                                    let data = {
                                        crm_pipeline_id: value?.pipelineId,
                                        estagio_pipeline_id: value?.pipelineStageId
                                    }
                                    editarLead("editar_funil", data);
                                    callbackRefreshLeads();
                                    callBackRefreshLead();
                                    setAnchorMenuAlterarFunil(null);
                                }}
                                configCrmProps={configCrm}
                                cliente={cliente}
                                user={user}
                                open={openAnchorAlterarFunil}
                                anchorEl={anchorMenuAlterarFunil}
                                onClose={() => setAnchorMenuAlterarFunil(null)}
                            />
                        </Stack>
                        <Box sx={{ mt: 2 }}>
                            <DrawerNegocioStagesPipeline
                                activeStepProps={posicaoStage}
                                onChange={async (value) => {
                                    setNegocio({ ...negocio, origemLead: value });
                                    await editarLead("editar_estagio_funil", value);
                                    callbackRefreshLeads();
                                }}
                                estagios={configCrm?.stages && newStagesPipelines}
                            />
                        </Box>

                    </Stack>

                    <Stack spacing={5} direction={"row"}>


                        {/* <RowInput label="Previsão fechamento" input={
                            <DateTimePicker
                            sx={{width:190}}
                            onChange={ async (newValue) => {
                                setNegocio({ ...negocio, dataFechamento: newValue });
                                await editarLead("editar_data_fechamento", newValue);
                            }}
                            value={negocio?.dataFechamento}
                            minDate={new Date()}

                            reduceAnimations
                            onClose={() => console.log('teste')}
                                slots={{
                                    textField: (params) => <InputStyle {...params} />,
                                }}
                            />
                        } /> */}


                        <RowInput
                            label="Origem Contato"
                            input={<FormControl fullWidth sx={{ m: 1, minWidth: 150 }}>
                                <InputStyle
                                    select
                                    size="small"
                                    value={negocio?.origemLead}
                                    onChange={async e => {
                                        setNegocio({ ...negocio, origemLead: e.target.value });
                                        await editarLead("editar_origem_lead", e.target.value);
                                        callbackRefreshLeads();
                                    }}
                                >
                                    {configCrm?.origens.map((option) => (
                                        <MenuItem key={option._id} value={option._id}>
                                            {option.name}
                                        </MenuItem>
                                    ))}
                                </InputStyle>
                            </FormControl>}
                        />

                        <RowInput
                            label="Valor do negócio"
                            input={<FormControl fullWidth sx={{ m: 1, maxWidth: 120 }}>
                                <InputStyle
                                    size="small"
                                    value={substituirVirgulaPorPonto(negocio?.valor) }
                                    onBlur={() =>  {
                                        if(negocio?.valor){
                                            editarLead("editar_valor", negocio?.valor);
                                            callbackRefreshLeads();
                                        }
                                    }}
                                    onChange={async e => {
                                        setNegocio({ ...negocio, valor: e.target.value });
                                        await editarLead("editar_valor", e.target.value);
                                        callbackRefreshLeads();
                                    }}
                                />
                            </FormControl>}
                        />


                        <RowInput
                            label="Qualificação"
                            input={
                                <Stack spacing={1} direction="column">

                                    <StyledRating
                                        onChange={async (event) => {
                                            setNegocio({ ...negocio, qualificacao: event.target.value })
                                            await editarLead("editar_qualificacao", event.target.value);
                                            callbackRefreshLeads();
                                        }}
                                        icon={<Iconify width={22} height={22} icon="fluent:fire-24-filled" />}
                                        emptyIcon={<Iconify width={22} height={22} icon="fluent:fire-24-regular" />}
                                        name="size-small"
                                        getLabelText={getLabelText}
                                        onChangeActive={(event, newHover) => {
                                            setHover(newHover);
                                        }}
                                        defaultValue={negocio.qualificacao}
                                        value={negocio.qualificacao}
                                        sx={{ mt: 2 }}

                                    />
                                    <Typography sx={{ fontSize: 10, color: 'text.secondary' }}>
                                        {labels[hover !== -1 ? hover : negocio.qualificacao]}
                                    </Typography>
                                </Stack>
                            }
                        />

                    </Stack>



                    <Stack direction="row" spacing={2}>
                        <Stack spacing={2} direction="row">



                            <Box>


                                <Stack direction="column">
                                    <LabelInput>
                                        Canais de conversa
                                    </LabelInput>

                                    <Stack alignItems={"center"} direction="row">
                                        {lead?.whatsapp ? (
                                            <Stack alignItems={"center"} direction="row" spacing={0}>

                                                <Chip
                                                    onClick={() => onOpenChatWhatsapp(lead?.whatsapp)}
                                                    sx={{ borderRadius: 1.5, backgroundColor: 'white' }}
                                                    icon={<IconButton color="primary">
                                                        <Tooltip title="Desvincular chat do lead" onClick={() => onOpenChatWhatsapp(lead?.whatsapp)}>
                                                            <Iconify icon={'ic:baseline-whatsapp'} sx={{ width: 12, height: 12 }} />
                                                        </Tooltip>
                                                    </IconButton>}
                                                    label={<Typography variant="extraSmall">
                                                        {formataNumeroCelular(lead?.whatsapp?.numero)}</Typography>}
                                                    variant="outlined"
                                                    onDelete={() => {
                                                        editarLead("editar_chat_whatsapp", null);
                                                        callBackRefreshLead()
                                                    }}
                                                    deleteIcon={
                                                        <Tooltip title="Desvincular chat do lead">
                                                            <Iconify
                                                                icon={'mdi:clear-bold'}
                                                                sx={{ width: 16, height: 16, pt: 0.2 }} />
                                                        </Tooltip>}
                                                />
                                                <IconButton color="primary" onClick={() => onOpenChatWhatsapp(lead?.whatsapp)}>
                                                </IconButton>
                                            </Stack>

                                        ) : (
                                            <>
                                                <IconButton
                                                    color="primary"
                                                    onClick={event => setAnchorMenuCanaisConversa(event.currentTarget)}
                                                    aria-controls={openAnchorMenuCanaisConversa ? 'basic-menu' : undefined}
                                                    aria-haspopup="true"
                                                    aria-expanded={openAnchorMenuCanaisConversa ? 'true' : undefined}
                                                >
                                                    <Iconify width={18} height={18} icon="gala:add" />
                                                </IconButton>
                                                <MenuCanaisConversa
                                                    onSelectChat={value => {
                                                        editarLead("editar_chat_whatsapp", value?._id);
                                                        callBackRefreshLead();
                                                        setAnchorMenuCanaisConversa(null);
                                                    }}
                                                    cliente={cliente}
                                                    user={user}
                                                    open={openAnchorMenuCanaisConversa}
                                                    anchorEl={anchorMenuCanaisConversa}
                                                    onClose={handleCloseMenuCanaisConvera}
                                                />
                                            </>
                                        )}
                                    </Stack>
                                </Stack>
                            </Box>
                        </Stack>
                    </Stack>
                </Stack>

            </RootStyle >
        </Scrollbar >
    );
}
