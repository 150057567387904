import axios from "axios";

import Config from "../../config";

const getActionFinalizarChat = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/chat/finalizar`,
        data
    });
};

export default getActionFinalizarChat;
