import axios from "axios";
import Config from "src/config";

const v2_actionEditarCanal = (canalId, data) => {
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/canal/${canalId}/editar`,
        data
    });
};

export default v2_actionEditarCanal;
