
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';

// import { UploadAvatar } from 'src/components/upload';
// import UploadFotoCategoria from 'src/components/upload/UploadFotoCategoria';
// import UploadFileSyncClientes from 'src/components/upload/UploadFileSyncClientes';

import { Button } from "src/@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "src/@/components/ui/dialog"
import { Input } from "src/@/components/ui/input"
import UploadContatos from 'src/components/upload/UploadContatos';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { useCallback, useEffect, useState } from 'react';
import { Divider, Paper, Stack, StepContent, Typography } from '@mui/material';
import Iconify from 'src/components/Iconify';

import { Label } from "src/@/components/ui/label"
import { Switch } from "src/@/components/ui/switch"
import v2_actionImportarContatos from 'src/actions/v2/contatos/v2_actionImportarContatos';

import {
    Alert,
    AlertDescription,
    AlertTitle,
} from "src/@/components/ui/alert"
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';


const ItemStep1 = () => {
    return (
        <div>
            <Label>Passo 1</Label>
            <p>Passo 1</p>
        </div>
    )
}

export default function ModalUploadContatos({
    cliente,
    open,
    onClose
}) {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();

    const [activeStep, setActiveStep] = useState(0);

    const [checked1, setChecked1] = useState(false);
    const [checked2, setChecked2] = useState(false);
    const [checked3, setChecked3] = useState(false);

    const [filesUploadContacts, setFilesUploadContacts] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    const [erros, setErros] = useState([]);

    const handleDrop = useCallback(
        (acceptedFiles) => {
            setFilesUploadContacts(acceptedFiles)
        },
        []
    );


    const handleUploadContacts = async () => {
        const formData = new FormData();
        let option = {
            replace_contacts: checked1,
            link_chats: checked2,
            ignore_errors: checked3
        }
        const optionString = JSON.stringify(option);
        formData.append("options", optionString);
        formData.append("file", filesUploadContacts[0]);
        setIsLoading(true)
        try {
            const response = await v2_actionImportarContatos(cliente._id, formData)
            if (response.status === 200) {
          
                if (response.data.error) {
                    setErros(response.data.message)
                    enqueueSnackbar('Erro ao importar contatos', { variant: 'error' });
                    setActiveStep(1);
                    setIsLoading(false);
                    return;
                }

                setIsLoading(false)
                setFilesUploadContacts([]);
                onClose();
                enqueueSnackbar('Contatos importados com sucesso', { variant: 'success' });

                
            }

        } catch (error) {
            console.log(error);
            setIsLoading(false)
        }
    }

    const steps = [
        {
            label: 'Baixe o modelo de planilha',
            description: <Stack spacing={2}>
                <p className="leading-6 text-sm [&:not(:first-child)]:mt-6">
                    Faça o download do modelo de planilha para importar seus contatos. É importante que siga o modelo para que os contatos sejam importados corretamente.
                </p>
                <div>
                    <Button onClick={() => window.open('https://lais-app.s3.us-east-2.amazonaws.com/static/modelos/modelo_importar_contatos_lais.csv', '_blank')} variant="outline"> <Iconify className="mr-2" icon="ic:sharp-download" />   Baixar modelo</Button>

                </div>
            </Stack>,
        },
        {
            label: 'Importe o arquivo',
            description: <Stack direction={"column"} spacing={2}>
                <UploadContatos
                    accept=".csv"
                    files={filesUploadContacts}
                    onDrop={handleDrop}
                    showPreview={true}
                    onRemove={() => setFilesUploadContacts([])}
                />

                <Stack sx={{ mt: 3 }}>
                    {erros.map(i => {
                        return (
                            <Alert variant="destructive">
                                <Iconify icon="bx:error" className="h-4 w-4" />
                                <AlertDescription className="mt-1">
                                    {i}
                                </AlertDescription>
                            </Alert>
                        )

                    })}
                </Stack>
            </Stack>,
        },
        {
            label: 'Configure os campos',
            description: <Box className="mt-5 mb-5">
                <Stack direction="column" spacing={2}>
                    <div className="flex items-center space-x-2">
                        <Switch checked={checked1} onCheckedChange={setChecked1} name="checked1" id="airplane-mode" />
                        <Label htmlFor="airplane-mode">Sobrepor contatos já existentes</Label>
                    </div>

                    <div className="flex items-center space-x-2">
                        <Switch checked={checked2} onCheckedChange={setChecked2} name="checked2" id="airplane-mode" />
                        <Label htmlFor="airplane-mode">Vincular chats ja existentes</Label>
                    </div>
                    <div className="flex items-center space-x-2">
                        <Switch checked={checked3} onCheckedChange={setChecked3} name="checked3" id="airplane-mode" />
                        <Label htmlFor="airplane-mode">Ignorar erros</Label>
                    </div>

                    <Stack sx={{ mt: 3 }}>
                        {erros.map(i => {
                            return (
                                <Alert variant="destructive">
                                    <Iconify icon="bx:error" className="h-4 w-4" />
                                    <AlertDescription className="mt-1">
                                        {i}
                                    </AlertDescription>
                                </Alert>
                            )

                        })}
                    </Stack>
                </Stack>
            </Box >,
        },
        {
            label: 'Concluído',
            description:  <Alert variant="destructive">
            <Iconify icon="bx:error" className="h-4 w-4" />
            <AlertDescription className="mt-1">
                Importação concluida com sucesso!
            </AlertDescription>
        </Alert>,
        },
    ];


    const handleNext = () => {
        console.log('filesUploadContacts', filesUploadContacts)
        console.log('activeStep', activeStep)
        if (activeStep === 1 && filesUploadContacts.length === 0) {
            enqueueSnackbar('Insira um arquivo antes de continuar', { variant: 'error' });
            return
        }
        if (activeStep === 2) {
            handleUploadContacts()
            return
        }
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        setErros([]);
    };

    const handleReset = () => {
        setActiveStep(0);
    };

    useEffect(() => {

        return () => {
            setErros([]);
        }
    }, [])



    return (

        <Dialog onOpenChange={onClose} open={open}>

            <DialogContent className="sm:max-w-[425px] md:max-w-screen-md lg:max-w-screen-lg">
                <DialogHeader>
                    <DialogTitle>Importar contatos via planilha</DialogTitle>
                    <DialogDescription>
                        Importe seus contatos usando uma planilha em formato .csv
                    </DialogDescription>
                </DialogHeader>

                <Divider className="mt-2 mb-2 border-dashed" />


                <Box>
                    <Stepper activeStep={activeStep} orientation="vertical">
                        {steps.map((step, index) => (
                            <Step key={step.label}>
                                <StepLabel
                                // optional={
                                //     index === 2 ? (
                                //         <Typography variant="caption">última etapa</Typography>
                                //     ) : null
                                // }
                                >
                                    {step.label}
                                </StepLabel>
                                <StepContent>
                                    {step.description}
                                    <Box sx={{ mb: 2 }}>
                                        <div className='mt-3'>
                                            {index !== 0 && (
                                                <Button
                                                    disabled={index === 0}
                                                    onClick={handleBack}
                                                    sx={{ mt: 1, mr: 1 }}
                                                    variant="ghost"
                                                >
                                                    Voltar
                                                </Button>
                                            )}

                                            <ButtonLoading
                                                loading={isLoading}
                                                onClick={handleNext}
                                                className="mt-5"
                                            >
                                                {index === steps.length - 1 ? 'Importar contatos' : 'Continuar'}
                                            </ButtonLoading>

                                        </div>
                                    </Box>
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>

                </Box>



            </DialogContent>
        </Dialog>

    );
}


