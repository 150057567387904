import { Grid, IconButton, Avatar, Stack, Box } from '@mui/material';
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { AudioPlayer } from "./style";
import { styled } from '@mui/material/styles';
import Iconify from 'src/components/Iconify';


const Layout = styled('dic')(({ theme }) => ({
    height: 50,
    width: '100%',
    display: 'flex',
    textAlign: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(2, 2)
}));


const AudioComponentMeta = ({ url, isMe, profileImage, downloadAudio, loading }) => {
    const audioPlayer = useRef(null);
    const sliderRef = useRef(null);
    const [audio, setAudio] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [downloaded, setDownloaded] = useState(false);

    useEffect(() => {
        setAudio(new Audio(audioPlayer.current.dataset.url));
    }, [url]);

    const onClickPlay = () => {
        audio.paused ? audio.play() : audio.pause();
    };


    async function checkIsDownloaded() {
        if (audioPlayer.current.dataset.url === undefined) {
            downloadAudio("audio").then(() => {
                setDownloaded(true)
            });
        } else {
            start();
            audio.play();

        }
    }

    async function start() {
        onClickPlay();
        audio.onloadstart = () => {
            setMessageDate();
            audioPlayer.current.classList.add("loading");
        };

        audio.onplay = () => {
            audioPlayer.current.classList.add("playing");
            setPlaying(true);
        }
        audio.onpause = () => {
            audioPlayer.current.classList.remove("playing");
            setPlaying(false)
        }
        audio.onloadeddata = () => audioPlayer.current.classList.remove("loading");
        audio.ondurationchange = showTimeDuration;
        audio.onended = () => {
            (audio.currentTime = 0);
            setPlaying(false);
        }
        audio.ontimeupdate = () => {
            const { currentTime } = audio;
            const currentTimeDisplay = formatTimeToDisplay(currentTime);
            updateCurrentTimeDisplay(currentTimeDisplay);
            updateCurrentPercent();
            if (currentTime === 0) {
                showTimeDuration();
            }
        };
    }

    function formatTimeToDisplay(seconds) {
        const milliseconds = seconds * 1000;
        return new Date(milliseconds).toISOString().substr(14, 5);
    }

    function setMessageDate() {
        let currentDateTime = new Date().toISOString().substr(11, 5);
        audioPlayer.current.style.setProperty(
            "--player-current-date-time",
            `'${currentDateTime}'`
        );
    }

    function handleSlider(e) {
        const { duration } = audio;
        const percent = e.target.value;
        const currentTimeInSeconds = ((percent * duration) / 100).toFixed(2);
        audio.currentTime = currentTimeInSeconds;
    }

    function showTimeDuration() {
        const { duration } = audio;
        const durationDisplay = formatTimeToDisplay(duration);
        updateCurrentTimeDisplay(durationDisplay);
    }

    function updateCurrentTimeDisplay(time) {
        audioPlayer.current.style.setProperty("--player-current-time", `'${time}'`);
    }

    function updateCurrentPercent() {
        const { currentTime, duration } = audio;
        const percentPlayed = (currentTime * 100) / duration;
        sliderRef.current.value = percentPlayed;
        audioPlayer.current.style.setProperty(
            "--player-percent-played",
            `${percentPlayed}%`
        );
    }


    return (
        <Layout>
            <AudioPlayer className="audio-player" data-url={url} ref={audioPlayer} isMe={isMe}>
                <Stack spacing={1} direction={"row"}>
                    <Box>
                        <Avatar src={profileImage} alt={"Foto perfil"} sx={{ borderRadius: 1 }} />
                    </Box>
                    <Box>
                        <Stack spacing={1} alignItems={"center"} direction={"row"}>
                            <Box>
                            {playing ? <IconButton >
                                            <Iconify onClick={onClickPlay} width={18} icon="icon-park-outline:pause-one" />
                                        </IconButton> : <IconButton onClick={checkIsDownloaded}>
                                            <Iconify width={18} icon="icon-park-solid:play" />
                                        </IconButton>
                                        }
                            </Box>
                            <Box sx={{pt:loading ? 1.5 : 0}}>
                                <Stack direction={"column"}>
                                    <input dir="ltr" type="range" min="0" max="100" value="0" ref={sliderRef} onInput={handleSlider} />
                                    <Stack direction={"row"}>
                                        <div className="current-time" />
                                        <div className="time" />
                                    </Stack>

                                </Stack>
                            </Box>
                        </Stack>
                    </Box>
                </Stack>
            </AudioPlayer>
        </Layout>
    );
};

export default AudioComponentMeta;

AudioComponentMeta.propTypes = {
    url: PropTypes.string,
    isMe: PropTypes.bool.isRequired,
    profileImage: PropTypes.string.isRequired,
    downloadAudio: PropTypes.func.isRequired,
};// JavaScript Document
