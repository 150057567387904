import axios from "axios";
import Config from "src/config";

const v2_actionDeletarVozAi = (id) => {
    return axios({
        method: "delete",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/ai/vozes/${id}/deletar`
            });
};

export default v2_actionDeletarVozAi;
