import axios from "axios";

import Config from "src/config";

const v2_actionListaChatsFunil = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/funil/lista-chats${querys}`
    });
};

export default v2_actionListaChatsFunil;
