import axios from "axios";
import Config from "../../../config";

const actionMetricasAtendimentosUsuarios = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/dashboard/relatorios/metricas-atendimentos-usuarios${querys}`
    });
};

export default actionMetricasAtendimentosUsuarios;
