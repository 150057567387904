import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import { useTheme } from '@mui/material/styles';
import {
    Container,
    IconButton
} from '@mui/material';

// redux
import { useDispatch, useSelector } from 'src/redux/store';

// components
import useAuth from 'src/hooks/useAuth';
import Documents from '../components/training/views';

import { Button } from "src/@/components/ui/button"
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from "src/@/components/ui/card"
import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "src/@/components/ui/tabs"
import FormAwsers from './components/FormAnswer';
import v2_actionAgenteTreinamentos from 'src/actions/v2/ai/v2_actionAgenteTreinamentos';
import FormAnswer from './components/FormAnswer';
import DocumentStoreDetails from '../components/training/views/DocumentStoreDetail';
import FormDocuments from './components/FormDocuments';
import { ScrollArea } from 'src/@/components/ui/scroll-area';
import FormWebsites from './components/FormWebsites';




export default function AiAgentTraining() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [trainings, setTrainings] = useState([]);

    const queryParams = new URLSearchParams(location.search);
    const id = queryParams.get('id');
    const [docLoaderNodeName, setDocLoaderNodeName] = useState('pdfFile');


    async function getTrainings() {
        const response = await v2_actionAgenteTreinamentos(id);
        if (response.status === 200) {
            setTrainings(response.data.data);
        }
    }

    useEffect(() => {
        getTrainings();
    }, [])


    return (
            <Container maxWidth="lg">

                <Card>
                    <CardContent >
                        <Tabs className='mt-3' defaultValue="awsers">
                            <TabsList className="grid grid-cols-3 bg-slate-200">
                                <TabsTrigger value="awsers">Perguntas e Respostas</TabsTrigger>
                                <TabsTrigger value="websites">Webistes</TabsTrigger>
                                <TabsTrigger value="documentos">Documentos</TabsTrigger>
                            </TabsList>
                            <div className="flex w-full">
                                <TabsContent className="w-full" value="awsers">
                                    <ScrollArea className="h-[50vh]" >
                                        <FormAnswer
                                            agentId={id}
                                            trainings={trainings}
                                            onCallback={getTrainings}
                                        />
                                    </ScrollArea>
                                </TabsContent>
                                <TabsContent className="w-full" value="websites">
                                <ScrollArea className="h-[50vh]" >
                                    <FormWebsites
                                        docLoaderNodeName={"cheerioWebScraper"}
                                        agentId={id}
                                        trainings={trainings}
                                        onCallback={getTrainings}
                                    />
                                </ScrollArea>
                                </TabsContent>
                                <TabsContent className="w-full" value="documentos">
                                <ScrollArea className="h-[50vh]" >
                                    <FormDocuments
                                        docLoaderNodeName={docLoaderNodeName}
                                        agentId={id}
                                        trainings={trainings}
                                        onCallback={getTrainings}
                                        changeDocLoaderNodeName={(value) => {
                                            setDocLoaderNodeName(value);
                                        }}
                                    />
                                    </ScrollArea>
                                </TabsContent>

                            </div>
                        </Tabs>
                    </CardContent>
                </Card>

            </Container>
    );
}
