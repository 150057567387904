// @mui
import { Card, Skeleton, Stack, Box } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonChatWindow() {

    return (
    <Box sx={{p:3}}>
       <div className="chat-messages">
        {Array.from({ length: 8 }).map((_, index) => (
          <div key={index} className={`chat-message ${index % 2 === 0 ? 'user' : 'bot'}`}>
 
            <div className="chat-text">
              {index % 2 === 0 ? (
                <>
                  <Skeleton variant="text" className='bg-slate-300' width={'80%'} height={50} />
                  <Skeleton variant="text" className='bg-slate-300' width={'60%'} height={50} />
                </>
              ) : (
                <>
                  <Skeleton variant="text" className='bg-slate-300' width={'30%'} height={100} />
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </Box>
  );
}
