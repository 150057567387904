
// material
import {
    Table,
    TableRow,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Dialog,
    DialogTitle,
    DialogActions,
    Switch,
    Tabs,
    Tab,
    Paper,
    Stack,
    Typography,
    Select,
    MenuItem,
    Container
} from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { useState, useEffect, useCallback, useMemo } from 'react';
import actionCriarAgendamento from 'src/actions/agendamentos/actionCriarAgendamento';
import actionDescontinuarAgendamento from 'src/actions/agendamentos/actionDescontinuarAgendamento';
import actionEditarAgendamento from 'src/actions/agendamentos/actionEditarAgendamento';
import actionListaAgendamentos from 'src/actions/agendamentos/actionListaCampanhas';
import AgendamentosMoreMenu from 'src/components/_chats/agendamentos/AgendamentosMoreMenu';
import DrawerAgendamentos from 'src/components/_chats/agendamentos/DrawerAgendamentos';
import useTabs from 'src/hooks/useTabs';
import { getListaOptionsFunil } from 'src/redux/slices/global';
import { fDateTimeSuffix } from 'src/utils/formatTime';
import MicRecorder from "mic-recorder-to-mp3";
import { UserListHead } from '../../components/_dashboard/user/list';
import Label from '../../components/Label';
import Scrollbar from '../../components/Scrollbar';
import { useDispatch, useSelector } from '../../redux/store';
import v2_actionUploadS3 from 'src/actions/v2/global/actionUploadS3';
import useAuth from 'src/hooks/useAuth';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import HeaderPagePadrao from 'src/components/header/HeaderPagePadrao';

import { Card, CardContent } from 'src/@/components/ui/card';
import { Button } from 'src/@/components/ui/button';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';

const STATUS_OPTIONS = [
    { value: null, label: "Todos" },
    { value: 'agendado', label: "Agendados" },
    { value: 'finalizado', label: "Finalizado" },
    { value: 'erro', label: "Falhados" }
];



const TABLE_HEAD = [
    { id: 'numero', label: 'Número', alignRight: false },
    { id: 'status_envio', label: 'Status Envio', alignRight: false },
    { id: 'criado_por', label: 'Criado Por', alignRight: false },
    { id: 'data_envio', label: 'Data Envio', alignRight: false },
    { id: 'status', label: 'Status', alignRight: false },
    { id: '', label: '', alignRight: false },
];



export default function MensagensAgendadas() {
    const theme = useTheme();
    const dispatch = useDispatch();
    const [tableData, setTableData] = useState([]);
    const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(null);
    const { usuariosList } = useSelector((state) => state.user);
    const { listaAgendamentos } = useSelector((state) => state.marketing);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');

    const [rowsPerPage, setRowsPerPage] = useState(25);
    const { cliente, user } = useAuth();


    const [body, setBody] = useState('');
    const [dataAgendamento, setDataAgendamento] = useState(new Date())
    const [hora, setHora] = useState('')
    const [minuto, setMinuto] = useState('');
    const [numero, setNumero] = useState('');
    const [ddd, setDDD] = useState('');

    // agendamentos
    const [drawerAgendamento, setDrawerAgendamento] = useState(false);
    const [agendamentoId, setAgendamentoId] = useState('');
    const [modalConfirmarExclusao, setModalConfirmarExclusao] = useState(false);
    const [isAlert, setIsAlert] = useState(false);
    const [isEdit, setIsEdit] = useState(false);
    const [isEditFile, setIsEditFile] = useState(false);
    const [filesEdit, setFilesEdit] = useState([]);


    //upload
    const [files, setFiles] = useState([]);
    const filesUrl = []
    // eslint-disable-next-line no-unused-vars
    const [preview, setPreview] = useState(true);
    const [isFile, setIsFile] = useState(false);
    const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
    const [isChatAtendimento, setIsChatAtendimento] = useState(false);
    const [tipoArquivo, setTipoArquivo] = useState('');

    const [recording, setRecording] = useState(false);
    const [audioGravado, setAudioGravado] = useState(null);
    const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);
    const [loadingAudio, setLoadingAudio] = useState(false);

    const [clienteIdPadrao, setClienteIdPadrao] = useState(user.cliente_id_padrao);
    const [listaClientesCanais, setListaClientesCanais] = useState([]);
    const isMultisession = cliente.multiSession === 1;

    async function buscarListaClientesCanais() {
        try {
            const response_clientes_canais = await actionListaClientesCanais(cliente._id)
            setListaClientesCanais(response_clientes_canais.data.data);
        } catch (err) {
            console.log(err)
        }
    }

    const handleChangeClienteIdPadrao = (e) => {
        setClienteIdPadrao(e.target.value)
        callListaAgendamentos(e.target.value)
    }



    // calls
    const callListaAgendamentos = async (clienteId) => {
        try {
            const res = await actionListaAgendamentos(isMultisession ? clienteId : cliente._id, user.id);
            setTableData(res.data.data)
        } catch (error) {
            console.log('Err', error)
        }
    }

    useEffect(() => {
        dispatch(getListaOptionsFunil(user.clienteId));
        callListaAgendamentos(isMultisession ? clienteIdPadrao : cliente._id);
        if (isMultisession) {
            buscarListaClientesCanais();
        }

    }, []);

    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usuariosList.map((n) => n.nome);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };



    const handleOpenDialogExcluirAgendamento = (id) => {
        setAgendamentoId(id)
        setModalConfirmarExclusao(true);
    }

    const handleOpenDialogEditarAgendamentos = (row) => {
        setAgendamentoId(row._id)
        setDrawerAgendamento(true);
        let horaFormat = new Date(row.data_envio).getHours()
        let minutoFormat = new Date(row.data_envio).getMinutes()
        let dddExtract = row.numero.slice(0, 2);
        let numeroExtract = row.numero.slice(2, 14);

        setIsFile(row.file);
        setHora(horaFormat.toString());
        setMinuto(minutoFormat.toString())
        setBody(row.texto)
        setDDD(dddExtract);
        setNumero(numeroExtract);
        setIsEditFile(true);
        setFilesEdit(row.files)
        setIsEdit(true);
    }

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onCloseDrawerAgendamento = () => {
        setDrawerAgendamento(false);
        limparCampos()
    }


    const handleDropMultiFile = useCallback(
        (acceptedFiles) => {
            setFiles(acceptedFiles.map((file) => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
        },
        [setFiles]
    );

    const handleRemoveAll = () => {
        setFiles([]);
    };

    const handleRemove = (file) => {
        const filteredItems = files.filter((_file) => _file !== file);
        setFiles(filteredItems);
    };

    const handleChangeIsFile = (event) => {
        setIsFile(event.target.checked);
    };

    const handleRemoveEdit = (file) => {
        const filteredItems = filesEdit.filter((_file) => _file !== file);
        setIsEditFile(false);
        setFilesEdit(filteredItems);
    };



    const limparCampos = () => {
        setDataAgendamento(new Date());
        setHora('');
        setMinuto('');
        setFiles([]);
        setDDD('');
        setNumero('');
        setIsFile(false);
        setBody('')
        setIsEditFile(false);
        setFilesEdit([])
        setIsEdit(false);
        setIsAlert(false)

    }

    const onDeletarAgendamento = async (id) => {
        try {
            await actionDescontinuarAgendamento(id);
            callListaAgendamentos(isMultisession ? clienteIdPadrao : cliente._id);
            setModalConfirmarExclusao(false);
            setAgendamentoId('');
        } catch (error) {
            console.log('erro ao deletar campanha');
        }
    }

    async function uploadAndGetUrl() {
        if (files.length > 0) {
            for (let file of files.map(item => item)) {
                var formData = new FormData();
                var imagefile = file;
                formData.append("file", imagefile);
                try {
                    let querys = `?path=plataforma/agendamentos`
                    const response = await v2_actionUploadS3(formData, querys)
                    if (!response.data.error) {
                        filesUrl.push({ key: response.data.data.key, type: file.type });
                    }
                } catch (error) {
                    console.log('erro ao upload arquivo', error)
                }

            }
        }
    }


    const onCriarAgendamento = async () => {
        if (hora === "" || minuto === "" || ddd === "" || numero === "" || (isFile && files.length === 0 && !audioGravado) || (!isFile && body === "") || (!isFile && audioGravado)) {
            setIsAlert(true)
        } else {
            setLoadingEnviarArquivos(true);

            try {
                const data = {
                    dataAgendamento,
                    hora,
                    minuto,
                    numero: ddd + numero,
                    status_envio: "agendado",
                    criado_por: user.id,
                    cliente_id: isMultisession ? clienteIdPadrao : cliente._id,
                    texto: body,
                    isFile: isFile,
                    files: filesUrl,
                    isChatAtendimento
                }
                if (isFile) {
                    if (tipoArquivo === "audio") {
                        await handleUploadAudio();
                    } else {
                        await uploadAndGetUrl();
                    }

                }
                await actionCriarAgendamento(data);
                setLoadingEnviarArquivos(false);
                setDrawerAgendamento(false)
                callListaAgendamentos(isMultisession ? clienteIdPadrao : cliente._id);
                limparCampos();
                setIsAlert(false)
            } catch (error) {
                setLoadingEnviarArquivos(false);
                console.log('error', error);

                // limparCampos();
            }
        }
    }

    const onEditarOnlyStatus = async (event, id) => {
        console.log(event, id)
        try {
            let data = { action: 'edit_only_status', status: event.target.checked }
            await actionEditarAgendamento(id, data);
            callListaAgendamentos(isMultisession ? clienteIdPadrao : cliente._id);

        } catch (error) {
            alert('Erro ao alterar o status, tente novamente')
        }
    };

    const onEditarAgendamento = async () => {
        if (hora === "" || minuto === "" || ddd === "" || numero === "" || (isFile && files.length === 0 && !audioGravado) || (!isFile && body === "")) {
            setIsAlert(true)
        } else {
            setLoadingEnviarArquivos(true);

            if (isFile) {
                try {
                    let data = {
                        action: 'edit_info_with_file',
                        dataAgendamento,
                        numero: ddd + numero,
                        hora,
                        minuto,
                        texto: body,
                        isFile: isFile,
                        files: filesUrl,
                        isChatAtendimento
                    }
                    await uploadAndGetUrl();
                    await actionEditarAgendamento(agendamentoId, data);
                    setDrawerAgendamento(false)
                    callListaAgendamentos(isMultisession ? clienteIdPadrao : cliente._id);
                    limparCampos();
                    setLoadingEnviarArquivos(false);
                } catch (error) {
                    setLoadingEnviarArquivos(false);
                    console.log('error', error);
                    // limparCampos();
                }

            } else {

                try {
                    let data = {
                        action: 'edit_info_without_file',
                        dataAgendamento,
                        numero: ddd + numero,
                        hora,
                        minuto,
                        texto: body,
                        isChatAtendimento
                    }
                    await uploadAndGetUrl();
                    await actionEditarAgendamento(agendamentoId, data);
                    setDrawerAgendamento(false)
                    callListaAgendamentos(isMultisession ? clienteIdPadrao : cliente._id);
                    limparCampos();
                    setLoadingEnviarArquivos(false);
                } catch (error) {
                    setLoadingEnviarArquivos(false);
                    console.log('error', error);
                    // limparCampos();
                }

            }

        }
    }

    const handleChangeIsChatAtendimento = (event) => {
        setIsChatAtendimento(event.target.checked);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - listaAgendamentos.length) : 0;
    const dataFiltered = applySortFilter({
        tableData,
        filterStatus
    });



    const handleCancelAudio = async () => {
        try {
            await recorder.stop().getMp3();
            setRecording(false);
            setAudioGravado(null);
        } catch (err) {
            console.log(err);
        }
    };

    async function pararGravacao() {
        try {
            const [buffer, blob] = await recorder.stop().getMp3()
            const file = new File(buffer, "audio.mp3", {
                type: blob.type,
                lastModified: Date.now()
            });
            setAudioGravado(file)
            setRecording(false)
        } catch (error) {
            filesUrl = [];
        }

    }

    const handleStartRecording = async () => {
        setLoadingAudio(true);
        try {
            await navigator.mediaDevices.getUserMedia({ audio: true });
            await recorder.start();
            setRecording(true);
            setLoadingAudio(false);
        } catch (err) {
            console.log(err);
            setLoadingAudio(false);
        }
    };

    async function uploadAudioAndGetUrl(file) {
        if (file) {
            var formData = new FormData();
            var audiofile = file;
            formData.append("file", audiofile);
            try {
                let querys = `?path=plataforma/agendamentos`
                const response = await v2_actionUploadS3(formData, querys)
                if (!response.data.error) {
                    return { key: response.data.data.key, type: file.type, tipo: "audio" };
                }
            } catch (error) {
                console.log('erro ao upload arquivo', error)
            }


        }
    }

    const handleUploadAudio = async () => {
        const response_upload = await uploadAudioAndGetUrl(audioGravado);
        filesUrl.push(response_upload)

    };


    return (
        <Container>

            <div className='my-5'>
                <HeaderPageComponentConfig
                    titulo="Agendamentos"
                    descricao={'Agende mensagens pra serem enviadas em um determinado dia e horário.'}
                    actions={<Button
                        onClick={() => setDrawerAgendamento(true)}
                    >
                        Criar Agendamento
                    </Button>}
                />

            </div>

            <DrawerAgendamentos
                user={user}
                dataAgendamento={dataAgendamento}
                hora={hora}
                minuto={minuto}
                body={body}
                changeBody={e => setBody(e.target.value)}
                isFile={isFile}
                numero={numero}
                ddd={ddd}
                changeDDD={e => setDDD(e.target.value)}
                loadingEnviarArquivos={loadingEnviarArquivos}
                preview={preview}
                tipoArquivo={tipoArquivo}
                changeTipoArquivo={e => {
                    setTipoArquivo(e.target.value)
                }}
                files={files}
                isAlert={isAlert}
                changeNumero={e => setNumero(e.target.value)}
                changeFile={handleChangeIsFile}
                changeData={newValue => {
                    setDataAgendamento(newValue)
                }}
                changeHora={e => setHora(e.target.value)}
                changeMinuto={e => setMinuto(e.target.value)}
                handleDropMultiFile={handleDropMultiFile}
                handleRemove={handleRemove}
                onCriarAgendamento={onCriarAgendamento}
                onEditarAgendamento={onEditarAgendamento}
                handleRemoveAll={handleRemoveAll}
                drawerAgendamento={drawerAgendamento}
                onCloseDrawerAgendamento={onCloseDrawerAgendamento}
                isEdit={isEdit}
                isEditFile={isEditFile}
                filesEdit={filesEdit}
                handleRemoveEdit={handleRemoveEdit}
                isChatAtendimento={isChatAtendimento}
                onChangeIsChatAtendimento={handleChangeIsChatAtendimento}
                audioGravado={audioGravado}
                recording={recording}
                loading={loadingAudio}
                handleCancelAudio={handleCancelAudio}
                pararGravacao={pararGravacao}
                handleStartRecording={handleStartRecording}
                changeAudioGravado={() => setAudioGravado(null)}
                isMultisession={isMultisession}
                listaClientesCanais={listaClientesCanais}
                clienteIdPadrao={clienteIdPadrao}
                handleChangeClienteIdPadrao={handleChangeClienteIdPadrao}
            />


            <Stack spacing={2} direction="row" alignItems="center" mb={1}>

                {isMultisession && (
                    <Paper
                        component="form"
                        sx={{ p: '10px 20px', display: 'flex', alignItems: 'center' }}
                    >
                        <Stack alignItems={'center'} direction={'row'} spacing={1}>
                            <Typography variant="caption">Canal:</Typography>
                            <Select
                                size="small"
                                placeholder='Escolha um funil'
                                defaultValue={clienteIdPadrao}
                                sx={{ minWidth: 150, height: 35 }}
                                value={clienteIdPadrao}
                                onChange={handleChangeClienteIdPadrao}
                            >
                                {listaClientesCanais.map((option) => (
                                    <MenuItem key={option.cliente_id} value={option.cliente_id}>
                                        {option.nome}
                                    </MenuItem>

                                ))}
                            </Select>
                        </Stack>
                    </Paper>
                )}
            </Stack>

            <Card>


                <Dialog
                    open={modalConfirmarExclusao}
                    onClose={() => { setModalConfirmarExclusao(false); setAgendamentoId('') }}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">
                        {"Deseja mesmo excluir?"}
                    </DialogTitle>

                    <DialogActions>
                        <Button onClick={() => { setModalConfirmarExclusao(false); setAgendamentoId('') }}>Cancelear</Button>
                        <Button variant='contained' onClick={() => onDeletarAgendamento(agendamentoId)} autoFocus>Confirmar</Button>
                    </DialogActions>
                </Dialog>


                <Tabs
                    allowScrollButtonsMobile
                    variant="scrollable"
                    scrollButtons="auto"
                    value={filterStatus}
                    onChange={onChangeFilterStatus}
                    sx={{ px: 2, mb: 2, bgcolor: 'background.neutral' }}
                >
                    {STATUS_OPTIONS.map((tab) => (
                        <Tab disableRipple key={tab.value} label={tab.label} value={tab.value} />
                    ))}
                </Tabs>
                <CardContent>

                    <Scrollbar sx={{ height: 'calc(70vh - 200px)' }}>
                        <TableContainer sx={{ minWidth: 400 }}>
                            <Table>
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={listaAgendamentos.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}
                                />
                                <TableBody>
                                    {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { _id, data_envio, nome_usuario, numero, status, status_envio, } = row;
                                        const isItemSelected = selected.indexOf(_id) !== -1;

                                        return (
                                            <TableRow
                                                hover
                                                key={_id}
                                                tabIndex={-1}
                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >
                                                <TableCell align="left">
                                                    {numero}
                                                </TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                        color={(status_envio === 'em andamento' && 'warning') || (status_envio === 'agendado' && 'info') || (status_envio === 'finalizado' && 'success') || (status_envio === 'erro' && 'error') || 'default'}
                                                    >
                                                        {status_envio}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="left">
                                                    {nome_usuario}
                                                </TableCell>

                                                <TableCell align="left">{fDateTimeSuffix(new Date(data_envio))}</TableCell>

                                                <TableCell align="left">
                                                    <Switch
                                                        checked={status}
                                                        onChange={(event) => onEditarOnlyStatus(event, _id)}
                                                        inputProps={{ 'aria-label': 'controlled' }}
                                                    />

                                                </TableCell>




                                                <TableCell align="left">
                                                    <AgendamentosMoreMenu
                                                        onEdit={() => handleOpenDialogEditarAgendamentos(row)}
                                                        onDelete={() => handleOpenDialogExcluirAgendamento(_id)}
                                                    />
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={usuariosList.length}
                        labelRowsPerPage={'Linhas por página'}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                </CardContent>
            </Card>
        </Container>
    );
}


function applySortFilter({ tableData, filterStatus }) {

    if (filterStatus !== null) {
        tableData = tableData.filter((item) => item.status_envio === filterStatus);
    }

    return tableData;
}
