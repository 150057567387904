import { cloneDeep } from 'lodash'
import { useEffect, useState } from 'react'
import { validate as uuidValidate, v4 as uuidv4 } from 'uuid'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ReactJson from 'flowise-react-json-view'

// Hooks
import useApi from 'src/hooks/useApi'

// Material-UI
import { Skeleton, Toolbar, Box, Card, CardContent, Grid, OutlinedInput, Stack, Typography } from '@mui/material'
import { useTheme, styled } from '@mui/material/styles'
import { IconScissors, IconArrowLeft, IconDatabaseImport, IconBook, IconX, IconEye } from '@tabler/icons-react'

// Project import
import { BackdropLoader } from 'src/components/ui-component/loading/BackdropLoader'
import DocStoreInputHandler from './DocStoreInputHandler'
import { Dropdown } from 'src/components/ui-component/dropdown/Dropdown'
import ErrorBoundary from 'src/ErrorBoundary'
import ExpandedChunkDialog from './ExpandedChunkDialog'
import { Button } from 'src/@/components/ui/button'
// API
import nodesApi from 'src/api/nodes'
import documentStoreApi from 'src/api/documentstore'
import documentsApi from 'src/api/documentstore'

// Const
import { baseURL, gridSpacing } from 'src/store/constant'
import { closeSnackbar as closeSnackbarAction, enqueueSnackbar as enqueueSnackbarAction } from 'src/store/actions'

// Utils
import { initNode } from 'src/utils/genericHelper'
import Iconify from 'src/components/Iconify'
// import useNotifier from 'src/utils/useNotifier'

import { Label } from 'src/@/components/ui/label'
import { RadioGroup, RadioGroupItem } from 'src/@/components/ui/radio-group'
import { useSnackbar } from 'notistack'
import v2_actionCriarTreinamento from 'src/actions/v2/ai/v2_actionCriarTreinamento'



const LoaderConfigPreviewChunks = ({
    docLoaderNodeName,
    agentId,
    type,
    changeDocLoaderNodeName,
    onCallback
}) => {

    const {enqueueSnackbar} = useSnackbar()
    const getNodeDetailsApi = useApi(nodesApi.getSpecificNode)
    const getNodesByCategoryApi = useApi(nodesApi.getNodesByCategory)
    const getSpecificDocumentStoreApi = useApi(documentsApi.getSpecificDocumentStore)

    const storeId = '6388852d-50d5-4c0f-930a-96d9d69f958d'

    const [selectedDocumentLoader, setSelectedDocumentLoader] = useState({})

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState(null)

    const [textSplitterNodes, setTextSplitterNodes] = useState([])
    const [splitterOptions, setTextSplitterOptions] = useState([])
    const [selectedTextSplitter, setSelectedTextSplitter] = useState({})
    const [previewChunkCount, setPreviewChunkCount] = useState(20)
    const [existingLoaderFromDocStoreTable, setExistingLoaderFromDocStoreTable] = useState()

    const [showExpandedChunkDialog, setShowExpandedChunkDialog] = useState(false)
    const [expandedChunkDialogProps, setExpandedChunkDialogProps] = useState({})

    console.log('docLoaderNodeName 1', docLoaderNodeName);

   
    const optionsDocuments = [
        {
            value: 'pdfFile',
            label: 'PDF',
            fileType:'.pdf'
        },

        {
            value: 'docxFile',
            label: 'DOCX',
            fileType:'.docx'
        },
        {
            value: 'textFile',
            label: 'TXT',
            fileType:'.txt'
        },
        // {
        //     value: 'csvFile',
        //     label: 'CSV',
        //     fileType:'.csv'
        // }
    ]

    const onSplitterChange = (name) => {
        const textSplitter = (textSplitterNodes ?? []).find((splitter) => splitter.name === name)
        if (textSplitter) {
            setSelectedTextSplitter(textSplitter)
        } else {
            setSelectedTextSplitter({})
        }
    }

    function getFileTypeByValue(value) {
        const file = optionsDocuments.find(file => file.value === value);
        return file ? file.fileType : null;
    }


    async function addAnswer(config) {

        try {
            let data = {
                scope: {
                    type: type,
                    content: {
                        "type": docLoaderNodeName,
                        "chunck": config
                    }
                }
            }

            console.log('data', data);
            await v2_actionCriarTreinamento(agentId, data);
            onCallback();
            // setAnswer('');
            // setResponseAwser('');
        } catch (error) {
            console.log('error', error);
        }
    }




    const onChunkClick = (selectedChunk, selectedChunkNumber) => {
        const dialogProps = {
            data: {
                selectedChunk,
                selectedChunkNumber
            }
        }
        setExpandedChunkDialogProps(dialogProps)
        setShowExpandedChunkDialog(true)
    }

    const checkMandatoryFields = () => {
        let canSubmit = true
        const inputParams = (selectedDocumentLoader.inputParams ?? []).filter((inputParam) => !inputParam.hidden)
        for (const inputParam of inputParams) {
            if (!inputParam.optional && (!selectedDocumentLoader.inputs[inputParam.name] || !selectedDocumentLoader.credential)) {
                if (inputParam.type === 'credential' && !selectedDocumentLoader.credential) {
                    canSubmit = false
                    break
                } else if (inputParam.type !== 'credential' && !selectedDocumentLoader.inputs[inputParam.name]) {
                    canSubmit = false
                    break
                }
            }
        }
        if (!canSubmit) {
            enqueueSnackbar({
                message: 'Preencha todos os campos obrigatórios',
                variant: 'error'
            })
        }
        return canSubmit
    }

    const onPreviewChunks = async () => {
        if (checkMandatoryFields()) {
            setLoading(true)
            const config = prepareConfig()
            config.previewChunkCount = previewChunkCount

            try {
                // const previewResp = await documentStoreApi.previewChunks(config)
                // if (previewResp.data) {
                //     setTotalChunks(previewResp.data.totalChunks)
                //     setDocumentChunks(previewResp.data.chunks)
                //     setCurrentPreviewCount(previewResp.data.previewChunkCount)
                // }
                addAnswer(config);
                setLoading(false)
                
                setSelectedDocumentLoader({})
                setSelectedTextSplitter({})
                getNodeDetailsApi.request(docLoaderNodeName)
                enqueueSnackbar({
                    message: 'Dados adicionados com sucesso',
                    variant: 'success'
                })

                setTimeout(() => {
                    onCallback();
                }, 2000);
               

            } catch (error) {
                setLoading(false)
                enqueueSnackbar({
                    message: `Preencha todos os campos`,
                    options: {
                        key: new Date().getTime() + Math.random(),
                        variant: 'error'
                    }
                })
            }
        }
    }

    // const onSaveAndProcess = async () => {
    //     if (checkMandatoryFields()) {
    //         setLoading(true)
    //         const config = prepareConfig()
    //         try {
    //             const processResp = await documentStoreApi.processChunks(config)
    //             setLoading(false)
    //             if (processResp.data) {
    //                 enqueueSnackbar({
    //                     message: 'File submitted for processing. Redirecting to Document Store..',
    //                     options: {
    //                         key: new Date().getTime() + Math.random(),
    //                         variant: 'success',
    //                         action: (key) => (
    //                             <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
    //                                 <IconX />
    //                             </Button>
    //                         )
    //                     }
    //                 })
    //                 navigate('/document-stores/' + storeId)
    //             }
    //         } catch (error) {
    //             setLoading(false)
    //             enqueueSnackbar({
    //                 message: `Failed to process chunking: ${typeof error.response.data === 'object' ? error.response.data.message : error.response.data
    //                     }`,
    //                 options: {
    //                     key: new Date().getTime() + Math.random(),
    //                     variant: 'error',
    //                     action: (key) => (
    //                         <Button style={{ color: 'white' }} onClick={() => closeSnackbar(key)}>
    //                             <IconX />
    //                         </Button>
    //                     )
    //                 }
    //             })
    //         }
    //     }
    // }

    const prepareConfig = () => {
        const config = {}

        // Set loader id & name
        if (existingLoaderFromDocStoreTable) {
            config.loaderId = existingLoaderFromDocStoreTable.loaderId
            config.id = existingLoaderFromDocStoreTable.id
        } else {
            config.loaderId = docLoaderNodeName
        }

        // Set store id & loader name
        config.storeId = storeId
        config.loaderName = selectedDocumentLoader?.label

        // Set loader config
        if (selectedDocumentLoader.inputs) {
            config.loaderConfig = {}
            Object.keys(selectedDocumentLoader.inputs).map((key) => {
                config.loaderConfig[key] = selectedDocumentLoader.inputs[key]
            })
        }

        // If Text splitter is set
        if (selectedTextSplitter.inputs && selectedTextSplitter.name && Object.keys(selectedTextSplitter).length > 0) {
            config.splitterId = selectedTextSplitter.name
            config.splitterConfig = {}

            Object.keys(selectedTextSplitter.inputs).map((key) => {
                config.splitterConfig[key] = selectedTextSplitter.inputs[key]
            })
            const textSplitter = textSplitterNodes.find((splitter) => splitter.name === selectedTextSplitter.name)
            if (textSplitter) config.splitterName = textSplitter.label
        }

        if (selectedDocumentLoader.credential) {
            config.credential = selectedDocumentLoader.credential
        }

        return config
    }

    useEffect(() => {
        if (uuidValidate(docLoaderNodeName)) {
            // this is a document store edit config
            getSpecificDocumentStoreApi.request(storeId)
        } else {
            getNodeDetailsApi.request(docLoaderNodeName)
        }
    }, [])

    useEffect(() => {
        if (getNodeDetailsApi.data) {
            const nodeData = cloneDeep(initNode(getNodeDetailsApi.data, uuidv4()))

            // If this is a document store edit config, set the existing input values
            if (existingLoaderFromDocStoreTable && existingLoaderFromDocStoreTable.loaderConfig) {
                nodeData.inputs = existingLoaderFromDocStoreTable.loaderConfig
            }
            setSelectedDocumentLoader(nodeData)

            // Check if the loader has a text splitter, if yes, get the text splitter nodes
            const textSplitter = nodeData.inputAnchors.find((inputAnchor) => inputAnchor.name === 'textSplitter')
            if (textSplitter) {
                getNodesByCategoryApi.request('Text Splitters')
            }
        }

    }, [getNodeDetailsApi.data])

    useEffect(() => {
        if (getNodesByCategoryApi.data) {
            // Set available text splitter nodes
            const nodes = []
            for (const node of getNodesByCategoryApi.data) {
                nodes.push(cloneDeep(initNode(node, uuidv4())))
            }
            setTextSplitterNodes(nodes)

            // Set options
            const options = getNodesByCategoryApi.data.map((splitter) => ({
                label: splitter.label,
                name: splitter.name
            }))
            options.unshift({ label: 'None', name: 'none' })
            setTextSplitterOptions(options)

            // If this is a document store edit config, set the existing input values
            if (
                existingLoaderFromDocStoreTable &&
                existingLoaderFromDocStoreTable.splitterConfig &&
                existingLoaderFromDocStoreTable.splitterId
            ) {
                const textSplitter = nodes.find((splitter) => splitter.name === existingLoaderFromDocStoreTable.splitterId)
                if (textSplitter) {
                    textSplitter.inputs = cloneDeep(existingLoaderFromDocStoreTable.splitterConfig)
                    setSelectedTextSplitter(textSplitter)
                } else {
                    setSelectedTextSplitter({})
                }
            }
        }
    }, [getNodesByCategoryApi.data])

    useEffect(() => {
        if (getSpecificDocumentStoreApi.data) {
            if (getSpecificDocumentStoreApi.data?.loaders.length > 0) {
                const loader = getSpecificDocumentStoreApi.data.loaders.find((loader) => loader.id === docLoaderNodeName)
                if (loader) {
                    setExistingLoaderFromDocStoreTable(loader)
                    getNodeDetailsApi.request(loader.loaderId)
                }
            }
        }

    }, [getSpecificDocumentStoreApi.data])

    useEffect(() => {
        if (getSpecificDocumentStoreApi.error) {
            setError(getSpecificDocumentStoreApi.error)
        }

    }, [getSpecificDocumentStoreApi.error])

    useEffect(() => {
        if (getNodeDetailsApi.error) {
            setError(getNodeDetailsApi.error)
        }

    }, [getNodeDetailsApi.error])

    useEffect(() => {
        if (getNodesByCategoryApi.error) {
            setError(getNodesByCategoryApi.error)
        }

    }, [getNodesByCategoryApi.error])

    return (
        <>
            <div className=''>
                {error ? (
                    <ErrorBoundary error={error} />
                ) : (
                    <Stack flexDirection='column'>

                        <Box>

                            <div
                                className={`w-full flex ${type === "websites" ? "flex-row" : "flex-col"} flex-row gap-2 mt-2`}

                            >

                                {docLoaderNodeName !== "cheerioWebScraper" && (
                                    <div className='w-full flex justify-center'>
                                        <RadioGroup
                                    value={docLoaderNodeName}
                                    onValueChange={(value) => {
                                        console.log('value', value);
                                        changeDocLoaderNodeName(value)
                                        getNodeDetailsApi.request(value)
                                    } }
                                    className={`grid grid-cols-${optionsDocuments.length} gap-4 w-full`}
                                >
                                        {optionsDocuments.map((option) => (
                                            <div className='cursor-pointer w-full'>
                                                <RadioGroupItem
                                                    value={option.value}
                                                    id={option.value}
                                                    className="peer sr-only "
                                                />
                                                <Label
                                                    htmlFor={option.value}
                                                    className={`flex flex-col items-center justify-between rounded-md border-2 border-muted bg-popover p-2 hover:bg-violet-600 hover:text-white ${docLoaderNodeName === option.value ? 'bg-violet-800 text-white' : ''}`}
                                                >
                                                    {option.label}
                                                </Label>
                                            </div>
                                        ))}
                                        </RadioGroup>
                                    </div>
                                )}

                                {selectedDocumentLoader &&
                                    Object.keys(selectedDocumentLoader).length > 0 &&
                                    (selectedDocumentLoader.inputParams ?? [])
                                        .filter((inputParam) => !inputParam.hidden)
                                        .map((inputParam, index) => (
                                            <DocStoreInputHandler
                                                key={index}
                                                typeFile={getFileTypeByValue(docLoaderNodeName)}
                                                inputParam={inputParam}
                                                data={selectedDocumentLoader}
                                            />
                                        ))}





                                {Object.keys(selectedTextSplitter).length > 0 &&
                                    (selectedTextSplitter.inputParams ?? [])
                                        .filter((inputParam) => !inputParam.hidden)
                                        .map((inputParam, index) => (
                                            <DocStoreInputHandler key={index} data={selectedTextSplitter} inputParam={inputParam} />
                                        ))}
                            </div>
                            <div className='w-full flex gap-2 mt-2 items-center justify-center'>

                                <Button
                                    className="h-12 bg-violet-600"
                                    onClick={onPreviewChunks}
                                >
                                    <Iconify className="mr-2" icon="bx:bxs-edit" />
                                    {docLoaderNodeName === "cheerioWebScraper" ? "Adicionar webisite" : "Adicionar documento"}
                                </Button>

                            </div>

                            {/* <Grid container spacing='2'> */}

                            {/* <Grid item xs={8} md={6} lg={6} sm={8}>
                                    {!documentChunks ||
                                        (documentChunks.length === 0 && (
                                            <div style={{ position: 'relative' }}>
                                                <Box display='grid' gridTemplateColumns='repeat(2, 1fr)' gap={gridSpacing}>
                                                    <Skeleton
                                                        animation={false}
                                                        sx={{ bgcolor: customization.isDarkMode ? '#23262c' : '#fafafa' }}
                                                        variant='rounded'
                                                        height={160}
                                                    />
                                                    <Skeleton
                                                        animation={false}
                                                        sx={{ bgcolor: customization.isDarkMode ? '#23262c' : '#fafafa' }}
                                                        variant='rounded'
                                                        height={160}
                                                    />
                                                    <Skeleton
                                                        animation={false}
                                                        sx={{ bgcolor: customization.isDarkMode ? '#23262c' : '#fafafa' }}
                                                        variant='rounded'
                                                        height={160}
                                                    />
                                                    <Skeleton
                                                        animation={false}
                                                        sx={{ bgcolor: customization.isDarkMode ? '#23262c' : '#fafafa' }}
                                                        variant='rounded'
                                                        height={160}
                                                    />
                                                    <Skeleton
                                                        animation={false}
                                                        sx={{ bgcolor: customization.isDarkMode ? '#23262c' : '#fafafa' }}
                                                        variant='rounded'
                                                        height={160}
                                                    />
                                                    <Skeleton
                                                        animation={false}
                                                        sx={{ bgcolor: customization.isDarkMode ? '#23262c' : '#fafafa' }}
                                                        variant='rounded'
                                                        height={160}
                                                    />
                                                </Box>
                                                <div
                                                    style={{
                                                        position: 'absolute',
                                                        top: 0,
                                                        right: 0,
                                                        width: '100%',
                                                        height: '100%',
                                                        backdropFilter: `blur(1px)`,
                                                        background: `transparent`,
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        justifyContent: 'center'
                                                    }}
                                                >
                                                    <StyledFab
                                                        color='secondary'
                                                        aria-label='preview'
                                                        title='Preview'
                                                        variant='extended'
                                                        onClick={onPreviewChunks}
                                                    >
                                                        <IconEye style={{ marginRight: '5px' }} />
                                                        Ver Pré-visualização
                                                    </StyledFab>
                                                </div>
                                            </div>
                                        ))}
                                    {documentChunks && documentChunks.length > 0 && (
                                        <>
                                         <div className="text-lg font-semibold"> {currentPreviewCount} of {totalChunks} recortes</div>
                              
                                            <Box sx={{ mb: 3 }}>
                                                <Typography>Mostrar pré-vizualização de recoretes</Typography>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <OutlinedInput
                                                        size='small'
                                                        multiline={false}
                                                        sx={{ mt: 1, flex: 1, mr: 2 }}
                                                        type='number'
                                                        key='previewChunkCount'
                                                        onChange={(e) => setPreviewChunkCount(e.target.value)}
                                                        value={previewChunkCount ?? 25}
                                                    />
                                                    <StyledFab
                                                        color='secondary'
                                                        aria-label='preview'
                                                        title='Preview'
                                                        variant='extended'
                                                        onClick={onPreviewChunks}
                                                    >
                                                        <IconEye style={{ marginRight: '5px' }} />
                                                        Preview
                                                    </StyledFab>
                                                </div>
                                            </Box>
                                            <div style={{ height: '800px', overflow: 'scroll', padding: '5px' }}>
                                                <Grid container spacing={2}>
                                                    {documentChunks?.map((row, index) => (
                                                        <Grid item lg={6} md={6} sm={6} xs={6} key={index}>
                                                            <CardWrapper
                                                                content={false}
                                                                onClick={() => onChunkClick(row, index + 1)}
                                                                sx={{
                                                                    border: 1,
                                                                    borderColor: theme.palette.grey[900] + 25,
                                                                    borderRadius: 2
                                                                }}
                                                            >
                                                                <Card>
                                                                    <CardContent sx={{ p: 1 }}>
                                                                        <Typography sx={{ wordWrap: 'break-word', mb: 1 }} variant='h5'>
                                                                            {`#${index + 1}. Characters: ${row.pageContent.length}`}
                                                                        </Typography>
                                                                        <Typography sx={{ wordWrap: 'break-word' }} variant='body2'>
                                                                            {row.pageContent}
                                                                        </Typography>
                                                                        <ReactJson
                                                                            theme={customization.isDarkMode ? 'ocean' : 'rjv-default'}
                                                                            style={{ paddingTop: 10 }}
                                                                            src={row.metadata}
                                                                            name={null}
                                                                            quotesOnKeys={false}
                                                                            enableClipboard={false}
                                                                            displayDataTypes={false}
                                                                            collapsed={1}
                                                                        />
                                                                    </CardContent>
                                                                </Card>
                                                            </CardWrapper>
                                                        </Grid>
                                                    ))}
                                                </Grid>
                                            </div>
                                        </>
                                    )}
                                </Grid> */}
                            {/* </Grid> */}
                        </Box>
                    </Stack>
                )}
            </div>
            <ExpandedChunkDialog
                show={showExpandedChunkDialog}
                isReadOnly={true}
                dialogProps={expandedChunkDialogProps}
                onCancel={() => setShowExpandedChunkDialog(false)}
            ></ExpandedChunkDialog>
            {loading && <BackdropLoader open={loading} />}
        </>
    )
}

export default LoaderConfigPreviewChunks
