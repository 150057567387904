import { ReloadIcon } from "@radix-ui/react-icons"
import { Button } from "src/@/components/ui/button"

export function ButtonLoading({
    onClick,
    children,
    variant,
    size,
    className,
    loading
}) {
    return (
        <Button className={className} variant={variant} onClick={onClick} size={size} disabled={loading ? true : false}>
            {loading && <ReloadIcon className="mr-2 h-2 w-4 animate-spin" />}
            {children}
        </Button>
    )
}
