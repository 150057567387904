import editFill from '@iconify/icons-eva/edit-fill';
import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import plusFill from '@iconify/icons-eva/plus-fill';
import trash2Outline from '@iconify/icons-eva/trash-2-outline';
import { Icon } from '@iconify/react';
import { useTheme } from '@mui/material/styles';
import {

  Stack,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Box,
  LinearProgress,
  FormControl,
  FormLabel,
  RadioGroup,
  Radio,
  CircularProgress,
  Drawer,
  Divider
} from '@mui/material';
import { useState, useEffect, useRef, useCallback, useMemo } from 'react';
// material

import MicRecorder from "mic-recorder-to-mp3";

// redux
import actionCadastrarRespostaRapida from 'src/actions/dashboard/respostas-rapidas/actionCadastrarRespostaRapida';
import actionDeletarRespostaRapida from 'src/actions/dashboard/respostas-rapidas/actionDeletarRespostaRapida';
import actionEditarRespostaRapida from 'src/actions/dashboard/respostas-rapidas/actionEditarRespostaRapida';
import actionListaRespostasRapidas from 'src/actions/dashboard/respostas-rapidas/actionListaRespostasRapida';
import RecordingTimer from 'src/components/_chats/chat/components/RecordingTimer';

// components

import useAuth from 'src/hooks/useAuth';


import { BsMicFill } from 'react-icons/bs';
import { FaCheck } from "react-icons/fa";
import v2_actionUploadS3 from 'src/actions/v2/global/actionUploadS3';
import Iconify from 'src/components/Iconify';
import { LoadingButton } from '@mui/lab';
import { UploadUnicoArquivo } from 'src/components/upload';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import { TableBorder } from 'src/components/table';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import InputStyleMultline from 'src/components/InputStyleMultline';

import { Button } from 'src/@/components/ui/button';
import Label from 'src/components/Label';
import { Textarea } from 'src/@/components/ui/textarea';
import { Input } from 'src/@/components/ui/input';
import HeaderDrawer from 'src/components/modal/HeaderDrawer';
import TableRespostasRapidas from './TableRespostasRapidas';
import { CardContent, Card } from 'src/@/components/ui/card';
import ModalConfimacao from 'src/components/modal/ModalConfimacao';
import { useSnackbar } from 'notistack';

// ----------------------------------------------------------------------


export default function RespostasRapidasComponent() {
  const theme = useTheme();
  const { user } = useAuth();
  const enqueueSnackbar = useSnackbar
  const [openDialogNovoSetor, setOpenDialogNovoSetor] = useState(false);
  const [openDialogEditarSetor, setOpenDialogEditarSetor] = useState(false);

  const [isEdit, setIsEdit] = useState(false);
  const [isEditFile, setIsEditFile] = useState(true);

  const [resposta, setRespostaRapida] = useState('');
  const [listaRespostaRapidas, setListasRespostasRapidas] = useState([]);
  const [pesquisaRespostaRapida, setPesquisaRespostaRapidas] = useState('');
  const [loading, setLoading] = useState(false);

  ///// value fields
  const [tituloEdit, setTituloEdit] = useState('');
  const [respostaEdit, setRespostaEdit] = useState('');
  const [statusEdit, setStatusEdit] = useState(true);
  const [idResposta, setIdResposta] = useState('');

  const [tipoResposta, setTipoResposta] = useState('');


  const [isMe, setIsMe] = useState(false);
  const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
  const [files, setFiles] = useState([]);
  const filesUrl = []
  const [filesEdit, setFilesEdit] = useState([]);
  const [preview, setPreview] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [recording, setRecording] = useState(false);
  const [audioGravado, setAudioGravado] = useState(null);
  const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);
  const [openDrawerAudio, setOpenDrawerAudio] = useState(false);
  const [action, setAction] = useState('');

  const [modalAlerta, setModalAlerta] = useState(false);
  const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
  const [idRespostaRapida, setIdRespostaRapida] = useState(null);


  const handleCloseDialogNovaResposta = () => {
    setOpenDialogNovoSetor(false);
    setIdResposta('');
    setRespostaEdit('')
    setTituloEdit('');
    setAudioGravado(null);
    setFiles([]);
  };

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
    [setFiles]
  );

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };


  const handleChangeRadio = (event) => {
    setTipoResposta(event.target.value);
  };

  const handleStartRecording = async () => {
    setLoading(true);
    setOpenDrawerAudio(true)
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await recorder.start();
      setRecording(true);
      setLoading(false);
    } catch (err) {
      console.log(err);
      setLoading(false);
    }
  };



  const buscarRespostasRapidas = async () => {
    setLoading(true)
    try {
      const res = await actionListaRespostasRapidas(user.clienteId)
      setListasRespostasRapidas(res.data.respostas)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log('Erro ao buscar Lista de Responstas rápidas', error)
    }
  }

  const handleCancelAudio = async () => {
    try {
      await recorder.stop().getMp3();
      setRecording(false);
      setAudioGravado(null);
    } catch (err) {
      console.log(err);
    }
  };


  useEffect(() => {
    buscarRespostasRapidas()
  }, []);



  const handleClickOpenDialogNovoSetor = () => {
    setOpenDialogNovoSetor(true)
  };



  const handleUploadArquivo = async () => {
    const response_upload = await uploadAudioAndGetUrl(files[0]);
    filesUrl.push(response_upload)

  };


  const handleEditarResposta = async () => {
    try {
      let data = {
        id: resposta._id,
        titulo: tituloEdit,
        resposta: respostaEdit,
      }
      await actionEditarRespostaRapida(data);
      handleCloseDialogNovaResposta();
      await buscarRespostasRapidas()
      handleCloseDialogNovaResposta()
    } catch (error) {
      console.log(error)
    }
  }

  ////////////// salvar resposta rapida
  const handleAddRespostaRapida = async () => {
    setLoading(true)
    try {
      if (tipoResposta === "audio") {
        await handleUploadAudio();
        let data = {
          clienteId: user.clienteId,
          titulo: tituloEdit,
          respostaField: respostaEdit,
          tipo: tipoResposta,
          files: filesUrl
        }
        await actionCadastrarRespostaRapida(data);
        setLoading(false);
        handleCloseDialogNovaResposta();
        buscarRespostasRapidas();
      } else if (tipoResposta === "arquivo") {

        console.log('começou upload arquivo')
        await handleUploadArquivo();
        let data = {
          clienteId: user.clienteId,
          titulo: tituloEdit,
          respostaField: respostaEdit,
          tipo: tipoResposta,
          files: filesUrl
        }
        await actionCadastrarRespostaRapida(data);
        setLoading(false);
        handleCloseDialogNovaResposta();
        buscarRespostasRapidas();

      } else {
        let data = {
          clienteId: user.clienteId,
          titulo: tituloEdit,
          respostaField: respostaEdit,
          tipo: tipoResposta,
          files: []
        }
        await actionCadastrarRespostaRapida(data);
        setLoading(false);
        handleCloseDialogNovaResposta();
        buscarRespostasRapidas();
      }


    } catch (error) {
      setLoading(false);
      alert('Erro ao cadastrar resposta rápida');
    }
  }

  async function uploadAudioAndGetUrl(file) {
    if (file) {
      var formData = new FormData();
      var audiofile = file;
      formData.append("file", audiofile);
      try {
        let querys = `?path=plataforma/resposta-rapida`
        const response = await v2_actionUploadS3(formData, querys)
        if (!response.data.error) {
          return { key: response.data.data.key, type: file.type, tipo: "audio" };
        }
      } catch (error) {
        console.log('erro ao upload arquivo', error)
      }


    }
  }

  async function pararGravacao() {
    try {
      const [buffer, blob] = await recorder.stop().getMp3()
      const file = new File(buffer, "audio.mp3", {
        type: blob.type,
        lastModified: Date.now()
      });
      console.log('file', file)
      setAudioGravado(file)
      setRecording(false)
    } catch (error) {
      filesUrl = [];
    }
  }

  const handleUploadAudio = async () => {
    const response_upload = await uploadAudioAndGetUrl(audioGravado);
    filesUrl.push(response_upload)
  };

  const handleDeleteRespostas = async () => {
    await actionDeletarRespostaRapida({ id: idRespostaRapida });
    await buscarRespostasRapidas();
    setOpenModalConfirmacao(false);
    setIdRespostaRapida(null);
  };

  const handleOpenDialogEditarRespostaRapida = (row) => {
    setAction('editar');
    setTipoResposta('texto')
    if (row.tipo !== "texto") {
      alert('Não pe possivel editar resposta rapida do tipo arquivo ou audio, exclua e crie uma nova resposta rápida');
      return;
    }
    setOpenDialogNovoSetor(true)
    setRespostaRapida(row);
  };






  const handleChangeTitulo = (event) => {
    setTituloEdit(event.target.value);
  };

  const handleChangeResposta = (event) => {
    setRespostaEdit(event.target.value);
  };

  function validaBotaoCriar() {
    if (tipoResposta === "audio" && audioGravado && tituloEdit !== "") {
      return false
    } else if (tipoResposta === "arquivo" && files.length > 0 && tituloEdit !== "") {
      return false
    } else if (tipoResposta === "texto" && tituloEdit !== "") {
      return false
    } else {
      return true
    }
  }

  return (
    <>


      <ModalAlerta
        open={modalAlerta}
        onClose={() => setModalAlerta(false)}
        mensagem={'Não é possivel editar resposta rapida do tipo arquivo ou audio, exclua e crie uma nova resposta rápida'}
      />

      <Container maxWidth="lg">
        <HeaderPageComponentConfig
          titulo="Respostas rápidas"
          descricao={'Cadastre respostas rápidas para agilizar o atendimento. Incluindo, textos, arquivos e audios.'}
          actions={<Button
            onClick={handleClickOpenDialogNovoSetor}
          >
            Criar Resposta Rápida
          </Button>}
        />

        <ModalConfimacao
          open={openModalConfirmacao}
          onClose={() => {
            setOpenModalConfirmacao(false);
            setIdRespostaRapida(null);
          }}
          onConfirm={handleDeleteRespostas}
          loadingFinalizar={loading}
          mensagem={'Deseja mesmo excluir essa resposta rápida?'}
        />

        <Drawer anchor="right" open={openDialogNovoSetor} onClose={handleCloseDialogNovaResposta}>
          <HeaderDrawer
            title="Criar Resposta Rápida"
            close={handleCloseDialogNovaResposta}
          />
          <Box sx={{ width: 650, p: 2 }}>
            <Stack spacing={3} sx={{ mb: 1, mt: 3 }}>

              <RowInput
                label="Nome"
                input={
                  <Input
                    autoFocus
                    type="text"
                    value={tituloEdit}
                    onChange={handleChangeTitulo}
                  />}
              />


              <RowInput
                label="Tipo"
                input={
                  <FormControl sx={{ mt: 5, mb: 5 }}>
                    <RadioGroup
                      row
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={tipoResposta}
                      onChange={handleChangeRadio}
                    >
                      <FormControlLabel value="texto" control={<Radio />} label="Texto" />
                      <FormControlLabel value="arquivo" control={<Radio disabled={action === "editar" ? true : false} />} label="Arquivo" />
                      <FormControlLabel value="audio" control={<Radio disabled={action === "editar" ? true : false} />} label="Audio" />
                    </RadioGroup>
                  </FormControl>}
              />




              {tipoResposta === "texto" && (

                <RowInput
                  label="Texto"
                  input={<Textarea
                    autoFocus
                    multiline
                    rows={4}
                    type="text"
                    value={respostaEdit}
                    margin="dense"
                    variant="outlined"
                    onChange={handleChangeResposta}
                  />}
                />

              )}


              {tipoResposta === "arquivo" && (
                <UploadUnicoArquivo
                  isMe={isMe}
                  loadingEnviarArquivos={loadingEnviarArquivos}
                  showPreview={preview}
                  completado={false}
                  files={files}
                  isEdit={isEdit}
                  isEditFile={isEditFile}
                  filesEdit={filesEdit}
                  onRemoveEdit={(file) => {
                    setFileSelect(file);
                    setOpenConfirmaExlusaoArquivos(true);
                  }}
                  onDrop={handleDropMultiFile}
                  onRemove={handleRemove}
                  attachments={attachments}
                />
              )}

              {tipoResposta === "audio" && (
                <>
                  {recording ? (
                    <Stack direction="column" spacing={2}>
                      <Stack spacing={2} direction="row" alignItems={'center'}>
                        <Typography variant="subtitle2">
                          Gravação em andamento
                        </Typography>
                        <CircularProgress size={15} />
                      </Stack>
                      <Stack direction="row">
                        <IconButton
                          aria-label="cancelRecording"
                          component="span"
                          fontSize="large"
                          disabled={loading}
                          onClick={handleCancelAudio}
                        >
                          <Iconify icon="mdi:cancel" />
                        </IconButton>
                        {loading ? (
                          <div>
                            <CircularProgress />
                          </div>
                        ) : (
                          <RecordingTimer />
                        )}

                        <IconButton
                          aria-label="sendRecordedAudio"
                          component="span"
                          onClick={pararGravacao}
                          disabled={loading}
                        >
                          <FaCheck />
                        </IconButton>
                      </Stack>

                    </Stack>
                  ) : (
                    <Stack direction={'row'}>

                      {!audioGravado && (
                        <Button
                          variant="outline"
                          startIcon={<BsMicFill />}
                          disabled={loading}
                          onClick={handleStartRecording}
                        >
                          <Iconify className="mr-1 mt-0.5" icon="mdi:microphone" />
                          Gravar audio
                        </Button>
                      )}

                      {audioGravado && (
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                          <audio controls>
                            <source src={URL.createObjectURL(audioGravado)} type="audio/mp3" />
                            Seu navegador não suporta a reprodução de áudio.
                          </audio>
                          <IconButton onClick={() => setAudioGravado(null)} variant='outlined' color="error">
                            <Iconify icon="material-symbols:delete" />
                          </IconButton>
                        </Stack>

                      )}
                    </Stack>

                  )}
                </>
              )}

            </Stack>


            <Stack direction="row" alignItems="center" sx={{ mt: 5 }}>
              <LoadingButton disabled={validaBotaoCriar()} loading={loading} onClick={action === "editar" ? handleEditarResposta : handleAddRespostaRapida} variant="contained">
                {action === "editar" ? "Editar" : "Criar"}
              </LoadingButton>
            </Stack>

          </Box>

        </Drawer>


        <Stack sx={{ mt: 5 }}>




          {loading ? (
            <Box sx={{ width: '100%' }}>
              <LinearProgress />
            </Box>
          ) : (
            <Stack spacing={2}>
              {/* <Stack direction={'collumn'}>
                <TextField size="small" value={pesquisaRespostaRapida} onChange={e => setPesquisaRespostaRapidas(e.target.value)} placeholder="Pesquisar" />
              </Stack> */}
              <Card>
                <CardContent>
                  <TableRespostasRapidas
                    data={listaRespostaRapidas}
                    onEdit={row => {
                      setIdResposta(row._id);
                      setTituloEdit(row.titulo);
                      setRespostaEdit(row.resposta);
                      setStatusEdit(row.ativo);
                      handleOpenDialogEditarRespostaRapida(row)
                    }}
                    onDelete={row => {
                      setIdRespostaRapida(row._id);
                      setOpenModalConfirmacao(true);
                    }}
                  />

                </CardContent>
              </Card>
            </Stack>
          )}
        </Stack>
      </Container>
    </>
  );
}
