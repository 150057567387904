import axios from "axios";
import Config from "src/config";

const v1_actionCriarFlow = (clienteId, data) => {
    
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/${clienteId}/flows/criar`,
        data
    });
};

export default v1_actionCriarFlow;
