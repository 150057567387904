
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from '@mui/material';
import { LoadingButton } from "@mui/lab";

export function AlertDialogLoading({title, content, close,confirm, loading}) {
  return (
    <Dialog open onClose={close}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="primary">
          Cancelar
        </Button>
        <LoadingButton loading={loading} onClick={confirm} color="primary" autoFocus>
          Confirmar
        </LoadingButton>
      </DialogActions>
    </Dialog>
  );
}
