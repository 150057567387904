import axios from "axios";
import Config from "src/config";

const actionLerNotificacao = (usuarioId, data) => {
    console.log('notificacao Id', usuarioId, data)
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/notificacoes/${usuarioId}/mark-read-only`,
        data
    });
};

export default actionLerNotificacao;
