
import { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import { Box, Avatar, Typography, Menu, MenuItem, IconButton, Tooltip, Stack, FormControl, TextField, Select, Icon } from '@mui/material';
import { useNavigate } from 'react-router-dom';

//componemts
import ModalFinalizarChat from 'src/components/_chats/chat/components/ModalFinalizarChat';
import ModalTransferirChat from 'src/components/_chats/chat/components/ModalTransferirChat';
import ModalFunilChat from 'src/components/_chats/chat/components/ModalFunilChat';
import DrawerAgendamentos from 'src/components/_chats/agendamentos/DrawerAgendamentos';
// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getChatsPendente, getChatsAtendimento, getChatsFinalizados, getListaFunil, getCountsChats, setChatInfo, removeChatFromList } from 'src/redux/slices/chat';
import { getListaSetoresTransferencia, getListaUsuariosTransferencia } from 'src/redux/slices/user';
import useAuth from 'src/hooks/useAuth';
// whatsapp
// hooks
import useResponsive from 'src/hooks/useResponsive';
//routes
import { PATH_CHAT } from 'src/routes/paths';
//components
import { formataNumeroCelular } from 'src/utils/functions';
import Iconify from 'src/components/Iconify';
import LightboxModal from 'src/components/LightboxModal';
import MicRecorder from "mic-recorder-to-mp3";
import { useSnackbar } from 'notistack';
import Label from 'src/components/Label';
//actions
import v1_actionEnviarMenagemWhatsapp from 'src/actions/v1/whatsapp/v1_actionEnviarMenagemWhatsapp';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import v2_actionUploadS3 from 'src/actions/v2/global/actionUploadS3';
import getActionAceitarChat from 'src/actions/chats/getActionAceitarChat';
import actionSendMessageMeta from 'src/actions/chats/meta/actionSendMessageMeta';
import getActionTransferirChat from 'src/actions/chats/getActionTransferirChat';
import getActionFinalizarChat from 'src/actions/chats/getActionFinalizarChat';
import getActionCriarFunil from 'src/actions/chats/getActionCriarFunil';
import getActionBuscarNomeOuSetor from 'src/actions/chats/getActionBuscarNomeOuSetor';
import actionCriarAgendamento from 'src/actions/agendamentos/actionCriarAgendamento';
import { setMessages } from 'src/redux/slices/staff';
///
import { Button } from 'src/@/components/ui/button';
import v2_actionFinalizarChat from 'src/actions/v2/chats/v2_actionFinalizarChat';
import v2_actionTransferChatInit from 'src/actions/v2/chats/v2_actionTransferChatInit';

const RootStyle = styled('div')(({ theme }) => ({
  flexShrink: 0,
  minHeight: 70,
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0.5, 2)
}));

// ----------------------------------------------------------------------

OneAvatar.propTypes = {
  chat: PropTypes.object
};

function OneAvatar({ chat, chatIsOnline, handleOpenModalFunilChat }) {
  const defaultImage = 'https://zapchat.nyc3.digitaloceanspaces.com/images/not-picture.png'

  const [open, setOpen] = useState(false);

  let validaIsBrazilNumber = chat?.numeroCliente?.startsWith("55") ? formataNumeroCelular(chat.numeroCliente) : `+${chat.numeroCliente}`

  if (chat === undefined) {
    return null;
  }

  return (
    <>

      {chat && (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <LightboxModal
            images={[chat.fotoPerfil]}
            photoIndex={0}
            setPhotoIndex={() => { }}
            isOpen={open}
            onClose={() => setOpen(false)}
          />
          <Box sx={{ position: 'relative' }}>
            <Avatar onClick={() => setOpen(true)} sx={{ width: 30, height: 30, borderRadius: 2 }} src={chat ? chat.fotoPerfil : defaultImage} alt={chat.nomeCliente} />
            {/* <BadgeStatus status={participant.status} sx={{ position: 'absolute', right: 2, bottom: 2 }} /> */}
          </Box>
          <Box sx={{ display: "flex", ml: 0 }}>
            <Stack sx={{ lineHeight: 0, display: "block", ml: 2 }}>
              <Stack direction={'row'} spacing={0.5}>
                {chat && chat.canal === "whatsapp" && <Typography sx={{ fontSize: 12 }}> {chat ? (chat.numeroCliente ? validaIsBrazilNumber : "") : "Sem numero"}</Typography>}
                {chat && chat.canal === "instagram" && <Typography sx={{ fontSize: 12 }}> @{chat.instagramUser?.profile?.username}</Typography>}
                {chat && (
                  <Label color="success" >
                    <Stack direction={'row'} spacing={1}>
                      <Iconify icon={'fluent:channel-share-28-filled'} width={10} height={10} color={'#00a884'} />
                      <Typography sx={{ textTransform: 'uppercase', fontSize: 8 }}> {chat.nome_canal}</Typography>
                    </Stack>
                  </Label>
                )}

              </Stack>

              <Box style={{ display: 'flex' }}>
                {chatIsOnline ? <Typography style={{ marginRight: 10, background: "#6fdf00", color: '#fff', paddingLeft: 8, paddingRight: 8, borderRadius: 5 }} variant="caption">Online</Typography> : <Typography variant="extraSmall">{chat.nomeCliente}</Typography>}
              </Box>
            </Stack>
          </Box>

        </Box>

      )}
    </>
  );
}



ChatHeader.propTypes = {
  chat: PropTypes.object,
  user: PropTypes.object,
  chatIsOnline: PropTypes.bool,
  onlineUsers: PropTypes.array,
};

export default function ChatHeader({
  chat,
  user,
  onlineUsers,
  chatIsOnline,
  openSidebar,
  changeOpenSidebar,
  sidebarLeft,
  changeOpenSidebarLeft,
  ...other
}) {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();

  const [stateModalFinalizarChat, setStateModalFinalizarChat] = useState(false);
  const [stateModalTransferirChat, setStateModalTransferirChat] = useState(false);
  const [stateModalFunil, setStateModalFunil] = useState(false);
  const [loadingFinalizarChat, setLoadingFinalizarChat] = useState(false);
  const [loadingTransferirChat, setLoadingTransferirChat] = useState(false);
  const [valueTransferirChat, setValueTransferirChat] = useState('');
  // const [configMsg, setConfigMsg] = useState('');
  const [valorEscolhidoFunil, setValorEscolhidoFunil] = useState(chat.funilId);
  const [valorEscolhidoSetor, setValorEscolhidoSetor] = useState(null);
  const [valorEscolhidoUsuario, setValorEscolhidoUsuario] = useState(null);
  const { listaUsuariosTransferencia, listaSetoresTransferencia } = useSelector((state) => state.user);
  const { listaFunil } = useSelector((state) => state.chat)
  const isDesktop = useResponsive('up', 'md');
  const [body, setBody] = useState('');
  const [dataAgendamento, setDataAgendamento] = useState(new Date())
  const [hora, setHora] = useState('')
  const [minuto, setMinuto] = useState('');
  const [numero, setNumero] = useState('');
  const [ddd, setDDD] = useState('');
  // agendamentos
  const [drawerAgendamento, setDrawerAgendamento] = useState(false);
  const [isAlert, setIsAlert] = useState(false);
  const [isChatAtendimento, setIsChatAtendimento] = useState(false);
  //upload
  const [files, setFiles] = useState([]);
  const filesUrl = []
  const [preview, setPreview] = useState(true);
  const [isFile, setIsFile] = useState(false);
  const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [tipoArquivo, setTipoArquivo] = useState('');
  const [recording, setRecording] = useState(false);
  const [audioGravado, setAudioGravado] = useState(null);
  const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);
  const [loadingAudio, setLoadingAudio] = useState(false);
  const direcionaUsuarios = chat && chat.direciona_usuarios;
  const { cliente } = useAuth()
  const [clienteIdPadrao, setClienteIdPadrao] = useState(user.cliente_id_padrao);
  const [listaClientesCanais, setListaClientesCanais] = useState([]);
  const isMultisession = cliente.multiSession === 1;

  async function buscarListaClientesCanais() {
    try {
      const response_clientes_canais = await actionListaClientesCanais(cliente._id)
      setListaClientesCanais(response_clientes_canais.data.data);
    } catch (err) {
      console.log(err)
    }
  }


  useEffect(() => {
    isMultisession && buscarListaClientesCanais();
    setValorEscolhidoFunil(chat.funil_id);
    dispatch(getListaFunil(user.clienteId))
  }, [chat, valorEscolhidoFunil])



  async function uploadAndGetUrl() {
    if (files.length > 0) {
      for (let file of files.map(item => item)) {
        var formData = new FormData();
        var imagefile = file;
        formData.append("file", imagefile);
        try {
          let querys = `?path=plataforma/agendamentos`
          const response = await v2_actionUploadS3(formData, querys)
          if (!response.data.error) {
            filesUrl.push({ key: response.data.data.key, type: file.type });
          }
        } catch (error) {
          console.log('erro ao upload arquivo', error)
        }

      }
    }
  }


  const handleOpenDrawerAgendamentos = () => {
    if (chat && chat.numeroCliente) {
      setDDD(chat.numeroCliente.slice(2, 4))
      setNumero(chat.numeroCliente.slice(4, 14))
      setDrawerAgendamento(true)
    }
  }

  const onCriarAgendamento = async () => {
    if (hora === "" || minuto === "" || ddd === "" || numero === "" || (isFile && files.length === 0 && !audioGravado) || (!isFile && body === "")) {
      setIsAlert(true)
    } else {
      setLoadingEnviarArquivos(true);

      try {
        const data = {
          dataAgendamento,
          hora,
          minuto,
          numero: ddd + numero,
          status_envio: "agendado",
          criado_por: user.id,
          cliente_id: isMultisession ? clienteIdPadrao : user.clienteId,
          texto: body,
          isFile: isFile,
          files: filesUrl,
          isChatAtendimento
        }
        if (isFile) {
          if (tipoArquivo === "audio") {
            await handleUploadAudio();
          } else {
            await uploadAndGetUrl();
          }
        }
        await actionCriarAgendamento(data);
        setLoadingEnviarArquivos(false);
        setDrawerAgendamento(false)
        limparCampos();
      } catch (error) {
        setLoadingEnviarArquivos(false);
        console.log('error', error);
        // limparCampos();
      }
    }
  }


  const onCloseDrawerAgendamento = () => {
    setDrawerAgendamento(false)
  }

  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
    [setFiles]
  );

  const handleRemoveAll = () => {
    setFiles([]);
  };

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };

  const handleChangeIsFile = (event) => {
    setIsFile(event.target.checked);
  };


  const limparCampos = () => {
    setDataAgendamento(new Date());
    setHora('');
    setMinuto('');
    setFiles([]);

  }

  const handleChangeTipoTransferirChat = (event) => {
    setValueTransferirChat(event.target.value);
    setValorEscolhidoUsuario(null);
    setValorEscolhidoSetor(null);
  };

  const handleEscolherSetor = event => {
    setValorEscolhidoSetor(event);
  };

  const handleEscolherUsuario = event => {
    setValorEscolhidoUsuario(event);
  };



  const handleEscolherFunil = event => {
    setValorEscolhidoFunil(event.target.value);
  };

  const handleOpenModalFunilChat = () => {
    dispatch(getListaFunil(user.clienteId))
    setStateModalFunil(true);
  };


  const handleOpenModalFinalizarChat = () => {
    setStateModalFinalizarChat(true);
  };

  const handleOpenModalTransferirChat = () => {
    dispatch(getListaUsuariosTransferencia(user.clienteId));
    dispatch(getListaSetoresTransferencia(user.clienteId));
    setValorEscolhidoUsuario(null);
    setValorEscolhidoSetor(null);
    setStateModalTransferirChat(true);
  };

  const handleCloseModalFinalizarChat = () => {
    setValorEscolhidoUsuario(null);
    setValorEscolhidoSetor(null);
    setStateModalFinalizarChat(false);
  };

  const handleCloseModalTransferirChat = () => {
    setStateModalTransferirChat(false);
  };

  const handleCloseModalFunilChat = () => {
    setStateModalFunil(false);
  };

  const handleTransferirChat = async (sendMessage) => {
    setLoadingTransferirChat(true);
    let data = {
      id: chat._id,
      usuarioId: user.id,
      direcionaSetores: valorEscolhidoSetor,
      direcionaUsuarios: valorEscolhidoUsuario,
      sendMessage: sendMessage
    };

    try {
      await v2_actionTransferChatInit(chat._id, data);
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
      dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 10, true));
      setStateModalTransferirChat(false);
      setLoadingTransferirChat(false);
      navigate(PATH_CHAT.chat);
      dispatch(setChatInfo(null));
      dispatch(setMessages([]));
    } catch (error) {

    }
  }

  // const handleTranferirChat = async () => {
  //   setLoadingTransferirChat(true);
  //   let data = {
  //     id: chat._id,
  //     usuarioId: user.id,
  //     direcionaSetores: valorEscolhidoSetor,
  //     direcionaUsuarios: valorEscolhidoUsuario
  //   };

  //   try {
  //     await getActionTransferirChat(data);
  //     if (valorEscolhidoSetor) {
  //       let res = await getActionBuscarNomeOuSetor({ setorId: valorEscolhidoSetor, usuarioId: "" })
  //       let querys = `?sessao=${chat.sessao}&canal=whatsapp`;
  //       let body = {
  //         phone: chat.numeroCliente,
  //         message: `*_${user.nomeFantasia}:_* \n O seu atendimento foi transferido para ${res.data.nome_setor}, por favor, aguarde.`
  //       }
  //       await v1_actionEnviarMenagemWhatsapp(querys, body);
  //       setValorEscolhidoSetor(null)
  //     }

  //     if (valorEscolhidoUsuario) {
  //       setValorEscolhidoUsuario(null);
  //       let res = await getActionBuscarNomeOuSetor({ setorId: "", usuarioId: valorEscolhidoUsuario });
  //       let querys = `?sessao=${chat.sessao}&canal=whatsapp`;
  //       let body = {
  //         phone: chat.numeroCliente,
  //         message: `*_${user.nomeFantasia}:_* \n O seu atendimento foi transferido para ${res.data.nomeFantasia}, por favor, aguarde.`
  //       }
  //       await v1_actionEnviarMenagemWhatsapp(querys, body);
  //       setValorEscolhidoUsuario(null);
  //     }
  //     dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
  //     dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 10, true));
  //     setStateModalTransferirChat(false);
  //     setLoadingTransferirChat(false);
  //     navigate(PATH_CHAT.chat);
  //     dispatch(setChatInfo(null));
  //     dispatch(setMessages([]));
  //   } catch (error) {
  //     console.log('erro ao transferir chat')
  //   }
  // };

  const handleAdicionarNoFunil = async (funilId) => {
    let data = {
      chatId: chat._id,
      usuarioId: user.id,
      funilId: funilId
    };

    try {
      await getActionCriarFunil(data);
      setValorEscolhidoFunil(funilId);
      enqueueSnackbar('Funil alterado com sucesso', {
        variant: 'success'
      });
      //dispatch(getChatInfoByChatId(chat._id, funilId, user.id));
      // dispatch(getLoadMessagesInChat(chat.numeroCliente));
    } catch (error) {
      console.log('erro ao adicionar no funil', error)
    }
  };



  const handleFinalizarChat = async (chatId, sendMessage, statusOrigin) => {
    let data = {
      userId: user.id,
      sendMessage: sendMessage
    };
    try {
      setLoadingFinalizarChat(true);
      dispatch(removeChatFromList({ chatId: chatId, status: statusOrigin }))
      await v2_actionFinalizarChat(chatId, data);
      dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 50, true));
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 100, true));
      setStateModalFinalizarChat(false);
      setLoadingFinalizarChat(false);
      dispatch(setChatInfo(null));
      return navigate(`${PATH_CHAT.chat.root}`);

    } catch (error) {
      setLoadingFinalizarChat(false);
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 100, false));
      enqueueSnackbar("Erro ao finalizar chat", { variant: "error" });
    }

  };

  const handleCancelAudio = async () => {
    try {
      await recorder.stop().getMp3();
      setRecording(false);
      setAudioGravado(null);
    } catch (err) {
      console.log(err);
    }
  };

  async function pararGravacao() {
    try {
      const [buffer, blob] = await recorder.stop().getMp3()
      const file = new File(buffer, "audio.mp3", {
        type: blob.type,
        lastModified: Date.now()
      });
      setAudioGravado(file)
      setRecording(false)
    } catch (error) {
      filesUrl = [];
    }

  }

  const handleStartRecording = async () => {
    setLoadingAudio(true);
    try {
      await navigator.mediaDevices.getUserMedia({ audio: true });
      await recorder.start();
      setRecording(true);
      setLoadingAudio(false);
    } catch (err) {
      console.log(err);
      setLoadingAudio(false);
    }
  };

  async function uploadAudioAndGetUrl(file) {
    if (file) {
      var formData = new FormData();
      var audiofile = file;
      formData.append("file", audiofile);
      try {
        let querys = `?path=plataforma/resposta-rapida`
        const response = await v2_actionUploadS3(formData, querys)
        if (!response.data.error) {
          return { key: response.data.data.key, type: file.type, tipo: "audio" };
        }
      } catch (error) {
        console.log('erro ao upload arquivo', error)
      }


    }
  }

  const handleUploadAudio = async () => {
    const response_upload = await uploadAudioAndGetUrl(audioGravado);
    filesUrl.push(response_upload)
  };

  async function handleAceitar() {
    try {
      await getActionAceitarChat({ chatId: chat._id, usuarioId: user.id, action: "supervisao" });
      dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 100, true));
      enqueueSnackbar('Chat aceito com sucesso', { variant: 'success' });
      
    } catch (error) {
      console.log(error)
    }
  };

  const handleChangeClienteIdPadrao = (e) => {
    setClienteIdPadrao(e.target.value)
  }



  return (
    <RootStyle
      className='bg-slate-100 w-full'

      {...other}>
      {!isDesktop && (
        <Tooltip title={sidebarLeft ? "Esconder detalhes" : "Mostrar detalhes do chat"}>
          <Button
            variant="outline"
            size="icon"
            onClick={() => changeOpenSidebarLeft()}>
            <Iconify width={10} icon={sidebarLeft ? "ep:arrow-right-bold" : "ep:arrow-left-bold"} />
          </Button>
        </Tooltip>
      )}

      <OneAvatar
        chat={chat}
        chatIsOnline={chatIsOnline}
        handleOpenModalFunilChat={() => handleOpenModalFunilChat()}
      />

      <DrawerAgendamentos
        user={user}
        dataAgendamento={dataAgendamento}
        hora={hora}
        minuto={minuto}
        body={body}
        changeBody={e => setBody(e.target.value)}
        isFile={isFile}
        numero={numero}
        ddd={ddd}
        changeDDD={e => setDDD(e.target.value)}
        loadingEnviarArquivos={loadingEnviarArquivos}
        preview={preview}
        files={files}
        isAlert={isAlert}
        changeNumero={e => setNumero(e.target.value)}
        changeFile={handleChangeIsFile}
        changeData={newValue => {
          setDataAgendamento(newValue)
        }}
        changeHora={e => setHora(e.target.value)}
        changeMinuto={e => setMinuto(e.target.value)}
        handleDropMultiFile={handleDropMultiFile}
        handleRemove={handleRemove}
        onCriarAgendamento={onCriarAgendamento}
        handleRemoveAll={handleRemoveAll}
        drawerAgendamento={drawerAgendamento}
        onCloseDrawerAgendamento={onCloseDrawerAgendamento}
        isChatAtendimento={isChatAtendimento}
        onChangeIsChatAtendimento={event => setIsChatAtendimento(event.target.checked)}
        audioGravado={audioGravado}
        recording={recording}
        loading={loadingAudio}
        handleCancelAudio={handleCancelAudio}
        pararGravacao={pararGravacao}
        handleStartRecording={handleStartRecording}
        changeAudioGravado={() => setAudioGravado(null)}
        tipoArquivo={tipoArquivo}
        changeTipoArquivo={e => {
          setTipoArquivo(e.target.value)
        }}
        isMultisession={isMultisession}
        listaClientesCanais={listaClientesCanais}
        clienteIdPadrao={clienteIdPadrao}
        handleChangeClienteIdPadrao={handleChangeClienteIdPadrao}

      />

      <ModalFinalizarChat
        stateModalFinalizarChatProps={stateModalFinalizarChat}
        closeModalFinalizarChat={handleCloseModalFinalizarChat}
        finalizarChat={(value) => {
          console.log('value', value)
          handleFinalizarChat(chat._id, value, "Finalizado")
        }}
        user={user}
        loadingFinalizarChat={loadingFinalizarChat}
      />

      <ModalFunilChat
        closeModalFunilChat={handleCloseModalFunilChat}
        valorEscolhidoFunilProps={valorEscolhidoFunil}
        handleFunil={event => handleEscolherFunil(event)}
        listaFunilProps={listaFunil || []}
        stateModalFunilProps={stateModalFunil}
        submitFunilChat={handleAdicionarNoFunil}
      />

      <ModalTransferirChat
        stateModalTransferirChatProps={stateModalTransferirChat}
        closeModalTransferirChat={handleCloseModalTransferirChat}
        actionTipoTransferencia={event => handleChangeTipoTransferirChat(event)}
        valueTransferirChat={valueTransferirChat}
        listaUsuariosProps={listaUsuariosTransferencia}
        listaSetoresProps={listaSetoresTransferencia}
        valorEscolhidoSetorProps={valorEscolhidoSetor}
        valorEscolhidoUsuarioProps={valorEscolhidoUsuario}
        handleSetor={event => handleEscolherSetor(event)}
        handleUsuario={event => handleEscolherUsuario(event)}
        submitTranferirChat={value => handleTransferirChat(value)}
        user={user}
        loadingTransferirChat={loadingTransferirChat}
        onlineUsers={onlineUsers}
      />

      <Box sx={{ flexGrow: 1 }} />
      {!isDesktop ? (
        <>
          {/* <IconButton
            aria-label="more"
            aria-controls="long-menu"
            aria-haspopup="true"
            onClick={handleClick}
          >
            <FiMoreVertical />
          </IconButton>
          <Menu
            id="more"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenModalTransferirChat}>Transferir</MenuItem>
            <MenuItem onClick={handleOpenModalFinalizarChat}>Finalizar</MenuItem>
            <MenuItem onClick={handleOpenModalFunilChat}>
              {chat.nome_funil ? (
                <Button onClick={handleOpenModalFunilChat} startIcon={<BsFunnelFill />} size="small" variant="outlined">{chat.nome_funil}</Button>
              ) : (
                <Button onClick={handleOpenModalFunilChat} size="small" variant="contained">Adicionar Funil</Button>
              )}</MenuItem>
          </Menu> */}
        </>
      ) : (
        <Stack direction={'row'} spacing={1}>

          {/* <Select
            size="small"
            placeholder='Escolha um funil'
            sx={{ minWidth: 100 }}
            value={valorEscolhidoFunil}
            onChange={event => handleAdicionarNoFunil(event.target.value)}

          >

            {listaFunil.map((option) => (
              <MenuItem key={option._id} value={option._id}>
                {option.nome_funil}
              </MenuItem>

            ))}
          </Select> */}

          {user.level === 'root' && (
                <Tooltip title="Pegar Chat">
                <Button
                  variant="outline"
                  size="icon"
                  onClick={() => handleAceitar()}
                >
                  <Iconify icon="fa-solid:hand-rock" />
                </Button>
              </Tooltip>
          )}

          {/* {user.level === 'root' && direcionaUsuarios && !direcionaUsuarios.includes(user.id) && (
            <Tooltip title="Pegar Chat">
              <Button
                variant="outline"
                size="icon"
                onClick={() => handleAceitar()}
              >
                <Iconify icon="fa-solid:hand-rock" />
              </Button>
            </Tooltip>

          )} */}

          <Tooltip title="Transferir Chat">
            <Button
              variant="outline"
              size="icon"
              onClick={handleOpenModalTransferirChat}>
              <Iconify icon="mingcute:transfer-fill" />
            </Button>
          </Tooltip>


          {chat.canal === "whatsapp" && (
            <Tooltip title="Agendar Mensagem">
              <Button
                variant="outline"
                size="icon"
                onClick={handleOpenDrawerAgendamentos}>
                <Iconify icon="mdi:calendar-clock" />
              </Button>
            </Tooltip>
          )}


          {chat.statusRoom !== "Finalizado" && (
            <Tooltip title="Finalizar Chat">
              <Button
                variant="outline"
                size="icon"
                onClick={handleOpenModalFinalizarChat}>
                <Iconify icon="mdi:chat-remove" />
              </Button>
            </Tooltip>
          )}

          <Tooltip title={openSidebar ? "Esconder detalhes" : "Mostrar detalhes do chat"}>
            <Button
              variant="outline"
              size="icon"
              onClick={() => changeOpenSidebar(!openSidebar)}>
              <Iconify icon={openSidebar ? "ep:arrow-right-bold" : "ep:arrow-left-bold"} />
            </Button>
          </Tooltip>

        </Stack>
      )}


    </RootStyle>
  );
}
