
import {
    Box,
    Stack,
    Typography,
    Button,
    ListItemButton,
    Autocomplete,
    Alert,
    TextField
} from '@mui/material';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyleMultline from 'src/components/InputStyleMultline';
import { useEffect, useState } from 'react';
import v1_actionEditarFlow from 'src/actions/v1/clientes/v1_actionEditarFlow';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';
import ChannelDialogflow from './TypesChannels/ChannelDialogflow';
import ChannelLaisAi from './TypesChannels/ChannelLaisAi';
import Scrollbar from 'src/components/Scrollbar';
import ChannelDirect from './TypesChannels/ChannelDirect';
import getActionClienteByClienteId from 'src/actions/clientes/getActionClienteByClienteId';
import ChannelLaisAiV2 from './TypesChannels/ChannelLaisAiV2';
import { Input } from 'src/@/components/ui/input';
import {
    Card,
    CardContent,
    CardDescription,
    CardFooter,
    CardHeader,
    CardTitle,
} from 'src/@/components/ui/card';
import { Label } from 'src/@/components/ui/label';
import { ZuiTooltip } from 'src/@/components/use/tooltip';
import { Switch } from 'src/@/components/ui/switch';
import { Textarea } from 'src/@/components/ui/textarea';

import {
    Tabs,
    TabsContent,
    TabsList,
    TabsTrigger,
} from "src/@/components/ui/tabs"

const choices = [
    {
        id: 0,
        name: 'Lais',
        description: 'Agente simples de IA',
        value: 'laisai'
    },
    {
        id: 1,
        name: 'Lais v2',
        description: 'Agente simples de IA',
        value: 'laisai_v2'
    },
    {
        id: 2,
        name: 'Direcionamento Direto',
        description: 'Direcionamento direto para atendimento humano ou bot',
        value: 'direct'
    },
    // {
    //     id: 2,
    //     name: 'Studio',
    //     description: 'Crie fluxos complexos de atendimento',
    //     value: 'flowbuilder'
    // },
    {
        id: 3,
        name: 'DialogFlow',
        description: 'Integração com DialogFlow',
        value: 'dialogflow_basic'
    },
    {
        id: 4,
        name: 'Customizado',
        description: 'Crie fluxos complexos de atendimento',
        value: 'flowbuilder'
    },

    // {
    //     id: 2,
    //     name: 'Api Whatsapp',
    //     description: 'Integre seu sistema com nossa api',
    //     value: 'api'
    // }
]


const channelsList = [
    {
        id: 1,
        name: 'Whatsapp',
        description: 'Direcionamento direto para atendimento humano ou bot',
        value: 'whatsapp',
        icon: 'logos:whatsapp-icon',
        disabled: false
    },
    // {
    //     id: 0,
    //     name: 'Instagram (em breve)',
    //     description: 'Agente simples de IA',
    //     value: 'instagram',
    //     icon: 'skill-icons:instagram',
    //     disabled: true
    // }
]

const ItemChoiceChannel = ({ item, selectedIndex, index, onChange }) => {
    // console.log('selectedIndex', selectedIndex)
    // console.log('index', index)
    // console.log('item', item)

    return (
        <ListItemButton
            sx={{
                border: selectedIndex === index ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
                borderRadius: 1,
                background: selectedIndex === index ? '#f5f5f5' : '#fff'
            }}
            selected={selectedIndex === index}
            // disabled={selectedIndex === index}
            onClick={(event) => onChange(event, index)}
        >
            <Stack direction={"row"} spacing={2} alignItems={"center"}>
                {selectedIndex === index ?
                    <Iconify sx={{ color: 'primary.main' }} icon={"el:ok-circle"} width={14} height={14} />
                    :
                    <Iconify color="green" icon={"material-symbols:circle-outline"} width={16} height={16} />
                }
                {/* <ListItemAvatar
                sx={{ width: 40, height: 40 }}
            >
            </ListItemAvatar> */}
                <Stack>
                    <Typography variant="body2" color="text.secondary">{item.name}</Typography>
                </Stack>
            </Stack>
        </ListItemButton>
    )
}


const ItemChannel = ({ item, selectedIndex, index, onChange }) => {
    return (
        <ListItemButton
            disabled={item.disabled}
            sx={{
                border: selectedIndex === index ? '1px solid #e0e0e0' : '1px solid #e0e0e0',
                borderRadius: 1,
                background: selectedIndex === index ? '#f5f5f5' : '#fff',
                maxWidth: 300
            }}
            selected={selectedIndex === index}
            // disabled={selectedIndex === index}
            onClick={(event) => onChange(event, index)}
        >
            <Stack direction={"row"} spacing={1} alignItems={"center"}>
                <Iconify icon={item.icon} width={22} height={22} />
                <Stack>
                    <Typography variant="body2" color="text.secondary">{item.name}</Typography>
                </Stack>

            </Stack>
        </ListItemButton>
    )
}


export default function CanaisConfig({
    isEdit,
    canal,
    onChange,
    onChangeChannel,
    onChangeAutocomplete,
    listUsers,
    listSectors,
    config,
    onSubmit,
    cliente,
    user,
    changeAgent
}) {

    const { enqueueSnackbar } = useSnackbar();
    const [selectedIndex, setSelectedIndex] = useState(0);
    const [selectedChannelIndex, setSelectedChannelIndex] = useState(0);


    return (
        <Scrollbar sx={{
            height: 'calc(90vh - 100px)'
        }}
        >
            <Stack sx={{ p: 2 }} spacing={3}>

                <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                    <Stack spacing={{ xs: 2, md: 3 }}>
                        <Box sx={{ width: '100%' }}>

                            <Stack direction="row" spacing={2}>
                                <RowInput
                                    sx={{ width: '30%' }}
                                    label="nome do canal"
                                    input={<InputStyle
                                        size="small"
                                        value={canal?.nome}
                                        fullWidth
                                        name="channelName"
                                        onChange={onChange}
                                    />}
                                />
                                {/* <RowInput
                                    sx={{ width: '30%' }}
                                    label="número"
                                    input={<InputStyle
                                        size="small"
                                        value={canal?.numero}
                                        fullWidth
                                        name="numeroWhatsapp"
                                        onChange={onChange}
                                    />}
                                /> */}


                            </Stack>
                        </Box>

                    </Stack>
                </Box>

                {/* <Divider sx={{ borderStyle: 'dashed', borderColor: '#ccc' }} /> */}


                <Card className="py-5">
                    <CardContent>
                        <RowInput
                            label={"canal"}
                            input={<Stack sx={{ my: 2 }} spacing={1} direction={"row"}>
                                {channelsList.map((item, index) => (
                                    <ItemChannel
                                        item={item}
                                        selectedIndex={canal?.platform}
                                        index={item.value}
                                        onChange={() => {
                                            setSelectedChannelIndex(index);
                                            onChangeChannel({ target: 'channelType', value: item.value })
                                        }}
                                    />
                                ))}
                            </Stack>}
                        />
                    </CardContent>
                </Card>





                <Card className="py-5">
                    <CardContent>



                        <div className='flex flex-col gap-12'>

                            <div className="flex items-center space-x-2">
                                <Switch
                                    checked={config?.laisGo?.status}
                                    onCheckedChange={(value) => {
                                        let e = {
                                            target: {
                                                name: 'laisGo.status',
                                                value: value,
                                            },
                                        };
                                        onChange(e);
                                    }
                                    }
                                    id="airplane-mode" />
                                <Label htmlFor="airplane-mode">Silenciar IA com etiqueta</Label>

                                <ZuiTooltip content="Silencia IA ao adicionar uma etiqueta na conversa do whatsapp(aparelho)">
                                    <Box>
                                        <Iconify icon={'ph:info-bold'} width={18} height={18} />
                                    </Box>
                                </ZuiTooltip>
                            </div>
                            <RowInput
                                label={"configuração do canal"}
                                input={<Stack sx={{ my: 2 }} spacing={1} direction={"row"}>
                                    {choices.map((item, index) => (
                                        <ItemChoiceChannel
                                            item={item}
                                            selectedIndex={canal?.webhook_type}
                                            index={item.value}
                                            onChange={() => {
                                                setSelectedIndex(index);
                                                onChangeChannel({ target: 'webhookType', value: item.value });
                                            }}
                                        />
                                    ))}
                                </Stack>}
                            />



                            <div>
                                {canal?.webhook_type === 'laisai' && isEdit && (
                                    <ChannelLaisAi
                                        onChange={onChange}
                                        canal={canal}
                                        config={config}
                                        listUsers={listUsers}
                                        listSectors={listSectors}
                                    />
                                )}

                                {canal?.webhook_type === 'laisai_v2' && isEdit && (
                                    <ChannelLaisAiV2
                                        onChange={onChange}
                                        canal={canal}
                                        config={config}
                                        listUsers={listUsers}
                                        listSectors={listSectors}
                                        user={user}
                                        cliente={cliente}
                                        changeAgent={changeAgent}
                                    />
                                )}

                                {canal?.webhook_type === 'direct' && isEdit && (
                                    <ChannelDirect
                                        listUsers={listUsers}
                                        listSectors={listSectors}
                                        canal={canal}
                                        onChange={onChange}
                                        onChangeAutocomplete={onChangeAutocomplete}
                                    />
                                )}

                                {canal?.webhook_type === 'dialogflow_basic' && isEdit && (
                                    <ChannelDialogflow
                                        canal={canal}
                                        onChange={onChange}
                                        listUsers={listUsers}
                                        listSectors={listSectors}
                                    />
                                )}
                            </div>
                        </div>



                    </CardContent>
                </Card>






                {
                    isEdit && (
                        <Card className="py-5">
                            <CardContent>

                                <Tabs defaultValue="finish">
                                    <TabsList className="grid w-full grid-cols-5 bg-slate-200">
                                        <TabsTrigger value="finish">Finalização</TabsTrigger>
                                        <TabsTrigger value="transfer">Transferência de chat</TabsTrigger>
                                        <TabsTrigger value="feedback">Pesquisa satisfação</TabsTrigger>
                                        <TabsTrigger value="welcome">Mensagem Boas Vindas</TabsTrigger>
                                        <TabsTrigger value="afterTarget">Direcionamento</TabsTrigger>


                                    </TabsList>
                                    <TabsContent value="finish">

                                        <div className='flex flex-col gap-4 mt-8'>
                                            <h4 className="scroll-m-20 text-md font-semibold tracking-tight">
                                                Finalização de chat
                                            </h4>
                                            <div className="flex items-center space-x-2">
                                                <Switch
                                                    checked={config?.generalConfig?.msgFinish}
                                                    onCheckedChange={(value) => {
                                                        let e = {
                                                            target: {
                                                                name: 'generalConfig.msgFinish',
                                                                value: value,
                                                            },
                                                        };
                                                        onChange(e);
                                                    }
                                                    }
                                                    id="airplane-mode" />
                                                <Label htmlFor="airplane-mode">Enviar mensagem finalização de chat</Label>

                                                <ZuiTooltip content="Mensagem enviada ao cleinte após finalização de um chat">
                                                    <Box>
                                                        <Iconify icon={'ph:info-bold'} width={18} height={18} />
                                                    </Box>
                                                </ZuiTooltip>

                                            </div>
                                            <RowInput
                                                sx={{ width: '100%' }}
                                                label="Mensagem finalização chat"
                                                onChange={() => { }}
                                                input={<Textarea
                                                    multiline
                                                    rows={5}
                                                    size="small"
                                                    onChange={onChange}
                                                    name="generalConfig.msgs.finish"
                                                    value={config?.generalConfig?.msgs?.finish}
                                                    fullWidth
                                                />}
                                            />
                                        </div>

                                    </TabsContent>
                                    <TabsContent value="transfer">
                                        <div className='flex flex-col gap-4 mt-8'>
                                            <h4 className="scroll-m-20 text-md font-semibold tracking-tight">
                                                Transferência de chat
                                            </h4>
                                            <div className="flex items-center space-x-2">
                                                <Switch
                                                    checked={config?.generalConfig?.msgTransfer}
                                                    onCheckedChange={(value) => {
                                                        let e = {
                                                            target: {
                                                                name: 'generalConfig.msgTransfer',
                                                                value: value,
                                                            },
                                                        };
                                                        onChange(e);
                                                    }
                                                    }
                                                    id="airplane-mode" />
                                                <Label htmlFor="airplane-mode">Enviar mensagem de transferência de chat</Label>

                                                <ZuiTooltip content="Mensagem enviada ao cliente após ser transferido para um setor ou atendente">
                                                    <Box>
                                                        <Iconify icon={'ph:info-bold'} width={18} height={18} />
                                                    </Box>
                                                </ZuiTooltip>

                                            </div>
                                            <RowInput
                                                sx={{ width: '100%' }}
                                                label="Mensagem Transferência de chat"
                                                info="Use {{nome}} para nome do atendente ou setor a ser transferido e/ou {{nome_atendente}} para nome do atendente do chat"
                                                input={<Textarea
                                                    multiline
                                                    rows={5}
                                                    size="small"
                                                    name="generalConfig.msgs.transfer"
                                                    onChange={onChange}
                                                    value={config?.generalConfig?.msgs?.transfer}

                                                />}
                                            />
                                        </div>
                                    </TabsContent>
                                    <TabsContent value="feedback">
                                        <div className='flex flex-col gap-4 mt-8'>
                                            <h4 className="scroll-m-20 text-md font-semibold tracking-tight">
                                                Pesquisa de satisfação
                                            </h4>
                                            <div className="flex items-center space-x-2">
                                                <Switch
                                                    checked={config?.generalConfig?.feedback}
                                                    onCheckedChange={(value) => {
                                                        let e = {
                                                            target: {
                                                                name: 'generalConfig.feedback',
                                                                value: value,
                                                            },
                                                        };
                                                        onChange(e);
                                                    }
                                                    }
                                                    id="airplane-mode" />
                                                <Label htmlFor="airplane-mode">Enviar pesquisa de satisfação ao cliente</Label>

                                                <ZuiTooltip content="Envio de pesquisa de satisfação ao cliente após uma finalização de chat">
                                                    <Box>
                                                        <Iconify icon={'ph:info-bold'} width={18} height={18} />
                                                    </Box>
                                                </ZuiTooltip>

                                            </div>
                                            <RowInput
                                                sx={{ width: '100%' }}
                                                label="Mensagem da pesquisa"
                                                input={<Textarea
                                                    multiline
                                                    rows={5}
                                                    size="small"
                                                    name="generalConfig.msgs.feedback"
                                                    onChange={onChange}
                                                    value={config?.generalConfig?.msgs?.feedback}
                                                    fullWidth
                                                />}
                                            />
                                        </div>
                                    </TabsContent>
                                    <TabsContent value="welcome">
                                        <div className='flex flex-col gap-4 mt-8'>
                                            <h4 className="scroll-m-20 text-md font-semibold tracking-tight">
                                                Mensagem Boas Vindas
                                            </h4>

                                            <Textarea
                                                multiline
                                                rows={5}
                                                size="small"
                                                value={config?.generalConfig?.msgs?.welcome}
                                                name="generalConfig.msgs.welcome"
                                                onChange={onChange}
                                                fullWidth
                                            />
                                        </div>
                                    </TabsContent>
                                    <TabsContent value="afterTarget">
                                        <div className='flex flex-col gap-4 mt-8'>
                                            <h4 className="scroll-m-20 text-md font-semibold tracking-tight">
                                                Mensagem após direcionamento
                                            </h4>

                                            <Textarea
                                                multiline
                                                rows={5}
                                                size="small"
                                                value={config?.generalConfig?.msgs?.afterTarget}
                                                name="generalConfig.msgs.afterTarget"
                                                onChange={onChange}
                                                fullWidth
                                            />
                                        </div>
                                    </TabsContent>
                                </Tabs>
                            </CardContent>
                        </Card>
                    )
                }


                {!isEdit && (
                    <Stack direction={"row"} sx={{ mt: 2 }}>
                        <Button
                            onClick={onSubmit}
                            sx={{ width: '20%', }}
                            variant='contained'
                            color="success"
                            size="large"
                            startIcon={<Iconify icon="majesticons:save-line" color="white" width={20} height={20} />}
                        >
                            <Typography variant="body2" sx={{ fontWeight: 600, textTransform: 'lowercase', color: "#fff" }}>
                                {isEdit ? 'salvar' : 'criar canal'}
                            </Typography>
                        </Button>
                    </Stack>
                )}


            </Stack>
        </Scrollbar>

    );
}
