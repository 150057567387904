
import { Tab, Box, Tabs, Stack, Container } from '@mui/material';
import { Typography } from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import ConfigFlow from './tabs/MessageConfig';
import v1_actionEditarFlow from 'src/actions/v1/clientes/v1_actionEditarFlow';
import { useSnackbar } from 'notistack';
import TrainingConfig from './tabs/TrainingConfig';
import { Button } from 'src/@/components/ui/button';

export default function FlowDetails({ flow }) {
    const [currentTab, setCurrentTab] = useState('config');
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();


    const ACCOUNT_TABS = [
        {
            value: 'config',
            name: 'Configurações',
            icon: <Iconify icon="streamline:ai-settings-spark-solid" width={20} height={20} />,
            component: <ConfigFlow
                flow={flow}
            />
        },
        {
            value: 'treinamento',
            name: 'Treinamento',
            icon: <Iconify icon="eos-icons:machine-learning-outlined" width={20} height={20} />,
            component: <TrainingConfig 
            flow={flow}
            />
        },


    ];

    const handleChangeTab = (event, newValue) => {
        setCurrentTab(newValue);
    };

 

    return (
        <Container maxWidth="lg">

                <Stack spacing={3}>
                    <Box sx={{ width: '100%' }}>
                        <Stack direction={"row"} justifyContent={"space-between"}>
                            <Stack spacing={1} alignItems={"center"} direction={"row"}>
                       
                            <Button
                                    size='icon'
                                    variant='outline'
                                    onClick={
                                        () => {
                                            navigate('/dashboard/configuracoes/flows')
                                        }}
                                >
                                    <Iconify icon="majesticons:arrow-left" color="black" />
                    
                                </Button>
                                <p>voltar</p>
                            </Stack>
                      
                        </Stack>
                    </Box>
                    <Stack sx={{ mt: 1 }} spacing={2}>
                        <Tabs
                            value={currentTab}
                            scrollButtons="auto"
                            variant="scrollable"
                            allowScrollButtonsMobile
                            onChange={handleChangeTab}
                        >
                            {ACCOUNT_TABS.map((tab) => (
                                <Tab disableRipple key={tab.value} label={tab.name} icon={tab.icon} value={tab.value} />
                            ))}
                        </Tabs>
                        <Stack sx={{ mt: 3 }}>
                            {ACCOUNT_TABS.map((tab) => {
                                const isMatched = tab.value === currentTab;
                                return isMatched && <Box key={tab.value}>{tab.component}</Box>;
                            })}
                        </Stack>
                    </Stack>

                  
                </Stack>
        </Container>
    );
}
