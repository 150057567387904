// @mui
import { Stack, Skeleton, Box, Paper } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonKanbanCrm() {
  return (
    <Box

    >
      {[...Array(2)].map((_, index) => (
        <Box sx={{mt:2,}} variant="outlined" key={index}>
          <Stack direction="column" spacing={3}>
            <Skeleton height={100} animation="wave" variant="rectangular" sx={{ borderRadius: 0.5,    backgroundColor: theme => theme.palette.grey[0] }} />
          </Stack>
        </Box>
      ))}
    </Box>
  );
}
