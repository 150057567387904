import moreVerticalFill from '@iconify/icons-eva/more-vertical-fill';
import { Icon } from '@iconify/react';
// material
import { Menu, MenuItem, IconButton, ListItemIcon, ListItemText } from '@mui/material';
import { useRef, useState } from 'react';

// routes

import { FaUserEdit } from 'react-icons/fa';
import { MdDeleteForever } from 'react-icons/md';




// ----------------------------------------------------------------------


export default function AgendamentosMoreMenu({ onEdit, onDelete }) {
  const ref = useRef(null);
  const [isOpenMenu, setIsOpenMenu] = useState(false);

  const clickEdit = () => {
    setIsOpenMenu(false);
    onEdit()
  }


  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpenMenu(true)}>
        <Icon icon={moreVerticalFill} width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpenMenu}
        anchorEl={ref.current}
        onClose={() => setIsOpenMenu(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        {/* <MenuItem onClick={onDelete} sx={{ color: 'text.secondary' }}>
          <ListItemIcon>
            <Icon icon={trash2Outline} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Delete" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem> */}

       <MenuItem
          onClick={clickEdit}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <FaUserEdit />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>

        <MenuItem
          onClick={onDelete}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <MdDeleteForever />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}
