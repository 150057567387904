import axios from "axios";

import Config from "../../config";

const actionDescontinuarcampanha = campanhaId => {
    return axios({
        method: "delete",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/campanhas/${campanhaId}/deletar`
    });
};

export default actionDescontinuarcampanha;
