import sumBy from 'lodash/sumBy';
import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useLocation, useNavigate, useS } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import {
  Box,
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Switch,
  Button,
  Tooltip,
  Divider,
  TableBody,
  Container,
  IconButton,
  TableContainer,
  TablePagination,
  FormControlLabel,
  TextField,
  InputBase,
  Paper,
  FormControl,
  MenuItem,
} from '@mui/material';
// routes
import { PATH_DASHBOARD } from 'src/routes/paths';
// hooks
import useTabs from 'src/hooks/useTabs';
import useSettings from 'src/hooks/useSettings';
import useAuth from 'src/hooks/useAuth';
import useTable, { getComparator, emptyRows } from 'src/hooks/useTable';

// components
import Page from 'src/components/Page';
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { TableEmptyRows, TableHeadCustom, TableNoData, TableSelectedActions } from 'src/components/table';
// sections
import ToolbarMeusChamados from 'src/sections/@dashboard/tickets/ToolbarMeusChamados';
import ChamadosRowTable from 'src/sections/@dashboard/tickets/ChamadosRowTable';

import { useDispatch, useSelector } from 'src/redux/store';
import actionListaTicketsPorUsuario from 'src/actions/chamados/actionListaTicketsPorUsuario';

//components
import { changeOpenDetalhesChamado, getListaColunas, getListaTickets, getVerChamado } from 'src/redux/slices/chamados';
import actionConfiguracoesOrganizacao from 'src/actions/clientes/actionConfiguracoesOrganizacao';
import { NovoChamado } from 'src/sections/@dashboard/tickets/ChamadosNovoChamado';
import actionCriarChamado from 'src/actions/chamados/actionCriarChamado';
import MenuDatas from 'src/components/MenuDatas';
import { validaLabelMenuDatas } from 'src/utils/functions';
import { getListaUsuariosTransferencia } from 'src/redux/slices/user';
import moment from 'moment';
import InputStyle from 'src/components/InputStyle';


// ----------------------------------------------------------------------


const STATUS_OPTIONS = [
  { value: null, label: "Todos" },
  { value: 'aberto', label: "Abertos" },
  { value: 'pendente', label: "Pendentes" },
  { value: 'concluido', label: "Concluídos" },
  { value: 'recusado', label: "Recusados" },
  { value: 'cancelado', label: "Cancelados" }
];


const TABLE_HEAD = [
  { id: 'chamado', label: 'Chamado' },
  { id: 'assunto', label: 'Assunto' },
  { id: 'status', label: 'Status' },
  { id: 'data_cricao', label: 'Data Criação' },
  { id: 'data_para_concusao', label: 'Data Conclusão' },
  { id: 'ultima_modificacao', label: 'Ultima Modificação' },
 
  { id: 'nome_autor', label: 'Criado por' },
  { id: 'completado', label: 'Completado' },
  { id: '' },
];

// ----------------------------------------------------------------------

export default function MeusChamados() {
  const theme = useTheme();

  const { themeStretch } = useSettings();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'date_created', defaultRowsPerPage: 25 });


  const { user } = useAuth();
  const { listaUsuariosTransferencia } = useSelector((state) => state.user);

  const [filterName, setFilterName] = useState('');
  const [filterPesquisa, setFilterPesquisa] = useState('');
  const { currentTab: filterStatus, onChangeTab: onChangeFilterStatus } = useTabs(null);
  const [inicio, setInicio] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const [config, setConfig] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  // NOVO CHAMADO

  const [assunto, setAssunto] = useState('');
  const [descricao, setDescricao] = useState('');
  const [prioridade, setPrioridade] = useState('');
  const [arquivos, setArquivos] = useState([]);
  const [direcionadoPara, setDirecionadoPara] = useState('');
  const [direcionadoSetores, setDirecionadoSetores] = useState('');
  const [tipoLocal, setTipoLocal] = useState('chamado_interno');
  const [setorOuUsuario, setSetorOuUsuario] = useState('');
  const [setorEscolhido, setSetorEscolhido] = useState('');
  const [usuarioEscolhido, setUsuarioEscolhido] = useState('');
  const [filialEscolhido, setFilialEscolhido] = useState('');
  const [estimativa, setEstimativa] = useState('');
  const [files, setFiles] = useState([]);
  const filesUrl = [];
  const [preview, setPreview] = useState(true);
  const [isFile, setIsFile] = useState(false);
  const [modalAssunto, setModalAssunto] = useState(false);
  const [emCopia, setEmCopia] = useState([]);
  const [valorEscolhidoUsuario, setValorEscolhidoUsuario] = useState("");
  const [isPesquisado, setIsPesquisado] = useState(false);
  const [periodo, setPeriodo] = useState('mes_atual')
  const [anchorEl, setAnchorEl] = useState(null);

  const openMenuDatas = Boolean(anchorEl);
  const [listaPesquisa, setListaPesquisa] = useState([]);
  const [listaFiltro, setListaFiltro] = useState([]);
  const tableData = isPesquisado ? listaPesquisa : listaFiltro



  async function handleOpenDetails(card) {
    dispatch(getVerChamado(card._id));
    dispatch(changeOpenDetalhesChamado(true))
  };


  const handleDropMultiFile = useCallback(
    (acceptedFiles) => {
      setFiles(acceptedFiles.map((file) => Object.assign(file, { preview: URL.createObjectURL(file) })));
    },
    [setFiles]
  );

  const handleRemove = (file) => {
    const filteredItems = files.filter((_file) => _file !== file);
    setFiles(filteredItems);
  };



  async function buscarChamados(value, action) {
     setPeriodo(value)
    if (value === 'pesquisar' && !filterPesquisa) {
      alert("Campo de pesquisa está vazio!");
      return;
    }

    try {
      let inicio2 = moment(inicio).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = `?periodo=${value}&cliente_id=${user.clienteId}&usuario_id=${user.id}&inicio=${inicio2}&final=${final2}&action=${value}&search=${filterPesquisa}`
      const response = await actionListaTicketsPorUsuario(querys)
      if(value === 'pesquisar') {
        setListaPesquisa(response.data.data);
      } else {
        setListaFiltro(response.data.data);
      }
    
    } catch (error) {
      console.log(error)
    }

  }


  useEffect(() => {
    buscarChamados('mes_atual')
    dispatch(getListaUsuariosTransferencia(user.clienteId));
  }, [location]);


  const handleFilterName = (filterName) => {
    setFilterName(filterName);
    // getListaPedidosVendaFacil()
    setPage(0);
  };

  const handlePesquisa = (e) => {
    setFilterPesquisa(e.target.value);
  };



  const onPesquisar = () => {
    buscarChamados('pesquisar');
    setIsPesquisado(true)
  }

  const onFiltrar = (value) => {
    
    setPeriodo(value)
    setFilterPesquisa('')
    buscarChamados(value);
  }


  function limparCampos() {
    setAssunto('');
    setDescricao('');
    setPrioridade('');
    setArquivos([]);
    setDirecionadoPara('');
    setTipoLocal('');
    setDirecionadoSetores('');
    setSetorOuUsuario('');
    setSetorEscolhido('');
    setUsuarioEscolhido('');
    setFilialEscolhido('');
    setEmCopia([])
    setEstimativa('')
  }

  const onCriarChamado = async () => {
    setLoading(true)
    let data = {
      assunto: assunto,
      descricao: descricao,
      criadoPor: user.id,
      prioridade: estimativa,
      copiadoPara: emCopia.length > 0 ? emCopia.map(item => item._id) : [],
      arquivos: filesUrl,
      direcionadoPara: setorOuUsuario === 'usuario' ? usuarioEscolhido._id : null,
      direcionadoFilial: filialEscolhido ? filialEscolhido.codigo_filial : null,
      direcionadoSetores: setorEscolhido ? setorEscolhido._id : null
    }

    try {
      if (files.length > 0) await uploadFilesAndGetUrl(files);
      await actionCriarChamado(data);
      setIsOpen(false);
      dispatch(getListaTickets({ setorId: user.setor_id, usuarioId: user.id, filial: user.filial }));
      dispatch(getListaColunas({ setorId: user.setor_id, usuarioId: user.id, filial: user.filial }));
      setLoading(false)
    } catch (error) {
      console.log('error', error)
      setLoading(false)
    }

  }

  async function uploadFilesAndGetUrl(files) {
    if (files.length > 0) {
      for (let file of files.map(item => item)) {
        var formData = new FormData();
        var imagefile = file;
        formData.append("file", imagefile);
        try {
          let querys = `?path=chamados/clientes/${user.clienteId}/anexos`
          const response = await v2_actionUploadS3(formData, querys)
          if (!response.data.error) {
            console.log('!response.data.error')
            filesUrl.push({ key: response.data.data.key, type: file.type, name: file.name, size: file.size })
          }
        } catch (error) {
          console.log('erro ao upload arquivo', error)
        }
      }
    }
  }


  async function buscarConfiguracoes() {
    try {
      const response = await actionConfiguracoesOrganizacao(user.marketplaceId);
      setConfig(response.data.data)
    } catch (error) {
      console.log('error', error)
    }
  }

  const handleOpenNovoChamado = async () => {
    await buscarConfiguracoes();
    setIsOpen(true);
  }

  const dataFiltered = applySortFilter({
    tableData,
    filterStatus,
    valorEscolhidoUsuario

  });

  const denseHeight = dense ? 56 : 76;

  const isNotFound =
    (!dataFiltered.length && !!filterName) ||
    (!dataFiltered.length && !!filterStatus)


  return (
    <Page sx={{mt:4}} title="Meus Chamados">

      <NovoChamado
        user={user}
        loading={loading}
        config={config}
        tipoLocal={tipoLocal}
        setorOuUsuario={setorOuUsuario}
        usuarioEscolhido={usuarioEscolhido}
        setorEscolhido={setorEscolhido}
        filialEscolhido={filialEscolhido}
        estimativa={estimativa}
        selectAssunto={list => selectAssunto(list)}
        changeEmCopia={newValue => setEmCopia(newValue)}
        changeEstimativa={e => setEstimativa(e.target.value)}
        changeTipoLocal={e => {
          setTipoLocal(e.target.value);
          if (e.target.value === 'loja') {
            setUsuarioEscolhido('');
            setSetorEscolhido('');
            setSetorOuUsuario('');
          } else if (e.target.value === 'backoffice') {
            setFilialEscolhido('');
          }
        }}
        changeSetorOuUsuario={e => {
          setSetorOuUsuario(e.target.value);
          if (e.target.value === 'setor') {
            setUsuarioEscolhido('');
          } else if (e.target.value === 'setor') {
            setSetorEscolhido('');
          }
        }}
        changeUsuarioEscolhido={newValue => {
          setUsuarioEscolhido(newValue);
        }}
        changeFilialEscolhido={newValue => {
          setFilialEscolhido(newValue);

        }}
        changeSetorEscolhido={newValue => {
          setSetorEscolhido(newValue)
        }}
        changeIsFile={e => setIsFile(e.target.checked)}
        modalAssunto={modalAssunto}
        closeModalAssunto={() => setModalAssunto(false)}
        openModalAssunto={() => setModalAssunto(true)}
        //
        emCopia={emCopia}
        isFile={isFile}
        preview={preview}
        files={files}
        assunto={assunto}
        descricao={descricao}
        prioridade={prioridade}
        arquivos={arquivos}
        direcionadoPara={direcionadoPara}
        direcionadoSetores={direcionadoSetores}
        changePrioridade={e => setPrioridade(e.target.value)}
        changeAssunto={e => setAssunto(e.target.value)}
        changeDescricao={value => setDescricao(value)}
        changeDirecionadoPara={e => setDirecionadoPara(e.target.value)}
        changeDirecionadoSetores={e => {
          setDirecionadoSetores(e.target.value)
        }}
        isOpen={isOpen}
        onClose={() => {
          limparCampos();
          setIsOpen(false);
        }

        }
        handleDropMultiFile={handleDropMultiFile}
        handleRemove={handleRemove}
        onCriarChamado={onCriarChamado}
      />


      <Container maxWidth={false} >
      </Container>


      <Container maxWidth={false} >
        <Stack spacing={5} alignItems='center' direction={"row"}>

          <Stack direction={'row'} >

            <Stack direction={'row'} spacing={1}>
              <Button size="small" variant={location.pathname === "/dashboard/chamados" ? 'contained' : 'outlined'} startIcon={<Iconify icon={"bi:kanban"} width={12} height={12} />} onClick={() => navigate(`/dashboard/chamados`)}>
                Kanban
              </Button>
              <Button size="small" variant={location.pathname === "/dashboard/meus-chamados" ? 'contained' : 'outlined'} startIcon={<Iconify icon={"icons8:list"} width={12} height={12} />} onClick={() => navigate(`/dashboard/meus-chamados`)}>
                Lista
              </Button>
            </Stack>


          </Stack>

          <MenuDatas
            anchorEl={anchorEl}
            open={openMenuDatas}
            onClose={() => setAnchorEl(false)}
            inicial={inicio}
            final={final}
            changeInicial={newValue => setInicio(newValue)}
            changeFinal={newValue => { setFinal(newValue) }}
            onSelect={value => {
              setPeriodo(value);
              setAnchorEl(null);
              onFiltrar(value)
            }}
            onPesquisar={() => onFiltrar('null')}
          />

          <Stack spacing={2} direction="row" alignItems="center" mb={1}>

            <Paper
              component="form"
              sx={{ p: '1px 4px', display: 'flex', alignItems: 'center' }}
            >
              <Button
                //variant="outlined"
                sx={{ p: '7px', minWidth: 170, textTransform: 'none' }}
                startIcon={<Iconify icon="solar:calendar-date-bold-duotone" />}
                endIcon={<Iconify icon="bxs:down-arrow" sx={{ width: 10, height: 10 }} />}
                onClick={event => setAnchorEl(event.currentTarget)}
              >
                {validaLabelMenuDatas(periodo, inicio, final)}
              </Button>

              <FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                <InputStyle
                  select
                  label="Filtrar por usuário"
                  fullWidth
                  size="small"
                  value={valorEscolhidoUsuario}
                  onChange={e => {

                    setValorEscolhidoUsuario(e.target.value);
                    console.log('filterUsuario 2', e.target.value)
                  }}
                >
                  <MenuItem value=""><em>Sem filtro</em> </MenuItem>
                  {listaUsuariosTransferencia.map((option) => (
                    <MenuItem key={option._id} value={option.nomeFantasia}>
                      {option.firstName}
                    </MenuItem>
                  ))}
                </InputStyle>
              </FormControl>
              {/* <FormControl fullWidth sx={{ m: 1, minWidth: 200 }}>
                <TextField
                  select
                  label="Filtrar por usuário"
                  fullWidth
                  size="small"
                  value={valorEscolhidoUsuario}
                  onChange={e => {

                    setValorEscolhidoUsuario(e.target.value);
                    console.log('filterUsuario 2', e.target.value)
                  }}
                >
                  <MenuItem value=""><em>Sem filtro</em> </MenuItem>
                  {listaUsuariosTransferencia.map((option) => (
                    <MenuItem key={option._id} value={option.nomeFantasia}>
                      {option.firstName}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl> */}
            </Paper>

            <Paper
              component="form"
              sx={{ p: '10px 4px', display: 'flex', alignItems: 'center', width: 200 }}
            >

              <InputStyle
                sx={{ ml: 1, flex: 1 }}
                placeholder="Pesquisar"
                size='small'
                value={filterPesquisa}
                onChange={e => {
                   setFilterPesquisa(e.target.value)
                }}
                inputProps={{ 'aria-label': 'pesquisar contato' }}
              />
              {isPesquisado && (
                <IconButton onClick={() => {
                  setIsPesquisado(false);
                  setFilterPesquisa('')
                }} type="button" sx={{ p: '7px' }} aria-label="search">
                  <Iconify width={15} icon="ooui:clear" />
                </IconButton>
              )}
              <IconButton onClick={onPesquisar} type="button" sx={{ p: '7px' }} aria-label="search">
                <Iconify icon="eva:search-outline" />
              </IconButton>
            </Paper>
          </Stack>
          {/* <Stack direction={'row'} spacing={1}>
            <Button size="small" variant='contained' startIcon={<Iconify icon={"gg:add"} width={12} height={12} />} onClick={handleOpenNovoChamado}>
              Criar Chamado
            </Button>
          </Stack> */}
        </Stack>
      </Container>


      <Container maxWidth={themeStretch ? false : 'lg'}>
        <Card sx={{ mt: 3 }}>

          <Tabs
            allowScrollButtonsMobile
            variant="scrollable"
            scrollButtons="auto"
            value={filterStatus}
            onChange={onChangeFilterStatus}
            sx={{ px: 2, mb: 2, bgcolor: 'background.neutral' }}
          >
            {STATUS_OPTIONS.map((tab) => (
              <Tab disableRipple key={tab.value} label={tab.label} value={tab.value} />
            ))}
          </Tabs>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>


              <Table size={dense ? 'small' : 'medium'}>
                <TableHeadCustom
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={tableData.length}
                  numSelected={selected.length}
                  onSort={onSort}
                  onSelectAllRows={(checked) =>
                    onSelectAllRows(
                      checked,
                      tableData.map((row) => row.id)
                    )
                  }
                />
                <TableBody>
                  {dataFiltered.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
                    <ChamadosRowTable
                      key={row.id}
                      row={row}
                      selected={selected.includes(row.id)}
                      onSelectRow={() => onSelectRow(row.id)}
                      onViewRow={() => handleOpenDetails(row)}
      
                    />
                  ))}

                  <TableEmptyRows height={denseHeight} emptyRows={emptyRows(page, rowsPerPage, dataFiltered.length)} />

                  <TableNoData isNotFound={isNotFound} />
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          <Box sx={{ position: 'relative' }}>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={dataFiltered.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={onChangePage}
              onRowsPerPageChange={onChangeRowsPerPage}
            />

            <FormControlLabel
              control={<Switch checked={dense} onChange={onChangeDense} />}
              label="Estreitar"
              sx={{ px: 3, py: 1.5, top: 0, position: { md: 'absolute' } }}
            />
          </Box>
        </Card>
      </Container>

    </Page>
  );
}

// ----------------------------------------------------------------------

function applySortFilter({
  tableData,
  filterStatus,
  valorEscolhidoUsuario

}) {



  if (filterStatus !== null) {
    tableData = tableData.filter((item) => item.status === filterStatus)
  }


  if (valorEscolhidoUsuario) {
    tableData = tableData.filter((item) => item.nome_autor === valorEscolhidoUsuario)
  }

  console.log('filterUsuario', valorEscolhidoUsuario, filterStatus)

  return tableData;
}
