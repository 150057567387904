// @mui
import { Card, Skeleton, Stack, Box } from '@mui/material';

export default function SkeletonChatImage() {


    return (
        <Box sx={{ width:'100%'}}>
            <Skeleton sx={{height:330, borderRadius:0.5}}  variant="rectangular" />
        </Box>
    );
}
