// @mui
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

const InputStyleMultline = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'stretchStart',
})(({ stretchStart, theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    '&.Mui-focused': {
      borderColor: `${theme.palette.primary.main} !important`,
    },
    ...(stretchStart && {
      width: stretchStart,
      '&.Mui-focused': {
        boxShadow: theme.customShadows.z12,
        [theme.breakpoints.up('sm')]: {
          width: stretchStart + 60,
        },
      },
    }),
   padding:'10px',
   fontSize:'12px',
   fontWeight:'400'
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderRadius:5,
    padding:'5px',
    borderColor: `${theme.palette.grey[600]} !important`,
  },
}));

export default InputStyleMultline;
