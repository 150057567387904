import { createSlice } from '@reduxjs/toolkit';
import actionListaAgendamentos from 'src/actions/agendamentos/actionListaCampanhas';
import actionListaCampanhas from 'src/actions/campanhas/actionListaCampanhas';
// ----------------------------------------------------------------------
const initialState = {
  isLoading: false,
  error: false,
  listaCampanhas:[],
  listaAgendamentos:[]

};

const slice = createSlice({
  name: 'marketing',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // LISTA DE CAMPANHAS
    getListaCampanhasSuccess(state, action) {
      state.isLoading = false;
      state.listaCampanhas = action.payload;
    },
        // LISTA DE CAMPANHAS
        getListaMensagensAgendadasSuccess(state, action) {
          state.isLoading = false;
          state.listaAgendamentos = action.payload;
        }

  }
});

// Reducer
export default slice.reducer;

export const { actions } = slice;

// ----------------------------------------------------------------------

export function getListaCampanhas(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await actionListaCampanhas(clienteId);
      dispatch(slice.actions.getListaCampanhasSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getListaMensagensAgendadas(clienteId, usuarioId) {
  console.log('chega', clienteId, usuarioId)
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await actionListaAgendamentos(clienteId, usuarioId);
      dispatch(slice.actions.getListaMensagensAgendadasSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



