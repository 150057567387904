import { Button } from "src/@/components/ui/button"
import {
    Dialog,
    DialogContent,
    DialogDescription,
    DialogFooter,
    DialogHeader,
    DialogTitle,
    DialogTrigger,
} from "src/@/components/ui/dialog"
import { Input } from "src/@/components/ui/input"
import Iconify from "src/components/Iconify";
import { CircularProgress, Stack } from "@mui/material";
import MicRecorder from "mic-recorder-to-mp3";
import { useState, useMemo, useRef } from "react";
import RecordingTimer from "src/components/_chats/chat/components/RecordingTimer";
import { Card, CardContent } from "src/@/components/ui/card";
import { RowInput } from "src/components/forms/RowInput";
import { Alert, AlertDescription, AlertTitle } from "src/@/components/ui/alert";
import v2_actionCriarVoz from "src/actions/v2/ai/v2_actionCriarVoz";
import { ButtonLoading } from "src/@/components/use/ButtonLoading";
import { useSnackbar } from "notistack";


export function ModalClonarVoz({
    user,
    open,
    onClose,
    onCallback
}) {

    const [recording, setRecording] = useState(false);
    const [audioGravado, setAudioGravado] = useState(null);
    const recorder = useMemo(() => new MicRecorder({ bitRate: 128 }), []);
    const [openDrawerAudio, setOpenDrawerAudio] = useState(false);
    const [loading, setLoading] = useState(false);
    const [nameVoice, setVoice] = useState(null);
    const {enqueueSnackbar} = useSnackbar();

    const mediaRecorderRef = useRef(null);
    const audioChunksRef = useRef([]);


    const v2_handleCancelAudio = async () => {
        try {
            mediaRecorderRef.current.stop();
            mediaRecorderRef.current.onstop = async () => {
                setRecording(false);
                audioChunksRef.current = [];
                setLoading(false);
            }
        } catch (err) {
            console.log(err);
        }
    };

    const v2_stopRecording = async () => {
        mediaRecorderRef.current.stop();
        mediaRecorderRef.current.onstop = async () => {
            try {
                const audioBlob = new Blob(audioChunksRef.current, { type: 'audio/mp3' });
                setAudioGravado(audioBlob);
                setRecording(false);
               
            } catch (err) {
                console.log(err);
            }
            setLoading(false);
            setRecording(false);
            audioChunksRef.current = [];
        };
    };

    async function handleCriarVoz() {
        try {
            setLoading(true);
            const formData = new FormData();
            formData.append("nameVoice", nameVoice);
            formData.append("file", audioGravado);
            await v2_actionCriarVoz(user.clienteId, formData);
            setAudioGravado(null);
            setVoice(null);
            setLoading(false);
            onClose();
        } catch (error) {
            enqueueSnackbar('Erro ao criar voz', { variant: 'error' });
            console.log(error)
        }
    }




    const v2_startRecording = async () => {
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
            mediaRecorderRef.current = new MediaRecorder(stream);
            mediaRecorderRef.current.start();

            mediaRecorderRef.current.ondataavailable = (event) => {
                audioChunksRef.current.push(event.data);
            };

            setRecording(true);
        }
    };




    return (
        <Dialog open={open} onOpenChange={onClose} >

            <DialogContent className="w-full">
                <DialogHeader>
                    <DialogTitle>Clonar Voz</DialogTitle>
                    <DialogDescription>
                        Grave um audio de pelo menos 60 segundos para clonar a voz
                    </DialogDescription>

                </DialogHeader>
                <div className="grid gap-4 py-4">

                    <>
                        {recording ? (

                            <div className="flex">
                                <Stack direction="column" spacing={5}>

                                    <div>
                                        <Alert className="bg-yellow-50">
                                            <Iconify icon="mdi:information" />
                                            <AlertTitle>Atenção!</AlertTitle>
                                            <AlertDescription>
                                                Leia o texto abaixo para gravar a sua voz
                                            </AlertDescription>
                                        </Alert>



                                        <blockquote className="mt-6 text-xs border-l-2 pl-6 italic">
                                        "Bem-vindo à Lais, onde a inovação encontra a excelência e onde cada ideia é tratada com o cuidado e a paixão que merece. Somos mais do que uma equipe de especialistas; somos visionários, comprometidos em transformar sonhos em soluções tecnológicas que impactam vidas. Na Lais, acreditamos que a tecnologia deve ser um facilitador da vida, um meio para simplificar, aprimorar e empoderar o dia a dia das pessoas. Nossa missão é não apenas criar produtos que resolvam os desafios de hoje, mas também antecipar as necessidades de amanhã, criando soluções que moldem um futuro melhor e mais conectado.

Convidamos você a explorar nossas soluções inovadoras, desenvolvidas com base em uma profunda compreensão das necessidades humanas e um compromisso inabalável com a qualidade. Ao se juntar a nós nessa jornada de descoberta e transformação, você não está apenas escolhendo um parceiro tecnológico; está se alinhando com um futuro onde cada passo é um avanço em direção à excelência. Na Lais, acreditamos que o futuro não é algo que se espera, mas algo que se constrói — e estamos aqui para construí-lo, juntos, hoje."
                                        </blockquote>

                                    </div>


                                    <div className="">

                                        <Stack spacing={2} direction="row" alignItems={'center'}>
                                            <p className="text-xs">
                                                Gravação em andamento
                                            </p>

                                        </Stack>
                                        <Stack direction="row" className="mt-2">
                                            <Button
                                                className="w-5 h-5 bg-red-600"
                                                aria-label="cancelRecording"
                                                size="icon"
                                                component="span"
                                                disabled={loading}
                                                onClick={v2_handleCancelAudio}
                                            >
                                                <Iconify icon="mdi:cancel" />
                                            </Button>
                                            {loading ? (
                                                <div>
                                                    <CircularProgress />
                                                </div>
                                            ) : (
                                                <RecordingTimer />
                                            )}

                                            <Button
                                                className="w-5 h-5 bg-green-400"
                                                aria-label="sendRecordedAudio"
                                                component="span"
                                                onClick={v2_stopRecording}
                                                disabled={loading}
                                                size="icon"
                                            >
                                                <Iconify icon="el:ok" />
                                            </Button>
                                        </Stack>
                                    </div>


                                </Stack>
                            </div>
                        ) : (
                            <Stack direction={'column'} spacing={2}>


                                <RowInput
                                    label="Nome da voz"
                                    input={
                                        <Input
                                          value={nameVoice}
                                            onChange={(e) => setVoice(e.target.value)}
                                        />
                                    }
                                />

                                <Alert className="bg-yellow-50">
                                    <Iconify icon="mdi:information" />
                                    <AlertTitle>Atenção!</AlertTitle>
                                    <AlertDescription>
                                        Prepare-se para gravar a sua voz. Clique no botão abaixo para iniciar a gravação e leia o texto abaixo
                                    </AlertDescription>
                                </Alert>



                                <blockquote className="mt-6 text-xs border-l-2 pl-6 italic">
                                "Bem-vindo à Lais, onde a inovação encontra a excelência e onde cada ideia é tratada com o cuidado e a paixão que merece. Somos mais do que uma equipe de especialistas; somos visionários, comprometidos em transformar sonhos em soluções tecnológicas que impactam vidas. Na Lais, acreditamos que a tecnologia deve ser um facilitador da vida, um meio para simplificar, aprimorar e empoderar o dia a dia das pessoas. Nossa missão é não apenas criar produtos que resolvam os desafios de hoje, mas também antecipar as necessidades de amanhã, criando soluções que moldem um futuro melhor e mais conectado.

Convidamos você a explorar nossas soluções inovadoras, desenvolvidas com base em uma profunda compreensão das necessidades humanas e um compromisso inabalável com a qualidade. Ao se juntar a nós nessa jornada de descoberta e transformação, você não está apenas escolhendo um parceiro tecnológico; está se alinhando com um futuro onde cada passo é um avanço em direção à excelência. Na Lais, acreditamos que o futuro não é algo que se espera, mas algo que se constrói — e estamos aqui para construí-lo, juntos, hoje."
                                </blockquote>

                                {!audioGravado && (
                                    <Button
                                        className="w-full"
                                        variant="secondary"
                                        disabled={loading}
                                        onClick={v2_startRecording}
                                    >
                                        <Iconify className="mr-1 mt-0.5" icon="mdi:microphone" />
                                        Gravar audio
                                    </Button>
                                )}

                                {audioGravado && (
                                    <Stack direction={'row'} alignItems={'center'} spacing={2}>
                                        <audio controls>
                                            <source src={URL.createObjectURL(audioGravado)} type="audio/mp3" />
                                            Seu navegador não suporta a reprodução de áudio.
                                        </audio>
                                        <Button
                                            size="icon"
                                            onClick={() => setAudioGravado(null)} variant='outlined' color="error">
                                            <Iconify icon="material-symbols:delete" />
                                        </Button>
                                        {/* <Button
                                            size="icon"
                                            onClick={() => setAudioGravado(null)} variant='outlined' color="error">
                                            <Iconify color="green" icon="mage:file-upload-fill" />
                                        </Button> */}
                                    </Stack>

                                )}
                            </Stack>

                        )}
                    </>

                </div>
                <DialogFooter>
                    <ButtonLoading loading={loading} disabled={!nameVoice || !audioGravado} onClick={() => handleCriarVoz()}>Clonar voz</ButtonLoading>
                </DialogFooter>
            </DialogContent>
        </Dialog>
    )
}
