import { createSlice } from '@reduxjs/toolkit';

// utils
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import getActionTransferirChat from 'src/actions/chats/getActionTransferirChat';
import getListaFunilByChatId from 'src/actions/chats/getListaFunilByChatId';

// actions whatsapp

import actionGetChatMeta from 'src/actions/chats/meta/actionGetChatMeta';
import v2_actionBuscarChatById from 'src/actions/v2/services/chats/v2_actionBuscarChatById';
import v2_actionListaByStatus from 'src/actions/v2/services/chats/v2_actionListaByStatus';

import getActionChatsPendentesMarketplace from '../../actions/chats/getActionChatsPendentesMarketplace';
import getActionListaChatsByStatus from "../../actions/chats/getActionListaChatsByStatus"
import getActionAllMessagesWhatsapp from '../../actions/chats/whatsapp/getActionAllMessagesWhatsapp';
import actionGetMessages from '../../actions/chats/whatsapp/getMessagesNew';
import axios from '../../utils/axios';
import getActionPesquisarChat from 'src/actions/chats/getActionPesquisarChat';
import v2_actionListaTagsChats from 'src/actions/v2/chats/v2_actionListaTagsChats';
import v2_actionCountChats from 'src/actions/v2/chats/v2_actionCountChats';
import v1_actionBuscarMensagensWhatsapp from 'src/actions/v1/whatsapp/v1_actionBuscarMensagensWhatsapp';
import Worker from "worker-loader!../../actions/idb/workers/app.worker.worker";
const wbWorker = new Worker();

const initialState = {
  isLoading: false,
  chats_atendendo_loading: false,
  loadingChat: false,
  error: false,
  contacts: { byId: {}, allIds: [] },
  conversations: { byId: {}, allIds: [] },
  activeConversationId: null,
  participants: [],
  recipients: [],
  chatsAtendimento: [],
  chatsPendente: [],
  chatsFinalizado: [],
  chatsPendentesMarketplace: [],
  chatsGeral: [],
  countsListaChats:{
    count_finalizado:0,
    count_pendente:0,
    count_atendendo:0,
    count_geral: 0,
  },
  mensagensEnviar:[],
  loadingListaChatsAtendendo: false,
  loadingListaChatsPendente: false,
  loadingListaChatsGeral: false,
  loadingListaChatsFinalizados: false,
  mensagens: [],
  mensagensMeta:[],
  chatInfo: null,
  dialogRespostas: [],
  listaRespostas: [],
  listaFunil: [],
  listaTags: [],
  selectedMessage: null,
  sheet: null,
  chatIsOnline: null,
  verificaChatOnline: null,
  numeroClienteActive: false,
  dialogRespostasRapidas: false,
  pesquisasChat:[],
  drawerPesquisasChat:false,
  showLoadAllMessages: true,

};

const slice = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },
    startLoadingAtendendo(state) {
      state.loadingListaChatsAtendendo = true;
    },
    startLoadingGeral(state) {
      state.loadingListaChatsGeral = true;
    },
    startLoadingPendente(state) {
      state.loadingListaChatsPendente = true;
    },
    startLoadingFinalizados(state) {
      state.loadingListaChatsFinalizados = true;
    },
    stopLoadingAtendendo(state) {
      state.loadingListaChatsAtendendo = false;
    },
    stopLoadingGeral(state) {
      state.loadingListaChatsGeral = false;
    },
    stopLoadingPendente(state) {
      state.loadingListaChatsPendente = false;
    },
    stopLoadingFinalizados(state) {
      state.loadingListaChatsFinalizados = false;
    },
    stopLoading(state) {
      state.isLoading = false;
    },
    setShowLoadAllMessagesWhatsapp(state, action) {
      state.showLoadAllMessages = action.payload;
    },

    startLoadingChat(state) {
      state.loadingChat = true;
    },
    stopLoadingChat(state) {
      state.loadingChat = false;
    },

    startLimparChats(state) {
      state.mensagens = [];
    },

    setLimparChatInfo(state) {

      state.chatInfo = null;
      state.mensagens = []
    },
    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.loadingChat = false;
      state.error = action.payload;
    },



    // GET PARTICIPANTS
    getParticipantsSuccess(state, action) {
      const participants = action.payload;
      state.participants = participants;
    },

    // RESET ACTIVE CONVERSATION
    resetActiveConversation(state) {
      state.activeConversationId = null;
    },

    addRecipients(state, action) {
      const recipients = action.payload;
      state.recipients = recipients;
    },

            
    setCountsListaChats(state, action) {
         state.countsListaChats = action.payload;
    },


    setDialogRespostas(state, action) {
      state.dialogRespostasRapidas = action.payload;
    },

    // BUSCAR CHATS EM ATENDIMENTO
    getMessagesMetaSuccess(state, action) {

      state.mensagensMeta = action.payload;
      state.loadingChat = false;
    },

    setMetaMessages(state, action) {
      state.mensagensMeta.push(action.payload);
    },

    // BUSCAR CHATS EM ATENDIMENTO
    getMessagesWhatsappSuccess(state, action) {
      state.isLoading = false;
      state.mensagens = action.payload;
    },

    // BUSCAR TODAS MENSAGENS WHATSAPP
    getAllMessagesWhatsappSuccess(state, action) {
      state.isLoading = false;
      state.mensagens = action.payload;
    },

    // CARREGAR MENSAGENS
    getLoadMessagesWhatsappSuccess(state, action) {
      state.mensagens = action.payload;
      state.loadingChat = false;
    },

    // BUSCAR CHATS EM ATENDIMENTO
    getChatsAtendimentoSuccess(state, action) {
      state.isLoading = false;
      state.loadingListaChatsAtendendo = false;
      state.chatsAtendimento = action.payload;
    },

    // BUSCAR CHATS PENDENTES
    getChatsPendenteSuccess(state, action) {
      state.chatsPendente = action.payload;
      state.loadingListaChatsPendente = false;
    },

    // BUSCAR CHATS PENDENTES
    getChatsPendentesMarketplaceSuccess(state, action) {
      state.chatsPendentesMarketplace = action.payload;
    },

    // BUSCAR CHATS PENDENTES
    getChatsFinalizadosSuccess(state, action) {
      state.chatsFinalizado = action.payload;
      state.loadingListaChatsFinalizados = false;
    },

    // BUSCAR CHATS GERAL
    getChatsGeralSuccess(state, action) {
      state.isLoading = false;
      state.chatsGeral = action.payload;
      state.loadingListaChatsGeral = false;
    },

    // BUSCAR INFORMAÇÕES DE CHAT
    getChatsInfoSuccess(state, action) {
      state.isLoading = false;
      state.chatInfo = action.payload;
    },

    // BUSCAR INFORMAÇÕES DE CHAT by CHAT ID
    getChatsInfoByChatIdSuccess(state, action) {
      state.chatInfo = action.payload;
      state.isLoading = false;
    },



    // TRANSFERIR CHAT
    getTransferirChatSuccess(state) {
      state.isLoading = false;
    },

    // setSelectedMessage
    setSelectedMessage(state, action) {

      state.selectedMessage = action.payload.message;
      state.isLoading = false;
    },

    setChatInfo(state, action) {
      state.chatInfo = action.payload;
    },


    setNumeroClienteActive(state, action) {
      state.numeroClienteActive = action.payload;
    },

    setVerificaChatOnline(state, action) {
      state.verificaChatOnline = action.payload;
    },

    setChatIsOnline(state, action) {
      state.chatIsOnline = action.payload;
    },

    setMensagensEnviar(state, action) {
      state.mensagensEnviar.push(action.payload);
    },
    resetMensagensEnviar(state, action) {
      state.mensagensEnviar = []
    },


    // LISTA FUNIL
    getListaFunilSuccess(state, action) {
      state.listaFunil = action.payload;
      state.isLoading = false;
    },

       // LISTA TAGS
       getListaTagsSuccess(state, action) {
        state.listaTags = action.payload;
        state.isLoading = false;
      },

    limparProcurarChats(state) {
      state.mensagens = []
    },

    limparChats(state) {
      state.mensagens = []
    },
    getPesquisarChatsSuccess(state, action) {
      state.pesquisasChat = action.payload
    },
    setDrawerPesquisas(state, action) {
      state.drawerPesquisasChat = action.payload
    },

    // INSERIR MENSAGENS AO CHAT
    setMessageSuccess(state, action) {
      const novaMensgaem = action.payload;
      state.mensagens.push(novaMensgaem);
      // state.mensagens = action.payload;
    },
    setPushMensagemMeta(state, action) {
      const novaMensgaem = action.payload;
      state.mensagensMeta.push(novaMensgaem);
    },
    removeChatFromList(state, action) {
      const data = action.payload;
      switch (data.status) {
        case "Atendendo":
          state.chatsAtendimento = state.chatsAtendimento.filter((chat) => chat._id !== data.chatId);
          break;
        case "Pendente":
          state.chatsPendente = state.chatsPendente.filter((chat) => chat._id !== data.chatId);
          break;
        case "Geral":
          state.chatsGeral = state.chatsGeral.filter((chat) => chat._id !== data.chatId);
          break;
          case "Finalizado":
            state.chatsFinalizado = state.chatsFinalizado.filter((chat) => chat._id !== data.chatId);
            break;
      }

    },
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  addRecipients,
  onSendMessage,
  resetActiveConversation,
  setDialogRespostas,
  limparProcurarChats,
  setSelectedMessage,
  setVerificaChatOnline,
  startLoading,
  startLoadingAtendendo,
  startLoadingGeral,
  startLoadingPendente,
  startLoadingFinalizados,
  stopLoadingAtendendo,
  stopLoadingGeral,
  stopLoadingPendente,
  stopLoadingFinalizados,
  stopLoading,
  startLoadingChat,
  stopLoadingChat,
  setNumeroClienteActive,
  setChatIsOnline,
  setChatInfo,
  setMetaMessages,
  setDrawerPesquisas,
  setShowLoadAllMessagesWhatsapp,
  setMensagensEnviar, 
  resetMensagensEnviar,
  setPushMensagemMeta,
  removeChatFromList
} = slice.actions;

// ----------------------------------------------------------------------

const multiSession = localStorage.getItem("multiSession");
const clienteIdActive = localStorage.getItem("clienteIdActive");


// TRANSFERIR CHAT ----------------------------------------------------------------------
export function getTransferirChat(chatId, usuarioId, direcionaSetores, direcionaUsuarios) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let data = {
        id: chatId,
        usuarioId: usuarioId,
        direcionaSetores: direcionaSetores,
        direcionaUsuarios: direcionaUsuarios
      }
      await getActionTransferirChat(data);
      dispatch(slice.actions.getTransferirChatSuccess());
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// BUSCAR INFORMAÇÕES DE CHAT ----------------------------------------------------------------------
export function getChatInfoByChatId(chatId, funilId, userId, limparChat) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    if(limparChat) {
      dispatch(slice.actions.setLimparChatInfo());
    } 
    
    try {
      const response = await v2_actionBuscarChatById(chatId, funilId, userId);
      dispatch(slice.actions.getChatsInfoByChatIdSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// BUSCAR INFORMAÇÕES DE CHAT ----------------------------------------------------------------------
export function getChatInfo(clienteId, phone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionChatInfo(clienteId, phone);
      dispatch(slice.actions.getChatsInfoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



// BUSCAR MENSAGENS NO WHATSAPP ----------------------------------------------------------------------
export function getMessagesWhatsapp(phone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await actionGetMessages(phone);
      if (response.data.response) {
        dispatch(slice.actions.getMessagesWhatsappSuccess(response.data.response));
  
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




// BUSCAR TODAS MENSAGENS NO WHATSAPP ----------------------------------------------------------------------
export function getAllMessagesWhatsapp(phone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionAllMessagesWhatsapp(phone);
      dispatch(slice.actions.getAllMessagesWhatsappSuccess(response.data.response));
    } catch (error) {
      await getMessagesWhatsapp(phone)
      dispatch(slice.actions.hasError(error));
    }
  };
}

// BUSCAR TODAS MENSAGENS NO WHATSAPP ----------------------------------------------------------------------
export function getLoadMessagesInChat(phone) {
  return async (dispatch) => {
    dispatch(slice.actions.startLimparChats());
    dispatch(slice.actions.startLoadingChat());

    try {
      const response = await actionGetMessages(phone);
      dispatch(slice.actions.getLoadMessagesWhatsappSuccess(response.data.response));
    } catch (error) {
      if (error.response && error.response.status === 504) {
        getMessagesWhatsapp(phone);
      }

      dispatch(slice.actions.hasError(error));
    }
  };
}

// BUSCAR TODAS MENSAGENS NO WHATSAPP ----------------------------------------------------------------------
export function getMensagensWhatsapp(sessao, numero) {
  return async (dispatch) => {
    dispatch(slice.actions.startLimparChats());
    dispatch(slice.actions.startLoadingChat());

    try {
      let querys = `?sessao=${sessao}&numero=${numero}&canal=whatsapp&limit=100`
      const response = await v1_actionBuscarMensagensWhatsapp(querys);
      dispatch(slice.actions.stopLoadingChat());
      if(response.data.data) {
        dispatch(slice.actions.getLoadMessagesWhatsappSuccess(response.data.data));
      } else {
        dispatch(slice.actions.getLoadMessagesWhatsappSuccess([]));
        dispatch(slice.actions.stopLoadingChat());
      }
      dispatch(slice.actions.setShowLoadAllMessagesWhatsapp(null));

    } catch (error) {
      if (error.response && error.response.status === 504) {
        getMessagesWhatsapp(phone);
      }

      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getMessagesMeta(sessao, instagramUserId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let querys = `?sessao=${sessao}&instagram_user_id=${instagramUserId}&canal=instagram`
      const response = await v1_actionBuscarMensagensWhatsapp(querys);
      dispatch(slice.actions.stopLoadingChat());
      if(response.data.data) {
        dispatch(slice.actions.getMessagesMetaSuccess(response.data.data));
      } else {
        dispatch(slice.actions.getMessagesMetaSuccess([]));
        dispatch(slice.actions.stopLoadingChat());
      }
      dispatch(slice.actions.setShowLoadAllMessagesWhatsapp(null));


    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getMensagensWhatsappDb(sessao, numero) {
  return async (dispatch) => {

    dispatch(slice.actions.startLoadingChat());
    dispatch(slice.actions.startLimparChats());
    try {
      let querys = `?sessao=${sessao}&numero=${numero}&canal=whatsapp&limit=100&chat_source=db`
      const response = await v1_actionBuscarMensagensWhatsapp(querys);
      dispatch(slice.actions.stopLoadingChat());
      if(response.data.data) {
        dispatch(slice.actions.getLoadMessagesWhatsappSuccess(response.data.data));
      } else {
        dispatch(slice.actions.getLoadMessagesWhatsappSuccess([]));
      }
      dispatch(slice.actions.setShowLoadAllMessagesWhatsapp(true));
    } catch (error) {
      if (error.response && error.response.status === 504) {
        getMessagesWhatsapp(phone);
      }

      dispatch(slice.actions.hasError(error));
    }
  };
}





// CHATS MARKETPLACE PENDENTE ----------------------------------------------------------------------

export function getChatsPendentesMarketplace(marketplaceId, usuarioId, setorId) {
  return async (dispatch) => {
    try {
      const response = await getActionChatsPendentesMarketplace({ marketplaceId: marketplaceId, clienteIdActive: clienteIdActive, usuarioId: usuarioId, setorId: setorId });
      dispatch(slice.actions.getChatsPendentesMarketplaceSuccess(response.data.total));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// CHATS EM ATENDIMENTO ----------------------------------------------------------------------

export function getChatsAtendimento(clienteId, usuarioId, setorId, limit = 100, isStartLoading ) {

  return async (dispatch) => {
    if(isStartLoading) {
      dispatch(slice.actions.startLoadingAtendendo());
    }
    try {
   
      const response = await v2_actionListaByStatus(clienteId, usuarioId, "Atendendo",setorId, 100);
      dispatch(slice.actions.getChatsAtendimentoSuccess(response.data.data.chats));
      //dispatch(slice.actions.setCountsListaChats(response.data.data.counts));
    } catch (error) {
      dispatch(slice.actions.stopLoadingAtendendo());
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getCountsChats(clienteId, usuarioId, setorId) {
  return async (dispatch) => {
    try {
      // let querys = `?setor_id=${setorId}`	
      // const response = await v2_actionCountChats(clienteId, usuarioId, querys);
      // dispatch(slice.actions.setCountsListaChats(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// CHATS EM ATENDIMENTO ----------------------------------------------------------------------

export function getChatsPendente(clienteId, usuarioId, setorId, limit = 100, isStartLoading) {

  return async (dispatch) => {
    if(isStartLoading) {
    dispatch(slice.actions.startLoadingPendente());
    }
    try {
     
      const response = await v2_actionListaByStatus(clienteId, usuarioId, "Pendente", setorId, limit );
      if (response.data) {
        dispatch(slice.actions.getChatsPendenteSuccess(response.data.data.chats));
        dispatch(slice.actions.setCountsListaChats(response.data.data.counts));
      }
    } catch (error) {
      dispatch(slice.actions.stopLoadingPendente());
      dispatch(slice.actions.hasError(error));
    }
  };
}

// CHATS GERAL ----------------------------------------------------------------------

export function getChatsGeral(clienteId, usuarioId, setorId, limit, isStartLoading) {
  return async (dispatch) => {
    if(isStartLoading) {
    dispatch(slice.actions.startLoadingGeral());
    }
    try {
      const response = await v2_actionListaByStatus(clienteId, usuarioId, "Geral", setorId, limit);
      dispatch(slice.actions.getChatsGeralSuccess(response.data.data.chats));
      dispatch(slice.actions.setCountsListaChats(response.data.data.counts));

    } catch (error) {
      dispatch(slice.actions.stopLoadingGeral());
      dispatch(slice.actions.hasError(error));
    }
  };
}

// CHATS FINALIZADOS ----------------------------------------------------------------------
export function getChatsFinalizados(clienteId, usuarioId, setorId, limit = 100, isStartLoading ) {
  console.log('getChatsFinalizados', clienteId, usuarioId, setorId, limit, isStartLoading)

  return async (dispatch) => {
    if(isStartLoading) {
    dispatch(slice.actions.startLoadingFinalizados());
  }
    try {
      const response = await v2_actionListaByStatus(clienteId, usuarioId, "Finalizado", setorId, limit);
      dispatch(slice.actions.getChatsFinalizadosSuccess(response.data.data.chats));
      dispatch(slice.actions.setCountsListaChats(response.data.data.counts));
    } catch (error) {
      dispatch(slice.actions.stopLoadingFinalizados());
      dispatch(slice.actions.hasError(error));
    }
  };
}


// LISTA DE FUNISBY CLIENTE ID ----------------------------------------------------------------------

export function getListaFunil(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getListaFunilByChatId(clienteId);
      dispatch(slice.actions.getListaFunilSuccess(response.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getListaTags(querys) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v2_actionListaTagsChats(querys);
      dispatch(slice.actions.getListaTagsSuccess(response.data.data));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}




export function setMessage(message) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setMessageSuccess(message));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function setMessageWhatsapp(message) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      dispatch(slice.actions.setMessageSuccess(message));

    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getPesquisarChats(clienteId, value) {
  return async (dispatch) => {
    dispatch(slice.actions.setDrawerPesquisas(true));
    dispatch(slice.actions.startLoading());
    try {
      if (value) {
        if (value.length >= 4) {
          const response = await getActionPesquisarChat({ search: value, clienteId: clienteId });
          dispatch(slice.actions.getPesquisarChatsSuccess(response.data.chats));
  
        }}
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getMensagensWhatsappIdb(sessao, numero, page, limit) {

  return async (dispatch) => {
    dispatch(slice.actions.startLimparChats());
    dispatch(slice.actions.startLoadingChat());
 
    try {
      wbWorker.onmessage = (ev) => {
        const { body, hasMore, error, type } = ev.data;
        if (type !== "get-messages-range") return;
        if (error) {
          dispatch(slice.actions.stopLoadingChat());
          dispatch(slice.actions.getLoadMessagesWhatsappSuccess([]));
          dispatch(slice.actions.hasError(error));
        } else {
          dispatch(slice.actions.getLoadMessagesWhatsappSuccess(body.sort((x, y) => x.t - y.t)));
          console.log('setando mensagens')
          dispatch(slice.actions.stopLoadingChat());
          dispatch(slice.actions.setShowLoadAllMessagesWhatsapp(true));
        }
      }

      wbWorker.postMessage({ type: "get-messages-range", key: numero, sessao: sessao, pageSize: limit ?? 15, page: page ?? 1 });
    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopLoadingChat());
    }
  };
}
/////>
//Busca todas menssagens do indexdb
export function getAllMenssagensWhatsappIdb(sessao, numero) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoadingChat());
    dispatch(slice.actions.startLimparChats());

    try {
      wbWorker.onmessage = async (ev) => {
        const { body, error, type, hasMore } = ev.data;

        if (type !== 'get-messages') return;

        if (error) {
          try {
            const querys = `?sessao=${sessao}&numero=${numero}&canal=whatsapp&limit=100`;
            const { data: response } = await v1_actionBuscarMensagensWhatsapp(querys);

            dispatch(slice.actions.getLoadMessagesWhatsappSuccess(response.data));
            wbWorker.postMessage({ messages: response.data, type: 'store-messages' });
            dispatch(slice.actions.stopLoadingChat());
            dispatch(slice.actions.setShowLoadAllMessagesWhatsapp(null));
            dispatch(slice.actions.hasError(error));
          } catch (error) {
            dispatch(slice.actions.hasError(error));
            dispatch(slice.actions.stopLoadingChat());
            dispatch(slice.actions.setShowLoadAllMessagesWhatsapp(true));
          }
        } else {

          dispatch(slice.actions.getLoadMessagesWhatsappSuccess(body.sort((x, y) => x.t - y.t)));
          dispatch(slice.actions.stopLoadingChat());
          dispatch(slice.actions.setShowLoadAllMessagesWhatsapp(null));
        }
      };
    
      wbWorker.postMessage({ type: 'get-messages', key: numero, sessao: sessao });

    } catch (error) {
      dispatch(slice.actions.hasError(error));
      dispatch(slice.actions.stopLoadingChat());
    }
  };
}




