import { Button, Divider, } from "@mui/material";
import React, { useEffect } from 'react';

const LocationPreview = ({ link, base64  }) => {
    useEffect(() => {}, [link, base64 ]);

    const handleLocation = async() => {
        try {
            window.open(link);
        } catch (err) {
            console.log('err', err)
        }
    }

    return (
		<>
			<div style={{
				
			}}>
				<div>
					<div style={{ float: "left" }}>
						<img src={base64} onClick={handleLocation} style={{ width: "100px" }} />
					</div>
					{/* { description && (
					<div style={{ display: "flex", flexWrap: "wrap" }}>
						<Typography style={{ marginTop: "12px", marginLeft: "15px", marginRight: "15px", float: "left" }} variant="subtitle1" color="primary" gutterBottom>
							<div dangerouslySetInnerHTML={{ __html: description.replace('\\n', '<br />') }}></div>
						</Typography>
					</div>
					)} */}
					<div style={{ display: "block", content: "", clear: "both" }}></div>
					<div>
						<Divider />
						<Button
							fullWidth
							color="primary"
							onClick={handleLocation}
                            sx={{mt:1, mb:1}}
							disabled={!link}
						>Visualizar</Button>
					</div>
				</div>
			</div>
		</>
	);

};

export default LocationPreview;
