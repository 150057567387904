// material
import { Box, Card, Container, Stack, Typography } from '@mui/material';

import { BsFillFileBarGraphFill, BsKanban } from 'react-icons/bs';
import { MdFlashOn } from 'react-icons/md';

import HeaderBreadcrumbs from 'src/components/HeaderBreadcrumbs';
import { useNavigate } from 'react-router';
import Breadcrumbs from 'src/components/Breadcrumbs';

export default function Relatorios() {
    const navigate = useNavigate()

    const ItemRelatorio = ({ titulo, icon, descricao, link }) => {
        return (
            <Card onClick={link} sx={{ p: 2, m: 2, borderRadius: 1, borderWidth: 1, borderColor: '#ccc', cursor: 'pointer' }}>
                <Stack direction={'row'} alignItems={'center'} spacing={2}>
                    {icon}
                    <Typography variant="h6">{titulo}</Typography>
                    <Typography variant="caption">{descricao}</Typography>
                </Stack>
            </Card>
        )
    }

    return (
        <Box sx={{ mt: 4 }}>
            <Container maxWidth>
                <Breadcrumbs
                    heading="Relatórios"
                    links={[{ name: 'Dashboard', href: '/dashboard/home' }, { name: 'Relatorios' }]}
                />
                <Box>
                    <ItemRelatorio icon={<MdFlashOn size={25} />} titulo="Relatório Analítico de Força de Vendas" descricao="Relatório de Atividades" link={() => navigate('/dashboard/relatorios/forca-vendas')} />
                    <ItemRelatorio icon={<MdFlashOn size={25} />} titulo="Relatório Clientes Força de Vendas" descricao="Lista de clientes capturados pelo foraça de vendas" link={() => navigate('/dashboard/relatorios/clientes-forca-vendas')} />
                    <ItemRelatorio icon={<MdFlashOn size={25} />} titulo="Relatório Chamados" descricao="Relatório analítico de chamados" link={() => navigate('/dashboard/relatorios/chamados')} />
                    <ItemRelatorio icon={<MdFlashOn size={25} />} titulo="Relatório de Avaliações" descricao="Relatório com listagem das avaliações de atendiemento" link={() => navigate('/dashboard/relatorios/chats-avaliacoes')} />
                    {/* <ItemRelatorio icon={<MdFlashOn size={25} />} titulo="Relatório Negocios" descricao="Relatório com listagem das avaliações de atendiemento" link={() => navigate('/dashboard/relatorios/negocios')} /> */}

                    {/* <ItemRelatorio icon={<BsKanban size={25} />} titulo="Relatorio Funil de Vendas" descricao="Relatório de Atividades" link={() => navigate('/dashboard/relatorios/funil')} /> */}
                </Box>
            </Container>
        </Box>
    );
}
