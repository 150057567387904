import axios from "axios";
import Config from "../../../config";

const actionListaTagsUsuarios = (querys )=> {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chamados/tags/lista${querys}`
    });
};

export default actionListaTagsUsuarios;
