
import { Box, Button, Divider, Collapse, Typography, Stack, IconButton, Accordion, AccordionSummary, Autocomplete } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import PropTypes from 'prop-types';
import Scrollbar from 'src/components/Scrollbar';
import Label from 'src/components/Label';
import LabelInput from 'src/components/LabelInput';
import { fDateTime } from 'src/utils/formatTime';
import actionCriarNotas from 'src/actions/chats/notas/actionCriarNotas';
import { useState } from 'react';
import { useSnackbar } from 'notistack';

import { useEffect } from 'react';
import DrawerNegocioNotasInput from './DrawerNegocioNotasInput';
import NovoContatoForm from '../../cadastro/NovoContatoForm';
import Iconify from 'src/components/Iconify';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import buscarCep from 'src/actions/chats/buscarCep';
import PhoneInput from 'react-phone-number-input'
import { formataNumeroCelular, maskCPF, maskDate } from 'src/utils/functions';
import v2_actionPesquisarContatos from 'src/actions/v2/contatos/v2_actionPesquisarContatos';
import InputStyleAutocomplete from 'src/components/InputStyleAutocomplete';
import v2_actionEditarContato from 'src/actions/v2/contatos/v2_actionEditarContato';
import ModalNovoContato from 'src/sections/@chats/chat/Modals/ModalNovoContato';


DrawerContatos.propTypes = {
    isCollapse: PropTypes.bool,
    onCollapse: PropTypes.func
};

export default function DrawerContatos({
    data,
    user,
    cliente,
    lead,
    onEditarContatoId,
    callbackCreateContact
}) {

    const { enqueueSnackbar } = useSnackbar();
    const [listaContatos, setListaContatos] = useState([]);
    const [pesquisa, setPesquisa] = useState('');
    const [contactId, setContactId] = useState(lead?.contato);
    const [isChange, setIsChange] = useState(null);
    const [loadingEditarContato, setLoadingEditarContato] = useState(null);
    const [stateModalNovoContato , setStateModalNovoContato] = useState(null);


    const [contato, setContato] = useState({
        nome: lead?.contato?.nome,
        email: lead?.contato?.info?.email,
        whatsapp: lead?.contato?.telefone,
        dataNascimento: lead?.contato?.info?.dataNascimento,
        cpf: lead?.contato?.info?.cpf
    });
    const [endereco, setEndereco] = useState({
        cep: lead?.contato?.info?.endereco?.cep,
        rua: lead?.contato?.info?.endereco?.rua,
        numero: lead?.contato?.info?.endereco?.numero,
        bairro: lead?.contato?.info?.endereco?.bairro,
        cidade: lead?.contato?.info?.endereco?.cidade,
        estado: lead?.contato?.info?.endereco?.estado,
        complemento: lead?.contato?.info?.endereco?.complemento
    });


    async function pesquisarContatos(pesquisa) {
        try {
            const result = await v2_actionPesquisarContatos(cliente._id, pesquisa)
            setListaContatos(result.data.data)
        } catch (error) {
            console.log(error);
        }
    }

    async function editarContato() {
        setLoadingEditarContato(true);
        try {
            let data =
            {
                scope: {
                    nome: contato.nome,
                    telefone: contato.telefone,
                    info: {
                        email: contato.email,
                        cpf: contato.cpf,
                        dataNascimento: contato.dataNascimento,
                        endereco: {
                            cep: endereco.cep,
                            rua: endereco.rua,
                            bairro: endereco.bairro,
                            cidade: endereco.cidade,
                            estado: endereco.estado,
                            numero: endereco.numero,
                            complemento: endereco.complemento
                        }
                    }
                }
            }

            await v2_actionEditarContato(contactId._id, data)
            setLoadingEditarContato(false);
            setIsChange(false);
            enqueueSnackbar('Contato alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
        } catch (error) {
            setLoadingEditarContato(false);
            console.log(error);
        }
    }

    const handleOnBlurSearchCep = async (zipCode) => {
        try {
            const address = await buscarCep(zipCode)
            if (!address.erro) {
                setEndereco({
                    cep: address.cep,
                    rua: address.logradouro,
                    bairro: address.bairro,
                    cidade: address.localidade,
                    estado: address.uf,
                })
            }
        } catch (address) {

        }
    }

    useEffect(() => {
        setContato({
            nome: lead?.contato?.nome,
            email: lead?.contato?.info?.email,
            whatsapp: lead?.contato?.telefone,
            dataNascimento: lead?.contato?.info?.dataNascimento,
            cpf: lead?.contato?.info?.cpf
        });
        setEndereco({
            cep: lead?.contato?.info?.endereco?.cep,
            rua: lead?.contato?.info?.endereco?.rua,
            numero: lead?.contato?.info?.endereco?.numero,
            bairro: lead?.contato?.info?.endereco?.bairro,
            cidade: lead?.contato?.info?.endereco?.cidade,
            estado: lead?.contato?.info?.endereco?.estado,
            complemento: lead?.contato?.info?.endereco?.complemento
        });
        setContactId(lead?.contato);
    }, [lead, contactId])


    return (
        <Stack>

            <ModalNovoContato
                cliente={cliente}
                user={user}
                open={stateModalNovoContato}
                listaRespostas={[]}
                listaClientesCanais={[]}
                callbackAddContact={async (data) => {
                   await onEditarContatoId(data._id);
                   callbackCreateContact()
                }}
                onClose={() => {
                    setStateModalNovoContato(false);
                }}

            />

            <Box sx={{ width: '100%', mt: 1 }}>
                <Stack alignItems={"center"} direction="row" spacing={2}>
                    <Autocomplete
                        filterOptions={(x) => x}
                        autoComplete
                        includeInputInList
                        placeholder='Escolha um contato'
                        filterSelectedOptions
                        id="combo-box-demo"
                        options={listaContatos}
                        value={contactId}
                        inputValue={pesquisa}
                        noOptionsText={
                            <Stack direction="column" spacing={1}>
                                <Typography variant="subtitle2" textAlign={"center"} sx={{ color: 'text.secondary' }}>
                                    Nenhum contato encontrado
                                </Typography>
                                <Button onClick={() => setStateModalNovoContato(true)} startIcon={<Iconify icon={'gg:add'} sx={{ width: 20, height: 20 }} />} size="small">
                                    Criar Contato
                                </Button>
                            </Stack>
                        }
                        onChange={(event, newValue, reason) => {
                            if (reason === "reset") {
                                setContactId(null);
                                onEditarContatoId(null);
                            } else if (reason === "clear") {
                                setContactId(null);
                                onEditarContatoId(null);
                            }
                            if (newValue) {
                                setContactId(newValue);
                                onEditarContatoId(newValue?._id);
                            }

                        }}
                        onInputChange={async (event, newInputValue) => {
                            setPesquisa(newInputValue);
                            if (newInputValue.length > 3) {
                                pesquisarContatos(newInputValue);
                            } else {
                                setListaContatos([]);
                            }
                        }}
                        isOptionEqualToValue={(option, value) => option._id === value?._id}
                        getOptionLabel={(option) =>
                            typeof option === 'string' ? option : (option.nome + ' - ' + formataNumeroCelular(option.telefone))

                        }
                        renderInput={(params) => <InputStyleAutocomplete sx={{ width: 350 }} {...params} />}
                    />
                    {contactId && (
                        <IconButton onClick={() => {
                            onEditarContatoId(null);
                            setContactId(null);
                        }}>
                            <Iconify icon={'eva:close-outline'} sx={{ width: 20, height: 20 }} />
                        </IconButton>
                    )}
                    {!contactId && (
                        <Button onClick={() => setStateModalNovoContato(true)} variant="contained" startIcon={<Iconify icon={'gg:add'} sx={{ width: 20, height: 20 }} />} size="small">
                            Criar Contato
                        </Button>
                    )}
                </Stack>
            </Box>

            <Divider sx={{ my: 2, borderStyle: 'dashed' }} />

            <Scrollbar
                sx={{ height: { xs: '30vh', md: '30vh', lg: '30vh', xl: '40vh' } }}
                spacing={2}
            >

                <Box sx={{ mt: 3 }}>

                    {contactId && (
                        <Box sx={{ px: 0.5 }}>
                            <Stack direction="column" spacing={2}>
                                <Stack>
                                    <Stack
                                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                                        spacing={1}
                                        direction={"row"}
                                    >
                                        <Box sx={{ width: '90%' }}>
                                            <RowInput
                                                isRequired={true}
                                                label="Nome"
                                                input={<InputStyle onChange={e => {
                                                    setContato({ ...contato, nome: e.target.value })
                                                    setIsChange(true);
                                                }}
                                                    value={contato.nome}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '30%' }}>
                                            <RowInput
                                                isRequired={true}
                                                label="Telefone"
                                                input={<PhoneInput
                                                    defaultCountry='BR'
                                                    style={{ padding: 10, borderRadius: 4, border: '1px solid #666' }}
                                                    placeholder="Insira seu numero"
                                                    value={contato.whatsapp}
                                                    onChange={value => {
                                                        setIsChange(true);
                                                        setContato({ ...contato, whatsapp: value })
                                                    }}
                                                />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '35%' }}>
                                            <RowInput
                                                label="Data Nascimento"
                                                input={<InputStyle onChange={e => {
                                                    setContato({ ...contato, dataNascimento: e.target.value });
                                                    setIsChange(true);
                                                }}
                                                    value={contato.dataNascimento && maskDate(contato.dataNascimento)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '70%' }}>
                                            <RowInput
                                                label="Email"
                                                type="email"
                                                input={<InputStyle onChange={e => {
                                                    setContato({ ...contato, email: e.target.value });
                                                    setIsChange(true);
                                                }}
                                                    value={contato.email}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '60%' }}>
                                            <RowInput
                                                label="CPF"
                                                input={<InputStyle onChange={e => {
                                                    setContato({ ...contato, cpf: e.target.value });
                                                    setIsChange(true);
                                                }}
                                                    value={contato.cpf && maskCPF(contato.cpf)}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>

                                <Stack>
                                    <Stack
                                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                                        spacing={1}
                                        direction={"row"}
                                    >
                                        <Box sx={{ width: '30%' }}>
                                            <RowInput
                                                label="CEP"

                                                input={<InputStyle onChange={e => {
                                                    setEndereco({ ...endereco, cep: e.target.value })
                                                    setIsChange(true);
                                                }}
                                                    onBlur={e => handleOnBlurSearchCep(e.target.value)}
                                                    value={endereco.cep}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '70%' }}>
                                            <RowInput
                                                label="Rua"
                                                input={<InputStyle onChange={e => {
                                                    setEndereco({ ...endereco, rua: e.target.value })
                                                    setIsChange(true);
                                                }} value={endereco.rua} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '25%' }}>
                                            <RowInput
                                                label="Número"
                                                input={<InputStyle onChange={e => {
                                                    setEndereco({ ...endereco, numero: e.target.value });
                                                    setIsChange(true);
                                                }} value={endereco.numero} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '75%' }}>
                                            <RowInput
                                                label="Bairro"
                                                input={<InputStyle onChange={e => {
                                                    setEndereco({ ...endereco, bairro: e.target.value });
                                                    setIsChange(true);
                                                }} value={endereco.bairro} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                    </Stack>


                                    <Stack
                                        sx={{ width: { xs: '100%', md: '100%' } }}
                                        spacing={1}
                                        direction={"row"}
                                    >
                                        <Box sx={{ width: '40%' }}>
                                            <RowInput
                                                label="Complemento"
                                                input={<InputStyle onChange={e => {
                                                    setEndereco({ ...endereco, complemento: e.target.value })
                                                    setIsChange(true);
                                                }} value={endereco.complemento} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '40%' }}>
                                            <RowInput
                                                label="Cidade"
                                                input={<InputStyle onChange={e => {
                                                    setEndereco({ ...endereco, cidade: e.target.value })
                                                    setIsChange(true);
                                                }} value={endereco.cidade} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '20%' }}>
                                            <RowInput
                                                label="Estado"
                                                input={<InputStyle onChange={e => {
                                                    setEndereco({ ...endereco, estado: e.target.value });
                                                    setIsChange(true);
                                                }} value={endereco.estado} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>


                                {isChange && (
                                    <Stack direction="row">
                                        <LoadingButton onClick={editarContato} loading={loadingEditarContato} size="small" variant='contained'>Salvar alterações</LoadingButton>
                                    </Stack>
                                )}


                            </Stack>
                        </Box>
                    )}


                </Box>


            </Scrollbar>

        </Stack>

    );
}
