import axios from "axios";
import Config from "src/config";

const v1_actionEncaminharMensagemWhatsapp = (querys, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/canais/encaminhar-mensagem${querys}`,
        data
    });
};

export default v1_actionEncaminharMensagemWhatsapp;
