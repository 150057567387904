import axios from "axios";
import Config from "../../../../src/config";

const actionCriarComentario = (chamadoId, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chamados/${chamadoId}/comentarios/criar`,
        data
    });
};

export default actionCriarComentario;
