
import {
    Autocomplete,
    Box,
    Divider,
    Grid,
    Stack,
    Typography,
    TextField,
    Alert,
    Button,
    Select,
    MenuItem
} from '@mui/material';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyleMultline from 'src/components/InputStyleMultline';
import { useEffect, useState } from 'react';
import v1_actionEditarFlow from 'src/actions/v1/clientes/v1_actionEditarFlow';
import { useSnackbar } from 'notistack';
import Iconify from 'src/components/Iconify';

export default function LaisConfig({
    onChange,
    canal,
    config
}) {


    return (
        <Stack spacing={4}>
            <Box sx={{ width: { xs: '100%', md: '100%' } }}>
                <Stack spacing={3}>

                    <Stack spacing={4}>
                        <RowInput
                            sx={{ width: '100%' }}
                            label="prompt"

                            input={<InputStyleMultline
                                multiline
                                rows={10}
                                size="small"
                                name="prompt"
                                value={config?.ai?.prompt}
                                onChange={onChange}
                                fullWidth
                            />}
                        />
                        <RowInput
                            sx={{ width: '100%' }}
                            label="mensagem apos direcionamento"
                            onChange={e => { }}
                            input={<InputStyleMultline
                                multiline
                                rows={3}
                                size="small"
                                name="msgAfterTargeting"
                                value={config?.ai?.messages?.msgAfterTargeting}
                                onChange={onChange}
                                fullWidth
                            />}
                        />
                        <Stack direction={"column"} spacing={2} justifyContent={"start"} >
                            <RowInput
                                label={"Provedor de IA"}
                                sx={{ width: { sm: '50%', md: '20%', lg: '30%', xl: '30%' } }}
                                input={<Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={config?.ai?.provider}
                                    size='small'
                                    name="providerAi"
                                    onChange={onChange}
                                >
                                    <MenuItem value={"gemini"}>Google Gemini</MenuItem>
                                    <MenuItem value={"openai"}>Openai ChatGPT</MenuItem>

                                </Select>}
                            />
                            {config?.ai?.provider === 'gemini' && (
                                <Stack direction={"row"} spacing={2}>
                                    <RowInput
                                        label={"Modelo de IA"}
                                        sx={{ width: { sm: '50%', md: '20%', lg: '30%', xl: '30%' } }}

                                        input={<Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={config?.ai?.gemini?.model}
                                            size='small'
                                            name="modelGemini"
                                            onChange={onChange}
                                        >
                                            <MenuItem value={"gemini-1.5-flash-001"}>Gemini Flash</MenuItem>
                                            <MenuItem value={"gemini-1.5-pro"}>Gemini 1.5 Pro</MenuItem>

                                        </Select>}
                                    />
                                    <RowInput
                                        label="Api Key"
                                        sx={{width:'100%'}}
                                        input={<InputStyle
                                            name="apiKeyGemini"
                                            onChange={onChange}
                                            value={canal?.config?.ai?.gemini?.apiKey}
                                        />}
                                    />
                                </Stack>
                            )}

                            {config?.ai?.provider === 'openai' && (
                                <Stack direction={"row"} spacing={2}>
                                    <RowInput
                                        label={"Modelo de IA"}
                                        sx={{ width: '100%'}}
                                        input={<Select
                                            labelId="demo-simple-select-label"
                                            id="demo-simple-select"
                                            value={canal?.config?.ai?.openai?.model}
                                            size='small'
                                            name="modelOpenai"
                                            onChange={onChange}
                                        >
                                            <MenuItem value={"gpt-3.5-turbo"}>GPT 3.5 Turbo</MenuItem>
                                            <MenuItem value={"gpt-4"}>GPT 4</MenuItem>
                                            <MenuItem value={"gpt-4-turbo"}>GPT 4 Turbo</MenuItem>
                                            <MenuItem value={"gpt-4o"}>GPT 4o</MenuItem>

                                        </Select>}
                                    />
                                    <RowInput
                                        label="Api Key"
                                        sx={{ width: '100%'}}
                                        input={<InputStyle
                                            name="apiKeyOpenai"
                                            onChange={onChange}
                                            value={canal?.config?.ai?.openai?.apiKey}
                                        />}
                                    />
                                    <RowInput
                                        label="Organization"
                                        sx={{ width: '100%'}}
                                        input={<InputStyle
                                            name="organizationOpenai"
                                            onChange={onChange}
                                            value={canal?.config?.ai?.openai?.organization}
                                        />}
                                    />
                                </Stack>
                            )}



                        </Stack>
                        {/* <RowInput
                            sx={{ width: '100%' }}
                            label="tipo de mensagens permitidas"
                            input={<Autocomplete
                                multiple
                                disabled
                                id="tags-standard"
                                value={dataFlow?.config?.allowsInChat}
                                options={[
                                    "imagem",
                                    "audio",
                                    "arquivos"
                                ]}
                                onChange={(event, newValue) => setDataFlow({
                                    ...dataFlow,
                                    config: {
                                        ...dataFlow.config,
                                        allowsInChat: newValue
                                    }
                                })}

                                //     renderTags={(value, getTagProps) =>
                                //         value.map((option, index) => (
                                //             <Typography variant="body2" sx={{ fontWeight: 600 }} key={index} {...getTagProps({ index })}>
                                //                 {option}
                                //             </Typography>
                                //         ))
                                //    }
                                getOptionLabel={(option) => option}
                                renderInput={(params) => (
                                    <TextField
                                        size='small'
                                        {...params}
                                    />
                                )}
                            />}
                        /> */}
                        {/* 
                        {dataFlow?.config?.allowsInChat?.includes('audio') && (
                            <Alert severity="info">
                                <Typography variant="body2">
                                    Com a opção de permissão de audio nas mensagens ativado, serão debitados os tokens necessários para transcrição do audio.
                                </Typography>
                            </Alert>
                        )} */}


                    </Stack>
                    <Stack direction="row" spacing={2}>
                        <RowInput
                            sx={{ width: '100%' }}
                            label="Mensagem recusa envio de audios"
                            name="msgBreakAudios"

                            onChange={e => onChange(e)}
                            input={<InputStyleMultline
                                multiline
                                rows={5}
                                size="small"
                                name="msgBreakAudios"
                                onChange={onChange}
                                value={config?.ai?.messages?.msgBreakAudios}
                                fullWidth
                            />}
                        />
                        <RowInput
                            sx={{ width: '100%' }}
                            label="Mensagem recusa envio de imagens"
                            input={<InputStyleMultline
                                multiline
                                name="msgBreakImages"
                                onChange={onChange}
                                value={config?.ai?.messages?.msgImages}
                                rows={5}
                                size="small"
                                fullWidth
                            />}
                        />
                        <RowInput
                            sx={{ width: '100%' }}
                            label="Mensagem recusa envio de arquivos"
                            input={<InputStyleMultline
                                multiline
                                rows={5}
                                size="small"
                                name="msgBreakArquivos"
                                onChange={onChange}
                                value={config?.ai?.messages?.msgBreakFiles}
                                fullWidth
                            />}
                        />
                    </Stack>
                </Stack>
            </Box>

        </Stack>

    );
}
