import { filter } from 'lodash';

import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
// material
import { useTheme } from '@mui/material/styles';
import {
    Table,
    Stack,

    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Container,
    Drawer
} from '@mui/material';

// redux
import { useDispatch, useSelector } from 'src/redux/store';
import { getListaUsuariosByClienteId } from 'src/redux/slices/user';

// hooks
import useSettings from 'src/hooks/useSettings';
// components
import { UserListHead } from 'src/components/_dashboard/user/list';
import Page from 'src/components/Page';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import BadgeStatus from 'src/components/BadgeStatus';
import { nomeProjeto } from 'src/config';
import AgentesMoreMenu from 'src/components/_chats/agentes/AgentesMoreMenu';
import actionEditarSenha from 'src/actions/usuarios/actionEditarSenhaUsuario';
import actionEditarPerfil from 'src/actions/usuarios/actionEditarPerfil';
import { useSnackbar } from 'notistack';
import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/material';
import { TableBorder } from 'src/components/table';
import { formataNumeroCelular, soNumeros } from 'src/utils/functions';
import Iconify from 'src/components/Iconify';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import ModalEditarAgente from '../../cadastro/ModalEditarAgente';
import ModalEditarSenhaAgente from '../../cadastro/ModalEditarSenhaAgente';
import TableUsuarios from './TableUsuarios';
import { Card, CardContent } from 'src/@/components/ui/card';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'nome', label: 'Nome Fantasia', alignRight: false },
    { id: 'setor', label: 'Setor', alignRight: false },
    { id: 'ramal', label: 'Ramal', alignRight: false }, ,
    { id: 'status', label: 'Status', alignRight: false },
    { id: 'Email', label: 'Email', alignRight: false },
    { id: 'Acoes', label: 'Ações', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.nomeFantasia.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function UsuariosComponent({ user }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const { usuariosList } = useSelector((state) => state.user);
    const { onlineUsers } = useSelector((state) => state.staff);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);
    const [isLoading, setIsLoading] = useState(false);
    const [openEditarSenha, setOpenEditarSenha] = useState(false);
    const [openEditarPerfil, setOpenEditarPerfil] = useState(false);
    const [senha, setSenha] = useState('');
    const [confirmarSenha, setConfirmarSenha] = useState('');
    const [usuarioId, setUsuarioId] = useState('');
    const { logout } = useAuth();

    const [dataUsuario, setDataUsuario] = useState(null)

    useEffect(() => {
        dispatch(getListaUsuariosByClienteId(user.clienteId));
    }, []);


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = usuariosList.map((n) => n.nome);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleOpenDialogEditarPerfil = (usuario) => {
        const codigoPais = usuario.telefone && usuario.telefone.substring(0, 2);
        const ddd = usuario.telefone && usuario.telefone.substring(2, 4);
        const numeroTelefone = usuario.telefone && usuario.telefone.substring(4);

        setDataUsuario(usuario);
        setOpenEditarPerfil(true)
    }

    const handleOpenDialogEditarSenha = (data) => {
        setDataUsuario(data)
        setOpenEditarSenha(true)
    }

    const handleCloseDialogEditarSenha = () => {
        setOpenEditarSenha(false)
    }


    const resetarCampos = () => {
        setUsuarioId('');
        setSenha('');
        setConfirmarSenha('');
    }


    ///// editar usuario
    const handleEditarSenha = () => {
        actionEditarSenha({
            usuarioId: usuarioId,
            password: senha,
            repeatPassword: confirmarSenha,
        }).then(res => {
            dispatch(getListaUsuariosByClienteId(user.clienteId));
            setOpenEditarSenha(false);
            resetarCampos();
        }).catch(error => console.log('error', error));
    }




    const filteredUsers = applySortFilter(usuariosList, getComparator(order, orderBy), filterName);

    return (

        <Container maxWidth="lg">
            <HeaderPageComponentConfig
                titulo="Usuários"
                descricao={'O recurso usuários contém todos os agentes que sua empresa está envolvida. É possível editar usuários e senhas de acesso.'}
            // actions={<Button variant='contained'>Teste</Button>}
            />


            <ModalEditarSenhaAgente
                open={openEditarSenha}
                dataUsuario={dataUsuario}
                onCallback={() => setOpenEditarSenha(false)}
                onClose={() => setOpenEditarSenha(false)}
                callbackEdit={() => {
                    setOpenEditarSenha(false);
                    resetarCampos();
                }}
            />

            <ModalEditarAgente
                user={user}
                open={openEditarPerfil}
                onClose={() => setOpenEditarPerfil(false)}
                dataUsuario={dataUsuario}
                callbackEdit={() => {
                    setOpenEditarPerfil(false);
                    setDataUsuario(null);
                    dispatch(getListaUsuariosByClienteId(user.clienteId));
                }}
            />

            <div className='mt-5'>
                <Card>
                    <CardContent>
                        <TableUsuarios
                            data={filteredUsers}
                            onEditSenha={data => handleOpenDialogEditarSenha(data)}
                            onEditUsuario={data => handleOpenDialogEditarPerfil(data)}
                        />
                    </CardContent>
                </Card>

                {/* <TableBorder>

                    <Scrollbar>
                        <TableContainer sx={{ minWidth: 300 }}>
                            <Table size="small" aria-label="a dense table">
                                <UserListHead
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    rowCount={usuariosList.length}
                                    numSelected={selected.length}
                                    onRequestSort={handleRequestSort}
                                    onSelectAllClick={handleSelectAllClick}

                                />
                                <TableBody dense>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                                        const { firstName, lastName, nome_setor, nomeFantasia, _id, status, username, email, ramal, telefone, setor_id } = row;
                                        let dataUsuario = {
                                            firstName, lastName, nomeFantasia, _id, username, email, telefone, setor_id
                                        }
                                        const isItemSelected = selected.indexOf(nomeFantasia) !== -1;


                                        return (
                                            <TableRow
                                                hover
                                                // key={id}
                                                tabIndex={-1}
                                                sx={{ height: 50 }}

                                                role="checkbox"
                                                selected={isItemSelected}
                                                aria-checked={isItemSelected}
                                            >

                                                <TableCell width={100} component="th" scope="row" padding="none">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <BadgeStatus status={getStatus(row)} size="large" />
                                                        <Typography variant="caption" noWrap>
                                                            {nomeFantasia}
                                                        </Typography>

                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">

                                                    <Typography variant="caption" noWrap>  {nome_setor}</Typography>
                                                </TableCell>
                                                <TableCell align="left">{ramal}</TableCell>
                                                <TableCell align="left">
                                                    <Label
                                                        variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
                                                        color={(status === false && 'error') || 'success'}
                                                    >
                                                        {status === false ? "Desativado" : "Ativo"}
                                                    </Label>
                                                </TableCell>
                                                <TableCell align="left">  <Typography variant="caption" noWrap>  {email}</Typography></TableCell>

                                                <TableCell align="left">
                                                    <Stack direction="row" alignItems="center" spacing={2}>
                                                        <Iconify onClick={() => {
                                                            handleOpenDialogEditarSenha(dataUsuario)
                                                        }
                                                        } style={{ cursor: 'pointer' }} icon="bi:shield-lock" color="primary" />
                                                        <Iconify onClick={() => {
                                                            handleOpenDialogEditarPerfil(dataUsuario)
                                                        }
                                                        } style={{ cursor: 'pointer' }} icon="bi:pencil" color="primary" />
                                                    </Stack>

                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>
                                {isUserNotFound && (
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                                                <SearchNotFound searchQuery={filterName} />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                )}
                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination

                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={usuariosList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBorder>  */}
            </div>
        </Container>


    );
}
