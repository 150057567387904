import axios from "axios";

import Config from "../../../config";

const v2_actionUploadS3 = (data, querys) => {
    let url = `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/s3/upload${querys}`;
    return axios({
        method: "post",
        url:url,
        data
    });
};

export default v2_actionUploadS3;
