import { useNavigate } from "react-router"
import Iconify from "src/components/Iconify";
import { Link } from "@mui/material";
import { verifyRules } from "src/utils/functions";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "src/@/components/ui/card";
import { Progress } from "src/@/components/ui/progress";


const CreditQuotaCard = () => {
    return (
        <Card className="max-w-xs shadow-sm rounded-lg border border-gray-200  m-1.5">
            <CardContent className="flex justify-between items-center p-3">
                <div className="flex flex-col w-full">
                    <div>
                        <p className="text-gray-500 font-semibold text-xs  py-1 text-left rounded">Creditos</p>
                        <div className="flex justify-between w-full mt-3">
                            <div className="flex flex-col text-left">
                                <p className="text-gray-600 text-[10px]">usados</p>
                                <p className="text-xs font-bold">200</p>
                            </div>
                            <div className="flex flex-col text-right">
                                <p className="text-gray-600 text-[10px]">restantes</p>
                                <p className="text-xs font-bold">2000</p>
                            </div>
                        </div>

                    </div>
                    <div>
                        <Progress value={10} className="w-full h-2 mt-1 bg-slate-200" />
                    </div>
                </div>



            </CardContent>
        </Card>
    );
};

export default function SidebarDashboard({
    user
}) {
    const navigate = useNavigate();
    const isAdmin = user?.level === "root";

    const LinkItem = ({ title, onClick }) => {
        return (
            <Link
                onClick={onClick}
                className="flex items-center gap-3 rounded-lg px-3 py-1.5 text-muted-foreground transition-all hover:text-primary cursor-pointer"
            >
                {title}
            </Link>
        )
    }

    const LinkHeader = ({ title, icon }) => {
        return (
            <div className="flex items-center gap-1 mb-2">
                <Iconify size={15} icon={icon} className="text-primary" />
                <div className="text-xs font-semibold">{title}</div>
            </div>
        )
    }


    return (
        <div className="hidden border-r bg-slate-100  md:block">
            <div className="flex h-full max-h-screen flex-col gap-2">

                <div className="flex-1">
                    <nav className="grid items-start px-2 text-sm font-medium lg:px-4 mt-5">
                        <div className="mb-4">
                            <LinkHeader title="Conta" icon="octicon:organization-24" />
                            <LinkItem title="Perfil" onClick={() => navigate('/dashboard/conta/perfil')} />
                            {/* <LinkItem title="Consumo" onClick={() => navigate('/dashboard/conta/consumo')} /> */}
                        </div>
                        <div className="mb-4">
                            <LinkHeader title="Configurações" icon="ion:settings-outline" />

                            {(verifyRules(user.rules, "canais") || isAdmin) && (
                                <LinkItem title="Canais" onClick={() => navigate('/dashboard/configuracoes/canais')} />
                            )}

                            {(verifyRules(user.rules, "gerenciar_usuarios") || isAdmin) && (
                                <LinkItem title="Usuários" onClick={() => navigate('/dashboard/configuracoes/usuarios')} />
                            )}

                            {(verifyRules(user.rules, "gerenciar_setores") || isAdmin) && (
                                <LinkItem title="Setores" onClick={() => navigate('/dashboard/configuracoes/setores')} />

                            )}

                            {(verifyRules(user.rules, "gerenciar_funis") || isAdmin) && (
                                <LinkItem title="Funil" onClick={() => navigate('/dashboard/configuracoes/funil-vendas')} />
                            )}

                            {(verifyRules(user.rules, "gerenciar_integrações") || isAdmin) && (
                                <LinkItem title="Integrações" onClick={() => navigate('/dashboard/configuracoes/integracoes')} />
                            )}

                            {(verifyRules(user.rules, "respostas_rapidas") || isAdmin) && (
                                <LinkItem title="Respostas Rápidas" onClick={() => navigate('/dashboard/configuracoes/respostas-rapidas')} />
                            )}

                            <LinkItem title="Etiquetas" onClick={() => navigate('/dashboard/configuracoes/tags')} />

                            {/* <LinkItem title="Fluxos" onClick={() => navigate('/dashboard/configuracoes/flows')} /> */}

                            {/* <LinkItem title="Credenciais" onClick={() => navigate('/dashboard/configuracoes/credenciais')} /> */}

                        </div>

                        {(verifyRules(user.rules, "agentes_ia")) && (
                            <div className="mb-4">
                                <LinkHeader title="IA" icon="streamline:ai-settings-spark-solid" />
                                <LinkItem title="Agentes" onClick={() => navigate('/dashboard/configuracoes/agentes-ia')} />
                                <LinkItem title="Vozes" onClick={() => navigate('/dashboard/configuracoes/aiVoices')} />
                                {/* <LinkItem title="Credenciais" onClick={() => navigate('/dashboard/configuracoes/credenciais')} /> */}
                            </div>)}

                    </nav>




                </div>
                {/* <CreditQuotaCard /> */}
            </div>
        </div>
    );
}
