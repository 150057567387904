import axios from "axios";
import Config from "../../../config";

const v2_actionDeletarLead = (leadId) => {
    return axios({
        method: "delete",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/crm/leads/${leadId}/deletar`
    });
};

export default v2_actionDeletarLead;
