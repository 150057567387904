import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    Container,
    Box,
    Grid,
    Divider,
    LinearProgress,
    DialogActions
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import v2_actionCriarTreinamentoAgente from 'src/actions/v2/cliente/flows/v2_actionCriarTreinamentoAgente';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import InputStyleMultline from 'src/components/InputStyleMultline';
// @mui

ModalTraining.propTypes = {
    open: PropTypes.bool,
    handleClose: PropTypes.func,
};

export default function ModalTraining({ 
    flow,
    open, 
    onClose, 
    clienteId, 
    flowId, 
    onCallback,
    isEdit,
    messagetraing
 }) {

    const [loading, setLoading] = useState(false);
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');
    const [instruction, setInstruction] = useState('');


    async function handleSave() {
        try {
            let data = {
                scope: {
                    title,
                    description,
                    instruction
                }
            }
            await v2_actionCriarTreinamentoAgente(clienteId, flowId, data)
            onCallback();
            onClose();
            setTitle('');
            setDescription('');
            setInstruction('');

        } catch(err) {
            console.log(err)
        }
    }

    useEffect(() => {
      if(messagetraing && isEdit) {
        setTitle(messagetraing.title)
        setDescription(messagetraing.description)
        setInstruction(messagetraing.content)
      }
    }, [messagetraing])
    



    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'md'}
            fullWidth

        >
      

            <DialogTitle sx={{
                background: theme => theme.palette.primary.main, py: 1, color: '#fff'
            }}>
                Adicionar Treinamento
            </DialogTitle>
            <Container>
                <DialogContent sx={{ mt: 3 }}>

                    <Stack direction="column" spacing={2}>
                        <RowInput
                            label="Titulo"
                            input={<InputStyle value={title} onChange={e => setTitle(e.target.value)} />}
                        />
                        <RowInput
                            label="Descrição"
                            input={<InputStyle value={description} onChange={e => setDescription(e.target.value)} />}

                        />
                        <RowInput
                            label="Instrução"
                            input={<InputStyleMultline rows={3} multiline value={instruction} onChange={e => setInstruction(e.target.value)} />}

                        />
                    </Stack>
                </DialogContent>
                <Divider sx={{borderStyle:'dashed'}} />
                <DialogActions>
                    <Button onClick={onClose}>Cancelar</Button>
                    <LoadingButton loading={false} onClick={handleSave} variant="contained" color="primary">Salvar</LoadingButton>
                </DialogActions>
            </Container>
        </Dialog>
    );
}


