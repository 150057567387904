import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import TimelineOppositeContent, {
    timelineOppositeContentClasses,
} from '@mui/lab/TimelineOppositeContent';

import {fDateTime} from 'src/utils/formatTime'

export default function DrawerNegocioTimeline({ data }) {
    // console.log('listaHistorico', data)
    // const listaHistorico = data && data.lenght > 0 && data.sort((a, b) => {
    //     const dateA = new Date(a.date);
    //     const dateB = new Date(b.date);
    //     return dateA - dateB;
    //   });;
    // console.log('listaHistorico', listaHistorico)

    return (
        <Timeline
            sx={{
                padding:0,
                marginTop:2,
                [`& .${timelineOppositeContentClasses.root}`]: {
                    flex: 0.,
                },
            }}
        >

            {data && data.map((option) => (
                
                <TimelineItem >
                    <TimelineOppositeContent sx={{fontSize:12}} color="textSecondary">

                        {fDateTime(option.date)}
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                        <TimelineDot />
                        <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent sx={{fontSize:14}}>
                        {option.descricao}
                        </TimelineContent>
                </TimelineItem>
            ))}

        </Timeline>
    );
}
