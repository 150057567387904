import React from 'react';

const SkeletonRow = () => (
  <div className="flex items-center justify-between p-2 border-b border-gray-200">
    <div className="w-20 h-6 bg-gray-300 rounded animate-pulse"></div>
    <div className="w-40 h-6 bg-gray-300 rounded animate-pulse"></div>
    <div className="w-60 h-6 bg-gray-300 rounded animate-pulse"></div>
    <div className="w-40 h-6 bg-gray-300 rounded animate-pulse"></div>
    <div className="w-40 h-6 bg-gray-300 rounded animate-pulse"></div>
    <div className="w-20 h-6 bg-gray-300 rounded animate-pulse"></div>
    <div className="w-8 h-8 bg-gray-300 rounded-full animate-pulse"></div>
  </div>
);

const SkeletonSupervisionList = () => (
  <div className="p-4">
    {/* Cabeçalhos da Tabela */}
    <div className="flex justify-between p-4 border-b-2 border-gray-200">
      <div className="w-20 h-6 bg-gray-300 rounded animate-pulse"></div>
      <div className="w-40 h-6 bg-gray-300 rounded animate-pulse"></div>
      <div className="w-60 h-6 bg-gray-300 rounded animate-pulse"></div>
      <div className="w-40 h-6 bg-gray-300 rounded animate-pulse"></div>
      <div className="w-40 h-6 bg-gray-300 rounded animate-pulse"></div>
      <div className="w-20 h-6 bg-gray-300 rounded animate-pulse"></div>
      <div className="w-8 h-8 bg-gray-300 rounded-full animate-pulse"></div>
    </div>
    
    {/* Linhas da Tabela */}
    {Array.from({ length: 10 }).map((_, index) => (
      <SkeletonRow key={index} />
    ))}
  </div>
);

export default SkeletonSupervisionList;
