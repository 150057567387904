import * as React from 'react';
import { DialogTitle } from '@mui/material'
import Iconify from '../Iconify';

export default function HeaderModal({
    title
}) {

    return (
        <DialogTitle
            sx={{
                background: theme => theme.palette.primary.main, py: 1, color: '#fff'
            }}
        >
            {title}
        </DialogTitle>
    );
}
