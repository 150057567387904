import React, { useEffect, useState } from 'react';

import {
    Stack,
    Button,
    Container,
    Typography,
    IconButton,
    Box,
    Drawer,
    Divider
} from '@mui/material';


import LoadingButton from '@mui/lab/LoadingButton';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import v2_actionCriarPipeline from 'src/actions/v2/cliente/v2_actionCriarPipeline';
import v2_actionEditarPipeline from 'src/actions/v2/crm/v2_actionEditarPipeline';


export default function DrawerNovoFunil({
    user,
    cliente,
    open,
    isEdit,
    pipelineId,
    id,
    anchorEl,
    onClose,
    onCallback,
    pipeline
}) {

    const [loading, setLoading] = useState(false);
    const [nome, setNome] = useState('');
    const [descricao, setDescricao] = useState('');

    async function handleCriarFunil() {
        try {
            setLoading(true);
            let data = {
                scope: {
                    nome: nome,
                    descricao: descricao,
                    criadoPor: user._id

                }
            }
            let result = await v2_actionCriarPipeline(cliente._id, data);
            if (result.data.data) {
                onCallback();
                onClose();
                setLoading(false);

            }
        } catch (error) {
            setLoading(false);
            console.log(error);
        }
    }

    async function handleEditarPipilene(id, scope) {
        try {
          let data = {
            action: "editar_pipeline",
            scope: scope
          }
          await v2_actionEditarPipeline(id, data, cliente._id);
          onCallback();
          setNome('');
            setDescricao('');
    
        } catch (error) {
              
        }
    
      }

    function handleOnClose() {
        onClose();
        setNome('');
        setDescricao('');
    }

    useEffect(() => {
        if (isEdit) {
            setNome(pipeline.pipeline_nome);
            setDescricao(pipeline.description);
        }

   
    }, [pipeline])


    return (
        <Drawer anchor="right" open={open} onClose={handleOnClose}>
            <Stack sx={{ height: '100vh' }} justifyContent={"space-between"} direction={"column"}>
                <Stack>
                    <Box sx={{ width: 450, p: 2 }}>
                        <Stack alignItems={'center'} justifyContent={'space-between'} direction={"row"}>
                            <Typography variant='h6'>
                                {isEdit ? "Editar" : "Criar"} Funil
                            </Typography>
                        </Stack>
                        <Divider sx={{ mt: 2 }} />

                        <Stack direction={"column"} spacing={3} sx={{ mt: 2 }}>

                            <RowInput
                                label="Nome"
                                input={
                                    <InputStyle value={nome} onChange={(event) => setNome(event.target.value)} />

                                }
                            />
                            <RowInput
                                label="Descrição"
                                input={
                                    <InputStyle value={descricao} onChange={(event) => setDescricao(event.target.value)} />

                                }
                            />

                            {/* <Stack direction={"column"} spacing={2}>
          <Typography variant='subtitle2'>
            Estágios
          </Typography>
          <Stack sx={{ mt: 2 }} spacing={1} direction={"column"}>
            {
              pipelineStage?.estagios?.map((item, index) => (
                <PipileneStageItem isModal isEdit={isEdit} funil={item} />
              ))
            }
          </Stack>
        </Stack> */}

                        </Stack>
                    </Box>
                </Stack>

                <Stack>
                    <LoadingButton
                        loading={loading}
                        onClick={() => {
                            if(isEdit){
                                handleEditarPipilene(pipelineId, {nome: nome, descricao: descricao})
                            } else {
                                handleCriarFunil()
                            }
                        }}
                        variant="contained"
                        sx={{
                            borderRadius: 0, width: 1, height: 70
                        }} >
                        {isEdit ? "Editar" : "Criar"} Funil
                    </LoadingButton>
                </Stack>
            </Stack>

        </Drawer>
    );
}


