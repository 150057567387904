import axios from "axios";

import Config from "../../config";

const actionUploadFileMeta = (data, querys) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/s3/meta/upload-file${querys}`,
        data
    });
};

export default actionUploadFileMeta;
