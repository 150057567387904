// @mui
import { styled } from '@mui/material/styles';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

const InputStyle = styled(TextField, {
  shouldForwardProp: (prop) => prop !== 'stretchStart',
})(({ stretchStart, theme }) => ({
  '& .MuiOutlinedInput-root': {
    transition: theme.transitions.create(['box-shadow', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.shorter,
    }),
    // '&.Mui-focused': {
    //   boxShadow: theme.customShadows.z12,
    // },
    ...(stretchStart && {
      width: stretchStart,
      '&.Mui-focused': {
        boxShadow: theme.customShadows.z12,
        [theme.breakpoints.up('sm')]: {
          width: stretchStart + 60,
        },
      },
    }),
    padding:'3px 0',
    height:40, 
    fontSize:14,
    background:'white'
  },
  '& fieldset': {
   // backgroundColor: theme.palette.grey[500_12],
    borderWidth: `1px !important`,
    borderRadius:5,
  
    borderColor: `${theme.palette.grey[500]} !important`,
  },
}));

export default InputStyle;
