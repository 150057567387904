import { filter } from 'lodash';
import { useState, useEffect } from 'react';
// material
import { useTheme } from '@mui/material/styles';
import {
    Table,
    Stack,
    TableRow,
    TableBody,
    TableCell,
    Typography,
    TableContainer,
    TablePagination,
    Container,
    IconButton,
    TableHead
} from '@mui/material';

// redux
import { useDispatch, useSelector } from 'src/redux/store';

// components
import { UserListHead } from 'src/components/_dashboard/user/list';
import Label from 'src/components/Label';
import Scrollbar from 'src/components/Scrollbar';
import SearchNotFound from 'src/components/SearchNotFound';
import useAuth from 'src/hooks/useAuth';
import { Box } from '@mui/material';
import { TableBorder } from 'src/components/table';
import Iconify from 'src/components/Iconify';
import ModalTraining from '../dialogs/ModalTraining';
import v2_actionListaTreinamentoAgentes from 'src/actions/v2/cliente/flows/v2_actionListaTreinamentoAgentes';
import v2_actionListaFluxosDiretos from 'src/actions/v2/cliente/v2_actionListaFluxosDiretos';
import v2_actionDeleteFlowDirect from 'src/actions/v2/cliente/v2_actionDeleteFlowDirect';
import ModalDirecionamento from '../dialogs/ModalDirecionamento';
import { Button } from 'src/@/components/ui/button';

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'title', label: 'Titulo', alignRight: false },
    { id: '' },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    if (query) {
        return filter(array, (_user) => _user.nomeFantasia.toLowerCase().indexOf(query.toLowerCase()) !== -1);
    }
    return stabilizedThis.map((el) => el[0]);
}

export default function TrainingConfig({ 
    flow,
    listSectors,
    listUsers,
 }) {
    const theme = useTheme();
    const dispatch = useDispatch();
    const { user } = useAuth();
    const { usuariosList } = useSelector((state) => state.user);
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [selected, setSelected] = useState([]);
    const [orderBy, setOrderBy] = useState('nome');
    const [filterName, setFilterName] = useState('');
    const [rowsPerPage, setRowsPerPage] = useState(25);

    const [openModalTraining, setOpenModalTraining] = useState(false);
    const [list, setList] = useState([]);
    const [isEdit, setIsEdit] = useState(false);

    const [messageTraining, setMessageTraining] = useState('')

    async function getList() {
        try {
            const response = await v2_actionListaFluxosDiretos(user.clienteId);
            setList(response.data.data)
        } catch (error) {
            console.log(error);
        }

    }

    useEffect(() => {
        getList()
    }, []);

    async function handleDeleteFlowDirect(id) {
        try {
            await v2_actionDeleteFlowDirect(id);
            getList()
        } catch (error) {
            console.log(error)
        }
    }


    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - list.length) : 0;
    const filteredUsers = applySortFilter(list, getComparator(order, orderBy), filterName);

    return (
        <Box sx={{ mt: 2 }}>



            <ModalDirecionamento
                open={openModalTraining}
                onClose={() => {
                    setOpenModalTraining(false)
                }}
                onCallback={() => getList()}
                clienteId={user.clienteId}
                listUsers={listUsers}
                listSectors={listSectors}
            />

            <Stack spacing={3} direction={"column"}>
                <Stack justifyContent={"space-between"} spacing={2} direction={"row"}>

                    <Stack direction="row" spacing={1}>
                        <Button
                            onClick={() => setOpenModalTraining(true)}
                        >
                            <Iconify className="mr-2" icon="fluent:calendar-template-20-filled" />
                            criar direcionamento
                        </Button>
                    </Stack>
                </Stack>
                <TableBorder>

                    <Scrollbar sx={{ height: '30vh' }}>
                        <TableContainer sx={{ minWidth: 300 }}>
                            <Table size="small" aria-label="a dense table">

                                <TableHead>
                                    <TableRow>
                                        <TableCell>Recebe</TableCell>
                                     
                                        <TableCell >Setores</TableCell>
                                        <TableCell >Usuarios</TableCell>
                                        <TableCell ></TableCell>

                                    </TableRow>
                                </TableHead>

                                <TableBody dense>
                                    {filteredUsers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {

                                        return (
                                            <TableRow
                                                hover
                                                key={row._id}
                                                tabIndex={-1}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                role="checkbox"

                                            >

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Typography variant="subtitle2" noWrap>

                                                        {row.recebe}
                                                    </Typography>
                                                </TableCell>

                                                <TableCell component="th" scope="row" padding="none">
                                                    <Typography variant="caption" noWrap>
                                                        {row.setores?.length > 0 && row.setores.map((item, index) => item.nome_setor)}                                                        </Typography>
                                                </TableCell>


                                                <TableCell component="th" scope="row" padding="none">
                                                    <Typography variant="caption" noWrap>
                                                        {row.usuarios?.length > 0 && row.usuarios.map((item, index) => item.nomeFantasia)}
                                                    </Typography>
                                                </TableCell>





                                                <TableCell width={50} align="left">
                                                    <Stack direction={"row"} spacing={1}>
                                              
                                                        <IconButton
                                                            onClick={() => handleDeleteFlowDirect(row._id)}
                                                        >
                                                            <Iconify icon={"icon-park-twotone:delete"} width={20} height={20} />
                                                        </IconButton>
                                                    </Stack>
                                                </TableCell>


                                            </TableRow>
                                        );
                                    })}
                                    {emptyRows > 0 && (
                                        <TableRow style={{ height: 53 * emptyRows }}>
                                            <TableCell colSpan={6} />
                                        </TableRow>
                                    )}
                                </TableBody>

                            </Table>
                        </TableContainer>
                    </Scrollbar>

                    <TablePagination

                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={usuariosList.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </TableBorder>
            </Stack>




        </Box>
    );
}
