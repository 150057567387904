import { Suspense, lazy } from 'react';
import { Navigate, useRoutes, useLocation } from 'react-router-dom';

// layouts
import MensagensAgendadas from 'src/pages/chats/MensagensAgendadas';
import Marketing from 'src/pages/dashboard/Marketing';

import LoadingScreen from '../components/LoadingScreen';
import AuthGuard from '../guards/AuthGuard';
import GuestGuard from '../guards/GuestGuard';
import ChatsLayout from '../layouts/chats';
import DashboardLayout from '../layouts/dashboard';
import LogoOnlyLayout from '../layouts/LogoOnlyLayout';
// guards
// import RoleBasedGuard from '../guards/RoleBasedGuard';
// components

import MainLayout from '../layouts/main';
import Relatorios from 'src/pages/dashboard/Relatorios';
import RelatorioFunilVendas from 'src/pages/dashboard/Relatorios/RelatorioFunilVendas';
import RelatorioForcaVendas from 'src/pages/dashboard/Relatorios/RelatorioForcaVendas1';
import Gerencia from 'src/pages/dashboard/Gerencia';
import ChamadosPage from 'src/pages/dashboard/Chamados';
import MeusChamados from 'src/pages/dashboard/Chamados/MeusChamados';
import RelatorioForcaVendasClientes from 'src/pages/dashboard/Relatorios/RelatorioForcaVendasClientes';
import SupervisaoLista from 'src/pages/dashboard/Supervisao/SupervisaoLista';
import RelatorioChamados from 'src/pages/dashboard/Relatorios/RelatorioChamados';

import Chat from 'src/pages/chats/Chat';
import Contatos from 'src/pages/chats/Contatos';
import Negocios from 'src/pages/dashboard/Negocios/Negocios';
import RelatorioListaAvaliacoes from 'src/pages/dashboard/Relatorios/RelatorioListaAvaliacoes';
import RelatorioAnalitcoNegocios from 'src/pages/dashboard/Relatorios/RelatorioAnalitcoNegocios';
import  Configuracoes from 'src/pages/dashboard/Configuracoes';
import Conta from 'src/pages/dashboard/Conta';
import Suspend from 'src/pages/Suspend';
import { VendasPage } from 'src/pages/dashboard/Vendas';
import AiAnaliseSentimento from 'src/pages/dashboard/AiAnaliseSentimento/AiAnaliseSentimento';

// ----------------------------------------------------------------------

// eslint-disable-next-line react/display-name
const Loadable = (Component) => (props) => {
  const { pathname } = useLocation();
  const isDashboard = pathname.includes('/dashboard');

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: 'fixed'
            })
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: 'auth',
      children: [
        {
          path: 'login',
          element: (
            <GuestGuard>
              {/* <Login /> */}
            </GuestGuard>
          )
        },
        {
          path: 'register',
          element: (
            <GuestGuard>
              <Register />
            </GuestGuard>
          )
        },
        { path: 'login-unprotected', element: <Login /> },
        { path: 'register-unprotected', element: <Register /> },
        { path: 'reset-password', element: <ResetPassword /> },
        { path: 'verify', element: <VerifyCode /> },
        { path: 'suspend', element: <Suspend /> },

      ]
    },

    // Dashboard Routes
    {
      path: 'dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="home" replace /> },
        { path: 'app', element: <GeneralApp /> },
        { path: 'home', element: <Painel /> },
        { path: 'supervisao/lista', element: <SupervisaoLista /> },
        { path: 'ai/analyze', element: <AiAnaliseSentimento /> },
        { path: 'relatorios', element: <Relatorios /> },
        { path: 'negocios', element: <Negocios /> },
        { path: 'gerencia', element: <Gerencia /> },
        { path: 'contatos', element: <Contatos /> },
        { path: 'vendas', element: <VendasPage /> },

        { path: 'chamados', element: <ChamadosPage /> },
        { path: 'meus-chamados', element: <MeusChamados /> },
        { path: 'respostas-rapidas', element: <MensagensRapidas /> },
        { path: 'relatorios/funil', element: <RelatorioFunilVendas /> },
        { path: 'relatorios/forca-vendas', element: <RelatorioForcaVendas /> },
        { path: 'relatorios/clientes-forca-vendas', element: <RelatorioForcaVendasClientes /> },
        { path: 'relatorios/chamados', element: <RelatorioChamados /> },
        { path: 'relatorios/chats-avaliacoes', element: <RelatorioListaAvaliacoes /> },
        { path: 'relatorios/negocios', element: <RelatorioAnalitcoNegocios /> },
        { path: 'campanhas', element: <Campanhas /> },
        { path: 'calendar', element: <Calendar /> },
        { path: 'configuracoes/:label', element: <Configuracoes /> },
        { path: 'conta/:label', element: <Conta /> },
        { path: 'marketing/:label', element: <Marketing /> }
      ]
    },

    // Chat Routes
    {
      path: 'chats',
      element: (
        <AuthGuard>
          <ChatsLayout />
        </AuthGuard>
      ),
      children: [
        { path: '', element: <Navigate to="chat" replace /> },
        {
          path: 'chat',
          children: [
            { path: '', element: <Chat /> },
            { path: 'new', element: <Chat /> },
            { path: ':conversationKey', element: <Chat /> }
          ]
        },
        {
          path: 'suporte',
          children: [
            { path: '', element: <Suporte /> }
          ]
        },
        {
          path: 'perfil',
          children: [
            { path: '', element: <Perfil /> }
          ]
        },
        {
          path: 'funil',
          children: [
            { path: '', element: <ChatKanban /> }
          ]
        },
        {
          path: 'agendamentos',
          children: [
            { path: '', element: <MensagensAgendadas /> }
          ]
        },
        {
          path: 'staff',
          children: [
            { path: '', element: <Staff /> },
            { path: 'new', element: <Staff /> },
            { path: ':conversationKey', element: <Staff /> }
          ]
        }
      ]
    },

    // Main Routes
    {
      path: '*',
      element: <LogoOnlyLayout />,
      children: [
        { path: '500', element: <Page500 /> },

      ]
    },
 
    {
      path: '/',
      element: <MainLayout />,
      children: [
        { path: '', element: <Navigate to="chats/chat" replace /> },
        { path: 'sync', element: <Sync /> },
     
      ]
    },
    { path: '*', element: <Navigate to="/404" replace /> }
  ]);
}

// IMPORT COMPONENTS

// Authentication
const Login = Loadable(lazy(() => import('../pages/authentication/Login')));
const Login2 = Loadable(lazy(() => import('../pages/authentication/Login')));
const Register = Loadable(lazy(() => import('../pages/authentication/Register')));
const ResetPassword = Loadable(lazy(() => import('../pages/authentication/ResetPassword')));
const VerifyCode = Loadable(lazy(() => import('../pages/authentication/VerifyCode')));
// Dashboard
const Painel = Loadable(lazy(() => import('../pages/dashboard/Painel/index')));
const GeneralApp = Loadable(lazy(() => import('../pages/dashboard/GeneralApp')));
//const Chat = Loadable(lazy(() => import('../pages/chats/Chat')));
const Staff = Loadable(lazy(() => import('../pages/chats/Staff')));
const Suporte = Loadable(lazy(() => import('../pages/chats/Suporte')));
const ChatKanban = Loadable(lazy(() => import('../pages/chats/ChatKanban')));
const Campanhas = Loadable(lazy(() => import('../pages/chats/Campanhas')));
const Perfil = Loadable(lazy(() => import('../pages/dashboard/Perfil/Perfil')));
const MensagensRapidas = Loadable(lazy(() => import('../pages/dashboard/MensagensRapidas')));
const Calendar = Loadable(lazy(() => import('../pages/dashboard/Calendar')));

// const Configuracoes = Loadable(lazy(() => import('../pages/dashboard/Configuracoes')));

// Main
const Sync = Loadable(lazy(() => import('../pages/Sync')));

const Page500 = Loadable(lazy(() => import('../pages/Page500')));
