import { LoadingButton } from '@mui/lab';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
    Container,
    Box,
    Grid,
    Avatar,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Divider,
    IconButton,
    ListItemButton,
} from '@mui/material';

import Iconify from 'src/components/Iconify';


const items = [
    { text: '🚀 Agente de vendas', icon: <Iconify icon="material-symbols:robot" color="primary" /> },
    { text: '🗣️ FAQ ', icon: <Iconify icon="fluent:lead-16-filled" /> },
    { text: '🤖 Geração de Leads com IA', icon: <Iconify icon="fluent:lead-16-filled" /> }
];
export default function DialogTemplates({ open, onClose }) {


    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            fullWidth
            maxWidth={'lg'}

        >
            <Box display="flex" flexDirection="row" height="100vh">
                <Box
                    width="400px"
                    sx={{
                        bgcolor: 'background.paper',
                        display: 'flex',
                        flexDirection: 'column',
                        borderRight: 1,
                        borderColor: '#ccc',
                        backgroundColor: 'grey.800',

                        //borderRightStyle:'dashed'
                    }}
                >
                    <Box>
                        <Typography color="white" variant="h6" sx={{ p: 2 }}>
                            Templates
                        </Typography>
                    </Box>
                    <List>
                        {items.slice(0, 3).map((item, index) => (
                            <ListItemButton key={index}>
                                <ListItemText sx={{ color: 'white', fontSize: 10 }} primary={item.text} />
                            </ListItemButton>
                        ))}

                    </List>
                </Box>


                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        p: 3
                    }}
                >
                    {/* <Stack justifyContent={"space-between"} direction={"row"} spacing={2}>
                     
                       <IconButton>
                            <Iconify icon="mdi:close" />
                        </IconButton>
                    </Stack> */}
                    <Box
                        sx={{

                           // backgroundColor: '#43d257',
                            p: 1,
                        }}
                    >
                        <Stack direction={"column"} spacing={4}>
                            <Box
                                sx={{
                                    height: 600,
                                    width: '100%',
                                    borderRadius: 1,
                                    background: 'url(https://cdn.dribbble.com/users/839742/screenshots/2406426/cover.gif)',
                                }}>

                            </Box>

                            <Box sx={{background:'#f0f0f0', p:3, borderRadius:2}}>
                                <Stack spacing={3} justifyContent={"space-between"} direction="row" alignItems={"center"}>
                                    <Stack spacing={2}>
                                        <Typography variant="h5">🚀 Agente de vendas</Typography>

                                        <Typography variant="body2">
                                            Você é uma agência de marketing e este bot permite gerar novos leads interessados em seus serviços. Um bloco de IA é usado para aprofundar nas necessidades do usuário.
                                        </Typography>
                                    </Stack>
                                    <Button size="small" variant="contained" color="primary" sx={{ mt: 2 }}>
                                        Usar modelo
                                    </Button>
                                </Stack>
                            </Box>

                        </Stack>


                    </Box>

                </Box>
            </Box>
        </Dialog>
    );
}






