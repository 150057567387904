import { Step, Stepper, StepButton, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import Iconify from 'src/components/Iconify';
import React from 'react';
import StepConnector, { stepConnectorClasses } from '@mui/material/StepConnector';
import { useEffect } from 'react';
// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'relative',

}));

// ----------------------------------------------------------------------



DrawerNegocioStagesPipeline.propTypes = {
    disabled: PropTypes.bool,
    conversationId: PropTypes.string,
    onSend: PropTypes.func
};

const QontoConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
      top: 10,
      left: 'calc(-50% + 16px)',
      right: 'calc(50% + 16px)',
    },
    [`&.${stepConnectorClasses.active}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`&.${stepConnectorClasses.completed}`]: {
      [`& .${stepConnectorClasses.line}`]: {
        borderColor: '#784af4',
      },
    },
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.mode === 'dark' ? theme.palette.grey[800] : '#eaeaf0',
      borderTopWidth: 3,
      borderRadius: 1,
    },
  }));

const steps = ['Interesse', 'Prospeção', 'Proposta', 'Negociação', 'Fechamento'];

export default function DrawerNegocioStagesPipeline({ 
    disabled, 
    activeStepProps,
    estagios, 
    changeText, 
    text, 
    conversationId, 
    onSend, 
    onOpenMenu, 
    onChange,
    ...other }) {

    const [activeStep, setActiveStep] = React.useState(activeStepProps);
    const [completed, setCompleted] = React.useState({});

    useEffect(() => {
      setActiveStep(activeStepProps)
    }, [activeStepProps])
    

    const totalSteps = () => {
        return steps.length;
    };

    const completedSteps = () => {
        return Object.keys(completed).length;
    };

    const isLastStep = () => {
        return activeStep === totalSteps() - 1;
    };

    const allStepsCompleted = () => {
        return completedSteps() === totalSteps();
    };

    const handleNext = () => {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed,
                // find the first step that has been completed
                steps.findIndex((step, i) => !(i in completed))
                : activeStep + 1;
        setActiveStep(newActiveStep);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStep = (step, label) => () => {
        setActiveStep(step);
        onChange(label._id)
        setActiveStep(label.posicao);
    };


    return (
        <RootStyle {...other}>
            <Stepper alternativeLabel nonLinear  activeStep={activeStep}>
                {estagios?.map((item, index) => (
                    <Step 
                    connector={<QontoConnector />}
                    key={item._id} 
                    completed={completed[index]}
                    >
                        <StepButton
                        //icon={<Iconify icon="mdi:circle" width={10}  />}
                            color="inherit"
                            onClick={handleStep(index, item)}
                            icon={<Iconify icon="tabler:circle-dot-filled" width={18} color={theme => activeStep === index ? theme.palette.primary.main : 'grey'} />}
                            // sx={{
                            //     '& .MuiStepButton-root': {
                            //         display: 'none',
                             
                            //     }
                            // }}
                        >
                            <Typography  sx={{ fontSize: 8, fontWeight:'500', letterSpacing:0.5 }}> {item.nome}</Typography>
                        </StepButton>
                        
                    </Step>
                ))}
            </Stepper>

        </RootStyle>
    );
}
