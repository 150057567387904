import {
  Box,
  Grid,
  Typography,
  Stack,
  Divider,
  Container,
  CardContent
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
//paginas
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import Iconify from 'src/components/Iconify';
import { formataNumeroCelular } from 'src/utils/functions';
import { useNavigate } from 'react-router';
import ModalCriarAgente from './ModalCriarAgente';
import { Card } from 'src/@/components/ui/card';
import { Button } from 'src/@/components/ui/button';
import { Badge } from 'src/@/components/ui/badge';
import { useSnackbar } from 'notistack';
import v2_actionListaAgentesAi from 'src/actions/v2/ai/v2_actionListaAgentesAi';
import v2_actionDeletarAgente from 'src/actions/v2/ai/v2_actionDeletarAgente';
import ModalConfimacao from 'src/components/modal/ModalConfimacao';
// ----------------------------------------------------------------------


export default function AgentesIaComponent({ onConfigFlow, user, cliente, onCallback }) {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useSnackbar();
  const [openTemplates, setOpenTemplates] = useState(false)
  const [openModalCriarAgente, setOpenModalCriarAgente] = useState(false);
  const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);
  const [listaAgentesAi, setListaAgentesAi] = useState([])
  const [agentId, setAgentId] = useState(null)


  async function buscarListaAgentesAi() {
    try {
      const response = await v2_actionListaAgentesAi(cliente._id);
      if (response.status !== 200) return enqueueSnackbar("Erro ao buscar lista de agentes de IA", { variant: 'error' });
      setListaAgentesAi(response.data.data)
    } catch (error) {
      enqueueSnackbar("Erro ao buscar agentes de IA", { variant: 'error' });
      console.log(error)
    }
  }

  // deltar agente
  async function handleDeletarAgente() {
    try {
      const response = await v2_actionDeletarAgente(agentId);
      if (response.status !== 200) return enqueueSnackbar("Erro ao deletar agente", { variant: 'error' });
      enqueueSnackbar("Agente deletado com sucesso", { variant: 'success' });
      buscarListaAgentesAi();
      setOpenModalConfirmacao(false)
      setAgentId(null)
    }
    catch (error) {
      enqueueSnackbar("Erro ao deletar agente", { variant: 'error' });
      console.log(error)
    }
  }

    useEffect(() => {
      buscarListaAgentesAi();
    }, []);

    return (
      <Container maxWidth="lg">
        <ModalCriarAgente
          cliente={cliente}
          open={openModalCriarAgente}
          onClose={() => setOpenModalCriarAgente(false)}
          onCallback={agente => {
            setOpenModalCriarAgente(false);
            buscarListaAgentesAi();
            navigate(`/dashboard/configuracoes/agentDetail?id=${agente._id}`)
          }}
        />

        <ModalConfimacao
          open={openModalConfirmacao}
          onClose={() => {
            setAgentId(null)
            setOpenModalConfirmacao(false);
          }}
          mensagem="Deseja mesmo excluir?"
          onConfirm={() => handleDeletarAgente()}

        />

        <HeaderPageComponentConfig
          titulo="Agentes IA"
          descricao={'Crie agentes de ia para atender seus clientes.'}
        //  actions={<Stack spacing={1} direction={"row"}>
        //   <Button onClick={() => setOpenTemplates(true)} startIcon={<Iconify icon="fluent:calendar-template-20-filled" />} variant="outlined">templates</Button>
        //   <Button onClick={() => setOpenCriarFlow(true)} startIcon={<Iconify icon="fluent:bot-sparkle-24-filled" />} variant='contained'>novo fluxo</Button>
        //   </Stack>}
        />
        <Divider sx={{ my: 3, borderStyle: 'dashed' }} />
        <Box sx={{ flex: 1, px: 3 }}>

          <Box
            sx={{
              display: 'grid',
              gap: 1,
              flexDirection: 'row',
              mt: 2,
              gridTemplateColumns: {
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(3, 1fr)',
                md: 'repeat(4, 1fr)',
                lg: 'repeat(4, 1fr)',
              },
            }}
          >
            {listaAgentesAi && listaAgentesAi?.map((item, index) => (
              <AgentAiCard
                onClick={() => navigate(`/dashboard/configuracoes/agentDetail?id=${item._id}`)}
                canal={item}
                onDelete={() => {
                  setAgentId(item._id)
                  setOpenModalConfirmacao(true)
                }}
              />
            )
            )}
            <CardNew onClick={() => setOpenModalCriarAgente(true)} />
          </Box>

        </Box>
      </Container>
    );
  }

  // ----------------------------------------------------------------------

  AgentAiCard.propTypes = {
    item: PropTypes.object,
  };

  function AgentAiCard({
    canal,
    onClick,
    onDelete
  }) {

    const isDraft = canal.status_create === "draft"

    return (
      <Card className="bg-slate-100">
        <CardContent className='flex flex-col items-center gap-8'>
          <Stack direction="column" alignItems="center" justifyContent="center" spacing={1} >
            <small className="text-xs font-medium leading-none"> {canal.name}</small>

            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {formataNumeroCelular(canal.numero_whatsapp)}
            </Typography>
            <Badge className={`${isDraft ? "bg-violet-300" : "bg-violet-600"} text-[10px] rounded-sm px-1 py-0`}>
              <Iconify className="mr-1" icon={isDraft ? "bx:bxs-edit" : "bx:bxs-check-circle"} />
              {isDraft ? "Rascunho" : "Ativo"}
            </Badge>
          </Stack>
          <div className='flex flex-row gap-2'>
            <Button
              onClick={onClick}
              variant="outline"
              className="h-7 text-xs"
            >
              <Iconify className="mr-2" icon={isDraft ? "iconamoon:edit-fill" : "icon-park-solid:config"} />
              {isDraft ? "Continue editando" : "Configurar"}

            </Button>
            <Button
              onClick={onDelete}
              size="icon"
              variant="outline"
              className="h-6.5 text-xs"
            >
              <Iconify color="red" icon={"material-symbols:delete"} />
            </Button>
          </div>

          <div className='flex flex-row items-center gap-2'>
            <Iconify icon="tabler:plug-connected" />
            <p className='text-sm'>Canais conectados: </p>
            <div className="bg-violet-700 text-[10px] text-white font-bold rounded-sm w-4 h-4 flex justify-center items-center">{canal?.counts_use}</div>
            </div>

        </CardContent>
      </Card>
    );
  }
  // ----------------------------------------------------------------------


  const CardNew = ({ onClick }) => {
    return (
      <Box
        onClick={onClick}
        sx={{
          // backgroundColor: theme => theme.palette.grey[100],
          border: '2px dashed #e5e2f7',
          p: 2,
          ml: 1,
          borderRadius: 1,
          display: 'flex',
          position: 'relative',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column',
          minWidth: 200,
          '&:hover': {
            backgroundColor: '#f5f5f5',
            cursor: 'pointer'

          }
        }}
      >

        <Stack direction="column" alignItems="center" justifyContent="center" spacing={2} >
          <Stack direction={"column"} spacing={1} justifyContent={"center"} alignItems={"center"}>
            <Iconify icon={"lets-icons:add-duotone"} width={30} height={30} />
            <Typography variant="subtitle2" color="text.primary">
              Criar Agente
            </Typography>
          </Stack>
        </Stack>

      </Box>
    );

  }