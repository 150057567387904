import axios from "axios";
import Config from "src/config";

const v2_actionListaTreinamentoAgentes = (clienteId, flowId) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/${clienteId}/flows/${flowId}/treinamentos`
    });
};

export default v2_actionListaTreinamentoAgentes;
