import { createSlice } from '@reduxjs/toolkit';
import { map, filter } from 'lodash';
// utils

import getActionListaClientesByMarketplace from 'src/actions/clientes/getActionListaClientesByMarketplace';
import getFluxoByClient from 'src/actions/getFluxoByClient';
import getListaUsuariosByCliente from 'src/actions/getListaUsuariosByCliente';

import getActionListaRespostasRapidas from "../../actions/clientes/getActionListaRespostasRapidas";
import getActionListaSetoresByClienteId from "../../actions/clientes/getActionListaSetoresByClienteId";
import getSetoresByClient from "../../actions/getSetoresByClient";

// actions
import getActionListaUsuariosByClienteId from "../../actions/usuarios/getActionListaUsuariosByClienteId";
import axios from '../../utils/axios';



// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  myProfile: null,
  posts: [],
  users: [],
  setoresList: [],
  usuariosList: [],
  clientesMarketplace: [],
  listaUsuariosTransferencia: [],
  listaSetoresTransferencia: [],
  respostasRapidas: [],
  fluxoList: [],
  followers: [],
  friends: [],
  gallery: [],
  cards: null,
  addressBook: [],
  invoices: [],
  notifications: null,
  isUserStandby: false,
};

const slice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PROFILE
    getProfileSuccess(state, action) {
      state.isLoading = false;
      state.myProfile = action.payload;
    },

    // GET POSTS
    getPostsSuccess(state, action) {
      state.isLoading = false;
      state.posts = action.payload;
    },

    // GET USERS
    getUsersSuccess(state, action) {
      state.isLoading = false;
      state.users = action.payload;
    },

    // DELETE USERS
    deleteUser(state, action) {
      const deleteUser = filter(state.userList, (user) => user.id !== action.payload);
      state.userList = deleteUser;
    },

    // GET FOLLOWERS
    getFollowersSuccess(state, action) {
      state.isLoading = false;
      state.followers = action.payload;
    },

    // ON TOGGLE FOLLOW
    onToggleFollow(state, action) {
      const followerId = action.payload;

      const handleToggle = map(state.followers, (follower) => {
        if (follower.id === followerId) {
          return {
            ...follower,
            isFollowed: !follower.isFollowed
          };
        }
        return follower;
      });

      state.followers = handleToggle;
    },

    // GET FRIENDS
    getFriendsSuccess(state, action) {
      state.isLoading = false;
      state.friends = action.payload;
    },

    // GET GALLERY
    getGallerySuccess(state, action) {
      state.isLoading = false;
      state.gallery = action.payload;
    },

    // GET MANAGE USERS
    getUserListSuccess(state, action) {
      state.isLoading = false;
      state.userList = action.payload;
    },

    // BUSCAR SETORES DE CLIENTES
    getUserListSetoresSuccess(state, action) {
      state.isLoading = false;
      state.setoresList = action.payload;
    },

    // BUSCAR USUARIOS DE CLIENTES
    getUserListUsuariosSuccess(state, action) {
      state.isLoading = false;
      state.usuariosList = action.payload;
    },

    // BUSCAR FLUXO DE CLIENTES
    getUserListFluxoSuccess(state, action) {
      state.isLoading = false;
      state.fluxoList = action.payload;
    },

    // BUSCAR LISTA DE USUARIOS BY CLIENTE ID
    getListaUsuariosByClienteIdSuccess(state, action) {
      state.isLoading = false;
      state.usuariosList = action.payload;
    },

    // BUSCAR LISTA DE USUARIOS PARA TRANSFERENCIA
    getListaUsuariosTransferenciaSuccess(state, action) {
      state.isLoading = false;
      state.listaUsuariosTransferencia = action.payload;
    },

    // BUSCAR LISTA DE SETORES PARA TRANSFERENCIA
    getListaSetoresTransferenciaSuccess(state, action) {
      state.isLoading = false;
      state.listaSetoresTransferencia = action.payload;
    },


    // BUSCAR LISTA REPSPOSTAS RAPIDAS
    getListaRespostasRapidasSuccess(state, action) {
      state.isLoading = false;
      state.respostasRapidas = action.payload;
    },

    // BUSCAR LISTA REPSPOSTAS RAPIDAS
    getListaClientesByMarketplaceSuccess(state, action) {
      console.log('getListaClientesByMarketplace', action.payload)
      state.isLoading = false;
      state.clientesMarketplace = action.payload;
    },

    // edit user standby
    setUserStandby(state, action) {
      state.isUserStandby = action.payload;
    },

    // GET CARDS
    getCardsSuccess(state, action) {
      state.isLoading = false;
      state.cards = action.payload;
    },

    // GET ADDRESS BOOK
    getAddressBookSuccess(state, action) {
      state.isLoading = false;
      state.addressBook = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET NOTIFICATIONS
    getNotificationsSuccess(state, action) {
      state.isLoading = false;
      state.notifications = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const { onToggleFollow, deleteUser, setUserStandby } = slice.actions;

// ----------------------------------------------------------------------

export function getProfile() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/profile');
      dispatch(slice.actions.getProfileSuccess(response.data.profile));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPosts() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/posts');
      dispatch(slice.actions.getPostsSuccess(response.data.posts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFollowers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/followers');
      dispatch(slice.actions.getFollowersSuccess(response.data.followers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getFriends() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/friends');
      dispatch(slice.actions.getFriendsSuccess(response.data.friends));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getGallery() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/social/gallery');
      dispatch(slice.actions.getGallerySuccess(response.data.gallery));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUserList() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/manage-users');
      dispatch(slice.actions.getUserListSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// LISTA DE CLIENTES MARKETPLACE

export function getListaClientesByMarketplace(marketplaceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionListaClientesByMarketplace(marketplaceId);
      dispatch(slice.actions.getListaClientesByMarketplaceSuccess(response.data.clientes));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// LISTA DE USAURIOS PARA TRANSFERENCIA

export function getListaUsuariosTransferencia(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionListaUsuariosByClienteId(clienteId);
      dispatch(slice.actions.getListaUsuariosTransferenciaSuccess(response.data.usuarios));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getListaUsuariosByClienteId(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionListaUsuariosByClienteId(clienteId);
      dispatch(slice.actions.getListaUsuariosByClienteIdSuccess(response.data.usuarios));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// LISTA DE USAURIOS PARA TRANSFERENCIA

export function getListaSetoresTransferencia(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionListaSetoresByClienteId(clienteId);
      dispatch(slice.actions.getListaSetoresTransferenciaSuccess(response.data.setores));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// ----------------------------------------------------------------------

export function getFluxoList(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getFluxoByClient({ idCliente: name });
      dispatch(slice.actions.getUserListFluxoSuccess(response.data.fluxo));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSetoresList(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getSetoresByClient({ idCliente: name });
      dispatch(slice.actions.getUserListSetoresSuccess(response.data.setores));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsuariosList(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getListaUsuariosByCliente({ cliente: name });
      console.log('clientes list', response.data.usuarios)
      dispatch(slice.actions.getUserListUsuariosSuccess(response.data.usuarios));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getRespostasRapidas(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionListaRespostasRapidas(clienteId);
      console.log('response.data.respostas', response.data.respostas)
      dispatch(slice.actions.getListaRespostasRapidasSuccess(response.data.respostas));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCards() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/cards');
      dispatch(slice.actions.getCardsSuccess(response.data.cards));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getAddressBook() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/address-book');
      dispatch(slice.actions.getAddressBookSuccess(response.data.addressBook));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/invoices');
      dispatch(slice.actions.getInvoicesSuccess(response.data.invoices));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getNotifications() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/account/notifications-settings');
      dispatch(slice.actions.getNotificationsSuccess(response.data.notifications));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUsers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/user/all');
      dispatch(slice.actions.getUsersSuccess(response.data.users));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
