import PropTypes from 'prop-types';
// @mui
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Box } from '@mui/material';
// utils
import { fShortenNumber } from 'src/utils/formatNumber';
import {COLORS} from '../../../../constants/colors';
import Iconify from 'src/components/Iconify';

import palette from 'src/theme/palette';

// ----------------------------------------------------------------------

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(2, 2, 2, 3),
  borderRadius: 10,
  backgroundColor:theme.palette.grey[200],
  // borderColor:COLORS.light,
  // borderWidth: 1,
  // borderStyle: 'solid',
}));

// ----------------------------------------------------------------------

ForcaVendaResumoWidget.propTypes = {
  icon: PropTypes.any,
  title: PropTypes.string,
  total: PropTypes.number,
};

export default function ForcaVendaResumoWidget({ title, total, icon }) {
  return (
    <RootStyle>
      <div>
        <Typography color={theme => theme.palette.grey[900]} variant="h3">{total}</Typography>
        <Typography color={theme => theme.palette.grey[900]} variant="subtitle2" sx={{ color: 'text.secondary' }}>
          {title}
        </Typography>
      </div>
      <Box
        sx={{
          width: 100,
          height: 100,
          lineHeight: 0,
          borderRadius: '50%',
          bgcolor: 'background.neutral',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Iconify icon={icon} color={theme => theme.palette.grey[300]} width={100} height={100} />
      
      </Box>
    </RootStyle>
  );
}
