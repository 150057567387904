import axios from "axios";
import Config from "../../config";

const actionStatusConnectionWhatsapp = (querys) => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/clientes/status-session${querys ? querys : ""}`
    });
};

export default actionStatusConnectionWhatsapp;
