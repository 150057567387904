import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  MenuItem,
  FormControl,
  Grid,
  Divider,
  Radio,
  RadioGroup,
  FormControlLabel,
  TextField,
  Avatar,
  ListItemAvatar,
  Stack,
  Drawer

} from '@mui/material';

import BadgeStatus from '../../../BadgeStatus';
import { LoadingButton } from '@mui/lab';
import HeaderModal from 'src/components/modal/HeaderModal';
import { SelectZui } from 'src/components/zui';
import { SelectItem } from 'src/@/components/ui/select';
import { Checkbox } from 'src/@/components/ui/checkbox';
import { useState } from 'react';
import { Switch } from 'src/@/components/ui/switch';

// ----------------------------------------------------------------------

export default function ModalTransferirChat({
  stateModalTransferirChatProps,
  closeModalTransferirChat,
  valueTransferirChat,
  actionTipoTransferencia,
  valorEscolhidoUsuarioProps,
  valorEscolhidoSetorProps,
  handleUsuario,
  handleSetor,
  handleSelectAgent,
  listaUsuariosProps,
  listaSetoresProps,
  submitTranferirChat,
  onlineUsers,
  loadingTransferirChat
}) {


  function validaBotaoTransferir() {
    if (valueTransferirChat === '') {
      return true;
    } else {
      if (!valorEscolhidoSetorProps && !valorEscolhidoUsuarioProps) {
        return true;
      } else {
        return false;
      }
    }
  }

  const [isSendMessages, setIsSendMessages] = useState(true);


  return (
    <div>

      <Drawer
        open={stateModalTransferirChatProps}
        anchor='right'
        maxWidth={'sm'}
        onClose={closeModalTransferirChat}
        sx={{ zIndex:20 }}
      >
        <HeaderModal title="Transferir chat" />
        <DialogContent className='min-w-[400]'>
          <div className='min-w-[400px]'>
          <Grid sx={{ mt: 3 }} container>
            <Grid item md>
              <RadioGroup row value={valueTransferirChat} onChange={actionTipoTransferencia}>
                <FormControlLabel value="setores" control={<Radio />} label="Setores" />
                <FormControlLabel value="usuarios" control={<Radio size="small" />} label="Usuários" />
              </RadioGroup>
            </Grid>
          </Grid>

          <Stack sx={{ overflow: 'hidden', my: 3 }} container spacing={2}>
            {valueTransferirChat === 'usuarios' && (

              <SelectZui
                value={valorEscolhidoUsuarioProps}
                list={listaUsuariosProps}
                fullWidth
                onChange={event => {
                  handleUsuario(event);
                }}
                itemSelect={(item) => (
                  <SelectItem key={item._id} value={item._id}>
                    {item.nomeFantasia}
                  </SelectItem>
                )}
              />
            )}


            {valueTransferirChat === 'setores' && (
              <SelectZui
                value={valorEscolhidoSetorProps}
                list={listaSetoresProps}
                fullWidth
                onChange={event => {
                  handleSetor(event);
                }}
                itemSelect={(item) => (
                  <SelectItem key={item._id} value={item._id}>
                    {item.nome_setor}
                  </SelectItem>
                )}
              />
            )}
          </Stack>

          <div className="flex items-center gap-2 gap-y-2 mt-2">
            <Switch
              id="terms"
              checked={isSendMessages}
              onCheckedChange={value => {
                setIsSendMessages(value)
                console.log('isSendMessages', value)
              } }
            />
            <label
              htmlFor="terms"
              className="text-xs capitalize font-medium"
            >
              Enviar mensagem transferência
            </label>
          </div>
          </div>
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button 
          
          onClick={closeModalTransferirChat}>
            Cancelar
            </Button>
          <LoadingButton 
          loading={loadingTransferirChat} 
          disabled={validaBotaoTransferir()} 
          className='w-full'
          variant="contained" 
          onClick={async () => {
            await submitTranferirChat(isSendMessages);
            setIsSendMessages(true);
          }}
            autoFocus
          >
            Transferir
          </LoadingButton>
        </DialogActions>
      </Drawer>
    </div>
  );
}
