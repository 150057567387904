import searchFill from '@iconify/icons-eva/search-fill';
import { Icon } from '@iconify/react';
// material
import { Box, FormControl, OutlinedInput, InputAdornment, ClickAwayListener } from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { Input } from 'src/@/components/ui/input';

// ----------------------------------------------------------------------

ChatContactSearch.propTypes = {
  query: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onClickAway: PropTypes.func
};

export default function ChatContactSearch({ query, onChange, onFocus, onClickAway }) {
  return (
    <ClickAwayListener onClickAway={onClickAway}>
        <FormControl fullWidth size="small">
          <Input
            value={query}
            onFocus={onFocus}
            onChange={onChange}
            placeholder="Procurar..."
  
          />
        </FormControl>
    </ClickAwayListener>
  );
}
