// material
import {
    Alert,
    Autocomplete,
    DialogTitle,
    Divider,
    Grid,
    TextField,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Box,
    MenuItem, Select, Stack, Typography, Drawer, Container, IconButton
} from '@mui/material';
import './react-phone-number-input.css'
import { LoadingButton } from '@mui/lab';
import { useState, forwardRef } from 'react';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import buscarCep from 'src/actions/chats/buscarCep';
import Iconify from 'src/components/Iconify';
import getActionCriarContato from 'src/actions/chats/getActionCriarContato';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import PhoneInput from 'react-phone-number-input'
import { borderRadius } from 'react-toast-notifications/dist/ToastElement';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyle from 'src/components/InputStyle';
import { fDate, isDateValid } from 'src/utils/formatTime';
import { maskCPF, maskDate } from 'src/utils/functions';
import { useSnackbar } from 'notistack';
import { Input } from 'src/@/components/ui/input';
import { Popover, PopoverTrigger, PopoverContent } from 'src/@/components/ui/popover';
import { Button } from 'src/@/components/ui/button';
import { Label } from 'src/@/components/ui/label';
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from 'src/@/components/ui/accordion';
// ----------------------------------------------------------------------

export default function NovoContatoForm({
    user,
    cliente,
    open,
    listaClientesCanais,
    loading,
    showSaveButton,
    callbackAddContact,
    hideSelectEmpresa,
    showSignupButton,
    loadingNovoContato
}) {
    const { enqueueSnackbar } = useSnackbar();
    const [contato, setContato] = useState({
        nome: '',
        email: '',
        telefone: '',
        dataNascimento: '',
        cpf: ''
    });
    const [endereco, setEndereco] = useState({
        cep: null,
        rua: "",
        numero: "",
        bairro: "",
        cidade: "",
        estado: "",
        complemento: ""
    });
    const [camposPersonalizados, setCamposPersonalizados] = useState([]);
    const [campoPersonalizado, setCampoPersonalizado] = useState({
        nome: '',
        valor: ''
    })

    const [openModalAlerta, setOpenModalAlerta] = useState(false);
    const [mensagemModalAlerta, setMensagemModalAlerta] = useState('');

    const handleCallbackAddContact = () => {
        let data = {
            contato,
            endereco,
            camposPersonalizados
        }
        if (data.contato.nome === "" || data.contato.telefone === "" || data.contato.telefone.length < 10) {
            enqueueSnackbar('Preencha o nome do campo e o valor antes de inserir', { variant: 'error' });
            return;
        }
        callbackAddContact(data)
        clearFields();
    }

    function clearFields() {
        setContato({
            nome: '',
            email: '',
            telefone: '',
            dataNascimento: '',
            cpf: ''
        })

        setEndereco({
            cep: "",
            rua: "",
            numero: "",
            bairro: "",
            cidade: "",
            estado: "",
            complemento: ""
        })

    }

    function limparCampos() {
        setRua("");
        setCep("");
        setCidade("");
        setEstado("");
        setDDD("");
        setNome("");
        setEmail("");
        setSobrenome("");
        setBairro("");
        setNumero("");
        setWhatsapp("");
        setCpf("");
        setObs("");
    }


    const handleOnBlurSearchCep = async (zipCode) => {
        try {
            const address = await buscarCep(zipCode)
            if (!address.erro) {
                setEndereco({
                    cep: address.cep,
                    rua: address.logradouro,
                    bairro: address.bairro,
                    cidade: address.localidade,
                    estado: address.uf,
                })

            }
        } catch (address) {

        }
    }

    const top100Films = [
        { label: 'The Shawshank Redemption', year: 1994 },
        { label: 'The Godfather', year: 1972 }
    ]

    async function addNovoCampoPersonalizado() {
        if (campoPersonalizado.nome === '' || campoPersonalizado.valor === '') {
            enqueueSnackbar('Preencha os campos', { variant: 'error' });
            return;
        }
        let novoCampo = {
            nome: campoPersonalizado.nome,
            valor: campoPersonalizado.valor
        }
        setCamposPersonalizados([...camposPersonalizados, novoCampo]);
        setCampoPersonalizado({
            nome: '',
            valor: ''
        })
    }

    async function removeCampoPersonalizado(index) {
        let newCampos = camposPersonalizados.filter((item, i) => i !== index)
        setCamposPersonalizados(newCampos)
    }


    return (
        <div>

            <ModalAlerta
                open={openModalAlerta}
                mensagem={mensagemModalAlerta}
                onClose={() => {
                    setOpenModalAlerta(false);
                    setMensagemModalAlerta('');
                }}
            />


            <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={1}>

                <Stack spacing={1}>


                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '60%' }}>
                            <RowInput
                                isRequired={true}
                                label="Telefone"
                                input={<PhoneInput
                                    defaultCountry='BR'
                                    className='form-control from-input'
                                    style={{ padding: 5, borderRadius: 4, border: '1px solid #ccc' }}
                                    value={contato.telefone}
                                    onChange={value => setContato({ ...contato, telefone: value })}
                                />}
                            />

                        </Box>
                    </Stack>

                    <Divider sx={{ pt: 1, pb: 1, borderStyle: 'dashed' }} />

                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >
                        <Box sx={{ width: '60%' }}>
                            <RowInput
                                isRequired={true}
                                label="Nome"
                                input={<Input
                                    onChange={e => {
                                        setContato({ ...contato, nome: e.target.value })
                                    }}
                                    value={contato.nome}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>


                        <Box sx={{ width: '40%' }}>
                            <RowInput
                                label="Data Nascimento"
                                input={<Input onChange={e => {
                                    setContato({ ...contato, dataNascimento: e.target.value })
                                }}
                                    value={maskDate(contato.dataNascimento)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>

                    </Stack>


                    <Stack
                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                        spacing={1}
                        direction={"row"}
                    >

                        <Box sx={{ width: '60%' }}>
                            <RowInput
                                label="Email"
                                type="email"
                                input={<Input onChange={e => {
                                    setContato({ ...contato, email: e.target.value })
                                }}
                                    value={contato.email}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>

                        <Box sx={{ width: '40%' }}>
                            <RowInput
                                label="CPF"
                                input={<Input onChange={e => {
                                    setContato({ ...contato, cpf: e.target.value })
                                }}
                                    value={maskCPF(contato.cpf)}
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                />}
                            />
                        </Box>

                    </Stack>
                    {!hideSelectEmpresa && (
                        <Stack
                            sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                            spacing={1}
                            direction={"row"}
                        >
                            <Box sx={{ width: '100%' }}>
                                <RowInput
                                    label="Selecionar empresa"

                                    input={<Autocomplete
                                        disablePortal
                                        id="combo-box-demo"
                                        options={top100Films}

                                        renderInput={(params) => <InputStyle {...params} />}
                                    />}
                                />
                            </Box>
                        </Stack>
                    )}


                    <Divider sx={{ pt: 1, pb: 1, borderStyle: 'dashed' }} />



                    <Accordion type="multiple" collapsible className="w-full">
                        <AccordionItem value="item-1">
                            <AccordionTrigger>Endereço</AccordionTrigger>
                            <AccordionContent>

                                <Stack spacing={1}>

                                    <Stack
                                        sx={{ width: { xs: '100%', md: '100%', mt: 4 } }}
                                        spacing={1}
                                        direction={"row"}
                                    >
                                        <Box sx={{ width: '50%' }}>
                                            <RowInput
                                                label="CEP"

                                                input={<Input onChange={e => {
                                                    setEndereco({ ...endereco, cep: e.target.value })
                                                }}
                                                    onBlur={e => handleOnBlurSearchCep(e.target.value)}
                                                    value={endereco.cep}
                                                    variant="outlined"
                                                    size="small"
                                                    fullWidth
                                                />}
                                            />
                                        </Box>
                                    </Stack>

                                    <Stack
                                        sx={{ width: { xs: '100%', md: '100%' } }}
                                        spacing={1}
                                        direction={"row"}
                                    >
                                        <Box sx={{ width: '100%' }}>
                                            <RowInput
                                                label="Rua"
                                                input={<Input onChange={e => {
                                                    setEndereco({ ...endereco, rua: e.target.value })

                                                }} value={endereco.rua} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                    </Stack>


                                    <Stack
                                        sx={{ width: { xs: '100%', md: '100%' } }}
                                        spacing={1}
                                        direction={"row"}
                                    >
                                        <Box sx={{ width: '25%' }}>
                                            <RowInput
                                                label="Número"
                                                input={<Input onChange={e => {
                                                    setEndereco({ ...endereco, numero: e.target.value })
                                                }} value={endereco.numero} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '75%' }}>
                                            <RowInput
                                                label="Bairro"
                                                input={<Input onChange={e => {
                                                    setEndereco({ ...endereco, bairro: e.target.value })
                                                }} value={endereco.bairro} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>

                                    </Stack>


                                    <Stack
                                        sx={{ width: { xs: '100%', md: '100%' } }}
                                        spacing={1}
                                        direction={"row"}
                                    >
                                        <Box sx={{ width: '40%' }}>
                                            <RowInput
                                                label="Complemento"
                                                input={<Input onChange={e => {
                                                    setEndereco({ ...endereco, complemento: e.target.value })
                                                }} value={endereco.complemento} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '40%' }}>
                                            <RowInput
                                                label="Cidade"
                                                input={<Input onChange={e => {
                                                    setEndereco({ ...endereco, cidade: e.target.value })
                                                }} value={endereco.cidade} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                        <Box sx={{ width: '20%' }}>
                                            <RowInput
                                                label="Estado"
                                                input={<Input onChange={e => {
                                                    setEndereco({ ...endereco, estado: e.target.value })
                                                }} value={endereco.estado} variant="outlined" size="small" fullWidth />}
                                            />
                                        </Box>
                                    </Stack>
                                </Stack>
                            </AccordionContent>
                        </AccordionItem>
                        <AccordionItem value="item-2">
                            <AccordionTrigger>Campos personalizados</AccordionTrigger>
                            <AccordionContent>

                                {
                                    camposPersonalizados.map((item, index) => (
                                        <Stack className="mt-3" direction={"row"}>

                                            <RowInput
                                                sx={{ width: '100%' }}
                                                label={item.nome}
                                                input={<Input
                                                    onChange={e => {
                                                        // Clonar profundamente o array para evitar a modificação direta do estado
                                                        const novosCampos = camposPersonalizados.map((campo, i) => {
                                                            if (i === index) {
                                                                // Clonar o objeto que está sendo modificado e atualizar seu valor
                                                                return { ...campo, valor: e.target.value };
                                                            }
                                                            return { ...campo };
                                                        });
                                            
                                                        // Atualizar o estado com o novo array de campos
                                                        setCamposPersonalizados(novosCampos);
                                                        console.log('camposPersonalizados', novosCampos);
                                                    }} 
                                                    value={item?.valor}

                                                    fullWidth
                                                />}
                                            />

                                            <Stack>
                                                <Button onClick={() => removeCampoPersonalizado(index)} variant="ghost" size="icon" className="mt-5 ml-1">
                                                    <Iconify icon="material-symbols-light:delete-outline" />
                                                </Button>

                                            </Stack>

                                        </Stack>
                                    ))
                                }
                                <Divider sx={{ pt: 1, pb: 1, borderStyle: 'dashed' }} />
                                <Stack className="mt-5 w-full" direction={"row"}>
                                    <Stack className="w-full" spacing={1} direction={"row"} alignItems={"center"}>
                                        <RowInput
                                            label="nome do campo"
                                            sx={{ width: '30%' }}
                                            input={<Input
                                                onChange={e => setCampoPersonalizado(
                                                    { ...campoPersonalizado, nome: e.target.value }
                                                )}
                                                value={campoPersonalizado.nome}
                                              className="rounded-sm w-full h-6 bg-slate-50"
                                            />}
                                        />
                                        <RowInput
                                            label="valor"
                                            sx={{ width: '70%' }}

                                            input={<Input
                                                onChange={e => setCampoPersonalizado(
                                                    { ...campoPersonalizado, valor: e.target.value }
                                                )}
                                                value={campoPersonalizado.valor}
                                               className="rounded-sm w-full h-6 bg-slate-50"
                                            />}
                                        />
                                    </Stack>
                                    <Button onClick={addNovoCampoPersonalizado} size="icon" className="mt-6 ml-1 h-7 bg-green-300">
                                        <Iconify icon="ic:baseline-add" />
                                    </Button>

                                </Stack>
                            </AccordionContent>
                        </AccordionItem>

                    </Accordion>

                </Stack>

            </Stack>

            {showSaveButton && ([
                <Stack direction="row" sx={{ mt: 2, mb: 2 }}>
                    <Button
                        onClick={handleCallbackAddContact}
                        sx={{ height: 25, fontSize: 10, borderRadius: 0.5 }}
                        variant="contained"
                        startIcon={<Iconify icon="mdi:plus" />}
                    >
                        Adicionar
                    </Button>
                </Stack>
            ])}

            {showSignupButton && ([
                <Stack direction="row" sx={{ mt: 5, mb: 2 }}>
                    <LoadingButton
                        loading={loadingNovoContato}
                        onClick={handleCallbackAddContact}
                        sx={{ height: 40, fontSize: 12, borderRadius: 0.5 }}
                        variant="contained"
                        startIcon={<Iconify icon="mdi:plus" />}
                    >
                        Criar Contato
                    </LoadingButton>
                </Stack>
            ])}





        </div>
    );
}
