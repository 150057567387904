import { useState } from 'react';
// material
import { 
  
     Dialog,
     Drawer,
     DialogContent,
     Toolbar,
     Box,
     TextField,
     IconButton,
     Stack,
     Typography 

} from '@mui/material';

import { AiFillCloseCircle, AiOutlineCloseCircle } from "react-icons/ai"
import Iconify from 'src/components/Iconify';
// ----------------------------------------------------------------------

export default function ModalMensagensRapidas({
    data,
    pesquisaRespostaRapida,
    dialogRespostasRapidas,
    onClose,
    changePesquisa,
    onSelect
}) {


    function validaTipo(type) {
        if (type === "texto") {
          return <Iconify icon={'ion:text'} width={24} height={24} />;
        } else if (type === "arquivo") {
          return <Iconify icon={'solar:file-bold-duotone'} width={24} height={24} />;
        } else if (type === "audio") {
          return <Iconify icon={'mingcute:voice-line'} width={24} height={24} />;
        } 
      }


  return (
    <Drawer
    anchor={'right'} 	
    open={dialogRespostasRapidas} 
    onClose={onClose}
     >
        <Toolbar>
            <IconButton edge="start" color="inherit" onClick={onClose} aria-label="close">
                <AiFillCloseCircle />
            </IconButton>
        </Toolbar>
        <DialogContent>
            <Stack spacing={5}>
                <Stack direction={'collumn'}>
                <TextField value={pesquisaRespostaRapida} onChange={changePesquisa} placeholder="Pesquisar" />
                </Stack>
                <Stack>
                <Box>
                 {data && data.map((data, index) => {
                    return (
                        <div key={index} onClick={() => {
                            onSelect(data);
                       
                        }} style={{ padding: 10, margin: 10, background: '#f9f9f9', borderRadius: 10 }} >
                            <Stack spacing={3} direction={'row'}>
                                <Stack>
                                    {validaTipo(data.tipo)}
                                    </Stack>
                                <Stack>  
                                    <Typography variant='subtitle2'>{data.titulo}</Typography>
                                    <Typography variant='caption'>{data.resposta}</Typography>
                                </Stack>
                            </Stack>
                          
                
                        </div>
                    )
                })}
                </Box>
                </Stack>
            </Stack>
        </DialogContent>
    </Drawer>
  );
}
