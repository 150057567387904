import {
  Box,
  Grid,
  Typography,
  Stack,
  Button,
  Divider,
  Container
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

//paginas
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import Iconify from 'src/components/Iconify';
import { formataNumeroCelular } from 'src/utils/functions';
import Label from 'src/components/Label';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import DialogTemplates from './DialogTemplates';
import DialogCriarFlow from './DialogCriarFlow';

// ----------------------------------------------------------------------

FlowsComponent.propTypes = {
  canais: PropTypes.array,
};

export default function FlowsComponent({ list, onConfigFlow, user, cliente, onCallback }) {
  const navigate = useNavigate();
  const [openTemplates, setOpenTemplates] = useState(false)
  const [openCriarFlow, setOpenCriarFlow] = useState(false)

  return (
    <Container maxWidth="lg">
    <DialogCriarFlow
    cliente={cliente}
    open={openCriarFlow}
    onClose={() => setOpenCriarFlow(false)}
    onCallback={flow =>  {
      setOpenCriarFlow(false);
      onCallback()
    }}
    />

      <HeaderPageComponentConfig
        titulo="fluxos"
        descricao={'Crie fluxos automatizados para atender seus clientes.'}
       actions={<Stack spacing={1} direction={"row"}>
        <Button onClick={() => setOpenTemplates(true)} startIcon={<Iconify icon="fluent:calendar-template-20-filled" />} variant="outlined">templates</Button>
        <Button onClick={() => setOpenCriarFlow(true)} startIcon={<Iconify icon="fluent:bot-sparkle-24-filled" />} variant='contained'>novo fluxo</Button>
        </Stack>}
      />
      <Divider sx={{my:3, borderStyle:'dashed'}} />
      <Box sx={{ flex: 1, px: 3 }}>
        <DialogTemplates
        open={openTemplates}
        onClose={() => setOpenTemplates(false)}
     
        />
{/* 
        <Grid sx={{ mt: 0 }} container spacing={1.5}>
          {list && list.map((item, index) => (
            <Grid key={index} item xs={12} md={3}>
              <CanalCard
                botao={() => window.open(`http://studio.lais.app/pt-BR/signin/${item.config?.flowIdAccess}`, '_blank')}
                onConfigFlow={() => onConfigFlow(item)}
                canal={item}
              />
            </Grid>
          )
          )}
        </Grid> */}

      </Box>
    </Container>
  );
}

// ----------------------------------------------------------------------

CanalCard.propTypes = {
  item: PropTypes.object,
};

function CanalCard({ canal, botao, onConfigFlow }) {

  function formatIcons(key) {
    switch (key) {
      case "whatsapp":
        return <Iconify icon={"dashicons:whatsapp"} width={25} height={25} />
      case "instagram":
        return <Iconify icon={"ri:instagram-fill"} width={25} height={25} />
      case "messenger":
        return <Iconify icon={"mingcute:messenger-fill"} width={25} height={25} />
      default:
        break;
    }

  }

  return (
    <Box
      sx={{
        backgroundColor: theme => theme.palette.grey[100],
        border: '1px dashed #e5e2f7',
        p: 1,
        borderRadius: 1,
        display: 'flex',
        position: 'relative',
        alignItems: 'center',
        flexDirection: 'column',
      }}
    >
      <Stack direction="column" alignItems="center" justifyContent="center" spacing={2} >
        <Stack>
          {formatIcons(canal.platform)}
        </Stack>
        <Typography textAlign={"left"} variant="subtitle2" color="text.primary">
          {canal.name}
        </Typography>

        <Typography variant="body2" sx={{ color: 'text.secondary' }}>
          {formataNumeroCelular(canal.numero_whatsapp)}
        </Typography>

      </Stack>

      <Stack direction={{md:"column", sm:"column", lg:"row", xs:"column"}} spacing={1} sx={{ mt: 1, mb: 2 }}>
        <Button
          variant="outlined"
          size="small"
          sx={{ borderRadius:0.5, height: 24, borderRadius: 1, fontSize: 12, fontWeight: 600, textTransform: 'none' }}
          onClick={onConfigFlow}
        >
          Configuração
        </Button>
        <Button
          variant="outlined"
          size="small"
          sx={{ borderRadius:0.5, height: 24, borderRadius: 1, fontSize: 12, fontWeight: 600, textTransform: 'none' }}
          onClick={botao}
        >
          Abrir Studio
        </Button>
      </Stack>
    </Box>
  );
}
// ----------------------------------------------------------------------


