// material
import {
    Stack, Typography, Drawer, Container, IconButton, Box
} from '@mui/material';
import 'react-phone-number-input/style.css'
import Iconify from 'src/components/Iconify';
import NovoNegocioForm from './NovoNegocioForm';
import { useEffect, useState } from 'react';
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';
import { setModalAlerta } from 'src/redux/slices/global';
import { useDispatch } from 'react-redux';
import { getListaColunasCrm, getListaLeads } from 'src/redux/slices/crm';
import v2_actionCriarCrmLead from 'src/actions/v2/crm/v2_actionCriarCrmLead';
import moment from 'moment';
// ----------------------------------------------------------------------

export default function ModalNovoNegocio({
    open,
    onClose,
    chat,
    cliente,
    user
}) {
    const [loading, setLoading] = useState(false);
    const dispatch = useDispatch();

    const [inicial, setInicial] = useState(new Date());
    const [final, setFinal] = useState(new Date());
    const [periodo, setPeriodo] = useState('ultimos_90_dias')
    const [negocio, setNegocio] = useState(null);

    const [configCrm, setConfigCrm] = useState(null);
    async function buscarConfigCrm(querys) {
        try {
            const result = await v2_actionConfigCrm(cliente._id, querys);
            if (result.data.data) {
                setConfigCrm(result.data.data);
            }
        } catch (error) {   
            console.log(error);
        }
    }



    useEffect(() => {
        buscarConfigCrm(null)

    }, [open])


    async function handleListaChatsFunil(pipelineId, qualificacao, responsaveis, querysData) {
        try {
          let inicio2 = moment(inicial).format("YYYY-MM-DD");
          let final2 = moment(final).format("YYYY-MM-DD");
          let querys = querysData ? querysData : `?periodo=${periodo}&usuario_id=${user.id}&cliente_id=${user.clienteId}&data_inicial=${inicio2}&data_final=${final2}&pipeline_id=${pipelineId}&qualificacao=${qualificacao}&responsaveis=${responsaveis}`
         
          dispatch(getListaLeads(querys));
          dispatch(getListaColunasCrm(querys));
        } catch (error) {
          console.log('error', error)
        }
      }

    const criarNegocio = async (negocio) =>  {
        setNegocio(negocio);

        try {
            let data = {
                clienteId: cliente._id,
                usuarioId: user.id,
                scope: {
                    nome:negocio.nome,
                    qualificacao:negocio.qualificacao,
                    pipelineId:negocio.pipelineId,
                    pipelineEstagioId:negocio.pipelineStageId,
                    origemId:negocio.origemLead,
                    contatoId:negocio.contatoId,
                    empresa_id:negocio.empresaId,
                    nome: negocio.nome,
                }
            }

            await v2_actionCriarCrmLead(data);
            onClose();

            handleListaChatsFunil(negocio.pipelineId, 'all', negocio.responsaveis, null)

             
        } catch (error) {
            console.log('Error', error)
        }
    }



    return (
        <div>

            <Drawer
                variant=''

                open={open}
                onClose={onClose}
                anchor="right"
                PaperProps={{ sx: { width: { xs: 1, sm: 400 } } }}
            >
                <Container>
                    <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={1}>
                        <Stack justifyContent={'space-between'} direction="row" alignItems={'center'}>
                            <Typography variant="h6">Novo negócio</Typography>
                            <IconButton onClick={onClose} >
                                <Iconify icon={'eva:close-outline'} sx={{ width: 20, height: 20 }} />
                            </IconButton>
                        </Stack>
                    </Stack>

                    <NovoNegocioForm
                    dispatch={dispatch}
                        cliente={cliente}
                        user={user}
                        
                        callbackAddLead={data => criarNegocio(data)}
                        loading={loading}
                        onSelectPipeline={value => {
                            let querys = `?pipeline_id=${value}`
                            buscarConfigCrm(querys);
                        }}
                        configCrm={configCrm}
                    />

                </Container>
            </Drawer>
        </div>
    );
}
