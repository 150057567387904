import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
// substituir  >>>>>>    const selectedMessage = useSelector(state => state.io.selectedMessage); 
import { useDropzone } from 'react-dropzone';
import useResponsive from 'src/hooks/useResponsive';
// services
import { getChatInfoByChatId, getMensagensWhatsapp, getMensagensWhatsappDb, setSelectedMessage, setShowLoadAllMessagesWhatsapp } from 'src/redux/slices/chat';
import { useDispatch, useSelector } from 'src/redux/store';
import { getSession, getToken } from "src/services/auth";
// component
import Scrollbar from 'src/components/Scrollbar';
import ChatComponent from "src/components/_chats/chat/components/ChatPage/ChatComponent";
import { MyTooltip } from "src/components/_chats/chat/components/ChatPage/MyTooltip"
import { ReplyContainer } from "src/components/_chats/chat/style";
import Iconify from "src/components/Iconify";
import { IconButton, Stack } from "@mui/material";
import SkeletonChatWindow from 'src/components/skeleton/chats/SkeletonChatWindow';
import LightboxModal from "src/components/LightboxModal";
import { isSameDay, parseISO, format } from "date-fns";
import { Box } from '@mui/material';
import v2_actionDeletarNota from 'src/actions/v2/chats/v2_actionDeletarNota';
import useAuth from 'src/hooks/useAuth';

ChatMessageList.propTypes = {
  conversa: PropTypes.array.isRequired
};

export default function ChatMessageList({
  conversa,
  chat,
  cliente,
  callbackGpt,
  loadingChat,
  config
}) {
  const scrollRef = useRef();
  const chatRef = useRef(null);
  const messagesEnd = useRef(null);
  const dispatch = useDispatch();
  const isDesktop = useResponsive('up', 'md');
  const [setFiles] = useState([]);
  const { selectedMessage, showLoadAllMessages, chatInfo } = useSelector((state) => state.chat);
  const [clickedUrl, setClickedUrl] = useState("");
  const [isDownloadImage, setIsDownloadImage] = useState(false);
  const [openModalImage, setOpenModalImage] = useState(false);

   const {user} = useAuth();

  // Função para converter a data em timestamp
  function toTimestamp(date) {
    return Math.floor(new Date(date).getTime() / 1000);
  }

  const notesChat = chatInfo?.notas || [];

  let messagesChat = conversa || [];

  // Inserir todos os objetos de `notas` dentro do array `mensagens`
  notesChat.forEach(nota => {
    let novaNota = {
      id: nota.id,
      body: nota.body,
      usuario_id: nota.usuario_id,
      date: nota.date,
      timestamp: toTimestamp(nota.date),
      t: toTimestamp(nota.date),
      type: "note",
      fromMe: true,
      userName: nota.userName
    };
    conversa.push(novaNota);
  });

  // Ordenar os objetos dentro do array `mensagens` pela data
  messagesChat = conversa.sort((a, b) => a.t - b.t);


  const scrollMessagesToBottom = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
    if (isDesktop) {
      scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
    }
  };

  useEffect(() => {
    scrollMessagesToBottom();
  }, [conversa.length, selectedMessage]);


  async function handleDeleteNote(id) {
    try {
      await v2_actionDeletarNota(id);
      dispatch(getChatInfoByChatId(chat._id, null, user.id, false));
    } 
    catch (error) {
      console.log
    }
  }





  const groupMessagesByDate = (messages) => {
    return messages.reduce((acc, message) => {
      const date = new Date(message.t * 1000).toLocaleDateString();
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
  };


  const Chat = ({ messages }) => {
    const groupedMessages = groupMessagesByDate(messages);

    return (
      <div>
        {Object.keys(groupedMessages).map((date) => (
          <div key={date}>
            <div className='w-full flex justify-center'>
              <div className=" p-2 bg-slate-100 shadow-sm rounded-lg text-xs font-bold text-slate-500">{date}</div>

            </div>
            {groupedMessages[date].map((message, index) => (
              <ChatComponent
                config={config}
                chat={chatInfo}
                callbackGpt={value => {
                  callbackGpt(value);
                }}
                key={index}
                onDeleteNote={() => handleDeleteNote(message.id)}
                isMe={message.fromMe ? "right" : "left"}
                session={getSession()}
                token={getToken()}
                message={message}
                onCallbakOpenModal={image => {
                  setClickedUrl(image);
                  setOpenModalImage(true);

                }}
                callBackOpenDrawerGptw
                selectMessageId={() => {
                  scrollMessagesToBottom();
                  dispatch(setSelectedMessage({ message: message }))
                }}
                isDesktop={isDesktop}
              />
            ))}
          </div>
        ))}
      </div>
    );
  };




  const { getInputProps } = useDropzone({
    accept: ['image/*', 'application/*'],
    acceptStyle: { backgroundColor: "#0aafd9" },
    rejectStyle: { backgroundColor: "#0aafd9" },
    onDrop: acceptedFiles => {
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });




  return (
    <Scrollbar scrollableNodeProps={{ ref: scrollRef }} sx={{ p: 3, pb: 20, height: 'calc(100vh - 130px)' }}>
      <input  {...getInputProps()} />

      <LightboxModal
        images={clickedUrl}
        photoIndex={0}
        mainSrc={clickedUrl}
        setPhotoIndex={() => { }}
        isOpen={openModalImage}
        download={() => baixarMidiaDevice()}
        onClose={() => {
          setOpenModalImage(false);
          setIsDownloadImage(false);
          setClickedUrl("");
        }}
      />


      <ul ref={chatRef}>
        {cliente && cliente.config.chat_source_db && showLoadAllMessages && (
          <Stack justifyContent={'center'} alignItems={'center'}>
            <IconButton onClick={() => {
              if (chat.statusRoom === "Atendendo") {
                dispatch(getMensagensWhatsapp(chat.sessao, chat.numeroCliente));
              } else {
                dispatch(getMensagensWhatsapp(chat.sessao, chat.numeroCliente));
              }

            }} style={{ cursor: "pointer", backgroundColor: '#fff', color: "#0aafd9", fontSize: "20px" }} >
              <Iconify icon="material-symbols:refresh" />
            </IconButton>
          </Stack>
        )}

        {loadingChat ? (
          <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
            <SkeletonChatWindow />
          </Box>
        ) : <Chat messages={messagesChat} />}

        <div ref={messagesEnd} />
      </ul>


      {!!selectedMessage && (
        <ReplyContainer>

          <ChatComponent
            callbackGpt={callbackGpt}
            isMe={selectedMessage.fromMe ? "right" : "left"}
            isWarning={!selectedMessage?.body && selectedMessage.type !== "chat" && !["ptt", "audio"].includes(selectedMessage.type)}
            session={getSession()}
            token={getToken()}
            chat={chatInfo}
            message={selectedMessage}
            onCallbakOpenModal={image => {
              setClickedUrl(image);
              setOpenModalImage(true);

            }}
            selectMessageId={() => {
              scrollMessagesToBottom();
              dispatch(setSelectedMessage(selectedMessage))
            }}
          />

          <MyTooltip
            name="Cancelar"
            icon={<Iconify icon="material-symbols:close" />}
            onClick={() => dispatch(setSelectedMessage({}))}
          />

        </ReplyContainer>
      )}
    </Scrollbar>

  );
}
