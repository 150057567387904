// material
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';
import {
  Stack,
  Button,
  Container,
  Typography,
  TextField,
  Drawer,
  FormControlLabel,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  Divider,
  Alert,
  RadioGroup,
  Radio,
  FormLabel,
  IconButton,
  CircularProgress,
  Paper
} from '@mui/material';
import { UploadCampanha } from 'src/components/upload';

//actions
import { fDate } from 'src/utils/formatTime';
import { AiOutlineUnderline } from 'react-icons/ai';
import RecordingTimer from '../chat/components/RecordingTimer';
import { FaCheck } from 'react-icons/fa';
import { BsMicFill } from 'react-icons/bs';
import Iconify from 'src/components/Iconify';

// ----------------------------------------------------------------------


export default function DrawerAgendamentos({
  dataAgendamento,
  hora,
  minuto,
  body,
  changeBody,
  isFile,
  numero,
  changeNumero,
  loadingEnviarArquivos,
  preview,
  isEdit,
  isEditFile,
  filesEdit,
  files,
  ddd,
  isAlert,
  changeDDD,
  changeFile,
  changeData,
  changeHora,
  changeMinuto,
  handleDropMultiFile,
  handleRemove,
  onCriarAgendamento,
  onEditarAgendamento,
  handleRemoveEdit,
  drawerAgendamento,
  onCloseDrawerAgendamento,
  isChatAtendimento,
  onChangeIsChatAtendimento,
  changeTipoArquivo,
  tipoArquivo,
  audioGravado,
  recording,
  loading,
  handleCancelAudio,
  pararGravacao,
  handleStartRecording,
  changeAudioGravado,
  isMultisession,
  listaClientesCanais,
  clienteIdPadrao,
  handleChangeClienteIdPadrao
}) {

  const verificaAlertaDdd = () => {
    if (ddd && ddd.length > 1) {
      if (ddd > 30) {
        return <Alert severity="info">Esse número <strong>NÃO PRECISA colocar o dígito 9</strong></Alert>
      } else {
        return <Alert severity="info">Esse número <strong>precisa colocar o dígito 9</strong></Alert>
      }
    }

  }




  return (
    <Drawer open={drawerAgendamento} onClose={onCloseDrawerAgendamento} anchor="right" PaperProps={{ sx: { width: { xs: 1, sm: 600 } } }}>
      <Container>
        <Typography sx={{ mt: 3, mb:2 }} variant='h6'>Agendamentos</Typography>
        <Divider />
        <Stack sx={{mt:2}} spacing={3}>

  


        <Stack spacing={2} direction="row" alignItems="center" mb={1}>

          {isMultisession && (
     
              <Stack alignItems={'center'} direction={'row'} spacing={1}>
                <Typography variant="caption">Canal:</Typography>
                <Select
                  size="small"
                  placeholder='Escolha um funil'
                  defaultValue={clienteIdPadrao}
                  sx={{ minWidth: 150, height: 35 }}
                  value={clienteIdPadrao}
                  onChange={handleChangeClienteIdPadrao}
                >
                  {listaClientesCanais.map((option) => (
                    <MenuItem key={option.cliente_id} value={option.cliente_id}>
                      {option.nome}
                    </MenuItem>

                  ))}
                </Select>
              </Stack>
        
          )}
        </Stack>

        <Divider sx={{ mt: 2, mb: 2 }} />

        <Stack sx={{ mt: 6 }} spacing={1} direction="row">
          <TextField size="small" inputProps={{ maxLength: 2 }} value={ddd} onChange={changeDDD} placeholder='DDD' />
          <TextField size="small" fullWidth inputProps={{ maxLength: parseInt(ddd) > 30 ? 8 : 9 }} value={numero} onChange={changeNumero} placeholder='Número Whatsapp' />
        </Stack>

        <Stack sx={{ mt: 2 }}>
          {verificaAlertaDdd()}
        </Stack>


        <Stack sx={{ mt: 2 }} spacing={2} direction={'row'}>
          <FormControlLabel control={<Switch
            checked={isChatAtendimento}
            onChange={onChangeIsChatAtendimento}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Colocar chats em atendimento" />
        </Stack>



        <Stack spacing={2}>
          <Stack sx={{ mt: 3 }} alignItems={'center'} spacing={2} direction={'row'}>
            <DatePicker
              label="Data Inicial"
              inputFormat="dd/MM/yyyy"
              value={dataAgendamento}
              minDate={new Date('2017-01-01')}
              onChange={changeData}
              renderInput={(params) => <TextField  {...params} size="small" margin="normal" />}
            />
            <FormControl sx={{ width: 100 }}>
              <InputLabel id="demo-simple-select-label">Hora</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={hora}
                label="Hora"
                size='small'
                onChange={changeHora}
              >
                <MenuItem value={'6'}>06</MenuItem>
                <MenuItem value={'7'}>07</MenuItem>
                <MenuItem value={'8'}>08</MenuItem>
                <MenuItem value={'9'}>09</MenuItem>
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'11'}>11</MenuItem>
                <MenuItem value={'12'}>12</MenuItem>
                <MenuItem value={'13'}>13</MenuItem>
                <MenuItem value={'14'}>14</MenuItem>
                <MenuItem value={'15'}>15</MenuItem>
                <MenuItem value={'16'}>16</MenuItem>
                <MenuItem value={'17'}>17</MenuItem>
                <MenuItem value={'18'}>18</MenuItem>
                <MenuItem value={'19'}>19</MenuItem>
                <MenuItem value={'20'}>20</MenuItem>
                <MenuItem value={'21'}>21</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ width: 100 }}>
              <InputLabel id="demo-simple-select-label">Minutos</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={minuto}
                label="Minutos"
                size='small'
                onChange={changeMinuto}
              >
                <MenuItem value={'01'}>0</MenuItem>
                <MenuItem value={'10'}>10</MenuItem>
                <MenuItem value={'20'}>20</MenuItem>
                <MenuItem value={'30'}>30</MenuItem>
                <MenuItem value={'40'}>40</MenuItem>
                <MenuItem value={'50'}>50</MenuItem>

              </Select>
            </FormControl>
          </Stack>
          {hora && minuto && <Typography variant='body'>Sua mensagem será enviada em {fDate(dataAgendamento)} as {hora}:{minuto}</Typography>}
        </Stack>

        <Stack spacing={2} sx={{ mt: 5 }}>
          {!isFile && (
            <>
              <TextField multiline inputProps={{ maxLength: 1000 }} value={body} onChange={changeBody} rows={4} placeholder='Texto' />
              <Typography sx={{ fontSize: 10 }}>{body.length}/1000</Typography>
            </>

          )}



          <FormControlLabel control={<Switch
            checked={isFile}
            onChange={changeFile}
            inputProps={{ 'aria-label': 'controlled' }}
          />} label="Inserir Arquivos" />


          {isFile && (
            <>

              <FormControl sx={{ mt: 5, mb: 5 }}>
                <FormLabel id="demo-controlled-radio-buttons-group">Tipo</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={tipoArquivo}
                  onChange={changeTipoArquivo}
                >
                  <FormControlLabel value="arquivo" control={<Radio />} label="Arquivo" />
                  <FormControlLabel value="audio" control={<Radio />} label="Audio" />
                </RadioGroup>
              </FormControl>

              {tipoArquivo === "arquivo" && (
                <UploadCampanha
                  loadingEnviarArquivos={loadingEnviarArquivos}
                  showPreview={preview}
                  isEdit={isEdit}
                  isEditFile={isEditFile}
                  files={files}
                  filesEdit={filesEdit}
                  onDrop={handleDropMultiFile}
                  onRemove={handleRemove}
                  onRemoveEdit={handleRemoveEdit}
                />
              )}

              {tipoArquivo === "audio" && (
                <>
                  {recording ? (
                    <Stack direction="column" spacing={2}>
                      <Stack spacing={2} direction="row" alignItems={'center'}>
                        <Typography variant="subtitle2">
                          Gravação em andamento
                        </Typography>
                        <CircularProgress size={15} />
                      </Stack>
                      <Stack direction="row">
                        <IconButton
                          aria-label="cancelRecording"
                          component="span"
                          fontSize="large"
                          disabled={loading}
                          onClick={handleCancelAudio}
                        >
                          <Iconify icon="mdi:cancel" />
                        </IconButton>
                        {loading ? (
                          <div>
                            <CircularProgress />
                          </div>
                        ) : (
                          <RecordingTimer />
                        )}

                        <IconButton
                          aria-label="sendRecordedAudio"
                          component="span"
                          onClick={pararGravacao}
                          disabled={loading}
                        >
                          <FaCheck />
                        </IconButton>
                      </Stack>

                    </Stack>
                  ) : (
                    <Stack direction={'row'}>

                      {!audioGravado && (
                        <Button
                          startIcon={<BsMicFill />}
                          variant='contained'
                          size="small"
                          component="span"
                          disabled={loading}
                          onClick={handleStartRecording}
                        >
                          Gravar audio
                        </Button>
                      )}

                      {audioGravado && (
                        <Stack direction={'row'} alignItems={'center'} spacing={2}>
                          <audio controls>
                            <source src={URL.createObjectURL(audioGravado)} type="audio/mp3" />
                            Seu navegador não suporta a reprodução de áudio.
                          </audio>
                          <IconButton onClick={changeAudioGravado} variant='outlined' color="error">
                            <Iconify icon="material-symbols:delete" />
                          </IconButton>
                        </Stack>

                      )}
                    </Stack>

                  )}
                </>
              )}

            </>

          )}
        </Stack>

        {isAlert && (
          <Stack sx={{ mt: 5 }}>
            <Alert severity="warning">Preencha todos os campos antes de continuar</Alert>
          </Stack>
        )}


        <Stack sx={{ mt: 6 }} spacing={3} direction="row" justifyContent="flex-end">
          <Button onClick={onCloseDrawerAgendamento} sx={{ mr: 1.5 }}>Cancelar</Button>
          <LoadingButton fullWidth onClick={isEdit ? onEditarAgendamento : onCriarAgendamento} loading={loadingEnviarArquivos} variant="contained" >{isEdit ? "Editar" : "Criar Agendamento"}</LoadingButton>
        </Stack>

        </Stack>
      </Container>
    </Drawer>

  );
}
