import axios from "axios";
import Config from "src/config";

const v1_actionResponderMensagemWhatsapp = (querys, data) => {
    return axios({
        method: "post",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/canais/responder-mensagem${querys}`,
        data
    });
};

export default v1_actionResponderMensagemWhatsapp;
