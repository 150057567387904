import axios from "axios";
import Config from "src/config";

const actionMarcarNotificacoesComoLidas = usuarioId => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/notificacoes/${usuarioId}/mark-read`
    });
};

export default actionMarcarNotificacoesComoLidas;
