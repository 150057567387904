import { Link } from "@mui/material"
import {
  Bell,
  CircleUser,
  Home,
  LineChart,
  Menu,
  Package,
  Package2,
  Search,
  ShoppingCart,
  Users,
} from "lucide-react"

import { Badge } from "src/@/components/ui/badge"
import { Button } from "src/@/components/ui/button"
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "src/@/components/ui/card"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "src/@/components/ui/dropdown-menu"
import { Input } from "src/@/components/ui/input"
import { Sheet, SheetContent, SheetTrigger } from "src/@/components/ui/sheet"

import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
// @mui
// redux
import useAuth from 'src/hooks/useAuth';
import { useDispatch } from 'src/redux/store';
// routes
// hooks
// components
import MainIntegracoes from 'src/sections/@dashboard/configuracoes/Integracoes/MainIntegracoes';

//paginas
import actionListaCanaisPorCliente from 'src/actions/clientes/actionListaCanaisPorCliente';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import v1_actionListaAutomacoes from 'src/actions/v1/clientes/v1_actionListaAutomacoes';

import v1_actionListaFlows from 'src/actions/v1/clientes/v1_actionListaFlows';
import { useLocation } from 'react-router-dom'; // se estiver usando react-router
import Iconify from "src/components/Iconify"
import PerfilComponent from "src/sections/@dashboard/configuracoes/perfil"
import SidebarDashboard from "src/sections/@dashboard/SidebarDashboard"
import { ConsumoComponent } from "src/sections/@dashboard/conta/consumo"

export default function Conta() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { user, cliente } = useAuth()
  const { label } = useParams();
  const [openSidebar, setOpenSidebar] = useState(false);
  const [listaCanais, setCanais] = useState([]);
  const [listaFlows, setListaFlows] = useState([]);
  const [listaAutomacoes, setListaAutomacoes] = useState([]);
  const [modalAlerta, setModalAlerta] = useState(false);
  const [msgModalAlerta, setMsgModalAlerta] = useState("");
  const [flow, setFlow] = useState(null);

  const location = useLocation();
  let path = location.pathname.substring(1); // Remove o "/" inicial
  console.log('path', path)

  const [tab1, setTab1] = useState('configuracoes');

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const code = queryParams.get('code');

    if (code) {
      // Você tem o código aqui, pode agora fazer uma chamada de servidor para servidor para trocá-lo por um token de acesso
      console.log("Código de autorização:", code);
    }
  }, [location]);


  <ModalAlerta
    open={modalAlerta}
    mensagem={msgModalAlerta}
    onClose={() => setModalAlerta(false)}
  />

  const buscarListaAutomacoes = async () => {
    try {

      const response = await v1_actionListaAutomacoes(cliente._id)
      setListaAutomacoes(response.data.data);

    } catch (error) {

    }
  }

  const buscarListaCanais = async () => {
    try {
      const response = await actionListaCanaisPorCliente(cliente._id)
      setCanais(response.data.data)
    } catch (error) {
      setMsgModalAlerta("Erro ao buscar lista de canais, tente novamente mais tarde. Se persistir entre em contato com o suporte.")
      setModalAlerta(true)
    }
  }


  const buscarListaFlows = async () => {
    try {
      const response = await v1_actionListaFlows(cliente._id)
      setListaFlows(response.data.data)
    } catch (error) {
      setMsgModalAlerta("Erro ao buscar lista de canais, tente novamente mais tarde. Se persistir entre em contato com o suporte.")
      setModalAlerta(true)
    }
  }

  // eslint-disable-next-line no-unused-vars


  const linkTo = (label) => {
    return `/dashboard/configuracoes/${label}`;

  };


  function abrirOutraPagina(canal) {
    window.open(`https://www.facebook.com/v20.0/dialog/oauth?client_id=1130925304811273&state=${canal._id}&redirect_uri=https://webhook-beta.lais.app/webhook/v1/auth&scope=instagram_basic,instagram_manage_messages`, '_blank');
  }

  useEffect(() => {

    buscarListaCanais();
    buscarListaAutomacoes();
    buscarListaFlows();

  }, [dispatch]);

  const LinkItem = ({title, onClick}) => {
    return (
      <Link
        onClick={onClick}
        className="flex items-center gap-3 rounded-lg px-3 py-1.5 text-muted-foreground transition-all hover:text-primary cursor-pointer"
      >
        {title}
      </Link>
    )
  }

  const LinkHeader = ({title, icon}) => {
    return (
      <div className="flex items-center gap-2 mb-2">
      <Iconify size={15} icon={icon} className="text-primary" />
      <div className="text-sm font-semibold">{title}</div>
    </div>
    )
  }




  return (
    <div className="grid min-h-screen w-full md:grid-cols-[180px_1fr] lg:grid-cols-[220px_1fr]">
      <SidebarDashboard user={user}/>
      <div className="flex flex-col">

        <main className="mt-10">
          <>
          
            {label === "perfil" && <PerfilComponent cliente={cliente} />}
            {label === "consumo" && <ConsumoComponent />}
           
          </>
        </main>
      </div>
    </div>
  )
}
