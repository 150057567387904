import { createSlice } from '@reduxjs/toolkit';


const initialState = {
    isOpen: [], // for active default menu
    fontFamily: `'Roboto', sans-serif`,
    borderRadius: 12,
    opened: true,
    isHorizontal: true,
    isDarkMode: false
}

const slice = createSlice({
  name: 'customization',
  initialState,
  reducers: {}
});

// Reducer
export default slice.reducer;







