import axios from "axios";

import Config from "../../../config";

const v2_actionMetaListaPaginas = (querys) => {
    let url = `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/meta/canal/lista-paginas${querys}`;
    return axios({
        method: "get",
        url:url
    });
};

export default v2_actionMetaListaPaginas;
