import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup,
    Stack,
    Switch,
    TextField,
    Typography
} from '@mui/material';
import PropTypes from 'prop-types';
import { Textarea } from 'src/@/components/ui/textarea';
import { RowInput } from 'src/components/forms/RowInput';

// @mui

ModalChatGpt.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
};

export default function ModalChatGpt({
    open,
    onClose,
    chatGptStatus,
    changeStatusIntegracao,
    changeTipoIntrucaoChatGpt,
    tipoIntrucaoChatGpt,
    instrucaoChatgpt,
    changeInstrucaoChatgpt,
    chatGptApiKey,
    chatGptOrganizationKey,
    changeChatGptApiKey,
    changeChatGptOrganizationKey,
    children,
    onSubmit
}) {


    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            maxWidth={'sm'}
            fullWidth={true}
        >
            <DialogTitle>Lais Copilot - Configurações</DialogTitle>

            <DialogContent sx={{ mt: 3 }}>
                <Stack>

                    <Stack spacing={4}>

                        <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">Status</FormLabel>
                            <Switch
                                checked={chatGptStatus}
                                onChange={changeStatusIntegracao}
                                inputProps={{ 'aria-label': 'controlled' }}
                            />
                        </FormControl>
                        {/* <Stack spacing={2} direction={'column'}>
                            <TextField onChange={changeChatGptApiKey} value={chatGptApiKey} size='small' placeholder='API KEY' variant='outlined' />
                            <TextField onChange={changeChatGptOrganizationKey} value={chatGptOrganizationKey} size='small' placeholder='ORGANIZATION KEY' variant='outlined' />
                        </Stack> */}
                        {/* <FormControl>
                            <FormLabel id="demo-controlled-radio-buttons-group">Tipo de instrução</FormLabel>
                            <RadioGroup
                                aria-labelledby="demo-controlled-radio-buttons-group"
                                name="controlled-radio-buttons-group"
                                value={tipoIntrucaoChatGpt}
                                row
                                onChange={changeTipoIntrucaoChatGpt}
                            >
                                <FormControlLabel value="cliente" control={<Radio />} label="Geral" />
                            </RadioGroup>
                        </FormControl> */}
                        {/* {tipoIntrucaoChatGpt === 'usuario' && (
                            <Alert severity="info"> Insira as intruções nas configurações dos usuários</Alert>
                        )} */}

                        {tipoIntrucaoChatGpt === 'cliente' && (
                            <RowInput
                                label="Prompt"
                                input={<Textarea
                                    value={instrucaoChatgpt}
                                    onChange={changeInstrucaoChatgpt}
                                    multiline rows={4}
                                />
                                }
                            />
                        )}




                    </Stack>

                </Stack>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} autoFocus>
                    Cancelar
                </Button>
                <Button di onClick={onSubmit} variant="contained" autoFocus>
                    Salvar
                </Button>
            </DialogActions>

        </Dialog>
    );
}


