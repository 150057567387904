import Config from "../../../config";
import api from "../../../services/api";
import { getSession} from "../../../services/auth";
import config from "../../../utils/sessionHeader";

const getMessagesWhatsapp = phone => {

   return api.get(`${Config.urlWhatsapp}/api/${getSession()}/get-messages/${phone}`, config);
};

export default getMessagesWhatsapp;
