import jwtDecode from 'jwt-decode';
import { setGlobal } from 'reactn';

import initIO from "./initIO";
import setAuthToken from "./actions/chats/staff/setAuthToken";
//import store from './store';

import { store } from './redux/store'




const init = () => {
    // document.addEventListener('gesturestart', e => {
    //     e.preventDefault();
    // });

    const token = localStorage.getItem('token');
    const userString = localStorage.getItem('user');
    const user = userString ? JSON.parse(userString) : null;

    if (token) {
        setAuthToken(token);
        store.dispatch(initIO(token))
    }

    const state = {
        version: '2.0.20',
        entryPath: window.location.pathname,
        token,
        user: user || (token ? jwtDecode(token) : {}),
        rooms: [],
        searchResults: [],
        favorites: [],
        meetings: [],
        nav: 'rooms',
        search: '',
        over: null,
        isPicker: false,
        messages: [],
        streams: [],
        inCall: false,
        video: true,
        audio: true,
        audioStream: null,
        videoStream: null,
        screenStream: null,
        callStatus: null,
        counterpart: null,
        callDirection: null,
        numeroWhatsappAtivo: null,
        meeting: null,
        showPanel: true,
        panel: 'standard',
        newGroupUsers: [],
    };

    setGlobal(state).then(() => {});

    
  
}

export default init;
