import axios from "axios";

import Config from "../../../../config";

const v2_actionBuscarChatById = (chatId, funilId, userId) => {
    let urlFunil = `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/${chatId}/detalhes?funil_id=${funilId}&usuario_id=${userId}`;
    let url = `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/${chatId}/detalhes?usuario_id=${userId}`;
    return axios({
        method: "get",
        url:funilId ? urlFunil : url
    });
};

export default v2_actionBuscarChatById;
