
import { styled } from '@mui/material/styles';

import {
    Stack,
    Button,
    Container,
    FormControl,
    MenuItem,
    Typography,
    Select
} from '@mui/material';
import MenuDatas from 'src/components/MenuDatas';
import Iconify from 'src/components/Iconify';
import useAuth from 'src/hooks/useAuth';
import { useEffect, useState } from 'react';
import { validaLabelMenuDatas, verifyRules } from 'src/utils/functions';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import MenuOptionsNovo from './MenuOptionsNovo';
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';

import {
    SelectZui
} from 'src/components/zui'
import { SelectGroup, SelectItem } from 'src/@/components/ui/select';
import MenuDatas2 from 'src/components/MenuDatas2';

const RootStyle = styled('div')(({ theme }) => ({
    width: '100%',
    flexShrink: 0,
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(3),
    //borderBottom: `solid 1px ${theme.palette.divider}`,
    transition: theme.transitions.create('width')
}));

export default function NegociosToolbar({
    pipelineId,
    changePipelineId,
    qualificacao,
    changeQualificacao,
    responsaveis,
    changeResponsaveis,
    onFiltrarDataCustom,
    onFiltrarData
}) {
    const { user, cliente } = useAuth();
    const [inicio, setInicio] = useState(new Date());
    const [final, setFinal] = useState(new Date());
    const [periodo, setPeriodo] = useState('mes_atual')
    const [anchorEl, setAnchorEl] = useState(null);
    const openMenuDatas = Boolean(anchorEl);
    const [isPesquisado, setIsPesquisado] = useState(false);
    const [listaChatsPesquisa, setListaChatsPesquisa] = useState([]);
    const [listaChatsFiltro, setListaChatsFiltro] = useState([]);
    const tableData = isPesquisado ? listaChatsPesquisa : listaChatsFiltro;
    const [configCrm, setConfigCrm] = useState(null);

    const optionsResponsaveis = [
        { value: 'me', label: 'Meus Leads' },
        { value: 'all', label: 'Todos Leads' }
    ]

    async function buscarConfigCrm(querys) {
        try {
            const result = await v2_actionConfigCrm(cliente._id, querys);
            if (result.data.data) {
                setConfigCrm(result.data.data);
            }
        } catch (error) {
            console.log(error);
        }
    }


    useEffect(() => {
        buscarConfigCrm();
    }, [])




    return (
        <RootStyle>
            <Stack direction={"row"} alignItems={"center"} justifyContent={"space-between"}>
                <Stack spacing={2} direction="row" alignItems={"flex-end"}>
                    <Stack sx={{ width: '100%' }} spacing={2} direction="row">
                        <RowInput
                            label="Filtrar por data"
                            input={
                                <MenuDatas2
                                    anchorEl={anchorEl}
                                    open={openMenuDatas}
                                    onClose={() => setAnchorEl(false)}
                                    inicial={inicio}
                                    final={final}
                                    content={validaLabelMenuDatas(periodo, inicio, final)}
                                    changeInicial={newValue => setInicio(newValue)}
                                    changeFinal={newValue => { setFinal(newValue) }}
                                    onSelect={value => {
                                        setPeriodo(value)
                                        onFiltrarData(value)
                                    }}
                                    onPesquisar={() => onFiltrarDataCustom('null')}
                                />
                            }
                        />
                        <RowInput
                            sx={{ width: '100%' }}
                            label="funil"
                            input={<SelectZui
                                value={pipelineId}
                                list={configCrm?.pipelines}
                                onChange={e => {
                                    let value = e;
                                    changePipelineId(value);

                                }}
                                itemSelect={(item) => (
                                    <SelectItem key={item._id} value={item._id}>
                                        {item.nome}
                                    </SelectItem>
                                )}
                            />}

                        />



                        <RowInput
                            sx={{ width: '100%' }}
                            label="qualificação"

                            input={
                                <SelectZui
                                    value={qualificacao}
                                    list={configCrm?.qualificacoes}
                                    onChange={e => {
                                        console.log('e', e)
                                        let value = e;

                                        changeQualificacao(value);
                                    }}
                                    emptyOption={{ value: "all", label: 'Todos' }}

                                    itemSelect={(item) => (
                                        <SelectItem key={item.value} value={item.value}>
                                            {item.nome}
                                        </SelectItem>
                                    )}
                                />
                            }

                        />

                        {verifyRules(user.rules, "supervisionar_leads") && (
                            <RowInput
                                sx={{ width: '100%' }}
                                label="Responsáveis"
                                input={<SelectZui
                                    value={responsaveis}
                                    list={optionsResponsaveis}
                                    onChange={e => {
                                        let value = e;
                                        changeResponsaveis(value);
                                    }}
                                    itemSelect={(item) => (
                                        <SelectItem key={item.value} value={item.value}>
                                            {item.label}
                                        </SelectItem>
                                    )}
                                />}
                            />
                        )}


                    </Stack>

                    <Stack direction="row" alignItems={"flex-end"} spacing={1}>
                        <MenuOptionsNovo onSelect={value => console.log(value)} />
                    </Stack>
                </Stack>
            </Stack>
        </RootStyle>
    );
}
