import PropTypes from 'prop-types';
import { noCase } from 'change-case';
import { useState } from 'react';
// @mui
import {
  Box,
  List,
  Badge,
  Avatar,
  Tooltip,
  Divider,
  Typography,
  ListItemText,
  ListSubheader,
  ListItemAvatar,
  ListItemButton,
  Stack,
  LinearProgress
} from '@mui/material';
import {Button} from 'src/@/components/ui/button'
// utils
import { fToNow } from '../../utils/formatTime';
// _mock_
// components
import Iconify from '../../components/Iconify';
import Scrollbar from '../../components/Scrollbar';
import MenuPopover from '../../components/MenuPopover';
import { IconButtonAnimate } from '../../components/animate';

import { useDispatch, useSelector } from 'react-redux';

import { BsMegaphone } from 'react-icons/bs';
import actionMarcarNotificacoesComoLidas from 'src/actions/notificacoes/actionMarcarNotificacoesComoLidas';
import { getListaNotificacoes } from 'src/redux/slices/notificacoes';
import useAuth from 'src/hooks/useAuth';
import { useNavigate } from 'react-router-dom';
import { PATH_DASHBOARD } from 'src/routes/paths';
import { changeOpenDetalhesChamado, getVerChamado } from 'src/redux/slices/chamados';
import actionLerNotificacao from 'src/actions/notificacoes/actionLerNotificacao';
// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const { user } = useAuth();
  const { notificacoesNaoLidas } = useSelector((state) => state.notificacoes);
  const totalUnRead = notificacoesNaoLidas.length;
  const countNotificacoes = notificacoesNaoLidas.length
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
    // handleMarkAllAsRead()
  };

  const handleMarkAllAsRead = async () => {
    try {
      await actionMarcarNotificacoesComoLidas(user.id);
      dispatch(getListaNotificacoes(user.id))
    } catch (error) {
    }
  };



  const handleClickNotification = async (notification) => {
    console.log(notification)

    if (notification.tipo === "chamados") {
      setLoading(true);
      if (notification.chamado_id) {

        await actionLerNotificacao(user.id, { notificacaoId: notification._id })
        dispatch(getVerChamado(notification.chamado_id));
        dispatch(changeOpenDetalhesChamado(true))
        setLoading(false)
        setOpen(null);
        dispatch(getListaNotificacoes(user.id))
        //    navigate({pathname: PATH_DASHBOARD.tickets.lista });
      }

    }
  }

  return (
    <>
        <Button
          onClick={handleOpen}
          variant="text"
          size='icon'
        >
          <Badge badgeContent={totalUnRead} color="error">
            <Iconify color="black" icon="ion:notifications" />
          </Badge>
        </Button>


      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        {countNotificacoes === 0 ? (
          <Box sx={{ p: 2 }}>
            <Stack spacing={3} alignItems="center" direction="column">
              <BsMegaphone color="primary" size={18} />
              <Typography>Sem notificações no momento</Typography>
            </Stack>
          </Box>

        ) : (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', py: 2, px: 2.5 }}>
              <Box sx={{ flexGrow: 1 }}>
                <Typography variant="subtitle1">Notificações</Typography>
                {notificacoesNaoLidas.length > 0 && (
                  <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Você tem {totalUnRead} não lidas
                  </Typography>
                )}

              </Box>

              {totalUnRead > 0 && (
                <Tooltip title=" Marcar todos como lido">
                  <IconButtonAnimate color="primary" onClick={handleMarkAllAsRead}>
                    <Iconify icon="eva:done-all-fill" width={18} height={18} />
                  </IconButtonAnimate>
                </Tooltip>
              )}
            </Box>

            <Divider sx={{ borderStyle: 'dashed' }} />

            {loading ? (
              <Box sx={{ p: 3 }}>
                <LinearProgress />
              </Box>

            ) : (
              <Scrollbar
                sx={{ height: { xs: 340, sm: '80vh', md: '80vh' }, overflow: 'auto' }}
              >
                {notificacoesNaoLidas.length > 0 && (
                  <List
                    disablePadding
                  >
                    {notificacoesNaoLidas.map((notification) => (
                      <NotificationItem onClick={() => handleClickNotification(notification)} key={notification.id} notification={notification} />
                    ))}
                  </List>
                )}
              </Scrollbar>
            )}

            {/* 
        <Divider sx={{ borderStyle: 'dashed' }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
           Ver mais
          </Button>
        </Box> */}
          </>
        )}

      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onClick }) {
  const { mensagem, titulo, tipo, data_criacao } = renderContent(notification);

  return (
    <ListItemButton
      onClick={onClick}
      sx={{
        py: 1.5,
        px: 2.5,
        display: 'flex',
        mt: '1px',
        ...(!notification.lido && {
          bgcolor: 'action.selected',
        }),
      }}
    >
      {/* <ListItemAvatar>
        <Avatar sx={{ bgcolor: 'background.neutral' }}>{avatar}</Avatar>
      </ListItemAvatar> */}
      <ListItemText
        primary={titulo}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: 'flex',
              alignItems: 'center',
              color: 'text.disabled',
            }}
          >
            <Iconify icon="eva:clock-outline" sx={{ mr: 0.5, width: 16, height: 16 }} />
            {fToNow(notification.data_criacao)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const titulo = (
    <Typography variant="subtitle2">
      {notification.titulo}
      <Typography component="span" variant="body2" sx={{ color: 'text.secondary' }}>
        &nbsp; {noCase(notification.mensagem)}
      </Typography>
    </Typography>
  );

  if (notification.tipo === 'chamados') {
    return {
      avatar: (
        <img
          alt={notification.titulo}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      titulo,
    };
  }
  return {
    avatar: notification.avatar ? <img alt={notification.titulo} src={notification.avatar} /> : null,
    titulo,
  };
}
