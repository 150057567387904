import { createSlice } from '@reduxjs/toolkit';
import omit from 'lodash/omit';

//actions
import actionListaColunas from 'src/actions/chamados/actionListaColunas';
import actionListaTickets from 'src/actions/chamados/actionListaTickets';
import actionVerChamado from 'src/actions/chamados/actionVerChamado';

function objFromArray(array, key = 'id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}

function objFromArrayId(array, key = '_id') {
  return array.reduce((accumulator, current) => {
    accumulator[current[key]] = current;
    return accumulator;
  }, {});
}



const initialState = {
  isLoading: false,
  error: null,
  colunas: [],
  countChamadosPendentes: 0,
  tickets: [],
  chamado: null,
  openChamado:false,
  comentarios_chamado: []
};

const slice = createSlice({
  name: 'chamados',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    // CREATE NEW COLUMN
    createColumnSuccess(state, action) {
      const newColumn = action.payload;
      state.isLoading = false;
      state.board.columns = {
        ...state.board.columns,
        [newColumn.id]: newColumn,
      };
      state.board.columnOrder.push(newColumn.id);
    },

    persistCard(state, action) {
      const columns = action.payload;
      state.board.columns = columns;
    },

    changeOpenDetalhesChamado(state, action) {
      try {
        state.openChamado = action.payload;
        console.log('changeOpenDetalhesChamado', action.payload)
      } catch (error) {
        console.log('error', error)
      }
    },

    closeModalDetalhesChamado(state, action) {
      try {
        state.openChamado = action.payload;
      } catch (error) {
        console.log('error', error)
      }
    },

    persistColumn(state, action) {
      state.board.columnOrder = action.payload;
    },

    addTask(state, action) {
      const { card, columnId } = action.payload;
      state.board.cards[card.id] = card;
      state.board.columns[columnId].cardIds.push(card.id);
    },

    deleteTask(state, action) {
      const { cardId, columnId } = action.payload;
      state.board.columns[columnId].cardIds = state.board.columns[columnId].cardIds.filter((id) => id !== cardId);
      state.board.cards = omit(state.board.cards, [cardId]);
    },

    // UPDATE COLUMN
    updateColumnSuccess(state, action) {
      const column = action.payload;
      state.isLoading = false;
      state.board.columns[column.id] = column;
    },


    // BUSCA DE COLUNAS COM CHAMADOS
    getListaColunasSuccess(state, action) {
      state.colunas = objFromArray(action.payload);
        },

    // BUSCA DE CHAMADOS
    getListaTicketsSuccess(state, action) {
      state.tickets = objFromArrayId(action.payload);

    },

    // BUSCA DE CHAMADOS
    getVerChamadoSuccess(state, action) {
      state.isLoading = false;
      state.chamado = action.payload.data;
      state.comentarios_chamado = action.payload.comentarios;
    
    },

    setCountChamadosPendentes(state, action) {
      state.countChamadosPendentes = action.payload;
    
    },

    // BUSCA DE CHAMADOS
    getRefreshSuccess(state, action) {
      state.isLoading = false;
      state.comentarios_chamado = action.payload.comentarios;
    },

    setChamado(state, action) {
      state.chamado = null;
    },

    // DELETE COLUMN
    deleteColumnSuccess(state, action) {
      const { columnId } = action.payload;
      const deletedColumn = state.board.columns[columnId];

      state.isLoading = false;
      state.board.columns = omit(state.board.columns, [columnId]);
      state.board.cards = omit(state.board.cards, [...deletedColumn.cardIds]);
      state.board.columnOrder = state.board.columnOrder.filter((c) => c !== columnId);
    },
  },
});

// Reducer
export default slice.reducer;

export const { 
  actions, 
  setChamado, 
  changeOpenDetalhesChamado,
  closeModalDetalhesChamado,
  setCountChamadosPendentes
} = slice.actions;

// ----------------------------------------------------------------------

export function getListaTickets(data) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await actionListaTickets(data);
      dispatch(slice.actions.getListaTicketsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getListaColunas(data) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const response = await actionListaColunas(data);
      dispatch(slice.actions.getListaColunasSuccess(response.data.data));
     
      if(response.data.data) {
        const statusPendente = response.data.data.find(status => status.id === "pendente");
        const numeroDeTicketsPendentes = statusPendente.ticketsId.length;
        console.log('numeroDeTicketsPendentes', numeroDeTicketsPendentes)
        dispatch(slice.actions.setCountChamadosPendentes(numeroDeTicketsPendentes));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


// VER CHAMADO ----------------------------------------------------------------------

export function getVerChamado(chamadoId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await actionVerChamado(chamadoId);
      dispatch(slice.actions.getVerChamadoSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getRefreshComments(chamadoId) {
  return async (dispatch) => {
    try {
      const response = await actionVerChamado(chamadoId);
      dispatch(slice.actions.getRefreshSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


