0// material
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  Divider,
  Grid,
  TextField,
  Alert,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  Stack,
  Menu,
  Typography,
  MenuItem,
  Select,
  Drawer,
  Container,
  IconButton
} from '@mui/material';
import { useSnackbar } from 'notistack';
import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import getActionChatInfo from 'src/actions/chats/getActionChatInfo';
import getActionCriarChatAndNovaMensagem from 'src/actions/chats/getActionCriarChatAndNovaMensagem';
import getActionVerificaNumero from 'src/actions/chats/whatsapp/getActionVerificaNumero';
import v1_actionVerificarNumero from 'src/actions/v1/whatsapp/v1_actionVerificarNumero';
import Iconify from 'src/components/Iconify';
import { RowInput } from 'src/components/forms/RowInput';
import HeaderDrawer from 'src/components/modal/HeaderDrawer';
import { getChatsAtendimento, getCountsChats } from 'src/redux/slices/chat';
import { PATH_CHAT } from 'src/routes/paths';
import NovoChatForm from 'src/sections/@dashboard/cadastro/NovoChatForm';
import { soNumeros, validarNumeroWhatsapp } from 'src/utils/functions';

// ----------------------------------------------------------------------

export default function ModalNovoChat({
  open,
  onClose,
  listaClientesCanais,
  cliente,
  user
}) {
  const navigate = useNavigate();
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const isMultisession = cliente.multiSession === 1;
  const [loadingNovoChat, setLoadingNovoChat] = useState(false);
  const [selectedClienteId, setSelectedClienteId] = useState(cliente._id);

  const sessaoEscolhida = listaClientesCanais.find(item => item.cliente_id === selectedClienteId);

  async function onCriarChat(data) {
    const { contato } = data;
    if(!contato.whatsapp || !contato.nome) {
     enqueueSnackbar('Preencha todos os campos', { variant: 'error' });
     return setLoadingNovoChat(false);
    }
    setLoadingNovoChat(true);
    try {
      const querys = `?sessao=${ isMultisession ? sessaoEscolhida.sessao : cliente.sessao}&numero=${validarNumeroWhatsapp(contato.whatsapp)}&canal=whatsapp`;
      const result_verificar_numero = await v1_actionVerificarNumero(querys);

      if (result_verificar_numero.data.data.numberExists === false) {
        setLoadingNovoChat(false);
        enqueueSnackbar('Número não existe no whatsapp', { variant: 'error' });
        return;
      } else {
        const response_chat_info = await getActionChatInfo(isMultisession ? selectedClienteId : cliente._id, validarNumeroWhatsapp(contato.whatsapp));
        if (response_chat_info.data) {
          enqueueSnackbar('Chat já está cadastrado', { variant: 'warning' });
          setLoadingNovoChat(false);
        } else {
          getActionCriarChatAndNovaMensagem({
            nomeCliente: contato.nome,
            numeroCliente: validarNumeroWhatsapp(contato.whatsapp),
            statusRoom: "Atendendo",
            usuarioId: user.id,
            clienteId: isMultisession ? selectedClienteId : cliente._id
          }).then(() => {

            getActionChatInfo(isMultisession ? selectedClienteId : cliente._id, validarNumeroWhatsapp(contato.whatsapp)).then(res => {
              onClose();
              setLoadingNovoChat(false);
              navigate(`${PATH_CHAT.chat.root}`);
              dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, true));
              dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
            })
          }).catch(() => setLoadingNovoChat(false));
        }
      }
    } catch (error) {
      setLoadingNovoChat(false);
      enqueueSnackbar('Erro ao criar chat', { variant: 'error' });
      console.log(error)
    }

  }


  return (
    <div>

      <Drawer
        open={open}
        onClose={onClose}
        anchor="right"
        PaperProps={{ sx: { width: { xs: 1, sm: 400, md: 400 } } }}
      >
        <HeaderDrawer
          title="Novo Chat"
          close={onClose}
        />
        <Container>

          <Stack alignContent={'space-around'} sx={{ mt: 2 }} direction={'column'} spacing={1}>

            <Stack direction="column" spacing={3}>


              {isMultisession && (
                <Stack spacing={2} alignItems={'center'} direction={'row'}>
                  <Typography variant="subtitle2" >
                    Escolha um canal:
                  </Typography>
                  <Select
                    size="small"
                    placeholder='Escolha um funil'
                   defaultValue={selectedClienteId}
                    sx={{ minWidth: 100, height: 30 }}
                    value={selectedClienteId}
                    onChange={event =>  setSelectedClienteId(event.target.value) }
                  >
                    {listaClientesCanais.length > 0 && listaClientesCanais.map((option) => (
                      <MenuItem key={option.cliente_id} value={option.cliente_id}>
                        {option.nome}
                      </MenuItem>

                    ))}
                  </Select>
                </Stack>
              )}



              <NovoChatForm
                cliente={cliente}
                hideSelectEmpresa={true}
                showSignupButton={true}
                loadingNovoContato={loadingNovoChat}
                callbackAddContact={data => onCriarChat(data)}
              />


            </Stack>

          </Stack>
        </Container>
      </Drawer>
    </div>
  );
}
