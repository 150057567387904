import axios from "axios";
import Config from "src/config";

const v2_actionCriarTag = (data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/tags/criar`,
        data
    });
};
export default v2_actionCriarTag;
