import axios from "axios";
import Config from "../../config";

const actionListaTickets = data => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chamados/lista`,
        data
    });
};

export default actionListaTickets;
