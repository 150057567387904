import {
    Box,
    Grid,
    Typography,
    Stack,
    Button,
    Switch,
    IconButton,
    Divider
} from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';

//paginas
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import Iconify from 'src/components/Iconify';
import { formataNumeroCelular } from 'src/utils/functions';
import Label from 'src/components/Label';
import { useDispatch } from 'react-redux';
import DrawerAutomacao from './DrawerAutomacao';

// ----------------------------------------------------------------------



MainAutomacoes.propTypes = {
    canais: PropTypes.array,
};

export default function MainAutomacoes({ listaAutomacoes, user, cliente, onCallback }) {
    const [openDrawerAutomacao, setOpenDrawerAutomacao] = useState(false)
    const [automacao, setAutomacao] = useState(null)

    return (
        <>
            <HeaderPageComponentConfig
                titulo="Automações"
                descricao={'Crie automações para agilizar o atendimento e melhorar a experiência do cliente.'}
                actions={<Button onClick={() => setOpenDrawerAutomacao(true)} variant='contained'>Criar Automação</Button>}
            />
            <Box sx={{ flex: 1, p: 3 }}>


                <DrawerAutomacao
                    user={user}
                    cliente={cliente}
                    open={openDrawerAutomacao}
                    onCallback={() => {
                        setOpenDrawerAutomacao(false)
                        onCallback()
                    }}
                    onClose={() => setOpenDrawerAutomacao(false)}
                />

                <Grid sx={{ mt: 0 }} container spacing={1.5}>
                    {listaAutomacoes?.map((item, index) => (
                        <Grid key={index} item lg={3} md={6} sm={6} xs={12}>
                            <AutomacaoCard
                                botao={() => {
                                    setOpenDialgoWhatsapp(true)
                                    setAutomacao(item)
                                }}
                                se
                                automacao={item}
                            />
                        </Grid>
                    )
                    )}
                </Grid>

            </Box>
        </>
    );
}

// ----------------------------------------------------------------------

AutomacaoCard.propTypes = {
    item: PropTypes.object,
};

function AutomacaoCard({ automacao, botao }) {

    function formatIcons(key) {
        switch (key) {
            case "whatsapp":
                return <Iconify icon={"dashicons:whatsapp"} width={25} height={25} />
            case "instagram":
                return <Iconify icon={"ri:instagram-fill"} width={25} height={25} />
            case "messenger":
                return <Iconify icon={"mingcute:messenger-fill"} width={25} height={25} />
            default:
                break;
        }

    }

    function transformTipoAutomacao(tipo) {
        switch (tipo) {
            case "pipiline_send_birthday_message":
                return "Mensagem aniversário"
            case "pipeline_send_message_inativity":
                return "Mensagem Inatividade - Funil de Vendas";
            case "pipeline_move_stage_inativity":
                return "Alterando estágio - Funil de Vendas";
        }
    }


    return (
        <Box
            sx={{
                backgroundColor: theme => theme.palette.grey[100],
                border: '1px solid #e5e2f7',
                p: 2,
                borderRadius: 1,
                display: 'flex',
                height: 130,
                flexDirection: 'column',
            }}
        >

            <Stack direction="column" spacing={1} >

                <Typography sx={{ fontSize: 13, fontWeight: '600' }}>
                    {automacao.nome}
                </Typography>

                <Typography sx={{ fontSize: 12, fontWeight: '400' }} color="text.primary">
                    {transformTipoAutomacao(automacao.tipo)}
                </Typography>

            </Stack>

            <Divider sx={{ mt: 1, mb: 1, borderStyle:'dashed' }} />

            <Stack alignItems={"center"} spacing={0.5} direction={"row"}>
                <Switch
                    checked={automacao.status}
                    size="small"
                    onChange={(event) => {

                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                />
                <IconButton size="small" color="primary" aria-label="add" onClick={botao}>
                    <Iconify color="grey" icon="material-symbols:edit-outline" width={18} height={18} />
                </IconButton>
                <IconButton size="small" color="primary" aria-label="add" onClick={botao}>
                    <Iconify color="red" icon="material-symbols-light:delete-outline" width={18} height={18} />
                </IconButton>
            </Stack>


        </Box>
    );
}
// ----------------------------------------------------------------------


