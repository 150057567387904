import axios from "axios";

import Config from "../../config";

const getActionLoginUsuario = data => {
    
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/auth/login/usuario`,
        data
    });
};

export default getActionLoginUsuario;



