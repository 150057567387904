import { useState } from "react";
import { Badge } from "src/@/components/ui/badge";
import { Button } from "src/@/components/ui/button";
import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from "src/@/components/ui/select";
import Iconify from "src/components/Iconify";


const SelectVoice = ({
    listTypeVoices,
    voice,
    changeVoice
}) => {
    const [currentAudio, setCurrentAudio] = useState(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const playAudio = (audioUrl) => {
        if (currentAudio) {
            currentAudio.pause(); // Pausa o áudio atual, se houver
        }

        const newAudio = new Audio(audioUrl);

        newAudio.addEventListener('ended', () => {
            setIsPlaying(false);
            setCurrentAudio(null); // Reseta o áudio atual
        })

        setCurrentAudio(newAudio);
        newAudio.play()
            .then(() => {
                setIsPlaying(true); // Define o estado para tocando
            })
            .catch(error => {
                console.error('Playback failed:', error);
            });


    };

    const stopAudio = () => {
        if (currentAudio) {
            currentAudio.pause();
            currentAudio.currentTime = 0; // Reseta o tempo do áudio para o início
            setIsPlaying(false); // Atualiza o estado para não tocando
        }
    };

    return (
        <Select value={voice} onValueChange={changeVoice}>
            <SelectTrigger className="w-[200px]">
                <SelectValue placeholder="Selecione uma voz" />
            </SelectTrigger>
            <SelectContent>
                {listTypeVoices?.map((i) => (
                    <SelectGroup className="mt-2" key={i._id}>
                        <div className='flex flex-row justify-between'>
                            <SelectItem value={i._id}>
                                <div className="flex flex-row gap-2 items-center">
                                {i.name} {i.cliente_id && <Badge className="text-[10px]" variant="secondary">Voz clonada</Badge>}
                                </div>
                                
                                </SelectItem>
                            {isPlaying && currentAudio?.src === i.preview_url ? (
                                <Button
                                    variant="outline"
                                    size="icon"
                                    className="h-8"
                                    onClick={stopAudio}
                                >
                                    <Iconify color="red" width={15} icon="solar:stop-bold" />
                                </Button>
                            ) : (
                                <Button
                                    variant="outline"
                                    size="icon"
                                    className="h-8"
                                    onClick={() => playAudio(i.preview_url)}
                                >
                                    <Iconify width={15} icon="solar:play-bold" />
                                </Button>
                            )}
                        </div>
                    </SelectGroup>
                ))}
            </SelectContent>
        </Select>
    );
};

export default SelectVoice;