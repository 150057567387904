import { createSlice } from '@reduxjs/toolkit';

import getActionSomaChatsByStatus from '../../actions/dashboard/somaChatsByStatus';
import getActionSomaFunilVendas from '../../actions/dashboard/somaFunilVendas';
// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  somaChatsByStatus:[],
  somaFunilVendas:[]
};

const slice = createSlice({
  name: 'dashboard',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    getSomaChatsByStatusSuccess(state, action) {
      state.isLoading = false;
      state.somaChatsByStatus = action.payload;
    },
    getSomaFunilVendasSuccess(state, action) {
      state.isLoading = false;
      state.somaFunilVendas = action.payload.funis;
      console.log('redux', action.payload.funis)
    },
    
  }
});

// Reducer
export default slice.reducer;

// Actions
// export const { startLoading, setMessage, setMessages, setAddRoomUnread, setRemoveRoomUnread } = slice.actions;



// CHATS FINALIZADOS ----------------------------------------------------------------------
export function getSomaChatsByStatus(clienteId, inicio, final, ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionSomaChatsByStatus({clienteId:clienteId, inicio:inicio, final:final});
      dispatch(slice.actions.getSomaChatsByStatusSuccess(response.data[0]));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getSomaFunilVendas(clienteId, inicio, final, ) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionSomaFunilVendas({clienteId:clienteId, inicio:inicio, final:final});
      dispatch(slice.actions.getSomaFunilVendasSuccess(response.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

