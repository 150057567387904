import axios from "axios";
import Config from "src/config";

const v2_actionEditarSetor = (setorId, data) => {
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/${setorId}/setores/editar`,
        data
    });
};

export default v2_actionEditarSetor;
