import axios from "axios";

import Config from "../../../../config";

const v2_actionListaChatsSupervisao = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/dashboard/supervisao/lista-chats${querys}`
    });
};

export default v2_actionListaChatsSupervisao;
