import { List, Drawer } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

// @mui
// hooks
import Scrollbar from '../../../../components/Scrollbar';
import { SkeletonMailSidebarItem } from '../../../../components/skeleton';
import { NAVBAR } from '../../../../config';
import useResponsive from '../../../../hooks/useResponsive';
// config
// components
//
import MarketingSidebarItem from '../../marketing/MarketingSidebar/MarketingSidebarItem';

// ----------------------------------------------------------------------

MarketingSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onOpenCompose: PropTypes.func,
  onCloseSidebar: PropTypes.func,
};

export default function MarketingSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'md');

  const SIDEBAR_ARRAY = [
    {
      id:"1",
      slug:"agendamentos",
      nome:"Agendamentos",
      icon:"eva:email-fill",
    },

    {
      id:"2",
      slug:"campanhas",
      nome:"Campanhas",
      icon:"eva:email-fill",
    },
  ]

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);


  const renderContent = (
    <Scrollbar>

      <List disablePadding>
        {SIDEBAR_ARRAY.map((label, index) =>
          label ? <MarketingSidebarItem key={label.id} label={label} /> : <SkeletonMailSidebarItem key={index} />
        )}
      </List>
    </Scrollbar>
  );

  return (
    <>
      {isDesktop ? (
        <Drawer variant="permanent" PaperProps={{ sx: { width: NAVBAR.BASE_WIDTH, position: 'relative' } }}>
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          ModalProps={{ keepMounted: true }}
          PaperProps={{ sx: { width: NAVBAR.BASE_WIDTH } }}
        >
          {renderContent}
        </Drawer>
      )}
    </>
  );
}
