import axios from "axios";
import Config from "src/config";

const v2_actionFinalizarChat = (chatId, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chat/${chatId}/finalizar`,
        data
    });
};
export default v2_actionFinalizarChat;
