import axios from "axios";
import Config from "src/config";

const v2_actionEnviarAudioBuffer = (querys, formData) => {
    return axios({
        method: "post",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/canais/enviar-voz-buffer${querys}`,
        data:formData
    });
};

export default v2_actionEnviarAudioBuffer;
