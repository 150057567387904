import { Card, CardContent } from "src/@/components/ui/card"

const ContainerOptions = ({ children }) => {
    return (
        <Card className="p-5 bg-white items-center">
            {children}
        </Card>
    )
}

export default ContainerOptions