import axios from "axios";

import Config from "../../../config";

const actionListaClientesFv = (querys) => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/dashboard/relatorios/forca-vendas/lista-clientes${querys}`
    });
};

export default actionListaClientesFv;
