import axios from "axios";
import Config from "src/config";

const v2_actionCriarVoz = (clienteId, formData) => {
    return axios({
        method: "post",
        url:`${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/${clienteId}/ai/vozes/criar`,
        data:formData
    });
};

export default v2_actionCriarVoz;
