import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// @mui
import { Paper, Typography, Box, Stack, Backdrop, LinearProgress, CircularProgress, Divider, Autocomplete, TextField, Chip } from '@mui/material';
// components
import Label from 'src/components/Label'
import { fToNow } from 'src/utils/formatTime';
import { changeOpenDetalhesChamado, getListaColunas, getListaTickets, getVerChamado } from 'src/redux/slices/chamados';
import { useDispatch, useSelector } from 'react-redux';
import actionEditarChamado from 'src/actions/chamados/actionEditarChamado';
import useAuth from 'src/hooks/useAuth';
import actionEditarTagsChamado from 'src/actions/chamados/actionEditarTagsChamado';
import { Draggable } from 'react-beautiful-dnd';
import Countdown from "react-countdown";
import Iconify from 'src/components/Iconify';
// ----------------------------------------------------------------------

ChamadosCard.propTypes = {
  card: PropTypes.object,
  index: PropTypes.number,
  onDeleteTask: PropTypes.func,
};

export default function ChamadosCard({ listaTags, card, onDeleteTask }) {

  const dispatch = useDispatch();
  const { user } = useAuth()

  const [dataCriacao, setDataCriacao] = useState('')
  const [criadoPor, setCriadoPor] = useState('')
  const [prioridade, setPrioridade] = useState('')
  const [titulo, setTitulo] = useState('')
  const [nomeAutor, setNomeAutor] = useState('')
  const [backoffice, setBackoffice] = useState(null)

  const { chamado, comentarios_chamado, isLoading, openChamado } = useSelector((state) => state.chamados);

  const [openDetails, setOpenDetails] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [isMe, setIsMe] = useState(false);
  const [copiadoPara, setCopiadoPara] = useState([]);
  const [numeroChamado, setNumeroChamado] = useState();
  const [filialCriador, setFilialCriador] = useState('');
  const [ultimaModificacao, setUltimaModificacao] = useState('');
  const [tagsChamado, setTagsChamado] = useState([]);



  const [loading, setIsLoading] = useState(false);

  function validarDataParaConclusao(documento) {
    const dataAtual = new Date();
    const dataParaConclusao = new Date(documento.data_para_conclusao);
    if (dataAtual > dataParaConclusao) {
      return true;
    } else {
      return false;
    }
  }



  useEffect(() => {
    if (card) {
      setTitulo(card.titulo);
      setPrioridade(card.prioridade);
      setCriadoPor(card.criado_por);
      setDataCriacao(card.data_criacao)
      setNomeAutor(card.nome_autor)
      setBackoffice(card.backoffice)
      setCopiadoPara(card.copiado_para)
      setNumeroChamado(card.chamado);
      setUltimaModificacao(card.ultima_modificacao)
      setFilialCriador(card.filialCriador);

      if (Object.keys(card.tagsObject[0]).length === 0) {
        setTagsChamado([]);
      } else {
        setTagsChamado(card.tagsObject);
      }

      if (user.id === card.criado_por) {
        setIsMe(true);
      } else {
        setIsMe(false);
      }
    }
  }, [card, copiadoPara])

  const validaCopia = copiadoPara.includes(user.id);


  async function onEditarTagsChamados(tags) {

    let transformedArr = tags.map(obj => {
      return {
        usuario_id: user.id,
        tag_id: obj.tag_id
      };
    });
    setTagsChamado(transformedArr)
    try {
      let data = {
        tags: transformedArr,
        particpantExclude: user.id
      }
      setIsLoading(true);
      await actionEditarTagsChamado(card._id, data);
      dispatch(getListaTickets({ setorId: user.setorId, usuarioId: user.id, filial: "" }));
      setTimeout(() => {
        setIsLoading(false)
      }, 900);

    } catch (error) {
      setIsLoading(false)
      alert('Erro ao alterar tag no chamado')
    }
  }

  async function handleOpenDetails() {

    dispatch(getVerChamado(card._id));

    if (card.status === 'pendente') {
      if (user.id !== card.criado_por && !isMe) {

        let data = {
          action: 'aceitar_chamado',
          status: 'aberto',
          usuarioId: user.id
        }
        await actionEditarChamado(card._id, data);
        dispatch(getVerChamado(card._id));
        dispatch(changeOpenDetalhesChamado(true))
      } else {
        //   action: 'aceitar_chamado',
        //   status: 'aberto',
        //   usuarioId: user.id
        // }
        // await actionEditarChamado(card._id, data);
        dispatch(getVerChamado(card._id));
        dispatch(changeOpenDetalhesChamado(true))
      }
    } else {
      dispatch(getVerChamado(card._id));
      dispatch(changeOpenDetalhesChamado(true))
    }
  };

  const handleCloseDetails = () => {
    dispatch(getListaColunas({ setorId: user.user.setor_id, usuarioId: user.id, filial: user.user.filial }));
    dispatch(getListaTickets({ setorId: user.user.setor_id, usuarioId: user.id, filial: user.user.filial }));
    dispatch(changeOpenDetalhesChamado(false))
  };

  const handleChangeComplete = (event) => {
    setCompleted(event.target.checked);
  };

  const renderer = ({ days, hours, minutes, seconds, completed }) => {

    function verificaDias() {
      if (days > 0) {
        return `${days} ${days > 1 ? 'dias' : 'dia'}`;
      } else {
        return `${hours}:${minutes}:${seconds}`;
      }
    }

    if (completed) {
      // Render a completed state
      return <Label variant="filled" color="error">Atrasado</Label>;
    } else {
      // Render a countdown
      return <Typography variant='caption'>{verificaDias()}</Typography>;
    }
  };

  return (
    <>
      <Backdrop
        sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress />
      </Backdrop>

      {
        card && (
          <>

            <Paper
              sx={{
                px: 2,
                width: 1,
                ...((card.status === "pendente" || card.status === "aberto") && validarDataParaConclusao(card) && { borderColor: '#f03' }),
                bgcolor:(card.status === "pendente" || card.status === "aberto") && validarDataParaConclusao(card) ? '#ffeaee' : 'grey.300',
                position: 'relative',
                boxShadow: (theme) => theme.customShadows.z1,
                '&:hover': {
                  boxShadow: (theme) => theme.customShadows.z16,
                }
              }}
            >

              <Box
                onClick={handleOpenDetails}
              >

                <Box sx={{ cursor: 'pointer', py: 2 }}>
                  <Stack direction="row" alignItems="center" spacing={2}>
                    <Typography
                      noWrap
                      variant="caption"
                      sx={{
                        fontWeight: 800,
                        letterSpacing: 1,
                        transition: (theme) =>
                          theme.transitions.create('opacity', {
                            duration: theme.transitions.duration.shortest,
                          }),
                        ...(completed && { opacity: 0.48 }),
                      }}
                    >
                      #{numeroChamado}
                    </Typography>
                    {prioridade === 'normal' && (
                      <Label variant="filled" color="primary">{prioridade}</Label>
                    )}
                    {prioridade === 'imediato' && (
                      <Label variant="filled" color="error">{prioridade}</Label>
                    )}
                    {validaCopia === true && (
                      <Label variant="outlined" color="default">Em Copia</Label>
                    )}
                    {isMe && (
                      <Label variant="filled" color="info">Meu Chamado</Label>
                    )}
                  </Stack>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Typography
                    noWrap
                    variant="subtitle2"
                    sx={{
                      fontWeight: 'bold',
                      transition: (theme) =>
                        theme.transitions.create('opacity', {
                          duration: theme.transitions.duration.shortest,
                        }),
                      ...(completed && { opacity: 0.48 }),
                    }}
                  >
                    {titulo}
                  </Typography>
                  <Divider sx={{ mt: 1, mb: 1 }} />
                  <Stack sx={{ mt: 2 }} spacing={4} direction="row" alignItems="center">
                    <Stack>
                      {/* <Typography variant="body2" sx={{  fontSize:12 }}>
                      Criado por  <span style={{fontWeight:'bold'}} >{nomeAutor}</span> {fToNow(dataCriacao)}
                    </Typography> */}
                      <Typography variant="body2" sx={{ fontSize: 12 }}>
                        Criado por  <span style={{ fontWeight: 'bold' }} >{nomeAutor} </span>
                      </Typography>
                      {dataCriacao && (
                        <Typography variant="body2" sx={{ fontSize: 10 }}>
                          Ultima atividade {fToNow(ultimaModificacao)}
                        </Typography>
                      )}
                    </Stack>

                    {/* {validarDataParaConclusao(card) && (
                    <Label variant="filled" color="error">Atrasado</Label>
                  )} */}
                  </Stack>

                  {card.status === "pendente" || card.status === "aberto" && (
                    <Stack alignItems={'center'} spacing={1} direction={'row'} sx={{ mt: 2 }}>
                      <Iconify icon="ph:clock-countdown-bold" color="#000" />
                      <Countdown
                        renderer={renderer}
                        date={card.data_para_conclusao}
                      />
                    </Stack>
                  )}



                </Box>


              </Box>


            </Paper>

          </>
        )
      }
    </>
  );

}
