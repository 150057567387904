import { Grid, IconButton, Avatar, Typography, Stack, Box, CircularProgress } from '@mui/material';
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { HiFolderDownload } from 'react-icons/hi';
import { AudioPlayer, PlayButton, Player, Timeline, User } from "./style";
import { styled } from '@mui/material/styles';
import AudioPlayerMessage from '../../AudioPlayerMessage';
import Iconify from 'src/components/Iconify';
import { Button } from 'src/@/components/ui/button';
import { Dropdown } from 'src/components/ui-component/dropdown/Dropdown';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from 'src/@/components/ui/dropdown-menu';
import { Card, CardContent } from 'src/@/components/ui/card';



const AudioComponent = ({ url, isMe, profileImage, downloadAudio, loading }) => {
    const audioPlayer = useRef(null);
    const sliderRef = useRef(null);
    const [audio, setAudio] = useState(null);
    const [playing, setPlaying] = useState(false);
    const [downloaded, setDownloaded] = useState(false);
    const [playbackRate, setPlaybackRate] = useState(1); // Estado para playbackRate

    const optionsVolume = [
        { value: '1', label: '1x' },
        { value: '1.5', label: '1.5x' },
        { value: '2', label: '2x' },
    ]

    useEffect(() => {
        if (audio) {
            // Pausa o áudio atual antes de criar um novo
            audio.pause();
            setAudio(new Audio(audioPlayer.current.dataset.url));
        } else {
            setAudio(new Audio(audioPlayer.current.dataset.url));
        }
    
        return () => {
            // Pausa o áudio quando o componente é desmontado
            if (audio) {
                audio.pause();
            }
        };
    }, [url]);
    
    useEffect(() => {
        if (audio) {
            audio.playbackRate = playbackRate;
        }
    }, [playbackRate]);
    
    const onClickPlay = () => {
        if (audio) {
            audio.playbackRate = playbackRate;
            if (audio.paused) {
                audio.play();
            } else {
                audio.pause();
            }
        }
    };


    const handlePlaybackRateChange = (value) => {
        // Verifica se o áudio está tocando
        const wasPlaying = !audio.paused;
        // Pausa o áudio temporariamente
        audio.pause();
        if (sliderRef) {
            sliderRef.current.value = null;
        }

        // Define o comportamento quando o áudio é pausado
        audio.onpause = () => {
            audioPlayer.current.classList.remove("playing");
            setPlaying(false);
        };

        // Converte o valor para um número de ponto flutuante
        const newPlaybackRate = parseFloat(value);

        // Define a nova taxa de reprodução
        setPlaybackRate(newPlaybackRate);

        // Atualiza a taxa de reprodução do áudio
        audio.playbackRate = newPlaybackRate;

        // Define o estado de reprodução como falso
        setPlaying(false);

        // Retoma a reprodução se o áudio estava tocando antes
        if (wasPlaying) {
            audio.play();
        }
    };

    async function checkIsDownloaded() {
        if (audioPlayer.current.dataset.url === undefined) {
            downloadAudio("audio").then(() => {
                setDownloaded(true)
            });
        } else {
            start();
            audio.play();

        }
    }

    async function start() {
        onClickPlay();
        console.log("start");
        audio.onloadstart = () => {
            setMessageDate();
            audioPlayer.current.classList.add("loading");
        };

        audio.onplay = () => {
            audioPlayer.current.classList.add("playing");
            setPlaying(true);
        }
        audio.onpause = () => {
            audioPlayer.current.classList.remove("playing");
            setPlaying(false)
        }
        audio.onloadeddata = () => audioPlayer.current.classList.remove("loading");
        audio.ondurationchange = showTimeDuration;
        audio.onended = () => {
            (audio.currentTime = 0);
            setPlaying(false);
        }
        audio.ontimeupdate = () => {
            const { currentTime } = audio;
            const currentTimeDisplay = formatTimeToDisplay(currentTime);
            updateCurrentTimeDisplay(currentTimeDisplay);
            updateCurrentPercent();
            if (currentTime === 0) {
                showTimeDuration();
            }
        };
    }

    function formatTimeToDisplay(seconds) {
        const milliseconds = seconds * 1000;
        return new Date(milliseconds).toISOString().substr(14, 5);
    }

    function setMessageDate() {
        let currentDateTime = new Date().toISOString().substr(11, 5);
        audioPlayer.current.style.setProperty(
            "--player-current-date-time",
            `'${currentDateTime}'`
        );
    }

    function handleSlider(e) {
        const { duration } = audio;
        const percent = e.target.value;
        const currentTimeInSeconds = ((percent * duration) / 100).toFixed(2);
        audio.currentTime = currentTimeInSeconds;
    }

    function showTimeDuration() {
        const { duration } = audio;
        const durationDisplay = formatTimeToDisplay(duration);
        updateCurrentTimeDisplay(durationDisplay);
    }

    function updateCurrentTimeDisplay(time) {
        audioPlayer.current.style.setProperty("--player-current-time", `'${time}'`);
    }

    function updateCurrentPercent() {
        const { currentTime, duration } = audio;
        const percentPlayed = (currentTime * 100) / duration;
        sliderRef.current.value = percentPlayed;
        audioPlayer.current.style.setProperty(
            "--player-percent-played",
            `${percentPlayed}%`
        );
    }


    return (
        <Card className="flexjustify-center items-center" >

            <div
                className='flex flex-row gap-2 p-2 items-center'
                data-url={url}
                ref={audioPlayer}
                isMe={isMe}
            >
                <Avatar
                    sx={{ bgcolor: 'primary.secondary', color: '#fff', width: 30, height: 30 }}
                    src={profileImage}
                    alt={"Foto perfil"}

                />


                <Box>
                    <Stack spacing={1} alignItems={"center"} direction={"row"}>
                        <Box>
                            {downloaded ? (
                                <Stack direction="row">
                                    {playing ?
                                        <IconButton >
                                            <Iconify onClick={onClickPlay} width={18} icon="icon-park-outline:pause-one" />
                                        </IconButton>
                                        :
                                        <IconButton onClick={checkIsDownloaded}>
                                            <Iconify width={18} icon="icon-park-solid:play" />
                                        </IconButton>
                                    }

                                </Stack>
                            ) : (
                                loading ? (
                                    <div className='mt-1'>
                                        <Iconify width={24} height={24} icon="eos-icons:three-dots-loading" />
                                    </div>
                                ) : (
                                    <IconButton onClick={checkIsDownloaded}><Iconify width={18} icon="fluent:cloud-download-20-filled" /></IconButton>
                                )

                            )}
                        </Box>
                        <Box sx={{ pt: loading ? 1.5 : 0 }}>
                            <Stack direction={"column"}>
                                <input dir="ltr" type="range" min="0" max="100" value="0" ref={sliderRef} onInput={handleSlider} />
                                <Stack direction={"row"}>
                                    <div className="current-time" />
                                    <div className="time" />
                                </Stack>

                                <Box>

                                </Box>
                            </Stack>
                        </Box>

                        <DropdownMenu>
                            <DropdownMenuTrigger asChild>
                                <Button className="w-6 h-6 text-[10px] flex- items-center" size="icon" variant="outline">{playbackRate}x</Button>
                            </DropdownMenuTrigger>
                            <DropdownMenuContent className="w-3">
                                {optionsVolume.map((option) => (
                                    <DropdownMenuItem
                                        key={option.value}
                                        onClick={() => handlePlaybackRateChange(option.value)}
                                        value={option.value}
                                        className="w-10px"
                                    >
                                        {option.label}
                                    </DropdownMenuItem>
                                ))}
                            </DropdownMenuContent>
                        </DropdownMenu>

                    </Stack>
                </Box>

            </div>

        </Card>


    );
};

export default AudioComponent;

AudioComponent.propTypes = {
    url: PropTypes.string,
    isMe: PropTypes.bool.isRequired,
    profileImage: PropTypes.string.isRequired,
    downloadAudio: PropTypes.func.isRequired,
};// JavaScript Document
