import { createSlice } from '@reduxjs/toolkit';
import getActionListaFunilByClienteId from 'src/actions/clientes/getActionListaFunilByClienteId';
import v1_actionGlobalFrontConfig from 'src/actions/global/v1_actionGlobalFrontConfig';
import v1_actionBuscarConfig from 'src/actions/v1/global/v1_actionBuscarConfig';
import v2_actionListaTagsChats from 'src/actions/v2/chats/v2_actionListaTagsChats';
// utils


const initialState = {
  isLoading: false,
  isStaff: null,
  soundNotification: false,
  defcon:null,
  optionsFunil:[],
  optionsTags:[],
  qrcode:null,
  loadingQrcodeWhatsapp:false,
  statusQrcodeRead:null,
  empresa: {
    openModal:false,
    loading:false
  },
  conversa: {
    openModal:false,
    loading:false
  },
  contato: {
    openModal:false,
    loading:false
  },
  negocio: {
    openModal:false,
    loading:false
  },
  modalAlerta: {
    open:false,
    mensagem:null,
    titulo:null
  },
  config: null,
  globalConfig: null,

};

const slice = createSlice({
  name: 'global',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },


    setIsOnlineUsers(state, action) {
      state.isOnlineUsers = action.payload;
    },

    setSoundNotification(state, action) {
      state.soundNotification = action.payload;
    },

    
    setDefcon1(state, action) {
      state.defcon = action.payload;
    },

    setQrcode(state, action) {
      state.qrcode = action.payload;
    },
    setLoadingQrcodeWhatsapp(state, action) {
      state.loadingQrcodeWhatsapp = action.payload;
    },
    setStatusQrcode(state, action) {
      state.statusQrcodeRead = action.payload;
    },

    getListaOptionsFunilSuccess(state, action) {
      state.optionsFunil = action.payload;
    },
    getListaOptionsTagsSuccess(state, action) {
      state.optionsTags = action.payload;
    },
    getConfigSuccess(state, action) {
      state.config = action.payload;
    },
    getConfigGlobalSuccess(state, action) {
      state.globalConfig = action.payload;
    },
    setEmpresa(state, action) {
      state.empresa = action.payload
    },
    setContato(state, action) {
      state.contato = action.payload
    },
    setConversa(state, action) {
      state.conversa = action.payload
    },
    setNegocio(state, action) {
      state.negocio = action.payload
    },

    setModalAlerta(state, action) {
      state.modalAlerta = action.payload
    },

    resetModalAlerta(state) {
      state.modalAlerta =  {
        open:false,
        mensagem:null,
        titulo:null
      }
    },

  }
});

// Reducer
export default slice.reducer;

// Actions
export const {resetModalAlerta, setModalAlerta, startLoading, setSoundNotification, setDefcon1, setQrcode, setLoadingQrcodeWhatsapp, setStatusQrcode, setEmpresa, setNegocio, setContato, setConversa } = slice.actions;




export function getListaOptionsFunil(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await getActionListaFunilByClienteId(clienteId);
      dispatch(slice.actions.getListaOptionsFunilSuccess(response.data.funis));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getListaOptionsTags(clienteId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      let querys = `?cliente_id=${clienteId}`
      const response = await v2_actionListaTagsChats(querys);
      dispatch(slice.actions.getListaOptionsTagsSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}



export function getConfig() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v1_actionBuscarConfig();
      dispatch(slice.actions.getConfigSuccess(response.data.data));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}


export function getGlobalConfig(url) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await v1_actionGlobalFrontConfig(url);
      dispatch(slice.actions.getConfigGlobalSuccess(response.data));
    } catch (error) {
      console.error(error)
      dispatch(slice.actions.hasError(error));
    }
  };
}




