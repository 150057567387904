import { Stack, Container } from "@mui/material";
//zui 

import { Label } from 'src/@/components/ui/label';
import { RadioGroup, RadioGroupItem } from 'src/@/components/ui/radio-group';
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from "src/@/components/ui/tooltip";


export default function AiSelectPromptType({
    aiAgent,
    changePromptType
}) {



    const optionsTypePrompt = [
        { value: 'stepByStep', label: 'Pré-configurado', desc: 'Use nosso passo a passo para criar seu agente de forma fácil e pré-configurada', recommended: true },
        { value: 'promptFree', label: 'Customizado', desc: 'Indicado para construir um agente com prompt livre', recommended: false }
    ];




    return (


            <Container maxWidth="md">
                <RadioGroup
                    value={aiAgent?.config?.generalConfig?.type}
                    onValueChange={(value) => changePromptType(value)}
                    className=" grid grid-cols-2 gap-2 w-full"
                >
                    {optionsTypePrompt.map((option) => (
                        <TooltipProvider>
                            <Tooltip>
                                <TooltipTrigger asChild>
                                    <div className='w-full' key={option.value}>
                                        <RadioGroupItem
                                            value={option.value}
                                            id={option.value}
                                            className="peer sr-only "
                                        />
                                        <Label
                                            htmlFor={option.value}
                                            className={`flex flex-col items-center h-32 justify-center  rounded-md border-2 border-muted bg-popover p-2 hover:bg-violet-400 hover:text-white ${aiAgent?.config?.generalConfig?.type === option.value ? 'border-violet-300 text-pretty' : ''}`}
                                        >
                                            <h4 className="scroll-m-20 text-lg font-semibold tracking-tight">
                                            {option.label}
                                            </h4>
                                            <p className="leading-4 text-xs text-center [&:not(:first-child)]:mt-6">{option.desc}</p>

                                         
                                        </Label>
                                    </div>
                                </TooltipTrigger>
                                <TooltipContent className="bg-slate-900 text-white">
                                    <p>{option.desc}</p>
                                </TooltipContent>
                            </Tooltip>
                        </TooltipProvider>

                    ))}
                </RadioGroup>
            </Container>
      
    )

}