import jwtDecode from "jwt-decode";
import IO from "socket.io-client";
import { getChatsAtendimento, getChatsFinalizados, getChatsGeral, getChatsPendente, getCountsChats, setPushMensagemMeta } from "src/redux/slices/chat";

import alertaSound from "src/assets/alerta.mp3";
import messageSound from "src/assets/message.mp3";
import chamadoSound from "src/assets/som-chamado.mp3";

// import Actions from "../constants/Actions";
import { setMetaMessages, setMessageWhatsapp, getMessagesMeta } from './redux/slices/chat'
import { setDefcon1, setLoadingQrcodeWhatsapp, setQrcode, setStatusQrcode } from 'src/redux/slices/global'
import { getIoInit, setAddRoomUnread, setOnlineUsers, setMessage, getListaStaffsWithoutIsGroup, setIsOnlineUsers } from 'src/redux/slices/staff';
import { store } from "./redux/store";
// import getRooms from "./getRooms";
import { notifyMe, notifyMeComentario, notifyMeStaff, notifyNewMessage, obterNumeroPorClienteId, soNumeros } from 'src/utils/functions';
import { getListaNotificacoes } from "src/redux/slices/notificacoes";
import { getRefreshComments, getListaColunas, getListaTickets } from "src/redux/slices/chamados";
import getActionClienteByClienteId from "src/actions/clientes/getActionClienteByClienteId";
import actionListaClientesCanais from "src/actions/clientes/actionListaClientesCanais";
// eslint-disable-next-line camelcase, no-undef
const socketPromise = require('./lib/socket.io-promise').promise;
import Worker from "worker-loader!./actions/idb/workers/app.worker.worker";

const initIO = token => (dispatch) => {
  const user = jwtDecode(token);

  const clienteData = localStorage.getItem('cliente_data');
  const clienteParse = JSON.parse(clienteData);
  const wbWorker = new Worker();

  let io = IO(`https://ws.zindi.com.br` + '/');
  io.request = socketPromise(io);

  ////// sistema antigo

  function playSoundNotification() {
    const soundNotification = localStorage.getItem('soundNotification');
    if (soundNotification === "true") {
      console.log('acionado')
      const audio = document.createElement('audio');
      audio.style.display = "none";
      audio.src = alertaSound;
      audio.autoplay = soundNotification;
      audio.onended = () => audio.remove();
      document.body.appendChild(audio);
    }

  }

  io.on('connect', () => {
    io.emit('authenticate', { token });
    console.log('conectado')
  });

  io.on('authenticated', () => {
    dispatch(getIoInit(io));

  });

  // verificar se há chats pendentes, havendo, toca-se um sinal sonoro
  // setInterval(async () => {
  //   getActionListaChatsByStatus({ clienteId: user.clienteId, status: "Pendente", usuarioId: user.id, setorId: user.setorId }).then(res => {
  //     if (res.data.length > 0) {
  //       playSoundNotification();
  //       notifyMe2(res.data.length);
  //     }

  //   }).catch(err => console.log(err));

  // }, 180000);


  setInterval(async () => {
    const roomsWithNewMessages = store.getState().staff.roomsWithNewMessages;
    if (roomsWithNewMessages) {
      if (roomsWithNewMessages.length > 0) {
        notifyMeStaff('Atenção', 'Você tem mensagens não vistas no Chat Interno');
      }
    }

  }, 90000);

  io.on('novo_comentario', (data) => {
    console.log('novo_comentario socket', data)
    if (!data) return null;
    const { nomeAutor, setorAutor, backoffice, filial, texto } = data.comentario

    let combineUsuarios = data.result;
    if (combineUsuarios.includes(user.id)) {

      dispatch(getListaNotificacoes(user.id))
      console.log('valida 01', data.comentario.autor_id, user.id)
      if (user.id !== data.comentario.autor_id) {
        const audio = document.createElement('audio');
        audio.style.display = "none";
        audio.src = chamadoSound;
        audio.autoplay = true;
        audio.onended = () => audio.remove();
        document.body.appendChild(audio);
        notifyMeComentario(`${nomeAutor}`, `\nDiz:  ${texto} \n\n Chamado: #${data.chamado.chamado}`);;
      }
      dispatch(getRefreshComments(data.chamado._id))
    }
  });

  io.on('job-count-chamados-pendentes', (data) => {

    if (!data) return null;


    if (data.direcionado_setor.includes(user.setorId) || data.direcionado_para.includes(user.id) || data.direcionado_usuarios.includes(user.id)) {
      const audio = document.createElement('audio');
      audio.style.display = "none";
      audio.src = chamadoSound;
      audio.autoplay = true;
      audio.onended = () => audio.remove();
      document.body.appendChild(audio);

      notifyMeStaff('Atenção', 'Você tem chamados pendentes');
      dispatch(getListaColunas({ setorId: user.setorId, usuarioId: user.id }));
      dispatch(getListaTickets({ setorId: user.setorId, usuarioId: user.id }));
      dispatch(getListaNotificacoes(user.id))
    }

  });

  io.on('atualizar_chamados', (data) => {
    if (!data) return null;
    console.log('atualzar', data)

    if (data.cliente_id) {
      if (data.cliente_id === user.clienteId) {
        dispatch(getListaColunas({ setorId: user.setorId, usuarioId: user.id }));
        dispatch(getListaTickets({ setorId: user.setorId, usuarioId: user.id }));
        dispatch(getListaNotificacoes(user.id))
        return;
      }
    }

    if (data.usuario_id) {
      if (data.usuario_id === user.id) {
        dispatch(getListaColunas({ setorId: user.setorId, usuarioId: user.id }));
        dispatch(getListaTickets({ setorId: user.setorId, usuarioId: user.id }));
        dispatch(getListaNotificacoes(user.id))
        return;
      }
    }


  });

  io.on('qrcodeEvent', data => {
    console.log('qrcodeEvent', data)
    const { qrcode, event } = data.data.scope;

    if (event === "qrcode") {
      dispatch(setQrcode(`data:image/png;base64,${qrcode}`))
      dispatch(setLoadingQrcodeWhatsapp(false))
      dispatch(setStatusQrcode('notLogged'))
    }

  });

  io.on('statusFindEvent', data => {
    const { event, status } = data.data.scope

    dispatch(setStatusQrcode(status))
    if (status === 'inChat') {
      window.location.href = "/chats/chat"
    }



  });

  io.on('message-in', data => {
    const { room, message } = data;
    const currentRoom = store.getState().staff.room;

    const audio = document.createElement('audio');
    audio.style.display = "none";
    audio.src = messageSound;
    audio.autoplay = true;
    audio.onended = () => audio.remove();
    document.body.appendChild(audio);

    if (!currentRoom || currentRoom._id !== room._id) {
      dispatch(setAddRoomUnread(room._id));
    }

    if (!currentRoom) {
      notifyMeStaff('Atenção', 'Você tem uma nova mensagem no Chat Interno');

    }
    if (currentRoom) {
      if (currentRoom._id === room._id) dispatch(setMessage(message));
      if (currentRoom._id !== room._id) {

        notifyMeStaff('Atenção', 'Você tem uma nova mensagem no Chat Interno');
      }
    }
    dispatch(getListaStaffsWithoutIsGroup());
    // dispatch(getListaStaffs(false))
    // dispatch(getListaStaffsGroup(true))

  });

  io.on('job-count-chats-pendentes', data => {
    let clientesId = data.data.clientes_id;
    let setoresId = data.data.direcionado_setor;
    let usuariosId = data.data.direcionado_usuarios;
    const isMultisession = clienteParse.multiSession === 1;



    if (isMultisession) {
      let commonIds = clientesId.filter(item => clienteParse.cliente_ids.includes(item));
      let isFindCommonIds = commonIds.length > 0;

      if (isFindCommonIds) {
        if (setoresId.includes(user.setorId)) {
          dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));
          if (data.sound) {
            notifyMe2();
            playSoundNotification();
          }

        } else if (usuariosId.includes(user.id)) {
          dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));
          if (data.sound) {
            notifyMe2();
            playSoundNotification();
          }
        }
      }

    } else {
      if (clientesId.includes(user.clienteId)) {
        if (setoresId.includes(user.setorId)) {
          dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));
          if (data.sound) {
            notifyMe2();
            playSoundNotification();
          }

        } else if (usuariosId.includes(user.id)) {
          dispatch(getChatsPendente(user.clienteId, user.id, user.setorId));
          if (data.sound) {
            notifyMe2();
            console.log('acionado som usuario')
            playSoundNotification();
          }
        }
      }
    }



  });


  if (clienteParse.config.ready_v2_webhook) {

    io.on('socket-device-refresh-list-chats', data => {
      if (data.data.whoTo === "clienteId" && data.data.to === user.clienteId) {

        let lista = data.data.scope.lista_atualizar;
        for (const item of lista) {
          setTimeout(() => {
            if (item === "Atendendo") dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
            if (item === "Geral") dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, false));
            if (item === "Pendente") dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 10, false));
            if (item === "Finalizado") dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 10, false));
          }, 4000);
          //dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
        }
      }

      if (data.data.whoTo === "usuarioId" && data.data.to === user.id) {
        let lista = data.data.scope.lista_atualizar;
        for (const item of lista) {
          setTimeout(() => {
            if (item === "Atendendo") dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
            if (item === "Geral") dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, false));
            if (item === "Pendente") dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 10, false));
            if (item === "Finalizado") dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 10, false));
          }, 4000);
          //  dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
        }
      }
    });


    io.on('newChatToUser', data => {
      console.log('newChatToUser', data)
      if (data.data.whoTo === "usuarioId" && data.data.scope.userIds.includes(user.id)) {

        createNotify('NOVO CHAT', 'Você tem um novo chat no seu painel')
        playSoundNotification();
      }

    });



    // recenbendo mensagens websocket front vindo do webhook
    io.on('newMessage', async data => {
      if (!data) return;


      const chatInfo = store.getState().chat.chatInfo;
      const listaChatsAtendendo = store.getState().chat.chatsAtendimento;
      const dataChatByLista = listaChatsAtendendo.find(item => item.numeroCliente === soNumeros(data.data.scope.message.chatId));
      
      if(chatInfo) {
        let listaStatus = ['Pendente', 'Geral', 'Finalizado','Avaliando'];
        if(listaStatus.includes(chatInfo.statusRoom)) {
          if(chatInfo.numeroCliente === soNumeros(data.data.scope.message.chatId)) {
            dispatch(setMessageWhatsapp(data.data.scope.message))
          }
        }

      }

      if (data.data.scope.usuario_id === user.id) {
        console.log('aqui 02')
        const isMultisession = clienteParse.multiSession === 1;
        if (isMultisession) {
          console.log('aqui 03');

          let listaNumerosClientesSubs = clienteParse.numeros_subs;
          if (listaNumerosClientesSubs.includes(soNumeros(data.data.scope.message.to))) {
            console.log('aqui 12')
            if (chatInfo?.numeroCliente + '@c.us' !== data.data.scope.message.chatId) {

              if (data.data.scope.message.self === "in") {
                const audio = document.createElement('audio');
                audio.style.display = "none";
                audio.src = messageSound;
                audio.autoplay = true;
                audio.onended = () => audio.remove();
                document.body.appendChild(audio);
                console.log('notify new message')
                notifyNewMessage(data.data.scope.message, dataChatByLista)
                setTimeout(() => {
                  dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
                  dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
                }, 4500);
              }

            }

          }
          console.log('aqui 13')

          if (chatInfo && chatInfo?.numeroCliente) {
            console.log('step 05', data.data.scope.message.fromMe)

            if (data.data.scope.message.fromMe) {
              let cliente_id_chat = chatInfo?.cliente_id;
              let numero_chat = obterNumeroPorClienteId(cliente_id_chat, clienteParse.numeros_subs_data);

              if (numero_chat === soNumeros(data.data.scope.message.from)) {

                // if (Config.numero + '@c.us' === message.response.from) {
                if (chatInfo?.numeroCliente + '@c.us' === data.data.scope.message.chatId) {
                  if (data.data.scope.message.chatId !== "status@broadcast") {
                    dispatch(setMessageWhatsapp(data.data.scope.message))
                    dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
                    dispatch(getCountsChats(user.clienteId, user.id, user.setorId));

                  }
                }
              }

            } else {

              const cliente_id_chat = chatInfo?.cliente_id;
              const numero_chat = obterNumeroPorClienteId(cliente_id_chat, clienteParse.numeros_subs_data);
              console.log('step 06', numero_chat, soNumeros(data.data.scope.message.to))
              if (numero_chat === soNumeros(data.data.scope.message.to)) {
                if (chatInfo?.numeroCliente + '@c.us' === data.data.scope.message.chatId) {
                  if (data.data.scope.message.chatId !== "status@broadcast") {
                    dispatch(setMessageWhatsapp(data.data.scope.message))
                  }
                }
              }
            }
          }

          // função para verificar se o numero base que chegou esta incluido no array de numeros do cliente

        } else {
          console.log('aqui 04')

          if (clienteParse.numero + '@c.us' === data.data.scope.message.to) {

            if (!chatInfo) {
              const audio = document.createElement('audio');
              audio.style.display = "none";
              audio.src = messageSound;
              audio.autoplay = true;
              audio.onended = () => audio.remove();
              document.body.appendChild(audio);
              notifyNewMessage(data.data.scope.message, dataChatByLista)
            }

            if (chatInfo && data.data.scope.message.chatId !== "status@broadcast" && chatInfo?.numeroCliente + '@c.us' !== data.data.scope.message.chatId) {

              if (chatInfo?.statusRoom !== "Pendente" || chatInfo?.statusRoom !== "Geral") {
                if (data.data.scope.message.self === "in") {
                  const audio = document.createElement('audio');
                  audio.style.display = "none";
                  audio.src = messageSound;
                  audio.autoplay = true;
                  audio.onended = () => audio.remove();
                  document.body.appendChild(audio);
                  notifyNewMessage(data.data.scope.message, dataChatByLista)
                  setTimeout(() => {
                    dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
                    dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
                  }, 4500);

                }
              }

            }
            //fim do buscar atendimento
          }

          if (chatInfo && chatInfo?.numeroCliente) {
            console.log('step d 01')
            if (data.data.scope.message.fromMe === true) {
              if (clienteParse.numero + '@c.us' === data.data.scope.message.from) { // CONFERIR AQUI
                if (chatInfo?.numeroCliente + '@c.us' === data.data.scope.message.chatId) { // CONFERIR AQUI
                  if (data.data.scope.message.chatId !== "status@broadcast") {
                    console.log("bateu aqui quando mandei a menssagem");
                    wbWorker.postMessage({ message: data.data.scope.message, type: "store-fromMe-message" });
                    dispatch(setMessageWhatsapp(data.data.scope.message));
                    dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
                    dispatch(getCountsChats(user.clienteId, user.id, user.setorId));

                  }
                }
              }

            } else {
              console.log('step f 01', data.data.scope.message.to, clienteParse.numero, data.data.scope.message.chatId)
              if (clienteParse.numero + '@c.us' === data.data.scope.message.to) {  // conferir aqui
                wbWorker.postMessage({
                  status: data.data.scope.lista_atualizar,
                  message: data.data.scope.message,
                  type: "store-new-message",
                });

                if (chatInfo?.numeroCliente + '@c.us' === data.data.scope.message.chatId) {
                  console.log('step f 03');
                  dispatch(setMessageWhatsapp(data.data.scope.message));
                }
              }
            }
          }


        }

        ///// corggir aqui em baixo 


        let lista = data.data.scope.lista_atualizar;
        for (const item of lista) {
          if (item === "Atendendo") dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
          if (item === "Geral") dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, false));
          if (item === "Pendente") dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 10, false));
          if (item === "Finalizado") dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 10, false));
          //  dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
        }
      }



    });


    io.on('msgInstagramDeleted', async data => {
      const chatInfo = store.getState().chat.chatInfo;
      const instagramUserId = data.data.scope.instagramUserId;


      if (chatInfo?.instagramUser?.id === instagramUserId) {
        dispatch(getMessagesMeta(chatInfo?.sessao, instagramUserId));

      }

    });


    // recenbendo mensagens websocket front vindo do webhook
    io.on('newMessageInstagram', async data => {
      console.log('data', data)
      if (!data) return;

      const isFile = data.data.scope.message.message.isFile;

      const chatInfo = store.getState().chat.chatInfo;
      const listaChatsAtendendo = store.getState().chat.chatsAtendimento;
      const dataChatByLista = listaChatsAtendendo.find(item => item?.instagramUser.id === soNumeros(data.data.scope.message.chatId));
      console.log(data.data.scope.usuario_id, user.id)
      if (data.data.scope.usuario_id === user.id) {
        const isMultisession = clienteParse.multiSession === 1;
        if (isMultisession) {
          let listaNumerosClientesSubs = clienteParse.numeros_subs;
          if (listaNumerosClientesSubs.includes(soNumeros(data.data.scope.message.to))) {

            if (chatInfo && chatInfo?.instagramUser.id !== data.data.scope.instagramUserId) {

              if (data.data.scope.message.isMe) {
                const audio = document.createElement('audio');
                audio.style.display = "none";
                audio.src = messageSound;
                audio.autoplay = true;
                audio.onended = () => audio.remove();
                document.body.appendChild(audio);
                console.log('notify new message')
                notifyNewMessage(data.data.scope.message, dataChatByLista)
                setTimeout(() => {
                  dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
                  dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
                }, 4500);
              }

            }

          }

          if (chatInfo && chatInfo?.instagramUser?.id) {

            // if (data.data.scope.message.message.isMe) {
            //   let cliente_id_chat = chatInfo.cliente_id;
            //   let numero_chat = obterNumeroPorClienteId(cliente_id_chat, clienteParse.numeros_subs_data);

            //   if (numero_chat === soNumeros(data.data.scope.message.from)) {

            //     // if (Config.numero + '@c.us' === message.response.from) {
            //     if (chatInfo.numeroCliente + '@c.us' === data.data.scope.message.chatId) {

            //         dispatch(setMessageWhatsapp(data.data.scope.message))
            //         dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
            //         dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
            //         // getActionAtualizarData({ chatId: chatInfo._id, lastMessage: data.data.scope.message.body }).then(() => {
            //         //   dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
            //         //   dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
            //         // })

            //     }
            //   }

            // } else {

            //   const cliente_id_chat = chatInfo.cliente_id;
            //   const numero_chat = obterNumeroPorClienteId(cliente_id_chat, clienteParse.numeros_subs_data);
            //   if (numero_chat === soNumeros(data.data.scope.message.to)) {
            //     if (chatInfo.numeroCliente + '@c.us' === data.data.scope.message.chatId) {
            //       if (data.data.scope.message.chatId !== "status@broadcast") {
            //         dispatch(setMessageWhatsapp(data.data.scope.message))
            //       }
            //     }
            //   }
            // }
          }

          // função para verificar se o numero base que chegou esta incluido no array de numeros do cliente

        } else {
          console.log('step c 01')
          if (!chatInfo) {
            const audio = document.createElement('audio');
            audio.style.display = "none";
            audio.src = messageSound;
            audio.autoplay = true;
            audio.onended = () => audio.remove();
            document.body.appendChild(audio);
            notifyNewMessage("nova mensagem", dataChatByLista)
          }
          console.log('step c 02', data.data.scope.message.instagramUserId, chatInfo?.instagramUser.id)
          if (chatInfo && chatInfo.instagramUser?.id === data.data.scope.message.instagramUserId) {
            console.log('step c 02')
            if (chatInfo.statusRoom === "Atendendo") {
              console.log('step c 03')
              if (!data.data.scope.message.message.isMe) {
                console.log('step c 04')
                const audio = document.createElement('audio');
                audio.style.display = "none";
                audio.src = messageSound;
                audio.autoplay = true;
                audio.onended = () => audio.remove();
                document.body.appendChild(audio);
                notifyNewMessage(data.data.scope.message, dataChatByLista)
                setTimeout(() => {
                  dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
                  dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
                }, 4500);

              }
            }
          }
          //fim do buscar atendimento


          if (chatInfo && chatInfo.instagramUser?.id) {
            console.log('step d 01', data.data.scope.message.message.isMe)
            if (data.data.scope.message.message.isMe) {
              console.log('step d 02')
              if (chatInfo.instagramUser?.id === data.data.scope.message.instagramUserId) {// CONFERIR AQUI
                console.log("bateu aqui quando mandei a menssagem");
                dispatch(setPushMensagemMeta(data.data.scope.message));
                dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
                dispatch(getCountsChats(user.clienteId, user.id, user.setorId));


              }

            } else {

              if (chatInfo.instagramUser?.id === data.data.scope.message.instagramUserId) {
                console.log('step f 03');
                dispatch(setPushMensagemMeta(data.data.scope.message));
              }

            }
          }


        }

        let lista = data.data.scope.lista_atualizar;
        for (const item of lista) {
          if (item === "Atendendo") dispatch(getChatsAtendimento(user.clienteId, user.id, user.setorId, 10, false));
          if (item === "Geral") dispatch(getChatsGeral(user.clienteId, user.id, user.setorId, 100, false));
          if (item === "Pendente") dispatch(getChatsPendente(user.clienteId, user.id, user.setorId, 10, false));
          if (item === "Finalizado") dispatch(getChatsFinalizados(user.clienteId, user.id, user.setorId, 10, false));
          //  dispatch(getCountsChats(user.clienteId, user.id, user.setorId));
        }
      }

    });


  }


  io.on(`${user.clienteId}-meta-message`, data => {
    const currentChat = store.getState().chat.chatInfo;
    if (!currentChat) return null;

    if (data.cliente_id === user.clienteId) {
      if (data.id_usuario === currentChat.instagram_user_id) {
        dispatch(setMetaMessages(data.message))
      }

    }
  });


  io.on(`atualizar-dados-cliente`, async () => {
    const responseCliente = await getActionClienteByClienteId(user.clienteId);
    const response_clientes_canais = await actionListaClientesCanais(user.clienteId);

    let listaNumerosSubs = response_clientes_canais.data.numeros_subs;
    const numeros_subs_reduce = listaNumerosSubs.map((item) => item.nome)

    const newClienteObj = {
      ...responseCliente.data.cliente,
      clientes_canais: responseCliente.data.cliente.multiSession === 1 ? response_clientes_canais.data.data : [],
      numeros_subs: responseCliente.data.cliente.multiSession === 1 ? numeros_subs_reduce : [],
      numeros_subs_data: responseCliente.data.cliente.multiSession === 1 ? listaNumerosSubs : []
    }

    localStorage.setItem('cliente_data', JSON.stringify(newClienteObj));

    window.location.href = "/";
  });

  io.on(`atualizar-front`, () => {
    window.location.href = "/";
  });

  io.on(`force-logout`, (data) => {

    function clear() {
      // Limpar o cache de armazenamento local
      localStorage.clear();

      // Limpar o cache de sessão
      sessionStorage.clear();

      // Limpar todos os cookies
      const cookies = document.cookie.split(";");

      for (let i = 0; i < cookies.length; i++) {
        const cookie = cookies[i];
        const eqPos = cookie.indexOf("=");
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/";
      }

      // Recarregar a página para aplicar as alterações
      window.location.reload();
    }

    console.log('force-logout', data.data.whoto, data.data.to, user.clienteId, user.id)

    if (data.data.whoTo === "clienteId" && data.data.to === user.clienteId) {
      clear()
    } else if (data.data.whoTo === "usuarioId" && data.data.to === user.id) {
      clear()
    }

  });


  const verificaIsDefcon1 = () => {
    io.on(`_defcon1`, data => {
      if (data) {
        dispatch(setDefcon1(Boolean(data.status)))
      }
      console.log('_defcon1', data.status)
    });

  }
  verificaIsDefcon1()


  io.on(`_defcon`, data => {
    console.log('defcon', data)
    if (data.tipo === "defcon1") {
      dispatch(setDefcon1(Boolean(data.status)))
    }

  });

  // const verificaIsUO = () => {

  //   const isOnlineUsers = store.getState().staff.isOnlineUsers;
  //   if (isOnlineUsers) {
  //     io.on(`${user.clienteId}_onlineUsers`, data => {

  //       if (isOnlineUsers === true) {
  //         const newArray = data.filter((el) => {
  //           return el.clienteId === user.clienteId;
  //         });

  //         dispatch(setIsOnlineUsers(false));
  //         if (newArray.length > 0) {
  //           dispatch(setOnlineUsers(newArray));
  //         }
  //       } 
  //     });
  //   }
  // }

  // if (user.isOnlineUsersStaff === true) {
  //   verificaIsUO();
  // }



  io.on('disconnected', () => {
    console.log('DESCONECTADO')
  });


  io.on('private message', () => {
    console.log('DESCONECTADO')
  });


  const notifyMe2 = () => {

    // Verifica se o browser suporta notificações
    if (!("Notification" in window)) {
      alert("Este browser não suporta notificações de Desktop");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      new Notification("CHATS PENDENTES", {
        body: `Você tem chat(s) no seu painel`
      });
    }

    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          new Notification("CHATS PENDENTES", {
            body: `Você tem chat(s) no seu painel`
          });
        }
      });
    }
  }


  const createNotify = (title, message) => {

    // Verifica se o browser suporta notificações
    if (!("Notification" in window)) {
      alert("Este browser não suporta notificações de Desktop");
    }

    // Let's check whether notification permissions have already been granted
    else if (Notification.permission === "granted") {
      new Notification(title, {
        body: message
      });
    }

    else if (Notification.permission !== 'denied') {
      Notification.requestPermission(function (permission) {
        if (permission === "granted") {
          new Notification(title, {
            body: message
          });
        }
      });
    }
  }




};

export default initIO;
