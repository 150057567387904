import { Button } from "src/@/components/ui/button";
import { Card, CardHeader, CardTitle, CardDescription, CardContent, CardFooter } from "src/@/components/ui/card";
import { Input } from "src/@/components/ui/input";
import { Textarea } from "src/@/components/ui/textarea";
import { RowInput } from "src/components/forms/RowInput";

import {
    Table,
    TableBody,
    TableCaption,
    TableCell,
    TableFooter,
    TableHead,
    TableHeader,
    TableRow,
} from "src/@/components/ui/table"
import Iconify from "src/components/Iconify";
import v2_actionCriarTreinamento from "src/actions/v2/ai/v2_actionCriarTreinamento";

import { useState } from "react";
import v2_actionDeletarTreinamento from "src/actions/v2/ai/v2_actionDeletarTreinamento";
import ModalConfimacao from "src/components/modal/ModalConfimacao";
import LoaderConfigPreviewChunks from "../../components/training/views/LoaderConfigPreviewChunks";
import { fDateTime } from "src/utils/formatTime";


export default function FormWebsites({ agentId, trainings, onCallback, docLoaderNodeName }) {

    const [answer, setAnswer] = useState('');
    const [responseAwser, setResponseAwser] = useState([]);
    const [idTraining, setIdTraining] = useState('');
    const [loading, setLoading] = useState(false);
    const [openModalConfirmacao, setOpenModalConfirmacao] = useState(false);

    async function addAnswer() {

        try {
            let data = {
                scope: {
                    content: {
                        "type": "answers",
                        "answer_content": {
                            "answer": answer,
                            "response": responseAwser
                        }
                    }
                }
            }
            await v2_actionCriarTreinamento(agentId, data);
            onCallback();
            setAnswer('');
            setResponseAwser('');
        } catch (error) {
            console.log('error', error);
        }
    }

    async function deleteAnswer() {

        try {
            setLoading(true);
            await v2_actionDeletarTreinamento(idTraining);
            onCallback();
            setOpenModalConfirmacao(false);
            setIdTraining('');
            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.log('error', error);
        }
    }


    return (
        <div className='flex flex-col gap-5 mt-5'>

            <ModalConfimacao
                open={openModalConfirmacao}
                onClose={() => {
                    setOpenModalConfirmacao(false);
                }}
                onConfirm={deleteAnswer}
                loadingFinalizar={false}
                mensagem={'Deseja mesmo excluir esse treinamento?'}
            />

            <Card className="w-full bg-slate-50">

                <CardContent>
                    <div className='flex flex-col gap-2  mt-3'>
                        <LoaderConfigPreviewChunks
                        agentId={agentId}
                        docLoaderNodeName={docLoaderNodeName}
                        type="websites"
                        onCallback={onCallback}
                        />

                    </div>
                </CardContent>
            </Card>

            {trainings?.responseWebsites?.length > 0 && (
                <Card className="w-full bg-slate-50">

                    <CardContent>
                        <Table>
                            <TableHeader>
                                <TableRow>
                                <TableHead>Tipo</TableHead>
                                    <TableHead>Url</TableHead>
                                    {/* <TableHead>Data Criação</TableHead> */}
                                    <TableHead className="w-[100px]"></TableHead>
                                </TableRow>
                            </TableHeader>
                            <TableBody>
                                {trainings?.responseWebsites?.map((item) => (
                                    <TableRow key={item?._id}>
                                        <TableCell>{item?.content?.type}</TableCell>
                                        <TableCell >{item?.content?.document_content?.url}</TableCell>
                                        {/* <TableCell >{fDateTime(item?.createAt) }</TableCell> */}
                                        <TableCell>
                                            <div className="flex flex-row gap-2">
                        
                                                <Button onClick={() => {
                                                    setIdTraining(item._id);
                                                    setOpenModalConfirmacao(true);
                                                }} variant="outline" size="icon">
                                                    <Iconify color="red" icon="material-symbols:delete-outline" />
                                                </Button>
                                            </div>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>

                        </Table>
                    </CardContent>
                </Card>

            )}


        </div>
    );
}
