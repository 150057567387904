import {
  Table,
  TableBody,
  TableCaption,
  TableCell,
  TableFooter,
  TableHead,
  TableHeader,
  TableRow,
} from "src/@/components/ui/table"

import {
  IconButton,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  Stack
} from '@mui/material';
import { useRef, useState } from "react";
import Iconify from "src/components/Iconify";
import { fDate } from "src/utils/formatTime";

import { Button } from "src/@/components/ui/button";


const MoreMenu = ({ onDelete, onEdit }) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon={"lucide:more-vertical"} width={20} height={20} />
      </IconButton>
      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 200, maxWidth: '100%' }
        }}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <MenuItem
          onClick={onEdit}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon={"material-symbols:edit"} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Editar" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
        <MenuItem
          onClick={onDelete}
          sx={{ color: 'text.secondary' }}
        >
          <ListItemIcon>
            <Iconify icon={"mdi:trash-outline"} width={24} height={24} />
          </ListItemIcon>
          <ListItemText primary="Excluir" primaryTypographyProps={{ variant: 'body2' }} />
        </MenuItem>
      </Menu>
    </>
  );
}




export default function TableTags({ data, onEdit, onDelete }) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead >Nome</TableHead>
          <TableHead>Data Criação</TableHead>
          <TableHead className="w-[100px]"></TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={index}>
            <TableCell className="font-medium">{row.nome}</TableCell>
            <TableCell>{fDate(row.date)}</TableCell>
            <TableCell>
              <Stack direction="row" spacing={1}>
                <Button onClick={() => onEdit(row)} variant="outline" size="icon" >
                  <Iconify icon={"ph:pencil-line"} />
                </Button>
                <Button onClick={() => onDelete(row)} variant="outline" size="icon" >
                  <Iconify color="red" icon={"material-symbols-light:delete-outline"} />
                </Button>
              </Stack>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  )
}
