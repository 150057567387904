import axios from "axios";
import Config from "../../../config";

const v2_actionEditarPipeline = (id, data, clienteId) => {
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/clientes/${clienteId}/crm/${id}/editar-pipeline`,
        data
    });
};

export default v2_actionEditarPipeline;
