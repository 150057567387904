import { LoadingButton } from '@mui/lab';
import { Stack, TextField, Grid, Box, Switch, FormControlLabel, Divider } from '@mui/material';
import { RowInput } from 'src/components/forms/RowInput';
import InputStyleMultline from 'src/components/InputStyleMultline';


export default function Preferencias({
    checkedSomNotificacao,
    changeSomNotificacao,
    mensagemIntervalo,
    changeMensagemIntervalo,
    isModificado,
    changeIsModificado,
    onSubmit
}) {


    return (
        <Stack sx={{ maxWidth: '40%' }} spacing={2} direction="column">
            <Stack spacing={3}>
                <FormControlLabel control={<Switch
                    checked={checkedSomNotificacao}
                    onChange={changeSomNotificacao
                    }
                    inputProps={{ 'aria-label': 'controlled' }}
                />} label="Som notificacao" />

            </Stack>
            <Divider sx={{ borderStyle: 'dashed' }} />

            <Stack spacing={3}>
                <RowInput
                    label={"Mensagem de intervalo"}
                    input={<InputStyleMultline
                        onChange={e => {
                            changeMensagemIntervalo(e);
                            changeIsModificado(true);

                        }}
                        value={mensagemIntervalo}
                        multiline rows={3} />}
                />
            </Stack>
            <Stack direction={"row"} justifyContent={'flex-start'}>
                {isModificado && (
                    <LoadingButton variant='contained' onClick={onSubmit}>
                        Salvar
                    </LoadingButton>
                )}

            </Stack>
        </Stack>
    );
}
