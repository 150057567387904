import axios from "axios";
import Config from "../../../config";

const v2_actionAddNotasLead = (leadId, data) => {
    
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/crm/leads/${leadId}/add-notas`,
        data
    });
};

export default v2_actionAddNotasLead;
