import axios from "axios";

import Config from "../../config";

const v1_actionGlobalFrontConfig = provider => {    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/global/main`,
        headers: { 
            'x-provider': provider
          }
    });
};

export default v1_actionGlobalFrontConfig;
