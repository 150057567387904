export function mergeTags(listaTags, tags) {
    if (!tags) return;
    // Cria um objeto para mapear os _id de tags com checked no array tags
    let checkedMap = new Map(tags.map(tag => [tag._id, true]));

    // Cria um novo array baseado em listaTags
    let newArray = listaTags.map(tag => {
        return {
            ...tag,
            checked: checkedMap.has(tag._id) ? true : false
        };
    });

    return newArray;
}