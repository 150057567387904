
import PropTypes from 'prop-types';
import { useState, useRef, useCallback, useEffect } from 'react';
// @mui
import { styled } from '@mui/material/styles';
import {
    Stack,
    Drawer,
    Button,
    Tooltip,
    Divider,
    TextField,
    Typography,
    Card,
    Container,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    Autocomplete,
    Switch,
    DialogTitle,
    DialogContentText,
    DialogContent,
    DialogActions,
    Dialog,
    OutlinedInput,
    Grid,
    Box,
    List,
    ListItemText,
    ListItemButton
} from '@mui/material';
// components
import Iconify from 'src/components/Iconify';
import Scrollbar from 'src/components/Scrollbar';
import { IconButtonAnimate } from 'src/components/animate';
//
import Editor from '../ChamadosNovoChamado/editor'
//icons
import { UploadChamados } from 'src/components/upload';
import getActionListaUsuariosByClienteId from 'src/actions/usuarios/getActionListaUsuariosByClienteId';
import getActionListaSetoresByClienteId from 'src/actions/clientes/getActionListaSetoresByClienteId';
import { LoadingButton } from '@mui/lab';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

// ----------------------------------------------------------------------

const PRIORITIZES = ['imediato', 'normal',];

const LabelStyle = styled(Typography)(({ theme }) => ({
    ...theme.typography.body2,
    width: 140,
    fontSize: 13,
    flexShrink: 0,
    color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

NovoChamado.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    card: PropTypes.object,
    changeDescricao: PropTypes.func,
    onDeleteTask: PropTypes.func,
    selectAssunto: PropTypes.func,
    changeTipoLocal: PropTypes.func
};


export default function NovoChamado({
    user,
    config,
    listaInputsPrioridade,
    isOpen,
    onClose,
    onCriarChamado,
    selectAssunto,
    tipoLocal,
    setorOuUsuario,
    usuarioEscolhido,
    setorEscolhido,
    filialEscolhido,
    estimativa,
    assunto,
    files,
    descricao,
    preview,
    isFile,
    emCopia,
    changeEmCopia,
    changeDescricao,
    changeAssunto,
    changeIsFile,
    changeEstimativa,
    changeTipoLocal,
    changeSetorOuUsuario,
    changeUsuarioEscolhido,
    changeFilialEscolhido,
    changeSetorEscolhido,
    readOnlyDescricao,
    handleDropMultiFile,
    handleRemove,
    modalAssunto,
    closeModalAssunto,
    openModalAssunto,
    loading,
    dataConclusao,
    changeDataConclusao

}) {
    const [tableData, setTableData] = useState([])
    const [loadingEnviarArquivos, setLoadingEnviarArquivos] = useState(false);
    //modal assuntos
    const [pesquisar, setPesquisar] = useState('');
    const [open, setOpen] = useState(false);
    const isSimple = true
    const [listaUsuarios, setListaUsuarios] = useState([])
    const [listaSetores, setListaSetores] = useState([]);
    const [listaFiliais, setListaFiliais] = useState([]);

    // const buscarAssuntos = async () => {
    //     try {
    //         const res = await actionListaUsuariosBackoffice();
    //         setListaUsuariosBackoffice(res.data.data)
    //     } catch (error) {

    //     }
    // }

    const buscarUsuarios = async () => {
        try {
            const res = await getActionListaUsuariosByClienteId(user.clienteId);
            setListaUsuarios(res.data.usuarios)
        } catch (error) {
            console.log(error)
        }
    }


    // const buscarFiliais = async () => {
    //     try {
    //         const res = await actionListaFiliais();
    //         setListaFiliais(res.data.data)
    //     } catch (error) {

    //     }
    // }


    const buscarSetores = async () => {
        try {
            const res = await getActionListaSetoresByClienteId(user.clienteId);
            setListaSetores(res.data.setores)
        } catch (error) {

        }
    }

    function handleChangeTipoLocal(e) {
        buscarUsuarios();
        buscarSetores();
        changeTipoLocal(e);
    }


    useEffect(() => {
        // buscarAssuntos();
        buscarUsuarios()
        buscarSetores();
        // buscarFiliais();
    }, []);


    const dataFiltered = applySortFilter({
        tableData,
        pesquisar
    });

    const defaultProps = {
        options: listaSetores,
        getOptionLabel: (option) => option.nome_setor,
    };

    const defaultPropsUsuarios = {
        options: listaUsuarios,
        getOptionLabel: (option) => `${option.nomeFantasia} ${option.setor ? "(" + option.nome_setor + ")" : ""}`,
    };

    const defaultPropsFilial = {
        options: listaFiliais,
        getOptionLabel: (option) => option.nome_fantasia + " (" + option.codigo_filial + ")",
    };

    return (
        <>
            <Dialog
                open={modalAssunto}
                onClose={closeModalAssunto}
                maxWidth={'lg'}
                fullWidth
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >

                <DialogTitle id="alert-dialog-title">
                    {"Escolha um assunto pré-selecionado"}
                </DialogTitle>
                <DialogContent sx={{ mt: 3, }}>

                    <OutlinedInput

                        size="small"
                        placeholder="Pesquisar Assunto"
                        onChange={e => setPesquisar(e.target.value)}
                        value={pesquisar}
                        sx={{
                            typography: 'h6',
                            '& .MuiOutlinedInput-notchedOutline': { borderColor: 'gray' },
                        }}
                    />
                    <Scrollbar sx={{ mt: 3, height: { xs: 340, sm: 'auto' } }}>
                        <List disablePadding >
                            {dataFiltered.map((list) => (
                                <AssuntosCard selectAssunto={() => selectAssunto(list)} list={list} />
                            ))}
                        </List>
                    </Scrollbar>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeModalAssunto}>Fechar</Button>
                </DialogActions>
            </Dialog>

            <Drawer open={isOpen} onClose={onClose} anchor="right" PaperProps={{ sx: { background: '#f0f2fb', width: { xs: 1, sm: '100%' } } }}>
                <Stack spacing={2} direction="row" alignItems="center" sx={{ ml: 4, mt: 2 }}>

                    <Stack direction="row" spacing={1} justifyContent="flex-start" alignItems={'center'} flexGrow={1}>
                        <Tooltip title="Fechar">
                            <IconButtonAnimate onClick={onClose} size="small">
                                <Iconify icon={'ion:arrow-undo'} width={30} height={30} />
                            </IconButtonAnimate>
                        </Tooltip>
                        <Stack><Typography>Voltar</Typography></Stack>
                    </Stack>
                </Stack>


                {config ? (
                    <>
                        <Container maxWidth >
                            <Card sx={{ p: 3, m: 2 }}>
                                {/* <Stack direction='column' alignItems={'flex-start'}>
                            <Button onClick={openModalAssunto} variant="contained">Preencher com assunto pré-configurado</Button>
                            <Typography sx={{ fontSize: 12, mt: 2 }}>ou preencha manualmente abaixo</Typography>
                        </Stack>
                        <Divider sx={{ mt: 3, mb: 3 }} /> */}

                                <Grid container spacing={6}>
                                    <Grid item xs={12} sm={6}>
                                        <Stack direction={'column'} spacing={4}>

                                            <Typography variant='h6'>Assunto do chamado</Typography>


                                            <Stack>
                                                <TextField size="small" value={assunto} onChange={changeAssunto} placeholder='Assunto' />
                                            </Stack>
                                            {/* <Stack>

                                                <Typography variant='h6'>{config.chamados.novo_chamado.titulo_abrir_chamado_para}</Typography>
                                                <FormControl>
                                                    <RadioGroup
                                                        row
                                                        aria-labelledby="demo-controlled-radio-buttons-group"
                                                        name="controlled-radio-buttons-group"
                                                        value={tipoLocal}
                                                        onChange={handleChangeTipoLocal}
                                                    >
                                                        {config.chamados.novo_chamado.inputs_direcionamento.map((item) => {
                                                            return (
                                                                <FormControlLabel value={item.value} control={<Radio />} label={`${item.label}`} />
                                                            )
                                                        })}


                                                    </RadioGroup>
                                                </FormControl>
                                            </Stack> */}


                                            {tipoLocal === 'chamado_interno' && (
                                                <Stack sx={{ mt: 3 }}>
                                                    <Typography variant='h6'>Direcionar para:</Typography>
                                                    <FormControl>
                                                        <RadioGroup
                                                            row
                                                            aria-labelledby="demo-controlled-radio-buttons-group"
                                                            name="controlled-radio-buttons-group"
                                                            value={setorOuUsuario}
                                                            onChange={changeSetorOuUsuario}
                                                        >
                                                            <FormControlLabel value="setor" control={<Radio />} label="Setor (Recomendado)" />
                                                            <FormControlLabel value="usuario" control={<Radio />} label="Usuário em específico" />
                                                        </RadioGroup>
                                                    </FormControl>
                                                </Stack>
                                            )}

                                            {tipoLocal === 'chamado_interno' && setorOuUsuario === 'setor' && (
                                                <Stack>
                                                    <Typography>Escolha o setor:</Typography>
                                                    <Autocomplete
                                                        {...defaultProps}
                                                        id="controlled-demo"
                                                        value={setorEscolhido ? setorEscolhido : null}
                                                        onChange={(event, newValue) => {
                                                            changeSetorEscolhido(newValue);
                                                            console.log('setor escolhido', newValue)
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField {...params} variant="standard" />
                                                        )}
                                                    />
                                                </Stack>
                                            )}

                                            {tipoLocal === 'chamado_interno' && setorOuUsuario === 'usuario' && (
                                                <Stack direction={'column'} alignItems='flex-start'>
                                                    <Typography>Escolha o Usuario:</Typography>

                                                    <Autocomplete
                                                        {...defaultPropsUsuarios}
                                                        id="size-small-outlined-multi"
                                                        value={usuarioEscolhido ? usuarioEscolhido : null}
                                                        defaultValue=""
                                                        fullWidth
                                                        onChange={(event, newValue) => {
                                                            changeUsuarioEscolhido(newValue);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField sx={{ minWidth: 250 }} {...params} variant="standard" />
                                                        )}
                                                    />
                                                </Stack>
                                            )}

                                            {tipoLocal === 'chamado_externo' && (
                                                <Stack direction={'column'} alignItems='flex-start'>
                                                    <Typography variant='h6'>Escolha a franquia:</Typography>

                                                    <Autocomplete
                                                        {...defaultPropsFilial}
                                                        id="size-small-outlined-multi"
                                                        value={filialEscolhido ? filialEscolhido : null}
                                                        defaultValue=""

                                                        fullWidth
                                                        onChange={(event, newValue) => {
                                                            changeFilialEscolhido(newValue);
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField sx={{ minWidth: 250 }} {...params} placeholder='Escolha a filial' variant="standard" />
                                                        )}
                                                    />
                                                </Stack>
                                            )}




                                            {config.chamados.novo_chamado.status_em_copia && (
                                                <>
                                                    <Stack spacing={1} direction={'column'} sx={{ mt: 3, mb: 3 }}>

                                                        <Typography variant='body'>{config.chamados.novo_chamado.titulo_em_copia} <span style={{fontSize:10}}>(não obrigatorio)</span></Typography>

                                                        <Autocomplete
                                                            sx={{ mt: 2 }}
                                                            multiple
                                                            id="size-small-outlined-multi"
                                                            options={listaUsuarios}
                                                            value={emCopia}
                                                            size="small"
                                                            onChange={(event, newValue) => {
                                                                changeEmCopia(newValue);
                                                            }}
                                                            getOptionLabel={(option) => option.nomeFantasia + " (" + option.nome_setor + ")"}
                                                            renderInput={(params) => (
                                                                <TextField {...params} label="" placeholder="Selecionar usuarios" />
                                                            )}
                                                        />
                                                    </Stack>
                                                </>
                                            )}


                                            <Stack spacing={2} sx={{ mt: 5, mb: 5 }}>
                                                {/* <Typography variant='h6'>{config.chamados.novo_chamado.titulo_prioridade}</Typography> */}
                                                <Typography variant='h6'>Prazo para resolução do chamado</Typography>
                                                <DateTimePicker
                                                    label="Data para Conclusão"
                                                    views={['year', 'month', 'day', 'hours', 'minutes']}
                                                    value={dataConclusao}
                                                    minDate={new Date()}
                                                    minDateTime={new Date()}
                                                    onChange={(newValue) => {
                                                        changeDataConclusao(newValue);
                                                    }}
                                                    renderInput={(params) => <TextField   {...params} size="small" margin="normal" />}
                                                />

                                                {/* <FormControl>
                                                            <RadioGroup
                                                                row
                                                                aria-labelledby="demo-controlled-radio-buttons-group"
                                                                name="controlled-radio-buttons-group"
                                                                value={estimativa}
                                                                onChange={changeEstimativa}
                                                            >
                                                                {listaInputsPrioridade.map((item) => {
                                                                    return (
                                                                        <FormControlLabel key={item._id} value={item._id} control={<Radio />} label={`${item.label} (${item.descricao})`} />
                                                                    )
                                                                })}

                                                            </RadioGroup>
                                                        </FormControl> */}
                                            </Stack>


                                        </Stack>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Stack spacing={2}>
                                            <Typography variant='h6'>{config.chamados.novo_chamado.titulo_descricao}</Typography>
                                            <Editor
                                                value={descricao}
                                                onChange={changeDescricao}
                                                readOnly={readOnlyDescricao}
                                            />
                                        </Stack>

                                        {config.chamados.novo_chamado.status_anexar_arquivos && (
                                            <Stack>
                                                <FormControlLabel control={<Switch
                                                    checked={isFile}
                                                    onChange={changeIsFile}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />} label="Anexar Arquivos" />

                                                {isFile && (
                                                    <UploadChamados
                                                        loadingEnviarArquivos={loadingEnviarArquivos}
                                                        showPreview={preview}
                                                        files={files}
                                                        onDrop={handleDropMultiFile}
                                                        onRemove={handleRemove}
                                                    />
                                                )}
                                            </Stack>
                                        )}


                                    </Grid>
                                </Grid>
                                <Divider sx={{ mt: 5, mb: 5 }} />
                                <Stack direction="row" spacing={2} sx={{ mt: 5 }}>
                                    <Button >Cancelar</Button>
                                    <LoadingButton disabled={loading ? true : false} loading={loading} onClick={onCriarChamado} variant="contained">Criar Chamado</LoadingButton>
                                </Stack>



                            </Card>
                        </Container>
                    </>
                ) : (
                    <Stack>
                        <Typography>Recarregur a pagina</Typography>
                    </Stack>
                )}





            </Drawer >
        </>
    );
}



AssuntosCard.propTypes = {
    follower: PropTypes.object,
    selectAssunto: PropTypes.func
};

function AssuntosCard({ list, selectAssunto }) {
    const { assunto, descricao_info } = list;


    return (
        <ListItemButton
            onClick={selectAssunto}
        >
            <ListItemText
                sx={{
                    py: 1.5,
                    px: 2.5,
                    fontSize: 30,
                    fontWeight: 'bold'
                }}
                primary={assunto}

            />
        </ListItemButton>
    );
}



function applySortFilter({
    tableData,
    pesquisar
}) {


    if (pesquisar) {
        tableData = tableData.filter(
            (item) => item.assunto.toLowerCase().indexOf(pesquisar.toLowerCase()) !== -1);
    }


    return tableData;
}



