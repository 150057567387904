import { styled } from '@mui/material/styles';
import { Box } from '@mui/material';
import { useState } from 'react';
import { Outlet } from 'react-router-dom';
import { HEADER, NAVBAR } from 'src/config';
import useCollapseDrawer from 'src/hooks/useCollapseDrawer';
import NavbarVertical from '../chats/DashboardSidebar';
//

import DashboardNavbar from '../chats/DashboardNavbar';
import { useSelector } from 'react-redux';



const MainStyle = styled('main', {
  shouldForwardProp: (prop) => prop !== 'collapseClick',
})(({ collapseClick, theme }) => ({
  flexGrow: 1,
  paddingBottom: HEADER.MOBILE_HEIGHT,
  elevation: 1,
  [theme.breakpoints.up('lg')]: {
    paddingTop: HEADER.DASHBOARD_DESKTOP_HEIGHT-8,
    // paddingBottom: HEADER.DASHBOARD_DESKTOP_HEIGHT + 24,
    width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    height: `calc(100vh - ${NAVBAR.DASHBOARD_WIDTH}px)`,
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
  [theme.breakpoints.down('lg')]: {
    paddingTop: HEADER.MOBILE_HEIGHT,
    width: '100%',
    height: 'auto',
    transition: theme.transitions.create('margin-left', {
      duration: theme.transitions.duration.shorter,
    }),
  },
}));

// ----------------------------------------------------------------------

export default function DashboardLayout() {
  const [open, setOpen] = useState(false);
  const { collapseClick, isCollapse } = useCollapseDrawer();
  const { chamado, comentarios_chamado, openChamado } = useSelector((state) => state.chamados);
  // 
  return (
    <Box
    sx={{
      background:'#FFF',
      display: { lg: 'flex' },
      minHeight: { lg: 1 },
    }}
  >
      <DashboardNavbar />
      {/* <NavbarVertical isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} /> */}

      <MainStyle collapseClick={collapseClick}>
        <Outlet />
      </MainStyle>
    </Box>
  );
}
