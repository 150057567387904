// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_CHAT = '/chats';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components',
  sync: '/sync'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
 
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    supervisionar: path(ROOTS_DASHBOARD, '/supervisao/lista'),
    conexao: path(ROOTS_DASHBOARD, '/conexao'),
    conectar: path(ROOTS_DASHBOARD, '/conectar'),
    agentes: path(ROOTS_DASHBOARD, '/agentes'),
    campanhas: path(ROOTS_DASHBOARD, '/campanhas'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/clientes'),
    profile: path(ROOTS_DASHBOARD, '/clientes/profile'),
    cards: path(ROOTS_DASHBOARD, '/clientes/cards'),
    clientes: path(ROOTS_DASHBOARD, '/clientes/clientes'),
    flow: path(ROOTS_DASHBOARD, '/clientes/flow'),
    users: path(ROOTS_DASHBOARD, '/clientes/list'),
    newUser: path(ROOTS_DASHBOARD, '/clientes/new'),
    editById: path(ROOTS_DASHBOARD, `/clientes/reece-chung/edit`),
    account: path(ROOTS_DASHBOARD, '/clientes/account')
  }
};


export const PATH_CHAT = {
  root: ROOTS_CHAT,
  chat: {
    root: path(ROOTS_CHAT, '/chat'),
    funil: path(ROOTS_CHAT, '/funil'),
    campanha: path(ROOTS_CHAT, '/campanhas'),
    mensagensAgendadas: path(ROOTS_CHAT, '/agendamentos'),
    perfil: path(ROOTS_CHAT, '/perfil'),
    new: path(ROOTS_CHAT, '/chat/new'),
    conversation: path(ROOTS_CHAT, '/chat/:conversationKey')
  },
  staff: {
    root: path(ROOTS_CHAT, '/staff'),
    new: path(ROOTS_CHAT, '/staff/new'),
    conversation: path(ROOTS_CHAT, '/staff/:conversationKey')
  },
  suporte: {
    root: path(ROOTS_CHAT, '/suporte'),
  },
  funil: {
    root: path(ROOTS_CHAT, '/funil'),
  },
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
