import axios from "axios";

import Config from "../../config";

const actionDetalhesUsuario = usuarioId => {
    
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/usuarios/${usuarioId}/detalhes`
    });
};

export default actionDetalhesUsuario;
