
export const mapConfig = process.env.REACT_APP_MAP_MAPBOX;
export const googleAnalyticsConfig = process.env.REACT_APP_GA_MEASUREMENT_ID;
export const nomeProjeto = process.env.REACT_APP_NOME_PROJETO;
export const urlLogo = process.env.REACT_APP_LOGO_URL;
export const colorTheme = process.env.REACT_APP_COLOR_THEME;
const usuario = localStorage.getItem("usuarioId");
const numero = localStorage.getItem("numberId");
const pod = localStorage.getItem("pod");


export const HEADER = {
  MOBILE_HEIGHT: 64,
  MAIN_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_HEIGHT: 65,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  BASE_WIDTH: 220,
  DASHBOARD_WIDTH: 200,
  DASHBOARD_COLLAPSE_WIDTH: 70,
  DASHBOARD_ITEM_ROOT_HEIGHT: 48,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32
};

export default {
    dev:false,
    urlBase: `https://api1-${usuario}.zapchat.app`,
    urlWhatsapp: `https://z${pod}.zindi.com.br`,
    urlMeta: `https://patagon.zindi.com.br`,
    urlApi: `https://api-${usuario}.zapchat.app`,
    numero:numero,
    url_dev: 'http://localhost:2000',
    url_prod: 'https://api.zindi.com.br',
    urlBackend: 'https://api.zindi.com.br',
    url_dev_ws:'https://ws.zindi.com.br',
    urlWs:'https://ws.zindi.com.br',
    //urlWs:'http://localhost:4000',
    erroDesconexaoWhatsapp: {
      titulo:"Erro de conexão",
      mensagem:"A sessão com o whatsapp está desconetada, por favor reconecte o whatsapp"
    }

};

export const ICON = {
  NAVBAR_ITEM: 22,
  NAVBAR_ITEM_HORIZONTAL: 20,
};


