import axios from "axios";
import Config from "../../../config";

const v2_actionEditarLead = (leadId, data, usuarioId) => {
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/crm/leads/${leadId}/editar?usuario_id=${usuarioId}`,
        data
    });
};

export default v2_actionEditarLead;
