import axios from "axios";

import Config from "../../config";

const getActionChatInfo = (clienteId, phone) => {
    console.log('clienteId, phone', clienteId, phone)
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v1/chats/chat/${clienteId}/${phone}`
    });
};

export default getActionChatInfo;
