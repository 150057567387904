import axios from "axios";

import Config from "src/config";

const actionUpdateConfigFunil = data => {
    return axios({
        method: "put",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/funil/editar-config-funil`,
        data
    });
};

export default actionUpdateConfigFunil;
