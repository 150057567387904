import { DatePicker } from '@mui/x-date-pickers';
import { Stack, Container, Box } from '@mui/material';
import { useEffect, useState } from 'react';
import { KanbanColumn } from 'src/sections/@dashboard/negocios';
import Page from 'src/components/Page';
import { persistCard, persistColumn } from 'src/redux/slices/chat-kanban';
import { useDispatch, useSelector } from 'src/redux/store';
// utils
import { validaLabelMenuDatas } from 'src/utils/functions';
// components
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import EmptyContent from 'src/components/EmptyContent';
import NegociosToolbar from 'src/sections/@dashboard/negocios/NegociosToolbar';
import { getListaColunasCrm, getListaLeads } from 'src/redux/slices/crm';
// hooks
import useAuth from 'src/hooks/useAuth';
//actions
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';
import v2_actionEditarChat from 'src/actions/v2/chats/v2_actionEditarChat';
import actionListaClientesCanais from 'src/actions/clientes/actionListaClientesCanais';
import Scrollbar from 'src/components/Scrollbar';
import v2_actionEditarLead from 'src/actions/v2/crm/v2_actionEditarLead';
import ModalNovoNegocio from 'src/sections/@dashboard/cadastro/ModalNovoNegocio';
// ---------------------------------------------------------------

export default function Negocios() {
  const dispatch = useDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const { colunas, ordemColunas, lead, leadsKanban } = useSelector((state) => state.crm);
  const { negocio } = useSelector((state) => state.global);
  const { user, cliente } = useAuth();
  const [inicial, setInicial] = useState(new Date());
  const [final, setFinal] = useState(new Date());
  const [periodo, setPeriodo] = useState('ultimos_90_dias')
  const [listaClientesCanais, setListaClientesCanais] = useState([]);
  const [pipelineId, setPipelineId] = useState('');
  const [configCrm, setConfigCrm] = useState(null);
  const [qualificacao, setQualificacao] = useState('all');
  const [responsaveis, setResponsaveis] = useState('me');
  const [loadingKanban, setLoadingKanban] = useState(false);
  const [loadingModalCriarNegocio, setLoadingModalCriarNegocio] = useState(false);
  const [openModalCriarNegocio, setOpenModalCriarNegocio] = useState(false);

  async function buscarConfigCrm(querys) {
    try {
      const result = await v2_actionConfigCrm(cliente._id, querys);
      if (result.data.data) {
        let data = result.data.data;
        if (data.pipelines.length > 0) {
          setPipelineId(data.pipelines[0]._id);
          handleListaChatsFunil(data.pipelines[0]._id, qualificacao, responsaveis)
        }

        setConfigCrm(result.data.data);

      }
    } catch (error) {
      console.log(error);
    }
  }

  async function buscarListaClientesCanais() {
    try {
      const response_clientes_canais = await actionListaClientesCanais(cliente._id)
      setListaClientesCanais(response_clientes_canais.data.data);
    } catch (err) {
      console.log(err)
    }
  }


  async function handleListaChatsFunil(pipelineId, qualificacao, responsaveis, querysData) {
    try {
      let inicio2 = moment(inicial).format("YYYY-MM-DD");
      let final2 = moment(final).format("YYYY-MM-DD");
      let querys = querysData ? querysData : `?periodo=${periodo}&usuario_id=${user.id}&cliente_id=${user.clienteId}&data_inicial=${inicio2}&data_final=${final2}&pipeline_id=${pipelineId}&qualificacao=${qualificacao}&responsaveis=${responsaveis}`
      dispatch(getListaLeads(querys));
      dispatch(getListaColunasCrm(querys));
    } catch (error) {
      console.log('error', error)
    }
  }

  useEffect(() => {
    buscarConfigCrm()
    buscarListaClientesCanais();
  }, [dispatch]);


  async function editarLead(action, value, leadId) {
    if (action === "editar_estagio_funil") {
      let data = {
        action: action,
        scope: {
          estagio_pipeline_id: value,
        }
      }
      await v2_actionEditarLead(leadId, data, user.id);
      enqueueSnackbar('Estágio alterado com sucesso', { variant: 'success', autoHideDuration: 3000, anchorOrigin: { vertical: 'bottom', horizontal: 'left' } });
    }
  }

  const onDragEnd = async (result) => {
    const { destination, source, draggableId, type } = result;

    if (!destination) return;
    if (destination.droppableId === source.droppableId && destination.index === source.index) return;
    setLoadingKanban(true);
    await editarLead("editar_estagio_funil", destination.droppableId, draggableId);
    handleListaChatsFunil(pipelineId, qualificacao, responsaveis);

    const start = colunas[source.droppableId];
    const finish = colunas[destination.droppableId];

    if (start.id === finish.id) {
      const updatedCardIds = [...start.leadIds];
      updatedCardIds.splice(0, 1);
      updatedCardIds.splice(destination.index, 0, draggableId);

      const updatedColumn = {
        ...start,
        leadIds: updatedCardIds,
      };

      dispatch(
        persistCard({
          ...colunas,
          [updatedColumn.id]: updatedColumn,
        })

      );

      return;
    }

    const startCardIds = [...start.leadIds];

    startCardIds.splice(0, 1);
    const updatedStart = {
      ...start,
      leadIds: startCardIds,
    };

    const finishCardIds = [...finish.leadIds];
    finishCardIds.splice(destination.index, 0, draggableId);
    const updatedFinish = {
      ...finish,
      leadIds: finishCardIds,
    };

    let data3 = {
      ...colunas,
      [updatedStart.id]: updatedStart,
      [updatedFinish.id]: updatedFinish,
    }

    dispatch(
      persistCard(data3)
    );

    setTimeout(() => {
      setLoadingKanban(false);
    }, 1000);


  };


  useEffect(() => {
  }, [colunas, leadsKanban]);

  return (
    <Page title="Negócios">
      <ModalNovoNegocio
        open={openModalCriarNegocio}
        cliente={cliente}
        user={user}
        callbackAddContact={() => { }}
        onClose={() => {
          setOpenModalCriarNegocio(false);
          setLoadingModalCriarNegocio(false);
        }}
      />

      <Container maxWidth>
        <NegociosToolbar
          pipelineId={pipelineId}
          changeQualificacao={(value) => {
            setQualificacao(value);
            handleListaChatsFunil(pipelineId, value, responsaveis);
          }}
          changePipelineId={(value) => {
            setPipelineId(value);
            console.log('value', value)
            handleListaChatsFunil(pipelineId, qualificacao, responsaveis);
          }}
          responsaveis={responsaveis}
          changeResponsaveis={(value) => {
            setResponsaveis(value);
            handleListaChatsFunil(pipelineId, qualificacao, value);
          }}
          onFiltrarDataCustom={(value) => {
            let querys = `?periodo=${value}&usuario_id=${user.id}&cliente_id=${user.clienteId}&pipeline_id=${pipelineId}&qualificacao=${qualificacao}&responsaveis=${responsaveis}`
            handleListaChatsFunil(pipelineId, qualificacao, responsaveis, querys)
          }}
          onFiltrarData={(value) => {
            let querys = `?periodo=${value}&usuario_id=${user.id}&cliente_id=${user.clienteId}&pipeline_id=${pipelineId}&qualificacao=${qualificacao}&responsaveis=${responsaveis}`
            handleListaChatsFunil(pipelineId, qualificacao, responsaveis, querys)
          }}
        />
      </Container>

      <Box sx={{
        height: {
          xs: 'calc(75vh - 100px)',
          sm: 'calc(85vh - 50px)',
          lg: 'calc(80vh - 50px)'
        }
      }}>

        <Container sx={{height: 1 }} maxWidth>
          <Scrollbar>
            {leadsKanban ? (
              <DragDropContext onDragEnd={onDragEnd}>
                <Droppable
                  droppableId="all-columns" direction="horizontal" type="column">
                  {(provided) => (
                    <Stack
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      direction="row"
                      spacing={2}
                    >
                      {!ordemColunas.length ? (
                        <EmptyContent title={"Não encontramos nenhum lead por aqui"} />
                      ) : (
                        colunas &&
                        ordemColunas.map((columnId, index) => <KanbanColumn user={user} loadingKanban={loadingKanban} cliente={cliente} configCrm={configCrm} callbackRefreshLeads={() => handleListaChatsFunil(pipelineId, qualificacao, responsaveis)} index={index} key={columnId} column={colunas[columnId]} />)
                      )}
                      {provided.placeholder}
                    </Stack>
                  )}
                </Droppable>
              </DragDropContext>
            ) : (
              <EmptyContent
                title={"Não encontramos nenhum lead por aqui"}
              />
            )}
          </Scrollbar>
        </Container>
      </Box>
    </Page>
  );
}
