import axios from "axios";

import Config from "src/config";

const actionCriarNotas = (chatId, data) => {
    return axios({
        method: "post",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/chats/${chatId}/notas/add`,
        data
    });
};

export default actionCriarNotas;
