
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { useNavigate } from "react-router-dom";

import React from 'react'

import {
  Link,
  Stack,
  Alert,
  Checkbox,
  TextField,
  IconButton,
  InputAdornment,
  FormControlLabel,
  Grid,
  Typography
} from '@mui/material';
import { LoadingButton } from '@mui/lab';

import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';

// hooks
import useAuth from '../../../hooks/useAuth';
import useIsMountedRef from '../../../hooks/useIsMountedRef';
//
import { MIconButton } from '../../@material-extend';
import { Button } from '@mui/material';
import InputStyle from 'src/components/InputStyle';
import { RowInput } from 'src/components/forms/RowInput';
import { Input } from 'src/@/components/ui/input';
import { ButtonLoading } from 'src/@/components/use/ButtonLoading';



export default function LoginForm() {
  const navigate = useNavigate();
  const { login } = useAuth();
  const isMountedRef = useIsMountedRef();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = React.useState(false);

  const [email, setEmail] = useState('');
  const [senha, setSenha] = useState('');
  const [checkManterLogado, setCheckManterLogado] = React.useState(false);


  const handleShowPassword = () => {
    setShowPassword((show) => !show);
  };

  const handleKeyUp = (event) => {
    if (!event.shiftKey && event.key === 'Enter') {
      onLogin();
    }
  };


  useEffect(() => {
    const storedCredenciais = JSON.parse(localStorage.getItem('credenciais'));

    if (storedCredenciais) {
      setEmail(storedCredenciais.email);
      setSenha(storedCredenciais.password);
      setCheckManterLogado(storedCredenciais.checkManterLogado)
    }
  }, []);


  async function onLogin() {
    setLoading(true);

    try {
      const resLogin = await login(email, senha, checkManterLogado);

      if (resLogin.data.password === 'Wrong password.') {
        setLoading(false);
        enqueueSnackbar('Sennha Incorreta', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });
      }


    }
    catch (e) {
      setLoading(false);
      enqueueSnackbar('Sennha Incorreta', {
        variant: 'error',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
      console.log(e)
    }
  };

  return (
    <Stack sx={{minWidth:350}}>
      <Stack spacing={3}>
      <RowInput
        label={'email'}
        input={     <Input
          fullWidth
          value={email}
          onKeyPress={handleKeyUp}
          autoComplete="email"
          type="email"
          remember={true}
          onChange={event => setEmail(event.target.value)}

        />}
        />
   

        <RowInput
        label={'senha'}
        input={        <Input
          fullWidth
          value={senha}
          onKeyPress={handleKeyUp}
          autoComplete="current-password"
          type={showPassword ? 'text' : 'password'}
          onChange={event => setSenha(event.target.value)}
        />}
        />
      </Stack>

      <Stack sx={{mt:2}} alignItems={'center'} justifyContent={'space-between'} direction="row">
        <FormControlLabel
          sx={{fontSize:10, fontWeight:'500'}}
          control={<Checkbox
            checked={checkManterLogado}
            onChange={event => setCheckManterLogado(event.target.checked)}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          } 
          label={<Typography sx={{fontSize:12}} >Lembrar senha</Typography>}
           />
          <Button onClick={() => navigate('/auth/reset-password')} sx={{fontSize:12, fontWeight:'500', textTransform:'none'}} variant='text'>Esqueceu a senha?</Button>
      </Stack>

      <ButtonLoading onClick={onLogin} className="mt-5" loading={loading}  >
        Login
      </ButtonLoading>
    </Stack>
  );
}
