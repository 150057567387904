import { Container, IconButton, Stack, Typography } from "@mui/material";
import Iconify from "src/components/Iconify";

export default function HeaderPageComponentConfig({ titulo, descricao, actions, back, backAction }) {

    return (
            <Stack justifyContent={'space-between'} alignItems={'center'} direction={'row'} >
                    <Stack alignItems={'center'} direction={'row'} spacing={1}>
                        {backAction && <IconButton onClick={backAction}><Iconify icon={'typcn:arrow-back'} height={25} width={25} /></IconButton>}
                        <Stack direction={'column'}>
                            <h4 className="scroll-m-20 text-xl font-semibold tracking-tight">
                                {titulo}
                            </h4>

                            <p className="text-xs leading-7">
                                {descricao}
                            </p>
                        </Stack>

                    </Stack>
                    {actions}
            </Stack>

    );
}
