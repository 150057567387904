import plusFill from '@iconify/icons-eva/plus-fill';
import { Icon } from '@iconify/react';
import {
  Stack,
  Container,
  Box
} from '@mui/material';
import { useState } from 'react';
import ModalAlerta from 'src/components/modal/ModalAlerta';
import { HeaderPageComponentConfig } from 'src/components/_dashboard/configuracoes';
import PipileneItem from './PipileneItem';
import { useEffect } from 'react';
import DrawerNovoFunil from './DrawerNovoFunil';
import ModalConfimacao from 'src/components/modal/ModalConfimacao';

//actions
import v2_actionDeletarPipeline from 'src/actions/v2/crm/v2_actionDeletarPipeline';
import v2_actionConfigCrm from 'src/actions/v2/crm/v2_actionConfigCrm';

//zui
import { Button } from 'src/@/components/ui/button';
import Iconify from 'src/components/Iconify';

export default function FunilComponent({ cliente, user }) {
  const [openPopoverNovoFunil, setOpenPopoverNovoFunil] = useState(false);
  const [modalAlerta, setModalAlerta] = useState(false);
  const [openDrawerNovoFunil, setOpenDrawerNovoFunil] = useState(false);
  const [configCrm, setConfigCrm] = useState(null);
  const [openModalConfirmacaoExclusao, setOpenModalConfirmacaoExclusao] = useState(false);

  const [pipeline, setPipeline] = useState(null);
  const [pipelineId, setPipelineId] = useState(null);
  const [isEdit, setIsEdit] = useState(false);


  async function buscarConfigCrm(querys) {
    try {
      const result = await v2_actionConfigCrm(cliente._id, querys);
      if (result.status === 200) {
        setConfigCrm(result.data?.data);
      }
    } catch (error) {
      console.log(error);
    }
  }

  async function handleDeletePipeline() {
    try {
      await v2_actionDeletarPipeline(pipelineId);
      let querys = `?all=1`
      buscarConfigCrm(querys);
      setOpenModalConfirmacaoExclusao(false)
    } catch (error) {
      setOpenModalConfirmacaoExclusao(false)

      console.log(error);
    }
  }

  useEffect(() => {
    let querys = `?all=1`
    buscarConfigCrm(querys)

  }, [])

  return (
    <>
      <ModalAlerta
        open={modalAlerta}
        onClose={() => setModalAlerta(false)}
        mensagem={'Não é possivel editar resposta rapida do tipo arquivo ou audio, exclua e crie uma nova resposta rápida'}
      />

      <ModalConfimacao
        open={openModalConfirmacaoExclusao}
        onClose={() => setOpenModalConfirmacaoExclusao(false)}
        onConfirm={() => handleDeletePipeline()}
        loadingFinalizar={null}
        mensagem={'Deseja mesmo excluir esse funil?'}
      />

      <DrawerNovoFunil
        open={openDrawerNovoFunil}
        onClose={() => {
          setOpenDrawerNovoFunil(false);
          setIsEdit(false)
        }}
        onCallback={() => {
          let querys = `?all=1`
          buscarConfigCrm(querys)
          setOpenDrawerNovoFunil(false)
        }}
        user={user}
        cliente={cliente}
        isEdit={isEdit}
        pipeline={pipeline}
        pipelineId={pipeline?._id}
      />

     

      <Container maxWidth="lg">
      <HeaderPageComponentConfig
        titulo="Funil"
        descricao={'Gerencie os funis de vendas da sua empresa. Configure pipilines, etapas e status.'}
        actions={<>
          <Button
            onClick={() => setOpenDrawerNovoFunil(true)}
          >
            <Iconify className="mr-2 mt-1" icon={"heroicons:plus-16-solid"} />
            Criar Funil
          </Button>

        </>}
      />
          <Box
          className="mt-10"
            sx={{
              display: 'grid',
              gap: 2,
              flexDirection: 'row',
              gridTemplateColumns: {
                xs: 'repeat(2, 1fr)',
                sm: 'repeat(4, 1fr)',
                md: 'repeat(4, 1fr)',
                lg: 'repeat(4, 1fr)',
              },
            }}
          >
            {
              configCrm?.all_pipelines?.map((item, index) => (
                <PipileneItem
                  user={user}
                  onDelete={item => {
                    setPipelineId(item._id)
                    setOpenModalConfirmacaoExclusao(true)
                  }}
                  onEdit={(item) => {
                    setPipeline(item)
                    setOpenDrawerNovoFunil(true);
                    setIsEdit(true)
                  }}
                  cliente={cliente}
                  pipelines={configCrm?.all_pipelines}
                  index={index}
                  item={item}
                  onCallback={() => {
                    let querys = `?all=1`;
                    buscarConfigCrm(querys)
                  }}
                />
              ))
            }
          </Box>
      </Container>
    </>
  );
}
