import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import { Button } from 'src/@/components/ui/button';
import { Card, CardContent, CardTitle, CardHeader, CardDescription } from 'src/@/components/ui/card';
import v2_actionCheckIsBlock from 'src/actions/v2/cliente/v2_actionCheckIsBlock';
import Logo from 'src/components/Logo';
import useAuth from 'src/hooks/useAuth';


export default function Suspend() {
    const {cliente, logout} = useAuth();
    const navigate = useNavigate();

    const { globalConfig } = useSelector(state => state.global);

    async function checkIsBlock(id) {

        try {
            const response = await v2_actionCheckIsBlock(id);
            if(response.status === 200) {
                if(!response.data.data) {
                    navigate('/chats/chat');
                }
            }
         } catch(err) {

         }
        
    }

    useEffect(() => {
      if(cliente) {
        checkIsBlock(cliente?._id)
      }
    }, [])
    


    const SuspendComponent = () => {
        return (
            <div className='flex flex-col gap-5 justify-center items-center min-w-[500px]'>
                <Logo />
                <Card className="p-5">
                    <CardContent className="flex flex-col items-center gap-3">
                        <img width="300" src="https://lais-app.s3.us-east-2.amazonaws.com/static/payment-error-2130357-1800921.png" />

                        <div className='flex-col items-center flex mt-5'>
                            <h2 className="mt-10 scroll-m-20 pb-2 text-2xl font-semibold transition-colors first:mt-0">
                                Sua conta está suspensa
                            </h2>
                            <p className="">
                                Entre em contato com o suporte para verificar.
                            </p>
                            <p className='text-sm mt-4 text-gray-600'>(37) 9-8844.4321</p>


                            <Button  onClick={() => window.open(`https://api.whatsapp.com/send/?phone=${globalConfig?.provider?.config?.phone_support}&text=Oi%2C+venho+do+site+lais+e+gostaria+de+tirar+algumas+duvidas&type=phone_number&app_absent=0`, '_blank')} className="mt-10 bg-slate-600">
                                Entrar em contato
                            </Button>
                        </div>

                        
                        <Button  
                        onClick={() => logout()} 
                        variant="text"
                        >
                                Sair
                            </Button>


                    </CardContent>
                </Card>
            </div>
        )
    }



    return (
        <div className='flex flex-col gap-5 justify-center items-center h-full bg-slate-200'>
            <SuspendComponent />
        </div>
    );
}
