import axios from "axios";

import Config from "../../../../config";

const v2_actionMetricasForcaVendas = querys => {
    return axios({
        method: "get",
        url: `${Config.dev ? Config.url_dev : Config.urlBackend}/api/v2/dashboard/relatorios/forca-vendas/metricas${querys}`
    });
};

export default v2_actionMetricasForcaVendas;
