import arrowIosBackFill from '@iconify/icons-eva/arrow-ios-back-fill';
import arrowIosForwardFill from '@iconify/icons-eva/arrow-ios-forward-fill';
import { Icon } from '@iconify/react';
// material
import { useMediaQuery, Divider, IconButton } from '@mui/material';
import { useTheme, styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';

import ChatRoomOneParticipant from './ChatRoomOneParticipant';
import v2_actionListaTagsChats from 'src/actions/v2/chats/v2_actionListaTagsChats';
import useAuth from 'src/hooks/useAuth';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  width: 300,
  flexShrink: 0,
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  borderLeft: `solid 1px ${theme.palette.divider}`,
  transition: theme.transitions.create('width')
}));

const ToggleButtonStyle = styled('div')(({ theme }) => ({
  borderRight: 0,
  display: 'flex',
  overflow: 'hidden',
  position: 'absolute',
  alignItems: 'center',
  top: theme.spacing(1),
  left: theme.spacing(-4),
  width: theme.spacing(4),
  height: theme.spacing(4),
  justifyContent: 'center',
  boxShadow: theme.customShadows.z8,
  border: `solid 1px ${theme.palette.divider}`,
  borderTopLeftRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  borderBottomLeftRadius: theme.shape.borderRadius
}));


// ----------------------------------------------------------------------

ChatRoom.propTypes = {
  conversation: PropTypes.object.isRequired
};

export default function ChatRoom({ chat, ...other }) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));
  const [openSidebar, setOpenSidebar] = useState(false);
  const [showInfo, setShowInfo] = useState(true);
  const [listaTags, setListaTags] = useState([]);
  const { user } = useAuth();


  async function buscarListaTags() {
    try {
      let querys = `?cliente_id=${user.clienteId}`
      const response = await v2_actionListaTagsChats(querys);
      setListaTags(response.data.data)
    } catch (error) {

    }
  }

  useEffect(() => {
    if (isMobile) {
      return setOpenSidebar(false);
    }
    buscarListaTags()
    return setOpenSidebar(true);
  }, [isMobile]);



  return (
    <RootStyle
      sx={{
        ...(!openSidebar && {
          width: 0,
          '& > *': { overflow: 'hidden' }
        })
      }}
      {...other}
    >
      <ToggleButtonStyle>
        <IconButton onClick={() => setOpenSidebar(!openSidebar)}>
          <Icon width={16} height={16} icon={openSidebar ? arrowIosForwardFill : arrowIosBackFill} />
        </IconButton>
      </ToggleButtonStyle>

      {/* <ChatRoomOneParticipant
          chat={chat}
          listaTags={listaTags}
          isCollapse={showInfo}
          onCollapse={() => setShowInfo((prev) => !prev)}
        /> */}


    </RootStyle>
  );
}
